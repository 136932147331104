import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Save } from "@mui/icons-material";
import React, { useState } from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import Select from "react-select";
import { selectFieldProps } from "../../shared/constants";

export default function ChangeFacilitator({ open, close, session }) {
  const {
    GetTrainingProviders,
    getTrainingFacilitators,
    updateTrainingSession,
  } = useDb();
  const providers = GetTrainingProviders();
  const [facilitators, setFacilitators] = useState();
  const [facilitator, setFacilitator] = useState();
  const [source, setSource] = useState();
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setFacilitators(null);
    setFacilitator(null);
    setSource(null);
    setLoading(false);
    close();
  };

  const getFacilitators = async (externalBool, providerId) => {
    const f = await getTrainingFacilitators(externalBool, providerId);
    setFacilitators(f);
  };

  const handleSelect = async (v, e) => {
    setFacilitator(null);
    if (e.name === "provider") {
      setFacilitators(null);
      await getFacilitators(true, v.value.id);
    }
    if (e.name === "facilitator") {
      setFacilitator(v.value);
    }
  };

  const handleRadio = async (e) => {
    setFacilitator(null);
    setFacilitators(null);
    if (e.target.value === "inhouse") {
      await getFacilitators(false, null);
    }
    setSource(e.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await updateTrainingSession(session, {
        facilitator,
        source,
      });
      handleClose();
    } catch (err) {
      window.alert(err.message);
    }
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
      <DialogTitle>Change Facilitator: {session?.module?.name}</DialogTitle>
      <DialogContent>
        <RadioGroup name="source" onChange={handleRadio}>
          <FormControlLabel
            control={<Radio value="external" />}
            label="External"
          />
          <FormControlLabel
            control={<Radio value="inhouse" />}
            label="In-House"
          />
        </RadioGroup>
        {source === "external" ? (
          <>
            <Select
              {...selectFieldProps}
              placeholder="Select Training Provider"
              className="iso-select"
              options={providers}
              name="provider"
              onChange={handleSelect}
            />
            {facilitators ? (
              <Select
                {...selectFieldProps}
                placeholder="Select External Facilitator"
                className="iso-select"
                name="facilitator"
                options={facilitators}
                onChange={handleSelect}
              />
            ) : null}
          </>
        ) : null}
        {source === "inhouse" ? (
          <Select
            {...selectFieldProps}
            placeholder="Select In-House Facilitator"
            className="iso-select"
            name="facilitator"
            options={facilitators}
            onChange={handleSelect}
          />
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          startIcon={<Save />}
          onClick={handleSubmit}
          disabled={!facilitator || loading}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
