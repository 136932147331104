import { Button, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'

export default function AddDepartment({ closeDialog }) {
    const { addDepartment } = useDb()
    const [name, setName] = useState('')
    const [error, setError] = useState('')
    const [code, setCode] = useState('')

    const saveDepartment = async () => {
        const result = await addDepartment({name, code})
        if(result !== null) {
            closeDialog()
        }
        else {
            setError('Error Saving to Database')
        }
    }

    return (
        <>
            <TextField onChange={(e) => setName(e.target.value)} label="Department Name" variant="outlined" fullWidth /><br/><br/>
            <TextField onChange={(e) => setCode(e.target.value)} label="Department Code (Optional) ie: ABC" variant="outlined" fullWidth />
            <Typography style={{ color: 'red' }}>{error}</Typography>
            <br/>
            <div className="iso-right">
                <Button disabled={ name !== '' ? false : true } onClick={saveDepartment} color="primary">Confirm</Button>
                <Button onClick={closeDialog} color="secondary">Cancel</Button>
            </div>
        </>
    )
}
