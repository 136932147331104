import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import React, { useState } from 'react'
import { useAuth } from '../../../../../contexts/AuthContext'
import { useDb } from '../../../../../contexts/DatabaseContext'
import BulkFileUploader from '../../../../shared/BulkFileUploader'

export default function CoActionAddAttachment({ coId, actionId, open, closeDialog }) {
    const { currentUser } = useAuth()
    const { mgmtAddActionAttachment } = useDb()
    const [loading, setLoading] = useState(false)
    const [fileUrl, setFileUrl] = useState(null)
    const [file, setFile] = useState(null)
    const [error, setError] = useState(null)

    const getFile = async (fileObj) => {
        setFileUrl(fileObj.url)
        setFile(fileObj.fileName)
        try {
            await mgmtAddActionAttachment(coId, actionId, fileObj.url, fileObj.fileName).then(() => {
                handleClose()
            })
        }
        catch(err) {
            setError(err.message)
        }
    }

    const getFiles = async (files) => {
        await files.forEach(async f => {
            await getFile(f)
        })
    }

    const handleClose = () => {
        setLoading(false)
        setError(null)
        setFile(null)
        setFileUrl(null)
        closeDialog()
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogContent>
                <BulkFileUploader userId={currentUser.uid} getFiles={getFiles} setBusy={e => setLoading(e)}/>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}
