import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material'
import { Save } from '@mui/icons-material'
import React, { useState } from 'react'
import Select from 'react-select'
import { useDb } from '../../../contexts/DatabaseContext'
import { selectFieldProps, textFieldProps } from '../../shared/constants'

export default function EditIsoAgency({ agency, open, closeDialog }) {
    const { GetIsoClasses, updateIsoAgency } = useDb()

    const isoClasses = GetIsoClasses()

    const [state, setState] = useState({
        isoAgency: agency.isoAgency,
        isoClass: agency.isoClass
    })
    const [updated, setUpdated] = useState(false)

    // Action State
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const handleClose = () => {
        setUpdated(false)
        setLoading(false)
        closeDialog()
    }

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
        setUpdated(true)
    }

    const handleSelect = (v) => {
        setState({
            ...state,
            isoClass: v.value
        })
        setUpdated(true)
    }

    const handleSubmit = async () => {
        setError(null)
        setLoading(false)
        try {
            await updateIsoAgency(agency.id, state).then(() => {
                handleClose()
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogContent>
                ISO Classification: {agency.isoClass}
                <Select
                    options={isoClasses}
                    {...selectFieldProps}
                    placeholder="Change ISO Classification ..."
                    onChange={v => handleSelect(v)}
                />
                <br/>
                <TextField
                    {...textFieldProps}
                    label="ISO Agency"
                    value={state.isoAgency}
                    name="isoAgency"
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                { error ? <span className="iso-error">{error}</span> : null }
                <Button disabled={loading} color="secondary" onClick={handleClose}>Cancel</Button>
                <Button disabled={!updated || loading} color="primary" onClick={handleSubmit} variant="contained" startIcon={<Save />}>Update</Button>
            </DialogActions>
        </Dialog>
    )
}
