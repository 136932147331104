import { Button, Card, CardActions, CardContent, CircularProgress, Typography } from '@mui/material'
import { Add, KeyboardArrowRight } from '@mui/icons-material'
import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useAuth } from '../../../contexts/AuthContext'
import { useDb } from '../../../contexts/DatabaseContext'
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid'
import AddRiskTypes from './components/AddRiskTypes'

export default function R3CreateRiskTypes() {
  
  const { GetRiskTypes, updateProgress } = useDb()
  const { currentUser } = useAuth()
  const history = useHistory()
  const riskTypes = GetRiskTypes()
  
  const [state, setState] = useState({
    open: false,
    loading: false,
    error: null
  })

  const progress = async () => {
    await updateProgress(4)
  }

  const handleSubmit = async () => {
    setState({
      ...state,
      loading: true,
      error: null
    })
    try {
      progress()
      setState({
        open: false,
        loading: false,
        error: null
      })
      history.push('/risk-manager/setup/add-risk-entities')
    } catch (err) {
      setState({
        ...state,
        loading: false,
        error: err.message
      })
    }
  }

  const handleOpen = () => {
    setState({
      ...state,
      open: true
    })
  }

  const closeDialog = () => {
    setState({
      ...state,
      open: false
    })
  }

  const rows = riskTypes && riskTypes.map(t => ({
    id: t.id,
    riskType: t.riskType,
    isoClass: t.isoClass.label
  }))

  const columns = [
    {
      field: 'riskType',
      headerName: 'Risk Type',
      width: 300
    },
    {
      field: 'isoClass',
      headerName: 'ISO Class',
      width: 300
    }
  ]

  return (
    <>
      {
        state.loading
        ?
        <CircularProgress />
        :
        <div>
          {
            currentUser.riskSetupComplete
            ?
            <>
              <Typography variant={'h5'}>Risk Types:</Typography>
              <br />
            </>
            :
            <>
              <Typography variant={'h5'}>Risk Manager: Initial Setup</Typography>
              <br />
              <Typography variant={'h5'}>Step 3: Add Risk Types:</Typography>
              <br />
            </>
          }
          <Button onClick={() => handleOpen()} variant={'contained'} color={'primary'} startIcon={<Add/>} >ADD</Button>
          <br/><br/>
          <Card>
            <CardContent>
              <IsoDataGrid
                  data={rows}
                  columns={columns}
              />
            </CardContent>
            <CardActions className="iso-right">
              {
                currentUser.riskSetupComplete
                ?
                null
                :
                <Button 
                  onClick={handleSubmit} 
                  disabled={state.loading} 
                  endIcon={<KeyboardArrowRight />} 
                  variant={'contained'} 
                  color={'primary'} >
                    Next
                </Button>
              }
            </CardActions>
          </Card>
          { state.error && <Typography color="secondary">{state.error}</Typography> }
        
          {/* Dialog */}
          <AddRiskTypes open={state.open} closeDialog={closeDialog}/>
        </div>
      }
    </>
  )
}
