import { Avatar, Button, Card, CardActions, CardContent, CardHeader, Grid } from '@mui/material'
import { ArrowBack, Assessment } from '@mui/icons-material'
import React, { useState } from 'react'
import { useDb } from '../../../../contexts/DatabaseContext'
import NewSessionsReport from './NewSessionsReport'
import moment from 'moment'
import { Link, useHistory } from 'react-router-dom'

export default function SessionReports() {
    const [addOpen, setAddOpen] = useState(false)
    const { GetSessionReports } = useDb()
    const reports = GetSessionReports()
    const history = useHistory()

    return (
        <div>
            <h1>Session Reports</h1>
            <div className="back-link">
                <Button onClick={() => history.goBack()} color="primary" variant="contained" startIcon={<ArrowBack />}>Back</Button>
            </div>
            <Card>
                <CardActions>
                    <Button onClick={() => setAddOpen(true)} variant="contained" color="primary">New Report</Button>
                </CardActions>
                <CardContent>
                    <Grid container spacing={3}>
                        {
                            reports && reports.map(r => (
                                <Grid item xs={12} md={4} key={r.id}>
                                    <Link to={`/training-manager/reports/sessions/${r.id}`}>
                                        <Card>
                                            <CardHeader
                                                avatar={<Avatar>
                                                    <Assessment />
                                                </Avatar>}
                                                title={`Ran: ${moment(r.timestamp.toDate()).fromNow()}`}
                                                subheader={`Sessions: ${r.sessions.length}`}
                                            />
                                        </Card>
                                    </Link>
                                </Grid>
                            ))
                        }
                        
                    </Grid>
                </CardContent>
            </Card>
            <NewSessionsReport
                open={addOpen}
                close={() => setAddOpen(false)}
            />
        </div>
    )
}
