import React, { useEffect, useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import MocChooseMatrix from "./MocChooseMatrix";
import {
  Avatar,
  CardActions,
  CardContent,
  Card,
  CardHeader,
  Grid,
  Button,
} from "@mui/material";
import { People, Save } from "@mui/icons-material";
import RiskLevelCard from "./RiskLevelCard";
import { useDb } from "../../../contexts/DatabaseContext";
import { Alert } from "@mui/material";

export default function RiskSetup({ handleUpdate, loading }) {
  const { currentUser } = useAuth();
  const [errors, setErrors] = useState([false, false, false]);
  const { GetGroups } = useDb();
  const groups = GetGroups();

  const [thresholds, setThresholds] = useState(
    currentUser.mocThresholds || {
      low: {
        spend: 0,
        groups: [],
        description: "Low Risk",
      },
      med: {
        spend: 50000,
        groups: [],
        description: "Medium Risk",
      },
      high: {
        spend: 100001,
        groups: [],
        description: "High Risk",
      },
    }
  );

  console.log(thresholds);

  const [riskMonetary, setRiskMonetary] = useState([
    {
      value: thresholds["low"]?.spend || 0,
    },
    {
      value: thresholds["medium"]?.spend || 50000,
    },
    {
      value: thresholds["high"]?.spend || 100001,
    },
  ]);

  const levels = [
    {
      name: "low",
      description: "Low Risk",
    },
    {
      name: "med",
      description: "Medium Risk",
    },
    {
      name: "high",
      description: "High Risk",
    },
  ];

  const handleMonetaryRiskChange = (e, index) => {
    // Set the new value of the levels risk monetary and also set the next level minimum to be that value + 1
    const newRiskMonetary = [...riskMonetary];
    const newValue = parseInt(e.target.value, 10);
    newRiskMonetary[index].value = newValue;

    // Update the errors state
    const newErrors = [...errors];
    if (newValue < (index > 0 ? riskMonetary[index - 1].value + 1 : 0)) {
      newErrors[index] = true;
    } else {
      newErrors[index] = false;
    }
    setErrors(newErrors);

    if (index < riskMonetary.length - 1) {
      newRiskMonetary[index + 1].value = newValue + 1;
    }
    setRiskMonetary(newRiskMonetary);
    const thresholdIndexes = ["low", "med", "high"];
    setThresholds({
      ...thresholds,
      [thresholdIndexes[index]]: {
        ...thresholds[thresholdIndexes[index]],
        spend: newValue,
      },
    });
  };

  const handleGroupSelect = (value, index) => {
    const groupIds = value.map((v) => v.id);
    const thresholdIndexes = ["low", "med", "high"];
    setThresholds({
      ...thresholds,
      [thresholdIndexes[index]]: {
        ...thresholds[thresholdIndexes[index]],
        groups: groupIds,
      },
    });
  };

  // useEffect(() => {
  //   first

  //   return () => {
  //     second
  //   }
  // }, [third])

  return (
    <div>
      {currentUser.riskSettings ? (
        <Alert severity="info">
          Your company is using the {currentUser.riskSettings.riskMatrix} Risk
          Matrix
        </Alert>
      ) : (
        <MocChooseMatrix />
      )}
      <br />
      <Card>
        <CardHeader
          title="Risk Level Group Assignments"
          subheader="Assign groups to risk levels, which will trigger email notifications for approvals based on each MOC criteria."
          avatar={
            <Avatar>
              <People />
            </Avatar>
          }
        />
        <CardContent>
          <Grid container spacing={2}>
            {levels.map((level, index) => (
              <Grid key={index} item xs={12} sm={4}>
                <RiskLevelCard
                  level={level}
                  riskControls={currentUser.riskControls || null}
                  index={index}
                  handleMonetary={handleMonetaryRiskChange}
                  riskMonetary={riskMonetary}
                  error={errors[index]}
                  groups={groups}
                  handleGroupSelect={handleGroupSelect}
                />
              </Grid>
            ))}
          </Grid>
        </CardContent>
        <CardActions style={{ float: "right" }}>
          <Button
            onClick={() =>
              handleUpdate({
                mocThresholds: thresholds,
              })
            }
            startIcon={<Save />}
            variant="contained"
            color="primary"
            disabled={loading}
          >
            Save
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}
