import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {
  CheckBox,
  Delete,
  Edit,
  FileCopy,
  Info,
  RadioButtonChecked,
  TextFields,
  Warning,
} from "@mui/icons-material";
import React, { useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { useDb } from "../../../contexts/DatabaseContext";
import { textFieldProps } from "../../shared/constants";
import FileUploader from "../../shared/FileUploader";
import AddCheckbox from "./form-elements/AddCheckbox";
import AddFile from "./form-elements/AddFile";
import AddRadio from "./form-elements/AddRadio";
import AddText from "./form-elements/AddText";
import EditCheckbox from "./form-elements/EditCheckbox";
import EditFile from "./form-elements/EditFile";
import EditRadio from "./form-elements/EditRadio";
import EditText from "./form-elements/EditText";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: 10,
  },
  fieldContainer: {
    marginBottom: 10,
    borderBottom: "1px solid #efefef",
    padding: "10px 0",
    width: "100%",
  },
}));

export default function ChecklistSection({ checklistId, section }) {
  const classes = useStyles();
  const { currentUser } = useAuth();
  const { GetChecklistSectionFields, updateChecklistSectionScore } = useDb();
  const fields = GetChecklistSectionFields(checklistId, section.id);

  // Add Dialog
  const [dialogState, setDialogState] = useState({
    text: false,
    checkbox: false,
    radio: false,
    file: false,
  });

  const [dialogEditState, setDialogEditState] = useState({
    text: false,
    checkbox: false,
    radio: false,
    file: false,
  });

  const [selectedFieldData, setSelectedFieldData] = useState("");

  const closeAddDialog = (type) => {
    setDialogState({
      ...dialogState,
      [type]: false,
    });
  };
  const openAddDialog = (type) => {
    setDialogState({
      ...dialogState,
      [type]: true,
    });
  };
  const closeEditDialog = (type) => {
    setSelectedFieldData("");
    setDialogEditState({
      ...dialogEditState,
      [type]: false,
    });
  };
  const openEditDialog = (type, fieldId, data) => {
    setSelectedFieldData({
      id: fieldId,
      data: data,
    });
    setDialogEditState({
      ...dialogEditState,
      [type]: true,
    });
  };

  const handleDelete = async (field) => {
    console.log(field.type)
    try {
      if (field.score) {
        if(field.type == 'text') {
          const newScore = field.score - (field.score * 2)
          await updateChecklistSectionScore(checklistId, section.id, newScore, 'text')
          await field.ref.delete()
        }
        if(field.type == 'file'){
          const newScore = field.score - (field.score * 2)
          await updateChecklistSectionScore(checklistId, section.id, newScore, 'file')
          await field.ref.delete()
        }
        if(field.type == 'checkbox') {
          const score = field.score.reduce((a, b) => {
            return a + b;
          })
          const newScore = score - (score * 2)
          await updateChecklistSectionScore(checklistId, section.id, [newScore], 'checkbox')
          await field.ref.delete()
        }
        if(field.type == 'radio'){
          const score = field.score.sort((a, b) => b - a)[0]
          const newScore = score - (score * 2)
          await updateChecklistSectionScore(checklistId, section.id, [newScore], 'radio')
          await field.ref.delete()
        }
      } else {
        await field.ref.delete()
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Card className={classes.card}>
      <CardHeader
        title={`Form Preview: ${section.name}`}
        avatar={
          <Avatar>
            <Info />
          </Avatar>
        }
        action={
          section.maxScore && (
            <div>
              <Typography>
                Section Maximum Score: <Chip label={section.maxScore} />
              </Typography>
            </div>
          )
        }
      />
      <CardContent>
        {fields &&
          fields.map((f) => {
            if (f.type === "text") {
              return (
                <div className={classes.fieldContainer}>
                  <TextField
                    label={f.name}
                    {...textFieldProps}
                    required={f.required}
                    key={f.id}
                  />
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<Warning />}
                  >
                    Raise Issue
                  </Button>
                  &nbsp;
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Edit />}
                    onClick={() =>
                      openEditDialog("text", f.id, {
                        name: f.name,
                        required: f.required,
                      })
                    }
                  >
                    Edit
                  </Button>
                  &nbsp;
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<Delete />}
                    onClick={() => handleDelete(f)}
                  >
                    Delete
                  </Button>
                </div>
              );
            }
            if (f.type === "checkbox") {
              return (
                <div className={classes.fieldContainer}>
                  <FormControl key={f.id}>
                    <FormLabel>{f.name}</FormLabel>
                    {f.options &&
                      f.options.map((o) => (
                        <FormControlLabel
                          key={o}
                          label={o}
                          control={<Checkbox />}
                        />
                      ))}
                  </FormControl>
                  <br />
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<Warning />}
                  >
                    Raise Issue
                  </Button>
                  &nbsp;
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Edit />}
                    onClick={() =>
                      openEditDialog("checkbox", f.id, {
                        options: f.options,
                        name: f.name,
                      })
                    }
                  >
                    Edit
                  </Button>
                  &nbsp;
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<Delete />}
                    onClick={() => handleDelete(f)}
                  >
                    Delete
                  </Button>
                </div>
              );
            }
            if (f.type === "radio") {
              return (
                <div className={classes.fieldContainer}>
                  <RadioGroup key={f.id}>
                    <FormLabel>{f.name}</FormLabel>
                    {f.options &&
                      f.options.map((o) => (
                        <FormControlLabel
                          key={o}
                          label={o}
                          control={<Radio checked={false} />}
                        />
                      ))}
                  </RadioGroup>
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<Warning />}
                  >
                    Raise Issue
                  </Button>
                  &nbsp;
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Edit />}
                    onClick={() =>
                      openEditDialog("radio", f.id, {
                        options: f.options,
                        name: f.name,
                      })
                    }
                  >
                    Edit
                  </Button>
                  &nbsp;
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<Delete />}
                    onClick={() => handleDelete(f)}
                  >
                    Delete
                  </Button>
                </div>
              );
            }
            if (f.type === "file") {
              return (
                <div className={classes.fieldContainer}>
                  <Typography>{f.file}</Typography>
                  <FileUploader
                    getFileObj={() => console.log("File")}
                    getFilename={() => console.log("File")}
                    getUrl={() => console.log("File")}
                    userId={currentUser.uid}
                  />
                  <br />
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<Warning />}
                  >
                    Raise Issue
                  </Button>
                  &nbsp;
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Edit />}
                    onClick={() =>
                      openEditDialog("file", f.id, { file: f.file })
                    }
                  >
                    Edit
                  </Button>
                  &nbsp;
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<Delete />}
                    onClick={() => handleDelete(f)}
                  >
                    Delete
                  </Button>
                </div>
              );
            }
          })}
      </CardContent>
      <CardActions>
        <Button
          color="primary"
          variant="contained"
          startIcon={<TextFields />}
          onClick={() => openAddDialog("text")}
        >
          Add Text
        </Button>
        <Button
          color="primary"
          variant="contained"
          startIcon={<CheckBox />}
          onClick={() => openAddDialog("checkbox")}
        >
          Add Checklist
        </Button>
        <Button
          color="primary"
          variant="contained"
          startIcon={<RadioButtonChecked />}
          onClick={() => openAddDialog("radio")}
        >
          Add Radio
        </Button>
        <Button
          color="primary"
          variant="contained"
          startIcon={<FileCopy />}
          onClick={() => openAddDialog("file")}
        >
          Add File Upload
        </Button>
      </CardActions>

      <AddText
        open={dialogState.text}
        close={() => closeAddDialog("text")}
        checklistId={checklistId}
        sectionId={section.id}
      />
      <AddCheckbox
        open={dialogState.checkbox}
        close={() => closeAddDialog("checkbox")}
        checklistId={checklistId}
        sectionId={section.id}
      />
      <AddRadio
        open={dialogState.radio}
        close={() => closeAddDialog("radio")}
        checklistId={checklistId}
        sectionId={section.id}
      />
      <AddFile
        open={dialogState.file}
        close={() => closeAddDialog("file")}
        checklistId={checklistId}
        sectionId={section.id}
      />
      <EditText
        open={dialogEditState.text}
        close={() => closeEditDialog("text")}
        checklistId={checklistId}
        sectionId={section.id}
        fieldData={selectedFieldData}
      />
      <EditCheckbox
        open={dialogEditState.checkbox}
        close={() => closeEditDialog("checkbox")}
        checklistId={checklistId}
        sectionId={section.id}
        fieldData={selectedFieldData}
      />
      <EditRadio
        open={dialogEditState.radio}
        close={() => closeEditDialog("radio")}
        checklistId={checklistId}
        sectionId={section.id}
        fieldData={selectedFieldData}
      />
      <EditFile
        open={dialogEditState.file}
        close={() => closeEditDialog("file")}
        checklistId={checklistId}
        sectionId={section.id}
        fieldData={selectedFieldData}
      />
    </Card>
  );
}
