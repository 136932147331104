import {
  Card,
  CardHeader,
  IconButton,
  CircularProgress,
  CardContent,
  Typography,
  CardActions,
  Button,
  Menu,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { amber } from "@mui/material/colors";
import { Apps, List, PictureAsPdf, Search, Warning } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from "prop-types";
import { useAuth } from "../../../contexts/AuthContext";
import moment from "moment";
import Add from "@mui/icons-material/Add";
import RActionRegisterData from "./RActionRegisterData";
import IsoDataGrid from "../../shared/data-grid/IsoDataGrid"
import { useDb } from "../../../contexts/DatabaseContext";
import ActionRegisterFilters from "./ActionRegisterFilters";
import { Link } from "react-router-dom";
import CreateAction from "../CreateAction";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import ActionDropDown from "../cards/ActionDropDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditAction from "../views/EditAction";

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background,
      width: 500,
      position: "relative",
      minHeight: 200,
    },
    fab: {
      position: "fixed",
      bottom: theme.spacing(6),
      right: theme.spacing(7),
      backgroundColor: amber[500],
      "&:hover": {
        backgroundColor: amber[600],
      },
    },
    tab: {
      indicatorColor: theme.palette.primary,
    },
    icon: {
      color: theme.palette.primary,
    },
    tabPanel: {
      backgroundColor: theme.palette.main,
      padding: '0px 10px'
    },
    dialog: {
      backgroundColor: theme.palette.dialogBg,
    },
    search: {
      color: theme.palette.isotext,
    },
    chip: {
      marginRight: 5
    },
    filtersTab: {
      marginBottom: 20
    },
    loaderDiv: {
      width: '100%',
      textAlign: 'center'
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <CardContent className="iso-card-content">
            <Typography component={"span"}>{children}</Typography>
          </CardContent>
        )}
      </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
}



export default function RActionRegister() {
    const classes = useStyles()
    const { getRActions, createActionsReportPdf } = useDb()
    const [actions, setActions] = useState([])
    const [anchorEl, setAnchorEl] = useState(null)
    const [action, setAction] = useState(null)
    const [loading, setLoading] = useState(false)
    const [listView, setListView] = useState(false)
    const { currentUser } = useAuth()
    const [createOpen, setCreateOpen] = useState(false)
    const [lastDoc, setLastDoc] = useState(null)
    const divScrollRef = useRef()
    const [gettingData, setGettingData] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [emailShareOpen, setEmailShareOpen] = useState(false)
    const [selectedAttachment, setSelectedAttachment] = useState(null)

    useEffect(async () => {
      const d = await getRActions()
      setActions(d.data)
    }, [])

    const [filters, setFilters] = useState({
      department: {
        fieldName: 'department.name',
        value: null
      },
      site: {
        fieldName: 'site.value',
        value: null
      },
      source: {
        fieldName: 'source.sourceName',
        value: null
      },
      isoClass: {
        fieldName: 'isoClass',
        value: null
      },
      isoAgency: {
        fieldName: 'isoAgency.isoAgency',
        value: null
      },
      open: {
        fieldName: 'open',
        value: true
      }
    })

    const getPdfReport = async () => {
      setLoading(true);
      try {
        await createActionsReportPdf(filters);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    const shareAction = (a) => {
      setSelectedAttachment(a)
      setEmailShareOpen(true)
    }

    const updateFilterState = (filter) => {
      setFilters({
        ...filters,
        [filter.key]: filter.data
      })
    }

    // The below use effect fires every time the filters state changes to get new documents
    useEffect(async () => {
      setGettingData(true)
      const data = await getRActions(filters)
      setActions(data.data)
      setLastDoc(data.lastDoc)
      setGettingData(false)
    }, [filters])

    const rows = actions && actions.map((a, i)  => ({
      index: i,
      id: a.id,
      department: a.department.name,
      hod: `${a.hod.firstName} ${a.hod.lastName}`,
      isoAgency: a.isoAgency.isoAgency,
      isoClass: a.isoClass,
      originator: `${a.originator.firstName} ${a.originator.lastName}`,
      priority: a.priority,
      site: a.site.label,
      source: a.source.sourceName,
      targetDate: moment(a.targetDate.toDate()).format('DD/MM/YYYY'),
      actionNumber: a.actionNumber,
      description: a.description
  }))

    const columns = [
      { 
        field: 'action', 
        headerName: 'Action' , 
        width: 120,
        renderCell: (params) => {
          return (
            <IconButton
              onClick={(e) => {
                  setAction(actions[params.row.index])
                  setAnchorEl(e.currentTarget)
                }}
              size="large">
              <FontAwesomeIcon icon={faEllipsisH} />
            </IconButton>
          );
        }
      },
      { field: 'actionNumber', headerName: 'Action No.' , width: 145 },
      { field: 'description', headerName: 'Description' , width: 300 },
      { field: 'department', headerName: 'Department' , width: 200 },
      { field: 'hod', headerName: 'HOD' , width: 200 },
      { field: 'isoAgency', headerName: 'Agency' , width: 200 },
      { field: 'isoClass', headerName: 'Classification' , width: 200 },
      { field: 'originator', headerName: 'Originator' , width: 200 },
      { field: 'priority', headerName: 'Priority' , width: 200 },
      { field: 'site', headerName: 'Site' , width: 200 },
      { field: 'source', headerName: 'Source' , width: 200 },
      { field: 'targetDate', headerName: 'Target Date' , width: 200 },
    ]

    return (
      <div>
          <Card>
              <CardHeader
                  className={classes.tabPanel}
                  title={
                  <>
                      {loading ? (
                      <CircularProgress />
                      ) : (
                      <IconButton className={classes.icon} onClick={getPdfReport} size="large">
                          <PictureAsPdf />
                      </IconButton>
                      )}                        
                      {currentUser.companyAdmin ||
                      currentUser.accessRights.actionManager <= 2 ? (
                      <IconButton onClick={() => setCreateOpen(true)} className={classes.icon} size="large">
                          <Add />
                      </IconButton>
                      ) : null}
                      <Link to="/action-manager/overdue">
                        <Button disableElevation variant="contained" color="primary" startIcon={<Warning />}>Overdue</Button>
                      </Link>
                  </>
                  }
                  
              />
              <div className={classes.tabPanel}>
                <span className="iso-small">Filters: </span>
                <ActionRegisterFilters
                  updateFilters={updateFilterState}
                  openState={filters.open.value}
                />
                </div>
              <CardContent>
                <IsoDataGrid 
                  data={rows}
                  columns={columns}
                />
                {
                  action 
                  ? 
                  <>
                    <Menu
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={() => setAnchorEl(null)} 
                      
                    >
                      <ActionDropDown action={action} shareAction={shareAction} editAction={() => setEditOpen(true)}/>
                    </Menu>
                    <EditAction reloadState={updateFilterState} action={action} open={editOpen} closeDialog={() => setEditOpen(false)} />
                  </>
                  :
                  null
                }
                {/* <RActionRegisterData 
                  data={actions}
                  listView={listView}
                /> */}
              </CardContent>
              <CardActions>
                {
                  gettingData
                  ?
                  <div className={classes.loaderDiv}>
                    <CircularProgress />
                  </div>
                  :
                  null
                }
                <div ref={divScrollRef}></div>
              </CardActions>
          </Card>
          <CreateAction open={createOpen} closeDialog={() => setCreateOpen(false)} />
      </div>
    );
}

// Hook
// function useOnScreen(ref, rootMargin = "0px") {
//   // State and setter for storing whether element is visible
//   const [isIntersecting, setIntersecting] = useState(false);
//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       ([entry]) => {
//         // Update our state when observer callback fires
//         setIntersecting(entry.isIntersecting);
//       },
//       {
//         rootMargin,
//       }
//     );
//     if (ref.current) {
//       observer.observe(ref.current);
//     }
//     return () => {
//       //observer.unobserve(ref.current);
//     };
//   }, []); // Empty array ensures that effect is only run on mount and unmount
//   return isIntersecting;
// }