import { Button, Card, CardContent, LinearProgress, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../contexts/AuthContext';
import { useDb } from '../../../../contexts/DatabaseContext'
import AddParticipant from '../new-assessment/AddParticipant';
import BlraParticipantList from './BlraParticipantList';

export default function BlraParticipants({ blraId }) {
    const { getBaseline, addParticipantToBlra } = useDb();
    const [blra, setBlra] = useState();
    const [updated, setUpdated] = useState(new Date());
    const [addOpen, setAddOpen] = useState(false);
    const { currentUser } = useAuth();

    // #efeff0

    const getBlra = async () => {
        const b = await getBaseline(blraId);
        setBlra(b)
    }

    const toggleAddOpen = (openBool) => {
        setAddOpen(openBool)
    }   

    const handleAdd = async (person) => {
        try {
            await addParticipantToBlra(blraId, person);
            setUpdated(new Date());
        }
        catch(err) {
            window.alert(err.message)
        }
    }

    useEffect(() => {
      const unsubscribe = getBlra();
      return unsubscribe;
    }, [blraId, updated])
    
    if(!blra) {
        return <LinearProgress />
    }
    else return (
        <div>
            <Typography variant="h5">Participants:</Typography>
            {
                currentUser.companyAdmin && <Button
                    color="primary" 
                    size="small" 
                    variant="contained" 
                    startIcon={<Add />}
                    onClick={() => toggleAddOpen(true)}
                >
                    Add Participant
                </Button>
            }
            <br/><br/>
            <Card>
                <CardContent>
                    {
                        blra.participants && <BlraParticipantList
                            parties={blra.participants}
                        />
                    }
                </CardContent>
            </Card>

            <AddParticipant
                open={addOpen}
                close={() => toggleAddOpen(false)}
                add={handleAdd}
            />
        </div>
    )
}
