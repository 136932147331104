import { Button, Card, CardActions, CardContent, CircularProgress, Grid, Slider, TextField, Typography } from '@mui/material'
import { KeyboardArrowRight } from '@mui/icons-material'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../../contexts/AuthContext'
import { useDb } from '../../../contexts/DatabaseContext'
import { textFieldProps } from '../../shared/constants'

export default function R6CriticalControlThreshold() {

  const { updateProgress, addCriticalThreshold } = useDb()
  const { currentUser } = useAuth()
  const history = useHistory()
  const [lowMin, setLowMin] = useState(1)
  const [low, setLow] = useState(null)
  const [med, setMed] = useState(null)
  const [state, setState] = useState({
    loading: false,
    error: null
  })

  const progress = async () => {
    await updateProgress(7)
  }

  const handleLowChange = (e, value) => {
    setLowMin(value)
    setLow(value)
  }

  const handleMedChange = (e, value) => {
    setMed(value)
  }

  const handleSubmit = async () => {
    setState({
      ...state,
      loading: true, 
      error: null
    })
    try {
      progress()
      await addCriticalThreshold({low: low, med: med, high: currentUser.riskSettings?.riskMax || 1})
      setState({
        ...state,
        loading: false,
        error: null
      })
      history.push('/risk-manager/setup/heirarchy-of-control')
    } catch (err) {
      setState({
        ...state,
        error: err.message,
        loading: false
      })
    }
  }

  return (
    <>
      {
        state.loading
        ?
        <CircularProgress/>
        :
        <div>
          <Typography variant={'h5'}>Risk Manager: Initial Setup</Typography>
          <br />
          <Typography variant={'h5'}>Step 6: Add Critical Control Threshold:</Typography>
          <br />
          <Grid item xs={12} lg={6} >
            <Card elevation={3}>
              <CardContent>
                <Typography>Low: {low}</Typography>
                <br />
                <Slider
                  defaultValue={1}
                  min={1}
                  max={currentUser.riskSettings?.riskMax}
                  marks
                  step={1}
                  valueLabelDisplay="auto"
                  onChange={handleLowChange}
                />
                <Typography>Medium: {med}</Typography>
                <br />
                <Slider
                  defaultValue={2}
                  min={lowMin + 1}
                  max={currentUser.riskSettings?.riskMax}
                  marks
                  step={1}
                  valueLabelDisplay="auto"
                  onChange={handleMedChange}
                />
                <Typography>High: {currentUser.riskSettings?.riskMax}</Typography>
                <br />
                <Slider
                  value={currentUser.riskSettings?.riskMax}
                  marks
                  step={1}
                  valueLabelDisplay="on"
                  disabled
                  min={currentUser.riskSettings?.riskMax - 1}
                  max={currentUser.riskSettings?.riskMax}
                />
              </CardContent>
              <CardActions className="iso-right">
                <Button 
                  onClick={handleSubmit}
                  endIcon={<KeyboardArrowRight />} 
                  disabled={ state.loading || !med || !low } 
                  variant={'contained'} 
                  color={'primary'} 
                >
                  Next
                </Button>
              </CardActions>
            </Card>
          { state.error && <Typography color="secondary">{state.error}</Typography> }
          </Grid>
        </div>
      }
    </>
  )
}
