import {
  IconButton,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Menu,
  ButtonGroup,
  Snackbar,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Search } from "@mui/icons-material";
import React, { useState } from 'react'
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faHeart } from "@fortawesome/free-solid-svg-icons";
import IsoDataGrid from "../../../shared/data-grid/IsoDataGrid";
import FileDialog from "../../../shared/FileDialog";
import DocDropDown from "../../document/DocDropDown";
import RevisionHistory from "../../document/RevisionHistory";
import { useAuth } from "../../../../contexts/AuthContext";
import { useDb } from "../../../../contexts/DatabaseContext";
import { amber } from "@mui/material/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    width: 500,
    position: "relative",
    minHeight: 200,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(6),
    right: theme.spacing(7),
    backgroundColor: amber[500],
    "&:hover": {
      backgroundColor: amber[600],
    },
  },
  tab: {
    indicatorColor: theme.palette.primary,
  },
  icon: {
    color: theme.palette.primary,
  },
  tabPanel: {
    backgroundColor: theme.palette.main,
    padding: '0px 10px'
  },
  dialog: {
    backgroundColor: theme.palette.dialogBg,
  },
  search: {
    color: theme.palette.isotext,
  },
  chip: {
    marginRight: 5
  },
  filtersTab: {
    marginBottom: 20
  },
  loaderDiv: {
    width: '100%',
    textAlign: 'center'
  }
}));

export default function MyFavDocDatagrid({favDocuments}) {

  const classes = useStyles()
  const { currentUser } = useAuth()
  const { addDocToFavorites, removeDocFromFavorites } = useDb()
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null)
  const [dest, setDest] = useState(null)
  const [doc, setDoc] = useState(null)
  const [revisionDialog, setRevisionDialog] = useState(false)
  const [pdfViewOpen, setPdfViewOpen] = useState(false)
  const [snackOpen, setSnackOpen] = useState(false)
  const [snackMessage, setSnackMessage] = useState('')
  const [update, setUpdate] = useState(0)

  const handleSnack = (msg) => {
    setSnackMessage(msg)
    setSnackOpen(true)
  }

  const closeSnack = () => {
    setSnackOpen(false)
    setSnackMessage('')
  }

  const openDialog = (dest) => {
    setDest(dest)
    setAnchorEl(null) 
    setOpen(true)
  }

  const closeDialog = () => {
    setOpen(false)
  }

  const closePdfView = () => {
    setPdfViewOpen(false)
  }

  const addToFavorites = async (docId) => {
    try {
      await addDocToFavorites(docId, currentUser.uid)
      handleSnack('Document Added to Favourites!')
    }
    catch(err) {
      console.log('Error saving document to Favourites: ', err.message)
    }
  }

  const removeFromFavorites = async (docId) => {
    try {
      await removeDocFromFavorites(docId, currentUser.uid)
      handleSnack('Document Removed from Favourites')
    }
    catch(err) {
      console.log('Error saving document to Favourites: ', doc.id)
    }
  }

  let tempArray = [];

  favDocuments.map((doc, i) => {

    const docData = {
      id: doc.id,
      index: i,
      code: doc.docCode,
      name: doc.docName,
      dateCreated: moment(doc.createdDate.toDate()).format('DD/MM/YYYY'),
      department: doc.department.label,
      targetRevDate: moment(doc.targetRevDate.toDate()).format('DD/MM/YYYY'),
      docOwner: doc.owner.label,
      status: `Due: ${moment(doc.targetRevDate.toDate()).fromNow()}`,
      tRefDate: doc.targetRevDate,
    }

    if (doc.pendingApproval < 1) {
      // Filter out grouped docs 
      if(doc.groups && doc.groups.length > 0) {
        console.log(currentUser.groups)
        if(currentUser.groups && currentUser.groups.some(r => doc.groups.includes(r))) {
          tempArray.push(docData)
        }
      } else {
        tempArray.push(docData)
      }
    }
  });

  const rows = tempArray

  const columns = [
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => {
        return (
          <ButtonGroup>
            <IconButton
              onClick={(e) => {
                setDoc(favDocuments[params.row.index])
                setAnchorEl(e.currentTarget)
              }}
              size="large">
                <FontAwesomeIcon icon={faEllipsisH} />
            </IconButton> 
            <IconButton
              onClick={() => {
                setDoc(favDocuments[params.row.index])
                setPdfViewOpen(true)
              }}
              size="large"><Search /></IconButton>
            {
              currentUser.favoriteDocs && currentUser.favoriteDocs.includes(params.row.id)
              ?
              <IconButton
                onClick={() => {
                  removeFromFavorites(params.row.id)
                  .then(() => setUpdate(update +1))
                }}
                size="large">
                <FontAwesomeIcon icon={faHeart} style={{ color: '#ffa900'}}/>
              </IconButton> 
              :
              <IconButton
                onClick={() => {
                  addToFavorites(params.row.id)
                  .then(() => setUpdate(update +1))
                }}
                size="large">
                  <FontAwesomeIcon icon={faHeart}/>
              </IconButton>
            }
          </ButtonGroup>
        );
      }
    },
    {
      field: 'code',
      headerName: 'Doc Number',
      width: 200
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 600
    },
    {
      field: 'dateCreated',
      headerName: 'Date Created',
      width: 200
    },
    {
      field: 'department',
      headerName: 'Department',
      width: 200
    },
    {
      field: 'targetRevDate',
      headerName: 'Target Revision Date',
      width: 200
    },
    {
      field: 'docOwner',
      headerName: 'Document Owner',
      width: 200
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 200,
    },
  ]


  return (
    <div>
      <IsoDataGrid
        data={rows}
        columns={columns}
      />
      {
        doc 
        ?
        <>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            
          >
            <DocDropDown doc={doc} openDialog={openDialog} closeDialog={closeDialog}/>
          </Menu>
          
          <Dialog maxWidth="sm" fullWidth open={open} onClose={() => setOpen(false)}>
              <DialogContent className={classes.dialog}>
                { dest }
              </DialogContent>
              <DialogActions className={classes.dialog} >
                <Button onClick={closeDialog} color="primary">Close</Button>
              </DialogActions>
          </Dialog>

          <Dialog open={revisionDialog} onClose={() => setRevisionDialog(false)} maxWidth="md" fullWidth>
              <DialogContent>
                <RevisionHistory doc={doc}/>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setRevisionDialog(false)} color="primary">Close</Button>
              </DialogActions>
          </Dialog>
          <FileDialog open={pdfViewOpen} closeDialog={closePdfView} file={doc.docUrl} /> 
        </>
        :
        null
      }
      <Snackbar
        anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
        }}
        open={snackOpen}
        autoHideDuration={5000}
        onClose={closeSnack}
        message={snackMessage}
      />
    </div>
  )
}
