import {
    AppBar,
    Button,
    Card,
    CardActions,
    CardContent,
    Dialog,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Menu,
    LinearProgress,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AddBox, ArrowLeftOutlined, AttachFile, Close, Edit, MeetingRoom, Search } from '@mui/icons-material'
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import moment from 'moment'
import { amber } from '@mui/material/colors'
import EditProgram from './EditProgram'
import ConfirmSomething from '../../shared/ConfirmSomething'
import { useAuth } from '../../../contexts/AuthContext'
import CloseProgram from './CloseProgram'
import AddProgram from './AddProgram'
import { Link, useParams, useHistory } from 'react-router-dom'
import { Pagination } from '@mui/material';
import FloatingHelpComponent from '../../help/FloatingHelpComponent'


const useStyles = makeStyles(theme => ({
    greenBtn: {
        color: '#fff',
        backgroundColor: '#72a55e'
    },
    amberBtn: {
        color: '#fff',
        backgroundColor: amber[600]
    },
    redBtn: {
        color: '#fff',
        backgroundColor: 'red'
    },
    fab: {
        position: "fixed",
        bottom: theme.spacing(6),
        right: theme.spacing(7),
        backgroundColor: amber[500],
        "&:hover": {
            backgroundColor: amber[600],
        },
    }, 
}))

export default function ViewPrograms() {
    const { otpId, targetId } = useParams()
    const { GetProgramsForTarget, obsoleteProgram, getSingleTarget } = useDb()
    const classes = useStyles()
    const history = useHistory()

    const programs = GetProgramsForTarget(otpId, targetId)
    const { currentUser } = useAuth()
    const [anchorEl, setAnchorEl] = useState(null)

    // Full View Component (Route)
    const [target, setTarget] = useState(null)
    const [loaded, setLoaded] = useState(false)

    // Edits
    const [editOpen, setEditOpen] = useState(false)
    const [selectedProgram, setSelectedProgram] = useState(null)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const handleEdit = (p) => {
        console.log(p)
        setSelectedProgram(p)
        setEditOpen(true)
    }

    // Pagination
    const itemsPerPage = 10; // <- Not state but related to below pagination
    const [page, setPage] = useState(1);
    const [noOfPages] = useState(
        Math.ceil(programs.length / itemsPerPage)
    );

    const handleDelete = async (res) => {
        if(res) {
            await obsoleteProgram(target.otpId, target.id, selectedProgram.id)
        }
    }

    // Adding
    const [addOpen, setAddOpen] = useState(false)

    // Closing Programs
    const [closeOpen, setCloseOpen] = useState(false)
    const handleProgramClose = (p) => {
        setSelectedProgram(p)
        setCloseOpen(true)
    }
    
    const firstLoad = async () => {
        const t = await getSingleTarget(otpId, targetId)
        setTarget(t)
        setLoaded(true)
    }

    if(!loaded) {
        firstLoad()
        return <LinearProgress />
    }
    else 
    return (
        <Card>
            <CardContent>
                <Typography>Programs for Target # {target.targetNo}</Typography>
                <br/>
                <Link to={`/otp/${otpId}/targets`}>
                    <Button variant="contained" color="primary" startIcon={<ArrowLeftOutlined />}>Objective {target.otpNumber}</Button>
                </Link>
                <br/><br/>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead> 
                            <TableRow>
                                <TableCell>Program #</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Responsible HOD</TableCell>
                                <TableCell>Program Executor</TableCell>
                                <TableCell>Target Date</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                programs && programs
                                .slice((page -1) * itemsPerPage, page * itemsPerPage)
                                .map(p => {
                                    if (!p.obsolete) {
                                        return (
                                            <TableRow key={p.id}>
                                                <TableCell>{p.programNo}</TableCell>
                                                <TableCell>{p.description}</TableCell>
                                                <TableCell>{`${p.responsible.firstName} ${p.responsible.lastName}`}</TableCell>
                                                <TableCell>{`${p.closer.firstName} ${p.closer.lastName}`}</TableCell>
                                                <TableCell>{moment(p.targetDate.toDate()).format('DD/MM/YYYY')}</TableCell>
                                                <TableCell>{ p.open ? 
                                                    p.targetDate.toDate() > new Date()
                                                    ? <Button variant="contained" className={classes.amberBtn}>Open</Button>
                                                    : <Button variant="contained" className={classes.redBtn}>Overdue</Button>
                                                : <Button variant="contained" className={classes.greenBtn}>Closed</Button>}</TableCell>
                                                <TableCell align="right">
                                                    { currentUser.uid === p.responsible.id || currentUser.companyAdmin ? 
                                                        !p.open 
                                                        ?
                                                        null
                                                        :
                                                            !p.currentProgress || p.currentProgress < p.programTarget
                                                            ?
                                                            <Button disabled startIcon={<MeetingRoom />} variant="contained">
                                                                Close
                                                            </Button>
                                                            :
                                                            <Button startIcon={<MeetingRoom />} variant="contained" color="primary" onClick={() => handleProgramClose(p)}>
                                                                Close
                                                            </Button>
                                                        
                                                    : null }
                                                    <Link to={`/program-attachments/${target.otpId}/${target.id}/${p.id}`}>
                                                        <IconButton color="primary" size="large">
                                                            <AttachFile />
                                                        </IconButton>
                                                    </Link>
                                                    <IconButton onClick={() => handleEdit(p)} color="primary" size="large">
                                                        <Edit />
                                                    </IconButton>
                                                    <Link to={`/otp-program/${otpId}/${targetId}/${p.id}`}>
                                                        <IconButton color="primary" size="large">
                                                            <Search />
                                                        </IconButton>
                                                    </Link>
                                                    
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }
                                    else return null
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
            <CardActions>
                <Button onClick={() => setAddOpen(true)} color="primary" variant="contained" startIcon={<AddBox />}>Add Program</Button>
                <Pagination
                    count={Math.ceil(programs.length / itemsPerPage)}
                    page={page}
                    onChange={(e, val) => setPage(val)}
                    defaultPage={1}
                    color="primary"
                    size="large"
                    showFirstButton
                    showLastButton
                /> 
            </CardActions>
            {
                selectedProgram
                ?
                <EditProgram open={editOpen} closeDialog={() => setEditOpen(false)} program={selectedProgram} target={target} />
                :
                null
            }
            {
                selectedProgram
                ?
                <ConfirmSomething open={deleteOpen} closeDialog={() => setDeleteOpen(false)} response={handleDelete} message="Are you sure you want to obsolete this program?" />
                :
                null
            }
            <AddProgram open={addOpen} closeDialog={() => setAddOpen(false)} target={target}/>
            <CloseProgram target={target} program={selectedProgram} open={closeOpen} closeDialog={() => setCloseOpen(false)} />

            <FloatingHelpComponent
                name="How to Create an Effective Program" 
                videoPath="otp_manager"
                videoIndex={4}
            />
        </Card>
    );
}
