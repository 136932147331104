import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material'
import { Save } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import { textFieldProps } from '../../shared/constants'

export default function EditDepartment({ dept, open, closeDialog }) {
    const { updateDepartment } = useDb()

    const [state, setState] = useState({
        code: dept.code,
        name: dept.name
    })
    const [updated, setUpdated] = useState(false)

    useEffect(() => {
        return setState({
            code:dept.code,
            name: dept.name
        })
    }, [open])
    

    // Action State
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleClose = () => {
        setLoading(false)
        setUpdated(false)
        closeDialog()
    }

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
        setUpdated(true)
    }

    const handleSubmit = async () => {
        setError(null)
        setLoading(false)
        try {
            await updateDepartment(dept.id, state).then(() => {
                handleClose()
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogContent>
                <TextField
                    {...textFieldProps}
                    label="Department Code"
                    value={state.code}
                    onChange={handleChange}
                    name="code"
                />
                <TextField
                    {...textFieldProps}
                    label="Department Name"
                    value={state.name}
                    onChange={handleChange}
                    name="name"
                />
            </DialogContent>
            <DialogActions>
                { error ? <span className="iso-error">{error}</span> : null }
                <Button disabled={ loading } color="secondary" onClick={handleClose}>Cancel</Button>
                <Button disabled={ loading || !updated } color="primary" variant="contained" startIcon={<Save />} onClick={handleSubmit}>Update</Button>
            </DialogActions>
        </Dialog>
    )
}
