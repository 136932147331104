import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import { Save } from "@mui/icons-material";
import React, { useState } from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import { textFieldProps } from "../../shared/constants";

export default function CreateSourceForm({ open, closeDialog }) {
  const { createSource } = useDb();

  const [sourceName, setSourceName] = useState("");
  const [rootCauseRequired, setRootCauseRequired] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await createSource({
        sourceName,
        timestamp: new Date(),
        rootCauseRequired,
      }).then(() => {
        setLoading(false);
        closeDialog();
      });
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={closeDialog} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            {...textFieldProps}
            label={"Source Name"}
            onChange={(e) => setSourceName(e.target.value)}
          />

          <FormControlLabel
            control={
              <Switch
                checked={rootCauseRequired}
                onChange={(e) => setRootCauseRequired(e.target.checked)}
                name="rootCauseRequired"
                color="primary"
              />
            }
            label="Root Cause Analysis Required"
          />
        </DialogContent>
        <DialogActions>
          <span className={"iso-error"}>{error}</span>&nbsp;
          <Button color="secondary" onClick={closeDialog}>
            Cancel
          </Button>
          <Button
            type={"submit"}
            color="primary"
            variant={"contained"}
            startIcon={<Save />}
            disabled={loading}
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
