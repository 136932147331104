import { Avatar, Button, ButtonGroup, Card, CardActions, CardContent, CardHeader, CircularProgress, IconButton, Input, InputAdornment, TextField } from '@mui/material'
import { AddBox, ArrowBack, Edit, Info } from '@mui/icons-material'
import React, { useState } from 'react'
import { useParams, Link, useHistory } from 'react-router-dom'
import { useDb } from '../../../../contexts/DatabaseContext'
import BackLink from '../../../shared/BackLink'
import { textFieldProps } from '../../../shared/constants'
import AddBlraRisk from '../new-risk/AddBlraRisk'
import BlraHazardRisks from './BlraHazardRisks'

export default function HazardDetails() {
    const { blraId, hazardId } = useParams()
    const history = useHistory()
    const { getHazard, updateHazard, getBlraName } = useDb()
    const [hazard, setHazard] = useState()
    const [addOpen, setAddOpen] = useState(false)
    const [blra, setBlra] = useState(null)
    
    const [hazardName, setHazardName] = useState()
    const [editing, setEditing] = useState(false)
    
    const handleEditSave = async () => {
        try {
            await updateHazard(blraId, hazardId, { name: hazardName })
            setEditing(false)
        }
        catch(err) {
            console.log(err.message)
        }
    }
    
    const handleEditCancel = () => {
        setHazardName(hazard.name)
        setEditing(false)
    }

    const firstLoad = async () => {
        const h = await getHazard(blraId, hazardId)
        const blraName = await getBlraName(blraId)
        setBlra(blraName)
        setHazardName(h.name)
        setHazard(h)
    }

    console.log(hazard)

    if(!hazard) {
        firstLoad()
        return <CircularProgress />
    }
    else return (
        <div>
            {
                editing
                ?
                <Input
                    type="text"
                    variant="outlined"
                    style={{ padding: 10, width: '50%'}}
                    label="Hazard Name"
                    value={hazardName}
                    onChange={(e) => setHazardName(e.target.value)}
                    endAdornment={
                        <InputAdornment position="end">
                            <ButtonGroup>
                                <Button
                                    onClick={handleEditCancel}
                                >Cancel</Button>
                                <Button
                                    onClick={handleEditSave}
                                >Save</Button>
                            </ButtonGroup>
                        </InputAdornment>
                    }
                />
                :
                <h1>Hazard: {hazardName} <IconButton onClick={() => setEditing(true)} size="large"><Edit /></IconButton></h1>
            }
            <BackLink route={`/risk-manager/baselines/baseline-process-steps/${blraId}/${hazard.process}/Process`} />
            <Card>
                <CardHeader
                    title="Risks"
                    avatar={<Avatar>
                        <Info />
                    </Avatar>}
                    action={<Button
                        variant="contained"
                        startIcon={<AddBox />}
                        color="primary"
                        onClick={() => setAddOpen(true)}
                    >
                        Add Risk
                    </Button>}
                />
                <CardContent>
                    <BlraHazardRisks 
                        blraId={blraId}
                        hazardId={hazardId}
                    />
                </CardContent>
            </Card>

            <AddBlraRisk
                open={addOpen}
                close={() => setAddOpen(false)}
                blraId={blraId}
                hazardId={hazardId}
            />
        </div>
    );
}
