import React, { useState } from 'react';
import PDF from '@mikecousins/react-pdf';
import { AppBar, Button, Dialog, IconButton, Toolbar, Typography } from '@mui/material'
import { Pagination } from '@mui/material';
import { Close } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';


export default function PdfView({ pdfFile, open, closeDialog }) {
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState(null)
    const [error, setError] = useState(null)

    const onDocumentComplete = (pdfInfo) =>{
        setPages(pdfInfo.numPages)
    }
    return (
        <Dialog fullScreen open={open} onClose={closeDialog} >
            <AppBar>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={closeDialog}
                        aria-label="close"
                        size="large">
                        <Close />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div className={'iso-pdf-wrapper'}>
                {
                    error
                    ?
                    <Typography>{error}</Typography>
                    :
                    <div>
                        <br/>
                        <PDF
                            page={page}
                            file={pdfFile}
                            onDocumentLoadSuccess={onDocumentComplete}
                            onDocumentError={(err) => setError(err)}
                        /><br/>
                        {page > 1 ? <Button startIcon={<FontAwesomeIcon icon={faArrowLeft}/>} variant={'contained'} color={'primary'} onClick={() => setPage(page-1)}>Previous Page</Button> : null }
                        &nbsp;&nbsp;&nbsp;
                        {
                            pages > page
                            ?
                            <Button endIcon={<FontAwesomeIcon icon={faArrowRight}/>} variant={'contained'} color={'primary'} onClick={() => setPage(page+1)}>Next Page</Button>
                            :
                            null
                        }
                    </div>
                }
                
            </div>
        </Dialog>
    );
}
