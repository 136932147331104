import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, TextField, Typography } from '@mui/material'
import { Save } from '@mui/icons-material'
import React, { useState } from 'react'
import { selectFieldProps, textFieldProps } from '../../shared/constants'
import { useDb } from '../../../contexts/DatabaseContext'
import Select from 'react-select'

export default function AddFacilitator({ open, closeDialog }) {
    
    const { facilitatorCheck, GetTrainingProviders, addFacilitator } = useDb()
    const providers = GetTrainingProviders()
    
    const [state, setState] = useState({})
    const [newUser, setNewUser] = useState(false)
    const [user, setUser] = useState(null)
    const [checkDone, setCheckDone] = useState(false)

    // Action State
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    
    const handleClose = () => {
        setState({})
        setError(null)
        setLoading(false)
        setNewUser(false)
        setCheckDone(false)
        closeDialog()
        setUser(null)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        setError(null)
        if(newUser) {
            // Trigger the user creation flow
            try {
                await addFacilitator(state, newUser).then(() => {
                    handleClose()
                })
            }
            catch(err) {
                setError(err.message)
                setLoading(false)
            }
        }
        else {
            // Just add as a facilitator
            try {
                await addFacilitator(user, newUser).then(() => {
                    handleClose()
                })
            }
            catch(err) {
                setError(err.message)
                setLoading(false)
            }
        }
        setLoading(true)
        setError(null)
        
    }

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const handleCheckbox = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.checked
        })
    }

    const handleSelect = (v, e) => {
        setState({
            ...state,
            [e.name]: v.value
        })
    }

    const handleCheck = async () => {
        setLoading(true)
        setError(null)
        try {
            const result = await facilitatorCheck(state.email)
            if(result === null) {
                setNewUser(true)
                setCheckDone(true)
                setLoading(false)
            }
            else {
                setUser(result)
                setCheckDone(true)
                setLoading(false)
            }
        }
        catch(err) {
            console.log(err)
            setError(err.message)
            setLoading(false)
        }
    }
    
    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <form onSubmit={handleSubmit}>
                <DialogTitle>Add Training Facilitator</DialogTitle>
                <DialogContent>
                    <TextField
                        {...textFieldProps}
                        label="Email"
                        type="email"
                        name="email"
                        required
                        onChange={handleChange}
                    />
                    <Button disabled={ loading || !state.email } onClick={handleCheck} variant="contained" color="primary" size="small">Check Availability</Button>
                    {
                        newUser && checkDone
                        ?
                        <>
                            <br/><br/>
                            <Divider />
                            <br/>
                            <Typography>Create a New User</Typography>
                            <TextField
                                {...textFieldProps}
                                label="First Name"
                                name="firstName"
                                required 
                                onChange={handleChange}
                            />
                            <TextField
                                {...textFieldProps}
                                label="Last Name"
                                name="lastName"
                                required
                                onChange={handleChange}
                            />
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={state.external}
                                    onChange={handleCheckbox}
                                    name="external"
                                    color="primary"
                                />
                                }
                                label="External Facilitator"
                            />
                            {
                                state.external
                                ?
                                <Select
                                    options={providers}
                                    {...selectFieldProps}
                                    name="provider"
                                    onChange={handleSelect}
                                    placeholder="Select Training Provider"
                                />
                                :
                                null
                            }
                        </>
                        :
                        null
                    }
                    {
                        !newUser && checkDone
                        ?
                        <>
                            <br/><br/>
                            <Divider />
                            <br/>
                            <Typography>Existing User Found</Typography>
                        </>
                        :
                        null
                    }
                    { error ? <span className="iso-error">{error}</span> : null }
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={handleClose}>Cancel</Button>
                    <Button 
                        disabled={ loading || !checkDone  }
                        startIcon={<Save />} 
                        variant="contained" 
                        color="primary" 
                        type="submit"
                    >Add Facilitator</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
