import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material'
import { Save } from '@mui/icons-material'
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import { textFieldProps } from '../../shared/constants'

export default function EditMetric({ open, closeDialog, m }) {
    const { updateOtpMetric } = useDb()

    // Action State
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const handleChange = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }
    
    const handleClose = () => {
        setLoading(false)
        setError(null)
        closeDialog()

    }

    const [state, setState] = useState({
        metricName: m.metricName,
        metricUnit: m.metricUnit    
    })

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        setError(null)
        try {
            await updateOtpMetric(m.id, state).then(() => {
                handleClose()
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(true)
        }
    }

    

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        {...textFieldProps}
                        required
                        label="Metric Name"
                        onChange={handleChange}
                        name="metricName"
                        value={state.metricName}
                    />
                    <TextField
                        {...textFieldProps}
                        required
                        label="Metric Unit"
                        onChange={handleChange}
                        name="metricUnit"
                        value={state.metricUnit}
                    />
                    { error ? <span className="iso-error">{error}</span> : null }
                </DialogContent>
                <DialogActions>
                    <Button disabled={ loading } color="secondary" onClick={handleClose}>Cancel</Button>
                    <Button disabled={ loading } color="primary" type="submit" variant="contained" startIcon={<Save />}>Submit</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
