import { Button, Dialog, DialogActions, DialogContent, IconButton } from '@mui/material'
import { Add, ArrowForward, Delete } from '@mui/icons-material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import moment from 'moment'
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid'
import { useAuth } from '../../../contexts/AuthContext'

export default function AuditChecklists() {
    const { GetChecklists, deleteCheckList } = useDb()
    const { currentUser } = useAuth()
    const lists = GetChecklists()
    const [open, setOpen] = useState(false)
    const [id, setId] = useState(null)

    const rows = lists && lists.map(list => ({
        id: list.id,
        created: moment(list.created.toDate()).format('DD/MM/YYYY'),
        type: list.type,
        title: list.title
    }))

    const handleDelete = async () => {
        await deleteCheckList(id)
        setOpen(false)
    }

    const columns = [
        { field: 'title', headerName: 'Title' , width: 200 },
        { field: 'type', headerName: 'Type' , width: 200 },
        { field: 'created', headerName: 'Created' , width: 200 },
        { 
            field: 'action', 
            headerName: 'View', 
            width: 240,
            renderCell: (params) => {
                return (
                    <Link to={`/audit-manager/checklists/builder/${params.row.id}`}>
                        <IconButton size="large">
                            <ArrowForward />
                        </IconButton>
                    </Link>
                );
            }
        },
        { 
            field: 'delete', 
            headerName: 'Delete', 
            width: 240,
            hide: !currentUser.companyAdmin,
            renderCell: (params) => {
                return (
                    <IconButton
                        onClick={() => {
                                setId(params.row.id)
                                setOpen(true)
                            }}
                        size="large">
                        <Delete />
                    </IconButton>
                );
            }
        },
    ]

    return (
        <div>
            <h1>Checklists</h1>
            <div className="back-link">
                <Link
                    to="/audit-manager/checklists/create"
                >
                    <Button
                        variant="contained"
                        startIcon={<Add />}
                        color="primary"
                    >
                        New Checklist
                    </Button>
                </Link>
            </div>
            <IsoDataGrid
                data={rows}
                columns={columns}
            />
            <Dialog open={open} fullWidth onClose={() => setOpen(false)}>
                <DialogContent>
                    <h2>Are you sure</h2>
                    <h4><i>This action cannot be undone</i></h4>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='primary' onClick={handleDelete}>Delete</Button>
                    <Button variant='outlined' color='secondary' onClick={() => setOpen(false)}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
