import { Button, Card, CardActions, CardContent, CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import { ArrowBack } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'

export default function ViewInspections() {

  const currentURL = window.location.href

  const { inspectionId } = useParams()
  const { GetInspectionSectionData, getInspection, generateSingleAuditsInspectionPdf } = useDb()
  const history = useHistory()
  const inspectionData = GetInspectionSectionData(inspectionId)
  const [inspection, setInspection] = useState()
  const [loading, setLoading] = useState(true)

  useEffect( async () => {
    const ins = await getInspection(inspectionId)
    console.log(ins)
    setInspection(ins)
    setLoading(false)
  }, [inspectionId])

  console.log(inspectionData)
  console.log(inspection)

  return (
    <>
      {
        loading
        ?
        <CircularProgress />
        :
        <>
          {
            inspectionData && inspectionData.length > 0 
            ?
            <>
            <Button startIcon={<ArrowBack />} variant='contained' color='primary' onClick={() => history.goBack()}>Back</Button>
            {/* Inspection Info */}
            <Card elevation={3} style={{marginBottom: '20px', marginTop: '10px'}}>
              <CardContent>
                <h3>Title: {inspection.title}</h3>
                <p><b>Status:</b> {inspection.status}</p>
                <p><b>Assigned To:</b> {inspection.assignedTo.label}</p>
              </CardContent>
            </Card>
            {/* Inspection Details */}
            <Card elevation={3}>
              <CardContent>
                {
                  inspectionData && inspectionData.map(i => (
                    <>
                      <h2>{i.sectionName}</h2>
                      {
                        i.data.map((d, i) => (
                          <div>
                            <span><b>{d.name ? d.name : d.field ? d.field : 'No Question Recorded'}</b> <br /></span>
                            {
                              d.type === "checkbox"
                              ?
                              d.value.map((val, i) => (
                                <span>{[Object.keys(val)[0]]} <br /></span>
                              ))
                              :
                              null
                            }
                            {
                              d.type === "radio"
                              ?
                              <span>{[Object.values(d.value)[0]]} <br /></span>
                              :
                              null
                            }
                            {
                              d.type === "text"
                              ?
                              <span>{d.value} <br /></span>
                              :
                              null
                            }
                            {
                              d.type === "file"
                              ?
                              <span><img src={d.value} alt="Image" width={200} /><br /></span>
                              :
                              null
                            }
                            {
                              d.issueValue
                              ?
                              <span>Issue: <span style={{color: 'red'}}>{d.issueValue}</span><br /></span>
                              :
                              null
                            }
                            {
                              d.commentValue
                              ?
                              <span>Comment: <span style={{color: 'green'}}>{d.commentValue}</span><br /></span>
                              :
                              null
                            }
                          </div>
                        ))
                      }
                      <br />
                    </>
                  ))
                }
              </CardContent>
              <CardActions className='iso-right'>
                <Button variant='contained' color='primary' onClick={() => generateSingleAuditsInspectionPdf(inspectionData)} >Export to PDF</Button>
              </CardActions>
            </Card>
            </>
            :
            'No Results' 
          }
        </>
      }
    </>
  )
}
