import { Button, Card, CardActions } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import NewAttendedReport from './NewAttendedReport'

export default function BookedVsAttended() {
    const history = useHistory()
    const [addOpen, setAddOpen] = useState(false)

    return (
        <div>
            <h1>Booked vs. Attended Reports</h1>
            <div className="back-link">
                <Button onClick={() => history.goBack()} variant="contained" color="primary" startIcon={<ArrowBack />}>Back</Button>
            </div>
            <Card>
                <CardActions>
                    <Button onClick={() => setAddOpen(true)} color="primary" variant="contained">New Report</Button>

                </CardActions>
                
            </Card>

            <NewAttendedReport
                open={addOpen}
                close={() => setAddOpen(false)}
            />
        </div>
    )
}
