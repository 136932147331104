import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDb } from "../../../contexts/DatabaseContext";
import { LinearProgress } from "@mui/material";
import MocRequestStep from "./phase-steps/MocRequestStep";

export default function Moc() {
  const { mocId } = useParams();
  const [moc, setMoc] = useState();
  const { getCompanyDoc } = useDb();

  const getMoc = async () => {
    const mocDoc = await getCompanyDoc("moc", mocId);
    setMoc(mocDoc);
  };

  useEffect(() => {
    return getMoc();
  }, [mocId]);

  return !moc ? (
    <LinearProgress />
  ) : (
    <div>
      <MocRequestStep moc={moc} getMoc={getMoc} setMoc={setMoc} />
    </div>
  );
}
