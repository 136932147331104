import { Button, ButtonGroup, Card, CardActions, CardContent, Dialog, DialogActions, DialogContent, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { AddBox, ArrowLeftSharp, Delete, Search } from '@mui/icons-material'
import React, { useState } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import { useAuth } from '../../../contexts/AuthContext'
import { useDb } from '../../../contexts/DatabaseContext'
import BulkFileUploader from '../../shared/BulkFileUploader'
import moment from 'moment'
import { Pagination } from '@mui/material';
import FileDialog from '../../shared/FileDialog'

export default function ProgramAttachments() {
    const { otpId, targetId, programId } = useParams()
    const { currentUser } = useAuth()
    const { addProgramAttachment, GetProgramAttachments, deleteProgramAttachment } = useDb()
    const [uploadOpen, setUploadOpen] = useState(false)
    const [fileUrl, setFileUrl] = useState(null)
    const [file, setFile] = useState(null)
    const [fileDialogOpen, setFileDialogOpen] = useState(false)
    const attachments = GetProgramAttachments(otpId, targetId, programId)
    const history = useHistory()
    // Action State
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleUploadClose = () => {
        setUploadOpen(false)
        setError(null)
    }

    const getFiles = async (files) => {
        setError(null)
        await files.forEach(async f => {
            await getFile(f)
        })
    }

    const getFile = async (fileObj) => {
        setFileUrl(fileObj.url)
        setFile(fileObj.fileName)
        try {
            await addProgramAttachment(otpId, targetId, programId, fileObj.url, fileObj.fileName)
        }
        catch(err) {
            setError(err.message)
        }
    }

    const openFileDialog = (fUrl) => {
        setFileUrl(fUrl)
        setFileDialogOpen(true)
    }

    const handleDelete = async (attachmentId) => {
        try {
            await deleteProgramAttachment(otpId, targetId, programId, attachmentId)
        }
        catch(err) {
            setError(err.message)
        }
    }

    // Pagination
    const itemsPerPage = 10; // <- Not state but related to below pagination
    const [page, setPage] = useState(1);
    const [noOfPages] = useState(
        Math.ceil(attachments.length / itemsPerPage)
    );

    return (
        <div>
            <h1>Program Attachments</h1>
                <Button onClick={() => history.goBack()} startIcon={<ArrowLeftSharp />} color="primary" variant="contained">
                    Back 
                </Button>
            <br/><br/>
            <Card>
                <CardContent>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>File Name</TableCell>
                                    <TableCell>Upload Date</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    attachments && attachments
                                    .slice((page -1) * itemsPerPage, page * itemsPerPage)
                                    .map(a => {
                                        return (
                                            <TableRow key={a.id}>
                                                <TableCell>{a.fileName}</TableCell>
                                                <TableCell>{moment(a.timestamp.toDate()).format('DD/MM/YYYY')}</TableCell>
                                                <TableCell align="right">
                                                    <ButtonGroup>
                                                        <IconButton onClick={() => openFileDialog(a.url)} color="primary" size="large">
                                                            <Search />
                                                        </IconButton>
                                                        {
                                                            currentUser.companyAdmin 
                                                            ?
                                                            <IconButton onClick={() => handleDelete(a.id)} color="secondary" size="large">
                                                                <Delete />
                                                            </IconButton>
                                                            :
                                                            null
                                                        }
                                                    </ButtonGroup>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
                <CardActions>
                    <Button onClick={() => setUploadOpen(true)} variant="contained" color="primary" startIcon={<AddBox />}>
                        Add Files
                    </Button>
                    <Pagination
                        count={Math.ceil(attachments.length / itemsPerPage)}
                        page={page}
                        onChange={(e, val) => setPage(val)}
                        defaultPage={1}
                        color="primary"
                        size="large"
                        showFirstButton
                        showLastButton
                    /> 
                    { error ? <span className="iso-error">Error: {error}</span> : null }
                </CardActions>
            </Card>

            <Dialog open={uploadOpen} onClose={handleUploadClose} maxWidth="sm" fullWidth>
                <DialogContent>
                    <BulkFileUploader getFiles={getFiles} userId={currentUser.uid} setBusy={e => setLoading(e)} />
                </DialogContent>
                <DialogActions>
                    { error ? <span className="iso-error">Error: {error}</span> : null }
                    <Button color="secondary" onClick={handleUploadClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {
                fileUrl 
                ?
                <FileDialog file={fileUrl} open={fileDialogOpen} closeDialog={() => setFileDialogOpen(false)}/>
                :
                null
            }
        </div>
    );
}
