import { Avatar, Card, CardContent, CardHeader, IconButton } from '@mui/material'
import { ArrowForward, Videocam } from '@mui/icons-material'
import React from 'react'
import { Link } from 'react-router-dom'

export default function VideoCategory({ cat }) {
    return (
        <Card>
            <CardHeader 
                title={cat.label}
                avatar={
                    <Avatar>
                        <Videocam />
                    </Avatar>
                }
                action={
                    <Link to={`/video-cat/${cat.id}`}>
                        <IconButton size="large">
                            <ArrowForward />
                        </IconButton>
                    </Link>
                }
            />
        </Card>
    );
}
