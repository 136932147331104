import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material'
import { Save } from '@mui/icons-material'
import React, { useState } from 'react'
import { useDb } from '../../../../contexts/DatabaseContext'
import { textFieldProps } from '../../../shared/constants'

export default function CoEditPry({ open, closeDialog, coId, pry }) {
    const { mgmtUpdatePriority } = useDb()
    
    const [state, setState] = useState({
        days: pry.days
    })

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const handleClose = () => {
        setState({})
        setLoading(false)
        setError(null)
        closeDialog()
    }

    const handleSubmit = async () => {
        setError(null)
        setLoading(true)
        try {
            await mgmtUpdatePriority(coId, pry.id, state.days).then(() => {
                handleClose()
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogContent>
                <TextField
                    {...textFieldProps}
                    label="Days"
                    onChange={e => setState({...state, days: e.target.value})}
                    type="number"
                    value={state.days}
                />
            </DialogContent>
            <DialogActions>
                { error ? <span className="iso-error">{error}</span> : null }
                
                <Button disabled={ loading } color="secondary" onClick={handleClose}>Cancel</Button>
                <Button disabled={ loading } color="primary" onClick={handleSubmit} variant="contained" startIcon={<Save />}>Confirm</Button>
            </DialogActions>
        </Dialog>
    )
}
