import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import {
  ArrowForward,
  AttachFile,
  Info,
  Remove,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import React from "react";
import MocRisk from "../change-request/MocRisk";
import FileUploader from "../../shared/FileUploader";

export default function MocSummary({
  moc,
  props,
  readOnly,
  toggleReadOnly,
  approvalView,
}) {
  const { title, setMoc, handleAdvance, handleUpdate } = props;

  const getFileObj = async (fileObj) => {
    await setMoc({
      ...moc,
      attachments: moc.attachments ? [...moc.attachments, fileObj] : [fileObj],
    });
  };

  const handleAttachmentDelete = async (index) => {
    let mocAttachments = [...moc.attachments];
    mocAttachments.splice(index, 1);
    setMoc({
      ...moc,
      attachments: mocAttachments,
    });
    await handleUpdate({
      attachments: mocAttachments,
    });
  };

  return (
    <div>
      <Card>
        <CardHeader
          title={title}
          avatar={
            <Avatar>
              <Info />
            </Avatar>
          }
          subheader={`Date Iniated: ${moc.createdAt.toDate().toDateString()}`}
        />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography>
                MOC ID: <b>{moc.mocNumber}</b>
              </Typography>
              <Typography>MOC Title: {moc.title}</Typography>
              <Typography>
                Sites Affected:{" "}
                {moc.sites &&
                  moc.sites.map((site, index) => (
                    <Chip key={index} label={site.label} />
                  ))}
              </Typography>
              <Typography>
                Departments Affected:{" "}
                {moc.departments &&
                  moc.departments.map((dept, index) => (
                    <Chip key={index} label={dept.label || ""} />
                  ))}
              </Typography>
              <Typography>Permanence: {moc.permanence.label}</Typography>
              <Typography>Description: {moc.description}</Typography>
              {approvalView && (
                <Card>
                  <CardHeader
                    title="Risk Assessment"
                    avatar={
                      <Avatar>
                        <Info />
                      </Avatar>
                    }
                    subheader={`Risk Level: ${
                      moc.initialCons * moc.initialLike
                    }`}
                    action={
                      <IconButton onClick={toggleReadOnly} size="large">
                        {!readOnly ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    }
                  />
                </Card>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Requested By: {moc.requestedBy?.label}</Typography>
              <Typography>Prepared By: {moc.preparedBy?.label}</Typography>
              <Card>
                <CardHeader
                  title="Attachments"
                  avatar={
                    <Avatar>
                      <AttachFile />
                    </Avatar>
                  }
                />
                <CardContent>
                  {moc.attachments &&
                    moc.attachments.map((file, i) => (
                      <Chip
                        style={{ cursor: "pointer" }}
                        label={file.fileName}
                        onClick={() => window.open(file.url, "_blank")}
                        onDelete={() => handleAttachmentDelete(i)}
                        key={i}
                      />
                    ))}
                  {moc.attachments && <div style={{ marginTop: 10 }} />}
                  {!readOnly && <FileUploader getFileObj={getFileObj} />}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          {!readOnly && <MocRisk updates={moc} setUpdates={setMoc} />}
        </CardContent>
        {!approvalView && (
          <CardActions style={{ float: "right" }}>
            <Button
              variant="contained"
              color="primary"
              endIcon={<ArrowForward />}
              onClick={handleAdvance}
            >
              Next
            </Button>
          </CardActions>
        )}
      </Card>
    </div>
  );
}
