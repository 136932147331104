import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {
  ArrowLeft,
  Assessment,
  AssignmentTurnedIn,
  CheckCircle,
  Info,
  ReportProblem,
  School
} from "@mui/icons-material";
import React, { useState } from "react";
import { useAuth } from "../../../../contexts/AuthContext";
import { useDb } from "../../../../contexts/DatabaseContext";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { green } from "@mui/material/colors";

const useStyles = makeStyles(theme => ({
  passed: {
    color: green[500]
  },
  card: {
    marginBottom: 10
  }
}));

export default function EmployeeDashboard() {
  const { currentUser } = useAuth();
  const {
    getEmployee,
    GetEmployeeCompetency,
    GetUserUpcomingTrainingSessions
  } = useDb();
  const history = useHistory();
  const modules = GetEmployeeCompetency(currentUser.uid);
  const classes = useStyles();
  const sessions = GetUserUpcomingTrainingSessions();
  console.log(sessions);

  const [employee, setEmployee] = useState(null);
  const [compState, setCompState] = useState({
    pageLoaded: false
  });

  const firstLoad = async () => {
    const e = await getEmployee(currentUser.uid);
    setEmployee(e);
    setCompState({
      ...compState,
      pageLoaded: true
    });
  };

  if (!compState.pageLoaded) {
    firstLoad();
    return <LinearProgress />;
  } else
    return (
      <div>
        {employee ? (
          <div>
            <h1>
              {employee.firstName} {employee.lastName}
            </h1>

            <Grid container spacing={3}>
              <Grid item xs={12} md={7}>
                <Card>
                  <CardHeader
                    title="Your Upcoming Sessions"
                    avatar={
                      <Avatar>
                        <School />
                      </Avatar>
                    }
                  />
                  <CardContent>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Module</TableCell>
                            <TableCell>Facilitator</TableCell>
                            <TableCell align="right"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {sessions &&
                            sessions.map(s => (
                              <TableRow key={s.id}>
                                <TableCell>
                                  {moment(s.startTime.toDate()).format(
                                    "MM/DD/YYYY"
                                  )}
                                </TableCell>
                                <TableCell>{s.module.name}</TableCell>
                                <TableCell>{s.facilitator.label}</TableCell>
                                <TableCell align="right"></TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={5}>
                <Card>
                  <CardHeader
                    title="Your Required Modules"
                    avatar={
                      <Avatar>
                        <AssignmentTurnedIn />
                      </Avatar>
                    }
                  />
                  <CardContent>
                    {modules &&
                      modules.map(m => (
                        <Card elevation={1} key={m.id} className={classes.card}>
                          <CardHeader
                            title={m.name}
                            avatar={
                              <Avatar>
                                <Info />
                              </Avatar>
                            }
                            subheader={
                              m.nextTrainingDue.toDate() > new Date() ? (
                                `Next Training Due: ${moment(
                                  m.nextTrainingDue.toDate()
                                ).format("DD/MM/YYYY")}`
                              ) : (
                                <span className="iso-error">
                                  {`Training Overdue: ${moment(
                                    m.nextTrainingDue.toDate()
                                  ).fromNow()}`}
                                </span>
                              )
                            }
                            action={
                              m.compLevel > 1 ? (
                                <CheckCircle className={classes.passed} />
                              ) : (
                                <ReportProblem color="secondary" />
                              )
                            }
                          />
                          <CardContent>
                            <Typography>
                              Competency: <b>{m.compLevel}</b>{" "}
                              <span className="iso-small">
                                ({m.assessment.compLevelLabel})
                              </span>
                            </Typography>
                          </CardContent>
                        </Card>
                      ))}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div>
            <Typography>
              You have not been created as an employee. Please contact your
              administrator.
            </Typography>
            <div className="back-link">
              <Button
                onClick={() => history.goBack()}
                color="primary"
                variant="contained"
                startIcon={<ArrowLeft />}
              >
                Back
              </Button>
            </div>
          </div>
        )}
      </div>
    );
}
