import React from 'react'
import { useDb } from '../../../../contexts/DatabaseContext'
import { Link, useHistory } from 'react-router-dom'
import moment from 'moment'
import IsoDataGrid from '../../../shared/data-grid/IsoDataGrid'
import { Button, IconButton } from '@mui/material'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import { useAuth } from '../../../../contexts/AuthContext'

export default function TempDocSearch() {
    const { GetDocs } = useDb()
    const docs = GetDocs()
    const history = useHistory()
    const { currentUser } = useAuth()

    const columns = [
        { 
            field: 'action', 
            headerName: 'Detail', 
            width: 140,
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                        <Link to={`/doc-manager/doc-detail/${params.row.id}`}>
                            <IconButton size="large">
                                <ArrowForward />
                            </IconButton>
                        </Link>
                     </div>
                );
            }
        },
        { field: 'category', headerName: 'Category' , width: 200 },
        { field: 'docCode', headerName: 'Code', width: 200 },
        { field: 'docName', headerName: 'Name', width: 200 },
        { field: 'docType', headerName: 'Type', width: 200 },
        { field: 'department', headerName: 'Department', width: 200 },
        { field: 'owner', headerName: 'Owner', width: 200 },
        { field: 'site', headerName: 'Site', width: 200 },
        { field: 'targetRevDate', headerName: 'Target Rev.', width: 200 }
        
    ]

    let tempArray = []

    docs && docs.map(doc  => {
        if(doc.groups && doc.groups.length > 0) {
            if(currentUser.groups && currentUser.groups.some(r => doc.groups.includes(r))) {
                tempArray.push({
                    id: doc.id,
                    targetRevDate: moment(doc.targetRevDate.toDate()).format('DD/MM/YYYY'),
                    category: doc.category,
                    department: doc.department.label,
                    docCode: doc.docCode,
                    docName: doc.docName,
                    docType: doc.docType.label,
                    owner: `${doc.owner.value.firstName} ${doc.owner.value.lastName}`,
                    site: doc.site.label
                })
            }
        } else {
            tempArray.push({
                id: doc.id,
                targetRevDate: moment(doc.targetRevDate.toDate()).format('DD/MM/YYYY'),
                category: doc.category,
                department: doc.department.label,
                docCode: doc.docCode,
                docName: doc.docName,
                docType: doc.docType.label,
                owner: `${doc.owner.value.firstName} ${doc.owner.value.lastName}`,
                site: doc.site.label
            })
        }
    })

    const rows = tempArray
    
    return (
        <div>
            <div className="back-link">
                <Button onClick={() => history.goBack()} color="primary" variant="contained" startIcon={<ArrowBack />}>Back</Button>
            </div>
            <IsoDataGrid
                data={rows}
                columns={columns}
            />
        </div>
    )
}
