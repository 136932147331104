import { Button, Card, CardActions, CardContent, TextField } from '@mui/material'
import { ArrowLeft, Save } from '@mui/icons-material'
import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import { textFieldProps } from '../../shared/constants'

export default function AddOccupation() {
    const { createOccupation } = useDb()
    const history = useHistory()

    const [state, setState] = useState({})

    // Action State
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setError(null)
        setLoading(true)
        try {
            await createOccupation(state).then(() => {
                setLoading(false)
                history.push('/training-manager/occupations/')
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    return (
        <div>
            <h1>Add Occupation</h1>        
            <Link to={'/training-manager/occupations'}>
                <Button variant="contained" startIcon={<ArrowLeft />} color="primary">Occupations</Button>
            </Link>
            <br/><br/>
            <Card>
                <form onSubmit={handleSubmit}>
                    <CardContent>
                        <TextField
                            {...textFieldProps}
                            label="Occupation Title"
                            onChange={handleChange}
                            name="title"
                            required
                        />
                        <TextField
                            {...textFieldProps}
                            label="Occupation Code (Optional)"
                            onChange={handleChange}
                            name="code"
                        />
                    </CardContent>
                    <CardActions>
                        <Button disabled={ loading } type="submit" variant="contained" color="primary" startIcon={<Save />}>Create Occupation</Button>
                    </CardActions>
                </form>
            </Card>
        </div>
    )
}
