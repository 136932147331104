import { Card, CardActions, CardContent, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { Delete, Edit } from '@mui/icons-material';
import { Pagination } from '@mui/material';
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext';
import EditIsoClass from './EditIsoClass';
import IsoClassDataGrid from './IsoClassDataGrid';

export default function IsoClassList({ isoClasses }) {
    const { deleteIsoClass } = useDb()
    // Pagination
    const itemsPerPage = 10; // <- Not state but related to below pagination
    const [page, setPage] = useState(1);
    const [noOfPages] = useState(
        Math.ceil(isoClasses.length / itemsPerPage)
    );

    const [selectedClass, setSelectedClass] = useState(null)
    const [editOpen, setEditOpen] = useState(false)

    const handleEdit = (isoClass) => {
        setSelectedClass(isoClass)
        setEditOpen(true)
    }

    const [error, setError] = useState(null)

    const handleDelete = async (i) => {
        setError(null)
        try {
            await deleteIsoClass(i.id)
        }
        catch(err) {
            setError(err.message)
        }
    }

    return (
        <Card>
            <CardContent>
                <IsoClassDataGrid isoClasses={isoClasses} handleDelete={handleDelete} handleEdit={handleEdit}/>
            </CardContent>

            { selectedClass ? <EditIsoClass isoClass={selectedClass} open={editOpen} closeDialog={() => setEditOpen(false)}/> : null }
        </Card>
    )
}
