import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { ChatBubble, Save, Warning } from "@mui/icons-material";
import React, { useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { useDb } from "../../../contexts/DatabaseContext";
import { textFieldProps } from "../../shared/constants";
import FileUploader from "../../shared/FileUploader";
import Comment from "./Comment";
import RaiseIssue from "./RaiseIssue";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: 10,
  },
  fieldContainer: {
    marginBottom: 10,
    borderBottom: "1px solid #efefef",
    padding: "10px 0",
    width: "100%",
  },
}));

export default function SectionSubmission({
  checklistId,
  inspectionId,
  sectionId,
  order,
  completeSection,
  sectionName,
}) {
  const classes = useStyles();
  const {
    GetChecklistSectionFields,
    saveSectionSubmission,
    getSectionSubmission,
  } = useDb();
  const fields = GetChecklistSectionFields(checklistId, sectionId);
  const { currentUser } = useAuth();

  const [state, setState] = useState({
    data: [],
    loading: false,
    error: null,
  });
  const [loaded, setLoaded] = useState(false);

  const [snackOpen, setSnackOpen] = useState(false);

  const handleChange = (e, type, i) => {
    let dataArray = state.data;
    dataArray[i] = {
      ...state.data[i],
      textValue: e.target.value,
      field: e.target.name,
    };
    setState({
      ...state,
      data: dataArray,
    });
  };

  const handleCheck = (e, type, name, i, fieldIndex) => {
    let dataArray = state.data;
    let fieldArray = dataArray[i] ? dataArray[i].checkValue : [];
    fieldArray[fieldIndex] = { [e.target.name]: e.target.checked };
    dataArray[i] = {
      ...state.data[i],
      checkValue: fieldArray,
      field: name,
    };
    setState({
      ...state,
      data: dataArray,
    });
  };

  const handleRadio = (e, type, i, name) => {
    let dataArray = state.data;
    dataArray[i] = {
      ...state.data[i],
      radioValue: {
        [e.target.name]: e.target.value,
      },
      field: name,
    };
    setState({
      ...state,
      data: dataArray,
    });
  };

  const handleFile = (url, i, name) => {
    let dataArray = state.data;
    dataArray[i] = {
      ...state.data[i],
      fileValue: url,
      field: name,
    };
    setState({
      ...state,
      data: dataArray,
    });
  };

  const handleSave = async () => {
    setState({
      ...state,
      loading: true,
      error: null,
    });
    try {
      await saveSectionSubmission(
        inspectionId,
        sectionId,
        state,
        order,
        sectionName
      );
      // TODO: Enable / Disable going back or forward until saved
      setSnackOpen(true);
      if (state.data.length === fields.length) {
        completeSection(order);
      }
      setState({
        ...state,
        loading: false,
      });
    } catch (err) {
      setState({
        ...state,
        loading: false,
        error: err.message,
      });
    }
  };

  const [raiseOpen, setRaiseOpen] = useState(false);
  const [selectedField, setSelectedField] = useState();
  const [selectedFieldVal, setSelectedFieldVal] = useState();
  const [selectedIndex, setSelectedIndex] = useState();
  const handleRaiseIssue = (field, i) => {
    setSelectedIndex(i);
    setSelectedField(field);
    setSelectedFieldVal(state.data[i].value);
    setRaiseOpen(true);
  };

  const closeRaise = () => {
    setRaiseOpen(false);
    setSelectedField(null);
    setSelectedIndex(null);
  };

  const handleIssueComplete = (i, issueId, description, field, fieldVal) => {
    let dataArray = state.data;
    dataArray[i] = {
      ...state.data[i],
      field: field,
      issueValue: description,
      issueVal: issueId,
    };
    setState({
      ...state,
      data: dataArray,
    });
  };

  const [commentOpen, setCommentOpen] = useState(false);
  const handleComment = (field, i) => {
    setSelectedIndex(i);
    setSelectedField(field);
    setSelectedFieldVal(state.data[i].value);
    setCommentOpen(true);
  };

  const closeComment = () => {
    setCommentOpen(false);
    setSelectedField(null);
    setSelectedIndex(null);
  };

  console.log(state);

  const handleCommentComplete = (i, comment, field, fieldVal) => {
    let dataArray = state.data;
    dataArray[i] = {
      ...state.data[i],
      field: field,
      commentValue: comment,
    };
    setState({
      ...state,
      data: dataArray,
    });
  };

  console.log(state);

  // Retrieving of data (Form Data Persistence)
  const firstLoad = async () => {
    const data = await getSectionSubmission(inspectionId, sectionId);
    if (data) {
      setState({ data });
    }
    setLoaded(true);
  };
  if (!loaded) {
    firstLoad();
    return <CircularProgress />;
  } else
    return (
      <div>
        {fields &&
          fields.map((f, i) => {
            if (f.type === "text") {
              return (
                <div className={classes.fieldContainer} key={f.id}>
                  <TextField
                    label={f.name}
                    {...textFieldProps}
                    required={f.required}
                    name={f.name}
                    onChange={(e) => handleChange(e, f.type, i)}
                    value={state.data[i] && state.data[i].value}
                  />
                  {state.data[i] && (
                    <div>
                      <Button
                        variant="outlined"
                        color="secondary"
                        startIcon={<Warning />}
                        onClick={() => handleRaiseIssue(f.name, i)}
                      >
                        Raise Issue
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<ChatBubble />}
                        onClick={() => handleComment(f.name, i)}
                      >
                        Comment
                      </Button>
                    </div>
                  )}
                </div>
              );
            }
            if (f.type === "checkbox") {
              return (
                <div className={classes.fieldContainer} key={f.id}>
                  <FormControl>
                    <FormLabel>{f.name}</FormLabel>
                    {f.options &&
                      f.options.map((o, fi) => (
                        <FormControlLabel
                          key={o}
                          label={o}
                          control={
                            <Checkbox
                              onChange={(e) =>
                                handleCheck(e, f.type, f.name, i, fi)
                              }
                              name={o}
                              checked={
                                state.data[i] &&
                                state.data[i].checkValue[fi] &&
                                state.data[i].checkValue[fi][o]
                              }
                            />
                          }
                        />
                      ))}
                  </FormControl>
                  <br />
                  {state.data[i] && (
                    <div>
                      <Button
                        variant="outlined"
                        color="secondary"
                        startIcon={<Warning />}
                        onClick={() => handleRaiseIssue(f.name, i)}
                      >
                        Raise Issue
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<ChatBubble />}
                        onClick={() => handleComment(f.name, i)}
                      >
                        Comment
                      </Button>
                    </div>
                  )}
                </div>
              );
            }
            if (f.type === "radio") {
              return (
                <div className={classes.fieldContainer} key={f.id}>
                  <RadioGroup
                    onChange={(e) => handleRadio(e, f.type, i, f.name)}
                    name={f.name}
                    value={
                      state.data[i] && Object.values(state.data[i].field)[0]
                    }
                  >
                    <FormLabel>{f.name}</FormLabel>
                    {f.options &&
                      f.options.map((o) => (
                        <FormControlLabel
                          key={o}
                          label={o}
                          control={<Radio value={o} />}
                        />
                      ))}
                  </RadioGroup>

                  {state.data[i] && (
                    <div>
                      <Button
                        variant="outlined"
                        color="secondary"
                        startIcon={<Warning />}
                        onClick={() => handleRaiseIssue(f.name, i)}
                      >
                        Raise Issue
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<ChatBubble />}
                        onClick={() => handleComment(f.name, i)}
                      >
                        Comment
                      </Button>
                    </div>
                  )}
                </div>
              );
            }
            if (f.type === "file") {
              return (
                <div className={classes.fieldContainer}>
                  <Typography>{f.file}</Typography>
                  <FileUploader
                    getFileObj={() => console.log("File")}
                    getFilename={() => console.log("File")}
                    getUrl={(url) => handleFile(url, i, f.file)}
                    userId={currentUser.uid}
                  />
                  <br />
                  {state.data[i] && (
                    <div>
                      <Button
                        variant="outlined"
                        color="secondary"
                        startIcon={<Warning />}
                        onClick={() => handleRaiseIssue(f.name, i)}
                      >
                        Raise Issue
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<ChatBubble />}
                        onClick={() => handleComment(f.name, i)}
                      >
                        Comment
                      </Button>
                    </div>
                  )}
                </div>
              );
            }
          })}
        <div style={{ marginBottom: 10 }}>
          <Button
            color="primary"
            variant="contained"
            startIcon={<Save />}
            onClick={handleSave}
            disabled={state.loading}
          >
            Save
          </Button>
          &nbsp;
          {state.error && (
            <Typography color="secondary">{state.error}</Typography>
          )}
          <Snackbar
            open={snackOpen}
            autoHideDuration={5000}
            onClose={() => setSnackOpen(false)}
            message="Form Saved!"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          />
        </div>

        {selectedField && (
          <RaiseIssue
            inspectionId={inspectionId}
            field={selectedField}
            fieldVal={selectedFieldVal}
            open={raiseOpen}
            close={closeRaise}
            completeIssue={handleIssueComplete}
            index={selectedIndex}
          />
        )}
        <Comment
          open={commentOpen}
          close={closeComment}
          completeComment={handleCommentComplete}
          index={selectedIndex}
          field={selectedField}
          fieldVal={selectedFieldVal}
        />
      </div>
    );
}
