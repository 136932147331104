import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material'
import React, { useState } from 'react'
import { textFieldProps } from '../../shared/constants'

export default function Comment({ open, close, field, fieldVal, index, completeComment }) {

  const [state, setState] = useState(null)

  const handleClose = () => {
    close()
  }

  const handleChange = (e) => {
    setState({
        ...state,
        [e.target.name]: e.target.value
    })
    console.log(state)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    completeComment(index, state.comment, field, fieldVal)
    handleClose()
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            {...textFieldProps}
            name="comment"
            onChange={handleChange}
            label="Comment"
            required
            multiline
            rows={8}
          />
        </DialogContent>
        <DialogActions>
        <Button
          color="secondary"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          type="submit"
        >
          Confirm
        </Button>
        </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}
