import { Button, ButtonGroup, Card, CardActions, CardContent, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { AddBox, Delete, Edit } from '@mui/icons-material'
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import FloatingHelpComponent from '../../help/FloatingHelpComponent'
import AddMetric from './AddMetric'
import EditMetric from './EditMetric'

export default function OtpMetrics() {
    const { GetOtpMetrics } = useDb()
    const metrics = GetOtpMetrics()
    const [addOpen, setAddOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [selectedMetric, setSelectedMetric] = useState(null)
    
    const handleEdit = (m) => {
        setSelectedMetric(m)
        setEditOpen(true)
    }
    return (
        <div>
            <h1>Measurement Metrics</h1>
            <Card>
                <CardContent>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Metric Name</TableCell>
                                    <TableCell>Unit</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    metrics && metrics.map(m => {
                                        return (
                                            <TableRow key={m.id}>
                                                <TableCell>{m.metricName}</TableCell>
                                                <TableCell>{m.metricUnit}</TableCell>
                                                <TableCell align="right">
                                                    <ButtonGroup>
                                                        <IconButton onClick={() => handleEdit(m)} size="large">
                                                            <Edit />
                                                        </IconButton>
                                                        {/* <IconButton>
                                                            <Delete />
                                                        </IconButton> */}
                                                    </ButtonGroup>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
                <CardActions>
                    <Button onClick={() => setAddOpen(true)} color="primary" variant="contained" startIcon={<AddBox />}>Add Metric</Button>
                </CardActions>
            </Card>

            <AddMetric open={addOpen} closeDialog={() => setAddOpen(false)} />
            {
                selectedMetric
                ?
                <EditMetric open={editOpen} closeDialog={() => setEditOpen(false)} m={selectedMetric} />
                :
                null
            }

            <FloatingHelpComponent
                name="Creating Measurement Metrics" 
                videoPath="otp_manager"
                videoIndex={2}
            />
        </div>
    );
}
