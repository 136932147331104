import { faPlusSquare, faUserCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    Grid,
    Card,
    CardContent,
    Divider,
    Button,
    List,
    TextField,
    CircularProgress,
    Dialog,
    DialogContent,
    CardActions,
    Typography,
    DialogActions,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Pagination } from '@mui/material';
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import ConfirmRosterDelete from './ConfirmRosterDelete'
import AddRoster from './AddRoster'
import RosterTile from './RosterTile'
import RosterDataGrid from './RosterDataGrid'
import FloatingHelpComponent from '../../help/FloatingHelpComponent'

const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: theme.palette.cardBg
    }
}))

export default function RosterList() {
    const classes = useStyles()
    const { GetRosterList, convertRosterToUser } = useDb()
    const rosters = GetRosterList()
    const [search, setSearch] = useState(null)
    const [open, setOpen] = useState(false)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [viewOpen, setViewOpen] = useState(false)

    const closeDialog = () => {
        setOpen(false)
    }

    // Delete
    const [selectedRoster, setSelectedRoster] = useState(null)
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)

    const handleDelete = (r) => {
        console.log(r)
        setSelectedRoster(r)
        setConfirmDeleteOpen(true)
    }

    const handleOpen = (r) => {
        setSelectedRoster(r)
        setViewOpen(true)
    }

    const deleteComplete = () => {

    }

    const handleConvert = async () => {
        console.log(selectedRoster.uid)
        try {
            setError(null)
            setLoading(true)
            await convertRosterToUser(selectedRoster.id, selectedRoster.uid)
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
                <Card className={classes.card}>
                    <CardContent>
                        <RosterDataGrid data={rosters} handleDelete={handleDelete} handleOpen={handleOpen} />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={5}>
                <Card className={classes.card}>
                    <CardContent>
                        <Button onClick={() => setOpen(true)} variant="contained" color="primary" startIcon={<FontAwesomeIcon icon={faPlusSquare} />}>Add Roster</Button>
                    </CardContent>
                </Card>
                <Dialog maxWidth="sm" fullWidth open={open} onClose={closeDialog}>
                    <DialogContent>
                        <AddRoster closeDialog={closeDialog} />
                    </DialogContent>
                </Dialog>
            </Grid>
            {
                selectedRoster
                ?
                <ConfirmRosterDelete open={confirmDeleteOpen} closeDialog={() => setConfirmDeleteOpen(false)} roster={selectedRoster} deleteComplete={deleteComplete} />
                :
                null
            }
            {
                selectedRoster
                ?
                <Dialog fullWidth maxWidth="sm" open={viewOpen} onClose={() => setViewOpen(false)}>
                    <DialogContent>
                        <Typography>First Name: {selectedRoster.firstName}</Typography>
                        <Typography>Last Name: {selectedRoster.lastName}</Typography>
                        <Typography>Email: {selectedRoster.email}</Typography>
                        <Typography>User Status: {!selectedRoster.userStatus ? 'Roster Only' : 'Active User'}</Typography>
                        { error ? <span className="iso-error">{error}</span> : null }
                        {
                            !selectedRoster.userStatus && selectedRoster.uid ? 
                            <Button disabled={loading} onClick={handleConvert} startIcon={<FontAwesomeIcon icon={faUserCheck}/>} color="primary" variant="contained">Convert to User</Button>
                            :
                            null
                        }

                    </DialogContent> 
                    <DialogActions>
                        {/* { currentUser.companyAdmin ?
                            <Button color="secondary" variant="contained" startIcon={<Delete />}>Delete Roster</Button>
                            :
                            null
                        } */}
                        <Button onClick={() => setViewOpen(false)} color="secondary">Close</Button>
                    </DialogActions>
                </Dialog>
                :
                null
            }

            <FloatingHelpComponent
                name="Managing Rosters" 
                videoPath="master_list"
                videoIndex={7}
            />
        </Grid>
    )
}
