import React, { useEffect, useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import { Redirect, useParams } from 'react-router-dom';
import { Avatar, Button, Card, CardContent, CardHeader, CircularProgress, Grid } from '@mui/material';
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../../contexts/AuthContext'

export default function AcknowledgeDoc() {
    
    const { id } = useParams();
    const { getDocById, acknowledgeDoc, getDocumentInterestedParties } = useDb()
    const { currentUser } = useAuth()
    const [error, setError] = useState('')
    const [doc, setDoc] = useState(null)
    const [parties, setParties] = useState([])
    const [loading, setLoading] = useState(false)
    const [complete, setComplete] = useState(false)
    const [loaded, setLoaded] = useState(false);
    
    const getDocument = async () => {
        const isoDoc = await getDocById(id);
        console.log(isoDoc)
        const intParties = await getDocumentInterestedParties(id);
        console.log(intParties)
        setParties(intParties);
        setDoc(isoDoc);
        setLoaded(true);
    }

    const approve = async () => {
        setLoading(true)
        const result = await acknowledgeDoc(id, currentUser.uid)
        
        if (result !== null) {
            setComplete(true) 
        }
        else {
            setLoading(false)
            setError('Error Acknowledging Document')
        }
    }

    useEffect(() => {
      const unsubscribe = getDocument();
      return unsubscribe;
    }, [id])
    

    if(!loaded) {
        return (
            <CircularProgress />
        )
    }
    else {
        if(
            parties && parties.includes(currentUser.uid) 
            || 
            doc.interestedParties && doc.interestedParties.includes(currentUser.uid)
            ||
            doc.groups?.some(r=> currentUser.groups?.indexOf(r) >= 0)
            )
        {
            return (
                <>
                    {
                        complete ? <Redirect to='/doc-manager/doc-register' /> : null
                    }
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Card>
                                <CardHeader
                                    title={`${doc.docCode}: ${doc.docName}`}
                                    subheader={doc.docType.label}
                                    avatar={<Avatar>{doc.category}</Avatar>}
                                />
                                <CardContent>
                                    <b>Document Owner:</b> {doc.owner.label}<br/>
                                    <b>Department:</b> {doc.department.label}<br/>
                                    <b>Created:</b> {moment(doc.createdDate.toDate()).format('DD/MM/YYYY')}<br/>
                                    <b>Target Revision Date:</b> {moment(doc.targetRevDate.toDate()).format('DD/MM/YYYY')}<br/>
                                    <b>Site:</b> {doc.site.label}<br/>
                                    <b>Disposition Method:</b> {doc.dispositionMethod.label}<br/>
                                    <b>Retention Period:</b> {doc.retentionPeriod.label}<br/>
    
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card>
                                <CardContent>
                                    <a href={doc.docUrl} target="_blank" rel="noopener noreferrer">
                                        <Button startIcon={<FontAwesomeIcon icon={faFile}/>} color="primary" variant="contained">View Document</Button>
                                    </a>
                                    <br/><br/>
                                    <Button disabled={loading} onClick={approve} startIcon={<FontAwesomeIcon icon={faCheckCircle}/>} color="primary" variant="contained">Acknowledge</Button><br/><br/>
                                    
                                    <span style={{ color: 'red '}}>{error}</span>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </>
            )
        }
        else {
            return (
                <Card>
                    <CardContent>
                        You are not an Interested Party on this document. Are you logged into the correct account? 
                    </CardContent>
                </Card>
            )
        }
    }
    
}
