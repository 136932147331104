import React, { useState, useRef } from "react";
import MocSummary from "../MocSummary";
import MocTimeline from "../MocTimeline";
import { Button, CardActions, Grid } from "@mui/material";
import {
  ArrowForward,
  PictureAsPdf,
  ThumbDown,
  ThumbUp,
} from "@mui/icons-material";
import ApprovalBenefits from "./ApprovalBenefits";
import ApprovalCosts from "./ApprovalCosts";
import { useAuth } from "../../../../contexts/AuthContext";
import DeclineReason from "./DeclineReason";
import {
  mocApproved,
  mocDeclined,
} from "../../change-request/phase-steps/mocMessages";
import { useHistory } from "react-router-dom";
import ReactToPrint from "react-to-print";

export default function MocApprovalReport({ moc, props }) {
  const {
    handleAdvance,
    handleUpdate,
    handleGroupNotification,
    notifyAllParties,
    setLoading,
    loading,
  } = props;
  const { currentUser } = useAuth();
  const [readOnly, setReadOnly] = React.useState(true);
  const toggleReadOnly = () => setReadOnly(!readOnly);
  const [declineReason, setDeclineReason] = useState("");
  const [declineOpen, setDeclineOpen] = useState(false);
  const history = useHistory();

  const handleApprove = async () => {
    const approverIndex = moc.approvers.findIndex(
      (obj) => obj.participant === currentUser.uid
    );
    if (approverIndex !== -1) {
      moc.approvers[approverIndex].approved = true;
      const mocApproversUpdated = moc.approvers;

      await handleUpdate({ approvers: mocApproversUpdated });

      // Check if all approvers have approved before calling handleAdvance
      if (mocApproversUpdated.every((approver) => approver.approved)) {
        const msgTemplate = mocApproved(
          moc,
          `${currentUser.firstName} ${currentUser.lastName}`
        );
        await notifyAllParties(msgTemplate);
        handleAdvance();
      } else {
        history.push("/moc-register");
      }
    }
  };

  const handleDecline = async () => {
    setLoading(true);
    try {
      const approverIndex = moc.approvers.findIndex(
        (obj) => obj.participant === currentUser.uid
      );
      moc.approvers[approverIndex].approved = false;
      const mocApproversUpdated = moc.approvers;
      await handleUpdate({
        approvers: mocApproversUpdated,
        declineReason,
        phase: 3,
        currentStepIndex: 0,
      });
      const msgTemplate = mocDeclined(
        moc,
        `${currentUser.firstName} ${currentUser.lastName}`,
        declineReason
      );
      await handleGroupNotification(msgTemplate);
      await notifyAllParties(msgTemplate);
      setDeclineOpen(false);
      setDeclineReason("");
      history.push("/moc-register");
      setLoading(false);
    } catch (err) {
      window.alert(err.message);
      setLoading(false);
    }
  };

  // Add useRef for react-to-print
  const componentRef = useRef();

  return (
    <div>
      <CardActions>
        <Button
          onClick={() => setDeclineOpen(true)}
          variant="contained"
          color="secondary"
          startIcon={<ThumbDown />}
          disabled={loading}
        >
          Decline
        </Button>
        <Button
          onClick={handleApprove}
          variant="contained"
          color="primary"
          startIcon={<ThumbUp />}
          disabled={loading}
        >
          Approve
        </Button>
        <ReactToPrint
          trigger={() => (
            <Button
              startIcon={<PictureAsPdf />}
              variant="contained"
              color="primary"
            >
              Download/Print
            </Button>
          )}
          content={() => componentRef.current}
        />
      </CardActions>
      <div ref={componentRef}>
        <MocSummary
          moc={moc}
          props={props}
          readOnly={readOnly}
          toggleReadOnly={toggleReadOnly}
          approvalView={true}
        />
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <ApprovalBenefits moc={moc} />
          </Grid>
          <Grid item xs={12} md={6}>
            {moc.costs && <ApprovalCosts moc={moc} />}
          </Grid>
        </Grid>
        <br />
        <MocTimeline moc={moc} props={props} approvalView={true} />
      </div>
      <DeclineReason
        open={declineOpen}
        close={() => {
          setDeclineOpen(false);
          setDeclineReason("");
        }}
        declineReason={declineReason}
        setDeclineReason={setDeclineReason}
        handleDecline={handleDecline}
        loading={loading}
      />
    </div>
  );
}
