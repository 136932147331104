import { Container } from "@mui/material";
import React from "react";
import { useAuth } from "../../../../contexts/AuthContext";
import FloatingHelpComponent from "../../../help/FloatingHelpComponent";

export default function DSTrainingMatrix() {
  const { currentUser } = useAuth();
  var params = {
    "ds11.company": currentUser.companyId,
    "ds10.company": currentUser.companyId,
  };
  var paramsAsString = JSON.stringify(params);
  var encodedParams = encodeURIComponent(paramsAsString);
  return (
    <div>
      <h1>Training Reports...</h1>
      <Container>
        <iframe
          width="1200"
          height="1200"
          src={`https://datastudio.google.com/embed/reporting/18f56c0c-8a4f-4b2f-a358-d9228c2424a8/page/g89qC?params=${encodedParams}`}
          frameBorder="0"
        ></iframe>
      </Container>
      <FloatingHelpComponent
        name="Training Reports"
        videoPath="training_manager"
        videoIndex={8}
      />
    </div>
  );
}
