import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material'
import { Save } from '@mui/icons-material'
import React, { useState } from 'react'
import { useDb } from '../../../../contexts/DatabaseContext'
import { textFieldProps } from '../../../shared/constants'

export default function RecordMeasurement({ open, closeDialog, otpId, targetId, programId, measurement, updateProgress, currentProgress }) {
    const { addProgramMeasurement } = useDb()
    
    const [state, setState] = useState({})

    // Action State
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const handleChange = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }
    
    const handleClose = () => {
        setState({})
        setError(null)
        setLoading(false)
        closeDialog()
    }

    const handleSubmit = async e => {
        e.preventDefault()
        setError(null)
        setLoading(true)
        try {
            await addProgramMeasurement(otpId, targetId, programId, state).then(() => {
                updateProgress(currentProgress + parseInt(state.measurementData))
                handleClose()
            })
        }
        catch(err) {
            console.log(err.message)
            setError(err.message)
            setLoading(false)
        }
    }
    
    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField 
                        {...textFieldProps}
                        label={measurement}
                        onChange={handleChange}
                        name="measurementData"
                        required
                        type="number"
                    />
                    <TextField 
                        {...textFieldProps}
                        label="Comment"
                        onChange={handleChange}
                        name="comment"
                        multiline
                        rows={4}
                    />
                    { error ? <span className="iso-error">{error}</span> : null }
                </DialogContent>
                <DialogActions>
                    <Button disabled={ loading } color="secondary" onClick={handleClose}>Cancel</Button>
                    <Button disabled={ loading || !state.measurementData } color="primary" type="submit" variant="contained" startIcon={<Save />}>Submit</Button>
                </DialogActions>

            </form>
        </Dialog>
    )
}
