import { Avatar, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Container, Grid, TextField, Typography } from '@mui/material'
import { Info, Save } from '@mui/icons-material'
import React, { useState } from 'react'
import { useAuth } from '../../../contexts/AuthContext'
import { useDb } from '../../../contexts/DatabaseContext'
import FloatingHelpComponent from '../../help/FloatingHelpComponent'
import { Currencies, selectFieldProps, textFieldProps } from '../../shared/constants'
import FileUploader from '../../shared/FileUploader'
import Select from 'react-select'

export default function CompanySettings() {
    const { getCompanySettings, updateCompanySettings } = useDb()
    const { currentUser } = useAuth()
    const [company, setCompany] = useState()
    const [loaded, setLoaded] = useState(false)
    const [updated, setUpdated] = useState(false)
    console.log(currentUser)

    // Action State
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    
    const firstLoad = async () => {
        const coDetails = await getCompanySettings()
        setCompany(coDetails)
        setLoaded(true)
    }

    const handleCurrencySelect = (e) => {
        console.log(e)
        setUpdated(true)
        setCompany({...company, currency: e})
        console.log(company)
    }

    const handleChange = e => {
        setUpdated(true)
        setCompany({ ...company, [e.target.name]: e.target.value })
    }

    const handleSubmit = async () => {
        setError(null)
        setLoading(true)
        try {
            await updateCompanySettings(company).then(() => {
                setLoading(false)
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    const getUrl = (url) => {
        setCompany({...company, logo: url })
        setUpdated(true)
    }

    const getFilename = (fileName) => {

    }

    const getFileObj = (fileObj) => {

    }
    

    if(!loaded) {
        firstLoad()
        return <CircularProgress />
    }
    else return (
        <div>
            <Container>
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={3} lg={3}>
                                Logo: 
                                {
                                    company.logo
                                    ?
                                    <img src={company.logo} width="100%"/>
                                    :
                                    <Typography>No Logo Set</Typography>
                                }
                                <Card>
                                    <CardHeader
                                        title="Please ensure your image has square dimensions"
                                        subheader="ie 512x512 or 1024x1024"
                                        avatar={
                                            <Avatar>
                                                <Info />
                                            </Avatar>
                                        }
                                    />
                                </Card>
                                <br/>
                                <FileUploader userId={currentUser.uid} getFilename={getFilename} getUrl={getUrl} getFileObj={getFileObj}/>

                            </Grid>
                            <Grid item xs={12} md={9} lg={9}>
                                <TextField
                                    {...textFieldProps}
                                    label="Company Name"
                                    value={company.companyName}
                                    name="companyName"
                                    onChange={handleChange}
                                />
                                <TextField
                                    {...textFieldProps}
                                    label="VAT Number"
                                    value={company.vatNumber}
                                    name="vatNumber"
                                    type="number"
                                    onChange={handleChange}
                                />
                                <TextField
                                    {...textFieldProps}
                                    label="Company Address"
                                    value={company.address}
                                    name="address"
                                    onChange={handleChange}
                                />
                                <p>{`Currency: ${company.currency ? company.currency.label : "None"}`}</p>
                                <Select
                                    options={Currencies}
                                    {...selectFieldProps}
                                    placeholder={"Select Currency ..."}
                                    onChange={(val) => handleCurrencySelect(val)}
                                    className={"iso-select"}
                                />

                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions className="iso-right">
                        { error ? <span className="iso-error">{error}</span> : null }
                        { updated ? <Button onClick={handleSubmit} disabled={loading} color="primary" variant="contained" startIcon={<Save />}>Save Changes</Button> : null }
                    </CardActions>
                </Card>
            </Container>

            <FloatingHelpComponent
                name="Managing Company Settings" 
                videoPath="master_list"
                videoIndex={1}
            />
        </div>
    )
}
