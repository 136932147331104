export const benefitQuestions = [
  {
    title: "Process Improvement",
    order: 1,
  },
  {
    title: "Increased Production",
    order: 2,
  },
  {
    title: "Operability",
    order: 3,
  },
  {
    title: "Safety",
    order: 4,
  },
  {
    title: "Asset Dismantling",
    order: 5,
  },
  {
    title: "Maintainability",
    order: 6,
  },
  {
    title: "Quality",
    order: 7,
  },
  {
    title: "Design",
    order: 8,
  },
  {
    title: "Environmental",
    order: 9,
  },
];
