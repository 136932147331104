import { Card, CardHeader } from "@mui/material";
import { Info } from "@mui/icons-material";
import React from "react";

export default function RiskControlCard({ control }) {
  return (
    <Card>
      <CardHeader title={control.label} avatar={<Info />} subheader={control.description} />
      {/* <CardActions>
        <Button size="small" variant="contained" color="primary">
          Procedure
        </Button>
        <Button size="small" variant="contained" color="primary">
          Training
        </Button>
        <Button size="small" variant="contained" color="primary">
          PTO
        </Button>
        <Button size="small" variant="contained" color="primary">
          Legal
        </Button>
      </CardActions> */}
    </Card>
  );
}
