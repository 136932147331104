import { Button, Dialog, DialogActions, DialogContent, List } from '@mui/material'
import { Pagination } from '@mui/material';
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import GroupsDataGrid from './GroupsDataGrid'
import GroupsDetail from './GroupsDetail'
import GroupTile from './GroupTile'

export default function GroupList() {
    const { GetGroups } = useDb()
    const [open, setOpen] = useState(false)
    const [group, setGroup] = useState([])
    const groups = GetGroups()
    
    const handleOpen = (g) => {
        setGroup(g)
        setOpen(true)
    }
    
    return (
        <>
            <GroupsDataGrid data={groups} handleOpen={handleOpen} />
            <Dialog maxWidth="sm" fullWidth open={open} onClose={() => setOpen(false)}>
                <DialogContent>
                    <GroupsDetail group={group} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">Close</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
