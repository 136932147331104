import { Grid } from '@mui/material'
import React from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import FloatingHelpComponent from '../../help/FloatingHelpComponent'
import CriteriaList from './CriteriaList'

export default function OtpCriteria() {
    const { GetCriteria } = useDb()
    const criteria = GetCriteria()
    
    return (
        <div>
            <CriteriaList criteria={criteria} />
            <FloatingHelpComponent
                name="Setting OTP Criteria" 
                videoPath="otp_manager"
                videoIndex={0}
            />
        </div>
    )
}
