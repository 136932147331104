import { Button, Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { textFieldProps } from '../../shared/constants'
import { Input, Save } from '@mui/icons-material'
import { useDb } from '../../../contexts/DatabaseContext'

export default function CloseOutAction({ action, open, closeDialog }) {
    const [rootCause, setRootCause] = useState(null)
    const [preventive, setPreventive] = useState(null)
    const [closeOutDate, setCloseOutDate] = useState(new Date())
    const [reviewBaseline, setReviewBaseline] = useState(false)
    const { closeOutAction } = useDb()
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [baselineText, setBaselineText] = useState(null)

    const handleSubmit = async () => {
        setLoading(true)
        try {
            await closeOutAction(action.id, {
                rootCause,
                preventive,
                closeOutDate,
                reviewBaseline,
                baselineText
            }).then(() => {
                closeDialog()
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    return (
        <Dialog open={open} onClose={closeDialog} maxWidth={'sm'} fullWidth> 
            
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <Typography variant={'h5'}>Close Out Action</Typography>
                    Action Code: {action.actionNumber}<br/><br/>
                    <TextField required {...textFieldProps} className={'iso-text-field'} label={'Root Cause'} multiline rows={3} onChange={(e) => setRootCause(e.target.value)}/>
                    <TextField required {...textFieldProps} className={'iso-text-field'} label={'Preventive Measures'} multiline rows={3} onChange={(e) => setPreventive(e.target.value)}/>
                    

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={reviewBaseline}
                                onChange={() => setReviewBaseline(!reviewBaseline)}
                                color={'primary'}
                            />
                        }
                        label={'Review Baseline Risk'}
                    />
                    {
                        reviewBaseline 
                        ?
                        <TextField required label={'Review Baseline Risk'} {...textFieldProps} onChange={(e) => setBaselineText(e.target.value)} />
                        :
                        null
                    }
                </DialogContent>
                <DialogActions>
                    <Button color={'secondary'} onClick={closeDialog}>Cancel</Button>
                    {
                        rootCause !== null && preventive !== null 
                        ?
                        <Button type={'submit'} disabled={loading} color={'primary'} variant={'contained'} startIcon={<Save />} onClick={handleSubmit}>Close Out Action</Button>
                        :
                        <Button disabled color={'primary'} variant={'contained'} startIcon={<Save />}>Close Out Action</Button>
                    }
                </DialogActions>
            </form>
        </Dialog>
    )
}
