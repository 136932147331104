import { faSmileBeam } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
export default function CustomerManagerMenuItem({ classes }) {

    return (
        <>
            <Link to='/' className="drawer-item">
                <ListItem>
                    <ListItemIcon><FontAwesomeIcon className={classes.icons} icon={faSmileBeam} /></ListItemIcon>
                    <ListItemText className={classes.menuText}>Customer Manager</ListItemText>
                </ListItem>
            </Link>
        </>
    )
}
