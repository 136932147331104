import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material'
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import { textFieldProps } from '../../shared/constants'

export default function AddAgent({ open, close }) {
    const { addSalesAgent } = useDb()
    const [state, setState] = useState({
        loading: false,
        error: null,
        data: {}
    })

    const handleChange = (e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.target.name]: e.target.value
            }
        })
    }
    
    const handleClose = () => {
        setState({
            loading: false,
            error: null,
            data: {}
        })
        close()
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault()
        setState({
            ...state,
            loading: true,
            error: null
        })
        try {
            await addSalesAgent(state.data)
            handleClose()
        }
        catch(err){
            setState({
                ...state,
                loading: false,
                error: err.message
            })
        }
    }
    return (
        <Dialog open={open} onClose={close} maxWidth="sm" fullWidth>
            <DialogTitle>Add Agent</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField 
                        label="First Name"
                        name="firstName"
                        onChange={handleChange}
                        {...textFieldProps}
                        required
                    />
                    <TextField
                        label="Last Name"
                        name="lastName"
                        onChange={handleChange}
                        {...textFieldProps}
                        required
                    />
                    <TextField
                        label="Email"
                        name="email"
                        onChange={handleChange}
                        {...textFieldProps}
                        required
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit">Add</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
