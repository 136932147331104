import {
  Badge,
  Button,
  Card,
  CardActions,
  CardContent,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  FormControlLabel,
  Switch,
  IconButton,
  CircularProgress,
} from "@mui/material";
import {
  ArrowDownward,
  ArrowForward,
  ArrowUpward,
  CheckCircle,
  GridOn,
  Help,
} from "@mui/icons-material";
import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDb } from "../../../../../contexts/DatabaseContext";
import { textFieldProps } from "../../../../shared/constants";
import AddRiskAttachment from "../../../baseline/new-risk/AddRiskAttachment";
import CCMGuide from "./CCMGuide";

export default function IBRiskHierarchy() {
  const { ibraId, riskId } = useParams();
  const {
    GetRiskHierarchy,
    updateIbraRisk,
    saveCcms,
    getIbraRisk,
    addIbraRiskControls,
  } = useDb();
  const hierarchy = GetRiskHierarchy();
  const history = useHistory();

  const [guideOpen, setGuideOpen] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const [fileType, setFileType] = useState();
  const [state, setState] = useState({
    loading: false,
    error: null,
  });
  const [attachments, setAttachments] = useState({
    0: {
      process: null,
      training: null,
      pto: null,
      compliance: null,
      length: 0,
      description: null,
      type: "elimination",
      label: "Elimination",
    },
    1: {
      process: null,
      training: null,
      pto: null,
      compliance: null,
      length: 0,
      description: null,
      type: "substitution",
      label: "Substitution",
    },
    2: {
      process: null,
      training: null,
      pto: null,
      compliance: null,
      length: 0,
      description: null,
      type: "isolation",
      label: "Isolation",
    },
    3: {
      process: null,
      training: null,
      pto: null,
      compliance: null,
      length: 0,
      description: null,
      type: "engineering",
      label: "Engineering",
    },
    4: {
      process: null,
      training: null,
      pto: null,
      compliance: null,
      length: 0,
      description: null,
      type: "administrative",
      label: "Administrative Controls",
    },
    5: {
      process: null,
      training: null,
      pto: null,
      compliance: null,
      length: 0,
      description: null,
      type: "ppe",
      label: "PPE",
    },
  });

  const [ccms, setCcms] = useState([]);

  const [addOpen, setAddOpen] = useState(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getFile = (u) => {
    setAttachments({
      ...attachments,
      [activeStep]: {
        ...attachments[activeStep],
        [fileType]: u,
        length: attachments[activeStep].length + 1,
      },
    });
  };

  const handleOpen = (t) => {
    setFileType(t);
    setAddOpen(true);
  };

  const handleChange = (e) => {
    setAttachments({
      ...attachments,
      [activeStep]: {
        ...attachments[activeStep],
        description: e.target.value,
      },
    });
  };

  const handleSubmit = async () => {
    let controlsArray = [];
    for (const i in attachments) {
      console.log(i);

      if (attachments[i].description) {
        controlsArray.push({ ...attachments[i], index: i });
      }
    }

    let totalControlValue = 0;
    controlsArray.map((c) => {
      console.log(hierarchy);
      c.controlValue = hierarchy[c.index].value;
      totalControlValue = totalControlValue + hierarchy[c.index].value;
    });
    setState({
      loading: true,
      error: null,
    });
    try {
      let ccmArray = [];
      for (let i in attachments) {
        if (attachments[i].ccm) {
          ccmArray.push({
            ...attachments[i],
          });
        }
      }
      console.log(ccmArray);
      if (ccmArray.length > 0) {
        await saveCcms(ccmArray, ibraId);
      }
      await addIbraRiskControls(
        ibraId,
        riskId,
        totalControlValue,
        controlsArray
      );
      await updateIbraRisk(ibraId, riskId, {
        totalControlValue,
        controlsArray,
        currentStep: 3,
      });

      history.push(
        `/risk-manager/residual-risk/${ibraId}/risk/${riskId}/4/ibra`
      );
    } catch (err) {
      window.alert(err.message);
      setState({
        loading: false,
        error: err.message,
      });
    }
  };

  const handleCCM = (e, t) => {
    setAttachments({
      ...attachments,
      [t]: {
        ...attachments[t],
        ccm: e.target.checked,
      },
    });
    setCcms([...ccms, attachments[t]]);
  };

  const [risk, setRisk] = useState();

  const firstLoad = async () => {
    const r = await getIbraRisk(ibraId, riskId);
    setRisk(r);
  };

  if (!risk) {
    firstLoad();
    return <CircularProgress />;
  } else
    return (
      <div>
        <h1>Hierarchy of Controls</h1>
        <p>Risk Description: {risk.description}</p>
        <Stepper activeStep={activeStep} orientation="vertical">
          {hierarchy &&
            hierarchy.map((step, index) => (
              <Step key={index}>
                <StepLabel>
                  {step.label} - {step.value}%
                </StepLabel>
                <StepContent>
                  <Card>
                    <CardContent>
                      <TextField
                        {...textFieldProps}
                        label="Description of Control"
                        onChange={handleChange}
                        required
                        value={attachments[index].description}
                      />
                    </CardContent>
                    <CardActions>
                      <Badge
                        badgeContent={
                          attachments[index].process && (
                            <CheckCircle style={{ color: "green" }} />
                          )
                        }
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleOpen("process")}
                        >
                          Procedure
                        </Button>
                      </Badge>
                      <Badge
                        badgeContent={
                          attachments[index].training && (
                            <CheckCircle style={{ color: "green" }} />
                          )
                        }
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleOpen("training")}
                        >
                          Training
                        </Button>
                      </Badge>
                      <Badge
                        badgeContent={
                          attachments[index].pto && (
                            <CheckCircle style={{ color: "green" }} />
                          )
                        }
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleOpen("pto")}
                        >
                          PTO
                        </Button>
                      </Badge>
                      <Badge
                        badgeContent={
                          attachments[index].compliance && (
                            <CheckCircle style={{ color: "green" }} />
                          )
                        }
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleOpen("compliance")}
                        >
                          Legal
                        </Button>
                      </Badge>
                      &nbsp; Attachments: {attachments[index].length} / 4
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <FormControlLabel
                        control={
                          <Switch
                            checked={attachments[index].ccm}
                            onChange={(e) => handleCCM(e, index)}
                            color="primary"
                          />
                        }
                        label="Critical Control Measure"
                      />
                      <IconButton onClick={() => setGuideOpen(true)} size="large">
                        <Help />
                      </IconButton>
                    </CardActions>
                  </Card>
                  <CardActions className="iso-right">
                    {activeStep > 0 && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleBack}
                        startIcon={<ArrowUpward />}
                      >
                        Back
                      </Button>
                    )}
                    {activeStep <= index &&
                    activeStep < hierarchy.length - 1 ? (
                      <>
                        {attachments[0].length < 4 && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            startIcon={<ArrowDownward />}
                          >
                            Next
                          </Button>
                        )}
                      </>
                    ) : null}
                    {attachments[0].length === 4 ? (
                      <Button
                        endIcon={<ArrowForward />}
                        variant="contained"
                        color="primary"
                        startIcon={<GridOn />}
                        onClick={handleSubmit}
                        disabled={state.loading}
                      >
                        Residual Risk
                      </Button>
                    ) : null}
                    {attachments[1].description ||
                    attachments[2].description ||
                    attachments[3].description ||
                    attachments[4].description ||
                    attachments[5].description ? (
                      <Button
                        endIcon={<ArrowForward />}
                        variant="contained"
                        color="primary"
                        startIcon={<GridOn />}
                        onClick={handleSubmit}
                        disabled={state.loading}
                      >
                        Residual Risk
                      </Button>
                    ) : null}
                    {state.loading && <CircularProgress />}
                  </CardActions>
                </StepContent>
              </Step>
            ))}
        </Stepper>
        {state.error && (
          <Typography color="secondary">{state.error}</Typography>
        )}
        <AddRiskAttachment
          sendFile={getFile}
          open={addOpen}
          close={() => setAddOpen(false)}
        />

        <CCMGuide open={guideOpen} close={() => setGuideOpen(false)} />
      </div>
    );
}
