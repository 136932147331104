export function camelCaseToTitleCase(str) {
  // Split words when a lower case is followed by an upper case
  var result = str.replace(/([a-z])([A-Z])/g, "$1 $2");

  // Capitalize the first letter of each word
  result = result
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");

  return result;
}
