import React, { useEffect, useState } from "react";
import { useDb } from "../../../../contexts/DatabaseContext";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
} from "@mui/material";
import { CheckCircle, Info } from "@mui/icons-material";

export default function ApprovalBenefits({ moc }) {
  const collectionPath = `moc/${moc.id}/responses`;
  const { getCompanyDoc } = useDb();
  const [responses, setResponses] = useState([]);

  const loadResponses = async () => {
    const r = await getCompanyDoc(collectionPath, "benefitChecklist");
    if (r) setResponses(r.responses || []);
  };

  useEffect(() => {
    return loadResponses();
  }, [moc.id]);

  return !responses ? (
    <CircularProgress />
  ) : (
    <div>
      <Card>
        <CardHeader
          title="Expected Benefits"
          subheader={`MOC Title: ${moc.title}`}
          avatar={
            <Avatar>
              <Info />
            </Avatar>
          }
        />
        <CardContent>
          {responses &&
            responses.length > 0 &&
            responses.map((r) => (
              <Card elevation={0}>
                <CardHeader
                  title={r.title}
                  avatar={
                    <Avatar style={{ backgroundColor: "#4aaf41" }}>
                      <CheckCircle />
                    </Avatar>
                  }
                />
              </Card>
            ))}
        </CardContent>
      </Card>
    </div>
  );
}
