import { Button, Card, CardActions, CardContent, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { AddBox, ArrowLeftOutlined, Edit, History, People, Search } from '@mui/icons-material'
import React, { useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { useDb } from '../../../../../contexts/DatabaseContext'
import moment from 'moment'
import { Pagination } from '@mui/material';

export default function CoDocs() {
    const { coId } = useParams()
    const { MgmtGetDocs } = useDb()
    const docs = MgmtGetDocs(coId)

    // Pagination
    const itemsPerPage = 10 // <- Not state but related to below pagination
    const [page, setPage] = useState(1)
    const [noOfPages] = useState(
        Math.ceil(docs.length / itemsPerPage)
    )

    return (
        <div>
            <h1 style={{ color: '#fff' }}>Company Documents</h1>
            <Card>
                <CardContent>
                    <Link to={`/companies/${coId}`}>
                        <Button startIcon={<ArrowLeftOutlined />} color="primary" variant="contained">Back</Button>
                    </Link>
                    <br/><br/>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Doc Code</TableCell>
                                    <TableCell>Doc Name</TableCell>
                                    <TableCell>Created</TableCell>
                                    <TableCell>Dept.</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Category</TableCell>
                                    <TableCell>Target Rev</TableCell>
                                    <TableCell>Site</TableCell>
                                    <TableCell>Owner</TableCell>
                                    <TableCell>Retention</TableCell>
                                    <TableCell>Disp.</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    docs && docs
                                    .slice((page -1) * itemsPerPage, page * itemsPerPage)
                                    .map(d => {
                                        return (
                                            <TableRow key={d.id}>
                                                <TableCell>{d.docCode}</TableCell>
                                                <TableCell>{d.docName}</TableCell>
                                                <TableCell>{moment(d.createdDate.toDate()).format('DD/MM/YYYY')}</TableCell>
                                                <TableCell>{d.department.label}</TableCell>
                                                <TableCell>{d.docType.label}</TableCell>
                                                <TableCell>{d.category}</TableCell>
                                                <TableCell>{moment(d.targetRevDate.toDate()).format('DD/MM/YYYY')}</TableCell>
                                                <TableCell>{d.site.label}</TableCell>
                                                <TableCell>{d.owner.label}</TableCell>
                                                <TableCell>{d.retentionPeriod.label}</TableCell>
                                                <TableCell>{d.dispositionMethod.label}</TableCell>
                                                <TableCell>
                                                    <Link to={`/companies/${coId}/doc-revisions/${d.id}`}>
                                                        <IconButton size="large">
                                                            <History />
                                                        </IconButton>
                                                    </Link>
                                                    <Link to={`/companies/${coId}/doc-intparties/${d.id}`}>
                                                        <IconButton size="large">
                                                            <People />
                                                        </IconButton>
                                                    </Link>
                                                    <Link to={`/companies/${coId}/edit-doc/${d.id}`}>
                                                        <IconButton size="large">
                                                            <Edit />
                                                        </IconButton>
                                                    </Link>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
                <CardActions>
                    <Pagination
                        count={Math.ceil(docs.length / itemsPerPage)}
                        page={page}
                        onChange={(e, val) => setPage(val)}
                        defaultPage={1}
                        color="primary"
                        size="large"
                        showFirstButton
                        showLastButton
                    /> 
                    <Link to={`/companies/${coId}/create-doc`}>
                        <Button variant="contained" color="primary" startIcon={<AddBox />}>Add Document</Button>
                    </Link>
                </CardActions>
            </Card>
        </div>
    );
}
