import {
  faFilePdf,
  faPlus,
  faSlidersH,
  faList,
  faTh,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  CardHeader,
  IconButton,
  CardContent,
  TextField,
  InputAdornment,
  Chip,
  Fab,
  Grid,
  List,
  Typography,
  CircularProgress,
  FormControlLabel,
  Switch,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { amber } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { Add } from "@mui/icons-material";
import RegisterCard from "./RegisterCard";
import RegisterListItem from "./RegisterListItem";
import { useAuth } from "../../contexts/AuthContext";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
    position: "relative",
    minHeight: 200,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(6),
    right: theme.spacing(7),
    backgroundColor: amber[500],
    "&:hover": {
      backgroundColor: amber[600],
    },
  },
  tab: {
    indicatorColor: amber[500],
  },
  icon: {
    color: theme.palette.primary,
  },
  card: {
    backgroundColor: theme.palette.cardBg,
  },
  search: {
    color: theme.palette.isotext,
  },
}));

export default function RegisterViewer({
  customAction,
  addItemFunction,
  customToolBarItems,
  data,
  filterItems,
  type,
  pdfFunction,
  openDialog,
  handleChipDelete,
  overdue,
  due30,
  setDue30,
  setOverdue,
}) {
  const classes = useStyles();
  const [dialogOpen, setDialog] = useState(false);
  const [listView, setListView] = useState(false);
  const [search, setSearch] = useState(null);

  // User
  const { currentUser } = useAuth()

  // Action State
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const myChip = (name, filter) => {
    const lowerCase = name.toLowerCase();
    const descLabel = name.charAt(0).toUpperCase() + lowerCase.slice(1);
    return (
      <Chip
        label={`${descLabel}: ${filter.label}`}
        key={filter.id}
        onDelete={() => handleChipDelete(name)}
        style={{ marginRight: 5 }}
      />
    );
  };

  const toggleView = () => {
    setListView(!listView);
  };

  const generatePdf = async () => {
    setLoading(true);
    try {
      await pdfFunction();
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };
  console.log(type)
  return <>
    <Card className={classes.card}>
      <CardHeader
        className="iso-card-header"
        title={
          <>
            <IconButton className={classes.icon} onClick={() => openDialog(true)} size="large">
              <FontAwesomeIcon icon={faSlidersH} />
            </IconButton>
            {loading ? (
              <CircularProgress />
            ) : (
              <IconButton onClick={generatePdf} className={classes.icon} size="large">
                <FontAwesomeIcon icon={faFilePdf} />
              </IconButton>
            )}
            <IconButton onClick={addItemFunction} className={classes.icon} size="large">
              <FontAwesomeIcon icon={faPlus} />
            </IconButton>
            <IconButton onClick={() => toggleView()} className={classes.icon} size="large">
              {listView ? (
                <FontAwesomeIcon icon={faList} />
              ) : (
                <FontAwesomeIcon icon={faTh} />
              )}
            </IconButton>
            {customToolBarItems}
          </>
        }
        action={customAction}
      />
      <CardContent className="iso-card-content">
        <TextField
          className={classes.search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search ..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={faSearch} />
              </InputAdornment>
            ),
          }}
          fullWidth
          variant="outlined"
        />
        {filterItems ? (
          <div>
            <br />
            Filters:
            <br />
            {Object.keys(filterItems).map(
              (filter) =>
                !isEmpty(filterItems[filter]) &&
                myChip(filter, filterItems[filter])
            )}
          </div>
        ) : null}

        <br />
        {
          type !== 'pendingDoc'
          ?
          <>
            <FormControlLabel
              label={<span className="iso-small">Overdue</span>}
              control={
                <Switch
                  checked={overdue}
                  onChange={(e) => {
                    setDue30(false);
                    setOverdue(e.target.checked);
                  }}
                  color="primary"
                />
              }
            />
            <FormControlLabel
              label={<span className="iso-small">Due in 30 Days</span>}
              control={
                <Switch
                  checked={due30}
                  onChange={(e) => {
                    setOverdue(false);
                    setDue30(e.target.checked);
                  }}
                  color="primary"
                />
              }
            />
          </>
        :
        null
        }
        {/* Cards or List Items rendered below */}

        {data.length == 0 ? <Typography>No items to show.</Typography> : ""}

        {listView ? (
          <List>
            {data &&
              data.map((doc) => {
                if(!search) {
                  return (
                    <RegisterListItem type={type} data={doc} key={doc.id} user={currentUser}/>
                  )
                }
                else if (
                  doc.actionNumber && doc.actionNumber.includes(search) ||
                  doc.otpNumber && doc.otpNumber.includes(search) ||
                  doc.defineObjectives && doc.defineObjectives.includes(search) || 
                  doc.defineObjectives && doc.defineObjectives.toLowerCase().includes(search) 
              ) {
                return (
                  <RegisterListItem type={type} data={doc} key={doc.id} user={currentUser}/>
                )
              }
                
              })}
          </List>
        ) : (
          <Grid container spacing={3}>
            {
              data &&
                data.map((doc) => {
                  if(!search) {
                    return (
                      <Grid item xs={4} key={doc.id}>
                        <RegisterCard type={type} data={doc} key={doc.id} user={currentUser}/>
                      </Grid>
                    )
                  }
                  else if (
                    doc.actionNumber && doc.actionNumber.includes(search) ||
                    doc.otpNumber && doc.otpNumber.toString().includes(search) ||
                    doc.defineObjectives && doc.defineObjectives.includes(search) || 
                    doc.defineObjectives && doc.defineObjectives.toLowerCase().includes(search) 
                ) {
                  return (
                    <Grid item xs={4} key={doc.id}>
                      <RegisterCard type={type} data={doc} key={doc.id} user={currentUser}/>
                    </Grid>
                  )
                }
              })
            }
          </Grid>
        )}
      </CardContent>
    </Card>
    <Fab onClick={addItemFunction} className={classes.fab}>
      <Add />
    </Fab>
  </>;
}
