import {
  Card,
  CardHeader,
  IconButton,
  CircularProgress,
  CardContent,
  Typography,
  Snackbar,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { amber } from "@mui/material/colors";
import { Search } from "@mui/icons-material";
import React, { useState } from 'react'
import PropTypes from "prop-types";
import { useAuth } from "../../../contexts/AuthContext";
import Add from "@mui/icons-material/Add";
import { useDb } from "../../../contexts/DatabaseContext";
import moment from "moment";
import IsoDataGrid from "../../shared/data-grid/IsoDataGrid";
import CreateFolder from "./CreateFolder";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background,
      width: 500,
      position: "relative",
      minHeight: 200,
    },
    fab: {
      position: "fixed",
      bottom: theme.spacing(6),
      right: theme.spacing(7),
      backgroundColor: amber[500],
      "&:hover": {
        backgroundColor: amber[600],
      },
    },
    tab: {
      indicatorColor: theme.palette.primary,
    },
    icon: {
      color: theme.palette.primary,
    },
    tabPanel: {
      backgroundColor: theme.palette.main,
      padding: '0px 10px'
    },
    dialog: {
      backgroundColor: theme.palette.dialogBg,
    },
    search: {
      color: theme.palette.isotext,
    },
    chip: {
      marginRight: 5
    },
    filtersTab: {
      marginBottom: 20
    },
    loaderDiv: {
      width: '100%',
      textAlign: 'center'
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <CardContent className="iso-card-content">
            <Typography component={"span"}>{children}</Typography>
          </CardContent>
        )}
      </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
}



export default function FolderRegister() {
    const classes = useStyles()
    const { GetSharedFolders} = useDb()
    const { currentUser } = useAuth()
    const [open, setOpen] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false)
    const [snackMessage, setSnackMessage] = useState('')

    const handleSnack = (msg) => {
      setSnackMessage(msg)
      setSnackOpen(true)
    }

    const closeSnack = () => {
      setSnackOpen(false)
      setSnackMessage('')
    }

    const openDialog = (dest) => {
      setOpen(true)
    }

    const closeDialog = () => {
      setOpen(false)
    }

    let tempArray = []

    GetSharedFolders().map(f => {
      tempArray.push({
        id: f.id,
        name: f.name,
        count: f.count,
        dateUpdated: moment(f.dateUpdated.toDate()).format('DD/MM/YY - hh:mm:ss')
      })
    })

    const rows = tempArray
    console.log(rows)

    const columns = [
      {
        field: 'actions',
        headerName: 'Actions',
        width: 150,
        renderCell: (params) => {
          return (
            <Link to={`/doc-manager/shared-folders/${params.row.id}`}>
              <IconButton size="large"><Search /></IconButton>
            </Link>
          );
        }
      },
      {
        field: 'name',
        headerName: 'Folder',
        width: 700
      },
      {
        field: 'count',
        headerName: 'Documents',
        width: 200
      },
      {
        field: 'dateUpdated',
        headerName: 'Date Updated',
        width: 200
      },
    ]

    return !rows ? (<CircularProgress />) : (
        <div>
            <h1>Shared Folders</h1>
            <br />
            <Card>
                <CardHeader
                    className={classes.tabPanel}
                    title={
                    <>       
                        <IconButton onClick={() => openDialog(true)} className={classes.icon} size="large">
                            <Add />
                        </IconButton>
                    </>
                    } 
                />
                <CardContent>
                  <IsoDataGrid
                    data={rows}
                    columns={columns}
                  />
                </CardContent>
            </Card>
            <Snackbar
              anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
              }}
              open={snackOpen}
              autoHideDuration={5000}
              onClose={closeSnack}
              message={snackMessage}
            />
            <CreateFolder open={open} handleClose={closeDialog} />
        </div>
    );
}

