import React from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid';
import moment from "moment"
import { Chip } from '@mui/material';
import { CheckCircle, MailRounded, Notifications, Warning } from '@mui/icons-material';
import { useAuth } from '../../../contexts/AuthContext';

export default function NotificationLog() {
    const { GetCollection } = useDb()
    const { currentUser } = useAuth()
    const emails = GetCollection(true, "email_log", ["companyId", "==", currentUser.companyId], ["request_timestamp", "desc"], 200);
    const notifs =  GetCollection(true, "notification_log", ["companyId", "==", currentUser.companyId], ["timestamp", "desc"], 200);
    console.log(notifs)

    const rows = emails && emails.map((email) => ({
        ...email,
        sentTime: moment(email?.sent_timestamp?.toDate()).format("YYYY-MM-DD HH:ss"),
        msg: email.msg?.dynamic_template_data?.message,
        type: "Email"
    }));

    const notificationRows = notifs && notifs.map(n => ({
        ...n,
        sentTime:  moment(n?.timestamp?.toDate()).format("YYYY-MM-DD HH:ss"),
        msg: n.text,
        type: "notif"
    }))

    console.log(notificationRows)

    const columns = [
        { field: "email", headerName: "Recipient", width: 200 },
        { field: "type", headerName: "Type", width: 180,
        renderCell: (params) => {
            return params.row.type === "Email" ? <Chip color="default" label="Email" icon={<MailRounded />}/> :  <Chip color="secondary" label="Notification" icon={<Notifications />}/>
        } },
        { 
            field: 'sent', 
            headerName: 'Status', 
            width: 200,
            renderCell: (params) => {
                if(params.row.sent === true) return <Chip color="primary" label="Sent" icon={<CheckCircle />}/>
                else return <Chip color="error" label="Missed" icon={<Warning />}/>
            }
        },
        { field: "sentTime", headerName: "Sent Time", width: 200 },
        { field: "msg", headerName: "Message", width: 700 },
    ]

    console.log(emails);
  return (
    <div>
        <h1>Notification Log</h1>
        {
            emails && <IsoDataGrid
            data={rows}
            columns={columns}
        />
        }
    </div>
  )
}
