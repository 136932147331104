import { Avatar, Button, Card, CardActions, CardContent, CardHeader } from '@mui/material'
import { AddBox, GridOn } from '@mui/icons-material'
import React, { useState } from 'react'
import MatrixReportList from './MatrixReportList'
import NewTrainingMatrix from './NewTrainingMatrix'

export default function MatrixReports() {
    const [newMatrixOpen, setNewMatrixOpen] = useState(false)

    return (
        <div>
            <Card>
                <CardHeader
                    title="Training Matrix Reports"
                    avatar={<Avatar><GridOn /></Avatar>}
                />
                <CardContent>
                    <MatrixReportList />
                </CardContent>
                <CardActions>
                    <Button onClick={() => setNewMatrixOpen(true)} color="primary" variant="contained" startIcon={<AddBox />}>New Report</Button>
                    
                </CardActions>
            </Card>
            <NewTrainingMatrix open={newMatrixOpen} close={() => setNewMatrixOpen(false)} />
        </div>
    )
}
