import { Button, IconButton } from '@mui/material'
import { ArrowBack, Search } from '@mui/icons-material'
import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import moment from 'moment'
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid'

export default function PastSessions() {
    const { GetPastTrainingSessions } = useDb()
    const sessions = GetPastTrainingSessions()
    const history = useHistory()

    const columns = [
        { field: 'date', headerName: 'Date' , width: 200 },
        { field: 'module', headerName: 'Module', width: 200 },
        { field: 'facilitator', headerName: 'Facilitator', width: 200 },
        { 
            field: 'action', 
            headerName: 'Action', 
            width: 140,
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                        <Link to={`/training-manager/sessions/detail/${params.row.id}`}>
                            <IconButton size="large">
                                <Search />
                            </IconButton>
                        </Link>
                     </div>
                );
            }
        }
    ]

    const rows = sessions && sessions.map(s  => ({
        id: s.id,
        date: moment(s.startTime.toDate()).format('DD/MM/YYYY'),
        module: s.module.name,
        facilitator: s.facilitator.label
    }))

    return (
        <div>
            <h1>Past Training Sessions</h1>
            <div className="back-link">
                <Button onClick={() => history.goBack()} color="primary" variant="contained" startIcon={<ArrowBack />}>Back</Button>
            </div>
            <br/>
                <IsoDataGrid
                    columns={columns}
                    data={rows}
                />
        </div>
    )
}
