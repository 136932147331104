import React, { useState } from 'react'
import moment from 'moment'
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid'
import { Check, Search, Warning } from '@mui/icons-material'
import { Button, Chip, CircularProgress, IconButton } from '@mui/material'
import { Link } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'

export default function AuditInspections({ inspections }) {

    const { generateAuditsInspectionsPdf } = useDb()
    const [loading, setLoading] = useState(false)

    console.log(inspections)

    const [filteredRows, setFilteredRows] = useState([])

    const handlePdf = async () => {
        setLoading(true)
        await generateAuditsInspectionsPdf(filteredRows.length < 1 ? inspections : filteredRows)
        setLoading(false)
    }

    const rows = inspections && inspections.map(i => ({
        id: i.id,
        title: i.title,
        type: i.type,
        dueDate: moment(i.dueDate.toDate()).format('DD/MM/YYYY'),
        completionDate: i.completionDate ? moment(i.completionDate.toDate()).format('DD/MM/YYYY') : ' ',
        assignedTo: i.assignedTo.label,
        assigneeUid: i.assignedTo.id,
        status: i.status ? i.status : null,
        checklistId: i.checklist,
        jobRef: i.jobRef
    }))

    const columns = [
        { field: 'jobRef', headerName: 'Code', width: 140 },
        { field: 'title', headerName: 'Title', width: 200 },
        { field: 'type', headerName: 'Type', width: 200 },
        { field: 'dueDate', headerName: 'Due Date', width: 200 },
        { field: 'completionDate', headerName: 'Completed', width: 200 },
        { field: 'assignedTo', headerName: 'Assignee', width: 200 },
        { 
            field: 'status', 
            headerName: 'Status', 
            width: 200,
            renderCell: (params) => {
                if(params.row.status === 'pending') return <Chip label="Pending"/>
                if(params.row.status === 'missed') return <Chip color="secondary" label="Missed" icon={<Warning />}/>
                if(params.row.status === 'complete') return <Chip color="primary" label="Complete" icon={<Check />}/>
            }
        },
        { 
            field: 'action', 
            headerName: 'Action', 
            width: 200,
            renderCell: (params) => {
                return (
                    params.row.status === 'pending'
                    ?
                    <Link to={`/audit-manager/inspection-submission/${params.row.checklistId}/${params.row.id}`}>
                        <Button
                            size="small"
                            color="primary"
                            variant="contained"
                        >
                            Begin {params.row.type}
                        </Button>
                    </Link>
                    :
                    ''
                    );
                }
            },
            { 
                field: 'view', 
                headerName: 'View', 
                width: 100,
                sortable: false,
                renderCell: (params) => {
                    return params.row.status !== 'pending' && params.row.status !== 'missed'
                    ?
                    <Link to={`/audit-manager/inspection-view/${params.row.id}`}>
                        <IconButton size="large">
                            <Search />
                        </IconButton>
                    </Link>
                    :
                    '';
                }
            },
    ]

    return (
        <div>
            <div>
                {
                    loading 
                    ?
                    <CircularProgress className='iso-right' />
                    :
                    <Button className='iso-right' color='primary' variant='contained' onClick={handlePdf}>Export To PDF</Button>
                }
                <br />
                <br />
            </div>
            <IsoDataGrid
                // TODO:

                // onStateChange={val => {
                //     if (val.state.visibleRows.visibleRows !== undefined) {
                //       setFilteredRows(val.state.visibleRows.visibleRows)
                //       return
                //     }
                //   }}
                data={rows}
                columns={columns}
            />
        </div>
    )
}
