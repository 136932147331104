import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { Save } from "@mui/icons-material";
import { useDb } from "../../../contexts/DatabaseContext";
import Select from "react-select";
import { selectFieldProps, textFieldProps } from "../../shared/constants";

export default function RequestTraining({ open, close, employee }) {
  const { GetTrainingModules, GetRosters, GetSites, requestTraining } = useDb();
  const modules = GetTrainingModules();
  const rosters = GetRosters();
  const sites = GetSites();
  const [state, setState] = useState({});
  const [compState, setCompState] = useState({ loading: false, error: null });

  const handleClose = () => {
    setState({});
    setCompState({ loading: false, error: null });
    close();
  };

  const handleSelect = (v, e) => {
    setState({
      ...state,
      [e.name]: v.value,
    });
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCompState({ loading: true, error: null });
    try {
      await requestTraining({
        ...state,
        employee,
      });
      handleClose();
    } catch (err) {
      setCompState({ loading: false, error: err.message });
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Request Training:{" "}
        {employee && `${employee.firstName} ${employee.lastName}`}
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Select
            {...selectFieldProps}
            placeholder="Training Module..."
            className="iso-select"
            options={modules}
            name="module"
            onChange={handleSelect}
          />
          <Select
            {...selectFieldProps}
            placeholder="Requested By..."
            className="iso-select"
            options={rosters}
            name="requestedBy"
            onChange={handleSelect}
          />
          <Select
            {...selectFieldProps}
            placeholder="Site..."
            className="iso-select"
            options={sites}
            name="site"
            onChange={handleSelect}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Preferred Date"
              onChange={(d) =>
                setState({
                  ...state,
                  preferredDate: d.toDate(),
                })
              }
              defaultValue={dayjs(state.preferredDate)}
            />
          </LocalizationProvider>

          <br />
          <br />
          <TextField
            {...textFieldProps}
            label="Motivation"
            multiline
            rows={3}
            name="motivation"
            onChange={handleChange}
            required
          />
          {compState.error ? (
            <span className="iso-erro">{compState.error}</span>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={compState.loading}
            color="secondary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            disabled={
              compState.loading ||
              !state.module ||
              !state.requestedBy ||
              !state.site ||
              !state.preferredDate
            }
            variant="contained"
            startIcon={<Save />}
            color="primary"
            type="submit"
          >
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
