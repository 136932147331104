import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField
} from "@mui/material";
import {
  textFieldProps,
  selectFieldProps,
  employeeTypes,
  countries,
  genders,
  races
} from "../../shared/constants";
import Select from "react-select";
import { SaveOutlined } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { useDb } from "../../../contexts/DatabaseContext";

export default function EmployeeEdit({ employee, close, open }) {
  const [contactNumber, setContactNumber] = useState(employee.contactNumber);
  const [employeeNumber, setEmployeeNumber] = useState(employee.employeeNumber);
  const [employeeId, setEmployeeId] = useState(employee.employeeId);
  const [firstName, setFirstName] = useState(employee.firstName);
  const [lastName, setLastName] = useState(employee.lastName);
  const [middleName, setMiddleName] = useState(employee.middleName);
  const [passport, setPassport] = useState(employee.passport);
  const [gender, setGender] = useState(employee.gender);
  const [disability, setDisability] = useState(employee.disability);
  const [email, setEmail] = useState(employee.email);
  const [department, setDepartment] = useState(
    employee.department ? employee.department.name : "None"
  );
  const [nationality, setNationality] = useState(employee.nationality);
  const [manager, setManager] = useState(
    employee.manager
      ? `${employee.manager.firstName} - ${employee.manager.email}`
      : "None"
  );
  const [race, setRace] = useState(employee.race);
  const [site, setSite] = useState(employee.site);
  const [employeeType, setEmployeeType] = useState(employee.employeeType);
  const {
    updateEmployeeDetails,
    GetUsersLevelFilter,
    GetSites,
    getSite,
    GetDepartments,
    GetOccupations
  } = useDb();
  const hods = GetUsersLevelFilter("trainingManager", 1);
  const jobs = GetOccupations();
  const sites = GetSites();
  const depts = GetDepartments();

  const [stateData, setStateData] = useState({});

  const [state, setState] = useState({
    loading: false,
    error: ""
  });

  useEffect(async () => {
    const site = await getSite(employee.site);
    console.log(site);
    setContactNumber(employee.contactNumber);
    setEmployeeNumber(employee.employeeNumber);
    setEmployeeId(employee.employeeId);
    setEmployeeType(employee.employeeType);
    setFirstName(employee.firstName);
    setLastName(employee.lastName);
    setMiddleName(employee.middleName);
    setPassport(employee.passport);
    setGender(employee.gender);
    setDisability(employee.disability);
    setEmail(employee.email);
    setManager(
      employee.manager
        ? `${employee.manager.firstName} - ${employee.manager.email}`
        : "None"
    );
    // setOccupation(employee.occupation)
    setDepartment(employee.department ? employee.department.name : "None");
    setNationality(employee.nationality);
    setRace(employee.race);
    setSite(site.name);
  }, [open]);

  const handleChange = e => {
    setStateData({
      ...stateData,
      [e.target.name]: e.target.value
    });
  };

  const handleSelect = (v, e) => {
    setStateData({
      ...stateData,
      [e.name]: v ? v.value : null
    });
  };

  const handleManagerChange = (v, e) => {
    handleSelect(v, e);
    if (
      typeof employee.linkUser !== "undefined" &&
      typeof employee.linkUser === "boolean" &&
      employee.linkUser === false
    ) {
      setStateData({
        ...stateData,
        email: v.value.email
      });
      setEmail(v.value.email);
    }
  };

  const handleClose = () => {
    setState({
      loading: false,
      error: ""
    });
    setStateData({});
    close();
  };

  const handleSubmit = async () => {
    setState({
      loading: true,
      error: ""
    });
    try {
      await updateEmployeeDetails(employee.id, stateData);
      handleClose();
    } catch (err) {
      setState({
        error: err.message,
        loading: false
      });
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Edit Employee Details</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                defaultValue={firstName}
                {...textFieldProps}
                label="First Name"
                onChange={handleChange}
                required
                name="firstName"
              />
              <TextField
                defaultValue={middleName}
                {...textFieldProps}
                label="Middle Name"
                onChange={handleChange}
                name="middleName"
              />
              <TextField
                defaultValue={lastName}
                {...textFieldProps}
                label="Last Name"
                onChange={handleChange}
                required
                name="lastName"
              />
              <TextField
                value={email}
                {...textFieldProps}
                label="Email"
                disabled
              />
              <TextField
                defaultValue={contactNumber}
                fullWidth
                type="text"
                variant="outlined"
                label="Contact Number"
                name="contactNumber"
                className="iso-text-field"
                onChange={handleChange}
              />
              <TextField
                defaultValue={employeeNumber}
                fullWidth
                type="text"
                variant="outlined"
                label="Employee Number"
                name="employeeNumber"
                className="iso-text-field"
                onChange={handleChange}
              />
              <TextField
                defaultValue={employeeId}
                fullWidth
                type="text"
                variant="outlined"
                label="Id Number"
                name="employeeId"
                className="iso-text-field"
                onChange={handleChange}
              />
              <span>Employee Type: {employeeType}</span>
              <Select
                placeholder="Employee Type ..."
                className="iso-select"
                {...selectFieldProps}
                onChange={handleSelect}
                name="employeeType"
                options={employeeTypes}
              />
              <TextField
                defaultValue={passport}
                {...textFieldProps}
                onChange={handleChange}
                label="Passport Number"
                name="passport"
              />
              <TextField
                defaultValue={disability}
                {...textFieldProps}
                onChange={handleChange}
                label="Disability"
                name="disability"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {/* <span>Occupation: {occupation}</span> */}
              {/* <Select
                  placeholder='Occupation ...'
                  className='iso-select'
                  {...selectFieldProps}
                  onChange={handleMultiSelect}
                  name="occupation"
                  options={jobs}
                  isMulti
              /> */}
              <span>Department: {department}</span>
              <Select
                placeholder="Department ..."
                className="iso-select"
                {...selectFieldProps}
                onChange={handleSelect}
                name="department"
                options={depts}
              />
              <span>Site: {site}</span>
              <Select
                placeholder="Site ..."
                className="iso-select"
                {...selectFieldProps}
                onChange={handleSelect}
                name="site"
                options={sites}
              />
              <input
                autoComplete="off"
                style={{ opacity: 0, height: 0, marginTop: -20, width: 0 }}
                defaultValue={state.site}
                required
              />
              <span>Manager: {manager}</span>
              <Select
                placeholder="Manager ..."
                className="iso-select"
                {...selectFieldProps}
                onChange={handleManagerChange}
                name="manager"
                options={hods}
              />
              <span>Nationality: {nationality}</span>
              <Select
                placeholder="Nationality ..."
                className="iso-select"
                {...selectFieldProps}
                onChange={handleSelect}
                name="nationality"
                options={countries}
              />
              <span>Race: {race}</span>
              <Select
                placeholder="Race / Ethnicity ..."
                className="iso-select"
                {...selectFieldProps}
                onChange={handleSelect}
                name="race"
                options={races}
              />
              <input
                autoComplete="off"
                style={{ opacity: 0, height: 0, marginTop: -20, width: 0 }}
                value={state.race}
                required
              />
              <span>Gender: {gender}</span>
              <Select
                placeholder="Gender ..."
                className="iso-select"
                {...selectFieldProps}
                onChange={handleSelect}
                name="gender"
                options={genders}
              />
            </Grid>
          </Grid>
          {state.error ? (
            <span className="iso-error">{state.error}</span>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={handleClose}
            disabled={state.loading}
          >
            Cancel
          </Button>
          <Button
            startIcon={<SaveOutlined />}
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={state.loading}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
