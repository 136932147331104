import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";

export default function MocManagerMenuItem({ classes }) {
  const [open, setOpen] = useState(false);
  const { currentUser } = useAuth();
  return (
    <>
      <ListItem button onClick={() => setOpen(!open)}>
        <ListItemIcon>
          <FontAwesomeIcon className={classes.icons} icon={faExchangeAlt} />
        </ListItemIcon>
        <ListItemText className={classes.menuText}>MOC Manager</ListItemText>
        {open ? (
          <ExpandLess className={classes.icon} />
        ) : (
          <ExpandMore className={classes.icon} />
        )}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {" "}
        {!currentUser.mocSetupComplete && currentUser.companyAdmin && (
          <List component="div" className={classes.subMenuItem} dense>
            <ListItem>
              <Link to="/moc-setup" className="drawer-item">
                <ListItemText className={classes.menuText}>
                  MOC Setup
                </ListItemText>
              </Link>
            </ListItem>
          </List>
        )}
        {currentUser.mocSetupComplete && (
          <List component="div" className={classes.subMenuItem} dense>
            {currentUser.companyAdmin ||
            currentUser.accessRights.mocManager < 1 ? (
              <ListItem>
                <Link to="/moc-dashboard" className="drawer-item">
                  <ListItemText className={classes.menuText}>
                    MOC Dashboard
                  </ListItemText>
                </Link>
              </ListItem>
            ) : null}
            {currentUser.companyAdmin ||
            currentUser.accessRights.mocManager < 1 ? (
              <ListItem>
                <Link to="/moc-register" className="drawer-item">
                  <ListItemText className={classes.menuText}>
                    MOC Register
                  </ListItemText>
                </Link>
              </ListItem>
            ) : null}
            {currentUser.companyAdmin ||
            currentUser.accessRights.trainingManager < 1 ? (
              <ListItem>
                <Link to="/moc-checklist-edit" className="drawer-item">
                  <ListItemText className={classes.menuText}>
                    MOC Checklist
                  </ListItemText>
                </Link>
              </ListItem>
            ) : null}
            {currentUser.companyAdmin ||
            currentUser.accessRights.trainingManager < 1 ? (
              <ListItem>
                <Link to="/moc-risk-settings" className="drawer-item">
                  <ListItemText className={classes.menuText}>
                    Level of Risk
                  </ListItemText>
                </Link>
              </ListItem>
            ) : null}
            {currentUser.companyAdmin ||
            currentUser.accessRights.trainingManager < 1 ? (
              <ListItem>
                <Link to="/moc-risk-questionnaire-edit" className="drawer-item">
                  <ListItemText className={classes.menuText}>
                    Risk Questionnaire
                  </ListItemText>
                </Link>
              </ListItem>
            ) : null}
            {currentUser.companyAdmin ||
            currentUser.accessRights.trainingManager < 1 ? (
              <ListItem>
                <Link to="/moc-benefits-settings" className="drawer-item">
                  <ListItemText className={classes.menuText}>
                    Expected Benefits
                  </ListItemText>
                </Link>
              </ListItem>
            ) : null}
          </List>
        )}
      </Collapse>
    </>
  );
}
