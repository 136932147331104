import {
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useDb } from "../../../contexts/DatabaseContext";
import moment from "moment";
import { NotInterested } from "@mui/icons-material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { textFieldProps } from "../../shared/constants";

export default function ActionExtensionApproval() {
  const { actionId } = useParams();
  const { GetActionExtensionRequests, extensionApproval } = useDb();
  const reqs = GetActionExtensionRequests(actionId);
  const [reqID, setReqID] = useState(false);
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleExtensionApproval = async (reqId, approved) => {
    // e.preventDefault()
    setLoading(true);
    setError(null);
    if (approved) {
      // Handle Approved Case
      try {
        await extensionApproval(
          actionId,
          reqId,
          approved,
          "Approved",
          reason
        ).then(() => {
          setLoading(false);
        });
      } catch (err) {
        setLoading(false);
        setError(null);
      }
    } else {
      // Handle Declined Case
      try {
        await extensionApproval(
          actionId,
          reqId,
          approved,
          "Declined",
          reason
        ).then(() => {
          setLoading(false);
          handleClose();
        });
      } catch (err) {
        setLoading(false);
        setError(null);
      }
    }
  };

  const handleOpen = (reqId) => {
    setReqID(reqId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <h1>Action Extensions</h1>
      <Card>
        <CardContent>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Action No.</TableCell>
                  <TableCell>Reason</TableCell>
                  <TableCell>Declined Reason</TableCell>
                  <TableCell>Requested Date</TableCell>
                  <TableCell>Requested By</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reqs &&
                  reqs.map((r) => {
                    return (
                      <TableRow key={r.id}>
                        <TableCell>
                          {moment(r.timestamp.toDate()).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell>
                          <Link to={`/action/${r.action.id}`}>
                            {r.action.actionNumber}
                          </Link>
                        </TableCell>
                        <TableCell>{r.reason}</TableCell>
                        {r.declineReason ? (
                          <TableCell>{r.declineReason}</TableCell>
                        ) : (
                          <TableCell> </TableCell>
                        )}
                        <TableCell>
                          {moment(r.requestedDate.toDate()).format(
                            "DD/MM/YYYY"
                          )}
                        </TableCell>
                        <TableCell>
                          {r.action.hod.firstName} {r.action.hod.lastName}
                        </TableCell>
                        <TableCell>{r.status ? r.status : "Pending"}</TableCell>
                        <TableCell>
                          {!r.status ? (
                            <ButtonGroup>
                              <IconButton
                                color="secondary"
                                onClick={() => handleOpen(r.id)}
                                size="large"
                              >
                                <NotInterested />
                              </IconButton>
                              <IconButton
                                color="primary"
                                onClick={() =>
                                  handleExtensionApproval(r.id, true)
                                }
                                size="large"
                              >
                                <ThumbUpIcon />
                              </IconButton>
                            </ButtonGroup>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <CardActions></CardActions>
      </Card>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogContent>
          <TextField
            {...textFieldProps}
            label="Reason"
            required
            onChange={(e) => setReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleExtensionApproval(reqID, false)}
            color="primary"
            variant="contained"
          >
            Confirm
          </Button>
          <Button onClick={handleClose} color="secondary" variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
