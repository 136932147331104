import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { selectFieldProps, textFieldProps } from "../../shared/constants";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { useDb } from "../../../contexts/DatabaseContext";
import Select from "react-select";
import { Alert } from "@mui/material";

export default function AddProgram({ target, open, closeDialog }) {
  const { GetUsersLevelFilter, addProgram, GetOtpMetrics } = useDb();
  const responsibles = GetUsersLevelFilter("otpManager", 1);
  const closers = GetUsersLevelFilter("otpManager", 2);
  const metrics = GetOtpMetrics();
  const [closersFinal, setClosersFinal] = useState(null);

  // Form State
  const [state, setState] = useState({
    targetDate: new Date(),
    open: true,
    progress: "...",
  });

  // Action State
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setState({
      targetDate: new Date(),
    });
    setLoading(false);
    closeDialog();
  };

  const [measurementType, setMeasurementType] = useState("cumulative");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    try {
      await addProgram(target.otpId, target, {
        ...state,
        measurementType,
      }).then(() => {
        handleClose();
      });
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const handleResponsibleSelect = (val) => {
    const closersFiltered = closers.filter((el) => {
      return el.value.id !== val.value.id;
    });
    setClosersFinal(closersFiltered);
    setState({
      ...state,
      responsible: val.value,
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Add Program to Target # {target.targetNo}</DialogTitle>
        <DialogContent>
          <TextField
            {...textFieldProps}
            required
            label="Program Description"
            onChange={(e) =>
              setState({ ...state, description: e.target.value })
            }
          />
          <FormControl component="fieldset">
            <FormLabel component="legend">Measurement Type</FormLabel>
            <RadioGroup
              name="type"
              value={measurementType}
              onChange={(e) => setMeasurementType(e.target.value)}
            >
              <FormControlLabel
                value="cumulative"
                control={<Radio />}
                label="Cumulative Progress"
              />
              <FormControlLabel
                value="continuous"
                control={<Radio />}
                label="Continuous Monitoring"
              />
            </RadioGroup>
          </FormControl>
          <br />
          <TextField
            {...textFieldProps}
            required
            label="Resources Required"
            onChange={(e) => setState({ ...state, resources: e.target.value })}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Target Date"
              onChange={(d) => setState({ ...state, targetDate: d.toDate() })}
              defaultValue={dayjs(state.targetDate)}
            />
          </LocalizationProvider>
          <br />
          <br />
          <Select
            {...selectFieldProps}
            options={responsibles}
            required
            // onChange={v => setState({...state, responsible: v.value})}
            onChange={handleResponsibleSelect}
            placeholder="HOD Responsible for Program..."
            className="iso-select"
          />
          <Select
            {...selectFieldProps}
            options={closers}
            required
            onChange={(v) => setState({ ...state, closer: v.value })}
            placeholder="Person To Execute Program ..."
            className="iso-select"
          />
          <TextField
            {...textFieldProps}
            required
            label="Program Measurement"
            onChange={(e) =>
              setState({ ...state, measurement: e.target.value })
            }
          />
          <Select
            {...selectFieldProps}
            options={metrics}
            required
            onChange={(v) => setState({ ...state, metric: v.value })}
            placeholder="Measurement Metric..."
            className="iso-select"
            menuPlacement="top"
          />
          <TextField
            {...textFieldProps}
            required
            type="number"
            label="Measurement Interval (In Days)"
            onChange={(e) =>
              setState({ ...state, measurementInterval: e.target.value })
            }
          />
          {state.metric && measurementType === "continuous" && (
            <Alert severity="info">
              You will have the opportunity to enter historical data to
              determine an opportunity range and adjust your target if required.
            </Alert>
          )}
          {state.metric ? (
            <TextField
              {...textFieldProps}
              required
              type="number"
              label={`${
                measurementType === "continuous" ? "Target" : "Target"
              } ${state.metric.metricName} for Program`}
              onChange={(e) =>
                setState({ ...state, targetNum: e.target.value })
              }
            />
          ) : null}
        </DialogContent>
        <DialogActions>
          {error ? <span className="iso-error">{error}</span> : null}
          <Button disabled={loading} color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={
              loading ||
              !state.responsible ||
              !state.closer ||
              !state.metric ||
              !state.measurementInterval ||
              !state.targetNum
            }
            color="primary"
            type="submit"
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
