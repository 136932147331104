import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import { Avatar, Button, Card, CardContent, CardHeader, Chip, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material'
import { faEnvelope, faMinusCircle, faPaperPlane, faPlusSquare, faUserTag } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { selectFieldProps } from '../../shared/constants'
import Select from 'react-select'

export default function AddRevisionApproversN() {
    const { docId, revId } = useParams()
    const { 
        savePartyToRevision, 
        removePartyFromRevision,
        bulkEmailRevParties, 
        GetInterestedParties,
        GetUsersLevelFilter
    } = useDb()
    const rosters = GetUsersLevelFilter('docManager', 1)
    const parties = GetInterestedParties(docId, true, revId)
    const [party, setParty] = useState(null)
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [emailDisable, setEmailDisable] = useState(false) 

    const addParty = async (p) => {
        setLoading(true) 
        const result = await savePartyToRevision(p, docId, revId)
        if (result !== null) {
            setParty(null)
            setLoading(false)
            setEmailDisable(false)
        }
        else {
            setError('Error Saving to Database')
            setLoading(false)
            setEmailDisable(true)
        }
    }

    const removeParty = async (p) => {
        await removePartyFromRevision(docId, p.id, revId)
    }

    const emailAll = async () => {
        const result = await bulkEmailRevParties(docId, revId, parties)
        if (result !== null) {
            setEmailDisable(true)
        }
        else {
            console.log('Error saving to Database Email Log')
        }
    }

    const emailOne = async (pty) => {
        const result = await bulkEmailRevParties(docId, revId, [pty])
        if (result !== null) {
            setEmailDisable(true)
        }
        else {
            console.log('Error saving to Database Email Log')
        }
    }

    return (
        <div>
            <Select 
                options={rosters}
                placeholder="Add Approver ..."
                onChange={(val => setParty(val))}
                {...selectFieldProps}
            />
            <span className="iso-small" style={{ color: 'red' }}>{error}</span>
            <br/>
            <Button onClick={() => addParty(party, revId)} disabled={party !== null ? false : true } color="primary" variant="contained" startIcon={<FontAwesomeIcon icon={faPlusSquare} />}>Add New</Button>&nbsp;
            <Button disabled={emailDisable} onClick={emailAll} color="primary" variant="contained" startIcon={<FontAwesomeIcon icon={faEnvelope} />}>Send Email</Button>
            <br/><br/>
            
            <Card>
                <CardHeader
                    title="Approvers:"
                    avatar={<Avatar><FontAwesomeIcon icon={faUserTag}/></Avatar>}
                />
                <CardContent>
                    <List>
                        {
                            parties && parties.map((pty) => {
                                return (
                                    <ListItem key={pty.id} className="iso-list-item">
                                        <ListItemText><span className="iso-small">{`${pty.firstName} ${pty.lastName}`} <Chip size="small" label={pty.status}/></span></ListItemText>
                                        <ListItemSecondaryAction>
                                            <IconButton onClick={() => removeParty(pty, revId)} size="large"><FontAwesomeIcon icon={faMinusCircle}/></IconButton>
                                            <IconButton onClick={() => emailOne(pty)} size="large"><FontAwesomeIcon icon={faPaperPlane}/></IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                );
                            })
                        }
                    </List>
                </CardContent>
            </Card>
        </div>
    );
}
