import { Avatar, Card, CardHeader, IconButton } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import React from "react";
import { useAuth } from "../../../../contexts/AuthContext";

export default function ParticipantCard({ p, handleDelete }) {
  const { currentUser } = useAuth();
  return (
    <Card style={{ marginBottom: 10 }}>
      <CardHeader
        title={p.user?.label}
        avatar={<Avatar>{p.user?.label[0]}</Avatar>}
        action={
          currentUser.companyAdmin ? (
            <IconButton size="large">
              <Delete onClick={() => handleDelete(p)} />
            </IconButton>
          ) : (
            <></>
          )
        }
      />
    </Card>
  );
}
