import React, { useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { Button, Card, CardActions, CardContent, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { ArrowLeftOutlined, Edit } from '@mui/icons-material'
import { useDb } from '../../../../contexts/DatabaseContext'
import CoEditPry from './CoEditPry'

export default function CoPriorities() {
    const { coId } = useParams()
    const { MgmtGetPriorities } = useDb()
    const priorities = MgmtGetPriorities(coId)

    const [editOpen, setEditOpen] = useState(false)
    const [selectedPry, setSelectedPry] = useState(null)

    const handleEdit = (pry) => {
        setSelectedPry(pry)
        setEditOpen(true)
    }

    return (
        <div>
            <h1 style={{ color: '#fff' }}>Company Action Priorities</h1>
            <Card>
                <CardContent>
                    <Link to={`/companies/${coId}`}>
                        <Button startIcon={<ArrowLeftOutlined />} color="primary" variant="contained">Back</Button>
                    </Link>
                    <br/><br/>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Priority</TableCell>
                                    <TableCell>Days</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    priorities && priorities
                                    .map(p => {
                                        return (
                                            <TableRow key={p.id}>
                                                <TableCell>{p.name}</TableCell>
                                                <TableCell>{p.days}</TableCell>
                                                <TableCell>
                                                    <IconButton onClick={() => handleEdit(p)} size="large">
                                                        <Edit />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
                <CardActions>
                </CardActions>
            </Card>

            {
                selectedPry
                ?
                <CoEditPry open={editOpen} closeDialog={() => setEditOpen(false)} coId={coId} pry={selectedPry} />
                :
                null
            }
            
        </div>
    );
}
