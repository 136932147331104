import { Button, Dialog, DialogActions, DialogContent, Divider, TextField, Typography, Grid } from '@mui/material'
import React, { useState } from 'react'
import { useDb } from '../../contexts/DatabaseContext'
import { useAuth } from '../../contexts/AuthContext'
import Select from 'react-select'
import { textFieldProps, selectFieldProps } from '../shared/constants'
import { Save } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'

export default function CreateOTP({ open, closeDialog }) {
    const { currentUser } = useAuth()
    const { GetDepartments, GetCriteria, CreateOTP, GetSiteList } = useDb()
    const history = useHistory()
    
    // Database Calls
    const departments = GetDepartments()
    const criterias = GetCriteria()    
    const sites = GetSiteList()
    
    // Component State
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    
    // Form State
    const [department, setDepartment] = useState(null)
    const [criteria, setCriteria] = useState(null)
    const [defineObjectives, setDefinedObjectives] = useState(null)
    const [site, setSite] = useState(null)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        const thisDate = new Date()
        const thisYear = thisDate.getFullYear()
        
        try {
            await CreateOTP({
                department,
                criteria,
                site,
                defineObjectives,
                timestamp: new Date(),
                createdBy: currentUser.uid,
                obsolete: false,
                loggedBy: {
                    firstName: currentUser.uid.firstname ? currentUser.uid.firstname : "",
                    lastName: currentUser.uid.lastName ? currentUser.uid.lastName : "",
                    email: currentUser.email ? currentUser.email : "",
                    uid: currentUser.uid ? currentUser.uid : ""
                },
                targetCount: 0,
            }).then((otpId) => {
                setLoading(false)
                history.push(`/otp/${otpId}/targets`)
                closeDialog()
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }
    
    return (
        <>
            <Dialog open={open} maxWidth="sm" fullWidth onClose={closeDialog}>
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography>Add Objective</Typography>
                                <br/>
                                <Select
                                    options={departments}
                                    {...selectFieldProps}
                                    placeholder={'Select Department ...'}
                                    onChange={(val) => setDepartment(val.value)}
                                    className={'iso-select'}
                                    required
                                />
                                
                                
                                <Select
                                    options={criterias}
                                    {...selectFieldProps}
                                    placeholder={'Select Criteria ...'}
                                    onChange={(val) => setCriteria(val.value)}
                                    className={'iso-select'}
                                    required
                                />
                                <Select
                                    options={sites}
                                    {...selectFieldProps}
                                    placeholder="Select Site..."
                                    onChange={(val) => setSite(val.value)}
                                    required
                                />
                                <br/>
                                <TextField 
                                    className={'iso-text-field'} 
                                    {...textFieldProps} 
                                    label="Defined Objectives" 
                                    multiline 
                                    rows={3} 
                                    required 
                                    onChange={(e) => setDefinedObjectives(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                        { error ? <span className={'iso-error'}>{error}</span> : null }
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={closeDialog} color="secondary">Cancel</Button>
                        <Button disabled={loading} type="submit" variant="contained"  color="primary" startIcon={<Save />}>Submit</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}
