import { faArrowAltCircleRight, faEnvelope, faEnvelopeOpen, } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Card, CardHeader } from '@mui/material'
import React, { useState } from 'react'
import moment from 'moment'
import { Link, useHistory } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import { useAuth } from '../../../contexts/AuthContext'
import { truncateString } from '../../shared/constants'

export default function Notification({ notif, closeMenu }) {
    const { markNotificationAsRead, markNotificationAsUnread } = useDb()
    const { currentUser } = useAuth()
    const [error, setError] = useState(null)
    const history = useHistory()

    const styles = {
        read: {
            fontWeight: 'normal',
            cursor: 'pointer'
        },
        unread: {
            fontWeight: 'bold',
            cursor: 'pointer'
        },
        readIcon: {
            cursor: 'pointer'
        },
        unreadIcon: {
            color: '#fff',
            backgroundColor: '#007ad2',
            cursor: 'pointer'
        },
        unreadCard: {
            width: 350,
            borderBottom: '3px solid #007ad2'
        },
        readCard: {
            width: 350,
        }
    }

    const markAsRead = async () => {
        try {
            await markNotificationAsRead(currentUser.uid, notif.id)
        }
        catch(err) {
            setError(err.message)
            console.log(err.message)
        }
    }

    const markAsUnread = async () => {
        try {
            await markNotificationAsUnread(currentUser.uid, notif.id)
        }
        catch(err) {
            setError(err.message)
            console.log(err.message)
        }
    }

    const markAsReadAndNavigate = async () => {
        try {
            await markNotificationAsRead(currentUser.uid, notif.id)
        }
        catch(err) {
            setError(err.message)
            console.log(err.message)
        }
        history.push(notif.link)
        closeMenu()
    }

    const truncTitle = truncateString(notif.text, 40)
    return (
        <Card className={notif.read ? "iso-notif-card iso-notif-card-read" : "iso-notif-card  iso-notif-card-unread"}>
            <CardHeader
                avatar={<Avatar onClick={markAsReadAndNavigate}  style={notif.read ? styles.readIcon : styles.unreadIcon}><FontAwesomeIcon icon={notif.read ? faEnvelopeOpen : faEnvelope}/></Avatar>}
                // title={
                //     <Link to={notif.link}>
                //         <span onClick={markAsRead}  style={notif.read ? styles.read : styles.unread}>{truncTitle}</span>
                //     </Link>
                // }
                title={<span onClick={markAsReadAndNavigate}  style={notif.read ? styles.read : styles.unread}>{truncTitle}</span>}
                subheader={moment(notif.timestamp.toDate()).fromNow()}
                action={
                    <div>
                        <div style={{ cursor: 'pointer'}} onClick={markAsReadAndNavigate} className="iso-right">
                            <span style={{ color: '#96b4d4' }}>
                                View &nbsp;
                            </span> 
                            <FontAwesomeIcon color={'#96b4d4'} icon={faArrowAltCircleRight}/>
                        </div><br/><br/>
                        <span onClick={notif.read ? markAsUnread : markAsRead} className="iso-small iso-right" style={{ color: '#007ad2', cursor: 'pointer'}}>{notif.read ? 'Mark as unread' : 'Mark as read'}</span>
                    </div>
                }
            />
        </Card>
    )
}
