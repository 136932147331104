import { Button, CardActions, TextField, Typography } from '@mui/material'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Select from 'react-select'
import { useDb } from '../../../contexts/DatabaseContext'
import { selectFieldProps, textFieldProps } from '../../shared/constants'

export default function NewAuditChecklist() {
    const history = useHistory()
    const { createChecklist } = useDb()

    const types = [
        { value: 'audit', label: 'Audit' },
        { value: 'inspection', label: 'Inspection' }
    ]

    const [state, setState] = useState({
        loading: false,
        error: null,
        addOpen: false,
        data: {}
    })

    const handleOpen = () => {
        setState({
            ...state,
            addOpen: true
        })
    }

    const handleChange = (e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleSelect = (v, e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.name]: v.value
            }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setState({
            ...state,
            loading: true,
            error: null
        })
        try {
            const checklistId = await createChecklist(state.data)
            history.push(`/audit-manager/checklists/builder/${checklistId}`)
        }
        catch(err) {
            setState({
                ...state,
                loading: false,
                error: err.message
            })
        }
    }

    return (
        <div>
            <h1>New Checklist</h1>
            <div className="back-link">
                <Button
                    color="primary"
                    variant="contained"
                    startIcon={<ArrowBack />}
                    onClick={() => history.goBack()}
                >
                    Back
                </Button>
            </div>
            <form onSubmit={handleSubmit}>
                <Select
                    options={types}
                    {...selectFieldProps}
                    placeholder="Type ..."
                    className="iso-select"
                    onChange={handleSelect}
                    name="type"
                />
                <TextField
                    label="Title"
                    {...textFieldProps} 
                    onChange={handleChange}
                    name="title"
                    required
                />
                <TextField
                    label="Description"
                    {...textFieldProps} 
                    onChange={handleChange}
                    name="description"
                    placeholder="Scope / Objective"
                    required
                />
                {
                    state.error && <Typography color="secondary">{state.error}</Typography>
                }
                <CardActions className="iso-right">
                    {/* TODO: Disable button if there are no sections */}
                    <Button
                        color="primary"
                        variant="contained"
                        endIcon={<ArrowForward />}
                        type="submit"
                        disabled={ state.loading || !state.data.type }
                    >
                        Next: Form Builder
                    </Button>
                </CardActions>
            </form>

        </div>
    )
}
