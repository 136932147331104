import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    Grid,
    Card,
    CardContent,
    Divider,
    Button,
    List,
    TextField,
    CircularProgress,
    Dialog,
    DialogContent,
    CardActions,
    ButtonGroup,
    IconButton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Pagination } from '@mui/material';
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import AddDocType from './AddDocType'
import DocTypeTile from './DocTypeTile'
import EditDocType from './EditDocType'
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid'
import { Delete, Edit } from '@mui/icons-material'
import FloatingHelpComponent from '../../help/FloatingHelpComponent'

const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: theme.palette.cardBg
    },
    dialog: {
        backgroundColor: theme.palette.dialogBg
    }
}))

export default function DocTypeList() {
    const classes = useStyles()
    const { GetDocTypes, deleteDocType } = useDb()
    const docTypes = GetDocTypes()
    const [search, setSearch] = useState(null)
    const [error, setError] = useState(null)
    const [dialogOpen, setDialogOpen] = useState(false)

    const closeDialog = () => {
        setDialogOpen(false)
    }

    // Pagination
    const itemsPerPage = 10; // <- Not state but related to below pagination
    const [page, setPage] = useState(1);
    const [noOfPages] = useState(
        Math.ceil(docTypes.length / itemsPerPage)
    );

    // Editing
    const [editOpen, setEditOpen] = useState(false)
    const [selectedDocType, setSelectedDocType] = useState(null)
    const handleEdit = (d) => {
        setSelectedDocType(d)
        setEditOpen(true)
    }
    const handleDelete = async (d) => {
        try {
            await deleteDocType(d.id)
        }
        catch(err) {
            setError(err.message)
        }
    }

    const columns = [
        {field: "type", headerName: "Type", width: 180 },
        {field: "code", headerName: "Code", width: 180 },
        {field: "category", headerName: "Category", width: 180 },
        {
            field: 'action',
            headerName: 'Action',
            width: 180,
            renderCell: (params) => {
                    return (
                        <ButtonGroup>
                            <IconButton onClick={() => handleEdit(params.row)} size="large">
                                <Edit />
                            </IconButton>
                            <IconButton onClick={() => handleDelete(params.row)} size="large">
                                <Delete />
                            </IconButton>
                        </ButtonGroup>
                    );
            }
        }
    ]
    
    return (
        <div>
            <h1>Document Types</h1>
            <Button onClick={() => setDialogOpen(true)} variant="contained" color="primary" startIcon={<FontAwesomeIcon icon={faPlusSquare} />}>Add Document Type</Button>
            <br /><br />
            <IsoDataGrid
                data={docTypes}
                columns={columns}
                />
            {
                selectedDocType
                ?
                <EditDocType open={editOpen} closeDialog={() => setEditOpen(false)} docType={selectedDocType} />
                :
                null
            }
            <Dialog maxWidth="sm" fullWidth open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogContent className={classes.dialog}>
                    <AddDocType closeDialog={closeDialog} />
                </DialogContent>
            </Dialog>

            <FloatingHelpComponent
                name="Document Types" 
                videoPath="doc_manager"
                videoIndex={3}
            />
        </div>
        
    )
}
