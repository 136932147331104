import { CardHeader, Card, CardContent, Avatar, Grid, CircularProgress, Typography } from '@mui/material'
import { CalendarTodaySharp } from '@mui/icons-material'
import React, { useState } from 'react'
import Chart from "react-apexcharts"
import { useAuth } from '../../../contexts/AuthContext'
import { useDb } from '../../../contexts/DatabaseContext'

export default function TrainingChart() {
    const { currentUser } = useAuth()
    const { getYearSessionCount } = useDb()
    
    const [state, setState] = useState({
          
        series: [
            {
            name: 'Sessions',
            data: [0,0,0,0,0,0,0,0,0,0,0,0]
            },
        ],
        options: {
          chart: {
            height: 350,
            type: 'area',
            toolbar: {
                show: false
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          },
          xaxis: {
            type: 'string',
            categories: ["January", "February", "March", "April" ,"May", "June" ,"July", "August", "September", "October","November", "December"],
            labels: {
                style: {
                    colors: currentUser.theme === 'dark' ? ['#fff'] : ['#333']
                }
            }
          },
          yaxis: {
            labels: {
                style: {
                    colors: currentUser.theme === 'dark' ? ['#fff'] : ['#333']
                }
            }
        },
          tooltip: {
            x: {
              format: 'dd/MM/yy HH:mm'
            },
            animations: {
                enabled: true,
                easing: 'easeout',
                speed: 800,
                animateGradually: {
                    enabled: true,
                    delay: 450
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 100
                }
            }
        }
        
        }
    })
    const [actCounts, setActCounts] = useState({
        total: 0,
        open: 0,
        overdue: 0
    })

    const [loaded, setLoaded] = useState(false)

    const firstLoad = async () => {
        const sessionCount = await getYearSessionCount()
        const months = {"January": 1,"February": 2,"March": 3,"April": 4,"May": 5,"June": 6,"July": 7,"August": 8,"September": 9,"October": 10,"November": 11,"December": 12};
        let seriesArray = [0,0,0,0,0,0,0,0,0,0,0,0]
        for ( var month in sessionCount) {
            const position = months[month] - 1 
            seriesArray.splice(position, 1, sessionCount[month])
        }
        console.log(seriesArray)
        setState({
            ...state,
            series: [
                {
                    name: 'Sessions',
                    data: seriesArray
                }
            ],
        })
        setActCounts({
        })
        setLoaded(true)
    }

    if(!loaded) {
        firstLoad()
    }
    return (
        <div>
            <Card>
                <CardHeader 
                    title="Training Sessions This Year"
                    avatar={
                        <Avatar>
                            <CalendarTodaySharp />
                        </Avatar>
                    }
                />

                <CardContent>
                    <Chart options={state.options} series={state.series} type="area" height={350} />
                </CardContent>
            </Card>
            
        </div>
    )
}
