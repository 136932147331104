import { Avatar, Button, Card, CardActions, CardContent, CardHeader, Grid, Slider, Typography } from '@mui/material'
import { ArrowForward, Info } from '@mui/icons-material'
import React, { useState } from 'react'
import { useAuth } from '../../../../contexts/AuthContext'
import { useDb } from '../../../../contexts/DatabaseContext'
import RiskMatrix from '../../risk-matrix/RiskMatrix'
import { useParams, useHistory } from 'react-router-dom'

export default function AddRisk2RawRisk() {
    const { currentUser } = useAuth()
    const { updateBlraRisk, updateIbraRisk } = useDb()
    const { assId, riskId, type } = useParams()
    const history = useHistory()
    const riskNum = parseInt(currentUser.riskSettings.riskMatrix.split('x')[0])

    const [state, setState] = useState({
        initialLike: null,
        initialCons: null,
        loading: false,
        error: null
    })

    const handleChange = (e, val, name) => {
        setState({
            ...state,
            [name]: val
        })
    }

    const handleSubmit = async () => {
        setState({
            ...state,
            loading: true,
            error: null
        })
        try {
            type === 'blra'
            ?
            await updateBlraRisk(assId, riskId, { 
                initialLike: state.initialLike, 
                initialCons: state. initialCons,
                riskValue: state.initialLike * state.initialCons,
                currentStep: 3
            }).then(() => {
                history.push(`/risk-manager/baselines/${assId}/risk/${riskId}/3`)
            })
            :
            await updateIbraRisk(assId, riskId, {
                initialLike: state.initialLike, 
                initialCons: state. initialCons,
                currentStep: 3
            }).then(() => {
                history.push(`/risk-manager/issue-based/${assId}/ppesigns/${riskId}`)
            })
        }
        catch(err) {
            setState({
                ...state,
                loading: false,
                error: err.message
            })
        }
    }

    return (
        <div>
            <h1>Inherent Risk</h1>
            <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                    <Card>
                        <CardContent>
                            <CardHeader
                                title="Inherent Risk"
                                avatar={
                                    <Avatar>
                                        <Info />
                                    </Avatar>
                                }
                            />
                            <Typography>
                                Likelihood
                            </Typography>
                            <br/>
                            <Slider
                                defaultValue={1}
                                step={1}
                                marks
                                min={1}
                                max={riskNum}
                                valueLabelDisplay="on"
                                onChangeCommitted={(e,v) => handleChange(e,v,'initialLike')}
                            />
                            <Typography>
                                Consequences
                            </Typography>
                            <br/>
                            <Slider
                                defaultValue={1}
                                step={1}
                                marks
                                min={1}
                                max={riskNum}
                                valueLabelDisplay="on"
                                onChangeCommitted={(e,v) => handleChange(e,v,'initialCons')}
                            />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={9}>
                    <div style={{ float: 'right' }}>
                        <RiskMatrix 
                            type={currentUser.riskSettings.riskMatrix}
                            initialLike={state.initialLike}
                            initialCons={state.initialCons}
                        />
                    </div>
                </Grid>
            </Grid>
            <br/>
            <CardActions className="iso-right">
                {
                    state.error && <Typography color="secondary">{state.error}</Typography>
                }
                <Button
                    variant="contained"
                    color="primary"
                    endIcon={<ArrowForward />}
                    disabled={
                        !state.initialLike || 
                        !state.initialCons ||
                        state.loading
                    }
                    onClick={handleSubmit}
                >
                    Next
                </Button>
            </CardActions>
        </div>
    )
}
