import { Alert } from '@mui/material';
import React from "react";

export default function MocAccessDenied() {
  return (
    <div>
      <Alert severity="error">
        You are not authorized to access this MOC at this time
      </Alert>
    </div>
  );
}
