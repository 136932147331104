import { ButtonGroup, IconButton } from '@mui/material'
import { Delete, Edit } from '@mui/icons-material'
import React from 'react'
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid'

export default function SiteDataGrid({data, handleDelete, handleEdit}) {

  const rows = data.map(d => ({
    id: d.id,
    name: d.name,
    site: d
  }))

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 400
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => {
        return (
          <ButtonGroup>
            <IconButton onClick={() => handleEdit(params.row.site)} size="large"><Edit /></IconButton>
            <IconButton onClick={() => handleDelete(params.row.site)} size="large"><Delete /></IconButton>
          </ButtonGroup>
        );
      }
    }
  ]

  return (
    <div>
      <IsoDataGrid
        data={rows}
        columns={columns}
      />
    </div>
  )
}
