import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    Card,
    CardActions,
    CardContent,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Search } from '@mui/icons-material'
import { Pagination } from '@mui/material';
import React, {useState} from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import { textFieldProps } from '../../shared/constants'
import { Link } from 'react-router-dom'
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: theme.palette.cardBg,
        padding: 10,
        marginTop: 10
    }
}))

export default function EmployeeList() {
    const classes = useStyles()
    const { GetEmployees, GetSites } = useDb()
    const employees = GetEmployees()
    const sites = GetSites()

    const getSiteName = (e) => {
        let siteName = ""
        sites && sites.map(s => {
            if(s.value === e.site) {
                siteName = s.label
            } else {
                siteName = 'N/A'
            }
        })
        return siteName
    }

    const rows = employees && employees.map(e =>({
        ...e,
        id: e.id,
        employmentDate: moment(e.employmentDate.toDate()).format('DD/MM/YYYY'),
        firstName: e.firstName,
        lastName: e.lastName,
        email: e.email,
        department: e.department ? e.department.name : "N/A",
        contactNumber: e.contactNumber,
        employeeNo: e.employeeNumber,
        employeeType: e.employeeType,
        reportsTo: e.manager ? `${e.manager.firstName} ${e.manager.lastName}` : 'N/A',
        site: getSiteName(e)
    }))

    const columns = [
        { 
            field: 'action', 
            headerName: 'View Detail', 
            sortable: false,
            width: 110,
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                        <Link to={`/training-manager/employees/detail/${params.row.id}`}>
                            <IconButton size="large"><Search /></IconButton>
                        </Link>
                     </div>
                );
            }
        },
        { field: 'employmentDate', headerName: 'Employment' , width: 170 },
        { field: 'firstName', headerName: 'First Name' , width: 170 },
        { field: 'lastName', headerName: 'Last Name' , width: 170 },
        { field: 'email', headerName: 'Email' , width: 170 },
        { field: 'contactNumber', headerName: 'Contact No', width: 170 },
        { field: 'department', headerName: 'Department', width: 170 },
        { field: 'employeeNo', headerName: 'Employee No', width: 170 },
        { field: 'employeeType', headerName: 'Employee Type', width: 180 },
        { field: 'reportsTo', headerName: 'Reports To', width: 180 },
        { field: 'gender', headerName: 'Gender', width: 180 },
        { field: 'race', headerName: 'Race', width: 180 },
        { field: 'nationality', headerName: 'Nationality', width: 180 },
        { field: 'site', headerName: 'Site', width: 180 },
        
    ]

    return (
        <Card className={classes.card}>
            <IsoDataGrid
                data={rows}
                columns={columns}
            />
        </Card>
    )
}
