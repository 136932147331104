import { Avatar, Button, Card, CardContent, CardHeader, CircularProgress, Grid, Typography } from '@mui/material'
import { ArrowBack, Info, Warning } from '@mui/icons-material'
import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import moment from 'moment'

export default function IssueDetail() {
    const { issueId } = useParams()
    const history = useHistory()
    const [issue, setIssue] = useState(null)
    const { getIssue } = useDb()

    const firstLoad = async () => {
        const i = await getIssue(issueId)
        console.log(i)
        setIssue(i)
    }

    if(!issue) {
        firstLoad()
        return <CircularProgress />
    }
    else return (
        <div>
            <h1>Issue on {issue.inspection.jobRef}</h1>
            <div className="back-link">
                <Button
                    color="primary"
                    variant="contained"
                    startIcon={<ArrowBack />}
                    onClick={() => history.goBack()}
                >
                    Back
                </Button>
            </div>
            <Grid container spacing={3}>
                <Grid item xs={12} md={7}>
                    <Card>
                        <CardHeader
                            title="Issue Details"
                            avatar={<Avatar><Warning /></Avatar>}
                        />
                        <CardContent>
                            <Typography>Created: {moment(issue.timestamp.toDate()).format('DD/MM/YYYY HH:MM')}</Typography>
                            <Typography>Field: {issue.field}</Typography>
                            <Typography>Description of Issue: {issue.description}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Card>
                        <CardHeader
                            title="Inspection Details"
                            avatar={<Avatar><Info /></Avatar>}
                            />
                        <CardContent>
                            <Typography>Code: {issue.inspection.jobRef}</Typography>
                            <Typography>Title: {issue.inspection.title}</Typography>
                            <Typography>Type: {issue.inspection.type}</Typography>
                            <Typography>Assigned To: {issue.inspection.assignedTo.label}</Typography>
                            <Typography>Due Date: {moment(issue.inspection.dueDate.toDate()).format('DD/MM/YYYY')}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}
