import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  TextField,
} from "@mui/material";
import { Add, Info, People, Save } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDb } from "../../../../contexts/DatabaseContext";
import BackLink from "../../../shared/BackLink";
import ParticipantCard from "./ParticipantCard";
import AddParticipant from "../../baseline/new-assessment/AddParticipant";
import { textFieldProps } from "../../../shared/constants";

export default function IbraEdit() {
  const { ibraId } = useParams();
  const { getIbra, removeIbraParticipant, addIbraParticipant, updateIbra } =
    useDb();
  const [ibra, setIbra] = useState(null);
  const [updated, setUpdated] = useState(new Date());
  const [addOpen, setAddOpen] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [loading, setLoading] = useState(false);

  const loadIbra = async () => {
    const i = await getIbra(ibraId);
    setIbra(i);
  };

  useEffect(() => {
    const unsubscribe = loadIbra();
    return unsubscribe;
  }, [ibraId, updated]);

  const handleParticipantDelete = async (p) => {
    const confirm = window.confirm(
      `Are you sure you want to remove this participant?`
    );
    if (confirm) {
      await removeIbraParticipant(ibraId, p);
      setUpdated(new Date());
    }
  };

  const handleAdd = async (p) => {
    // Add person to ibra state
    setIbra({
      participants: ibra.participants ? [...ibra.participants, p] : [p],
    });
    // Add person to database
    await addIbraParticipant(ibraId, p);
    setAddOpen(false);
  };

  const handleChange = (e) => {
    setIbra({ ...ibra, [e.target.name]: e.target.value });
    setUnsavedChanges(true);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      await updateIbra(ibraId, ibra);
      setUnsavedChanges(false);
    } catch (err) {
      window.alert(err.message);
    }
    setLoading(false);
  };

  return !ibra ? (
    <LinearProgress />
  ) : (
    <div>
      <h1>Edit IBRA</h1>
      <BackLink />
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader
                title="Participants"
                avatar={
                  <Avatar>
                    <People />
                  </Avatar>
                }
              />
              <CardContent>
                {ibra.participants &&
                  ibra.participants.map((p, i) => (
                    <ParticipantCard
                      key={i}
                      p={p}
                      handleDelete={handleParticipantDelete}
                    />
                  ))}
              </CardContent>
              <CardActions>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<Add />}
                  onClick={() => setAddOpen(true)}
                >
                  Add Participant
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader
                title="Details"
                avatar={
                  <Avatar>
                    <Info />
                  </Avatar>
                }
              />
              <CardContent>
                <TextField
                  {...textFieldProps}
                  label="Tag/Description"
                  name="tag"
                  value={ibra.tag}
                  onChange={handleChange}
                />
                <TextField
                  {...textFieldProps}
                  label="IBRA Area"
                  name="ibraArea"
                  value={ibra.ibraArea}
                  onChange={handleChange}
                />
              </CardContent>
              <CardActions>
                {unsavedChanges && (
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<Save />}
                    onClick={handleSave}
                    disabled={loading}
                  >
                    Save Changes
                  </Button>
                )}
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </div>

      <AddParticipant
        open={addOpen}
        close={() => setAddOpen(false)}
        add={handleAdd}
      />
    </div>
  );
}
