import {
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { textFieldProps } from "../../../../shared/constants";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { Save } from "@mui/icons-material";
import { useDb } from "../../../../../contexts/DatabaseContext";

export default function IBRiskResidualRisk() {
  const { ibraId, riskId } = useParams();
  const history = useHistory();
  const { updateIbraRisk } = useDb();

  const [state, setState] = useState({
    error: null,
    loading: false,
    data: {},
  });

  const handleChange = (e) => {
    setState({
      ...state,
      data: {
        ...state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleDateChange = (d) => {
    setState({
      ...state,
      data: {
        ...state.data,
        dueDate: d,
      },
    });
  };

  const handleSkip = async () => {
    setState({
      ...state,
      loading: true,
      error: null,
    });
    try {
      await updateIbraRisk(ibraId, riskId, { completed: true });
      history.push(`/risk-manager/issue-based/${ibraId}/3`);
    } catch (err) {
      setState({
        ...state,
        loading: false,
        error: err.message,
      });
    }
  };

  const handleSubmit = async () => {
    setState({
      ...state,
      loading: true,
      error: null,
    });
    try {
      await updateIbraRisk(ibraId, riskId, { ...state.data, completed: true });
      history.push(`/risk-manager/issue-based/${ibraId}/3`);
    } catch (err) {
      setState({
        ...state,
        loading: false,
        error: err.message,
      });
    }
  };

  return (
    <div>
      <h1>Additional Controls</h1>
      <Card>
        <CardContent>
          <TextField
            {...textFieldProps}
            label="Additional Controls aimed at improving existing controls"
            onChange={handleChange}
            name="additionalControls"
            multiline
            rows={3}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Due Date"
              onChange={(d) => handleDateChange(d.toDate())}
              defaultValue={dayjs(state.data.dueDate)}
            />
          </LocalizationProvider>
          <br />
          <br />
          <TextField
            {...textFieldProps}
            label="Any shortfalls / recommendations"
            onChange={handleChange}
            name="shortfalls"
            multiline
            rows={3}
          />
          {state.error && (
            <Typography color="secondary">{state.error}</Typography>
          )}
        </CardContent>
        <CardActions className="iso-right">
          <Button
            color="primary"
            variant="contained"
            onClick={handleSkip}
            disabled={state.loading}
          >
            Skip
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={state.loading}
            startIcon={<Save />}
          >
            Submit
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}
