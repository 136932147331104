import { faTasks } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

export default function AuditManagerMenuItem({ classes }) {

    const [menuOpen, setMenuOpen] = useState(false)

    return (
        <>
            <ListItem button onClick={() => setMenuOpen(!menuOpen)}>
                <ListItemIcon><FontAwesomeIcon className={classes.icons} icon={faTasks} /></ListItemIcon>
                    <ListItemText className={classes.menuText}>Audit Manager</ListItemText>
                { menuOpen ? <ExpandLess className={classes.icon} /> : <ExpandMore className={classes.icon} /> }
            </ListItem>
            <Collapse in={menuOpen} timeout="auto" unmountOnExit>
                <List component="div" className={classes.subMenuItem} dense>
                    <ListItem>
                        <Link to='/audit-manager' className="drawer-item">
                            <ListItemText className={classes.menuText}>Audit Dashboard</ListItemText>
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link to='/audit-manager/checklists' className="drawer-item">
                            <ListItemText className={classes.menuText}>Checklists</ListItemText>
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link to='/audit-manager/schedules' className="drawer-item">
                            <ListItemText className={classes.menuText}>Schedules</ListItemText>
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link to='/audit-manager/inspections' className="drawer-item">
                            <ListItemText className={classes.menuText}>Audits / Inspections</ListItemText>
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link to='/audit-manager/issues' className="drawer-item">
                            <ListItemText className={classes.menuText}>Issues</ListItemText>
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link to='/audit-manager/contrapro' className="drawer-item">
                            <ListItemText className={classes.menuText}>ContraPro (Suppliers)</ListItemText>
                        </Link>
                    </ListItem>
                </List>
            </Collapse>
        </>
    )
}
