import { Button, Card, CardActions, CardContent } from '@mui/material'
import { Search } from '@mui/icons-material'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

export default function SupportingDocDetail() {
    const { id } = useParams()
    const [supDoc, setSupDoc] = useState(null)
    const [loaded, setLoaded] = useState(false)
    const [pdfViewOpen, setPdfViewOpen] = useState(false)

    const firstLoad = async () => {

    }
    
    return (
        <div>
            <Card>
                <CardContent>

                </CardContent>
                <CardActions>
                    <Button color="primary" startIcon={<Search />} variant="contained">View Document Submitted</Button>
                </CardActions>
            </Card>
        </div>
    )
}
