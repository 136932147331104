import { Button } from '@mui/material'
import { Alert } from '@mui/material';
import React from 'react'
import { Link } from 'react-router-dom'

export default function TrialCounter({ currentUser }) {

  // show how many days since trialStart and if it's over 14 days, show a warning
  const trialStart = currentUser.trial_start.toDate();
  const isOwner = currentUser.companyOwner ? true : false;
  const expired = currentUser.trial_expired;
  const days = Math.floor((Date.now() - trialStart) / (1000 * 60 * 60 * 24));
  const daysLeft = 14 - days;
  const daysLeftText = daysLeft === 1 ? 'day' : 'days';

  const billingTypeLinks = {
    "stripe": "/addplan",
    "paystack": "/",
    "manual": "/sa-trial-expired"
  }
  
  if(!currentUser.trialing) {
    return null
  }
  else return (
    <div>
        <Alert
          severity="info"
          action={
            isOwner ? <Link to={billingTypeLinks[currentUser.billing_type]}>
              <Button size="small" color="primary" variant='contained'>
                Subscribe
              </Button>
            </Link>
            : <span>...</span>
          }
        >
          {
            expired
            ?
            "Your Free Trial has expired"
            :
            `You have ${daysLeft} days remaining in your free trial.`
          }
        </Alert>
    </div>
  )
}
