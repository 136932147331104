import { Button, CardActions, CircularProgress } from '@mui/material'
import { Add, ArrowBack, ArrowForward } from '@mui/icons-material'
import React, { useState } from 'react'
import NewChecklistSection from './NewChecklistSection'
import { useParams } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import ChecklistSection from './ChecklistSection'
import { useHistory } from 'react-router-dom'

export default function ChecklistCreator() {
    const { id } = useParams()
    const history = useHistory()
    const { getChecklist, GetChecklistSections } = useDb()
    const sections = GetChecklistSections(id)

    const [checklist, setChecklist] = useState()

    const [state, setState] = useState({
        loading: false,
        error: null,
        addOpen: false,
        data: {}
    })

    const firstLoad = async () => {
        const c = await getChecklist(id)
        setChecklist(c)
    }

    if(!checklist) {
        firstLoad()
        return <CircularProgress />
    }
    return (
        <div>
            <h1>Checklist: {checklist.title} ({checklist.type})</h1>
            <div className="back-link">
                <Button
                    color="primary"
                    variant="contained"
                    startIcon={<ArrowBack />}
                    onClick={() => history.goBack()}
                >
                    Back
                </Button>
            </div>
            {
                sections && sections.map(s => (
                    <ChecklistSection
                        section={s}
                        checklistId={id}
                    />
                ))
            }

            <br/>
            <div className="back-link">
                <Button
                    color="primary"
                    variant="contained"
                    startIcon={<Add />}
                    onClick={() => setState({ ...state, addOpen: true })}
                >
                    Add Section
                </Button>
                <CardActions className="iso-right">
                    {/* TODO: Disable button if there are no sections */}
                    <Button
                        color="primary"
                        variant="contained"
                        endIcon={<ArrowForward />}
                        onClick={() => history.push(`/audit-manager/add-schedule/${id}`)}
                    >
                        Next: Scheduling
                    </Button>
                </CardActions>
            </div>

            <NewChecklistSection
                open={state.addOpen}
                close={() => setState({ ...state, addOpen: false })}
                checklistId={id}
            />
        </div>
    )
}
