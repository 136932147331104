import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useDb } from "../../../../contexts/DatabaseContext";
import { textFieldProps } from "../../../shared/constants";

export default function AddBoneCause({ open, close, actionId, categoryId }) {
  const { addFishboneCause } = useDb();
  const [cause, setCause] = useState("");
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setCause("");
    setLoading(false);
    close();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await addFishboneCause(actionId, categoryId, cause);
      handleClose();
    } catch (err) {
      window.alert(err.message);
    }
  };
  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
      <DialogTitle>Add Cause</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            {...textFieldProps}
            label="Cause Description"
            required
            onChange={(e) => setCause(e.target.value)}
            value={cause}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={loading}
            color="primary"
            variant="contained"
            type="submit"
          >
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
