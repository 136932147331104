import { faBalanceScale, faCalendarCheck, faChalkboardTeacher, faExchangeAlt, faFile, faGavel, faSmileBeam, faTasks } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AppBar, Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, Snackbar, Switch, Toolbar, Typography } from '@mui/material'
import { Close, Delete, Save } from '@mui/icons-material'
import React, { useState } from 'react'
import Select from 'react-select'
import { useAuth } from '../../../contexts/AuthContext'
import { useDb } from '../../../contexts/DatabaseContext'
import { selectFieldProps, accessRights } from '../../shared/constants'
import ConfirmUserDelete from './ConfirmUserDelete'


export default function UserAccess({ user, open, closeDialog }) {
    const { currentUser } = useAuth()
    const { updateUserLevel, toggleAdminStatus, GetUsersLevelFilter, updateReportsTo } = useDb()
    
    const [state, setState] = useState({ ...user.accessRights })
    const [isAdmin, setIsAdmin] = useState(user.companyAdmin)
    const [updated, setUpdated] = useState(false)

    // Manager Dropdowns
    const docOfficers = GetUsersLevelFilter('docManager', 1)
    const actionOfficers = GetUsersLevelFilter('actionManager', 1)
    const otpOfficers = GetUsersLevelFilter('otpManager', 1)
    const trainingOfficers = GetUsersLevelFilter('trainingManager', 1)
    const riskOfficers = GetUsersLevelFilter('riskManager', 1)
    const auditOfficers = GetUsersLevelFilter('auditManager', 1)
    const mocOfficers = GetUsersLevelFilter('mocManager', 1)
    const customerOfficers = GetUsersLevelFilter('customerManager', 1)


    // Action State
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [deleteOpen, setDeleteOpen] = useState(false)

    const handleClose = () => {
        setUpdated(false)
        setLoading(false)
        closeDialog()
    }

    const handleChange = async (target, value) => {
        setState({
            ...state,
            [target]: value
        })
        setUpdated(true)
    }

    const handleSubmit = async () => {
        setError(null)
        setLoading(true)
        try {
            await updateUserLevel(user.id, state).then(() => {
                // handleClose()
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    const toggleAdmin = async (e) => {
        setError(null)
        setLoading(true)
        setIsAdmin(e.target.checked)
        try {
            await toggleAdminStatus(user.id, e.target.checked).then(() => {
                setLoading(false)
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    const deleteComplete = () => {
        closeDialog()
    }

    // Reports To (Manager)
    const [officers, setOfficers] = useState({
        docOfficer: user.docOfficer,
        actionOfficer: user.actionOfficer,
        otpOfficer: user.otpOfficer,
        trainingOfficer: user.trainingOfficer ,
        riskOfficer: user.riskOfficer ,
        auditOfficer: user.auditOfficer ,
        mocOfficer: user.mocOfficer ,
        customerOfficer: user.customerOfficer 
    })
    const [snackOpen, setSnackOpen] = useState(false)

    const handleOfficerSelect = async (val, e) => {
        setOfficers({
            ...officers,
            [e.name]: val
        })
        setError(null)
        setLoading(true)
        try {
            await updateReportsTo(user.id, e.name, val).then(() => {
                setLoading(false)
                setSnackOpen(true)
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} fullScreen>
            <div style={{ marginTop: 80}} />
            <AppBar color="primary">
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={closeDialog}
                        aria-label="close"
                        size="large">
                        <Close />
                        
                    </IconButton>
                    <Typography>
                        {user.firstName} {user.lastName}
                    </Typography>
                    
                </Toolbar>
            </AppBar>
            <DialogContent>
                
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={6}>
                        {
                            currentUser.modules.docManager
                            ?
                            <div>
                                <Card>
                                    <CardHeader
                                        title="Doc Manager"
                                        avatar={<FontAwesomeIcon icon={faFile} />}
                                        subheader={
                                            <>
                                                <Select
                                                    defaultValue={accessRights[user.accessRights.docManager]}
                                                    {...selectFieldProps}
                                                    options={accessRights}
                                                    onChange={v => handleChange('docManager', v.value)}
                                                    // onChange={v => console.log(v.value)}
                                                />
                                                <span className="iso-small">Reports to: { officers.docOfficer ? officers.docOfficer.label : 'Not Set' }</span>
                                                <Select
                                                    options={docOfficers}
                                                    {...selectFieldProps}
                                                    className="iso-select"
                                                    name="docOfficer"
                                                    onChange={handleOfficerSelect}
                                                />
                                            </>
                                        }
                                    />
                                </Card>
                                <br/>
                            </div>
                            :
                            null
                        }
                        {
                            currentUser.modules.actionManager
                            ?
                                <div>
                                    <Card>
                                        <CardHeader
                                            title="Action Manager"
                                            avatar={<FontAwesomeIcon icon={faGavel} />}
                                            subheader={
                                                <>
                                                    <Select
                                                        defaultValue={accessRights[user.accessRights.actionManager]}
                                                        {...selectFieldProps}
                                                        options={accessRights}
                                                        onChange={v => handleChange('actionManager', v.value)}
                                                    />
                                                    <span className="iso-small">Reports to: { officers.actionOfficer ? officers.actionOfficer.label : 'Not Set' }</span>
                                                    <Select
                                                        options={actionOfficers}
                                                        {...selectFieldProps}
                                                        className="iso-select"
                                                        name="actionOfficer"
                                                        onChange={handleOfficerSelect}
                                                    />
                                                </>
                                            }
                                        />
                                    </Card>
                                    <br/>
                                </div>
                            :
                            null
                        }
                        {
                            currentUser.modules.otpManager 
                            ?
                                <div>
                                    <Card>
                                        <CardHeader
                                            title="OTP Manager"
                                            avatar={<FontAwesomeIcon icon={faCalendarCheck} />}
                                            subheader={
                                                <>
                                                    <Select
                                                        defaultValue={accessRights[user.accessRights.otpManager]}
                                                        {...selectFieldProps}
                                                        options={accessRights}
                                                        onChange={v => handleChange('otpManager', v.value)}
                                                    />
                                                    <span className="iso-small">Reports to: { officers.otpOfficer ? officers.otpOfficer.label : 'Not Set' }</span>
                                                    <Select
                                                        options={otpOfficers}
                                                        {...selectFieldProps}
                                                        className="iso-select"
                                                        name="otpOfficer"
                                                        onChange={handleOfficerSelect}

                                                    />
                                                </>
                                            }
                                        />
                                    </Card>
                                    <br/>
                                </div>
                            :
                            null

                        }
                        {
                            currentUser.modules.trainingManager
                            ?
                                <div>
                                    <Card>
                                        <CardHeader
                                            title="Training Manager"
                                            avatar={<FontAwesomeIcon icon={faChalkboardTeacher} />}
                                            subheader={
                                                <>
                                                    <Select
                                                        defaultValue={accessRights[user.accessRights.trainingManager]}
                                                        {...selectFieldProps}
                                                        options={accessRights}
                                                        onChange={v => handleChange('trainingManager', v.value)}
                                                    />
                                                    <span className="iso-small">Reports to: { officers.trainingOfficer ? officers.trainingOfficer.label : 'Not Set' }</span>
                                                    <Select
                                                        options={trainingOfficers}
                                                        {...selectFieldProps}
                                                        className="iso-select"
                                                        name="trainingOfficer"
                                                        onChange={handleOfficerSelect}

                                                    />
                                                </>
                                            }
                                        />
                                    </Card>
                                </div>
                            :
                            null
                        }
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        {
                            currentUser.modules.riskManager
                            ?
                            <div>
                                <Card>
                                    <CardHeader
                                        title="Risk Manager"
                                        avatar={<FontAwesomeIcon icon={faBalanceScale} />}
                                        subheader={
                                            <>
                                                <Select
                                                    defaultValue={accessRights[user.accessRights.riskManager]}
                                                    {...selectFieldProps}
                                                    options={accessRights}
                                                    onChange={v => handleChange('riskManager', v.value)}
                                                />  
                                                <span className="iso-small">Reports to: { officers.riskOfficer ? officers.riskOfficer.label : 'Not Set' }</span>
                                                <Select
                                                    options={riskOfficers}
                                                    {...selectFieldProps}
                                                    className="iso-select"
                                                    name="riskOfficer"
                                                    onChange={handleOfficerSelect}

                                                />
                                            </>
                                        }
                                    />
                                </Card>
                                <br/>
                            </div>
                            :
                            null
                        }
                        {
                            currentUser.modules.auditManager
                            ?
                            <div>
                                <Card>
                                    <CardHeader
                                        title="Audit Manager"
                                        avatar={<FontAwesomeIcon icon={faTasks} />}
                                        subheader={
                                            <>
                                                <Select
                                                    defaultValue={accessRights[user.accessRights.auditManager]}
                                                    {...selectFieldProps}
                                                    options={accessRights}
                                                    onChange={v => handleChange('auditManager', v.value)}
                                                />
                                                <span className="iso-small">Reports to: { officers.auditOfficer ? officers.auditOfficer.label : 'Not Set' }</span>
                                                <Select
                                                    options={auditOfficers}
                                                    {...selectFieldProps}
                                                    className="iso-select"
                                                    name="auditOfficer"
                                                    onChange={handleOfficerSelect}

                                                />
                                            </>
                                        }
                                    />
                                </Card>
                                <br/>
                            </div>
                            :
                            null
                        }
                        {
                            currentUser.modules.mocManager
                            ?
                            <div>
                                <Card>
                                    <CardHeader
                                        title="MOC Manager"
                                        avatar={<FontAwesomeIcon icon={faExchangeAlt} />}
                                        subheader={
                                            <>
                                                <Select
                                                    defaultValue={accessRights[user.accessRights.mocManager]}
                                                    {...selectFieldProps}
                                                    options={accessRights}
                                                    onChange={v => handleChange('mocManager', v.value)}
                                                />
                                                <span className="iso-small">Reports to: { officers.mocOfficer ? officers.mocOfficer.label : 'Not Set' }</span>
                                                <Select
                                                    options={mocOfficers}
                                                    {...selectFieldProps}
                                                    className="iso-select"
                                                    name="mocOfficer"
                                                    onChange={handleOfficerSelect}

                                                />
                                            </>
                                        }
                                    />
                                </Card>
                                <br/>
                            </div>
                            :
                            null
                        }
                        {
                            currentUser.modules.customerManager
                            ?
                            <div>
                                <Card>
                                    <CardHeader
                                        title="Customer Manager"
                                        avatar={<FontAwesomeIcon icon={faSmileBeam} />}
                                        subheader={
                                            <>
                                                <Select
                                                    defaultValue={accessRights[user.accessRights.customerManager]}
                                                    {...selectFieldProps}
                                                    options={accessRights}
                                                    onChange={v => handleChange('customerManager', v.value)}
                                                    
                                                />
                                                <span className="iso-small">Reports to: { officers.customerOfficer ? officers.customerOfficer.label : 'Not Set' }</span>
                                                <Select
                                                    options={customerOfficers}
                                                    {...selectFieldProps}
                                                    className="iso-select"
                                                    name="customerOfficer"
                                                    onChange={handleOfficerSelect}

                                                />
                                            </>
                                        }
                                    />
                                </Card>
                            </div>
                            :
                            null
                        }
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                { error ? <span className="iso-error">{error}</span> : null }
                <Button onClick={() => setDeleteOpen(true)} color="secondary" startIcon={<Delete />}>Delete User</Button>
                <FormControlLabel
                    control={
                    <Switch
                        checked={isAdmin}
                        onChange={toggleAdmin}
                        
                        color="primary"
                    />
                    }
                    label="Has Admin Rights"
                />
                <Button color="secondary" onClick={handleClose}>Cancel</Button>
                <Button disabled={ !updated || loading } color="primary" variant="contained" startIcon={<Save />} onClick={handleSubmit}>Update Access Rights</Button>
            </DialogActions>

            <Snackbar
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
                }}
                open={snackOpen}
                autoHideDuration={6000}
                onClose={() => setSnackOpen(false)}
                message="User Manager Settings Updated"
                
            />

            <ConfirmUserDelete user={user} open={deleteOpen} closeDialog={() => setDeleteOpen(false)} deleteComplete={deleteComplete} />
        </Dialog>
    );
}
 