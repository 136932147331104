import {
  Button,
  TextField,
  LinearProgress,
  Box,
  Typography,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import React, { useState } from "react";
import { storage } from "../../../firebase";
import Select from "react-select";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faUpload } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { useDb } from "../../../contexts/DatabaseContext";
import {
  docCategories,
  retention,
  disposition,
  selectFieldProps,
  extendedDocCategories,
} from "../../shared/constants";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function AddDocument({ closeDialog }) {
  const {
    GetDepartments,
    GetRosters,
    GetSites,
    returnDocTypes,
    getDocLength,
    addDocument,
    GetGroups,
  } = useDb();
  const history = useHistory();
  const [docUrl, setDocUrl] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");
  const storageRef = storage.ref("companies").child("z9idrZEm3nIz8QBZQJwz");
  const [date, setDate] = useState(new Date());
  const [docName, setDocName] = useState("");
  const [customId, setCustomId] = useState("");
  const [department, setDepartment] = useState("");
  const [category, setCategory] = useState("");
  const [owner, setOwner] = useState("");
  const [retentionPeriod, setRetention] = useState(0);
  const [dispositionMethod, setDisposition] = useState("");
  const [file, setFile] = useState(null);
  const [docTypes, setDocTypes] = useState([]);
  const [site, setSite] = useState("");
  const [type, setType] = useState("");
  const [group, setGroup] = useState([]);
  const { currentUser } = useAuth();

  const [fileSelected, setFileSelected] = useState(false);

  const getDocTypes = async (category) => {
    const docTypes = await returnDocTypes(category.value);
    return docTypes;
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      setFileSelected(true);
    }
  };

  const handleUploadStart = () => {
    setIsUploading(true);
    setProgress(0);
    const fileName = Math.random().toString(36).slice(-8);
    console.log("FileName: ", fileName);
    const uploadTask = storageRef
      .child(`documents/${fileName}/${file.name}`)
      .put(file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var upProgress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(upProgress);
      },
      (error) => {
        setError("Error during File Upload");
        setIsUploading(false);
        setFileSelected(false);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((url) => {
          setDocUrl(url);
          setProgress(100);
          setIsUploading(false);
          console.log("File available at: ", url);
          setFileSelected(false);
        });
      }
    );
  };

  const validateDoc = () => {
    if (
      docUrl !== "" &&
      !isUploading &&
      error === "" &&
      department !== "" &&
      category !== "" &&
      owner !== "" &&
      retention !== 0 &&
      dispositionMethod !== "" &&
      site !== "" &&
      type !== ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  async function saveDocument(data) {
    setError("");
    const length = await getDocLength(data);

    if (validateDoc()) {
      setIsUploading(true);
      try {
        await addDocument(data, length).then((docId) => {
          history.push(`/doc-approvers/${docId}`);
          closeDialog();
        });
      } catch (err) {
        setError(err.message);
      }
    } else {
      setError("Please complete All Fields");
    }
  }

  const departments = GetDepartments();
  const rosters = GetRosters();
  const sites = GetSites();
  const groups = GetGroups();

  const selectCat = async (cat) => {
    setCategory(cat.value);
    const dtypes = await getDocTypes(cat);
    setDocTypes(dtypes);
  };

  const updateGroupVal = (val) => {
    if (val === null) {
      setGroup([]);
    } else {
      setGroup(val);
    }
  };

  return (
    <>
      <Card>
        <CardContent>
          Upload Document:
          <br />
          <input type="file" onChange={handleFileChange} />
          <LinearProgressWithLabel value={progress} />
          {error !== "" ? `Error Uploading file` : null}
        </CardContent>
        <CardActions className="iso-right">
          <Button
            disabled={!fileSelected || isUploading}
            onClick={handleUploadStart}
            startIcon={<FontAwesomeIcon icon={faUpload} />}
            variant="contained"
            color="primary"
          >
            Upload
          </Button>
        </CardActions>
      </Card>
      <br />
      <TextField
        label="Document Name"
        variant="outlined"
        fullWidth
        onChange={(e) => setDocName(e.target.value)}
      />
      <br />
      <br />
      <TextField
        label="Custom Doc ID"
        variant="outlined"
        fullWidth
        onChange={(e) => setCustomId(e.target.value)}
      />
      <br />
      <br />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Target Revision Date"
          onChange={(d) => {
            console.log(d.toDate());
            setDate(d.toDate());
          }}
          defaultValue={dayjs(date)}
        />
      </LocalizationProvider>
      <br />
      <br />
      <Select
        options={departments}
        placeholder="Department"
        onChange={(val) => setDepartment(val)}
        {...selectFieldProps}
      />
      <br />
      <Select
        options={
          currentUser.extendedDocs ? extendedDocCategories : docCategories
        }
        placeholder="Document Category"
        onChange={(val) => selectCat(val)}
        {...selectFieldProps}
      />
      <br />
      <Select
        options={docTypes}
        placeholder="Document Type"
        onChange={(val) => setType(val)}
        {...selectFieldProps}
      />
      <br />
      <Select
        options={rosters}
        placeholder="Document Owner"
        onChange={(val) => setOwner(val)}
        {...selectFieldProps}
      />
      <br />
      <Select
        options={sites}
        placeholder="Site"
        onChange={(val) => setSite(val)}
        {...selectFieldProps}
      />
      <br />
      <Select
        options={retention}
        placeholder="Retention (Years)"
        onChange={(val) => setRetention(val)}
        {...selectFieldProps}
      />
      <br />
      <Select
        options={disposition}
        placeholder="Disposition"
        onChange={(val) => setDisposition(val)}
        isRequired
        {...selectFieldProps}
      />
      <br />
      <Select
        options={groups}
        placeholder="Limit Visibility To Groups ..."
        onChange={(val) => updateGroupVal(val)}
        isMulti
        {...selectFieldProps}
      />
      <br />
      <Button
        disabled={isUploading || docUrl === null}
        onClick={() =>
          saveDocument({
            docUrl,
            docName,
            department,
            owner,
            retentionPeriod,
            dispositionMethod,
            site,
            docType: type,
            category,
            createdDate: new Date(),
            targetRevDate: date,
            groups: group,
          })
        }
        color="primary"
        variant="contained"
        startIcon={<FontAwesomeIcon icon={faSave} />}
      >
        Save
      </Button>{" "}
      &nbsp;{" "}
      <Button onClick={closeDialog} color="secondary">
        Cancel
      </Button>
      <div style={{ height: "200px" }}></div>
    </>
  );
}
