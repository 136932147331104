import React from 'react'
import { Button } from '@mui/material'
import { KeyboardArrowLeft } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'
import RiskMatrix from '../risk-matrix/RiskMatrix'

export default function Matrix8x8() {
  const history = useHistory()

  return (
    <div>
      <h1>8x8 Risk Matrix</h1>
      <div className="back-link">
        <Button variant={'contained'} color={'primary'}  startIcon={<KeyboardArrowLeft/>} onClick={() => history.goBack()} > Back </Button>
      </div>
      <RiskMatrix
        type="8x8"
      />
    </div>
  )
}
