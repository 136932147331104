import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React from "react";
import { textFieldProps } from "../../../shared/constants";
import { ThumbDown } from "@mui/icons-material";

export default function DeclineReason({
  open,
  close,
  declineReason,
  setDeclineReason,
  handleDecline,
  loading,
}) {
  return (
    <Dialog open={open} onClose={close} maxWidth="sm" fullWidth>
      <DialogTitle>Decline Reason</DialogTitle>
      <DialogContent>
        <TextField
          {...textFieldProps}
          label="Reason for Decline"
          multiline
          rows={4}
          value={declineReason}
          onChange={(e) => setDeclineReason(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={close}>
          Cancel
        </Button>
        <Button
          color="secondary"
          onClick={handleDecline}
          variant="contained"
          startIcon={<ThumbDown />}
          disabled={loading}
        >
          Confirm Decline
        </Button>
      </DialogActions>
    </Dialog>
  );
}
