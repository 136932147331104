import React, { useState } from 'react'
import BulkFileUploader from './BulkFileUploader'
import { useAuth } from '../../contexts/AuthContext'

export default function Testing() {
    const [loading, setLoading] = useState(false)
    const { currentUser } = useAuth()

    const getFiles = (files) => {
        console.log(files)
    }
    return (
        <div>
            <h1>Testing</h1>
            <BulkFileUploader userId={currentUser.uid} getFiles={getFiles} />
        </div>
    )
}
