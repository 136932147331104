import {
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { textFieldProps } from "../../../shared/constants";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { useDb } from "../../../../contexts/DatabaseContext";
import { Save } from "@mui/icons-material";

export default function AddRisk5ResidualRisk() {
  const { blraId, riskId, hazardId } = useParams();
  const history = useHistory();
  const { addResidualRisk, getRisk } = useDb();

  const [state, setState] = useState({
    error: null,
    loading: false,
    pageLoaded: false,
    data: {
      shortfalls: "",
      additionalControls: "",
    },
  });

  const handleChange = (e) => {
    setState({
      ...state,
      data: {
        ...state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleDateChange = (d) => {
    setState({
      ...state,
      data: {
        ...state.data,
        dueDate: d,
      },
    });
  };

  const handleSkip = () => {
    history.push(`/risk-manager/baselines/${blraId}/hazards/${hazardId}`);
  };

  const handleSubmit = async () => {
    setState({
      ...state,
      loading: true,
      error: null,
    });
    try {
      await addResidualRisk(blraId, riskId, state.data);
      history.push(`/risk-manager/baselines/${blraId}/hazards/${hazardId}`);
    } catch (err) {
      setState({
        ...state,
        loading: false,
        error: err.message,
      });
    }
  };

  const firstLoad = async () => {
    const r = await getRisk(blraId, riskId);
    if (r.additionalControls && r.shortfalls) {
      setState({
        ...state,
        data: {
          ...state.data,
          additionalControls: r.additionalControls,
          shortfalls: r.shortfalls,
        },
      });
    }
    setState({
      ...state,
      pageLoaded: true,
    });
  };

  if (!state.pageLoaded) {
    firstLoad();
    return <CircularProgress />;
  } else
    return (
      <div>
        <h1>Additional Controls</h1>
        <Card>
          <CardContent>
            <TextField
              {...textFieldProps}
              label="Additional Controls aimed at improving existing controls"
              onChange={handleChange}
              name="additionalControls"
              multiline
              rows={3}
              value={state.data.additionalControls}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Target Revision Date"
                onChange={(d) => handleDateChange(d.toDate())}
                defaultValue={dayjs(state.data.dueDate)}
              />
            </LocalizationProvider>
            <br />
            <br />
            <TextField
              {...textFieldProps}
              label="Any shortfalls / recommendations"
              onChange={handleChange}
              name="shortfalls"
              multiline
              rows={3}
              value={state.data.shortfalls}
            />
            {state.error && (
              <Typography color="secondary">{state.error}</Typography>
            )}
          </CardContent>
          <CardActions className="iso-right">
            <Button
              color="primary"
              variant="contained"
              onClick={handleSkip}
              disabled={state.loading}
            >
              Skip
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmit}
              disabled={state.loading}
              startIcon={<Save />}
            >
              Submit
            </Button>
          </CardActions>
        </Card>
      </div>
    );
}
