import { Button, TextField, Typography } from '@mui/material'
import { Save } from '@mui/icons-material'
import React, { useState } from 'react'
import { useDb } from '../../../../contexts/DatabaseContext'
import { textFieldProps } from '../../../shared/constants'

export default function EditRiskText({ assessmentId, riskId, type, close, assessmentType }) {
    const { updateBlraRisk, updateIbraRisk } = useDb();
    const [state, setState] = useState({
        loading: false,
        error: null,
        data: {}
    });

    const handleClose = () => {
        setState({
            loading: false,
            error: null,
            data: {}
        });
        close();
    }

    const handleChange = e => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleSubmit = async () => {
        setState({
            ...state,
            loading: true,
            error: null
        })
        try {
            if(assessmentType === "blra") {
                await updateBlraRisk(assessmentId, riskId, state.data);
            }
            if(assessmentType === "ibra") {
                await updateIbraRisk(assessmentId, riskId, state.data);
            }
            handleClose();
        }
        catch(err) {
            setState({
                ...state,
                loading: false,
                error: err.message
            })
        }
    }

    return (
        <div>
            <TextField
                name={type}
                onChange={handleChange}
                {...textFieldProps}
                label={`Edit ${type}`}
            />
            {
                state.error && <Typography color="secondary">{state.error}</Typography>
            }
            <Button
                color="primary"
                variant="contained"
                startIcon={<Save />}
                style={{ float: "right"}}
                onClick={handleSubmit}
                disabled={ state.loading }
            >
                Save
            </Button>
        </div>
    )
}
