import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDb } from '../../../../contexts/DatabaseContext'
import { textFieldProps } from '../../../shared/constants'

const useStyles = makeStyles((theme) => ({
  header: {
      background: theme.palette.primary.main
  }
}))

export default function EditFile({open, close, checklistId, sectionId, fieldData}) {

  const classes = useStyles()
  const { updateChecklistField } = useDb()
  const [state, setState] = useState({...fieldData.data})
  const [actionState, setActionState] = useState({ loading: false, error: null })

  useEffect(()=> {
    setState({...fieldData.data})
  }, [open])

  console.log(state)

  const handleClose = () => {
      setActionState({ loading: false, error: null })
      setState({})
      close()
  }

  const handleChange = (e) => {
      setState({
          ...state,
          [e.target.name]: e.target.value
      })
  }

  const handleSubmit = async (e) => {
      e.preventDefault()
      setActionState({ loading: true, error: null })
      try {
          await updateChecklistField(checklistId, sectionId, fieldData.id, 'file', state)
          handleClose()
      }
      catch(err) {
          setActionState({ loading: false, error: err.message })
      }
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle className={classes.header}>Edit Text Field</DialogTitle>
        <form onSubmit={handleSubmit}>
            <DialogContent>
                <TextField
                    defaultValue={state.file}
                    {...textFieldProps}
                    onChange={handleChange}
                    name="file"
                    label="File Name"
                />
                { actionState.error ? <span className="wireline-error">{actionState.error}</span> : null }
            </DialogContent>
            <DialogActions>
                <Button disabled={ actionState.loading } color="secondary" onClick={handleClose}>Cancel</Button>
                <Button disabled={ actionState.loading } type="submit" color="primary" variant="contained">Update</Button>
            </DialogActions>
        </form>
    </Dialog>
  )
}
