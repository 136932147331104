import { Button, CardActions, Grid } from '@mui/material'
import { ArrowForward } from '@mui/icons-material'
import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDb } from '../../../../../contexts/DatabaseContext'
import IBRiskSignTypes from './IBRiskSignTypes'

export default function AddIBRiskSigns() {
    const { ibraId, riskId } = useParams()
    const history = useHistory()
    const { updateIbraRisk } = useDb()

    const [state, setState] = useState({
        loading: false,
        error: null
    })

    const signTypes = [
        'Safe Condition',
        'Fire Protection',
        'Mandatory',
        'Prohibition',
        'Warning'
    ]

    const handleAdvance = async () => {
        setState({
            loading: true,
            error: null
        })
        try {
            await updateIbraRisk(ibraId, riskId, { currentStep: 2 })
            history.push(`/risk-manager/issue-based/${ibraId}/hierarchy/${riskId}`)
        }
        catch(err) {
            setState({
                loading: false,
                error: err.message
            })
        }
    }

    return (
        <div>
            <h1>Add Signage:</h1>
            <Grid container spacing={3}>
                {
                    signTypes.map((s, i) => (
                        <Grid item xs={12} md={6} key={i}>
                            <IBRiskSignTypes
                                ibraId={ibraId}
                                riskId={riskId}
                                type={s}
                            />
                        </Grid>
                    ))
                }
            </Grid>
            <CardActions
                className="iso-right"
            >
                <Button
                    color="primary"
                    variant="contained"
                    endIcon={<ArrowForward />}
                    disabled={state.loading}
                    onClick={handleAdvance}
                >
                    Next: Hierarchy of Controls
                </Button>
            </CardActions>
        </div>
    )
}
