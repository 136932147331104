import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React, { useState } from 'react'
import { useDb } from '../../../../contexts/DatabaseContext'
import Select from 'react-select'
import { selectFieldProps } from '../../../shared/constants'
import { useHistory } from 'react-router-dom'

export default function NewAttendedReport({ open, close }) {
    const { GetPastTrainingSessionsDropdown, generateAttendanceReport, saveAttendanceReport } = useDb()
    const sessions = GetPastTrainingSessionsDropdown()
    const history = useHistory()

    const [state, setState] = useState({
        loading: false,
        data: null,
        error: null
    })

    const handleClose = () => {
        close()
    }

    const handleSelect = (v, e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.name]: v.value
            }
        })
    }

    const handleSubmit = async () => {
        setState({
            ...state,
            loading: true
        })
        try {

            const attendees = await generateAttendanceReport(state.data.sessionId)
            const reportId = await saveAttendanceReport(attendees)
            setState({
                ...state,
                loading: false
            })
            history.push(`/training-manager/reports/bookedvsattended/${reportId}`)
        }
        catch(err) {
            setState({
                ...state,
                error: err.message,
                loading:false
            })
        }
        

    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                New Attendance Report
            </DialogTitle>
            <DialogContent>
                <Select 
                    {...selectFieldProps}
                    className="iso-select"
                    options={sessions}
                    placeholder="Search Session ..."
                    onChange={handleSelect}
                    name="sessionId"
                />
                {
                    state.error ? <span className="iso-error">{state.error}</span> : null
                }
            </DialogContent>
            <DialogActions>
                <Button disabled={ state.loading } onClick={handleSubmit} color="primary" variant="contained">Generate Report</Button>
            </DialogActions>
        </Dialog>
    )
}
