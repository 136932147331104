import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import React from 'react'

export default function ConfirmSomething({ open, closeDialog, message, response }) {

    const handleClose = (res) => {
        response(res)
        closeDialog()
    }

    return (
        <Dialog open={open} onClose={closeDialog} maxWidth="sm" fullWidth>
            <DialogContent>
                { message }
            </DialogContent>
            <DialogActions> 
                <Button color="secondary" onClick={() => handleClose(false)}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={() => handleClose(true)}>Confirm</Button>
            </DialogActions>
        </Dialog>
    )
}
