import { Button, CircularProgress, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'

export default function AddRoster({ closeDialog }) {

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const [busy, setBusy] = useState(false)
    const { addRoster } = useDb()

    const saveRoster = async (e) => {
        e.preventDefault()
        setBusy(true)
        try {
            await addRoster({firstName,lastName,email})
            closeDialog();
        }
        catch(err) {
            setError(err.message)
        }
    }

    return (
        <form onSubmit={saveRoster}>
            <TextField onChange={(e) => setFirstName(e.target.value)} label="First Name" variant="outlined" fullWidth required/>
            <br/><br/>
            <TextField onChange={(e) => setLastName(e.target.value)} label="Last Name" variant="outlined" fullWidth required />
            <br/><br/>
            <TextField type="email" onChange={(e) => setEmail(e.target.value)} label="Email" variant="outlined" fullWidth required/>
            <br/><br/>
            <Typography style={{ color: 'red' }}>{error}</Typography>
            <br/>
            <div className="iso-right">
                {
                    busy ? <CircularProgress />
                    : <Button disabled={ firstName && lastName && email !== '' ? false : true } type="submit" color="primary">Confirm</Button>
                }
                <Button onClick={closeDialog} color="secondary">Cancel</Button>
            </div>
        </form>
    )
}
