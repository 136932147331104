import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  LinearProgress,
  Typography
} from "@mui/material";
import { ArrowLeft, CloudDownload, Edit } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useDb } from "../../../contexts/DatabaseContext";
import moment from "moment";
import EditModule from "./EditModule";
import { useAuth } from "../../../contexts/AuthContext";
import ChangeProvider from "./ChangeProvider";

export default function TrainingModuleDetail() {
  const { id, code } = useParams();
  const { getTrainingModule } = useDb();
  const { currentUser } = useAuth();

  const [loaded, setLoaded] = useState(false);
  const [module, setModule] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  const [providerOpen, setProviderOpen] = useState(false);

  const firstLoad = async () => {
    const m = await getTrainingModule(id);
    setModule(m);
    setLoaded(true);
  };

  useEffect(() => {
    const loadPage = firstLoad();

    return loadPage;
  }, [id, providerOpen, editOpen]);

  const toggleProviderOpen = () => {
    setProviderOpen(!providerOpen);
  };

  if (!loaded) {
    firstLoad();
    return <LinearProgress />;
  } else
    return (
      <div>
        <h1>Training Module: {code}</h1>
        <Link to={"/training-manager/modules"}>
          <Button variant="contained" startIcon={<ArrowLeft />} color="primary">
            Training Modules
          </Button>
        </Link>
        <br />
        <br />
        <Card>
          <CardActions>
            {module.ptoUrl ? (
              <Button
                startIcon={<CloudDownload />}
                target="_blank"
                href={module.ptoUrl}
                color="primary"
                variant="contained"
              >
                Evaluation Template
              </Button>
            ) : null}
            {module.practicalUrl ? (
              <Button
                startIcon={<CloudDownload />}
                target="_blank"
                href={module.practicalUrl}
                color="primary"
                variant="contained"
              >
                Practical Assessment Template
              </Button>
            ) : null}
          </CardActions>
        </Card>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography>Name: {module.name}</Typography>
                <Typography>
                  Requires Competency:{" "}
                  {module.competencyRequired ? "Yes" : "No"}
                </Typography>
                <Typography>Department: {module.department.name}</Typography>
                <Typography>
                  Requested Evaluation:{" "}
                  {module.evaluationRequested ? "Yes" : "No"}
                </Typography>
                <Typography>
                  Training Provider:{" "}
                  {module.external ? module.provider.name : "In-House"}
                </Typography>
                <Typography>
                  Owner: {module.owner.firstName} {module.owner.lastName}
                </Typography>
                <Typography>
                  Practical Required: {module.practicalRequired ? "Yes" : "No"}
                </Typography>
                <Typography>Risk: {module.risk.name}</Typography>
                <Typography>
                  Frequency of Occurences (Days): {module.frequency || "N/A"}
                </Typography>
                <Typography>
                  Target Revision Date:{" "}
                  {moment(module.targetRevDate.toDate()).format("DD/MM/YYYY")}
                </Typography>
                <Typography>
                  Date Created:{" "}
                  {moment(module.timestamp.toDate()).format("DD/MM/YYYY")}
                </Typography>
              </CardContent>
              <CardActions>
                {currentUser.accessRights.trainingManager < 2 ||
                currentUser.companyAdmin ? (
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<Edit />}
                    onClick={() => setEditOpen(true)}
                  >
                    Edit Module
                  </Button>
                ) : null}
                {currentUser.accessRights.trainingManager < 2 ||
                currentUser.companyAdmin ? (
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<Edit />}
                    onClick={toggleProviderOpen}
                  >
                    Change Provider
                  </Button>
                ) : null}
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
        </Grid>

        <EditModule
          trainingModule={module}
          open={editOpen}
          close={() => setEditOpen(false)}
          currentUser={currentUser}
        />

        <ChangeProvider
          open={providerOpen}
          close={toggleProviderOpen}
          module={module}
        />
      </div>
    );
}
