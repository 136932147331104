import React from "react";
import {
  TextField,
  Grid,
  Card,
  CardHeader,
  Avatar,
  CardContent,
} from "@mui/material";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Select from "react-select";
import { useDb } from "../../../contexts/DatabaseContext";
import { selectFieldProps, textFieldProps } from "../../shared/constants";
import { Info } from "@mui/icons-material";

export default function MocRiskAssignTable({
  responses,
  riskActions,
  setRiskActions,
}) {
  const { GetUsersLevelFilter } = useDb();
  const users = GetUsersLevelFilter("mocManager", 2);

  const handleText = (e, index) => {
    const { name, value } = e.target;
    setRiskActions((prevActions) => {
      const newActions = [...prevActions];
      const updatedAction = { ...(newActions[index] || {}), [name]: value };
      newActions[index] = updatedAction;
      return newActions;
    });
  };

  const handleDateChange = (date, index) => {
    setRiskActions((prevActions) => {
      const newActions = [...prevActions];
      const updatedAction = { ...(newActions[index] || {}), dueDate: date };
      newActions[index] = updatedAction;
      return newActions;
    });
  };

  const handleSelect = (val, e, index) => {
    setRiskActions((prevActions) => {
      const newActions = [...prevActions];
      const updatedAction = {
        ...(newActions[index] || {}),
        hodResponsible: {
          id: val.value.id,
          label: val.label,
        },
      };
      newActions[index] = updatedAction;
      return newActions;
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container spacing={3}>
        {responses.map(
          (row, index) =>
            row.value === "yes" && (
              <Grid item xs={12} sm={6} key={index}>
                <Card>
                  <CardHeader
                    title={row.title}
                    avatar={
                      <Avatar>
                        <Info />
                      </Avatar>
                    }
                    subheader={
                      <DatePicker
                        label="Follow Up Due"
                        onChange={(date) =>
                          handleDateChange(date.toDate(), index)
                        }
                        defaultValue={dayjs(
                          riskActions[index]?.dueDate || new Date()
                        )}
                      />
                    }
                  />
                  <CardContent>
                    <TextField
                      {...textFieldProps}
                      label="Action Required"
                      name="actionRequired"
                      onChange={(e) => handleText(e, index)}
                    />
                    <Select
                      options={users}
                      {...selectFieldProps}
                      className="iso-select"
                      onChange={(val, e) => handleSelect(val, e, index)}
                      placeholder={`Select HOD ...`}
                      name="user"
                    />
                  </CardContent>
                </Card>
              </Grid>
            )
        )}
      </Grid>
    </LocalizationProvider>
  );
}
