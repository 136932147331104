import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faMinusCircle, faPaperPlane, faUsers, faUserTag } from '@fortawesome/free-solid-svg-icons'
import { Pagination } from '@mui/material';
import { Info } from '@mui/icons-material'
import { selectFieldProps } from '../constants'

const useStyles = makeStyles((theme) => ({
    dialog: {
        backgroundColor: theme.palette.dialogBg
    },
    card: {
        backgroundColor: theme.palette.cardBg
    }
}))

export default function IntPartiesTemplate({ open, closeDialog, collectionType, docId, doc }) {
    const classes = useStyles()
    const { 
        GetRosters, 
        GetGroups, 
        addDynamicIntParty, 
        GetDynamicIntParties, 
        addDynamicIntGroup, 
        GetDynamicIntPartyGroups, 
        removeDynamicIntParty, 
        removeDynamicIntGroup,
        emailDynamicIndividualParty,
        emailDynamicGroup,
        emailAllPartiesDynamic
    } = useDb()
    const rosters = GetRosters()
    const groups = GetGroups()
    const [intParty, setIntParty] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [group, setGroup] = useState(null)

    const parties = GetDynamicIntParties(collectionType, docId)
    const partyGroups = GetDynamicIntPartyGroups(collectionType, docId)
    
    // Pagination
    const itemsPerPage = 5
    const [page, setPage] = useState(1)
    const [noOfPages] = useState(
        Math.ceil(parties.length / itemsPerPage)
    )

    const [groupPage, setGroupPage] = useState(1)
    

    const handleAdd = async () => {
        setLoading(true)
        try {
            await addDynamicIntParty(collectionType, docId, intParty).then(() => {
                setIntParty(null)
                setLoading(false)
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    const handleGroupAdd = async () => {
      
        setLoading(true)
        setError(null)
        try {
            await addDynamicIntGroup(collectionType, docId, group).then(() => {
                setGroup(null)
                setLoading(false)
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    const handleRemove = async (pty) => {
        setLoading(true)
        try {
            await removeDynamicIntParty(collectionType, docId, pty).then(() => {
                setIntParty(null)
                setLoading(false)
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    const handleGroupRemove = async (grp) => {
        setLoading(true)
        setError(null)
        try {
            await removeDynamicIntGroup(collectionType, docId, grp).then(() => {
                setGroup(null)
                setLoading(false)
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    const handleSingleSend = async (pty) => {
        setLoading(true)
        setError(null)
        try {
            await emailDynamicIndividualParty(collectionType, doc, pty, false).then(() => {
                setLoading(false)
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    const handleGroupSend = async (grp) => {
        setLoading(true)
        setError(null)
        try {
            await emailDynamicGroup(collectionType, doc, grp.id).then(() => {
                setLoading(false)
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    const handleAllSend = async () => {
        setLoading(true)
        setError(null)
        try {
            await emailAllPartiesDynamic(collectionType, doc).then(() => {
                setLoading(false)
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    return (
        <Dialog open={open} onClose={closeDialog} maxWidth={'md'} fullWidth>
            
            {
                doc.pendingApproval > 0
                ?
                <DialogContent>
                        <CardHeader
                            title={'This document has approvals pending. Please wait until all approvals have been confirmed, before adding interested parties.'}
                            avatar={
                                <Avatar>
                                    <Info />
                                </Avatar>
                            }
                        />
                        <CardActions className="iso-right">
                            <Button color="secondary" onClick={closeDialog}>Close</Button>
                        </CardActions>
                </DialogContent>
                :
                <>
                    <DialogContent className={classes.dialog}>
                        { error ? <span className={'iso-error'}>{error}</span> : null }
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Select
                                    options={rosters}
                                    placeholder={'Add Interested Party ...'}
                                    {...selectFieldProps}
                                    onChange={(val) => setIntParty(val.value)}
                                />
                                { 
                                    intParty 
                                    ?
                                    <div>
                                        <br/>
                                        <Button disabled={loading} onClick={handleAdd} endIcon={<FontAwesomeIcon icon={faArrowDown} />} color={'primary'} variant={'contained'}>Add Party</Button>
                                        <br/>
                                    </div>
                                    :
                                    null
                                }
                                <br/>
                                <Card className={classes.card}>
                                    <CardHeader
                                        title="Interested Parties:"
                                        avatar={<Avatar><FontAwesomeIcon icon={faUserTag}/></Avatar>}
                                    />
                                    <CardContent>
                                        
                                        <List>
                                            {
                                                parties && parties
                                                .slice((groupPage -1) * itemsPerPage, groupPage * itemsPerPage)
                                                .map((pty) => {
                                                    let ackStatus = '';
                                                    if(pty.status && pty.status === 'Acknowledged') {
                                                        ackStatus = 'Acknowledged'
                                                    }
                                                    else {
                                                        ackStatus = pty.sent ? 'Email Sent' : 'Pending Email'
                                                    }
                                                    return (
                                                        <ListItem key={pty.id} className="iso-list-item">
                                                            <ListItemText>
                                                                <span className="iso-small">
                                                                    {`${pty.firstName} ${pty.lastName}`} 
                                                                    
                                                                    &nbsp;<Chip size="small" label={ackStatus}/> &nbsp;
                                                                    
                                                                </span>
                                                            </ListItemText>
                                                            <ListItemSecondaryAction>
                                                                <IconButton onClick={() => handleRemove(pty)} size="large"><FontAwesomeIcon icon={faMinusCircle}/></IconButton>
                                                                <IconButton onClick={() => handleSingleSend(pty)} size="large"><FontAwesomeIcon icon={faPaperPlane}/></IconButton>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    );
                                                })
                                            }
                                        </List>
                                        
                                    </CardContent>
                                    <CardActions>
                                        <Pagination
                                            count={Math.ceil(parties.length / itemsPerPage)}
                                            page={groupPage}
                                            onChange={(e, val) => setGroupPage(val)}
                                            defaultPage={1}
                                            color="primary"
                                            size="large"
                                            showFirstButton
                                            showLastButton
                                        />
                                    </CardActions>
                                </Card>
                            </Grid>
                            <Grid item xs={6}>
                                <Select
                                    options={groups}
                                    placeholder={'Add Groups ...'}
                                    {...selectFieldProps}
                                    onChange={(val) => setGroup(val)}
                                />
                                {
                                    group
                                    ?
                                    <div>
                                        <br/>
                                        <Button disabled={loading} onClick={handleGroupAdd} endIcon={<FontAwesomeIcon icon={faArrowDown} />} color={'primary'} variant={'contained'}>Add Group</Button>
                                        <br/>
                                    </div>
                                    :
                                    null
                                }
                                <br/>
                                <Card className={classes.card}>
                                    <CardHeader
                                        title="Groups:"
                                        avatar={<Avatar><FontAwesomeIcon icon={faUsers}/></Avatar>}
                                    />
                                    <CardContent>
                                        {
                                            partyGroups ?
                                            <CardContent>
                                                <List>
                                                    {
                                                        partyGroups && partyGroups.map((g) => {
                                                            return (
                                                                <ListItem key={g.id} className="iso-list-item">
                                                                    <ListItemText><span className="iso-small">{`${g.label}`} </span></ListItemText>
                                                                    <ListItemSecondaryAction>
                                                                        <IconButton onClick={() => handleGroupRemove(g)} size="large"><FontAwesomeIcon icon={faMinusCircle}/></IconButton>
                                                                        <IconButton onClick={() => handleGroupSend(g)} size="large"><FontAwesomeIcon icon={faPaperPlane}/></IconButton>
                                                                    </ListItemSecondaryAction>
                                                                </ListItem>
                                                            );
                                                        })
                                                    }
                                                </List>
                                                
                                            </CardContent>
                                            : null
                                        }
                                    </CardContent>
                                    <CardActions>
                                        <Pagination
                                            count={Math.ceil(partyGroups.length / itemsPerPage)}
                                            page={page}
                                            onChange={(e, val) => setPage(val)}
                                            defaultPage={1}
                                            color="primary"
                                            size="large"
                                            showFirstButton
                                            showLastButton
                                        />
                                    </CardActions>
                                </Card>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions className={classes.dialog}>
                        <Button disabled={loading} onClick={handleAllSend} color={'primary'} startIcon={<FontAwesomeIcon icon={faPaperPlane}/>} variant={'contained'}>Email All</Button>
                        <Button color={'secondary'} onClick={closeDialog}>Close</Button>
                    </DialogActions>
                </>
            }
        </Dialog>
    );
}
