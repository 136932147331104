import { Grid } from '@mui/material'
import React from 'react'
import Select from 'react-select'
import { useDb } from '../../../../contexts/DatabaseContext'
import { selectFieldProps } from '../../../shared/constants'

export default function DocRegisterFilters({ updateFilters }) {
    const { GetDepartments, GetSites, GetUsersLevelFilter, GetDocTypesDropdown } = useDb()
    const departments = GetDepartments()
    const sites = GetSites()
    const hods = GetUsersLevelFilter("docManager", 2);
    const types = GetDocTypesDropdown()

    // When calling this function, be sure to use a ternary operator in the last argument for a possible null value. See examples in Selects below.
    const handleSelect = (v, key, fieldName, value) => {
        if(!v) {
            updateFilters({
                key,
                data: {
                    fieldName,
                    value: null
                }
            })
        }
        else {
            updateFilters({
                key,
                data: {
                    fieldName,
                    value
                }
            })
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
                <Select
                    className="iso-select"
                    {...selectFieldProps}
                    options={departments}
                    onChange={(v) => handleSelect(v, 'department', 'department.id', v  ? v.id : null)}
                    placeholder="Department ..."
                    isClearable
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <Select
                    className="iso-select"
                    {...selectFieldProps}
                    options={sites}
                    onChange={(v) => handleSelect(v, 'site', 'site.value', v  ? v.value : null)}
                    placeholder="Site ..."
                    isClearable
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <Select
                    className="iso-select"
                    {...selectFieldProps}
                    options={types}
                    onChange={(v) => handleSelect(v, 'docType', 'docType.label', v  ? v.label : null)}
                    placeholder="Doc Type ..."
                    isClearable
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <Select
                    className="iso-select"
                    {...selectFieldProps}
                    options={hods}
                    onChange={(v) => handleSelect(v, 'owner', 'owner.label', v  ? v.label : null)}
                    placeholder="Doc Owner ..."
                    isClearable
                />
            </Grid>
        </Grid>
    )
}
