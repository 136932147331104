import { Avatar, Button, Card, CardActions, CardContent, CardHeader } from '@mui/material'
import { AddBox, CloudUpload, Warning } from '@mui/icons-material'
import React, { useState } from 'react'
import { useDb } from '../../../../../contexts/DatabaseContext'
import SelectCustomSign from './SelectCustomSign'
import SelectedSignsOfType from './SelectedSignsOfType'
import SelectExistingSign from './SelectExistingSign'
import UploadCustomSign from './UploadCustomSign'


export default function IBRiskSignTypes({ riskId, ibraId, type }) {
    const { addSignToIbra } = useDb()

    const [existingOpen, setExistingOpen] = useState(false)
    const [customOpen, setCustomOpen] = useState(false)
    const [uploadOpen, setUploadOpen] = useState(false)

    const [state, setState] = useState({
        loading: false,
        error: null
    })

    const handleSelect = async (s) => {
        setState({
            loading: true,
            error: null
        })
        try {
            await addSignToIbra(ibraId, s, riskId)
            setExistingOpen(false)
            setCustomOpen(false)
            setUploadOpen(false)
            setState({
                ...state,
                loading:false
            })
        }
        catch(err) {
            setState({
                loading: false,
                error: err.message
            })
        }
    }

    return (
        <>
            <Card>
                <CardHeader
                    title={type}
                    avatar={
                        <Avatar>
                            <Warning />
                        </Avatar>
                    }
                />
                <CardContent>
                    <SelectedSignsOfType
                        riskId={riskId}
                        ibraId={ibraId}
                        type={type}
                    />
                </CardContent>
                <CardActions>
                    <Button
                        startIcon={<AddBox />}
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => setExistingOpen(true)}
                    >
                        ISO 7010
                    </Button>
                    <Button
                        startIcon={<AddBox />}
                        onClick={() => setCustomOpen(true)}
                        variant="contained"
                        color="primary"
                        size="small"
                    >
                        Custom
                    </Button>
                    <Button
                        startIcon={<CloudUpload />}
                        onClick={() => setUploadOpen(true)}
                        variant="contained"
                        color="primary"
                        size="small"
                    >
                        Upload
                    </Button>
                </CardActions>
            </Card>

            <SelectExistingSign
                type={type}
                open={existingOpen}
                close={() => setExistingOpen(false)}
                ibraId={ibraId}
                riskId={riskId}
                handleSelect={handleSelect}
                loading={state.loading}
            />

            <SelectCustomSign
                type={type}
                ibraId={ibraId}
                riskId={riskId}
                open={customOpen}
                close={() => setCustomOpen(false)}
                setSign={handleSelect}
                loading={state.loading}
            />

            <UploadCustomSign
                open={uploadOpen}
                type={type}
                ibraId={ibraId}
                riskId={riskId}
                close={() => setUploadOpen(false)}
                setSign={handleSelect}
            />

        </>
    )
}
