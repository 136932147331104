import React from "react";
import Dashboard from "../dashboard/Dashboard";
import DepartmentList from "../master_list/departments/DepartmentList";
import RosterList from "../master_list/rosters/RosterList";
import UsersList from "../master_list/users/UsersList";
import PrivateRoute from "./PrivateRoute";
import DocManager from "../docmanager/DocManager";
import DocRegister from "../docmanager/doc-register/DocRegister";
import SiteList from "../master_list/sites/SiteList";
import DocTypeList from "../docmanager/doctypes/DocTypeList";
import AddRevision from "../docmanager/document/AddRevision";
import ApproveDoc from "../docmanager/approvals/ApproveDoc";
import ApproveRevision from "../docmanager/approvals/ApproveRevision";
import ProducePDF from "../docmanager/doc-register/produce-pdf/ProducePDF";
import AcknowledgeDoc from "../docmanager/approvals/AcknowledgeDoc";
import Groups from "../master_list/groups/Groups";
import MyFavDocs from "../docmanager/favorites/MyFavDocs";
import Employees from "../trainingmanager/employees/Employees";
import ActionManager from "../actionmanager/ActionManager";
import ActionRegister from "../actionmanager/ActionRegister";
import OtpManager from "../otpmanager/OtpManager";
import OtpRegister from "../otpmanager/OtpRegister";
import Sources from "../actionmanager/sources/Sources";
import IsoAgencies from "../master_list/iso-agencies/IsoAgencies";
import ActionPriorities from "../actionmanager/priorities/ActionPriorities";
import DocumentArchives from "../docmanager/doc-register/DocumentArchives";
import ManagementDashboard from "../management/ManagementDashboard";
import Testing from "../shared/Testing";
import TestCloseOut from "../actionmanager/views/TestCloseOut";
import CompanySettings from "../master_list/company-settings/CompanySettings";
import IsoClasses from "../master_list/iso-classes/IsoClasses";
import OtpCriteria from "../otpmanager/criteria/OtpCriteria";
import DocumentDetail from "../docmanager/document/DocumentDetail";
import SupportingDocDetail from "../docmanager/supporting-documents/SupportingDocDetail";
import UserTemplates from "../docmanager/templates/UserTemplates";
import AuditTrail from "../audittrail/AuditTrail";
import UserLogsSearch from "../audittrail/UserLogsSearch";
import SupportThanks from "../menu/SupportThanks";
import RequestActionExtension from "../actionmanager/views/RequestActionExtension";
import ActionExtensionApproval from "../actionmanager/views/ActionExtensionApproval";
import ProgramAttachments from "../otpmanager/programs/ProgramAttachments";
import ViewTargets from "../otpmanager/ViewTargets";
import ViewPrograms from "../otpmanager/programs/ViewPrograms";
import ActionInterestedParties from "../actionmanager/views/ActionInterestedParties";
import ActionAttachments from "../actionmanager/views/ActionAttachments";
import ActionDetail from "../actionmanager/views/ActionDetail";
import DocIntPartiesNew from "../docmanager/document/interested-parties/DocIntPartiesNew";
import DocApprovers from "../docmanager/document/interested-parties/DocApprovers";
import SupDocsNew from "../docmanager/supporting-documents/SupDocsNew";
import OtpMetrics from "../otpmanager/metrics/OtpMetrics";
import ProgramDetail from "../otpmanager/programs/ProgramDetail";
import DocManagerSettings from "../docmanager/settings/DocManagerSettings";
import PendingDocs from "../docmanager/pending-register/PendingDocs";
import RevisionHistoryN from "../docmanager/revisions/RevisionHistoryN";
import AddRevisionN from "../docmanager/revisions/AddRevisionN";
import AddRevisionApproversN from "../docmanager/revisions/AddRevisionApproversN";
import RevisionDetailN from "../docmanager/revisions/RevisionDetailN";
import TrainingManager from "../trainingmanager/TrainingManager";
import TrainingProviders from "../trainingmanager/providers/TrainingProviders";
import Facilitators from "../trainingmanager/facilitators/Facilitators";
import TrainingSettings from "../trainingmanager/settings/TrainingSettings";
import TrainingModules from "../trainingmanager/modules/TrainingModules";
import AddTrainingModule from "../trainingmanager/modules/AddTrainingModule";
import TrainingModuleDetail from "../trainingmanager/modules/TrainingModuleDetail";
import Occupations from "../trainingmanager/occupations/Occupations";
import AddOccupation from "../trainingmanager/occupations/AddOccupation";
import OccupationDetail from "../trainingmanager/occupations/OccupationDetail";
import TrainingVenues from "../trainingmanager/venues/TrainingVenues";
import VenueDetail from "../trainingmanager/venues/VenueDetail";
import FacilitatorDashboard from "../trainingmanager/facilitators/FacilitatorDashboard";
import TrainingSessions from "../trainingmanager/sessions/TrainingSessions";
import ScheduleSession from "../trainingmanager/sessions/ScheduleSession";
import SessionDetail from "../trainingmanager/sessions/SessionDetail";
import ConfirmSessionAttendance from "../trainingmanager/sessions/ConfirmSessionAttendance";
import FacilitatorSession from "../trainingmanager/facilitators/FacilitatorSession";
import EmployeeDetail from "../trainingmanager/employees/EmployeeDetail";
import Evaluations from "../trainingmanager/evaluations/Evaluations";
import NewEvaluation from "../trainingmanager/evaluations/NewEvaluation";
import EmployeeEvaluations from "../trainingmanager/employees/EmployeeEvaluations";
import EmployeeDashboard from "../trainingmanager/employees/employee-views/EmployeeDashboard";
import TrainingReports from "../trainingmanager/reports/TrainingReports";
import TrainingMatrixView from "../trainingmanager/reports/training-matrix/TrainingMatrixView";
import TrainingRequests from "../trainingmanager/requests/TrainingRequests";
import AssignToSession from "../trainingmanager/requests/AssignToSession";
import TrainingAdminDashboard from "../trainingmanager/admin-dashboard/TrainingAdminDashboard";
import RDocRegister from "../docmanager/refactor/doc-register/RDocRegister";
import DocSearch from "../docmanager/refactor/doc-register/DocSearch";
import MatrixReports from "../trainingmanager/reports/training-matrix/MatrixReports";
import SessionReports from "../trainingmanager/reports/session-reports/SessionReports";
import SessionReportDetail from "../trainingmanager/reports/session-reports/SessionReportDetail";
import PastSessions from "../trainingmanager/sessions/PastSessions";
import BookedVsAttended from "../trainingmanager/reports/bookedattended/BookedVsAttended";
import AttendanceReport from "../trainingmanager/reports/bookedattended/AttendanceReport";
import TempDocSearch from "../docmanager/refactor/doc-register/TempDocSearch";
import MyFavouriteDocs from "../docmanager/refactor/doc-register/MyFavouriteDocs";
import REditDoc from "../docmanager/refactor/doc-register/REditDoc";
import RPendingRegister from "../docmanager/refactor/doc-register/RPendingRegister";
import RPendingDocs from "../docmanager/refactor/doc-register/RPendingDocs";
import RActionRegister from "../actionmanager/refactor/RActionRegister";
import ROverdueDocs from "../docmanager/refactor/dueFiltered/ROverdueDocs";
import ROverdueActions from "../actionmanager/refactor/ROverdueActions";
import ActionsTempSearch from "../actionmanager/refactor/ActionsTempSearch";
import FacilitatorDetail from "../trainingmanager/facilitators/FacilitatorDetail";
import ROtpRegister from "../otpmanager/refactor/ROtpRegister";
import OTPTempSearch from "../otpmanager/refactor/OTPTempSearch";
import RiskManager from "../riskmanager/RiskManager";
import R1ChooseMatrix from "../riskmanager/setup/R1ChooseMatrix";
import RiskManagerDashboard from "../riskmanager/RiskManagerDashboard";
import Matrix5x5 from "../riskmanager/preview/Matrix5x5";
import Matrix8x8 from "../riskmanager/preview/Matrix8x8";
import Matrix6x6 from "../riskmanager/preview/Matrix6x6";
import R2ChooseAreas from "../riskmanager/setup/R2ChooseAreas";
import R3CreateRiskTypes from "../riskmanager/setup/R3CreateRiskTypes";
import R4RiskEntities from "../riskmanager/setup/R4RiskEntities";
import R5RiskExposurePattern from "../riskmanager/setup/R5RiskExposurePattern";
import R6CriticalControlThreshold from "../riskmanager/setup/R6CriticalControlThreshold";
import R7HeirarchyOfControl from "../riskmanager/setup/R7HeirarchyOfControl";
import BaselineRiskAssessments from "../riskmanager/baseline/BaselineRiskAssessments";
import AddBaselineRisk1 from "../riskmanager/baseline/new-assessment/AddBaselineRisk1";
import AddBaselineRisk2 from "../riskmanager/baseline/new-assessment/AddBaselineRisk2";
import RiskProcesses from "../riskmanager/processes/RiskProcesses";
import AddRiskProcess from "../riskmanager/processes/AddRiskProcess";
import AddBaselineRisk3 from "../riskmanager/baseline/new-assessment/AddBaselineRisk3";
import HazardDetails from "../riskmanager/baseline/new-assessment/HazardDetails";
import AddRisk2RawRisk from "../riskmanager/baseline/new-risk/AddRisk2RawRisk";
import AddRisk3Hierarchy from "../riskmanager/baseline/new-risk/AddRisk3Hierarchy";
import AddRisk4FinalRisk from "../riskmanager/baseline/new-risk/AddRisk4FinalRisk";
import AddRisk5ResidualRisk from "../riskmanager/baseline/new-risk/AddRisk5ResidualRisk";
import IssueBasedRAs from "../riskmanager/issue-based/IssueBasedRAs";
import NewIssueBasedRA from "../riskmanager/issue-based/new-assessment/NewIssueBasedRA";
import IBFromBaseline1 from "../riskmanager/issue-based/new-assessment/IBFromBaseline1";
import IBFromBaseline2 from "../riskmanager/issue-based/new-assessment/IBFromBaseline2";
import IBFromBaselineHazardRisks from "../riskmanager/issue-based/new-assessment/IBFromBaselineHazardRisks";
import AddIBRiskSigns from "../riskmanager/issue-based/new-assessment/new-risk/AddIBRiskSigns";
import IBRiskHierarchy from "../riskmanager/issue-based/new-assessment/new-risk/IBRiskHierarchy";
import IBRiskHazards from "../riskmanager/issue-based/new-assessment/new-risk/IBRiskHazards";
import IBRiskMatrix from "../riskmanager/issue-based/new-assessment/new-risk/IBRiskMatrix";
import IBRiskResidualRisk from "../riskmanager/issue-based/new-assessment/new-risk/IBRiskResidualRisk";
import BlraDetail from "../riskmanager/baseline/blra-detail/BlraDetail";
import BlraRiskDetail from "../riskmanager/risk-detail/BlraRiskDetail";
import IbraRiskDetail from "../riskmanager/risk-detail/IbraRiskDetail";
import IbraDetail from "../riskmanager/issue-based/IbraDetail";
import CriticalControls from "../riskmanager/critical-controls/CriticalControls";
import RiskSettings from "../riskmanager/settings/RiskSettings";
import RiskProcessDetail from "../riskmanager/processes/RiskProcessDetail";
import AuditManager from "../audit-manager/AuditManager";
import AuditChecklists from "../audit-manager/checklists/AuditChecklists";
import NewAuditChecklist from "../audit-manager/checklists/NewAuditChecklist";
import ChecklistCreator from "../audit-manager/checklists/ChecklistCreator";
import AddSchedule from "../audit-manager/schedules/AddSchedule";
import AuditSchedule from "../audit-manager/schedules/AuditSchedule";
import AuditInspections from "../audit-manager/inspections/AuditInspections";
import InspectionFormSubmission from "../audit-manager/inspections/InspectionFormSubmission";
import AuditIssues from "../audit-manager/issues/AuditIssues";
import IssueDetail from "../audit-manager/issues/IssueDetail";
import ContraPro from "../audit-manager/contrapro/ContraPro";
import AllInspections from "../audit-manager/inspections/AllInspections";
import EmployeeAttachments from "../trainingmanager/employees/EmployeeAttachments";
import ActionArchive from "../actionmanager/views/ActionArchive";
import ViewInspections from "../audit-manager/inspections/ViewInspections";
import FixTrainingComp from "../management/FixTrainingComp";
import FixPrograms from "../management/FixPrograms";
import DSTrainingMatrix from "../trainingmanager/reports/training-matrix/DSTrainingMatrix";
import ActionExtensionView from "../actionmanager/views/ActionExtensionView";
import ProviderView from "../trainingmanager/providers/ProviderView";
import Account from "../account/Account";
import AddPlan from "../account/AddPlan";
import { Redirect, Switch } from "react-router-dom";
import REditAction from "../actionmanager/refactor/REditAction";
import DataStudioDashboard from "../dashboard/DataStudioDashboard";
import SaTrialExpired from "../account/manual/SaTrialExpired";
import BlraProcessSteps from "../riskmanager/baseline/new-assessment/BlraProcessSteps";
import BlraProcessStepHazards from "../riskmanager/baseline/new-assessment/BlraProcessStepHazards";
import OtpEditNew from "../otpmanager/refactor/OtpEditNew";
import FiveWhyAnalysis from "../actionmanager/root-cause-analysis/5why/FiveWhyAnalysis";
import FishboneAnalysis from "../actionmanager/root-cause-analysis/fishbone/FishboneAnalysis";
import IbraEdit from "../riskmanager/issue-based/edit/IbraEdit";
import SetupWizard from "../moc-manager/setup/SetupWizard";
import MocSetup from "../moc-manager/setup/MocSetup";
import MocRegister from "../moc-manager/moc-register/MocRegister";
import Phase1 from "../moc-manager/change-request/Phase1";
import Phase2 from "../moc-manager/change-request/Phase2";

import MocRequestStep from "../moc-manager/change-request/phase-steps/MocRequestStep";
import Moc from "../moc-manager/change-request/Moc";
import EditRiskThresholds from "../moc-manager/risk-levels/EditRiskThresholds";
import ExpectedBenefits from "../moc-manager/benefits/ExpectedBenefits";
import RiskQuestions from "../moc-manager/risk-questions/RiskQuestions";
import MocChecklist from "../moc-manager/moc-checklist/MocChecklist";
import FollowUpChecklist from "../moc-manager/moc-components/evaluations/FollowUpChecklist";
import ImportProgramData from "../otpmanager/programs/import-data/ImportProgramData";
import FolderRegister from "../docmanager/shared-folders/FolderRegister";
import SharedFolderDocuments from "../docmanager/shared-folders/SharedFolderDocuments";
import FishboneTemplates from "../actionmanager/root-cause-analysis/fishbone/FishboneTemplates";
import NotificationLog from "../master_list/notification-log/NotificationLog";
import SharedFolders from "../docmanager/shared-folders/SharedFolders";
import MocDashboard from "../moc-manager/moc-dashboard/MocDashboard";

export default function Routes() {
  return (
    <Switch>
      <PrivateRoute exact path="/" component={DataStudioDashboard} />
      <PrivateRoute exact path="/fixcomp" component={FixTrainingComp} />
      <PrivateRoute exact path="/fixpro" component={FixPrograms} />
      <PrivateRoute path="/master-list/users" component={UsersList} />
      <PrivateRoute
        path="/master-list/departments"
        component={DepartmentList}
      />
      <PrivateRoute path="/master-list/rosters" component={RosterList} />
      <PrivateRoute path="/master-list/sites" component={SiteList} />
      <PrivateRoute path="/master-list/doc-types" component={DocTypeList} />
      <PrivateRoute path="/master-list/iso-classes" component={IsoClasses} />
      <PrivateRoute exact path="/doc-manager" component={DocManager} />
      <PrivateRoute
        path="/doc-manager/add-revision/:docId/:docCode"
        component={AddRevisionN}
      />
      <PrivateRoute
        path="/doc-manager/approve-doc/:id"
        component={ApproveDoc}
      />
      <PrivateRoute
        path="/doc-manager/approve-revision/:docid/:revid"
        component={ApproveRevision}
      />
      <PrivateRoute path="/doc-manager/pdf" component={ProducePDF} />
      <PrivateRoute
        path="/doc-manager/acknowledge-doc/:id"
        component={AcknowledgeDoc}
      />
      <PrivateRoute
        path="/doc-manager/doc-manager/my-favs/"
        component={MyFavDocs}
      />
      <PrivateRoute
        path="/doc-manager/archives/"
        component={DocumentArchives}
      />
      <PrivateRoute path="/doc-manager/templates" component={UserTemplates} />
      <PrivateRoute path="/master-list/groups" component={Groups} />
      <PrivateRoute path="/master-list/employees" component={Employees} />
      <PrivateRoute path="/master-list/iso-agencies" component={IsoAgencies} />

      {/* Action Manager */}
      <PrivateRoute exact path="/action-manager" component={ActionManager} />
      <PrivateRoute exact path="/action/:actionId" component={ActionDetail} />
      <PrivateRoute path="/action-manager/sources" component={Sources} />
      <PrivateRoute
        path="/action-manager/priorities"
        component={ActionPriorities}
      />
      <PrivateRoute
        path="/action-manager/close-out-action/:actionId"
        component={TestCloseOut}
      />
      <PrivateRoute
        path="/action-manager/request-extension/:actionId"
        component={RequestActionExtension}
      />
      <PrivateRoute
        path="/action-extension/:actionId"
        component={ActionExtensionApproval}
      />
      <PrivateRoute
        path="/action-int-parties/:actionId/:actionNo"
        component={ActionInterestedParties}
      />
      <PrivateRoute
        path="/action-attachments/:actionId"
        component={ActionAttachments}
      />
      <PrivateRoute path="/action-archive/" component={ActionArchive} />
      <PrivateRoute path="/edit-action/:id" component={REditAction} />
      <PrivateRoute
        path="/5-why-analysis/:actionId"
        component={FiveWhyAnalysis}
      />
      <PrivateRoute
        exact
        path="/fishbone-analysis/:actionId"
        component={FishboneAnalysis}
      />
      <PrivateRoute
        exact
        path="/fishbone-analysis/:actionId/templates"
        component={FishboneTemplates}
      />

      {/* OTP Manager */}
      <PrivateRoute exact path="/otp-manager" component={OtpManager} />
      <PrivateRoute path="/otp-manager/register" component={ROtpRegister} />
      <PrivateRoute path="/otp-manager/criteria" component={OtpCriteria} />
      <PrivateRoute path="/otp/:otpId/targets" component={ViewTargets} />
      <PrivateRoute
        path="/target/:otpId/:targetId/programs"
        component={ViewPrograms}
      />
      <PrivateRoute path="/management" component={ManagementDashboard} />
      <PrivateRoute path="/testing" component={Testing} />
      <PrivateRoute path="/company-settings" component={CompanySettings} />
      <PrivateRoute exact path="/audit-trail" component={AuditTrail} />
      <PrivateRoute
        path="/audit-trail/search/user/:uid/:from/:to/"
        component={UserLogsSearch}
      />
      <PrivateRoute path="/support-thanks/" component={SupportThanks} />
      <PrivateRoute
        path="/program-attachments/:otpId/:targetId/:programId"
        component={ProgramAttachments}
      />
      <PrivateRoute
        path="/doc-manager/sup-doc/:docid/:id"
        component={SupportingDocDetail}
      />
      <PrivateRoute
        path="/doc-manager/doc-detail/:id"
        component={DocumentDetail}
      />
      <PrivateRoute
        path="/doc-int-parties/:docId"
        component={DocIntPartiesNew}
      />
      <PrivateRoute path="/doc-approvers/:docId" component={DocApprovers} />
      <PrivateRoute path="/doc-supdocs/:docId" component={SupDocsNew} />
      <PrivateRoute path="/otp-metrics" component={OtpMetrics} />
      <PrivateRoute
        path="/otp-program/:otpId/:targetId/:programId"
        component={ProgramDetail}
      />
      <PrivateRoute path="/doc-settings" component={DocManagerSettings} />
      <PrivateRoute path="/edit-objective/:otpId" component={OtpEditNew} />

      <PrivateRoute
        path="/doc-manager/revisions/:docId/:docCode"
        component={RevisionHistoryN}
      />
      <PrivateRoute
        path="/doc-manager/revision-approvals/:docId/:revId/"
        component={AddRevisionApproversN}
      />
      <PrivateRoute
        path="/doc-manager/revision/:docId/:revId/:docCode"
        component={RevisionDetailN}
      />

      {/* Training Manager */}
      <PrivateRoute
        exact
        path="/training-manager"
        component={TrainingManager}
      />
      <PrivateRoute
        path="/training-manager/providers"
        component={TrainingProviders}
      />
      <PrivateRoute
        path="/training-manager/provider/:proId/view"
        component={ProviderView}
      />
      <PrivateRoute
        path="/training-manager/facilitators"
        component={Facilitators}
      />
      <PrivateRoute
        path="/training-manager/settings"
        component={TrainingSettings}
      />
      <PrivateRoute
        exact
        path="/training-manager/modules"
        component={TrainingModules}
      />
      <PrivateRoute
        path="/training-manager/modules/add"
        component={AddTrainingModule}
      />
      <PrivateRoute
        path="/training-manager/module/:id/:code"
        component={TrainingModuleDetail}
      />
      <PrivateRoute
        exact
        path="/training-manager/occupations"
        component={Occupations}
      />
      <PrivateRoute
        path="/training-manager/occupations/add"
        component={AddOccupation}
      />
      <PrivateRoute
        exact
        path="/training-manager/occupation/:id"
        component={OccupationDetail}
      />
      <PrivateRoute
        exact
        path="/training-manager/venues"
        component={TrainingVenues}
      />
      <PrivateRoute
        path="/training-manager/venue/:id"
        component={VenueDetail}
      />
      <PrivateRoute
        exact
        path="/training-manager/employees"
        component={Employees}
      />
      <PrivateRoute
        exact
        path="/training-manager/facilitator-dashboard"
        component={FacilitatorDashboard}
      />
      <PrivateRoute
        exact
        path="/training-manager/sessions"
        component={TrainingSessions}
      />
      <PrivateRoute
        exact
        path="/training-manager/past-sessions"
        component={PastSessions}
      />
      <PrivateRoute
        path="/training-manager/sessions/schedule"
        component={ScheduleSession}
      />
      <PrivateRoute
        path="/training-manager/sessions/detail/:id"
        component={SessionDetail}
      />
      <PrivateRoute
        path="/training-session-invite/:id/:userId"
        component={ConfirmSessionAttendance}
      />
      <PrivateRoute
        path="/training-manager/sessions/facilitator/:id"
        component={FacilitatorSession}
      />
      <PrivateRoute
        exact
        path="/training-manager/employees/detail/:id"
        component={EmployeeDetail}
      />
      <PrivateRoute
        exact
        path="/training-manager/evaluations"
        component={Evaluations}
      />
      <PrivateRoute
        path="/training-manager/evaluations/new/:empId"
        component={NewEvaluation}
      />
      <PrivateRoute
        path="/training-manager/employees/evaluations/:empId"
        component={EmployeeEvaluations}
      />
      <PrivateRoute
        path="/training-manager/employee-dashboard"
        component={EmployeeDashboard}
      />
      <PrivateRoute
        exact
        path="/training-manager/reports"
        component={DSTrainingMatrix}
      />
      <PrivateRoute
        path="/training-manager/matrix/:matrixId"
        component={TrainingMatrixView}
      />
      <PrivateRoute
        exact
        path="/training-manager/training-requests"
        component={TrainingRequests}
      />
      <PrivateRoute
        exact
        path="/training-manager/assign-request/:moduleId/:employeeId/:requestId"
        component={AssignToSession}
      />
      <PrivateRoute
        path="/training-manager/admin-dashboard"
        component={TrainingAdminDashboard}
      />
      <PrivateRoute
        exact
        path="/training-manager/reports/training-matrix"
        component={MatrixReports}
      />
      <PrivateRoute
        exact
        path="/training-manager/reports/sessions"
        component={SessionReports}
      />
      <PrivateRoute
        exact
        path="/training-manager/reports/sessions/:reportId"
        component={SessionReportDetail}
      />
      <PrivateRoute
        exact
        path="/training-manager/reports/bookedvsattended"
        component={BookedVsAttended}
      />
      <PrivateRoute
        exact
        path="/training-manager/reports/bookedvsattended/:reportId"
        component={AttendanceReport}
      />
      <PrivateRoute path="/facilitator/:id" component={FacilitatorDetail} />
      <PrivateRoute
        exact
        path="/training-manager/employees/detail/:empId/employees/attachments"
        component={EmployeeAttachments}
      />
      <PrivateRoute
        exact
        path="/training-matrix"
        component={DSTrainingMatrix}
      />

      {/* RiskManager */}

      <PrivateRoute exact path="/risk-manager" component={RiskManager} />
      <PrivateRoute
        path="/risk-manager/dashboard"
        component={RiskManagerDashboard}
      />
      <PrivateRoute
        exact
        path="/risk-manager/setup/choose-matrix"
        component={R1ChooseMatrix}
      />
      <PrivateRoute
        path="/risk-manager/setup/choose-matrix/5x5preview"
        component={Matrix5x5}
      />
      <PrivateRoute
        path="/risk-manager/setup/choose-matrix/6x6preview"
        component={Matrix6x6}
      />
      <PrivateRoute
        path="/risk-manager/setup/choose-matrix/8x8preview"
        component={Matrix8x8}
      />
      <PrivateRoute
        exact
        path="/risk-manager/setup/choose-areas"
        component={R2ChooseAreas}
      />
      <PrivateRoute
        exact
        path="/risk-manager/setup/add-risk-types"
        component={R3CreateRiskTypes}
      />
      <PrivateRoute
        exact
        path="/risk-manager/setup/add-risk-entities"
        component={R4RiskEntities}
      />
      <PrivateRoute
        exact
        path="/risk-manager/setup/add-risk-exposure-pattern"
        component={R5RiskExposurePattern}
      />
      <PrivateRoute
        exact
        path="/risk-manager/setup/critical-control-threshold"
        component={R6CriticalControlThreshold}
      />
      <PrivateRoute
        exact
        path="/risk-manager/setup/heirarchy-of-control"
        component={R7HeirarchyOfControl}
      />
      <PrivateRoute
        exact
        path="/risk-manager/areas"
        component={R2ChooseAreas}
      />
      <PrivateRoute
        exact
        path="/risk-manager/risk-types"
        component={R3CreateRiskTypes}
      />
      <PrivateRoute
        exact
        path="/risk-manager/entities"
        component={R4RiskEntities}
      />
      <PrivateRoute
        exact
        path="/risk-manager/patterns"
        component={R5RiskExposurePattern}
      />
      <PrivateRoute
        exact
        path="/risk-manager/baselines"
        component={BaselineRiskAssessments}
      />
      <PrivateRoute
        exact
        path="/risk-manager/baselines/add-baseline-1"
        component={AddBaselineRisk1}
      />
      <PrivateRoute
        exact
        path="/risk-manager/baselines/add-baseline-2/:blraId/:siteId"
        component={AddBaselineRisk2}
      />
      <PrivateRoute
        exact
        path="/risk-manager/baselines/add-baseline-3/:blraId/"
        component={AddBaselineRisk3}
      />
      <PrivateRoute
        exact
        path="/risk-manager/baselines/baseline-process-steps/:blraId/:processId/:processName"
        component={BlraProcessSteps}
      />
      <PrivateRoute
        exact
        path="/risk-manager/baselines/baseline-step-hazards/:blraId/:stepId/:stepName/:processId"
        component={BlraProcessStepHazards}
      />
      <PrivateRoute
        exact
        path="/risk-manager/baselines/:blraId/hazards/:hazardId"
        component={HazardDetails}
      />
      <PrivateRoute
        exact
        path="/risk-manager/baseline-detail/:blra/:blraId"
        component={BlraDetail}
      />
      <PrivateRoute
        exact
        path="/risk-manager/baseline-risk-detail/:blraId/:riskId"
        component={BlraRiskDetail}
      />
      <PrivateRoute
        exact
        path="/risk-manager/edit-ibra/:ibraId"
        component={IbraEdit}
      />

      {/* Refactored Re-usable Risk Matrixes */}
      <PrivateRoute
        exact
        path="/risk-manager/inherent-risk/:assId/risk/:riskId/2/:type"
        component={AddRisk2RawRisk}
      />
      <PrivateRoute
        exact
        path="/risk-manager/residual-risk/:assId/risk/:riskId/4/:type"
        component={AddRisk4FinalRisk}
      />

      <PrivateRoute
        exact
        path="/risk-manager/baselines/:blraId/risk/:riskId/3"
        component={AddRisk3Hierarchy}
      />
      <PrivateRoute
        exact
        path="/risk-manager/baselines/:blraId/risk/:riskId/5/:hazardId"
        component={AddRisk5ResidualRisk}
      />

      <PrivateRoute
        exact
        path="/risk-manager/processes"
        component={RiskProcesses}
      />
      <PrivateRoute
        exact
        path="/risk-manager/process-detail/:processId"
        component={RiskProcessDetail}
      />
      <PrivateRoute
        path="/risk-manager/processes/add/"
        component={AddRiskProcess}
      />
      <PrivateRoute
        exact
        path="/risk-manager/issue-based"
        component={IssueBasedRAs}
      />
      <PrivateRoute
        exact
        path="/risk-manager/issue-based/new"
        component={NewIssueBasedRA}
      />
      <PrivateRoute
        exact
        path="/risk-manager/issue-based/:ibraId/:blraId/1"
        component={IBFromBaseline1}
      />
      <PrivateRoute
        exact
        path="/risk-manager/issue-based/:ibraId/2"
        component={IBFromBaseline2}
      />
      <PrivateRoute
        exact
        path="/risk-manager/issue-based/:ibraId/3"
        component={IBRiskHazards}
      />
      <PrivateRoute
        exact
        path="/risk-manager/issue-based/:ibraId/hazard/:hazardId/edit"
        component={HazardDetails}
      />
      <PrivateRoute
        exact
        path="/risk-manager/issue-based/:ibraId/hazards/:hazardId/risks"
        component={IBFromBaselineHazardRisks}
      />
      <PrivateRoute
        exact
        path="/risk-manager/issue-based/:ibraId/ppesigns/:riskId"
        component={AddIBRiskSigns}
      />
      <PrivateRoute
        exact
        path="/risk-manager/issue-based/:ibraId/hierarchy/:riskId"
        component={IBRiskHierarchy}
      />
      <PrivateRoute
        exact
        path="/risk-manager/issue-based/:ibraId/risk-matrix/:riskId"
        component={IBRiskMatrix}
      />
      <PrivateRoute
        exact
        path="/risk-manager/issue-based/:ibraId/residual-risk/:riskId"
        component={IBRiskResidualRisk}
      />
      <PrivateRoute
        exact
        path="/risk-manager/issue-based-detail/:ibraId/:refNo"
        component={IbraDetail}
      />
      <PrivateRoute
        exact
        path="/risk-manager/ibra-risk-detail/:ibraId/:riskId"
        component={IbraRiskDetail}
      />

      <PrivateRoute
        exact
        path="/risk-manager/critical-controls"
        component={CriticalControls}
      />
      <PrivateRoute
        exact
        path="/risk-manager/settings"
        component={RiskSettings}
      />

      {/* Refactored  */}
      {/* <PrivateRoute path='/doc-manager/doc-register' component={DocRegister} /> */}
      <PrivateRoute path="/doc-manager/doc-register" component={RDocRegister} />
      {/* <PrivateRoute path='/doc-manager/search' component={DocSearch} /> */}
      <PrivateRoute path="/doc-manager/search" component={TempDocSearch} />
      <PrivateRoute path="/doc-manager/my-favs" component={MyFavouriteDocs} />
      <PrivateRoute
        exact
        path="/doc-manager/shared-folders"
        component={FolderRegister}
      />
      <PrivateRoute
        path="/doc-manager/shared-folders/:folderId"
        component={SharedFolderDocuments}
      />
      <PrivateRoute path="/doc-manager/edit-doc/:docId" component={REditDoc} />
      <PrivateRoute
        path="/doc-manager/pending-approval"
        component={RPendingDocs}
      />
      <PrivateRoute
        path="/action-manager/extension-requests/:actionId/:reqId"
        component={ActionExtensionView}
      />
      <PrivateRoute
        path="/action-manager/register"
        component={RActionRegister}
      />
      <PrivateRoute path="/doc-manager/overdue-docs" component={ROverdueDocs} />
      <PrivateRoute
        path="/action-manager/overdue"
        component={ROverdueActions}
      />
      <PrivateRoute
        path="/action-manager/search"
        component={ActionsTempSearch}
      />
      <PrivateRoute path="/otp-manager/search" component={OTPTempSearch} />

      {/* Audit Manager */}

      <PrivateRoute exact path="/audit-manager" component={AuditManager} />
      <PrivateRoute
        exact
        path="/audit-manager/checklists"
        component={AuditChecklists}
      />
      <PrivateRoute
        exact
        path="/audit-manager/checklists/create"
        component={NewAuditChecklist}
      />
      <PrivateRoute
        exact
        path="/audit-manager/checklists/builder/:id"
        component={ChecklistCreator}
      />
      <PrivateRoute
        exact
        path="/audit-manager/add-schedule/:checklistId"
        component={AddSchedule}
      />
      <PrivateRoute
        exact
        path="/audit-manager/schedules"
        component={AuditSchedule}
      />
      <PrivateRoute
        exact
        path="/audit-manager/inspections"
        component={AllInspections}
      />
      <PrivateRoute
        exact
        path="/audit-manager/inspection-submission/:checklistId/:inspectionId"
        component={InspectionFormSubmission}
      />
      <PrivateRoute
        exact
        path="/audit-manager/inspection-view/:inspectionId"
        component={ViewInspections}
      />
      <PrivateRoute
        exact
        path="/audit-manager/issues"
        component={AuditIssues}
      />
      <PrivateRoute
        exact
        path="/audit-manager/issues/:issueId"
        component={IssueDetail}
      />
      <PrivateRoute
        exact
        path="/audit-manager/contrapro"
        component={ContraPro}
      />

      {/* MOC Manager */}
      <PrivateRoute exact path="/moc-setup" component={MocSetup} />
      <PrivateRoute exact path="/moc-register" component={MocRegister} />
      <PrivateRoute exact path="/moc/phase-1/:mocId" component={Phase1} />
      <PrivateRoute exact path="/moc/phase-2/:mocId" component={Phase2} />
      <PrivateRoute exact path="/moc-request/:mocId" component={Moc} />
      <PrivateRoute
        exact
        path="/moc-risk-settings"
        component={EditRiskThresholds}
      />
      <PrivateRoute
        exact
        path="/moc-benefits-settings"
        component={ExpectedBenefits}
      />
      <PrivateRoute
        exact
        path="/moc-risk-questionnaire-edit"
        component={RiskQuestions}
      />
      <PrivateRoute exact path="/moc-checklist-edit" component={MocChecklist} />
      <PrivateRoute
        exact
        path="/followup-checklist-example"
        component={FollowUpChecklist}
      />
      <PrivateRoute exact path="/moc-dashboard" component={MocDashboard} />

      {/* Account */}
      <PrivateRoute exact path="/account" component={Account} />
      {/* AddPlan */}
      <PrivateRoute exact path="/addplan" component={AddPlan} />
      {/* SA Trial Expired */}
      <PrivateRoute exact path="/sa-trial-expired" component={SaTrialExpired} />

      <PrivateRoute
        exact
        path="/doc-manager/shared-folders"
        component={SharedFolders}
      />

      {/* SAGCO */}
      <PrivateRoute
        exact
        path="/import-program-data/:otpId/:targetId/:programId"
        component={ImportProgramData}
      />

      <PrivateRoute path="/notification-log" component={NotificationLog} />

      {/* Catch Unrouted Routes */}
      <PrivateRoute exact path="*">
        <Redirect to="/" />
      </PrivateRoute>
    </Switch>
  );
}
