import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { AddBox } from "@mui/icons-material";
import React, { useState } from "react";
import Select from "react-select";
import { useDb } from "../../contexts/DatabaseContext";
import { selectFieldProps } from "../shared/constants";

export default function AddParticipant({
  open,
  close,
  add,
  moduleName,
  accessLevel,
  roleName,
}) {
  const { GetUsersLevelFilter } = useDb();
  const users = GetUsersLevelFilter(moduleName, accessLevel);

  const [state, setState] = useState({});

  const handleClose = () => {
    setState({});
    close();
  };

  const handleAdd = () => {
    add({ ...state });
    handleClose();
  };

  const handleSelect = (v, e) => {
    setState({
      ...state,
      [e.name]: v,
    });
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={handleClose}>
      <DialogTitle>Add {roleName}</DialogTitle>
      <DialogContent>
        <Select
          options={users}
          {...selectFieldProps}
          className="iso-select"
          onChange={handleSelect}
          placeholder={`Select ${roleName} ...`}
          name="user"
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          startIcon={<AddBox />}
          color="primary"
          variant="contained"
          disabled={!state.user}
          onClick={handleAdd}
        >
          Add {roleName}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
