import { Button, Card, CardContent, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import FloatingHelpComponent from '../../help/FloatingHelpComponent'
import AddIsoClass from './AddIsoClass'
import AddIsoCode from './AddIsoCode'
import IsoClassList from './IsoClassList'

const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: theme.palette.cardBg
    }
}))

export default function IsoClasses() {
    const classes = useStyles()
    const { GetIsoClasses } = useDb()
    const isoClasses = GetIsoClasses()
    const [open, setOpen] = useState(false)
    const [codeOpen, setCodeOpen] = useState(false)

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} md={8} lg={8}>
                    
                    <IsoClassList isoClasses={isoClasses} />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Button onClick={() => setCodeOpen(true)} color="primary" variant="contained">Add Code</Button><br/><br/>
                            <Button onClick={() => setOpen(true)} color="primary" variant="contained">Add Standard</Button>
                        </CardContent>

                    </Card>
                </Grid>
                <AddIsoClass open={open} closeDialog={() => setOpen(false)} />
                <AddIsoCode open={codeOpen} closeDialog={() => setCodeOpen(false)}/>
            </Grid>

            <FloatingHelpComponent 
                name="What are ISO Classifications?" 
                videoPath="master_list"
                videoIndex={6}
            />
        </>
    )
}
