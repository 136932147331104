import React, { useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { Button, Card, CardActions, CardContent, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { AddBox, ArrowLeftOutlined, AttachFile, Edit, People } from '@mui/icons-material'
import { Pagination } from '@mui/material';
import { useDb } from '../../../../../contexts/DatabaseContext'
import moment from 'moment'
import CoEditAction from './CoEditAction'

export default function CoActions() {
    const { coId } = useParams()
    const { MgmtGetActions } = useDb()
    const actions = MgmtGetActions(coId)

    // Pagination
    const itemsPerPage = 10 // <- Not state but related to below pagination
    const [page, setPage] = useState(1)
    const [noOfPages] = useState(
        Math.ceil(actions.length / itemsPerPage)
    )

    // Edits
    const [editOpen, setEditOpen] = useState(false)
    const [selectedAction, setSelectedAction] = useState(null)

    const handleEdit = (a) => {
        setSelectedAction(a)
        setEditOpen(true)
    }
    console.log(actions)
    return (
        <div>
            <h1 style={{ color: '#fff' }}>Company Actions</h1>
            <Card>
                <CardContent>
                    <Link to={`/companies/${coId}`}>
                        <Button startIcon={<ArrowLeftOutlined />} color="primary" variant="contained">Back</Button>
                    </Link>
                    <br/><br/>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Action Number</TableCell>
                                    <TableCell>Dept.</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>HOD</TableCell>
                                    <TableCell>Imm. Action</TableCell>
                                    <TableCell>ISO Agency</TableCell>
                                    <TableCell>ISO Class</TableCell>
                                    <TableCell>Originator</TableCell>
                                    <TableCell>Priority</TableCell>
                                    <TableCell>Site</TableCell>
                                    <TableCell>Source</TableCell>
                                    <TableCell>Target Date</TableCell>
                                    <TableCell>Created Date</TableCell>
                                    <TableCell>Year</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    actions && actions
                                    .slice((page -1) * itemsPerPage, page * itemsPerPage)
                                    .map(a => {
                                        return (
                                            <TableRow key={a.id}>
                                                <TableCell>{a.actionNumber}</TableCell>
                                                <TableCell>{a.department.name}</TableCell>
                                                <TableCell>{a.description}</TableCell>
                                                <TableCell>{a.hod.firstName} {a.hod.lastName}</TableCell>
                                                <TableCell>{a.immAction}</TableCell>
                                                <TableCell>{a.isoAgency.isoAgency}</TableCell>
                                                <TableCell>{a.isoClass}</TableCell>
                                                <TableCell>{a.originator.firstName} {a.originator.lastName}</TableCell>
                                                <TableCell>{a.priority}</TableCell>
                                                <TableCell>{a.site.label}</TableCell>
                                                <TableCell>{a.source.sourceName}</TableCell>
                                                <TableCell>{moment(a.targetDate.toDate()).format('DD/MM/YYYY')}</TableCell>
                                                <TableCell>{moment(a.timestamp.toDate()).format('DD/MM/YYYY')}</TableCell>
                                                <TableCell>{a.year}</TableCell>
                                                <TableCell>
                                                    <IconButton onClick={() => handleEdit(a)} size="large">
                                                        <Edit />
                                                    </IconButton>
                                                    <Link to={`/companies/${coId}/action-intparties/${a.id}`}>
                                                        <IconButton size="large">
                                                            <People />
                                                        </IconButton>
                                                    </Link>
                                                    <Link to={`/companies/${coId}/action-attachments/${a.id}/`}>
                                                        <IconButton size="large">
                                                            <AttachFile />
                                                        </IconButton>
                                                    </Link>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
                <CardActions>
                    <Pagination
                        count={Math.ceil(actions.length / itemsPerPage)}
                        page={page}
                        onChange={(e, val) => setPage(val)}
                        defaultPage={1}
                        color="primary"
                        size="large"
                        showFirstButton
                        showLastButton
                    /> 
                    <Link to={`/companies/${coId}/create-action`}>
                        <Button variant="contained" color="primary" startIcon={<AddBox />}>Add Action</Button>
                    </Link>
                </CardActions>
            </Card>
            {
                selectedAction
                ?
                <CoEditAction open={editOpen} closeDialog={() => setEditOpen(false)} action={selectedAction} coId={coId} />
                :
                null
            }
        </div>
    );
}
