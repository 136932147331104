import { Button } from '@mui/material'
import React from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import BackLink from '../../shared/BackLink'
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid'


export default function TrainingCompetencyData() {
    const { GetTrainingCompetency, streamLinkTrainingCompetency } = useDb()
    const certs = GetTrainingCompetency()
    console.log(certs)

    const rows = certs && certs.map(c => ({
        ...c,
        streamLinked: c.streamLinked ? "Yes": "No"
    }))

    const columns = [
        { field: "id", headerName: "ID", width: 200 },
        { field: "streamLinked", headerName: "Linked", width: 200 },
    ]

    const handleStream = async () => {
        try {
            await streamLinkTrainingCompetency(certs)
        }
        catch(err) {
            window.alert(err.message)
        }
    }

    return (
        <div>
            <h1>Training Competency Data</h1>
            <BackLink />
            <br/>
            <Button
                color="primary"
                variant="contained"
                onClick={handleStream}
            >
                Stream
            </Button>
            <IsoDataGrid 
                data={rows}
                columns={columns}
            />
        </div>
    )
}
