import React from 'react'
import { useDb } from '../../../../contexts/DatabaseContext'
import IsoDataGrid from '../../../shared/data-grid/IsoDataGrid'

export default function Products() {
    const { GetProducts } = useDb()
    const products = GetProducts()

    const rows = products && products.map(p => ({
        id: p.id,
        name: p.name,
        sku: p.sku
    }))

    const columns = [
        { field: 'name', headerName: 'Product/Service', width: 250 },
        { field: 'sku', headerName: 'SKU', width: 150 },
        // TODO: Add Detail Link
    ]

    return (
        <div>
            <IsoDataGrid
                data={rows}
                columns={columns}
            />
        </div>
    )
}
