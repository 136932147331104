import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material'
import { Save } from '@mui/icons-material'
import React, { useState } from 'react'
import { useAuth } from '../../../contexts/AuthContext'
import { useDb } from '../../../contexts/DatabaseContext'
import { textFieldProps } from '../../shared/constants'
import FileUploader from '../../shared/FileUploader'

export default function AddTemplate({ open, closeDialog }) {
    const { currentUser } = useAuth()
    const { addTemplate } = useDb()
    const [state, setState] = useState({})

    // Action State 
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    
    const handleClose = () => {
        setState({})
        setLoading(false)
        setError(null)
        closeDialog()
    }

    const getUrl = (url) => {
        setState({
            ...state,
            url
        })
    }

    const getFilename = (fileName) => {

    }

    const getFileObj = (file) => {

    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setError(null)
        setLoading(false)
        try {
            await addTemplate(state).then(() => {
                handleClose()
            })
        }
        catch(err){
            setError(err.message)
            setLoading(false)
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        {...textFieldProps}
                        onChange={e => setState({ ...state, name: e.target.value })}
                        required
                        label="Template Name"
                    />
                    <FileUploader userId={currentUser.uid} getUrl={getUrl} getFilename={getFilename} getFileObj={getFileObj} />
                </DialogContent>
                <DialogActions>
                    { error ? <span className="iso-error">{error}</span> : null }
                    <Button disabled={loading} color="secondary" onClick={handleClose}>Cancel</Button>
                    <Button disabled={loading || !state.url} type="submit" color="primary" variant="contained" startIcon={<Save />}>Create Template</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
