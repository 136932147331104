import { Button, ButtonGroup, Card, CardActions, CardContent, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid'
import { AddBox, Edit, Search } from '@mui/icons-material'
import React from 'react'
import { Link } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import { textFieldProps } from '../../shared/constants'
import FloatingHelpComponent from '../../help/FloatingHelpComponent'

export default function Occupations() {
    const { GetOccupations } = useDb()
    const jobs = GetOccupations()

    const rows = jobs.map(j => ({
        id: j.id,
        title: j.title,
        code: j.code ? j.code : 'N/A',
        requiredModules: j.requiredModules ? j.requiredModules.length : '0'
    }))

    const columns = [
        {
            field: 'title',
            headerName: 'Title',
            width: 300
        },
        {
            field: 'code',
            headerName: 'Code',
            width: 200
        },
        {
            field: 'requiredModules',
            headerName: 'Req. Modules',
            width: 200
        },
        {
            field: 'action', 
            headerName: 'Detail',
            width: 200,
            renderCell: (params) => {
                return (
                    <Link to={`/training-manager/occupation/${params.id}`}>
                        <IconButton size="large">
                            <Search />
                        </IconButton>
                    </Link>
                );
            }
        }
    ]
    
    return (
        <div>
            <h1>Occupations</h1>
            <Link to={'/training-manager/occupations/add'} className='iso-right'>
                <Button color="primary" variant="contained" startIcon={<AddBox />}>Add Occupation</Button>
            </Link>
            <br /><br />
            <Card>
                <CardContent>
                    <IsoDataGrid
                        data={rows}
                        columns={columns}
                    />
                </CardContent>
            </Card>

            <FloatingHelpComponent
                name="Setting up Occupations" 
                videoPath="training_manager"
                videoIndex={3}
            />
        </div>
    )
}