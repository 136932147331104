import { Button, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'

export default function AddSite({ closeDialog }) {
    const { addSite } = useDb()
    const [name, setName] = useState('')
    const [error, setError] = useState('')

    const saveSite = async () => {
       const result = await addSite(name)
       if (result !== null) {
           closeDialog()
       }
       else {
           setError('Error saving to database')
       }
    }

    return (
        <>
            <TextField onChange={(e) => setName(e.target.value)} label="Site Name" variant="outlined" fullWidth />
            <Typography style={{ color: 'red' }}>{error}</Typography>
            <br/>
            <div className="iso-right">
                <Button disabled={ name !== '' ? false : true } onClick={saveSite} color="primary">Confirm</Button>
                <Button onClick={closeDialog} color="secondary">Cancel</Button>
            </div>
        </>
    )
}
