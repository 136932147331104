import { Button, Card, CardActions, CardContent, Tooltip, ButtonGroup, IconButton } from '@mui/material'
import { AddBox, CloudDownload, Search, Delete } from '@mui/icons-material'
import React from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import AddProvider from './AddProvider'
import moment from 'moment'
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../../contexts/AuthContext'
import FloatingHelpComponent from '../../help/FloatingHelpComponent'

export default function TrainingProviders() {
    const { GetTrainingProviders } = useDb()
    const providers = GetTrainingProviders()
    const history = useHistory()
    const { currentUser } = useAuth()

    const [addOpen, setAddOpen] = React.useState(false)

    const rows = providers && providers.map(p =>({
        id: p.id,
        ref: p.ref,
        beeCert: p.beeCert ? p.beeCert : 'none',
        beeExpiry: p.beeExpiry ? moment(p.beeExpiry.toDate()).format('DD/MM/YYYY') : 'none',
        employeeNo: p.employeeNumber,
        name: p.name
    }))

    const columns = [
        { field: 'name', headerName: 'Company Name' , width: 300 },
        { 
            field: 'beeCert', 
            headerName: 'BBBEEE Certificate', 
            width: 240,
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                        <Button disabled={params.row.beeCert === 'none'} startIcon={<CloudDownload />} href={params.row.beeCert} target="_blank" variant="contained" color="primary">Download</Button>
                     </div>
                );
            }
        },
        { field: 'beeExpiry', headerName: 'BBBEEE Expiry', width: 200 },
        {
            field: 'Actions',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => {
                return (
                    <ButtonGroup>
                        <Tooltip title='View' arrow>
                            <IconButton
                                onClick={() => history.push(`/training-manager/provider/${params.row.id}/view`)}
                                size="large"><Search /></IconButton>
                        </Tooltip>
                        {
                            currentUser.companyAdmin
                            ?
                            <Tooltip title='Delete' arrow>
                                <IconButton onClick={() => params.row.ref.delete()} size="large"><Delete /></IconButton>
                            </Tooltip>
                            :
                            null
                        }
                    </ButtonGroup>
                );
            }
        }
    ]


    return (
        <div>
            <h1>Training Providers</h1>
            <Card>
                <CardContent>
                    <IsoDataGrid
                        data={rows}
                        columns={columns}
                    />
                </CardContent>
                <CardActions>
                    <Button onClick={() => setAddOpen(true)} color="primary" variant="contained" startIcon={<AddBox />}>
                        Add Provider
                    </Button>
                </CardActions>
            </Card>

            <AddProvider open={addOpen} closeDialog={() => setAddOpen(false)} />

            <FloatingHelpComponent
                name="Managing Training Providers" 
                videoPath="training_manager"
                videoIndex={5}
            />
        </div>
    )
}
