import { Button, Chip, IconButton } from '@mui/material'
import { AddBox, ArrowForward, Check, Search } from '@mui/icons-material'
import React from 'react'
import { Link } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import { useAuth } from '../../../contexts/AuthContext'
import moment from 'moment'
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid'

export default function BaselineRiskAssessments() {
    const { GetBaselines } = useDb()
    const blras = GetBaselines()
    const { currentUser } = useAuth()

    // Dynamically set these urls to use the variables so user can pick up where they left off.
    const stepUrls = {
        1: '/risk-manager/baselines/add-baseline-1',
        2: '/risk-manager/baselines/add-baseline-2',
        3: '/risk-manager/baselines/add-baseline-3',
        4: ''
    }

    const getStep = (ba) => {
        let url = stepUrls[ba.currentStep]
        if(ba.currentStep === 2) {
            url = `${url}/${ba.id}/${ba.site.value}`
        }
        if(ba.currentStep === 3) {
            url = `${url}/${ba.id}`
        }
        return url
    }

    const rows = blras && blras.map(b =>({
        id: b.id,
        blra: b.blra,
        site: b.site,
        area: b.area?.areaName,
        siteLabel: b.site.label,
        created: moment(b.created.toDate()).format('DD/MM/YYYY'),
        status: b.complete ? true : false,
        action: getStep(b),
        currentStep: b.currentStep
    }))

    const columns = [
        { field: 'blra', headerName: 'Assessment' , width: 250 },
        { field: 'created', headerName: 'Created' , width: 180 },
        { field: 'siteLabel', headerName: 'Site' , width: 250 },
        { field: 'area', headerName: 'Area' , width: 250 },
        // { field: 'currentStep', headerName: 'Step' , width: 120 },
        { 
            field: 'status', 
            headerName: 'Status', 
            width: 200,
            renderCell: (params) => {
                return (
                    params.row.currentStep >= 3 ? <Chip color="primary" label="Setup Complete" icon={<Check />}/> : <Chip label="Draft" />
                );
            }
        },
        { 
            field: 'action', 
            headerName: 'Detail', 
            width: 240,
            renderCell: (params) => {
                return params.row.currentStep < 3
                ?
                <Link to={() => getStep(params.row)}>
                    <IconButton size="large">
                        <Search />
                    </IconButton>
                </Link>
                :
                <Link to={`/risk-manager/baseline-detail/${params.row.blra}/${params.row.id}`}>
                    <IconButton size="large">
                        <ArrowForward />
                    </IconButton>
                </Link>;
            }
        },
    ]

    return (
        <div>
            <h1>Baseline Risk Assessments</h1>
            {
                currentUser.accessRights.riskManager > 2 || currentUser.companyAdmin
                &&
                <Link
                to="/risk-manager/baselines/add-baseline-1"
                >
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddBox />}
                    >
                        New Baseline RA
                    </Button>
                </Link>
            }
            <br/><br/>
            <IsoDataGrid
                data={rows}
                columns={columns}
            />
        </div>
    )
}
