import React, { useState } from "react";
import RiskSetup from "./RiskSetup";
import { useDb } from "../../../contexts/DatabaseContext";

export default function EditRiskThresholds() {
  const [loading, setLoading] = useState(false);
  const { updateCompanyDoc } = useDb();

  const handleUpdate = async (mocThresholds) => {
    await updateCompanyDoc(null, null, mocThresholds);
  };

  return (
    <div>
      <h1>Edit Risk Thresholds</h1>
      <RiskSetup loading={loading} handleUpdate={handleUpdate} />
    </div>
  );
}
