import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState, useEffect } from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import AddUserDialog from "./AddUserDialog";
import UserListDataGrid from "./UserListDataGrid";
import { functions } from "../../../firebase";
import { useAuth } from "../../../contexts/AuthContext";
import { People } from "@mui/icons-material";
import FloatingHelpComponent from "../../help/FloatingHelpComponent";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.cardBg,
  },
}));

export default function UsersList() {
  const classes = useStyles();
  const { currentUser } = useAuth();
  const { GetUserList, getCompanyMaxUsers } = useDb();
  const users = GetUserList();
  const [userCount, setUserCount] = useState();
  const [maxCount, setMaxCount] = useState();
  const [loading, setLoading] = useState(true);

  // Dialogs
  // Add New User Dialog
  const [addOpen, setAddOpen] = useState(false);

  // Deleting a User

  const getCurrentUserCount = async () => {
    console.log("Getting User Count");
    if (currentUser.billing_type === "manual" && currentUser.stripe_exempt) {
      const maxUsers = await getCompanyMaxUsers();
      setMaxCount(maxUsers);
    } else {
      const functionRef = functions.httpsCallable("getUserCount");

      const count = await functionRef({ companyId: currentUser.companyId });
      setUserCount(count.data);
      console.log(currentUser);
      if (!currentUser.trialing) {
        const maxUsers = await getCompanyMaxUsers();
        setMaxCount(maxUsers);
      } else setMaxCount(99);
    }

    setLoading(false);
  };

  useEffect(() => {
    const count = getCurrentUserCount();
    return count;
  }, [users]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={7} lg={7}>
          <Card className={classes.card}>
            <CardContent>
              <UserListDataGrid users={users} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={5} lg={5}>
          <Card className={classes.card}>
            <CardContent>
              {currentUser.companyAdmin && !loading && (
                <Card>
                  <CardHeader
                    title={`Users on Plan: ${
                      users && users.length
                    } / ${maxCount}`}
                    avatar={
                      <Avatar>
                        <People />
                      </Avatar>
                    }
                  />
                </Card>
              )}
              <br />
              {currentUser.companyAdmin && (
                <Button
                  disabled={loading || users.length >= maxCount}
                  onClick={() => setAddOpen(true)}
                  variant="contained"
                  color="primary"
                  startIcon={<FontAwesomeIcon icon={faUserPlus} />}
                >
                  Add User
                </Button>
              )}
              {/* {currentUser.companyAdmin && (
                <Button
                  disabled={loading}
                  onClick={() => setAddOpen(true)}
                  variant="contained"
                  color="primary"
                  startIcon={<FontAwesomeIcon icon={faUserPlus} />}
                >
                  Add User
                </Button>
              )} */}
            </CardContent>
          </Card>

          <AddUserDialog open={addOpen} closeDialog={() => setAddOpen(false)} />
        </Grid>
      </Grid>

      <FloatingHelpComponent
        name="How to add Users"
        videoPath="master_list"
        videoIndex={2}
        title="How to Add Users"
      />
    </div>
  );
}
