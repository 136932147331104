import { Button, Card, CardActions, CardContent, CircularProgress, Grid, Slider, TextField, Typography } from '@mui/material'
import { KeyboardArrowRight } from '@mui/icons-material'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import { textFieldProps } from '../../shared/constants'

export default function R7HeirarchyOfControl() {

  let sum
  const history = useHistory()
  const { addRiskHierarchy, updateSetupComplete } = useDb()
  const [sub, setSub] = useState(null)
  const [iso, setIso] = useState(null)
  const [eng, setEng] = useState(null)
  const [adminC, setAdminC] = useState(null)
  const [ppe, setPPE] = useState(null)
  const [state,setState] = useState({
    loading: false,
    error: null
  })

  const sumColor = () => {
    if((sub + iso + eng + adminC + ppe) === 100) {
      return 'Lightgreen'
    } else {
      return 'Red'
    }
  }

  const handleSubmit = async () => {
    setState({
      ...state,
      loading: true,
      error: null
    })
    try {
      await addRiskHierarchy({
        elimination: 100,
        substitution: sub,
        isolation: iso,
        engineering: eng,
        administrativeControls: adminC,
        ppe: ppe
      })
      updateSetupComplete(true)
      setState({
        ...state,
        loading: false,
        error: null
      })
      history.push('/risk-manager/dashboard')
      window.location.reload()
    } catch (err) {
      setState({
        ...state,
        error: err.message,
        loading: false
      })
    }
  }

  return (
    <>
      {
        state.loading
        ?
        <CircularProgress />
        :
        <div>
          <Typography variant={'h5'}>Risk Manager: Initial Setup</Typography>
          <br />
          <Typography variant={'h5'}>Step 7: Hierarchy of  Control:</Typography>
          <br />
          <Grid item xs={12} lg={6} >
            <Card>
              <CardContent>
                <Typography>Elimination</Typography>
                <br />
                <Slider
                  value={100}
                  min={99}
                  max={101}
                  disabled
                  step={1}
                  valueLabelDisplay="on"
                />
                <Typography>Substitution: {sub}</Typography>
                <br />
                <Slider
                  defaultValue={1}
                  min={1}
                  max={99}
                  onChange={(e, val) => {
                    setSub(val)
                  }}
                  step={1}
                  valueLabelDisplay="auto"
                />
                <Typography>Isolation: {iso}</Typography>
                <br />
                <Slider
                  defaultValue={1}
                  min={1}
                  max={sub -1}
                  onChange={(e, val) => {
                    setIso(val)
                  }}
                  step={1}
                  valueLabelDisplay="auto"
                />
                <Typography>Engineering: {eng}</Typography>
                <br />
                <Slider
                  defaultValue={1}
                  min={1}
                  max={iso -1}
                  onChange={(e, val) => {
                    setEng(val)
                  }}
                  step={1}
                  valueLabelDisplay="auto"
                />
                <Typography>Administrative Controls: {adminC}</Typography>
                <br />
                <Slider
                  defaultValue={1}
                  min={1}
                  max={eng -1}
                  onChange={(e, val) => {
                    setAdminC(val)
                  }}
                  step={1}
                  valueLabelDisplay="auto"
                />
                <Typography>PPE: {ppe}</Typography>
                <br />
                <Slider
                  defaultValue={1}
                  min={1}
                  max={adminC -1}
                  onChange={(e, val) => {
                    setPPE(val)
                  }}
                  step={1}
                  valueLabelDisplay="auto"
                />
                <br />
                <Typography>
                  The sum of the fields must be 100:
                </Typography>
                <br />
                <Typography
                  style={{color: sumColor()}}
                >
                  {
                    sum = sub + iso + eng + adminC + ppe
                  }
                </Typography>
              </CardContent>
              <CardActions className="iso-right" >
                <Button
                  endIcon={<KeyboardArrowRight />}
                  color={'primary'} 
                  variant={'contained'}
                  disabled={state.loading || !sub || !iso || !eng || !adminC || !ppe || sum !== 100} 
                  onClick={handleSubmit}
                >
                  Complete Setup
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </div>
      }
    </>
  )
}
