import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from "react";
import { useDb } from "../../../../contexts/DatabaseContext";
import { textFieldProps } from "../../../shared/constants";

const useStyles = makeStyles((theme) => ({
  header: {
    background: theme.palette.primary.main,
  },
}));

export default function AddText({ open, close, checklistId, sectionId }) {
  const classes = useStyles();
  const { addChecklistField, updateChecklistSectionScore } = useDb();
  const [state, setState] = useState({});
  const [scoring, setScoring] = useState(false);
  const [actionState, setActionState] = useState({
    loading: false,
    error: null,
  });

  const handleClose = () => {
    setActionState({ loading: false, error: null });
    setState({});
    close();
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleSwitch = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.checked,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setActionState({ loading: true, error: null });
    try {
      if (state.score) {
        await updateChecklistSectionScore(checklistId, sectionId, state.score, 'text');
      }
      await addChecklistField(checklistId, sectionId, "text", state);
      handleClose();
    } catch (err) {
      setActionState({ loading: false, error: err.message });
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle className={classes.header}>Add Text Field</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            required
            {...textFieldProps}
            onChange={handleChange}
            name="name"
            label="Field Name"
          />
          <FormControlLabel
            control={
              <Switch
                checked={state.required}
                onChange={handleSwitch}
                name="required"
                color="primary"
              />
            }
            label="Required"
          />
          <FormControlLabel
            control={
              <Switch
                checked={state.scoringField}
                onChange={handleSwitch}
                name="scoringField"
                color="primary"
              />
            }
            label="Scoring Field"
          />
          {state.scoringField && (
            <TextField
              type="number"
              onChange={handleChange}
              name="score"
              {...textFieldProps}
              label="Score"
            />
          )}
          {actionState.error ? (
            <span className="wireline-error">{actionState.error}</span>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={actionState.loading}
            color="secondary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            disabled={actionState.loading}
            type="submit"
            color="primary"
            variant="contained"
          >
            Add to Section
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
