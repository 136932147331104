import { Button, ButtonGroup, Chip, IconButton } from "@mui/material";
import { AddBox, Search, Check, Edit } from "@mui/icons-material";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useDb } from "../../../contexts/DatabaseContext";
import moment from "moment";
import IsoDataGrid from "../../shared/data-grid/IsoDataGrid";
import { useAuth } from "../../../contexts/AuthContext";

export default function IssueBasedRAs() {
  const { GetIBAssessments, GetBaselines } = useDb();
  const ibras = GetIBAssessments();
  const history = useHistory();
  const { currentUser } = useAuth();
  const baselines = GetBaselines();

  const fromBlraNav = (step, ibra) => {
    switch (step) {
      case 1:
        history.push(`/risk-manager/issue-based/${ibra.id}/${ibra.blra}/1`);
        break;
      case 2:
        history.push(`/risk-manager/issue-based/${ibra.id}/2`);
        break;
      default:
        history.push(
          `/risk-manager/issue-based-detail/${ibra.id}/${ibra.ibra}`
        );
    }
  };

  const fromMocNav = (step, ibra) => {
    // TODO: Handle MOC based ibra navigation
  };

  const rows =
    ibras &&
    ibras.map((r) => {
      const baseline = baselines.find((b) => b.id === r.blra);
      return {
        id: r.id,
        initiateFrom: r.initiateFrom === "baseline" ? "Baseline" : "MOC",
        timestamp: moment(r.timestamp.toDate()).format("DD/MM/YYYY"),
        ibra: r.ibra,
        tools: r.tools,
        status: r.complete ? true : false,
        currentStep: r.currentStep,
        blra: r.blra ? r.blra : null,
        hazard: r.hazard ? r.hazard : null,
        currentStep: r.currentStep,
        complete: r.complete ? r.complete : false,
        area: baseline ? baseline.area?.areaName : null,
        ibraArea: r.ibraArea
          ? r.ibraArea
          : baseline
          ? baseline.area?.areaName
          : null,
        tag: r.tag || "",
        process: r.process?.name || "",
      };
    });

  const columns = [
    {
      field: "action",
      headerName: "Action",
      width: 120,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <ButtonGroup>
              <IconButton
                onClick={() => {
                  params.row.blra
                    ? fromBlraNav(params.row.currentStep, params.row)
                    : fromMocNav(params.row.currentStep, params.row);
                }}
                size="large">
                <Search />
              </IconButton>
              <IconButton
                onClick={() =>
                  history.push(`/risk-manager/edit-ibra/${params.row.id}`)
                }
                size="large">
                <Edit />
              </IconButton>
            </ButtonGroup>
          </div>
        );
      },
    },
    { field: "ibra", headerName: "IBRA No.", width: 200 },
    { field: "timestamp", headerName: "Date Created", width: 120 },
    { field: "initiateFrom", headerName: "Source", width: 120 },
    { field: "area", headerName: "BLRA Area", width: 120 },
    { field: "ibraArea", headerName: "IBRA Area", width: 120 },
    { field: "tag", headerName: "Tag / Desc.", width: 220 },
    { field: "process", headerName: "Init. Process", width: 220 },
  ];

  return (
    <div>
      <h1>Issue Based Risk Assessments</h1>
      {currentUser.accessRights.riskManager > 2 ||
        (currentUser.companyAdmin && (
          <Link to="/risk-manager/issue-based/new">
            <Button variant="contained" color="primary" startIcon={<AddBox />}>
              New Issue Based RA
            </Button>
          </Link>
        ))}
      <div>
        <IsoDataGrid data={rows} columns={columns} />
      </div>
    </div>
  );
}
