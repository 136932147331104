import { Button, Card, CardActions, CardContent, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { Add, Delete, Edit } from '@mui/icons-material';
import { Pagination } from '@mui/material';
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext';
import AddCriteria from './AddCriteria';
import EditCriteria from './EditCriteria';

export default function CriteriaList({ criteria }) {
    const { deleteCriteria } = useDb()

    const [addOpen, setAddOpen] = useState(false)
    const [error, setError] = useState(null)

    // Pagination
    const itemsPerPage = 10; // <- Not state but related to below pagination
    const [page, setPage] = useState(1);
    const [noOfPages] = useState(
        Math.ceil(criteria.length / itemsPerPage)
    )

    // Editing
    const [editOpen, setEditOpen] = useState(false)
    const [selectedCriteria, setSelectedCriteria] = useState(null)
    const handleEdit = (c) => {
        setSelectedCriteria(c)
        setEditOpen(true)
    }
    const handleDelete = async (c) => {
        try {
            await deleteCriteria(c.id)
        }
        catch(err) {
            setError(err.message)
        }
    }
    return <>
        <Grid container spacing={3}>
            <Grid item xs={12} md={8} lg={8}>
                <Card>
                    <CardContent>
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Criteria</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        criteria && criteria
                                        .slice((page -1) * itemsPerPage, page * itemsPerPage)
                                        .map(c => {
                                            return (
                                                <TableRow key={c.id}>
                                                    <TableCell>{c.name}</TableCell>
                                                    <TableCell align="right">
                                                        <IconButton onClick={() => handleEdit(c)} size="large">
                                                            <Edit />
                                                        </IconButton>
                                                        <IconButton onClick={() => handleDelete(c)} size="large">
                                                            <Delete />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                    <CardActions>
                        <Pagination
                            count={Math.ceil(criteria.length / itemsPerPage)}
                            page={page}
                            onChange={(e, val) => setPage(val)}
                            defaultPage={1}
                            color="primary"
                            size="large"
                            showFirstButton
                            showLastButton
                        /> 
                        { error ? <span className="iso-error">{error}</span> : null }
                        
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
                <Card>
                    <CardContent>
                        <Button onClick={() => setAddOpen(true)} color="primary" variant="contained" startIcon={<Add />}>Add Criteria</Button>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        <AddCriteria open={addOpen} closeDialog={() => setAddOpen(false)}/>
        {
            selectedCriteria
            ?
            <EditCriteria open={editOpen} closeDialog={() => setEditOpen(false)} criteria={selectedCriteria} />
            :
            null
        }
    </>;
}
