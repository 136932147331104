import { FormControlLabel, Grid, Switch } from '@mui/material'
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import Select from 'react-select'
import { selectFieldProps } from '../../shared/constants'

export default function ActionRegisterFilters({ updateFilters, openState }) {
    const { GetDepartments, GetSites, GetIsoAgencies, GetIsoClasses, GetSources } = useDb()
    const [open, setOpen] = useState(openState)
    const sites = GetSites()
    const departments = GetDepartments()
    const agencies = GetIsoAgencies()
    const isoClasses = GetIsoClasses()
    const sources = GetSources()

    // When calling this function, be sure to use a ternary operator in the last argument for a possible null value. See examples in Selects below.
    const handleSelect = (v, key, fieldName, value) => {
        if(!v) {
            updateFilters({
                key,
                data: {
                    fieldName,
                    value: null
                }
            })
        }
        else {
            updateFilters({
                key,
                data: {
                    fieldName,
                    value
                }
            })
        }
    }

    const toggleOpen = (e) => {
        setOpen(!open)
        updateFilters({
            key: 'open',
            data: {
                fieldName: 'open',
                value: e.target.checked
            }
        })
    }

    return (
        <div>
            <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
                <Select
                    className="iso-select"
                    {...selectFieldProps}
                    options={departments}
                    onChange={(v) => handleSelect(v, 'department', 'department.name', v  ? v.value.name : null)}
                    placeholder="Department ..."
                    isClearable
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <Select
                    className="iso-select"
                    {...selectFieldProps}
                    options={sites}
                    onChange={(v) => handleSelect(v, 'site', 'site.value', v  ? v.value : null)}
                    placeholder="Site ..."
                    isClearable
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <Select
                    className="iso-select"
                    {...selectFieldProps}
                    options={agencies}
                    onChange={(v) => handleSelect(v, 'isoAgency', 'isoAgency.isoAgency', v  ? v.isoAgency : null)}
                    placeholder="ISO Agency ..."
                    isClearable
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <Select
                    className="iso-select"
                    {...selectFieldProps}
                    options={isoClasses}
                    onChange={(v) => handleSelect(v, 'isoClass', 'isoClass', v  ? v.value : null)}
                    placeholder="ISO Classification ..."
                    isClearable
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <Select
                    className="iso-select"
                    {...selectFieldProps}
                    options={sources}
                    onChange={(v) => handleSelect(v, 'source', 'source.sourceName', v  ? v.sourceName : null)}
                    placeholder="Source ..."
                    isClearable
                />
            </Grid>
        </Grid>
        <FormControlLabel
            control={
            <Switch
                checked={open}
                onChange={toggleOpen}
                name="open"
                color="primary"
            />
            }
            label="Action Open"
        />
        </div>
    )
}
