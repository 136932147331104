import React from "react";
import { useAuth } from "../../contexts/AuthContext";

export default function RiskManagerDashboard() {
  const { currentUser } = useAuth();
  var params = {
    "ds0.company": currentUser.companyId,
    "ds2.company": currentUser.companyId,
    "ds70.companyid": currentUser.companyId,
  };
  var paramsAsString = JSON.stringify(params);
  var encodedParams = encodeURIComponent(paramsAsString);

  return (
    <div>
      <h1>Risk Manager</h1>

      <iframe
        width="90%"
        height="1800"
        src={`https://lookerstudio.google.com/embed/reporting/0e3ec55e-95d0-4048-b2e0-73e8d5dc248d/page/p_ev3pi5ug4c?params=${encodedParams}`}
        frameBorder="0"
      ></iframe>
    </div>
  );
}
