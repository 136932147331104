import React from "react";
import ParticipantsList from "./ParticipantsList";
import { useDb } from "../../contexts/DatabaseContext";

export default function PeopleListContainer({ moc, collectioName }) {
  const { GetCollectionPath } = useDb();
  const participants = GetCollectionPath(`${moc.path}/${collectioName}`);
  return (
    <div>
      <ParticipantsList parties={participants} roleName={collectioName} />
    </div>
  );
}
