import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useDb } from '../../../contexts/DatabaseContext';
import AuditInspections from './AuditInspections';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function AllInspections() {
    const { GetInspections, GetMissedInspections, GetCompleteInspections } = useDb()
    const upcoming = GetInspections()
    const missed = GetMissedInspections()
    const complete = GetCompleteInspections()
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <h1>Audits/Inspections</h1>
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                <Tab label="Upcoming" {...a11yProps(0)} />
                <Tab label="Missed" {...a11yProps(1)} />
                <Tab label="Completed" {...a11yProps(2)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                <AuditInspections
                    inspections={upcoming}
                />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <AuditInspections
                    inspections={missed}
                />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <AuditInspections
                    inspections={complete}
                />
            </TabPanel>
        </div>
    )
}
