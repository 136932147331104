import React, { useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { Button, Card, CardActions, CardContent, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { AddBox, ArrowLeftOutlined } from '@mui/icons-material'
import { Pagination } from '@mui/material';
import { useDb } from '../../../../contexts/DatabaseContext'
import CoAddRoster from './CoAddRoster'

export default function CoRosters() {
    const { coId } = useParams()
    const { MgmtGetRosters } = useDb()
    const rosters = MgmtGetRosters(coId)

    const [addOpen, setAddOpen] = useState(false)

    // Pagination
    const itemsPerPage = 10 // <- Not state but related to below pagination
    const [page, setPage] = useState(1)
    const [noOfPages] = useState(
        Math.ceil(rosters.length / itemsPerPage)
    )

    return (
        <div>
            <h1 style={{ color: '#fff' }}>Company Rosters</h1>
            <Card>
                <CardContent>
                    <Link to={`/companies/${coId}`}>
                        <Button startIcon={<ArrowLeftOutlined />} color="primary" variant="contained">Back</Button>
                    </Link>
                    <br/><br/>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>First Name</TableCell>
                                    <TableCell>Last Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>User Status</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    rosters && rosters
                                    .slice((page -1) * itemsPerPage, page * itemsPerPage)
                                    .map(r => {
                                        return (
                                            <TableRow key={r.id}>
                                                <TableCell>{r.firstName}</TableCell>
                                                <TableCell>{r.lastName}</TableCell>
                                                <TableCell>{r.email}</TableCell>
                                                <TableCell>{r.userStatus ? 'Active' : 'Roster Only'}</TableCell>
                                                <TableCell>{'TODO'}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
                <CardActions>
                    <Pagination
                        count={Math.ceil(rosters.length / itemsPerPage)}
                        page={page}
                        onChange={(e, val) => setPage(val)}
                        defaultPage={1}
                        color="primary"
                        size="large"
                        showFirstButton
                        showLastButton
                    /> 
                    <Button onClick={() => setAddOpen(true)} variant="contained" color="primary" startIcon={<AddBox />}>Add Roster</Button>
                </CardActions>
            </Card>
            
            <CoAddRoster open={addOpen} closeDialog={() => setAddOpen(false)} coId={coId} />
        </div>
    )
}
