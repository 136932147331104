import { ButtonGroup, IconButton } from '@mui/material'
import { Search } from '@mui/icons-material'
import React from 'react'
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid'

export default function GroupsDataGrid({data, handleOpen}) {

  const rows = data.map(d => ({
    id: d.id,
    name: d.name,
    group: d
  }))

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 300
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => {
        return <IconButton onClick={() => handleOpen(params.row.group)} size="large"><Search /></IconButton>;
      }
    }
  ]

  return (
    <div>
      <IsoDataGrid
        data={rows}
        columns={columns}
      />
    </div>
  )
}
