import { Typography, TextField, CssBaseline, Grid, IconButton, InputAdornment, Button, Paper, Box } from '@mui/material';
import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import happyman from '../../img/happyman.jpg'
import isoLogo from '../../img/gold_logo.png'
import makeStyles from '@mui/styles/makeStyles';
import { useAuth } from '../../contexts/AuthContext' 

// Icons
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

function Copyright() {
    return (
      <div>
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <a color="inherit" href="https://iso-office.co.za">
          ISO Office
        </a>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
      </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
    height: '100vh',
    },
    image: {
        backgroundImage: `url(${happyman})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor:
        theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
  }));


export default function SignIn({ setForgot }) {
    const classes = useStyles();

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, togglePassword] = useState(false)
    const { login, currentUser } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    const handleSubmit = async (e) => {
        e.preventDefault()
        setError('')
        setLoading(true)
        try {
            await login(email, password)
            history.push('/')
        } catch(err) {
            console.log(err)
            setError(err.message)
            setLoading(false)
        }
        // setLoading(false)
    }

    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <a href="https://iso-office.co.za" target="_blank" rel="noopener noreferred">
              <img src={isoLogo} width="100" alt="ISO Logo"/>
            </a>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form onSubmit={handleSubmit} className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment:<InputAdornment onClick={() => togglePassword(!showPassword)} position="end"><IconButton size="large">
                      {
                        showPassword
                        ?
                        <Visibility />
                        :
                        <VisibilityOff />
                      }
                    </IconButton></InputAdornment>
                }}
              />
              <span style={{color: 'red', textAlign: 'center', width: '100%'}}>{error}</span><br/>

              <Button
                type="submit"
                disabled={loading ? true : false}
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleSubmit}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  {/* <Link to='/forgot-password' variant="body2">
                    Forgot password?
                  </Link> */}
                  <Button onClick={setForgot}>Forgot Password?</Button>
                </Grid>
                <Grid item>
                  <Link to='/signup' variant="body2">
                    <Button>Create New Account</Button>
                  </Link>
                </Grid>
              </Grid>
              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>
    );
}
