import React from 'react'
import IsoDataGrid from '../../../shared/data-grid/IsoDataGrid';

export default function BlraParticipantList({ parties }) {

    const rows = parties && parties.map(p => ({
        ...p,
        id: p.user.value.id,
        name: p.user.label,

    }));

    const columns = [
        { field: "name", headerName: "Participant", width: 400 }
    ];

    return (
        <div>
            <IsoDataGrid
                data={rows}
                columns={columns}
            />
        </div>
    )
}
