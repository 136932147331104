import React from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import { useDb } from '../../../../../contexts/DatabaseContext'
import moment from 'moment'
import IsoDataGrid from '../../../../shared/data-grid/IsoDataGrid'
import { Button, IconButton } from '@mui/material'
import { AddBox, ArrowBack, ArrowForward } from '@mui/icons-material'

export default function IBRiskHazards() {
    const { ibraId } = useParams()
    const history = useHistory()
    const { GetHazardsForIB, getIbra } = useDb()
    const hazards = GetHazardsForIB(ibraId)

    const handleBack = async () => {
        const ibra = await getIbra(ibraId)
        const refNo = ibra.ibra
        history.push(`/risk-manager/issue-based-detail/${ibraId}/${refNo}`)
    }

    const rows = hazards && hazards.map(h => ({
        id: h.id,
        timestamp: moment(h.timestamp.toDate()).format('DD/MM/YYYY'),
        name: h.name,
        process: h.process?.label
    }))

    const columns = [
        { field: 'timestamp', headerName: 'Created', width: 140 },
        { field: 'name', headerName: 'Hazard', width: 400 },
        { field: 'process', headerName: 'Process', width: 400 },
        {
            field: 'action', 
            headerName: 'Go to Hazard',
            width: 200,
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                        <Link to={`/risk-manager/issue-based/${ibraId}/hazards/${params.row.id}/risks`}>
                            <IconButton size="large">
                                <ArrowForward /> 
                            </IconButton>
                        </Link>
                     </div>
                );
            }
        }
    ]

    return (
        <div>
            <h1>Hazards</h1>
            <div className="back-link">
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<ArrowBack />}
                    onClick={handleBack}
                >
                    Back
                </Button>
                &nbsp;
                <Link to={`/risk-manager/issue-based/${ibraId}/2`}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddBox />}
                    >
                        Add Hazard
                    </Button>
                </Link>
            </div>
            <IsoDataGrid
                data={rows}
                columns={columns}
            />
        </div>
    )
}
