import { Avatar, Button, Card, CardActions, CardContent, CardHeader, LinearProgress, Typography } from '@mui/material'
import { NotInterested, School, ThumbUpSharp } from '@mui/icons-material'
import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useAuth } from '../../../contexts/AuthContext'
import { useDb } from '../../../contexts/DatabaseContext'
import moment from 'moment'
import DeclineSesionInvite from './DeclineSesionInvite'

export default function ConfirmSessionAttendance() {
    const { id, userId } = useParams()
    const { currentUser } = useAuth()
    const { getTrainingSession, attendeeInviteResponse } = useDb()
    const [session, setSession] = useState({})
    const [open, setOpen] = useState(false)
    const history = useHistory()
    const [compState, setCompState] = useState({ loading: false, error: null, pageLoaded: false })

    const handleResponse = async (resBool, reason) => {
        await attendeeInviteResponse(id, resBool, reason || " ")
        history.push('/')
    }

    const firstLoad = async () => {
        const s = await getTrainingSession(id)
        setSession(s)
        setCompState({ ...compState, pageLoaded: true })
    }

    const handleClose = () => {
        setOpen(false)
    }

    console.log(session)
    if(!compState.pageLoaded) {
        firstLoad()
        return <LinearProgress />
    }
    else return (
        <div>
            <h3>Confirm Training Attendance: {session?.module?.name}</h3>
            { currentUser.uid === userId && session
                ?
                <Card>
                    <CardHeader
                        title={session.module.name}
                        avatar={<Avatar>
                            <School />
                        </Avatar>}
                    />
                    <CardContent>
                        <Typography>
                            Session Begins: {moment(session.startTime.toDate()).format('MMMM Do YYYY, h:mm:ss a')}
                        </Typography>
                        <Typography>
                            Session Ends: {moment(session.endTime.toDate()).format('MMMM Do YYYY, h:mm:ss a')}
                        </Typography>
                        <Typography>
                            Site: {session.siteLabel}
                        </Typography>
                        <Typography>
                            Venue: {session.venue.name}
                        </Typography>
                        <Typography>
                            Facilitator: {session.facilitator.label}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button onClick={() => setOpen(true)} startIcon={<NotInterested />} color="secondary" variant="contained">Decline</Button>
                        <Button onClick={() => handleResponse(true)} startIcon={<ThumbUpSharp />} color="primary" variant="contained">Confirm</Button>
                    </CardActions>
                </Card>
                :
                <Card>
                    <CardContent>
                        This invitation has been sent to a different user account. Are you logged into the correct account? 
                    </CardContent>
                </Card>
            }
            <DeclineSesionInvite open={open} close={handleClose} handleRes={handleResponse}/>
        </div>
    )
}
