import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Typography,
  Grid,
  Button,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import { AssignmentTurnedIn, Close, Save } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { textFieldProps, selectFieldProps } from "../../shared/constants";
import FileUploader from "../../shared/FileUploader";
import Select from "react-select";
import { useDb } from "../../../contexts/DatabaseContext";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function EditModule({
  open,
  close,
  trainingModule,
  currentUser,
}) {
  const {
    GetDepartments,
    GetUsersLevelFilter,
    GetTrainingRisks,
    GetTrainingProviders,
    updateTrainingModule,
    GetOccupations,
  } = useDb();
  const depts = GetDepartments();
  const hods = GetUsersLevelFilter("trainingManager", 1);
  const risks = GetTrainingRisks();

  const [state, setState] = useState({
    loading: false,
    error: null,
    data: null,
  });

  useEffect(() => {
    setState({
      ...state,
      data: {
        ...trainingModule,
        targetRevDate: !(trainingModule.targetRevDate instanceof Date)
          ? trainingModule.targetRevDate.toDate()
          : trainingModule.targetRevDate,
      },
    });
  }, [open]);

  const handleClose = () => {
    close();
  };

  const handleChange = (e) => {
    setState({
      ...state,
      data: {
        ...state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleCheck = (e) => {
    setState({
      ...state,
      data: {
        ...state.data,
        [e.target.name]: e.target.checked,
      },
    });
  };

  const handleSelect = (v, e) => {
    setState({
      ...state,
      data: {
        ...state.data,
        [e.name]: v.value,
      },
    });
  };

  const getUrl = (url) => {
    setState({
      ...state,
      data: {
        ...state.data,
        ptoUrl: url,
      },
    });
  };

  const getPracticalUrl = (url) => {
    setState({
      ...state,
      data: {
        ...state.data,
        practicalUrl: url,
      },
    });
  };

  const getFilename = (n) => {};

  const getFileObj = (o) => {};

  const handleSubmit = async () => {
    setState({
      ...state,
      loading: true,
      error: null,
    });
    try {
      await updateTrainingModule(trainingModule.id, state.data);
      handleClose();
      window.location.reload();
    } catch (err) {
      setState({
        ...state,
        loading: false,
        error: err.message,
      });
    }
  };

  console.log(state.data);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullScreen>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Edit Module
          </Typography>
          <Button
            color="inherit"
            // onClick={handleClose}
            variant="outlined"
            startIcon={<Save />}
            disabled={state.loading}
            onClick={handleSubmit}
          >
            Save Changes
          </Button>
        </Toolbar>
        {/* <Toolbar>
          <Typography>Edit Module</Typography>
          <Button
            color="secondary"
            onClick={handleClose}
            disabled={state.loading}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            // onClick={handleClose}
            variant="contained"
            startIcon={<Save />}
            disabled={state.loading}
            onClick={handleSubmit}
          >
            Save Changes
          </Button>
        </Toolbar> */}
      </AppBar>
      <DialogActions></DialogActions>
      {state.data && (
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                value={state.data.name}
                name="name"
                {...textFieldProps}
                label="Module Name"
                onChange={handleChange}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.data.practicalRequired}
                    onChange={handleCheck}
                    name="practicalRequired"
                    color="primary"
                  />
                }
                label="Practical Assessment Required"
              />
              <br />
              {state.data.practicalRequired ? (
                <Card>
                  <CardHeader
                    title="Practical Assessment"
                    avatar={
                      <Avatar>
                        <AssignmentTurnedIn />
                      </Avatar>
                    }
                  />
                  <CardContent>
                    <Typography>
                      Replace Practical Assessment Template
                    </Typography>
                    <FileUploader
                      getFileObj={getFileObj}
                      getFilename={getFilename}
                      getUrl={getPracticalUrl}
                      userId={currentUser.uid}
                    />
                  </CardContent>
                </Card>
              ) : null}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.data.competencyRequired}
                    onChange={handleCheck}
                    name="competencyRequired"
                    color="primary"
                  />
                }
                label="Include on Competency Certificate"
              />

              <Select
                options={depts}
                placeholder={`Department: ${state.data.department.name}`}
                onChange={handleSelect}
                {...selectFieldProps}
                className="iso-select"
                name="department"
              />
              <Select
                options={hods}
                placeholder={`Owner: ${state.data.owner.firstName} ${state.data.owner.lastName}`}
                onChange={handleSelect}
                {...selectFieldProps}
                className="iso-select"
                name="owner"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Target Revision Date"
                  onChange={(d) =>
                    setState({
                      ...state,
                      data: { ...state.data, targetRevDate: d.toDate() },
                    })
                  }
                  defaultValue={dayjs(state.data.targetRevDate)}
                />
              </LocalizationProvider>

              <br />
              <br />
              <TextField
                value={state.data.frequency}
                name="frequency"
                {...textFieldProps}
                label="Frequency of Occurences (Days)"
                onChange={handleChange}
              />
              <br />
              <Select
                options={risks}
                placeholder={`Risk: ${state.data.risk.name}`}
                onChange={handleSelect}
                {...selectFieldProps}
                className="iso-select"
                name="risk"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.data.evaluationRequested}
                    onChange={handleCheck}
                    name="evaluationRequested"
                    color="primary"
                  />
                }
                label="Evaluation Requested"
              />
              <br />
              <br />
              {state.data.evaluationRequested ||
              (state.data.risk && state.data.risk.name === "High") ? (
                <>
                  <Typography>Replace PTO Template:</Typography>
                  <FileUploader
                    getFileObj={getFileObj}
                    getFilename={getFilename}
                    getUrl={getUrl}
                    userId={currentUser.uid}
                  />
                  <input
                    autoComplete="off"
                    style={{ opacity: 0, height: 0, marginTop: -20, width: 0 }}
                    value={state.ptoUrl}
                    required
                  />
                  <br />
                </>
              ) : null}
            </Grid>
          </Grid>
          {state.error && (
            <Typography color="secondary">{state.error}</Typography>
          )}
        </DialogContent>
      )}
    </Dialog>
  );
}
