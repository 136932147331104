import { Avatar, Card, CardContent, CardHeader } from '@mui/material'
import { Check, Info } from '@mui/icons-material'
import React from 'react'
import { useDb } from '../../../../contexts/DatabaseContext'

export default function ExistingProcesses({ blraId }) {
    const { GetBaselineProcesses } = useDb()
    const procs = GetBaselineProcesses(blraId)
    
    return (
        <div>
            <Card>
                <CardHeader
                    title="Existing Processes on this Assessment"
                    avatar={
                        <Avatar>
                            <Info />
                        </Avatar>
                    }
                />
                <CardContent>
                    {
                        procs && procs.map(p => (
                            <Card key={p.id}>
                                <CardHeader
                                    avatar={
                                        <Avatar>
                                            <Check />
                                        </Avatar>
                                    }
                                    title={p.name}
                                />
                            </Card>
                        ))
                    }
                </CardContent>
            </Card>
        </div>
    )
}
