import { Button, LinearProgress } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDb } from '../../../../contexts/DatabaseContext'
import IsoDataGrid from '../../../shared/data-grid/IsoDataGrid'


export default function () {
    const { reportId } = useParams()
    const [report, setReport] = useState()
    const { retrieveAttendaceReport } = useDb()
    const history = useHistory()

    useEffect(async () => {
        const r = await retrieveAttendaceReport(reportId)
        setReport(r)
        return
    }, [])

    const columns = [
        { field: 'attended', headerName: 'Attended', width: 200 },
        { field: 'name', headerName: 'Name', width: 200 },
        
    ]

    const rows = report && report.attendees && report.attendees.map(a  => ({
        id: a.id,
        attended: a.attended ? 'Yes' : 'No',
        name: a.name
    }))

    return (
        
        <div>
            <h1>Attendance Report</h1>
            <div className="back-link">
                <Button onClick={() => history.goBack()} color="primary" variant="contained" startIcon={<ArrowBack />}>Back</Button>
            </div>
            {
                report 
                ?
                <div>
                    <IsoDataGrid
                        data={rows}
                        columns={columns}
                    />
                </div>
                :
                <LinearProgress />
            }
        </div>
    )
}
