import { Button, Card, CardActions, CardContent } from "@mui/material";
import { Info } from "@mui/icons-material";
import { Avatar, CardHeader, Typography } from "@mui/material";
import React from "react";
import MocSummary from "../MocSummary";
import SatisfactionSelector from "./SatisfactionSelector";

export default function MocEvaluation({ moc, props }) {
  const { title, setMoc, handleAdvance, handleUpdate } = props;
  return (
    <div>
      <Card>
        <CardHeader
          title={"Evaluation"}
          avatar={
            <Avatar>
              <Info />
            </Avatar>
          }
        />
        <CardContent>
          <MocSummary
            moc={moc}
            props={props}
            readOnly={true}
            approvalView={true}
          />
          <br />
          <SatisfactionSelector handleUpdate={handleUpdate} />
          <br />
        </CardContent>
        <CardActions>
          <Button variant="contained" color="primary" onClick={handleAdvance}>
            Schedule Follow Up
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}
