import React, { useEffect, useState } from "react";
import ApprovalQuestions from "../approvals/ApprovalQuestions";
import { Button, Snackbar } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import { useAuth } from "../../../../contexts/AuthContext";
import { Alert } from '@mui/material';

export default function FollowUpChecklist({ moc, props }) {
  const { handleAdvance, handleUpdate } = props;
  const { currentUser } = useAuth();
  const [allowProceed, setAllowProceed] = useState(false);
  const [message, setMessage] = useState(
    "You are not authorized to evaluate this MOC."
  );
  const [snackOpen, setSnackOpen] = useState(false);

  const handleRadioClick = async (answers) => {
    await handleUpdate({
      followUpQuestions: answers,
    });
    setSnackOpen(true);
  };

  const handleComplete = async () => {
    await handleUpdate({
      phase: 7,
      currentStepIndex: 0,
    });
  };

  const loadMoc = () => {
    if (
      moc.evaluators[0]?.participant === currentUser.uid ||
      currentUser.companyAdmin
    ) {
      if (moc.followUpDueDate && moc.followUpDueDate.toDate() < new Date()) {
        setAllowProceed(true);
      } else {
        setAllowProceed(false);
        setMessage("This MOC is not yet due for final evaluation.");
      }
    } else {
      setAllowProceed(false);
    }
  };

  useEffect(() => {
    return loadMoc();
  }, []);

  return !allowProceed ? (
    <div>
      <Alert severity="info">{message}</Alert>
    </div>
  ) : (
    <div style={{ marginBottom: 150 }}>
      <h1>Final Evaluation</h1>
      <Button
        variant="contained"
        color="primary"
        startIcon={<CheckCircle />}
        onClick={handleComplete}
      >
        Complete Evaluation
      </Button>
      <br />
      <br />
      <ApprovalQuestions handleUpdate={handleRadioClick} />

      <Snackbar
        open={snackOpen}
        message="Saved!"
        autoHideDuration={4000}
        onClose={() => setSnackOpen(false)}
      />
    </div>
  );
}
