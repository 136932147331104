import {
  Avatar,
  Button,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import moment from "moment";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { textFieldProps } from "../../shared/constants";
import { Save } from "@mui/icons-material";
import { useDb } from "../../../contexts/DatabaseContext";

export default function EditRevision({ rev, doc, open, closeDialog }) {
  const { updateRevision } = useDb();

  const [state, setState] = useState({
    targetRevDate: rev.targetRevDate.toDate(),
    description: rev.description,
  });

  // Action State
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleClose = () => {
    setLoading(false);
    setError(null);
    closeDialog();
  };

  const handleSubmit = async () => {
    console.log(state);
    setError(null);
    setLoading(true);

    try {
      await updateRevision(state, doc.id, rev.id).then(() => {
        handleClose();
      });
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogContent>
        <CardHeader
          avatar={
            <Avatar>
              <FontAwesomeIcon icon={faCalendar} />
            </Avatar>
          }
          title={
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Target Revision Date"
                onChange={(d) =>
                  setState({ ...state, targetRevDate: d.toDate() })
                }
                defaultValue={dayjs(state.targetRevDate)}
              />
            </LocalizationProvider>
          }
        />
        <TextField
          {...textFieldProps}
          value={state.description}
          label="Description"
          onChange={(e) => setState({ ...state, description: e.target.value })}
        />
      </DialogContent>
      <DialogActions>
        {error ? <span className="iso-error">{error}</span> : null}
        <Button disabled={loading} color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={loading}
          startIcon={<Save />}
          color="primary"
          variant="contained"
        >
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
