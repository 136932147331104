import { Avatar, Button, Card, CardContent, CardHeader, Grid, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, CardActions, CircularProgress, TextField, Typography, LinearProgress } from '@mui/material'
import { ArrowBack, AssignmentTurnedIn, Warning, ArrowForward, PictureAsPdf, AddBox, Edit } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import { useDb } from '../../../../contexts/DatabaseContext'
import { selectFieldProps } from '../../../shared/constants'
import BlraProcesses from '../new-assessment/BlraProcesses'
import BlraParticipants from './BlraParticipants'
import Select from 'react-select'
import BlraEditPopup from './BlraEditPopup'
import BackLink from '../../../shared/BackLink'


export default function BlraDetail() {
    const { blraId, blra } = useParams()
    const history = useHistory()
    const { GetAllBaselineProcessSteps, GetBlraHazards, GetBlraRisks, getBlraPdf, getBaseline } = useDb()
    const [baseline, setBaseline] = useState()

    const activities = GetAllBaselineProcessSteps(blraId)
    const hazards = GetBlraHazards(blraId)
    const risks = GetBlraRisks(blraId)

    const [loading, setLoading] = useState(false)
    const [editOpen, setEditOpen] = useState(false);

    const getPdf = async () => {
        setLoading(true)
        await getBlraPdf(blraId, hazards, activities, risks)
        setLoading(false)
    }   

    const getBlra = async () => {
        const b = await getBaseline(blraId);
        setBaseline(b)
    }

    useEffect(() => {
      const unsubscribe = getBlra();
      return unsubscribe;
    }, [blraId, editOpen])
    
    const handleEdit = () => {
        setEditOpen(true);
    }

    return (
        <div>
            <h1>{baseline && baseline.blra}</h1>
            <div className="back-link">
                <CardActions>
                    <BackLink
                        route={"/risk-manager/baselines"}
                    />
                    <Button
                        
                        // variant="contained"
                        startIcon={<PictureAsPdf />}
                        onClick={getPdf}
                        disabled={loading}
                    >
                        Download Report
                    </Button>
                    {
                        loading && <CircularProgress />
                    }
                </CardActions>
            </div>
            {
                baseline 
                ?
                <div>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardContent>
                                    <Typography>
                                        Area: {baseline.area?.areaName}
                                        <IconButton onClick={handleEdit} size="large">
                                            <Edit/>
                                        </IconButton>
                                    </Typography>
                                    <Typography>
                                        Site: {baseline.site?.label}
                                        <IconButton onClick={handleEdit} size="large">
                                            <Edit/>
                                        </IconButton>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
                :
                <LinearProgress />
            }
            <br/>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <BlraProcesses 
                        blraId={blraId}
                        blra={blra}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <BlraParticipants
                        blraId={blraId}
                    />
                </Grid>
            </Grid>

            <BlraEditPopup
                open={editOpen}
                close={() => setEditOpen(false)}
                blraId={blraId}
                blra={baseline}
            />
        </div>
    );
}
