import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Typography,
} from "@mui/material";
import { ArrowLeft, Save } from "@mui/icons-material";
import React, { useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDb } from "../../../contexts/DatabaseContext";
import Select from "react-select";
import { selectFieldProps } from "../../shared/constants";
import FileUploader from "../../shared/FileUploader";
import { useAuth } from "../../../contexts/AuthContext";

export default function NewEvaluation() {
  const { currentUser } = useAuth();
  const { empId } = useParams();
  const { GetEmployeeCompetencyEvaluationsOnly, newEmployeeEvaluation } =
    useDb();
  const mods = GetEmployeeCompetencyEvaluationsOnly(empId);
  const history = useHistory();

  const [state, setState] = useState({
    evaluationDate: new Date(),
  });
  const [compState, setCompState] = useState({
    loading: false,
    error: null,
  });

  const handleSelect = (v, e) => {
    setState({
      ...state,
      [e.name]: v.value,
    });
  };

  const getFilename = (n) => {};

  const getFileObj = (o) => {};

  const getUrl = (url) => {
    setState({
      ...state,
      proofUrl: url,
    });
  };

  const handleCheck = (e) => {
    setState({
      ...state,
      reasons: {
        ...state.reasons,
        [e.target.name]: e.target.checked,
      },
    });
  };

  const handleSubmit = async () => {
    setCompState({ error: null, loading: true });
    try {
      await newEmployeeEvaluation({ ...state, empId });
      history.push("/training-manager/evaluations");
    } catch (err) {
      setCompState({ error: err.message, loading: false });
    }
  };

  return (
    <div>
      <h1>New Evaluation</h1>
      <div className="back-link">
        <Link to={"/training-manager/evaluations"}>
          <Button startIcon={<ArrowLeft />} variant="contained" color="primary">
            Evaluations
          </Button>
        </Link>
      </div>
      <Card>
        <CardContent>
          <Select
            onChange={handleSelect}
            name="module"
            placeholder="Select Training Module ..."
            {...selectFieldProps}
            className="iso-select"
            options={mods}
          />
          {state.module ? (
            <div>
              <FormControl>
                <FormLabel>Reasons for Conducting the observation</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    label="Newly Appointed Employee"
                    control={
                      <Checkbox
                        name="newEmployee"
                        value="Newly Appointed Employee"
                        onChange={handleCheck}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Transferred Employee"
                    control={
                      <Checkbox name="transferred" onChange={handleCheck} />
                    }
                  />
                  <FormControlLabel
                    label="Follow up Evaluation"
                    control={
                      <Checkbox name="followUp" onChange={handleCheck} />
                    }
                  />
                  <FormControlLabel
                    label="Routine Evaluation"
                    control={<Checkbox name="routine" onChange={handleCheck} />}
                  />
                  <FormControlLabel
                    label="Incident Follow-up"
                    control={
                      <Checkbox
                        name="incidentFollowUp"
                        onChange={handleCheck}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Training Follow-up"
                    control={
                      <Checkbox
                        name="trainingFollowUp"
                        onChange={handleCheck}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Performance Evaluation"
                    control={
                      <Checkbox name="performance" onChange={handleCheck} />
                    }
                  />
                  <FormControlLabel
                    label="Hazardous Task/Job"
                    control={
                      <Checkbox name="hazardous" onChange={handleCheck} />
                    }
                  />
                  <FormControlLabel
                    label="Incapacitated Worker"
                    control={
                      <Checkbox name="incapacitated" onChange={handleCheck} />
                    }
                  />
                  <FormControlLabel
                    label="Experience Check"
                    control={
                      <Checkbox name="experienceCheck" onChange={handleCheck} />
                    }
                  />
                  <FormControlLabel
                    label="Other"
                    control={<Checkbox name="other" onChange={handleCheck} />}
                  />
                </FormGroup>
              </FormControl>
              <br />
              <br />
              <Typography>Upload Proof</Typography>
              <FileUploader
                getFileObj={getFileObj}
                getFilename={getFilename}
                getUrl={getUrl}
                userId={currentUser.uid}
              />
            </div>
          ) : null}
          {compState.error ? (
            <span className="iso-error">{compState.error}</span>
          ) : null}
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            startIcon={<Save />}
            onClick={handleSubmit}
            disabled={
              !state.reasons ||
              !state.evaluationDate ||
              !state.module ||
              !state.proofUrl ||
              compState.loading
            }
          >
            Submit
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}
