import { Avatar, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Info } from '@mui/icons-material'
import React from 'react'

const useStyles = makeStyles((theme) => ({
    container: {
        background: 'lightgrey',
        color: '#333',
        borderRadius: 10,
        marginTop: 10,
        marginBottom: 20,
        paddingRight: 20
    },
    icon: {
        marginLeft: 20
    }
}))

export default function InfoBlurb({ message }) {
    const classes = useStyles()
    return (
        <div className={classes.container}>
            <Grid container spacing={3}>
                <Grid item xs={2}>
                    <div className={classes.icon}>
                        <Avatar>
                            <Info/>
                        </Avatar>
                    </div>
                </Grid>
                <Grid item xs={10}>
                    <Typography>{message}</Typography>
                </Grid>
            </Grid>
        </div>
    )
}
