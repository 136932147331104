import React, { useState } from 'react'
import { CircularProgress, Divider, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DocsDueDates from '../docmanager/doc-dashboard-components/DocsDueDates'
import DocsOverdueRev from '../docmanager/doc-dashboard-components/DocsOverdueRev'
import ActionsPer from '../actionmanager/dashboard/ActionsPer'
import { useDb } from '../../contexts/DatabaseContext'
import ActionsOverdueDepartment from '../actionmanager/dashboard/ActionsOverdueDepartment'
import OverduePrograms from '../otpmanager/dashboard/OverduePrograms'
import TargetsPer from '../otpmanager/dashboard/TargetsPer'
import { useAuth } from '../../contexts/AuthContext'
import DocLevelsChart from '../docmanager/doc-dashboard-components/DocLevelsChart'
import DocTotalsChart from '../docmanager/doc-dashboard-components/DocTotalsChart'
import ActionsPerYear from '../actionmanager/dashboard/ActionsPerYear'
import ProgramDonut from '../otpmanager/dashboard/ProgramDonut'
import TrainingAdminDashboard from '../trainingmanager/admin-dashboard/TrainingAdminDashboard'
import RiskManagerDashboard from '../riskmanager/RiskManagerDashboard'
import AuditManager from '../audit-manager/AuditManager'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: theme.palette.cardBg
    }
}))

export default function Dashboard() {
    const classes = useStyles()
    const history = useHistory()
    const { currentUser } = useAuth()
    const [userFavDocs, setUserFavDocs] = useState([])
    const [loadComplete, setLoadComplete] = useState(false)
    const [userGroups, setUserGroups] = useState(null)
    const { GetActionsAsc, getUserFavoriteDocs, getUserGroups } = useDb()
    const actions = GetActionsAsc()

    const firstLoad = async () => {
        const favDocs = await getUserFavoriteDocs(currentUser.uid)
        const myGroups = await getUserGroups(currentUser.uid)
        setUserFavDocs(favDocs)
        setUserGroups(myGroups)
        setLoadComplete(true)
    }
    
    if(!loadComplete) {
        if(currentUser.facilitator) {
            history.push('/training-manager/facilitator-dashboard')
        }
        firstLoad()
        return (
          <CircularProgress /> 
        )
    }
    else return (
        <div>
            {
                currentUser.modules.docManager 
                ?
                <div>
                    <Typography>Documents:</Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={6}>
                            {/* <DocLevelsChart /> */}
                            <DocTotalsChart />
                            
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <DocsOverdueRev userGroups={userGroups}/>
                            <br/>
                            <DocsDueDates userGroups={userGroups}/>
                        </Grid>
                    </Grid>
                    <br/>
                    <Divider />
                    <br/>
                </div>
                :
                null
            }
            {
                currentUser.modules.actionManager 
                ?
                <div>
                    <Typography>Actions:</Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={6}>
                            <ActionsPerYear actions={actions}/>
                            <br/>
                            <ActionsOverdueDepartment />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <ActionsPer actions={actions}/>
                        </Grid>
                    </Grid>
                    <br/>
                    <Divider />
                    <br/>
                </div>
                :
                null
            }
            {
                currentUser.modules.otpManager 
                ?
                <div>
                    <Typography>OTP Programs:</Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={6}>
                            <ProgramDonut />
                            <br/>
                            <OverduePrograms />
                            
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <TargetsPer />
                        </Grid>
                    </Grid>
                </div>
                :
                null
            }
            {
                currentUser.modules.trainingManager 
                ?
                <div>
                    <br/>
                    <Divider />
                    <br/>
                    <Typography>Training Manager:</Typography>
                    <TrainingAdminDashboard />
                </div>
                :
                null
            }
            {
                currentUser.modules.riskManager 
                ?
                <div>
                    <br/>
                    <Divider />
                    <br/>
                    <RiskManagerDashboard />
                </div>
                :
                null
            }
            {
                currentUser.modules.auditManager 
                ?
                <div>
                    <br/>
                    <Divider />
                    <br/>
                    <AuditManager />
                </div>
                :
                null
            }
        </div>
    )
}
