import { Button, Card, CardContent, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react'
import FloatingHelpComponent from '../../help/FloatingHelpComponent'
import CreateSourceForm from './CreateSourceForm'
import SourceList from './SourceList'

const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: theme.palette.cardBg
    }
}))

export default function Sources() {
    const classes = useStyles()
    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} md={8}>  
                    <SourceList />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Button onClick={() => setOpen(true)} color="primary" variant="contained">Create Source</Button>
                        </CardContent>
                    </Card>
                    <CreateSourceForm open={open} closeDialog={handleClose} />
                </Grid>
            </Grid>

            <FloatingHelpComponent
                name="Action Sources" 
                videoPath="action_manager"
                videoIndex={5}
            />
        </div>
    )
}
