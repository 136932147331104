import React, { useState } from 'react'
import ForgotPassword from './ForgotPassword'
import SignIn from './SignIn'

export default function Authenticate() {

    const [forgot, setForgot] = useState(false)
    
    function toggleForgot() {
        setForgot(!forgot);
    }

    return (
        forgot ? <ForgotPassword setForgot={toggleForgot} /> : <SignIn setForgot={toggleForgot} />
    )
}
