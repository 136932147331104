import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Slider,
  TextField,
} from "@mui/material";
import { ArrowForward, Info } from "@mui/icons-material";
import React from "react";
import { textFieldProps } from "../../shared/constants";
import { Alert } from "@mui/material";

export default function MocTimeline({ moc, props, approvalView }) {
  const { setMoc, handleAdvance, loading, title, handleUpdate } = props;

  const marks = [
    {
      value: 14,
      label: "Two Weeks",
    },
    {
      value: 30,
      label: "One Month",
    },
    {
      value: 90,
      label: "Three Months",
    },
    {
      value: 180,
      label: "Six Months",
    },
    {
      value: 210,
      label: "More than Six Months",
    },
  ];

  const handleSubmit = () => {
    handleUpdate();
    handleAdvance();
  };

  return (
    <div>
      <Card>
        <CardHeader
          title={title}
          avatar={
            <Avatar>
              <Info />
            </Avatar>
          }
          subheader={
            <Alert severity="info">
              Move the slider to the expected timeframe interval below.
            </Alert>
          }
        />
        <CardContent>
          <Container>
            <Slider
              defaultValue={14}
              max={220}
              step={10}
              valueLabelDisplay="auto"
              marks={marks}
              value={moc.timeframe}
              onChange={(e, val) => setMoc({ ...moc, timeframe: val })}
            />
            <TextField
              {...textFieldProps}
              label="Detail and Justification of Expected Timeframe"
              multiline
              rows={5}
              name="timeframeDetail"
              value={moc.timeframeDetail || ""}
              onChange={(e) =>
                setMoc({ ...moc, [e.target.name]: e.target.value })
              }
            />
          </Container>
        </CardContent>
        {!approvalView && (
          <CardActions style={{ float: "right" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              endIcon={<ArrowForward />}
              disabled={loading}
            >
              Next
            </Button>
          </CardActions>
        )}
      </Card>
    </div>
  );
}
