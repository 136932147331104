import React, { useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import MocChecklist from "../moc-checklist/MocChecklist";
import RiskSetup from "../risk-levels/RiskSetup";
import { CardActions, Container } from "@mui/material";
import RiskQuestions from "../risk-questions/RiskQuestions";
import ExpectedBenefits from "../benefits/ExpectedBenefits";
import { Save } from "@mui/icons-material";
import { useDb } from "../../../contexts/DatabaseContext";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    "MOC Checklist",
    "Risk Setup",
    "Risk Questionnaire",
    "Expected Benefits",
  ];
}

export default function SetupWizard({ setupStep }) {
  const classes = useStyles();
  const history = useHistory();
  const { updateCompanyDoc } = useDb();
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(setupStep);
  const [skipped, setSkipped] = useState(new Set());
  const steps = getSteps();

  const isStepOptional = (step) => {
    return step === 99;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleUpdate = async (data) => {
    setLoading(true);
    try {
      await updateCompanyDoc(null, null, { ...data });
    } catch (err) {
      window.alert(err.message);
    }
    setLoading(false);
  };

  const handleComplete = async () => {
    setLoading(true);
    try {
      await updateCompanyDoc(null, null, { mocSetupComplete: true });
      history.push("/moc-register");
    } catch (err) {
      window.alert(err.message);
    }
    setLoading(false);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <MocChecklist />;
      case 1:
        return <RiskSetup handleUpdate={handleUpdate} loading={loading} />;
      case 2:
        return <RiskQuestions />;
      default:
        return <ExpectedBenefits />;
    }
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <CardActions>
              <Button onClick={handleReset} className={classes.button}>
                Reset
              </Button>
              <Button
                onClick={handleComplete}
                variant="contained"
                color="primary"
                startIcon={<Save />}
                disabled={loading}
              >
                Confirm Settings
              </Button>
            </CardActions>
          </div>
        ) : (
          <div>
            <Container>{getStepContent(activeStep)}</Container>
            <div style={{ marginTop: 20 }}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.button}
              >
                Back
              </Button>
              {isStepOptional(activeStep) && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSkip}
                  className={classes.button}
                >
                  Skip
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
