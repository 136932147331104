import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import Select from 'react-select'
import React, { useState } from 'react'
import { ArrowForward } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'
import { useDb } from '../../../../../contexts/DatabaseContext'
import { selectFieldProps, textFieldProps } from '../../../../shared/constants'

export default function AddIBRisk({ open, close, ibraId, hazardId }) {
    const { GetRiskTypes, GetRiskPatterns, GetRiskEntities, GetUsersLevelFilter, addIBRisk } = useDb()
    const types = GetRiskTypes()
    const entities = GetRiskEntities()
    const patterns = GetRiskPatterns()
    const hods = GetUsersLevelFilter('riskManager', 2)
    const history = useHistory()
    const [state, setState] = useState({
        loading: false,
        error: null,
        data: {
            routine: "routine"
        }
    })

    const handleClose = () => {
        close()
    }

    const handleRadio = (e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                routine: e.target.value
            }
        })
    }

    const handleChange = (e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleSelect = (v, e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.name]: v.value.id
            }
        })
    }

    const handleSubmit = async () => {
        setState({
            ...state,
            loading: true,
            error: null
        })
        try {
            const riskId = await addIBRisk(ibraId, {...state.data, hazard: hazardId, currentStep: 1 })
            // history.push(`/risk-manager/issue-based/${ibraId}/ppesigns/${riskId}`)
            history.push(`/risk-manager/inherent-risk/${ibraId}/risk/${riskId}/2/ibra`)
        }
        catch(err) {
            setState({
                ...state,
                loading: false,
                error: err.message
            })
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>Add New Risk</DialogTitle>
            <DialogContent>
                <TextField
                    {...textFieldProps}
                    label="Description of the Risk"
                    name="description"
                    onChange={handleChange}
                />
                <Select
                    {...selectFieldProps}
                    placeholder="Risk Type"
                    className="iso-select"
                    onChange={handleSelect}
                    options={types}
                    name="type"
                />
                <Select
                    {...selectFieldProps}
                    placeholder="Exposed Entity"
                    className="iso-select"
                    onChange={handleSelect}
                    options={entities}
                    name="entity"
                />
                <Select
                    {...selectFieldProps}
                    placeholder="Exposure Patterns"
                    className="iso-select"
                    onChange={handleSelect}
                    options={patterns}
                    name="pattern"
                />
                <TextField
                    {...textFieldProps}
                    label="Causes of the Risk"
                    name="cause"
                    onChange={handleChange}
                />
                
                <Select
                    {...selectFieldProps}
                    placeholder="Risk Owner"
                    className="iso-select"
                    onChange={handleSelect}
                    options={hods}
                    name="hod"
                />
                <RadioGroup name="routine" value={state.data.routine} onChange={handleRadio}>
                    <FormControlLabel value="routine" control={<Radio />} label="Routine Task" />
                    <FormControlLabel value="non-routine" control={<Radio />} label="Non-Routine Task" />
                </RadioGroup>
                { state.error && <Typography color="secondary">{state.error}</Typography> }
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    endIcon={<ArrowForward />}
                    color="primary"
                    disabled={
                        state.loading ||
                        !state.data.cause ||
                        !state.data.entity ||
                        !state.data.hod || 
                        !state.data.pattern ||
                        !state.data.type
                    }
                    onClick={handleSubmit}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
}
