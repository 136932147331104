import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ArrowBack, ArrowForward, Edit, Info } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import { useAuth } from '../../../contexts/AuthContext'
import { useDb } from '../../../contexts/DatabaseContext'
import EditRiskPopup from './edit-risk/EditRiskPopup'

const useStyles = makeStyles((theme) => ({
    card: {
        margin: '10px 0'
    }
}))

export default function BlraRiskDetail() {
    const classes = useStyles()
    const { blraId, riskId } = useParams()
    const history = useHistory()
    const { getCompleteRisk } = useDb()
    const { currentUser } = useAuth()
    const [risk, setRisk] = useState()
    const [editOpen, setEditOpen] = useState(false)
    const [type, setType] = useState()

    const retrieveRisk = async () => {
        const r = await getCompleteRisk(blraId, riskId)
        setRisk(r)
    }

    const handleEdit = (t) => {
        setType(t)
        setEditOpen(true)
    }

    useEffect(() => {
      const unsubscribe = retrieveRisk();
      return unsubscribe;
    }, [blraId, riskId, editOpen])
    

    if(!risk) {
        return <CircularProgress />
    }
    else return (
        <div>
            <h1>Risk {risk.ref}</h1>   
            <div className="back-link">
                <Button
                    startIcon={<ArrowBack />}
                    color="primary"
                    variant="contained"
                    onClick={() => history.goBack()}
                > 
                    Back
                </Button>

                {
                    type && <EditRiskPopup
                        type={type}
                        open={editOpen}
                        close={() => setEditOpen(false)}
                        assessmentId={blraId}
                        riskId={riskId}
                        assessmentType="blra"
                    />
                }

            </div>
            <Grid container spacing={3}>

                <Grid item xs={12} md={6}>
                    <Card>
                        <CardHeader
                            title="Risk Details"
                            avatar={<Avatar><Info /></Avatar>}
                        />
                        <CardContent>
                            <Typography>Risk Description: {risk.description} <IconButton onClick={() => handleEdit("description")} size='small'><Edit /></IconButton></Typography>
                            <Typography>Risk Cause: {risk.cause}<IconButton onClick={() => handleEdit("cause")} size='small'><Edit /></IconButton></Typography>
                            <br/>
                            <Typography>Risk Owner: {risk.hod.firstName} {risk.hod.lastName}<IconButton onClick={() => handleEdit("hod")} size='small'><Edit /></IconButton></Typography>
                            <Typography>Risk Type: {risk.type.riskType}<IconButton onClick={() => handleEdit("type")} size='small'><Edit /></IconButton></Typography>
                            <Typography>Task Type: {risk.routine === 'routine' ? 'Routine' : 'Non-Routine'}<IconButton onClick={() => handleEdit("routine")} size='small'><Edit /></IconButton></Typography>
                            <Typography>Entity Exposed: {risk.entity.riskEntity}<IconButton onClick={() => handleEdit("entity")} size='small'><Edit /></IconButton></Typography>
                            <Typography>Pattern: {risk.pattern.frequency}<IconButton onClick={() => handleEdit("pattern")} size='small'><Edit /></IconButton></Typography>
                            
                            <Card className={classes.card}>
                                <CardContent>
                                    <Typography><b>Inherent Risk:</b></Typography>
                                    <Typography>Likelihood: {risk.initialLike}</Typography>
                                    <Typography>Consequences: {risk.initialCons}</Typography>
                                    <Typography>Rating: {risk.initialCons * risk.initialLike}</Typography>
                                </CardContent>
                                <CardActions>
                                    {
                                        currentUser.accessRights.riskManager > 2 || currentUser.companyAdmin
                                        &&
                                        <Link to={`/risk-manager/inherent-risk/${blraId}/risk/${risk.id}/2/blra`}>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                endIcon={<ArrowForward />}
                                            >
                                                Inherent Risk
                                            </Button>
                                        </Link>
                                    }
                                </CardActions>
                            </Card>

                            <Card className={classes.card}>
                                <CardContent>
                                    <Typography><b>Residual Risk:</b></Typography>
                                    <Typography>Likelihood: {risk.finalLike}</Typography>
                                    <Typography>Consequences: {risk.finalCons}</Typography>
                                    <Typography>Rating: {risk.finalCons * risk.finalLike}</Typography>
                                </CardContent>
                                <CardActions>
                                    {
                                        currentUser.accessRights.riskManager > 2 || currentUser.companyAdmin
                                        &&
                                        <Link to={`/risk-manager/residual-risk/${blraId}/risk/${risk.id}/4/blra`}>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                endIcon={<ArrowForward />}
                                                disabled={!risk.initialLike || !risk.initialCons }
                                            >
                                                Residual Risk
                                            </Button>
                                        </Link>
                                    }
                                </CardActions>
                            </Card>
                            
                            
                            
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardHeader
                            title="Control Measures"
                            avatar={<Avatar><Info /></Avatar>}
                        />
                        <CardContent>
                            {
                                risk.controlsArray && risk.controlsArray.map((c,i) => (
                                    <Card key={i}>
                                        <CardHeader
                                            title={c.label}
                                            avatar={<Info />}
                                            subheader={c.description}
                                        />
                                        <CardActions>
                                            {
                                                c.process 
                                                ?
                                                <Button 
                                                    size="small"
                                                    variant="contained"
                                                    color="primary"
                                                    href={c.process}
                                                    target="_blank"
                                                >
                                                    Procedure
                                                </Button>
                                                :
                                                null
                                            }
                                            {
                                                c.training
                                                ?
                                                <Button 
                                                    size="small"
                                                    variant="contained"
                                                    color="primary"
                                                    href={c.training}
                                                    target="_blank"
                                                >
                                                    Training
                                                </Button>
                                                :
                                                null
                                            }
                                            {
                                                c.pto
                                                ?
                                                <Button 
                                                    size="small"
                                                    variant="contained"
                                                    color="primary"
                                                    href={c.pto}
                                                    target="_blank"
                                                >
                                                    PTO
                                                </Button>
                                                :
                                                null
                                            }
                                            {
                                                c.compliance
                                                ?
                                                <Button 
                                                    size="small"
                                                    variant="contained"
                                                    color="primary"
                                                    href={c.compliance}
                                                    target="_blank"
                                                >
                                                    Legal
                                                </Button>
                                                :
                                                null
                                            }
                                        </CardActions>
                                    </Card>
                                ))
                            }
                        </CardContent>
                    </Card>
                </Grid>

            </Grid>
            
        </div>
    )
}
