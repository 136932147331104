import { faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import { textFieldProps } from '../../shared/constants'

export default function AddUserDialog({ open, closeDialog }) {
    const { requestUserCreation } = useDb()

    // Form  State
    const [state, setState] = useState({})

    // Action State
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const handleClose = () => { 
        setState({})
        setLoading(false)
        setError(null)
        closeDialog()
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        setError(null)
        try {
            await requestUserCreation(state).then(() => {
                handleClose()
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }

    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        {...textFieldProps}
                        label="First Name"
                        required
                        onChange={e => setState({...state, firstName: e.target.value })}
                    />
                    <TextField
                        {...textFieldProps}
                        label="Last Name"
                        required
                        onChange={e => setState({...state, lastName: e.target.value })}
                    />
                    <TextField
                        type="email"
                        {...textFieldProps}
                        label="Email"
                        required
                        onChange={e => setState({...state, email: e.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    { error ? <span className="iso-error">{error}</span> : null }
                    <Button disabled={loading} color="secondary" onClick={handleClose}>Cancel</Button>
                    <Button disabled={loading} color="primary" variant="contained" type="submit" startIcon={<FontAwesomeIcon icon={faUserPlus}/>}>Add User</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
