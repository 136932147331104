import {
  faArrowAltCircleRight,
  faBuilding,
  faDollarSign,
  faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  CardActions,
  Button,
  ButtonGroup,
  Chip,
  Tooltip,
} from "@mui/material";
import { Add, Block, ThumbUp } from "@mui/icons-material";
import { Pagination } from "@mui/material";
import React, { useState } from "react";
import AddCompany from "./AddCompany";
import { Link } from "react-router-dom";
import IsoDataGrid from "../../shared/data-grid/IsoDataGrid";
import moment from "moment";
import { useDb } from "../../../contexts/DatabaseContext";
import { functions } from "../../../firebase";

export default function CompanyList({ companies }) {
  const [addOpen, setAddOpen] = useState(false);
  const { blockCompany, unblockCompany } = useDb();

  const handleBlock = async (co) => {
    const confirmBlock = window.confirm(
      `Are you sure you want to block ${co.companyName}?`
    );
    if (confirmBlock) {
      await blockCompany(co.id);
    }
  };

  const handleUnblock = async (co) => {
    const confirmUnblock = window.confirm(
      `Are you sure you want to Unblock ${co.companyName}?`
    );
    if (confirmUnblock) {
      await unblockCompany(co.id);
    }
  };

  const handleManualUpgrade = async (coId) => {
    const functionRef = functions.httpsCallable("convertTrialToPaidManually");
    await functionRef({ companyId: coId });
  };

  const rows =
    companies &&
    companies.map((c) => ({
      ...c,
      id: c.id,
      billing_type: c.billing_type,
      trial_start: c.trial_start
        ? moment(c.trial_start.toDate()).format("MM/DD/YYYY")
        : "",
      trialing: c.trialing,
      trial_expired: c.trial_expired,
      status: c.good_standing ? "OK" : "Blocked",
    }));

  const columns = [
    { field: "companyName", headerName: "Name", width: 200 },
    { field: "billing_type", headerName: "Billing Type?", width: 100 },
    {
      field: "maxUsers",
      headerName: "Max Users",
      width: 100,
      renderCell: (params) => {
        return params.row.billing_type !== "stripe"
          ? params.row.maxUsers || 99
          : "stripe";
      },
    },
    { field: "trial_start", headerName: "Trial Start Date", width: 200 },

    {
      field: "status",
      headerName: "Billing Status",
      width: 200,
      renderCell: (params) => {
        return (
          <Chip
            label={params.row.status}
            color={params.row.status === "OK" ? "primary" : "secondary"}
          />
        );
      },
    },
    {
      field: "trial_expired",
      headerName: "Trial State",
      width: 200,
      renderCell: (params) => {
        return (
          <Chip
            label={!params.row.trial_expired ? "Trialing" : "Trial Expired"}
            color={!params.row.trial_expired ? "primary" : "default"}
          />
        );
      },
    },
    {
      field: "trialing",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => {
        return (
          <ButtonGroup>
            <IconButton size="large">
              <Link to={`/companies/${params.row.id}`}>
                <FontAwesomeIcon icon={faArrowAltCircleRight} />
              </Link>
            </IconButton>
            {params.row.trialing && params.row.billing_type === "manual" && (
              <Tooltip title="Convert to Paying">
                <IconButton
                  onClick={() => handleManualUpgrade(params.row.id)}
                  size="large"
                >
                  <FontAwesomeIcon icon={faDollarSign} />
                </IconButton>
              </Tooltip>
            )}
            <IconButton size="large">
              {params.row.status === "OK" ? (
                <Block
                  color="secondary"
                  onClick={() => handleBlock(params.row)}
                />
              ) : (
                <ThumbUp
                  color="primary"
                  onClick={() => handleUnblock(params.row)}
                />
              )}
            </IconButton>
          </ButtonGroup>
        );
      },
    },
  ];

  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar>
            <FontAwesomeIcon icon={faBuilding} />
          </Avatar>
        }
        title={"Companies"}
      />
      <CardContent>
        <IsoDataGrid data={rows} columns={columns} />
      </CardContent>
      <CardActions>
        <Button
          onClick={() => setAddOpen(true)}
          color={"primary"}
          variant={"contained"}
          startIcon={<Add />}
        >
          Add Company
        </Button>
      </CardActions>

      <AddCompany open={addOpen} closeDialog={() => setAddOpen(false)} />
    </Card>
  );
}
