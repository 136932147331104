import { Button } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import React from "react";
import { useHistory } from "react-router-dom";

export default function BackLink({ route }) {
  const history = useHistory();
  return (
    <Button
      variant="contained"
      startIcon={<ArrowBack />}
      color="primary"
      onClick={route ? () => history.push(route) : () => history.goBack()}
      style={{
        marginBottom: 10,
      }}
    >
      Back
    </Button>
  );
}
