import { LinearProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import BackLink from "../../../shared/BackLink";
import { Avatar, Button, Card, CardHeader } from "@mui/material";
import { useDb } from "../../../../contexts/DatabaseContext";
import { CloudUpload } from "@mui/icons-material";
import { Importer } from "react-csv-importer";
import { useAuth } from "../../../../contexts/AuthContext";
import ProgramImportSchema from "./ProgramImportSchema";
import "react-csv-importer/dist/index.css";
import { useHistory } from "react-router-dom";

export default function ImportProgramData() {
  const { otpId, targetId, programId } = useParams();
  const { currentUser } = useAuth();
  const history = useHistory();
  const { getCompanyDoc, importOtpMeasurements } = useDb();

  const [program, setProgram] = useState();
  const [loading, setLoading] = useState(false);

  const loadProgram = async () => {
    const programCollectionPath = `otps/${otpId}/targets/${targetId}/programs`;
    const p = await getCompanyDoc(programCollectionPath, programId);
    setProgram(p);
  };

  useEffect(() => {
    return loadProgram();
  }, [otpId, targetId, programId]);

  let tempArray = [];

  const handleImport = async () => {
    setLoading(true);
    try {
      await importOtpMeasurements(otpId, targetId, programId, tempArray);
      history.goBack();
    } catch (err) {
      window.alert(err.message);
    }

    setLoading(false);
  };

  return !program ? (
    <LinearProgress />
  ) : (
    <div>
      <BackLink />
      <h1>Upload Historical Program Data</h1>
      <Card>
        <CardHeader
          title={`Metric: ${program.metric?.metricName}`}
          subheader={`Unit: ${program.metric?.metricUnit}`}
          avatar={
            <Avatar>
              <CloudUpload />
            </Avatar>
          }
          action={
            <Button size="small" variant="contained" color="primary">
              Sample File
            </Button>
          }
        />
      </Card>
      <Importer
        processChunk={async (rows) => {
          for (var row of rows) {
            if (isNaN(row.measurement)) {
              window.alert(
                "Your measurement column contains a value that is not a number. This import will abort and the screen will refresh. Press OK to proceed."
              );
              window.location.reload();
              return;
            }

            // Create the geo point
            tempArray.push({
              ...row,
              measurement: parseFloat(row.measurement),
              measurementData: row.measurement.toString(),
              timestamp: new Date(row.timestamp),
              createdBy: {
                firstName: currentUser.firstName,
                lastName: currentUser.lastName,
                email: currentUser.email,
                uid: currentUser.email,
              },
            });
          }
        }}
        onComplete={async () => {
          console.log(tempArray);
          await handleImport();
        }}
      >
        <ProgramImportSchema />
      </Importer>
    </div>
  );
}
