import { Badge, Button, Card, CardActions, CardContent, Step, StepContent, StepLabel, Stepper, TextField, Typography, CircularProgress } from '@mui/material'
import { ArrowDownward, ArrowForward, ArrowUpward, CheckCircle, GridOn } from '@mui/icons-material'
import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDb } from '../../../../contexts/DatabaseContext'
import { textFieldProps } from '../../../shared/constants'
import AddRiskAttachment from './AddRiskAttachment'

export default function AddRisk3Hierarchy() {
    const { blraId, riskId } = useParams()
    const { GetRiskHierarchy, addRiskControls, getRisk } = useDb()
    const hierarchy = GetRiskHierarchy()
    const history = useHistory()

    const [activeStep, setActiveStep] = useState(0);
    const [fileType, setFileType] = useState()
    const [state, setState] = useState({
        loading: false,
        error: null
    })
    const [attachments, setAttachments] = useState({
        0: {
            process: null,
            training: null,
            pto: null,
            compliance: null,
            length: 0,
            description: null,
            type: 'elimination',
            label: 'Elimination'
        },
        1: {
            process: null,
            training: null,
            pto: null,
            compliance: null,
            length: 0,
            description: null,
            type: 'substitution',
            label: 'Substitution'
        },
        2: {
            process: null,
            training: null,
            pto: null,
            compliance: null,
            length: 0,
            description: null,
            type: 'isolation',
            label: 'Isolation',
        },
        3: {
            process: null,
            training: null,
            pto: null,
            compliance: null,
            length: 0,
            description: null,
            type: 'engineering',
            label: 'Engineering'
        },
        4: {
            process: null,
            training: null,
            pto: null,
            compliance: null,
            length: 0,
            description: null,
            type: 'administrative',
            label: 'Administrative Controls'
        },
        5: {
            process: null,
            training: null,
            pto: null,
            compliance: null,
            length: 0,
            description: null,
            type: 'ppe',
            label: 'PPE',
        }
    })

    const [addOpen, setAddOpen] = useState(false)

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);  
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);  
    };

    const getFile = (u) => {
        setAttachments({
            ...attachments,
            [activeStep]: {
                ...attachments[activeStep],
                [fileType]: u,
                length: attachments[activeStep].length+1
            }
        })
    }

    const handleOpen = (t) => {
        setFileType(t)
        setAddOpen(true)
    }

    const handleChange = (e) => {
        setAttachments({
            ...attachments,
            [activeStep]: {
                ...attachments[activeStep],
                description: e.target.value
            }
        })
    }

    const handleSubmit = async () => {

        let controlsArray = []
        for(const i in attachments) {
            console.log(i)
            controlsArray.push({...attachments[i], index: i })
        }
        
        let totalControlValue = 0
        controlsArray.map(c => {
            console.log(hierarchy)
            c.controlValue = hierarchy[c.index].value
            totalControlValue = totalControlValue + hierarchy[c.index].value
        })
        setState({
            loading: true,
            error: null
        })
        try {
            await addRiskControls(blraId, riskId, totalControlValue, controlsArray)
            history.push(`/risk-manager/residual-risk/${blraId}/risk/${riskId}/4/blra`)
        }
        catch(err) {
            setState({
                loading: false,
                error: err.message
            })
        }
    }

    const [risk, setRisk] = useState(null)

    const firstLoad = async () => {
        const r = await getRisk(blraId, riskId)
        setRisk(r)
    }
    
    if(!risk) {
        firstLoad()
        return <CircularProgress />
    }
    else return (
        <div>
            <h1>Hierarchy of Controls</h1>
            <p>Risk Description: {risk.description}</p>
            <Stepper activeStep={activeStep} orientation="vertical">
                {
                    hierarchy && hierarchy.map((step, index) => (
                        <Step key={index}>
                            <StepLabel>{step.label} - {step.value}%</StepLabel>
                            <StepContent>
                                <Card>
                                    <CardContent>
                                        <TextField
                                            {...textFieldProps}
                                            label="Description of Control"
                                            onChange={handleChange}
                                            required
                                            value={attachments[index].description}
                                        />
                                    </CardContent>
                                    <CardActions>
                                        <Badge 
                                            badgeContent={attachments[index].process && <CheckCircle style={{ color: 'green' }}/>}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left'
                                            }}
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleOpen('process')}
                                            >
                                                Procedure
                                            </Button>
                                        </Badge>
                                        <Badge 
                                            badgeContent={attachments[index].training && <CheckCircle style={{ color: 'green' }}/>}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left'
                                            }}
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleOpen('training')}
                                            >
                                                Training
                                            </Button>
                                        </Badge>
                                        <Badge 
                                            badgeContent={attachments[index].pto && <CheckCircle style={{ color: 'green' }}/>}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left'
                                            }}
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleOpen('pto')}
                                            >
                                                PTO
                                            </Button>
                                        </Badge>
                                        <Badge 
                                            badgeContent={attachments[index].compliance && <CheckCircle style={{ color: 'green' }}/>}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left'
                                            }}
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleOpen('compliance')}
                                            >
                                                Legal
                                            </Button>
                                        </Badge>
                                        &nbsp;
                                        Attachments: {attachments[index].length} / 4
                                    </CardActions>
                                </Card>
                                <CardActions className="iso-right">
                                    {
                                        activeStep > 0 && <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleBack}
                                            startIcon={<ArrowUpward />}
                                        >
                                            Back
                                        </Button>
                                    }
                                    {
                                        activeStep <= index && activeStep < hierarchy.length-1 ? 
                                            <>
                                                {
                                                    attachments[0].length < 4 && <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleNext}
                                                        startIcon={<ArrowDownward />}
                                                    >
                                                        Next
                                                    </Button>
                                                }
                                                
                                            </>
                                        :
                                        null
                                    }
                                    {
                                        attachments[0].length === 4 
                                        ? 
                                        <Button 
                                            endIcon={<ArrowForward />}
                                            variant="contained"
                                            color="primary"
                                            startIcon={<GridOn />}
                                            onClick={handleSubmit}
                                            disabled={state.loading}
                                        >
                                            Residual Risk
                                        </Button> 
                                        : 
                                        null
                                    }
                                    {
                                        attachments[1].description ||
                                        attachments[2].description ||
                                        attachments[3].description ||
                                        attachments[4].description ||
                                        attachments[5].description 
                                        ? 
                                        <Button 
                                            endIcon={<ArrowForward />}
                                            variant="contained"
                                            color="primary"
                                            startIcon={<GridOn />}
                                            onClick={handleSubmit}
                                            disabled={state.loading}
                                        >
                                            Residual Risk
                                        </Button> 
                                        : 
                                        null
                                    }
                                    {
                                        state.loading && <CircularProgress />
                                    }
                                </CardActions>
                            </StepContent>
                        </Step>
                    ))
                }
            </Stepper>
            {
                state.error && <Typography color="secondary">{state.error}</Typography>
            }
            <AddRiskAttachment
                sendFile={getFile}
                open={addOpen}
                close={() => setAddOpen(false)}
            />
        </div>
    )
}
 