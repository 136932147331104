import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material'
import React, { useState } from 'react'
import { textFieldProps } from '../../shared/constants'

export default function DeclineSesionInvite({open, close, handleRes}) {

  const [reason, setReason] = useState(null)

  const handleClose = () => {
    setReason(null)
    close()
  }

  return (
    <Dialog open={open} maxWidth="md" fullWidth onClose={close}>
      <DialogContent>
        <h4>Please explain why you're not attending</h4>
        <TextField
          {...textFieldProps}
          required
          label='Reason'
          onChange={(e) => setReason(e.target.value)}
        />
      </DialogContent>
      <DialogActions className='iso-right' >
        <Button variant='contained' color='primary' onClick={() => handleRes(false, reason)}>Confirm</Button>
        <Button variant='outlined' color='secondary' onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
} 