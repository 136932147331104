import { Card, CardActions, CardContent, TextField, Grid, Button, Typography, Divider } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import BackLink from '../../shared/BackLink'
import { selectFieldProps, textFieldProps } from '../../shared/constants'
import Select from 'react-select'
import { Save } from '@mui/icons-material'

export default function REditAction() {
    const { id } = useParams()
    const history = useHistory()
    const { getAction, GetDepartments, GetSites, GetUsersLevelFilter, GetSources, getIsoAgenciesPerClassAsync, GetPriorities, GetIsoClasses, updateAction } = useDb()

    // Database Calls
    const departments = GetDepartments()
    const sites = GetSites()
    const rosters = GetUsersLevelFilter('actionManager', 1) // <-- this may need to change to a special HOD type of user
    const sources = GetSources()
    const isoClassifications = GetIsoClasses()
    const priorities = GetPriorities()
    
    const [action, setAction] = useState()
    const [isoAgencies, setIsoAgencies] = useState([]);

    // Edited State
    const [state, setState] = useState({
        loading: false,
        error: null,
        data: {}
    })

    const loadAction = async () => {
        const a = await getAction(id)
        setState({
            ...state,
            data: {
                ...state.data,
                description: a.description,
                immAction: a.immAction
            }
        })
        setAction(a)
    }

    useEffect(() => {
        const unsubscribe = loadAction();
        return unsubscribe
    }, [])
    

    const getIsoAgencies = async () => {
        if(action) {
            console.log("here")
            const agencies = await getIsoAgenciesPerClassAsync(state.data.isoClass || action.isoClass)
            setIsoAgencies(agencies)
        }
    }

    useEffect(() => {
      const unsubscribe = getIsoAgencies();
      return unsubscribe
    }, [action, state.data.isoClass])

    const handleChange = (e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleSelect = (v, e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.name]: v.value
            }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setState({
            ...state,
            loading: true,
            error: null
        })
        try {
            await updateAction(id, state.data);
            history.goBack();
        }
        catch(err) {
            setState({
                ...state,
                loading: false,
                error: err.message
            })
        }
    }
    
    return (
        <div>
            <h1>Edit Action</h1>
            <BackLink />
            {
                action && isoAgencies && <div>
                    <form onSubmit={handleSubmit}>
                            <Card>
                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <Typography>Action Source</Typography>
                                            <br/>
                                            Current Department: {action.department.name}
                                            <Select
                                                options={departments}
                                                {...selectFieldProps}
                                                placeholder={'Change Department ...'}
                                                onChange={handleSelect}
                                                className={'iso-select'}
                                                name="department"
                                            />
                                            Current Site: {action.site.label}
                                            <Select
                                                options={sites}
                                                {...selectFieldProps}
                                                placeholder={'Change Site ...'}
                                                onChange={(v, e) => handleSelect({value: v}, e)}
                                                className={'iso-select'}
                                                name="site"
                                            />
                                            Current Source: {action.source.sourceName}
                                            <Select
                                                options={sources}
                                                {...selectFieldProps}
                                                placeholder={'Change Source ...'}
                                                onChange={handleSelect}
                                                className={'iso-select'}
                                                name="source"
                                            />
                                            <br/>
                                            <Divider />
                                            <br/>
                                            <Typography>Action Responsiblity</Typography>
                                            <br/>
                                            Current HOD: {action.hod.firstName} {action.hod.lastName}
                                            <Select
                                                options={rosters}
                                                {...selectFieldProps}
                                                name="hod"
                                                placeholder={'Change Responsible HOD ...'}
                                                onChange={handleSelect}
                                                className={'iso-select'}
                                            />
                                            Current Originator: {action.originator.firstName} {action.originator.lastName}
                                            <Select
                                                options={rosters}
                                                {...selectFieldProps}
                                                placeholder={'Select Originator ...'}
                                                name="originator"
                                                onChange={handleSelect}
                                                className={'iso-select'}
                                            />
                                            Current ISO Classification: {action.isoClass}
                                            <Select
                                                options={isoClassifications}
                                                {...selectFieldProps}
                                                placeholder={'Change ISO Classification ...'}
                                                onChange={handleSelect}
                                                className={'iso-select'}
                                                name="isoClass"
                                            />
                                            {
                                                action.isoClass
                                                ?
                                                <>
                                                    Current ISO Agency: {action.isoAgency.isoAgency}
                                                    {
                                                        isoAgencies && <Select
                                                            options={isoAgencies}
                                                            {...selectFieldProps}
                                                            placeholder={'Change ISO Agency ...'}
                                                            onChange={handleSelect}
                                                            className={'iso-select'}
                                                            name="isoAgency"

                                                        />
                                                    }
                                                </>
                                                :
                                                null
                                            }
                                            Current Priority: {action.priority}
                                            <Select
                                                options={priorities}
                                                {...selectFieldProps}
                                                placeholder={'Change Priority ...'}
                                                name="priority"
                                                onChange={(v, e) => handleSelect({value: v.value.name}, e)}
                                                className={'iso-select capitalize'}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <Typography>Action Description</Typography>
                                            <br/>
                                            <TextField value={state.data.description} className={'iso-text-field'} {...textFieldProps} label="Action Description" name="description" multiline rows={3} required onChange={handleChange}/>
                                            <TextField value={state.data.immAction} className={'iso-text-field'} {...textFieldProps} label="Immediate Action Taken" name="immAction" multiline rows={3} required onChange={handleChange}/>
                                        </Grid>
                                    </Grid>
                                    { state.error && <Typography color="error">{state.error}</Typography> }
                                <CardActions>
                                    <Button disabled={ state.loading } color="primary" variant="contained" startIcon={<Save />} type="submit">Save Changes</Button>
                                </CardActions>
                                </CardContent>
                            </Card>
                    </form>
                </div>
            }
        </div>
    )
}
