import { Button, Card, CardContent, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react'
import FloatingHelpComponent from '../../help/FloatingHelpComponent'
import CreateIsoAgencyForm from './CreateIsoAgencyForm'
import IsoAgencyList from './IsoAgencyList'

const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: theme.palette.cardBg
    }
}))

export default function IsoAgencies() {
    const classes = useStyles()
    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                    <IsoAgencyList />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Button onClick={() => setOpen(true)} color="primary" variant="contained">Create ISO Agency</Button>
                        </CardContent>
                    </Card>
                    <CreateIsoAgencyForm closeDialog={handleClose} open={open} />
                </Grid>
            </Grid>

            <FloatingHelpComponent
                name="What are ISO Agencies?" 
                videoPath="master_list"
                videoIndex={5}
            />
        </>
    )
}
