import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ArrowForward, Language, SportsEsports } from '@mui/icons-material'
import React from 'react'

const useStyles = makeStyles((theme) => ({
    card: {

    },
    descDiv: {
        padding: '20px 0'
    },
    price: {
        fontWeight: 'bold',
        color: theme.palette.primary.main
    }
}))

export default function PlanCard({ loading }) {
    const classes = useStyles()

    return (
        <Card>
            <CardHeader
                title={"Enterprise Clients"}
                avatar={<Language />}
            />
            <CardContent>
                <div className={classes.descDiv}>
                    100 + Users
                </div>
                
                <Typography>Monthly Cost:</Typography>
                <Typography className={classes.price} variant="h6">Contact Us</Typography>
                
                
            </CardContent>
            <CardActions>
                {
                    loading ?
                    <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        
                    >
                        <CircularProgress size={20} />
                    </Button>
                    :
                    <Button 
                        fullWidth 
                        variant="contained" 
                        color="primary" 
                        endIcon={<ArrowForward />}
                        href="https://calendly.com/alex-iso/30min"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Select Plan
                    </Button>
                }
                
            </CardActions>
        </Card>
    )
}
