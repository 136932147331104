import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Collapse,
  IconButton,
  Chip,
  Box,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useAuth } from "../../../contexts/AuthContext";

const useStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  chip: {
    margin: "5px",
  },
});

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const { currentUser } = useAuth()

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.label}
        </TableCell>
        <TableCell>{currentUser.currency ? currentUser.currency.value : ""} {row.cost}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {row.attachments &&
                row.attachments.map((attachment, index) => (
                  <a key={index} href={attachment.url}>
                    <Chip
                      className={classes.chip}
                      label={attachment.fileName}
                    />
                  </a>
                ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function CollapsibleCostTable({ data }) {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="collapsible table">
        <TableBody>
          {data.map((row) => (
            <Row key={row.label} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
