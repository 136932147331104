import React, { useState } from "react";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { ThumbUp } from "@mui/icons-material";
import Select from "react-select";
import { useDb } from "../../../../contexts/DatabaseContext";
import { selectFieldProps } from "../../../shared/constants";

export default function FinalApprovalChecklist({ moc, props }) {
  const { handleAdvance, handleUpdate } = props;

  const { GetUsersLevelFilter } = useDb();
  const [loading, setLoading] = useState(false);

  const users = GetUsersLevelFilter("mocManager", 2);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [datePicked, setDatePicked] = useState(false);
  const [hodPicked, setHodPicked] = useState(false);

  const handleDateChange = async (date) => {
    setSelectedDate(date);
    console.log(date);
    await handleUpdate({
      followUpDueDate: date,
    });
    setDatePicked(true);
  };

  const handleHodSelect = async (v) => {
    await handleUpdate({
      followUpHod: {
        id: v.value.id,
        name: `${v.value.firstName} ${v.value.lastName}`,
      },
      evaluators: [
        {
          participant: v.value.id,
          name: `${v.value.firstName} ${v.value.lastName}`,
          approved: false,
        },
      ],
    });
    setHodPicked(true);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await handleUpdate({
        phase: 6,
        step: "finalEvaluation",
        currentStepIndex: 0,
      });
      await handleAdvance();
    } catch (err) {
      window.alert(err.message);
    }
    setLoading(false);
  };

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Typography>Schedule Follow Up:</Typography>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DatePicker
                  label="Follow Up Date"
                  onChange={(d) => handleDateChange(d.toDate())}
                  defaultValue={dayjs(selectedDate)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  options={users}
                  {...selectFieldProps}
                  placeholder="Responsible HOD"
                  onChange={handleHodSelect}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </LocalizationProvider>
      <br />
      <Button
        variant="contained"
        color="primary"
        endIcon={<ThumbUp />}
        disabled={loading || !hodPicked || !datePicked}
        onClick={handleSubmit}
      >
        Complete
      </Button>
    </div>
  );
}
