import { Button, Card, CardContent, CardHeader, LinearProgress, TextField, Typography } from '@mui/material'
import { Save } from '@mui/icons-material';
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext';
import BackLink from '../../shared/BackLink'
import { textFieldProps } from '../../shared/constants'

export default function VideoCategoryDetail() {
    const { id } = useParams();
    const history = useHistory();
    const { mgmtGetVideoCats, mgmtVideoUpdate } = useDb();
    const [cat, setCat] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const getCat = async () => {
        const c = await mgmtGetVideoCats(id);
        setCat(c)
    }

    useEffect(() => {
      const unsubscribe = getCat();
      return unsubscribe;
    }, [id])

    const handleChange = (e, i) => {
        let vidArray = cat.videos 
        vidArray[i].url = e.target.value
        setCat({
            ...cat,
            videos: vidArray
        })
    }

    const handleSubmit = async (e) => {
        setError(null);
        setLoading(true);
        try {
            await mgmtVideoUpdate(cat.videos);
            setLoading(false);
            history.goBack();
        }
        catch(err) {
            setError(err.message);
            setLoading(false);
        }
    }
    
    if(!cat) {
        return <LinearProgress />
    }
    else return (
        <div>
            <BackLink />
            <Card>
                <CardHeader 
                    title={cat.label}
                    subheader={error && <Typography color="secondary">{error}</Typography>}
                    action={<Button disabled={loading} color="primary" variant="contained" startIcon={<Save />}>Save</Button>}
                />
                <CardContent>
                    {
                        cat.videos.map((v, i) => (
                            <TextField 
                                {...textFieldProps}
                                label={v.label}
                                value={cat.videos[i].url}
                                onChange={(e) => handleChange(e, i)}
                            />
                        ))
                    }
                </CardContent>
            </Card>
        </div>
    )
}
