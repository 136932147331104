import { Button, Card, CardActions, CardContent, CircularProgress } from '@mui/material'
import { ArrowLeftOutlined, CloudDownload, Edit } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { useDb } from '../../../contexts/DatabaseContext'
import EditProvider from './EditProvider'
import { useAuth } from '../../../contexts/AuthContext'

export default function ProviderView() {

  const { getTrainingProvider } = useDb()
  const [provider, setProvider] = useState({})
  const [loading, setLoading] = useState(true)
  const [open, setOpen] = useState(false)
  const { currentUser } = useAuth()
  const history = useHistory()
  const { proId } = useParams()

  const handleClose = () => {
    setOpen(false)
  }
  const handleOpen = () => {
    setOpen(true)
  }

  useEffect(async () => {
    setProvider({})
    const p = await getTrainingProvider(proId)
    setProvider(p)
    setLoading(false)
  }, [proId])

  console.log(provider)

  return (
    <div>
      <Button variant="contained" color="primary" startIcon={<ArrowLeftOutlined />} onClick={() => history.goBack()}>Back</Button>
      <br />
      <br />
      {
        loading
        ?
        <CircularProgress />
        :
        <div>
          <Card elevation={5}>
            <CardContent>
              <h2>{provider.name}</h2>
              <h4>Adress</h4>
              <ul>
                <li>{provider.street}</li>
                <li>{provider.city}</li>
                <li>{provider.province}</li>
              </ul>
              <h4>Date Created: {moment(provider.createdAt.toDate()).format('DD-MMM-YYYY')}</h4>
              <h4>Email: {provider.email}</h4>
              {
                provider.beeExpiry
                ?
                <>
                  <h4>BBBEEE Expiry: {moment(provider.createdAt.toDate()).fromNow()} / {moment(provider.createdAt.toDate()).format('DD/MM/YYYY')}</h4>
                  <h4>BBBEEE Certificate: <Button disabled={!provider.beeCert} startIcon={<CloudDownload />} href={provider.beeCert} target="_blank" variant="contained" color="primary">Download</Button>
                  </h4>
                </>
                :
                null

              }
            </CardContent>
            <CardActions className='iso-right'>
              {
                currentUser.companyAdmin
                ?
                <Button variant="contained" color="primary" onClick={handleOpen} startIcon={<Edit />}>Edit</Button>
                :
                null
              }
            </CardActions>
          </Card>
          {
            open
            ?
            <EditProvider open={open} closeDialog={handleClose} data={provider} />
            : 
            null
          }
        </div>
      }
    </div>
  )
}
