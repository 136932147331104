import { LinearProgress } from "@mui/material";
import React from "react";
import {
  mocSteps,
  phases,
  mocProps,
  approvalLevels,
  getThreshold,
} from "./mocSteps";
import { useDb } from "../../../../contexts/DatabaseContext";
import { useAuth } from "../../../../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import MocAccessDenied from "../../moc-components/MocAccessDenied";
import { functions } from "../../../../firebase";

export default function MocRequestStep({ moc, getMoc, setMoc }) {
  const { updateDocFromPath } = useDb();
  const { currentUser } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();

  const handleUpdate = async (changes = {}) => {
    setLoading(true);
    try {
      console.log("Updating MOC");
      await updateDocFromPath(moc.path, { ...moc, ...changes });
      await getMoc();
    } catch (err) {
      window.alert(err.message);
    }
    setLoading(false);
  };

  const handleNotifications = async () => {
    const mocNotification = functions.httpsCallable("mocNotification");
    await mocNotification({
      mocPath: moc.path,
      partyCollection: approvalLevels[moc.phase].collectionName,
      partyType: approvalLevels[moc.phase].roleName,
    });
  };

  const notifyAllParties = async (msgTemplate) => {
    const mocNotifyAllParties = functions.httpsCallable("mocNotifyAllParties");
    await mocNotifyAllParties({
      moc,
      companyId: currentUser.companyId,
      templateData: msgTemplate,
    });
  };

  const handleGroupApprovalNotification = async () => {
    let thresholds = currentUser.mocThresholds;
    thresholds.low.risk = currentUser.riskControls.low;
    thresholds.med.risk = currentUser.riskControls.med;
    thresholds.high.risk = currentUser.riskControls.high;
    const mocRisks = {
      spend: moc.totalCost,
      riskValue: moc.initialCons * moc.initialLike,
    };
    const approvalLevel = getThreshold(mocRisks, thresholds);
    const mocNotifyGroups = functions.httpsCallable("mocNotifyGroups");
    await mocNotifyGroups({
      groupIdArray: thresholds[approvalLevel].groups,
      mocPath: moc.path,
      companyId: currentUser.companyId,
    });
  };

  const handleGroupNotification = async (msgTemplate) => {
    const notifyGroupsGeneric = functions.httpsCallable("notifyGroupsGeneric");
    let thresholds = currentUser.mocThresholds;
    thresholds.low.risk = currentUser.riskControls.low;
    thresholds.med.risk = currentUser.riskControls.med;
    thresholds.high.risk = currentUser.riskControls.high;
    const mocRisks = {
      spend: moc.totalCost,
      riskValue: moc.initialCons * moc.initialLike,
    };
    const approvalLevel = getThreshold(mocRisks, thresholds);
    await notifyGroupsGeneric({
      groupIdArray: thresholds[approvalLevel].groups,
      companyId: currentUser.companyId,
      ...msgTemplate,
    });
  };

  const advanceStep = async (moc, currentStep, index, phase) => {
    const nextStepIndex = currentStep + 1;
    const updates = {
      currentStepIndex: nextStepIndex,
      step: phases[moc.phase + 1].steps[nextStepIndex],
    };
    await updateDocFromPath(moc.path, updates);
    await getMoc();
  };

  const advanceIndividualStep = async (moc, currentStep, index) => {
    const nextStepIndex = currentStep + 1;
    const participantArray = moc[phases[moc.phase].accessArray];
    participantArray[index].step = nextStepIndex;
    await updateDocFromPath(moc.path, {
      [phases[moc.phase].accessArray]: participantArray,
    });
    await getMoc();
  };

  const advancePhase = async (moc) => {
    if (moc.phase < 5) {
      await updateDocFromPath(moc.path, {
        phase: moc.phase + 1,
        step: phases[moc.phase + 1].steps[0],
        currentStepIndex: 0,
      });
    }
    if (mocProps[moc.step]?.sendNotifications) {
      await handleNotifications();
    }
    if (phases[moc.phase].sendToGroups) {
      handleGroupApprovalNotification();
    }
    history.push(`/moc-register`);
  };

  const handleAdvance = async () => {
    const { phase, currentStepIndex } = moc;
    const steps = phases[phase].steps;
    setLoading(true);

    const accessArray = moc[phases[phase].accessArray];
    const index =
      accessArray &&
      accessArray.findIndex((item) => item.participant === currentUser.uid);

    const currentStep = phases[moc.phase].limitAccess
      ? accessArray[index].step
      : currentStepIndex;

    const currentPhase = phases[phase];
    try {
      if (currentStep < steps.length - 1) {
        // Go to the next step in the current phase
        if (currentPhase.limitAccess) {
          await advanceIndividualStep(moc, currentStep, index);
        } else await advanceStep(moc, currentStep, accessArray, index, phase);
      } else {
        // Go to the next phase and reset the step to 0
        await advancePhase(moc);
      }
    } catch (err) {
      window.alert(err.message);
    }
    setLoading(false);
  };

  const renderStep = (moc) => {
    // Check if an access level limitation exists
    const phase = phases[moc.phase];
    if (
      phase.limitAccess &&
      moc[phase.accessArray] &&
      !moc[phase.accessArray].some(
        (party) => party.participant === currentUser.uid
      )
    ) {
      // See if the users uid is in the access array
      return <MocAccessDenied />;
    } else {
      const stepProps = {
        ...mocProps[moc.step],
        ...approvalLevels[moc.phase],
        loading,
        setLoading,
        handleAdvance,
        handleUpdate,
        handleGroupNotification,
        notifyAllParties,
        setMoc,
      };
      function findParticipant(array, participant) {
        let object = array.find((item) => item.participant === participant);
        return object ? object.step : undefined;
      }
      const renderStep = phase.limitAccess
        ? findParticipant(moc[phase.accessArray], currentUser.uid)
        : moc.currentStepIndex;
      return mocSteps[phase.steps[renderStep]](moc, stepProps);
    }
  };
  return !moc ? (
    <LinearProgress />
  ) : (
    <div>
      <h1>{phases[moc.phase].label}</h1>
      {moc && renderStep(moc)}
      <div style={{ height: 50 }}></div>
    </div>
  );
}
