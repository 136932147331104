import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import Select from 'react-select'
import { selectFieldProps } from '../../shared/constants'
import { functions } from '../../../firebase' 

export default function AddWorkToEmployee({ open, close, employee, updateEmpJobs }) {
    const { GetOccupations, addOccupationRequest } = useDb()
    const jobs = GetOccupations()

    const existingJobs = employee.occupation.map(o => (o.id))

    let jobOptions = []
    jobs && jobs.map(j => {
        if(!existingJobs.includes(j.id)) {
            jobOptions.push(j)
        }
    })

    const [state, setState] = useState({
        loading: false,
        error: null,
        data: {}
    })

    const handleSelect = (v, e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.name]: v.value
            }
        })
    }
    
    const handleClose = () => {
        setState({
            loading: false,
            error: null,
            data: {}
        })
        close()
    }

    const handleSubmit = async (e) => {
        setState({
            ...state,
            error: null,
            loading:true
        })
        try {
            await addOccupationRequest(employee.id, state.data.occupation)
            updateEmpJobs({
                id: state.data.occupation.id,
                label: state.data.occupation.title
            })
            handleClose()
        }
        catch(err) {
            setState({
                ...state,
                error: err.message,
                loading: false
            })
        }
    }
    
    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>Add Occupation to {employee.firstName} {employee.lastName}</DialogTitle>
            <DialogContent>
                <Select
                    {...selectFieldProps}
                    className="iso-select"
                    placeholder="Select Occupation ..."
                    name="occupation"
                    onChange={handleSelect}
                    options={jobOptions}
                />
                {
                    state.error 
                    ?
                    <span className="iso-error">{state.error}</span>
                    :
                    null
                }
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    onClick={handleClose}
                    disabled={state.loading}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={
                        state.loading ||
                        !state.data.occupation
                    }
                >
                    Add Occupation
                </Button>
            </DialogActions>
        </Dialog>
    )
}
