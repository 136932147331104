import React from "react";
import MocSummary from "../../moc-components/MocSummary";
import Phase1Checklist from "../../moc-components/Phase1Checklist";
import MocExpectedBenefits from "../../moc-components/MocExpectedBenefits";
import RequestDetails from "../../moc-components/RequestDetails";
import MocParticipants from "../../moc-components/MocParticipants";
import MocRiskQuestionnaire from "../../moc-components/MocRiskQuestionnaire";
import MocCostEstimates from "../../moc-components/MocCostEstimates";
import MocTimeline from "../../moc-components/MocTimeline";
import MocApprovalReport from "../../moc-components/approvals/MocApprovalReport";
import MocRiskQuestionnaireAssignment from "../../moc-components/MocRiskQuestionnaireAssignment";
import FinalApprovalChecklist from "../../moc-components/approvals/FinalApprovalChecklist";
import MocEvaluation from "../../moc-components/evaluations/MocEvaluation";
import FollowUpChecklist from "../../moc-components/evaluations/FollowUpChecklist";
import MocReviewApprove from "../../moc-components/MocReviewApprove";
import MocComplete from "../../moc-components/MocComplete";

export const mocSteps = {
  phase1: (moc, props) => <Phase1Checklist moc={moc} props={props} />,
  summary: (moc, props) => <MocSummary moc={moc} props={props} />,
  requestDetails: (moc, props) => <RequestDetails moc={moc} props={props} />,
  benefits: (moc, props) => <MocExpectedBenefits moc={moc} props={props} />,
  participants: (moc, props) => <MocParticipants moc={moc} props={props} />,
  riskQuestionnaire: (moc, props) => (
    <MocRiskQuestionnaire moc={moc} props={props} />
  ),
  riskAssignment: (moc, props) => (
    <MocRiskQuestionnaireAssignment moc={moc} props={props} />
  ),
  costEstimates: (moc, props) => <MocCostEstimates moc={moc} props={props} />,
  timelines: (moc, props) => <MocTimeline moc={moc} props={props} />,
  approvalReport: (moc, props) => <MocApprovalReport moc={moc} props={props} />,
  finalApproval: (moc, props) => (
    <FinalApprovalChecklist moc={moc} props={props} />
  ),
  evaluation: (moc, props) => <MocEvaluation moc={moc} props={props} />,
  reviewApprove: (moc, props) => <MocReviewApprove moc={moc} props={props} />,
  finalEvaluation: (moc, props) => (
    <FollowUpChecklist moc={moc} props={props} />
  ),
  mocClosed: (moc, props) => <MocComplete moc={moc} props={props} />,
};

export const approvalLevels = {
  2: { collectionName: "reviewers", roleName: "Reviewer" },
  3: { collectionName: "approvers", roleName: "Additional Approver" },
  4: { collectionName: "approvers", roleName: "Additional Approver" },
};

export const mocProps = {
  phase1: {
    title: "MOC Checklist",
    advancePhase: true,
  },
  summary: {
    title: "Summary",
  },
  requestDetails: {
    title: "Populate Request Summary",
  },
  benefits: {
    title: "Expected Benefits",
  },
  participants: {
    title: "Review and Assess",
    advancePhase: true,
    sendNotifications: true,
  },
  requestDetails: {
    title: "Summary",
  },
  costEstimates: {
    title: "Estimated Cost Commitments/Additional Resources Required",
  },
  timelines: {
    title: "Expected Timeframe",
  },
  riskQuestionnaire: {
    title: "Risk Questionnaire",
  },
  approvalReport: {
    title: "Approval Report",
  },
  riskAssignment: {
    title: "Risk Questionnaire Assignment",
  },
  finalApproval: {
    title: "Final Approval",
    advancePhase: true,
  },
  evaluation: {
    title: "Evaluation",
  },
  finalEvaluation: {
    title: "Final Evaluation",
  },
  mocClosed: {
    title: "MOC Complete",
  },
};

export const phases = {
  1: {
    label: "Phase 1: MOC Checklist",
    steps: ["phase1"],
  },
  2: {
    label: "Phase 2: Request for Change",
    steps: ["requestDetails", "participants"],
  },
  3: {
    label: "Phase 3: Review and Assess",
    limitAccess: true,
    accessArray: "reviewers",
    sendToGroups: true,
    steps: [
      "summary",
      "benefits",
      "timelines",
      "costEstimates",
      "riskQuestionnaire",
      "riskAssignment",
      "participants",
      "reviewApprove",
    ],
  },
  4: {
    label: "Phase 4: Review and Final Approval",
    limitAccess: true,
    accessArray: "approvers",
    steps: ["approvalReport"],
  },
  5: {
    label: "Phase 5: Follow Up & Evaluation",
    limitAccess: true,
    accessArray: "approvers",
    steps: ["summary", "evaluation", "finalApproval"],
    readOnly: true,
  },
  6: {
    label: "Phase 6: Scheduled Evaluation",
    limitAccess: false,
    accessArray: "evaluators",
    steps: ["finalEvaluation"],
  },
  7: {
    label: "MOC Closed",
    limitAccess: false,
    accessArray: "evaluators",
    steps: ["mocClosed"],
  },
};

export function getThreshold(obj, thresholds) {
  var objRisk = obj.riskValue;
  var objSpend = obj.spend;
  var thresholdKeys = Object.keys(thresholds);

  // Sort the keys based on risk and spend values in descending order.
  thresholdKeys.sort(function (a, b) {
    var riskDiff = thresholds[b].risk - thresholds[a].risk;
    var spendDiff = thresholds[b].spend - thresholds[a].spend;
    if (riskDiff === 0) {
      return spendDiff;
    }
    return riskDiff;
  });

  var matchedThreshold = null;

  // Loop through the sorted keys
  for (var i = 0; i < thresholdKeys.length; i++) {
    if (
      objRisk <= thresholds[thresholdKeys[i]].risk ||
      objSpend <= thresholds[thresholdKeys[i]].spend
    ) {
      matchedThreshold = thresholdKeys[i];
      break;
    }
  }

  return matchedThreshold;
}
