import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material'
import { Save } from '@mui/icons-material'
import React, { useState } from 'react'
import Select from 'react-select'
import { useDb } from '../../../contexts/DatabaseContext'
import { textFieldProps, selectFieldProps } from '../../shared/constants'

export default function CreateIsoAgencyForm({ closeDialog, open }) {
    const [isoClass, setIsoClass] = useState(null)
    const [isoAgency, setIsoAgency] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    
    const { createIsoAgency, GetIsoClasses } = useDb()

    const isoClassifications = GetIsoClasses()

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            await createIsoAgency({
                isoClass,
                isoAgency
            }).then(() => {
                setLoading(false)
                setError(null)
                closeDialog()
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    return (
        <Dialog open={open} onClose={closeDialog} maxWidth="sm" fullWidth>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <Select
                        options={isoClassifications}
                        {...selectFieldProps}
                        onChange={(val) => setIsoClass(val.value)}
                        className={'iso-select'}
                        required
                        placeholder={'ISO Classification ...'}
                    />
                    <TextField required {...textFieldProps} label={'ISO Agency'} className={'iso-text-field'} onChange={(e) => setIsoAgency(e.target.value)} />
                    {
                        error ? <span className={'iso-eror'}>{error}</span> : null
                    }
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} color={'secondary'} onClick={closeDialog}>Cancel</Button>
                    <Button disabled={loading} type={'submit'} color={'primary'} variant={'contained'} startIcon={<Save />}>Submit</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
