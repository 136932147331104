import { Avatar, Card, CardActions, CardHeader } from "@mui/material";
import React from "react";
import { fieldTypes } from "../form-components/fieldTypes";
import { Delete, Edit, Info } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";

export default function IsoQuestion({
  question,
  responses,
  setResponses,
  loading,
  handleEdit,
  render,
  handleDelete,
}) {
  const handleInputChange = async (name, value) => {
    await setResponses((prevResponses) => {
      // Check if the current question response is already in the state array
      const responseIndex = prevResponses.findIndex(
        (response) => response.name === name
      );

      // If the response is found, update the value, otherwise add a new response object
      if (responseIndex !== -1) {
        prevResponses[responseIndex].value = value;
        return [...prevResponses];
      } else {
        return [...prevResponses, { name, title: name, value }];
      }
    });
  };
  const renderField = (field) => {
    const fieldProps = {
      ...field.props,
      label: field.title,
      loading,
      onChange: handleInputChange,
    };
    // Find the response object for the current question and pass its value
    const response = responses.find((resp) => resp.name === field.title);
    if (response) {
      fieldProps.value = response.value;
    }
    return fieldTypes[field.type](field.title, fieldProps);
  };

  return (
    <Card elevation={2}>
      <CardHeader
        title={question.title}
        avatar={
          <Avatar>
            <Info />
          </Avatar>
        }
        subheader={
          <div style={{ padding: 10 }}>
            {question.type && renderField(question)}
          </div>
        }
        action={
          !render && (
            <IconButton color="secondary" onClick={() => handleDelete(question)} size="large">
              <Delete />
            </IconButton>
          )
        }
      />
      {!render && (
        <CardActions>
          <Button
            startIcon={<Edit />}
            color="primary"
            variant="outlined"
            onClick={() => handleEdit(question)}
          >
            Edit
          </Button>
        </CardActions>
      )}
    </Card>
  );
}
