import { MenuItem } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { useDb } from "../../../contexts/DatabaseContext";
import DeleteConfirm from "../../shared/DeleteConfirm";
import PdfView from "../../shared/PdfView";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  dropDown: {
    backgroundColor: theme.palette.menuBg,
  },
}));

export default function DocDropDown({ doc, openDialog, closeDialog }) {
  const classes = useStyles();
  const { currentUser } = useAuth();
  const { generateSingleDocPdf, getExternalDocLink } = useDb();

  // PDF Stuff
  const [pdfViewOpen, setPdfViewOpen] = useState(false);
  const [pdf, setPdf] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [intPartiesOpen, setIntPartiesOpen] = useState(false);

  const openPdfView = (file) => {
    setPdf(file);
    setPdfViewOpen(true);
  };
  const closePdfView = () => {
    setPdfViewOpen(false);
    // setPdf(null)
  };

  // Action State
  const [loading, setLoading] = useState(false);

  const generatePDF = async () => {
    setLoading(true);
    try {
      await generateSingleDocPdf(doc).then(() => {
        setLoading(false);
      });
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }
  };

  const getHyperlink = async () => {
    const link = await getExternalDocLink(doc);
    await navigator.clipboard.writeText(link);
    console.log(link);
    window.alert("External Link Copied to clipboard!");
  };

  return (
    <div className={classes.dropDown}>
      {currentUser.companyAdmin || currentUser.accessRights.docManager <= 1 ? (
        <Link to={`/doc-manager/doc-detail/${doc.id}`}>
          <MenuItem>Detail</MenuItem>
        </Link>
      ) : null}
      <a target="_blank" href={doc.docUrl}>
        <MenuItem>Download</MenuItem>
      </a>
      {/* <MenuItem onClick={() => openDialog(<AddRevision doc={doc} closeDialog={closeDialog} />)}>Add Revision</MenuItem> */}
      {currentUser.companyAdmin || currentUser.accessRights.docManager <= 1 ? (
        <Link to={`/doc-manager/revisions/${doc.id}/${doc.docCode}`}>
          <MenuItem>Revisions</MenuItem>
        </Link>
      ) : null}

      <MenuItem disabled={loading} onClick={generatePDF}>
        Produce PDF
      </MenuItem>
      <MenuItem disabled={loading} onClick={getHyperlink}>
        Get Link
      </MenuItem>
      {currentUser.companyAdmin || currentUser.accessRights.docManager <= 1 ? (
        // <MenuItem onClick={() => openDialog(<InterestedParties doc={doc} isRevision={false} revId={null}/>)}>Approvals</MenuItem>
        <Link to={`/doc-approvers/${doc.id}`}>
          <MenuItem>Approvers</MenuItem>
        </Link>
      ) : null}
      {/* <MenuItem onClick={() => openDialog(<Subscribers doc={doc} isRevision={false} revId={null}/>)}>Interested Parties</MenuItem> */}
      {currentUser.companyAdmin || currentUser.accessRights.docManager <= 1 ? (
        // <MenuItem onClick={() => setIntPartiesOpen(true)}>Interested Parties</MenuItem>
        <Link to={`/doc-int-parties/${doc.id}`}>
          <MenuItem>Interested Parties</MenuItem>
        </Link>
      ) : null}
      <Link to={`/doc-supdocs/${doc.id}`}>
        <MenuItem>Documents Submitted</MenuItem>
      </Link>
      {currentUser.companyAdmin ? (
        <MenuItem onClick={() => setDeleteOpen(true)}>Make Obsolete</MenuItem>
      ) : null}

      <PdfView open={pdfViewOpen} closeDialog={closePdfView} pdfFile={pdf} />
      <DeleteConfirm
        collection={"documents"}
        docId={doc.id}
        open={deleteOpen}
        closeDialog={() => setDeleteOpen(false)}
      />
    </div>
  );
}
