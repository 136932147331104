import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { textFieldProps } from "../../../shared/constants";
import { useDb } from "../../../../contexts/DatabaseContext";

export default function EditQuestion({ open, close, question }) {
  const { updateDocFromPath } = useDb();
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    title: question.title,
  });

  const handleClose = () => {
    setLoading(false);
    close();
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await updateDocFromPath(question.path, { title: data.title });
      handleClose();
    } catch (err) {
      window.alert(err.message);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      TransitionProps={{
        onEnter: () => setData({ title: question.title })
      }}>
      <DialogTitle>Edit Question</DialogTitle>
      <DialogContent>
        <TextField
          {...textFieldProps}
          label="Question"
          value={data.title}
          multiline
          rows={3}
          onChange={(e) => setData({ ...data, title: e.target.value })}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={loading}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
