import { Avatar, Button, Card, CardContent, Container, Grid, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography } from '@mui/material'
import { ArrowBack, ArrowUpward } from '@mui/icons-material'
import React, { useState } from 'react'
import { textFieldProps } from '../../shared/constants'
import { useHistory } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'

export default function AddRiskProcess() {
    const history = useHistory()
    const { addRiskProcess } = useDb()

    const [state, setState] = useState({
        loading: false,
        error: null,
        data: {
            steps: []
        }
    })

    const handleChange = (e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleAdd = () => {
        let steps = state.data.steps
        steps.push(state.data.step)
        setState({
            ...state,
            data: {
                ...state.data,
                step: '',
                steps
            }
        })
    }

    const handleSubmit = async () => {
        setState({
            ...state,
            error: null,
            loading: true
        })
        try {
            await addRiskProcess(state.data)
            history.goBack()
        }
        catch(err) {
            setState({
                ...state,
                error: err.message,
                loading: false
            })
        }
    }

    return (
        <div>
            
            <Container>
                <h1>Add Process</h1>
                <div className="back-link">
                    <Button
                        color="primary"
                        variant="contained"
                        startIcon={<ArrowBack />}
                        onClick={() => history.goBack()}
                    >
                        Back
                    </Button>
                </div>
                <TextField
                    {...textFieldProps}
                    label="Process Name"
                    onChange={handleChange}
                    name="name"
                />
                <br/>
                <Card>
                    <CardContent>
                        <Typography>Steps:</Typography>
                        <List>
                            {
                                state.data.steps && state.data.steps.map((step, i) => (
                                    <ListItem key={i}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                {i+1}
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText>{step}</ListItemText>
                                    </ListItem>
                                ))
                            }
                        </List>
                    </CardContent>
                </Card>
                <br/>

                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={10}>
                                <TextField
                                    {...textFieldProps}
                                    label="Add Step ..."
                                    onChange={handleChange}
                                    name="step"
                                    value={state.data.step}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Button
                                    variant="contained"
                                    startIcon={<ArrowUpward />}
                                    fullWidth
                                    size="large"
                                    color="primary"
                                    onClick={handleAdd}
                                    disabled={!state.data.step}
                                >
                                    Add
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <br/>
                {
                    state.error && <Typography color="secondary">{state.error}</Typography>
                }
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    onClick={handleSubmit}
                    disabled={
                        state.loading ||
                        !state.data.steps ||
                        !state.data.name 
                    }
                >
                    Create Process
                </Button>
            </Container>
        </div>
    )
}
