import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Switch,
    TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDb } from '../../../../contexts/DatabaseContext'
import { textFieldProps } from '../../../shared/constants'

const useStyles = makeStyles((theme) => ({
    header: {
        background: theme.palette.primary.main
    }
}))

export default function EditText({open, close, checklistId, sectionId, fieldData}) {
    const classes = useStyles()
    const { updateChecklistField } = useDb()
    const [state, setState] = useState({...fieldData.data})
    const [actionState, setActionState] = useState({ loading: false, error: null })

    useEffect(() => {
      setState({...fieldData.data})
    }, [open])

    const handleClose = () => {
        setActionState({ loading: false, error: null })
        setState({})
        close()
    }

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const handleSwitch = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.checked
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setActionState({ loading: true, error: null })
        try {
            await updateChecklistField(checklistId, sectionId, fieldData.id, 'text', state)
            handleClose()
        }
        catch(err) {
            setActionState({ loading: false, error: err.message })
        }
    }
 
    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle className={classes.header}>Edit Text Field</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        defaultValue={state.name}
                        {...textFieldProps}
                        onChange={handleChange}
                        name="name"
                        label="Field Name"
                    />
                    <FormControlLabel
                        control={
                        <Switch
                            checked={state.required}
                            onChange={handleSwitch}
                            name="required"
                            color="primary"
                        />
                        }
                        label="Required"
                    />
                    { actionState.error ? <span className="wireline-error">{actionState.error}</span> : null }
                </DialogContent>
                <DialogActions>
                    <Button disabled={ actionState.loading } color="secondary" onClick={handleClose}>Cancel</Button>
                    <Button disabled={ actionState.loading } type="submit" color="primary" variant="contained">Update</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
