import {
  Avatar,
  Button,
  CardActions,
  CardHeader,
  LinearProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import MocRiskAssignTable from "./MocRiskAssignTable";
import { useDb } from "../../../contexts/DatabaseContext";
import { ArrowForward, Info } from "@mui/icons-material";

export default function MocRiskQuestionnaireAssignment({ moc, props }) {
  const { handleAdvance, title, handleUpdate } = props;
  const collectionPath = `moc/${moc.id}/responses`;
  const { getCompanyDoc } = useDb();
  const [responses, setResponses] = useState([]);
  const [riskActions, setRiskActions] = useState([]);

  const loadResponses = async () => {
    const r = await getCompanyDoc(collectionPath, "riskChecklist");
    setResponses(r.responses || []);
  };

  useEffect(() => {
    return loadResponses();
  }, [moc.id]);

  const handleSubmit = async () => {
    let filteredArr = riskActions.filter((value) => value !== undefined);
    await handleUpdate({ riskActions: filteredArr });
    handleAdvance();
  };

  console.log(riskActions);

  return !responses ? (
    <LinearProgress />
  ) : (
    <div>
      <CardHeader
        title={title}
        avatar={
          <Avatar>
            <Info />
          </Avatar>
        }
      />
      <CardActions style={{ float: "right" }}>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          endIcon={<ArrowForward />}
        >
          Next
        </Button>
      </CardActions>
      <MocRiskAssignTable
        responses={responses}
        riskActions={riskActions}
        setRiskActions={setRiskActions}
      />
    </div>
  );
}
