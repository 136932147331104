import {
  Card,
  CardHeader,
  IconButton,
  CircularProgress,
  CardContent,
  Typography,
  CardActions,
  Button,
  ButtonGroup,
  Tooltip,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { amber } from "@mui/material/colors";
import { Apps, Edit, List, PictureAsPdf, Search, Warning } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from "prop-types";
import { useAuth } from "../../../contexts/AuthContext";
import Add from "@mui/icons-material/Add";
import { useDb } from "../../../contexts/DatabaseContext";
import OtpRegisterFilters from "./OtpRegisterFilters";
import { Link } from "react-router-dom";
import ROtpRegisterData from './ROtpRegisterData'
import CreateOTP from "../CreateOTP";
import IsoDataGrid from "../../shared/data-grid/IsoDataGrid";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import EditOTP from "../cards/EditOTP";
import FloatingHelpComponent from "../../help/FloatingHelpComponent";

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background,
      width: 500,
      position: "relative",
      minHeight: 200,
    },
    fab: {
      position: "fixed",
      bottom: theme.spacing(6),
      right: theme.spacing(7),
      backgroundColor: amber[500],
      "&:hover": {
        backgroundColor: amber[600],
      },
    },
    tab: {
      indicatorColor: theme.palette.primary,
    },
    icon: {
      color: theme.palette.primary,
    },
    tabPanel: {
      backgroundColor: theme.palette.main,
      padding: '0px 10px'
    },
    dialog: {
      backgroundColor: theme.palette.dialogBg,
    },
    search: {
      color: theme.palette.isotext,
    },
    chip: {
      marginRight: 5
    },
    filtersTab: {
      marginBottom: 20
    },
    loaderDiv: {
      width: '100%',
      textAlign: 'center'
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <CardContent className="iso-card-content">
            <Typography component={"span"}>{children}</Typography>
          </CardContent>
        )}
      </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
}



export default function RActionRegister() {
    const classes = useStyles()
    const { getROtp, generateOtpRegisterPdf, generateSingleOtpPdf } = useDb()
    const [otps, setOtps] = useState([])
    const [loading, setLoading] = useState(false)
    const [listView, setListView] = useState(false)
    const { currentUser } = useAuth()
    const [createOpen, setCreateOpen] = useState(false)
    const [lastDoc, setLastDoc] = useState(null)
    const divScrollRef = useRef()
    // const bottomReached = useOnScreen(divScrollRef, "0px")
    const [gettingData, setGettingData] = useState(false)
    // Edits
    const [editOpen, setEditOpen] = useState(false)

    const [filters, setFilters] = useState({
      department: {
        fieldName: 'department.name',
        value: null
      },
      criteria: {
        fieldName: 'criteria.name',
        value: null
      },
      loggedBy: {
        fieldName: 'loggedBy.email',
        value: null
      }
    })

    const closeCreate = () => {
      setCreateOpen(false);
    };

    const getPdfReport = async () => {
      setLoading(true);
      try {
        await generateOtpRegisterPdf(filters);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    const updateFilterState = (filter) => {
      setFilters({
        ...filters,
        [filter.key]: filter.data
      })
    }

    // First Load or when page refreshes
    useEffect(async () => {
      const data = await getROtp()
      setOtps(data.data)
    }, [])

    const openEditView = () => {
      setEditOpen(true)
    }

    const getPdf = async (otp) => {
      setLoading(true)
      try{
          await generateSingleOtpPdf(otp).then(() => {
              setLoading(false)
          })
      }
      catch(err) {
          setLoading(false)
      }
  }

    // The below use effect fires every time the filters state changes to get new documents
    useEffect(async () => {
      setGettingData(true)
      console.log(filters)
      const data = await getROtp(filters)
      setOtps(data.data)
      console.log(data)
      setLastDoc(data.lastDoc)
      setGettingData(false)
    }, [filters])

    // Data Grid Setup

    const rows = otps && otps.map((o, i) => ({
      id: o.id,
      data: o,
      otpNumber: `Objective #${o.otpNumber}`,
      loggedBy: o.loggedBy?.email,
      dateCreated: moment(o.timestamp.toDate()).format('DD/MM/YYYY'),
      department: o.department?.name,
      criteria: o.criteria?.name,
      defineObj: o.defineObjectives
    }))

    const columns = [
      {
        field: 'actions',
        headerName: '',
        width: 130,
        renderCell: (params) => {
          return <>
            <ButtonGroup>
              <Tooltip title='Edit'>
                <Link to={`/edit-objective/${params.row.id}`}>
                  <IconButton size="large">
                    <Edit />
                  </IconButton>
                </Link>
              </Tooltip>
              <Tooltip title='Produce PDF'>
                <IconButton onClick={() => getPdf(params.row.data)} size="large"><FontAwesomeIcon icon={faFilePdf} /></IconButton>
              </Tooltip>
            </ButtonGroup>
            <EditOTP otp={params.row.data} open={editOpen} closeDialog={() => setEditOpen(false)} />
          </>;
        }
      },
      {
        field: 'otpNumber',
        headerName: 'Objective',
        width: 150
      },
      {
        field: 'loggedBy',
        headerName: 'Logged By',
        width: 200,
      },
      {
        field: 'dateCreated',
        headerName: 'Date Created',
        width: 200,
      },
      {
        field: 'department',
        headerName: 'Department',
        width: 200,
      },
      {
        field: 'criteria',
        headerName: 'Criteria',
        width: 200,
      },
      {
        field: 'defineObj',
        headerName: 'Define Objectives',
        width: 200,
      },
      {
        field: 'programs',
        headerName: 'Programs',
        width: 350,
        renderCell: (params) => {
          return(
            <div>
              {
                params.row.data.targetCount < 1
                ?
                  <Link to={`/otp/${params.row.data.id}/targets`}>
                    <Button variant="outlined" color="secondary" startIcon={<Warning />}>No Targets Set</Button>
                  </Link>
                :
                <>
                  <Link to={`/otp/${params.row.data.id}/targets`}>
                    <Button variant="contained" size="small" color="primary">View Targets ({params.row.data.targetCount})</Button>
                  </Link>
                  {
                      !params.row.data.programCount || params.row.data.programCount < 1 
                      ?
                      <Link to={`/otp/${params.row.data.id}/targets`}>
                        <Button size="small" variant="outlined" color="secondary" startIcon={<Warning />}>No Programs Set</Button>
                      </Link>
                      :
                      <span style={{marginLeft: '5px'}} className="iso-small">{`${params.row.data.closedPrograms ? params.row.data.closedPrograms : 0 } / ${params.row.data.programCount} Programs Closed (${((params.row.data.closedPrograms ? params.row.data.closedPrograms / params.row.data.programCount : 0) * 100).toFixed(2)}%)`}</span>
                  }
                </>
              }
            </div>
          )
        }
      }
    ]

    return (
      <div>
          <Card>
              <CardHeader
                  className={classes.tabPanel}
                  title={
                  <>
                      {loading ? (
                      <CircularProgress />
                      ) : (
                      <IconButton className={classes.icon} onClick={getPdfReport} size="large">
                          <PictureAsPdf />
                      </IconButton>
                      )}
                      <IconButton onClick={() => setCreateOpen(true)} className={classes.icon} size="large">
                          <Add />
                      </IconButton>
                      <IconButton
                        onClick={() => setListView(!listView)}
                        className={classes.icon}
                        size="large">
                      {listView ? (
                          <List />
                      ) : (
                          <Apps />
                      )}
                      </IconButton>
                    
                      <Link to="/otp-manager/search">
                        <IconButton className={classes.icon} size="large">
                            <Search />
                        </IconButton>
                      </Link>
                  </>
                  }
                  
              />
              <div className={classes.tabPanel}>
                <span className="iso-small">Filters: </span>
                <OtpRegisterFilters
                  updateFilters={updateFilterState}
                />
                </div>
              <CardContent>
               <IsoDataGrid
                data={rows}
                columns={columns}
               />
              </CardContent>
              <CardActions>
                {
                  gettingData
                  ?
                  <div className={classes.loaderDiv}>
                    <CircularProgress />
                  </div>
                  :
                  null
                }
                <div ref={divScrollRef}></div>
              </CardActions>
          </Card>

          <CreateOTP open={createOpen} closeDialog={closeCreate} />
          <FloatingHelpComponent
              name="Defining new Objectives with Targets" 
              videoPath="otp_manager"
              videoIndex={3}
          />
      </div>
    );
}
