import { ButtonGroup, IconButton } from '@mui/material'
import { Delete, Edit } from '@mui/icons-material'
import React from 'react'
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid'

export default function IsoAgencyDataGrid({data, handleDelete, handleEdit}) {

  const rows = data.map(d => ({
    id: d.id,
    isoAgency: d.isoAgency,
    isoClass: d.isoClass,
    agency: d
  }))

  const columns = [
    {
      field: 'isoAgency',
      headerName: 'Iso Agency',
      width: 300
    },
    {
      field: 'isoClass',
      headerName: 'Iso Class',
      width: 300
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => {
        return (
          <ButtonGroup>
            <IconButton onClick={() => handleEdit(params.row.agency)} size="large"><Edit /></IconButton>
            <IconButton onClick={() => handleDelete(params.row.agency)} size="large"><Delete /></IconButton>
          </ButtonGroup>
        );
      }
    }
  ]

  return (
    <div>
      <IsoDataGrid 
        data={rows}
        columns={columns}
      />
    </div>
  )
}
