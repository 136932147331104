import { Button, Dialog, DialogActions, DialogContent, IconButton } from '@mui/material'
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import moment from 'moment'
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid'
import { Delete } from '@mui/icons-material'
import { useAuth } from '../../../contexts/AuthContext'

export default function AuditSchedule() {
    const { GetSchedules, deleteSchedules } = useDb()
    const { currentUser } = useAuth()
    const schedules = GetSchedules()
    const [open, setOpen] = useState(false)
    const [id, setId] = useState(null)

    const handleDelete = async () => {
        await deleteSchedules(id)
        setOpen(false)
    }
    
    const rows = schedules && schedules.map(s => ({
        id: s.id,
        assignedTo: s.assignedTo.label,
        title: s.title,
        type: s.type,
        nextDate: moment(s.nextDate.toDate()).format('DD/MM/YYYY')
    }))

    const columns = [
        { field: 'assignedTo', headerName: 'Assigned To', width: 300 },
        { field: 'title', headerName: 'Title', width: 200 },
        { field: 'type', headerName: 'Type', width: 200 },
        { field: 'nextDate', headerName: 'Next Run', width: 200 },
        { 
            field: 'delete', 
            headerName: 'Delete', 
            width: 240,
            hide: !currentUser.companyAdmin,
            renderCell: (params) => {
                return (
                    <IconButton
                        onClick={() => {
                                setId(params.row.id)
                                setOpen(true)
                            }}
                        size="large">
                        <Delete />
                    </IconButton>
                );
            }
        }
    ]

    return (
        <div>
            <h1>Schedules</h1>
            <IsoDataGrid
                data={rows}
                columns={columns}
            />
            <Dialog open={open} fullWidth onClose={() => setOpen(false)}>
                <DialogContent>
                    <h2>Are you sure</h2>
                    <h4><i>This action cannot be undone</i></h4>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='primary' onClick={handleDelete}>Delete</Button>
                    <Button variant='outlined' color='secondary' onClick={() => setOpen(false)}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
