import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import DatabaseProvider from "../contexts/DatabaseContext";
import AdminMenu from "./menu/AdminMenu";
import MainMenu from "./menu/MainMenu";

// Theming
import {
  CircularProgress,
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material";
import { amber } from "@mui/material/colors";
import PublicRoutes from "./authenticate/PublicRoutes";

const lightTheme = createTheme({
  palette: {
    primary: amber,
    background: {
      paper: "#fff",
      default: "#fff",
    },
    icon: amber,
    cardBg: "#fff",
    isotext: "#000",
    type: "light",
    main: "#efefef",
    menuBg: "#fff",
    dialogBg: "#fff",
    mainMenuBg: "#efefef",
    // mainMenuBg: '#ffc008',
    appBarBg: "#ffc008",
    mainMenuIcon: "#11163a",
    mainMenuText: "#132652",
    isogreen: "#13c39a",
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});

const darkTheme = createTheme({
  palette: {
    primary: amber,
    background: {
      paper: "#122652",
      default: "#262b49",
    },
    icon: "#fff",
    cardBg: "#122652",
    isotext: "#fff",
    type: "dark",
    main: "#11163a",
    menuBg: "#383e68",
    dialogBg: "#2b3154",
    mainMenuBg: "#11163a",
    appBarBg: "#11163a",
    mainMenuIcon: "#ffc008",
    mainMenuText: "#fff",
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});

export default function Wrapper() {
  const { currentUser, setUserTheme } = useAuth();
  const [theme, setTheme] = useState(
    currentUser
      ? currentUser.theme === "light"
        ? lightTheme
        : darkTheme
      : lightTheme
  );
  const [loaded, setLoaded] = useState(false);
  console.log(currentUser);

  const toggleTheme = async (isLight) => {
    if (isLight) {
      setTheme(lightTheme);
      await setUserTheme(isLight);
    } else {
      setTheme(darkTheme);
      await setUserTheme(isLight);
    }
  };

  const firstLoad = async () => {
    const uTheme = currentUser ? currentUser.theme : "light";
    if (uTheme === "light") {
      setTheme(lightTheme);
      setLoaded(true);
    } else {
      setTheme(darkTheme);
      setLoaded(true);
    }
  };

  if (!loaded) {
    firstLoad();
    return <CircularProgress />;
  }
  return (
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme ? theme : lightTheme}>
          {currentUser && currentUser.uid ? (
            currentUser.companyId === "isoAdmin" ? (
              <DatabaseProvider coRef={currentUser.companyId}>
                <AdminMenu />
              </DatabaseProvider>
            ) : (
              <DatabaseProvider coRef={currentUser.companyId}>
                <MainMenu
                  toggleTheme={toggleTheme}
                  userTheme={theme === lightTheme ? true : false}
                />
              </DatabaseProvider>
            )
          ) : (
            <DatabaseProvider>
              <PublicRoutes />
            </DatabaseProvider>
          )}
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  );
}
