import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
} from "@mui/material";
import React from "react";
import ComponentCatalog from "../form-components/ComponentCatalog";
import { useDb } from "../../../../contexts/DatabaseContext";
import { textFieldProps } from "../../../shared/constants";

export default function AddQuestion({ open, close, path, questionLength }) {
  const { addStandardDoc } = useDb();
  const [loading, setLoading] = React.useState(false);
  const [questionTitle, setQuestionTitle] = React.useState("");

  const handleClose = () => {
    setQuestionTitle("");
    close();
  };

  const handlePick = async (comp) => {
    console.log(comp);
    setLoading(true);
    try {
      await addStandardDoc(`${path}/questions`, {
        ...comp.props,
        title: questionTitle,
        order: questionLength ? questionLength + 1 : 1,
      });
      handleClose();
    } catch (err) {
      window.alert(err.message);
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={close} maxWidth="md" fullWidth>
      <DialogTitle>Add Question</DialogTitle>
      <DialogContent>
        <TextField
          {...textFieldProps}
          label="Question Title"
          value={questionTitle}
          onChange={(e) => setQuestionTitle(e.target.value)}
        />
        {questionTitle && <ComponentCatalog handlePick={handlePick} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="error">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
