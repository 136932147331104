import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import moment from 'moment'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid'

export default function AssignToSession() {
    const { moduleId, employeeId, requestId } = useParams()
    const { GetSessionsOfModule, getEmployee, addAttendeesToSession, deleteTrainingRequest } = useDb()
    const history = useHistory()
    const sessions = GetSessionsOfModule(moduleId)
    const [employee, setEmployee] = useState(null)
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [selectedSession, setSelectedSession] = useState(null)
    const [state, setState] = useState({
        loading: false,
        error: null
    })
    
    useEffect(async () => {
        const e = await getEmployee(employeeId)
        setEmployee(e)
    })

    const handleClose = () => {
        setConfirmOpen(false)
        setSelectedSession(null)
        setState({
            loading: false,
            error: null
        })
    }

    const handleOpen = (session) => {
        setSelectedSession(session)
        setConfirmOpen(true)
    }

    const handleConfirm = async () => {
        const data = { attendees: [employee] }

        setState({
            ...state,
            loading: true,
            error: null
        })
        try {
            await addAttendeesToSession(selectedSession.id, data)
            await deleteTrainingRequest(requestId)
            handleClose()
            history.goBack()
        }
        catch(err) {
            setState({
                ...state,
                loading: false,
                error: err.message
            })
        }
    }

    const rows = sessions && sessions.map(s =>({
        id: s.id,
        moduleName: s.module.name,
        facilitator: s.facilitator.label,
        startTime: moment(s.startTime.toDate()).format('MMMM Do YYYY, h:mm:ss a'),
        endTime: moment(s.endTime.toDate()).format('MMMM Do YYYY, h:mm:ss a'),
        site: s.siteLabel,
        venue: s.venue.name
    }))

    const columns = [
        { field: 'moduleName', headerName: 'Module' , width: 200 },
        { field: 'facilitator', headerName: 'Facilitator' , width: 150 },
        { field: 'startTime', headerName: 'Starts', width: 240 },
        { field: 'endTime', headerName: 'Ends', width: 240 },
        { field: 'site', headerName: 'Site', width: 120 },
        { field: 'venue', headerName: 'Venue', width: 120 },
        { 
            field: 'action', 
            headerName: 'Assign', 
            width: 140,
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                        <IconButton onClick={() => handleOpen(params.row)} size="large">
                            <ArrowForward />
                        </IconButton>
                     </div>
                );
            }
        }
    ]
    return (
        <div>
            <h1>Assign Requested Training</h1>
            <div className="back-link">
                <Button color="primary" variant="contained" startIcon={<ArrowBack />} onClick={() => history.goBack()}>Back</Button>
            </div>
            <h3>Available Sessions</h3>
            <IsoDataGrid
                data={rows}
                columns={columns}
            />
            
            <Dialog width="sm" fullWidth open={confirmOpen} onClose={handleClose}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>
                    <Typography>
                        You are about to assign {employee && employee.firstName} {employee && employee.lastName} to the following training session:
                    </Typography>
                    <Typography>
                        {selectedSession && selectedSession.moduleName}
                    </Typography>
                    <Typography>
                        Starts: {selectedSession && selectedSession.startTime}
                    </Typography>
                    <Typography>
                        Ends: {selectedSession && selectedSession.endTime}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    { state.error ? <span className="iso-error">{state.error}</span> : null }
                    <Button disabled={state.loading} color="secondary" onClick={handleClose}>Cancel</Button>
                    <Button disabled={state.loading} color="primary" variant="contained" onClick={handleConfirm}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
