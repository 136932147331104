import { Button, Dialog, DialogActions, DialogContent, Slider, Typography } from '@mui/material'
import { Save } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'

export default function EditControlsDialog({open, data, handleClose, riskSettings, currentUser}) {

  const { updateCriticalThreshold } = useDb()
  const [state, setState] = useState({...data})
  const [lowMin, setLowMin] = useState(1)
  const [low, setLow] = useState(null)
  const [med, setMed] = useState(null)

  const handleChange = (name, val) => {
    setState({
      ...state,
      [name]: parseInt(val)
    })
  }

  const handleLowChange = (e, value) => {
    handleChange('low', value)
    setLowMin(value)
    setLow(value)
  }

  const handleMedChange = (e, value) => {
    handleChange('med', value)
    setMed(value)
  }

  const handleSubmit = async () => {
    try {
      await updateCriticalThreshold(state.low, state.med, currentUser.riskSettings.riskMax)
      .then(() => {
        handleClose()
      })
    } catch (error) {
      console.log(error)
    }
  } 

  useEffect(() => {
    setState(data ? data : {})
  }, [open])

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent>
          <Typography>Low: {low ? low : data.low}</Typography>
          <br />
          <Slider
            defaultValue={1}
            min={1}
            max={riskSettings.riskMax}
            marks
            name='low'
            step={1}
            valueLabelDisplay="auto"
            onChange={handleLowChange}
          />
          <Typography>Medium: {med ? med : data.med}</Typography>
          <br />
          <Slider
            defaultValue={2}
            min={lowMin + 1}
            max={riskSettings.riskMax - 1}
            marks
            name='med'
            step={1}
            valueLabelDisplay="auto"
            onChange={handleMedChange}
          />
          <Typography>High: {riskSettings.riskMax}</Typography>
          <br />
          <Slider
            value={riskSettings.riskMax}
            marks
            step={1}
            valueLabelDisplay="on"
            disabled
            min={riskSettings.riskMax - 1}
            max={riskSettings.riskMax}
          />
        </DialogContent>
        <DialogActions className='iso-right'>
          <Button
            variant='contained'
            color='primary'
            startIcon={<Save />}
            onClick={() => handleSubmit(state)}
          >Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
