import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Save } from '@mui/icons-material'
import React from 'react'
import { useDb } from '../../../../contexts/DatabaseContext'
import { textFieldProps } from '../../../shared/constants'

const useStyles = makeStyles((theme) => ({
    header: {
        background: theme.palette.primary.main
    }
}))

export default function AddProduct({ open, close }) {
    const classes = useStyles()
    const { createProduct } = useDb()

    const [state, setState] = React.useState({
        loading: false,
        error: null,
        data: {}
    })
    
    const handleClose = () => {
        setState({
            loading: false,
            error: null,
            data: {}
        })
        close()
    }

    const handleChange = (e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setState({
            ...state,
            loading: true,
            error: null
        })
        try {
            await createProduct(state.data)
            handleClose()
        }
        catch(err) {
            setState({
                ...state,
                loading: false,
                error: err.message
            })
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle className={classes.header}>Add Product / Service</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        {...textFieldProps}
                        name="name"
                        required
                        label="Product / Service Name"
                        onChange={handleChange}
                    />
                    <TextField
                        {...textFieldProps}
                        name="description"
                        required
                        label="Description"
                        onChange={handleChange}
                    />
                    <TextField
                        {...textFieldProps}
                        name="sku"
                        label="SKU (Optional)"
                        onChange={handleChange}
                    />
                    { 
                        state.error && <Typography color="secondary">{state.error}</Typography>
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        onClick={handleClose}
                        disabled={state.loading}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        startIcon={<Save />}
                        type="submit"
                        disabled={state.loading}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </form>
            
        </Dialog>
    )
}
