import React from 'react'
import { useAuth } from '../../contexts/AuthContext';

export default function DataStudioDashboard() {
    const { currentUser } = useAuth()
    var params = {
        "ds2.company": currentUser.companyId,
        "ds75.company": currentUser.companyId,
        "ds9.company": currentUser.companyId,
        "ds0.company": currentUser.companyId,
        "ds77.company": currentUser.companyId,
        "ds10.company": currentUser.companyId,
        "ds11.company": currentUser.companyId,
        "ds48.company": currentUser.companyId,
        "ds3.company": currentUser.companyId,
        "ds96.company": currentUser.companyId,
        "ds5.company": currentUser.companyId,
    }
    var paramsAsString = JSON.stringify(params);
    var encodedParams = encodeURIComponent(paramsAsString)
    return (
        <div>
            <iframe width="1200" height="2100" src={`https://datastudio.google.com/embed/reporting/2330858e-db3b-469d-bffb-0b0b801a2414/page/p_m71lhnnsxc?params=${encodedParams}`} frameBorder="0" allowfullscreen></iframe>
        </div>
    )
}
