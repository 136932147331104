import { Button } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

export default function DataManagement() {
    return (
        <div>
            <Link to="/training-comp-data">
                <Button
                    variant="contained"
                    color="primary"
                >
                    Training Competency
                </Button>
            </Link>
        </div>
    )
}
