import { Box, Button, Card, CardActions, CardContent, Chip, CircularProgress, LinearProgress, Typography } from '@mui/material';
import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { storage } from '../../firebase'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import Dropzone from 'react-dropzone'

function LinearProgressWithLabel(props) {
    return (
    <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
            props.value,
        )}%`}</Typography>
        </Box>
    </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

export default function BulkFileUploader({ userId, getFiles, setBusy }) {

    const storageRef = storage.ref('users').child(userId)
    const [isUploading, setIsUploading] = useState(false)
    const [progress, setProgress] = useState(0)
    const [error, setError] = useState('')
    const [files, setFiles] = useState([]);

    // New File Methods
    const [fileNames, setFileNames] = useState([]);
    const [urls, setUrls] = useState([])
    const [fileData, setFileData] = useState([])

    const handleUploadStart = async () => {
        setIsUploading(true)
        setProgress(0)
        setBusy(true)
        for(let i = 0; i < files.length; i++) {
            await uploadFile(files[i])
        }
        getFiles(fileData)
        setFileNames([])
        setUrls([])
        setFileData([])
        setIsUploading(false)
        setBusy(false)
    }

    const uploadFile = async (file) => {
        return new Promise(async (res, rej) => {
            setIsUploading(true)
            const fileName = Math.random().toString(36).slice(-10)
            const uploadTask = storageRef.child(`documents/${fileName}/${file.name}`).put(file);
            uploadTask.on(
                'state_changed',
                snapshot => {
                    var upProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setProgress(upProgress)
                },
                error => {
                    setError('Error during File Upload')
                    rej('Error during File Upload')
                    setIsUploading(false)
                },
                () => {
                    uploadTask.snapshot.ref.getDownloadURL().then(url => {
                        const stateUrls = urls
                        const stateFileData = fileData
                        setProgress(100)
                        setIsUploading(false)
                        stateUrls.push(url)
                        stateFileData.push({
                            url,
                            fileName: file.name
                        })
                        setFileData(stateFileData)
                        res()
                    })
                }
            )
        })
    }

    const handleDrop = acceptedFiles => {
        setError(null)
        console.log(acceptedFiles)
        setFileNames(acceptedFiles.map(file => file.name));
        setFiles(acceptedFiles.map(file => file))
    }
    

    const handleChipDelete = (e, fName) => {
        const fNames = fileNames.filter(item => item !== fName)
        const filesObj = files.filter(obj => obj.name !== fName)
        setFileNames(fNames)
        setFiles(filesObj)
    }
 
    return (
        <Card>
            <CardContent>
                Upload Multiple Files: (100 MB Max per file)<br/>
                
                <Dropzone
                    onDrop={handleDrop}
                    minSize={1024}
                    maxSize={104857600}
                    onDropRejected={() => setError('Exceeds max file size')}
                >
                    {({
                    getRootProps,
                    getInputProps,
                    isDragActive,
                    isDragAccept,
                    isDragReject
                    }) => {
                    const additionalClass = isDragAccept
                        ? "accept"
                        : isDragReject
                        ? "reject"
                        : "";

                    return (
                        <div
                        {...getRootProps({
                            className: `dropzone ${additionalClass}`
                        })}
                        >
                        <input {...getInputProps()} />
                        <span>
                            {isDragActive ? "📂" : "📁"}
                        </span>
                        <p>Drag & drop files, or click to select files</p>
                        </div>
                    );
                    }}
                </Dropzone>
                <div>
                    { error ? <span className="iso-error">Error: {error}</span> : null }<br/>
                    <strong>Files:</strong><br/>
                    {fileNames.map((fileName, index) => (
                        <Chip 
                            label={fileName}
                            onDelete={e => handleChipDelete(e, fileName)}
                            key={fileName}
                        />
                    ))}
                </div>
                <br/>
                { isUploading ? <div style={{ width: '100%'}}><LinearProgress/><br/></div> : null }
            </CardContent>
            <CardActions>
                
                <Button fullWidth disabled={!fileData || isUploading } onClick={handleUploadStart} startIcon={<FontAwesomeIcon icon={faUpload}/>} variant="contained" color="primary">Upload</Button>
            </CardActions>
        </Card>
    )
}
