import React from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { IconButton } from '@mui/material'
import { Search } from '@mui/icons-material'
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid'
import FloatingHelpComponent from '../../help/FloatingHelpComponent'

export default function TrainingRequests() {
    const { GetTrainingRequests } = useDb()
    const requests = GetTrainingRequests()

    const rows = requests && requests.map(r =>({
        id: r.id,
        employee: `${r.employee.firstName} ${r.employee.lastName}`,
        module: r.module.name,
        requestedBy: `${r.requestedBy.firstName} ${r.requestedBy.lastName}`,
        requestDate: moment(r.timestamp.toDate()).format('DD/MM/YYYY'),
        moduleId: r.module.id,
        employeeId: r.employee.id
    }))

    const columns = [
        { field: 'employee', headerName: 'Employee' , width: 200 },
        { field: 'module', headerName: 'Module' , width: 200 },
        { field: 'requestedBy', headerName: 'Requested By', width: 200 },
        { field: 'requestDate', headerName: 'Request Date', width: 200 },
        { 
            field: 'action', 
            headerName: 'Action', 
            width: 140,
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                        <Link to={`/training-manager/assign-request/${params.row.moduleId}/${params.row.employeeId}/${params.row.id}`}>
                            <IconButton size="large"><Search /></IconButton>
                        </Link>
                     </div>
                );
            }
        }
    ]

    return (
        <div>
            <h1>Training Requests</h1>
            <IsoDataGrid
                data={rows}
                columns={columns}
            />

            <FloatingHelpComponent
                name="Training Requests" 
                videoPath="training_manager"
                videoIndex={9}
            />
        </div>
    )
}
