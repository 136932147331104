import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material'
import { textFieldProps, docCategories, selectFieldProps, extendedDocCategories } from '../../shared/constants'
import Select from 'react-select'
import { useDb } from '../../../contexts/DatabaseContext'
import { useAuth } from '../../../contexts/AuthContext'
import { Save } from '@mui/icons-material'

export default function EditDocType({ docType, open, closeDialog }) {
    const { updateDocType } = useDb()
    const { currentUser } = useAuth()

    const [state, setState] = useState({
        category: docType.category,
        code: docType.code,
        type: docType.type
    })

    // Action State
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [updated, setUpdated] = useState(false)
    
    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
        setUpdated(true)
    }

    const handleSelect = (v) => {
        setState({
            ...state,
            category: v.value
        })
        setUpdated(true)
    }

    const handleClose = () => {
        setUpdated(false)
        setLoading(false)
        setError(null)
        closeDialog()
    }

    const handleSubmit = async () => {
        setError(null)
        setLoading(true)
        try {
            await updateDocType(docType.id, state).then(() => {
                handleClose()
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogContent>
                <TextField 
                    {...textFieldProps}
                    onChange={handleChange}
                    label="Document Type"
                    name="type"
                    value={state.type}
                />
                <TextField 
                    {...textFieldProps}
                    onChange={handleChange}
                    label="Code (Optional)"
                    name="code"
                    value={state.docType}
                />
                Category: {state.category}
                <Select
                    {...selectFieldProps}
                    onChange={handleSelect}
                    options={currentUser.extendedDocs ? extendedDocCategories : docCategories}
                    placeholder="Change Category"
                />
            </DialogContent>
            <DialogActions>
                { error ? <span className="iso-error">{error}</span> : null }
                <Button disabled={loading} color="secondary" onClick={handleClose}>Cancel</Button>
                <Button disabled={ loading || !updated } variant="contained" color="primary" startIcon={<Save />} onClick={handleSubmit}>Update</Button>

            </DialogActions>
        </Dialog>
    )
}
