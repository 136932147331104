import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React, { useState } from "react";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { useDb } from "../../../contexts/DatabaseContext";
import { Save } from "@mui/icons-material";
import Select from "react-select";
import { selectFieldProps } from "../../shared/constants";

export default function EditSupDoc({ supDoc, open, closeDialog }) {
  const { editSupportingDoc, GetSubfoldersDropdown, removeFolderFromSupDoc } =
    useDb();
  const folders = GetSubfoldersDropdown(supDoc.parentDocId);

  // Form State
  const [state, setState] = useState({
    expires: supDoc.expires,
  });
  const [expiryDate, setExpiryDate] = useState(
    supDoc.expiryDate ? supDoc.expiryDate.toDate() : new Date()
  );

  const initExpiryDate = supDoc.expiryDate
    ? supDoc.expiryDate.toDate()
    : new Date();
  // Action State
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleClose = () => {
    setLoading(false);
    setError(null);
    setState({});
    closeDialog();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    try {
      await editSupportingDoc(supDoc.parentDocId, supDoc.id, {
        ...state,
        expiryDate,
      }).then(() => {
        handleClose();
      });
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const handleFolderRemove = async () => {
    setError(null);
    setLoading(true);
    try {
      await removeFolderFromSupDoc(supDoc.parentDocId, supDoc.id).then(() => {
        setLoading(false);
      });
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <h3>Edit Document Submitted</h3>
          {supDoc.folder ? (
            <>
              Folder:{" "}
              <Chip
                label={supDoc.folder.label}
                size="small"
                onDelete={handleFolderRemove}
              />
              <br />
              <br />
            </>
          ) : null}
          <FormControlLabel
            control={
              <Checkbox
                checked={state.expires}
                onChange={() => setState({ ...state, expires: !state.expires })}
                name="expires"
              />
            }
            label="Expires"
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Edit Expiry Date"
              onChange={(d) => setExpiryDate(d.toDate())}
              defaultValue={dayjs(expiryDate)}
            />
          </LocalizationProvider>

          <br />
          <br />
          <Select
            options={folders}
            {...selectFieldProps}
            placeholder={"Move to Different Folder ..."}
            onChange={(v) => setState({ ...state, folder: v })}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} color={"secondary"} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={loading}
            color={"primary"}
            type={"submit"}
            variant={"contained"}
            startIcon={<Save />}
          >
            Update
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
