import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  TextField,
} from "@mui/material";
import { Info, Save } from "@mui/icons-material";
import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDb } from "../../../../../contexts/DatabaseContext";
import { selectFieldProps, textFieldProps } from "../../../../shared/constants";
import Select from "react-select";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import FileUploader from "../../../../shared/FileUploader";
import { useAuth } from "../../../../../contexts/AuthContext";

export default function CoAddRevision() {
  const { coId, docId } = useParams();
  const history = useHistory();
  const { currentUser } = useAuth();
  const { MgmtGetRosters, mgmtCreateRevision } = useDb();
  const rosters = MgmtGetRosters(coId);

  const [state, setState] = useState({});

  // Action State
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      await mgmtCreateRevision(coId, docId, state).then(() => {
        setState({});
        setLoading(false);
        history.push(`/companies/${coId}/doc-revisions/${docId}`);
      });
    } catch (err) {
      setError(err.message);
    }
  };

  const getUrl = (url) => {
    setState({
      ...state,
      docUrl: url,
    });
  };

  const getFileName = (f) => {
    console.log(f);
  };

  const getFileObj = (o) => {
    console.log(o);
  };

  return (
    <div>
      <Card>
        <CardHeader
          title="Manual Revision Creation"
          avatar={
            <Avatar>
              <Info />
            </Avatar>
          }
        />
        <form onSubmit={handleSubmit}>
          <CardContent>
            <FileUploader
              userId={currentUser.uid}
              getUrl={getUrl}
              getFilename={getFileName}
              getFileObj={getFileObj}
            />
            <br />
            <TextField
              required
              {...textFieldProps}
              label="Doc Code"
              name="docCode"
              onChange={handleChange}
            />
            <TextField
              required
              {...textFieldProps}
              label="Rev Code"
              name="revCode"
              onChange={handleChange}
            />
            <TextField
              required
              {...textFieldProps}
              label="Description"
              name="description"
              onChange={handleChange}
            />
            <Select
              options={rosters}
              placeholder="Requested By"
              onChange={(val) => setState({ ...state, requestedBy: val })}
              {...selectFieldProps}
              className="iso-select"
            />
            <Select
              options={rosters}
              placeholder="Revised By"
              isMulti
              onChange={(val) => setState({ ...state, revisedBy: val })}
              {...selectFieldProps}
              className="iso-select"
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Created Date"
                onChange={(d) => setState({ ...state, createdDate: d })}
                defaultValue={dayjs(state.createdDate)}
              />
            </LocalizationProvider>
            <br />
            <br />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Revision Date"
                onChange={(d) => setState({ ...state, revDate: d })}
                defaultValue={dayjs(state.revDate)}
              />
            </LocalizationProvider>
            <br />
            <br />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Target Revision Date"
                onChange={(d) => setState({ ...state, targetRevDate: d })}
                defaultValue={dayjs(state.targetRevDate)}
              />
            </LocalizationProvider>
          </CardContent>
          <CardActions>
            {error ? <span className="iso-error">{error}</span> : null}
            <Button
              disabled={
                loading ||
                !state.requestedBy ||
                !state.targetRevDate ||
                !state.createdDate ||
                !state.revDate ||
                !state.docUrl ||
                !state.docCode ||
                !state.revCode ||
                !state.description
              }
              fullWidth
              color="primary"
              type="submit"
              variant="contained"
              startIcon={<Save />}
            >
              Submit
            </Button>
          </CardActions>
        </form>
      </Card>
    </div>
  );
}
