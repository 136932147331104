import { Button, Card, CardContent, Grid, Typography } from '@mui/material'
import { ArrowForward, Phone } from '@mui/icons-material'
import { Alert } from '@mui/material';
import React from 'react'

export default function TrialExpiredUsers() {
    return (
        <div>
            <Card>
                <CardContent>
                    <Typography variant='h4'>Free Trial has expired</Typography>
                    <br/>
                    <Alert severity="info">
                        It seems you have been using our service for more than 14 days. Please contact your administrator to continue using our service.
                    </Alert>
                </CardContent>
            </Card>
        </div>
    )
}
