import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material'
import { Save } from '@mui/icons-material'
import React, { useState } from 'react'
import Select from 'react-select'
import { useDb } from '../../../contexts/DatabaseContext'
import { selectFieldProps, textFieldProps } from '../../shared/constants'

export default function EditOTP({ open, closeDialog, otp }) {
    const { GetSites, GetDepartments, GetCriteria, updateOTP } = useDb()
    const sites = GetSites()
    const departments = GetDepartments()
    const criteria = GetCriteria()

    // Action State
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const [state, setState] = useState({
        criteria: otp.criteria,
        defineObjectives: otp.defineObjectives,
        department: otp.department,
        site: otp.site
    })

    const handleClose = () => {
        setLoading(false)
        setError(null)
        closeDialog()
    }

    const handleSubmit = async () => {
        setLoading(true)
        setError(null)
        try {
            await updateOTP(otp.id, state).then(() => {
                handleClose()
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogContent>
                Current Department: {state.department && state.department.name}
                <Select
                    options={departments}
                    {...selectFieldProps}
                    className="iso-select"
                    placeholder="Change Department"
                    onChange={val => setState({
                        ...state,
                        department: val.value
                    })}
                />
                Current Criteria: {state.criteria && state.criteria.name}
                <Select
                    options={criteria}
                    {...selectFieldProps}
                    className="iso-select"
                    placeholder="Change Criteria"
                    onChange={val => setState({
                        ...state,
                        criteria: val.value
                    })}
                />
                Current Site: {state.site && state.site.name}
                <Select
                    options={sites}
                    {...selectFieldProps}
                    className="iso-select"
                    placeholder="Change Site"
                    onChange={val => setState({
                        ...state,
                        site: val.value
                    })}
                />
                <TextField
                    {...textFieldProps}
                    label="Defined Objectives"
                    value={state.defineObjectives}
                    onChange={e => setState({
                        ...state,
                        defineObjectives: e.target.value
                    })}
                    multiline 
                    rows={3} 
                />
            </DialogContent>
            <DialogActions>
                { error ? <span className="iso-error">{error}</span> : null }
                <Button disabled={ loading } color="secondary" onClick={handleClose} >Cancel</Button>
                <Button disabled={ loading } color="primary" onClick={handleSubmit} startIcon={<Save />} variant="contained">Save Changes</Button>

            </DialogActions>
        </Dialog>
    )
}
