import { Button, IconButton } from '@mui/material'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import React from 'react'
import { useDb } from '../../../../contexts/DatabaseContext'
import IsoDataGrid from '../../../shared/data-grid/IsoDataGrid'
import { useHistory, Link } from 'react-router-dom'
import moment from 'moment'

export default function ROverdueDocs() {
    const { GetOverdueDocs } = useDb()
    const docs = GetOverdueDocs()
    const history = useHistory()

    const columns = [
        { 
            field: 'action', 
            headerName: 'Detail', 
            width: 140,
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                        <Link to={`/doc-manager/doc-detail/${params.row.id}`}>
                            <IconButton size="large">
                                <ArrowForward />
                            </IconButton>
                        </Link>
                     </div>
                );
            }
        },
        { field: 'category', headerName: 'Category' , width: 200 },
        { field: 'docCode', headerName: 'Code', width: 200 },
        { field: 'docName', headerName: 'Name', width: 200 },
        { field: 'docType', headerName: 'Type', width: 200 },
        { field: 'department', headerName: 'Department', width: 200 },
        { field: 'owner', headerName: 'Owner', width: 200 },
        { field: 'site', headerName: 'Site', width: 200 },
        { field: 'targetRevDate', headerName: 'Target Rev.', width: 200 }
    ]

    const rows = docs && docs.map(doc  => ({
        id: doc.id,
        targetRevDate: moment(doc.targetRevDate.toDate()).format('DD/MM/YYYY'),
        category: doc.category,
        department: doc.department.label,
        docCode: doc.docCode,
        docName: doc.docName,
        docType: doc.docType.label,
        owner: `${doc.owner.value.firstName} ${doc.owner.value.lastName}`,
        site: doc.site.label
    }))

    return (
        <div>
            <h1>Overdue Documents</h1>
            <div className="back-link">
                <Button onClick={() => history.goBack()} color="primary" variant="contained" startIcon={<ArrowBack />}>Back</Button>
            </div>
            <IsoDataGrid
                data={rows}
                columns={columns}
            />
        </div>
    )
}
