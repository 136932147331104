import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    Grid,
    Card,
    CardContent,
    TextField,
    List,
    Button,
    Divider,
    CircularProgress,
    DialogContent,
    Dialog,
    CardActions,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Pagination } from '@mui/material';
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import FloatingHelpComponent from '../../help/FloatingHelpComponent'
import AddDepartment from './AddDepartment'
import DepartmentDataGrid from './DepartmentDataGrid'
import DeptTile from './DeptTile'
import EditDepartment from './EditDepartment'

const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: theme.palette.cardBg
    }
}))

export default function DepartmentList() {
    const classes = useStyles()
    const { GetDepartmentList, deleteDepartment } = useDb()
    const depts = GetDepartmentList()
    const [dialogOpen, setDialogOpen] = useState(false)

    console.log(depts)
    
    const closeDialog = () => {
        setDialogOpen(false)
    }
    
    const [search, setSearch] = useState(null) 
    // Pagination
    const itemsPerPage = 10; // <- Not state but related to below pagination
    const [page, setPage] = useState(1);
    const [noOfPages] = useState(
        Math.ceil(depts.length / itemsPerPage)
    );

    const [error, setError] = useState(null)

    // Editing
    const [selectedDept, setSelectedDept] = useState(null)
    const [editOpen, setEditOpen] = useState(false)

    const handleEdit = (d) => {
        setSelectedDept(d)
        setEditOpen(true)
    }

    const handleDelete = async (d) => {
        try {
            await deleteDepartment(d.id)
        }
        catch(err) {
            setError(err.message)
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
                <Card className={classes.card}>
                    <CardContent>
                        <DepartmentDataGrid data={depts} handleDelete={handleDelete} handleEdit={handleEdit} />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={5}>
                <Card className={classes.card}>
                    <CardContent>
                        <Button onClick={() => setDialogOpen(true)} variant="contained" color="primary" startIcon={<FontAwesomeIcon icon={faPlusSquare} />}>Add Department</Button>
                    </CardContent>
                </Card>
                <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="sm" fullWidth>
                    <DialogContent>
                        <AddDepartment closeDialog={closeDialog}/>
                    </DialogContent>
                </Dialog>
            </Grid>

            {
                selectedDept
                ?
                <EditDepartment dept={selectedDept} open={editOpen} closeDialog={() => setEditOpen(false)} />
                :
                null
            }

            <FloatingHelpComponent
                name="Managing Departments" 
                videoPath="master_list"
                videoIndex={3}
            />
        </Grid>
    )
}
