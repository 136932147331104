import { faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, CircularProgress, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import Select from 'react-select'
import { selectFieldProps, textFieldProps } from '../../shared/constants.js'
import { Cancel, Edit, Save } from '@mui/icons-material'

export default function GroupsDetail({ group }) {
    const { GetGroupMembers, removeMemberFromGroup, GetRosters, addMembersToGroup, updateGroup } = useDb()
    const members = GetGroupMembers(group.id)
    const rosters = GetRosters()
    const [newMembers, setNewMembers] = useState([])
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const removeMember = async (memberId) => {
        try {
            await removeMemberFromGroup(group.id, memberId)
            window.location.reload()
        }
        catch(err) {
            setError(err.message)
        }
    }

    const addMembers = async () => {
        const memberArray = newMembers.map((m) => ({
            ...m.value
        }))
        try {
            await addMembersToGroup(group.id, memberArray)
            setNewMembers([])
        }
        catch(err)  {
            setError(err.message)
        }
    }

    // Editing
    const [groupName, setGroupName] = useState(group.name)
    const [editActive, setEditActive] = useState(false)
    const submitEdit = async () => {
        setLoading(true)
        setError(null)
        try {
            await updateGroup(group.id, {name: groupName}).then(() => {
                setLoading(false)
                setEditActive(false)
            })
        } 
        catch(err) {
            setError(err.message)
        }
    }
    const cancelEdit = () => {
        setGroupName(group.name)
        setEditActive(false)
    }

    return <>
        {
            editActive
            ?
            <TextField
                {...textFieldProps}
                label="Group Name"
                value={groupName}
                onChange={e => setGroupName(e.target.value)}
                InputProps={{
                    endAdornment: <>
                        <IconButton onClick={cancelEdit} size="large">
                            <Cancel />
                        </IconButton>
                        {
                            loading
                            ?
                            <CircularProgress />
                            :
                            <IconButton onClick={submitEdit} size="large">
                                <Save />
                            </IconButton>
                        }
                    </>
                }}
            />
            :
            <Typography>{groupName}
                <IconButton onClick={() => setEditActive(true)} size="large">
                    <Edit />
                </IconButton>
            </Typography>
        }
        <List>
            {
                members && members.map((m) => {
                    return (
                        <ListItem key={m.id} className="iso-list-item">
                            <ListItemText><span className="iso-small">{m.firstName} {m.lastName} - ({m.email})</span></ListItemText>
                            <ListItemSecondaryAction>
                                <IconButton onClick={() => removeMember(m.uid)} size="large"><FontAwesomeIcon icon={faMinusCircle}/></IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    );
                })
            }
        </List>
        <br/>
        <Select
            options={rosters}
            value={newMembers}
            isMulti
            onChange={(val) =>  setNewMembers(val)}
            {...selectFieldProps}
            placeholder='Add Members to Group'
        />
        <br/>
        <span className="iso-error">{error}</span>
        {
            newMembers && newMembers.length > 0
            ?
            <Button fullWidth variant="contained" color="primary" onClick={addMembers}>Submit</Button>
            :
            null
            
        }
    </>;
}
