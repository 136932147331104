import {
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import Select from 'react-select'
import GroupList from './GroupList'
import { selectFieldProps } from '../../shared/constants.js'
import FloatingHelpComponent from '../../help/FloatingHelpComponent'

const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: theme.palette.cardBg
    },
    dialog: {
        backgroundColor: theme.palette.dialogBg
    }
}))

export default function Groups() {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [name, setName] = useState('')
    const { GetRosters, createGroup, GetUsersLevelFilter } = useDb()
    // const rosters = GetUsersLevelFilter('docManager', 1)
    const rosters = GetRosters()
    const [members, setMembers] = useState([])
    const [error, setError] = useState(null)

    const handleSubmit = async () => {
        if (name !== '' && members !== []) {
            try {
                await createGroup(name, members)
                setOpen(false)
            }
            catch(e) {
                setError(e.message)
            }
            
        }
        else {
            setError('Please complete all fields.')
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
                <Card className={classes.card}>
                    <CardContent>
                        <GroupList />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={4}>
                <Card className={classes.card}>
                    <CardContent>
                        <Button onClick={() => setOpen(true)} color="primary" variant="contained">Add Group</Button>
                    </CardContent>
                </Card>
                <Dialog maxWidth="sm" fullWidth open={open} onClose={() => setOpen(false)}>
                    <DialogContent className={classes.dialog}>
                        <Typography>Create Group</Typography>
                        <br/>
                        <TextField variant="outlined" label="Group Name" onChange={(e) => setName(e.target.value)} fullWidth /><br/><br/>
                        <Select
                            options={rosters}
                            isMulti
                            onChange={(val) =>  setMembers(val)}
                            {...selectFieldProps}
                            placeholder='Add Members to Group'
                        />
                        <span className="iso-error">{error}</span>
                    </DialogContent>
                    <DialogActions className={classes.dialog}>
                        <Button onClick={handleSubmit} color="primary">Confirm</Button>
                        <Button onClick={() => setOpen(false)} color="secondary">Cancel</Button>
                    </DialogActions>
                </Dialog>
            </Grid>

            <FloatingHelpComponent
                name="Using Groups" 
                videoPath="master_list"
                videoIndex={4}
            />
        </Grid>
    )
}
