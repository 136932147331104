import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material'
import { Save } from '@mui/icons-material'
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import { textFieldProps } from '../../shared/constants'

export default function AddIsoCode({ open, closeDialog }) {
    const { addIsoCode } = useDb()

    const [code, setCode] = useState('')

    // Action State
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const handleClose = () => {
        setCode('')
        setLoading(false)
        setError(null)
        closeDialog()
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        setError(null)
        try {
            await addIsoCode(code).then(() => {
                handleClose()
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        {...textFieldProps}
                        onChange={e => setCode(e.target.value)}
                        label="Code"
                        required
                    />
                </DialogContent>
                <DialogActions>
                    { error ? <span className="iso-error">{error}</span> : null }
                    <Button disabled={ loading } color="secondary" onClick={handleClose}>Cancel</Button>
                    <Button disabled={ loading } color="primary" type="submit" variant="contained" startIcon={<Save />}>Save Code</Button>

                </DialogActions>
            </form>
        </Dialog>
    )
}
