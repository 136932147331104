import { faEdit, faExclamationTriangle, faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, ButtonGroup, Card, CardHeader, Chip, IconButton, Typography } from '@mui/material'
import React, { useState } from 'react'
import moment from 'moment'
import EditEmpSupDoc from './EditEmpSupDoc'
import { Check, Delete, Search, ViewAgenda } from '@mui/icons-material'
import { useDb } from '../../../../contexts/DatabaseContext'
import { useAuth } from '../../../../contexts/AuthContext'
import FileDialog from '../../../shared/FileDialog'

export default function EmpSupDoc({ supDoc  }) {
    const { deleteEmployeeSupportingDoc, markEmployeeSupDocReplaced } = useDb()

    const [editOpen, setEditOpen] = useState(false)
    const [fileOpen, setFileOpen] = useState(false)

    const handleDelete = async () => {
        try {
            await deleteEmployeeSupportingDoc(supDoc.parentDocId, supDoc.id)
        }
        catch(err) {
            console.log(err.message)
        }
    }

    const handleMarkReplaced = async () => {
        try {
            await markEmployeeSupDocReplaced(supDoc.parentDocId, supDoc.id)
        }
        catch(err) {
            console.log(err.message)
        }
    }

    const { currentUser } = useAuth()
    return supDoc !== null ? 
    <>
        <Card className="iso-card">
            <CardHeader
                title={supDoc.fileName}
                action={
                    <>
                        {supDoc.expired ? <><FontAwesomeIcon icon={faExclamationTriangle}/> - <span className="iso-error">Expired</span></> : null }
                        <ButtonGroup size="small">
                            <IconButton onClick={() => setFileOpen(true)} size="large">
                                <Search color="primary" />
                            </IconButton>
                            {
                            currentUser.accessRights.docManager > 1
                            ?
                            null
                            :
                            <IconButton onClick={() => setEditOpen(true)} color={'primary'} size="large">
                                <FontAwesomeIcon icon={faEdit} /> 
                            </IconButton>
                            }
                            {
                                currentUser && currentUser.companyAdmin
                                ?
                                <IconButton onClick={() => handleDelete(supDoc.id)} color={'secondary'} size="large">
                                    <Delete />
                                </IconButton>
                                :
                                null
                            }
                        </ButtonGroup>
                    </>
                }
                subheader={
                    <span>
                    {`Date Submitted: ${moment(supDoc.createdAt.toDate()).format('DD/MM/YYYY')}`}<br/>
                    { 
                        supDoc.expires 
                        ? 
                        <>{`Expires: ${moment(supDoc.expiryDate.toDate()).fromNow()}`} <br/><Button onClick={handleMarkReplaced} startIcon={<Check />} color="primary" variant="contained" size="small">Mark as Replaced</Button> </>
                        : 
                        null 
                    }
                    </span>
                }
                
                avatar={
                    supDoc.folder ? <Chip size="small" label={supDoc.folder.label}/> : <span>&nbsp;</span>
                }
                
            />
        </Card>

        <EditEmpSupDoc open={editOpen} closeDialog={() => setEditOpen(false)} supDoc={supDoc} />
        <FileDialog open={fileOpen} closeDialog={() => setFileOpen(false)} file={supDoc.docUrl ? supDoc.docUrl : supDoc.fUrl} /> 
            
    </>
    : 
    null;
}
