import {
    Grid,
    Card,
    CardContent,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    Typography,
    TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react'
import FloatingHelpComponent from '../../help/FloatingHelpComponent'
import EmployeeList from './EmployeeList'
import NewEmployeeForm from './NewEmployeeForm'

const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: theme.palette.cardBg
    }
}))

export default function Employees() {
    const classes = useStyles()
    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <div>
                <h1>Employees</h1>
                <Button className='iso-right' onClick={() => setOpen(true)} color="primary" variant="contained">Create Employee</Button>
                <br />
                <br />
                <EmployeeList />
                <NewEmployeeForm open={open} closeDialog={handleClose} />
            </div>
            <FloatingHelpComponent
                name="Managing Employees" 
                videoPath="training_manager"
                videoIndex={0}
            />
        </>
    )
}
