import React from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid'
import { Button, IconButton } from '@mui/material'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import { Link, useHistory } from 'react-router-dom'
import moment from 'moment'

export default function ActionsTempSearch() {
    const { GetActions } = useDb()
    const actions = GetActions()
    const history = useHistory()

    const rows = actions && actions.map(a  => ({
        id: a.id,
        department: a.department.name,
        hod: `${a.hod.firstName} ${a.hod.lastName}`,
        isoAgency: a.isoAgency.isoAgency,
        isoClass: a.isoClass,
        originator: `${a.originator.firstName} ${a.originator.lastName}`,
        priority: a.priority,
        site: a.site.label,
        source: a.source.sourceName,
        targetDate: moment(a.targetDate.toDate()).format('DD/MM/YYYY'),
        actionNumber: a.actionNumber
    }))

    const columns = [
        { 
            field: 'action', 
            headerName: 'Detail', 
            width: 140,
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                        <Link to={`/action/${params.row.id}`}>
                            <IconButton size="large">
                                <ArrowForward />
                            </IconButton>
                        </Link>
                     </div>
                );
            }
        },
        { field: 'actionNumber', headerName: 'Action No.' , width: 200 },
        { field: 'department', headerName: 'Department' , width: 200 },
        { field: 'hod', headerName: 'HOD' , width: 200 },
        { field: 'isoAgency', headerName: 'Agency' , width: 200 },
        { field: 'isoClass', headerName: 'Classification' , width: 200 },
        { field: 'originator', headerName: 'Originator' , width: 200 },
        { field: 'priority', headerName: 'Priority' , width: 200 },
        { field: 'site', headerName: 'Site' , width: 200 },
        { field: 'source', headerName: 'Source' , width: 200 },
        { field: 'targetDate', headerName: 'Target Date' , width: 200 },
        
    ]

    return (
        <div>
            <div className="back-link">
                <Button onClick={() => history.goBack()} color="primary" variant="contained" startIcon={<ArrowBack />}>Back</Button>
            </div>
            <IsoDataGrid
                data={rows}
                columns={columns}
            />
        </div>
    )
}
