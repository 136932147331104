import React, { useState } from 'react'
import { Button, Card, CardActions, CardContent, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { AddBox, Delete, Search } from '@mui/icons-material'
import AddTemplate from './AddTemplate'
import { useDb } from '../../../contexts/DatabaseContext'

export default function DocTemplates() {
    const { GetTemplates, deleteTemplate } = useDb()
    const templates = GetTemplates()
    const [addOpen, setAddOpen] = useState(false)
    const [error, setError] = useState(null)

    const handleDelete = async (tId) => {
        try {
            await deleteTemplate(tId)
        }
        catch(err) {
            setError(err.message)
        }
    }

    return (
        <div>
            <Card>
                <CardContent>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Template Name</TableCell>
                                    <TableCell align="right">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    templates && templates.map(t => {
                                        return (
                                            <TableRow key={t.id}>
                                                <TableCell>{t.name}</TableCell>
                                                <TableCell align="right">
                                                    <IconButton href={t.url} target="_blank" size="large">
                                                        <Search />
                                                    </IconButton>
                                                    <IconButton onClick={() => handleDelete(t.id)} size="large">
                                                        <Delete />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
                <CardActions>
                    <Button onClick={() => setAddOpen(true)} color="primary" variant="contained" startIcon={<AddBox />}>Add Template</Button>
                    { error ? <span className="iso-error">{error}</span> : null }
                </CardActions>
            </Card>

            <AddTemplate open={addOpen} closeDialog={() => setAddOpen(false)} />
        </div>
    );
}
