import React from 'react'
import { Avatar, Button, Card, CardActions, CardContent, CardHeader, Grid, Typography } from '@mui/material'
import { Info, Search } from '@mui/icons-material'
import { useDb } from '../../../contexts/DatabaseContext'
import { Link } from 'react-router-dom'

export default function AnnualStats({ missed, complete }) {
    const { GetAuditCount } = useDb()
    const count = GetAuditCount()

    return (
        <div>
            <Card>
                <CardHeader
                    title="Audits/Inspections this year"
                    avatar={
                        <Avatar>
                            <Info />
                        </Avatar>
                    }
                />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <div style={{ textAlign: 'center'}}>
                                <Typography variant="h4" style={{ fontWeight: 'bold'}}>
                                    {
                                        count && count
                                    }
                                </Typography>
                                <span className="iso-small">
                                    Total
                                </span>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div style={{ textAlign: 'center'}}>
                                <Typography variant="h4" style={{ fontWeight: 'bold'}} color="secondary">
                                    {
                                        missed && missed.length
                                    }
                                </Typography>
                                <span className="iso-small">
                                    Missed
                                </span>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div style={{ textAlign: 'center'}}>
                                <Typography variant="h4" style={{ fontWeight: 'bold'}} color="primary">
                                    {
                                        complete && complete.length
                                    }
                                </Typography>
                                <span className="iso-small">
                                    Complete
                                </span>
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Link to="/audit-manager/inspections">
                        <Button
                            color="primary"
                            variant="contained"
                            startIcon={<Search />}
                        >
                            View Inspections
                        </Button>
                    </Link>
                </CardActions>
            </Card>
        </div>
    )
}
