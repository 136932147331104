import React, { useContext, useState, useEffect } from "react";
import { auth, db } from "../firebase";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [userClaims, setUserClaims] = useState();
  const [loading, setLoading] = useState(true);
  const [emailVerified, setEmailVerified] = useState(null);

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  async function logOut() {
    const user = await db
      .collection("users")
      .doc(currentUser.uid)
      .get()
      .then(doc => {
        return doc.data();
      });

    await db
      .collection("companies")
      .doc(currentUser.companyId)
      .collection("auditTrail")
      .add({
        event: "Logged Out",
        timestamp: new Date(),
        user
      });

    return await auth.signOut().then(() => {
      setCurrentUser(null);
    });
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  async function getCompanyId(uid) {
    return db
      .collection("users")
      .doc(uid)
      .get()
      .then(doc => {
        return doc.data().companyId;
      });
  }

  async function logSignIn(coId, uid) {
    const user = await db
      .collection("users")
      .doc(uid)
      .get()
      .then(doc => {
        return doc.data();
      });

    return await db
      .collection("companies")
      .doc(coId)
      .collection("auditTrail")
      .add({
        event: "Signed In",
        timestamp: new Date(),
        user
      });
  }

  async function setUserTheme(light) {
    const theme = light ? "light" : "dark";
    return await db.collection("users").doc(currentUser.uid).update({
      theme
    });
  }

  async function getUserTheme(uid) {
    const theme = await db
      .collection("users")
      .doc(uid)
      .get()
      .then(doc => {
        if (doc.data().theme) return doc.data().theme;
        else return "light";
      });
    return theme;
  }

  async function getCompanyDoc(coId) {
    return await db
      .collection("companies")
      .doc(coId)
      .get()
      .then(doc => {
        return doc.data();
      });
  }

  async function getUserDoc(uid) {
    return await db
      .collection("users")
      .doc(uid)
      .get()
      .then(doc => {
        return doc.data();
      });
  }

  async function updateCurrentUser() {
    const userDoc = await getUserDoc(currentUser.uid);
    const companyDoc = await getCompanyDoc(currentUser.companyId);
    setCurrentUser({
      ...currentUser,
      ...userDoc,
      ...companyDoc
    });
  }

  async function selfSignUp(email, password) {
    const uid = await auth
      .createUserWithEmailAndPassword(email, password)
      .then(user => {
        return user.user.uid;
      });
    return uid;
  }

  async function sendEmailVerification(email) {
    return new Promise((resolve, reject) => {
      auth.currentUser
        .sendEmailVerification()
        .then(res => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async user => {
      if (user) {
        setEmailVerified(user.emailVerified);
        await user.getIdTokenResult(true).then(token => {
          setUserClaims(token.claims);
        });
        const companyId = await getCompanyId(user.uid);
        const companyData = await getCompanyDoc(companyId);
        const userDoc = await getUserDoc(user.uid);
        await logSignIn(companyId, user.uid);
        setCurrentUser({
          ...user,
          ...companyData,
          ...userDoc
        });
      }
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    logOut,
    resetPassword,
    userClaims,
    setUserTheme,
    selfSignUp,
    updateCurrentUser,
    emailVerified,
    sendEmailVerification
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
