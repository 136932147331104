import { Alert } from '@mui/material';
import React from "react";
import BackLink from "../../shared/BackLink";

export default function MocComplete({ moc, props }) {
  return (
    <div>
      <BackLink />
      <Alert severity="info">This MOC has been completed</Alert>
    </div>
  );
}
