import {
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { textFieldProps } from "../../shared/constants";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { Send } from "@mui/icons-material";
import { useDb } from "../../../contexts/DatabaseContext";
import FloatingHelpComponent from "../../help/FloatingHelpComponent";

export default function RequestActionExtension() {
  const { actionId } = useParams();
  const { actionExtensionRequest } = useDb();
  const history = useHistory();

  // Form State
  const [state, setState] = useState({});

  // Action State
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    try {
      await actionExtensionRequest(actionId, state).then(() => {
        setLoading(false);
        history.push("/action-manager/register");
      });
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <h1>Request Target Date Extension</h1>
      <Card>
        <form onSubmit={handleSubmit}>
          <CardContent>
            <TextField
              {...textFieldProps}
              label="Reason for Requesting Extension"
              required
              name="reason"
              onChange={handleChange}
              multiline
              rows={2}
            />
            <br />
            <br />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Target Revision Date"
                onChange={(d) =>
                  setState({ ...state, requestedDate: d.toDate() })
                }
                defaultValue={dayjs(state.requestedDate)}
              />
            </LocalizationProvider>
          </CardContent>
          <CardActions>
            <Button
              disabled={loading || !state.requestedDate}
              color="primary"
              variant="contained"
              startIcon={<Send />}
              type="submit"
            >
              Send Request
            </Button>
            {error ? <span className="iso-error">{error}</span> : null}
          </CardActions>
        </form>
      </Card>

      <FloatingHelpComponent
        name="Requesting an Action Extension"
        videoPath="action_manager"
        videoIndex={4}
      />
    </div>
  );
}
