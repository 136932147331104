import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import { Redirect, useParams } from 'react-router-dom';
import { Avatar, Button, Card, CardContent, CardHeader, CircularProgress, Grid, Typography } from '@mui/material';
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../../contexts/AuthContext'

export default function ApproveRevision() {
    
    const params = useParams();
    const { getRevisionById, approveRevision } = useDb() 
    const { currentUser } = useAuth()
    const [error, setError] = useState('')
    const [doc, setDoc] = useState(null)
    const [loading, setLoading] = useState(false)
    const [complete, setComplete] = useState(false)

    const getDocument = async () => {
        const isoDoc = await getRevisionById(params.docid, params.revid)
        
        setDoc(isoDoc) 
    }

    const approve = async () => { 
        
        setLoading(true)
        const result = await approveRevision(params.docid, params.revid, currentUser.uid, 'Approved')
        if (result !== null) {
            setComplete(true) 
        } 
        else {
            setLoading(false)
            setError('Error Approving Document')
        }
    }
    const decline = async () => {
        setLoading(true)
        const result = await approveRevision(params.docid, params.revid, currentUser.uid, 'Declined')
        if (result !== null) {
            setComplete(true) 
        }
        else {
            setLoading(false)
            setError('Error Approving Document')
        }
    }

    if(doc === null) {
        getDocument()
        return (
            <CircularProgress />
        )
    }
    else {
        
        return (
            <>
                {
                    complete ? <Redirect to='/doc-manager/doc-register' /> : null
                }
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Card>
                            <CardHeader
                                title={`${doc.revCode}`}
                                subheader={doc.requestedBy && `Requested By: ${doc.requestedBy.label}`}
                                avatar={<Avatar><FontAwesomeIcon icon={faFile}/></Avatar>}
                            />
                            <CardContent>
                                <b>Created:</b> {moment(doc.createdDate.toDate()).format('DD/MM/YYYY')}<br/>
                                <b>Target Revision Date:</b> {moment(doc.targetRevDate.toDate()).format('DD/MM/YYYY')}<br/>
                                <b>Revised By:</b> {doc.revisedBy?.label}<br/>
                                <b>Description of Changes:</b> {doc.description}<br/>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <Card>
                            <CardContent>
                                {
                                    currentUser.companyAdmin || currentUser.accessRights.docManager <= 1 ?
                                    <>
                                        <a href={doc.docUrl} target="_blank" rel="noopener noreferrer">
                                            <Button startIcon={<FontAwesomeIcon icon={faFile}/>} color="primary" variant="contained">View Document</Button>
                                        </a>
                                        <br/><br/>
                                        <Button disabled={loading} onClick={approve} startIcon={<FontAwesomeIcon icon={faThumbsUp}/>} color="primary" variant="contained">Approve</Button><br/><br/>
                                        <Button disabled={loading} onClick={decline}  startIcon={<FontAwesomeIcon icon={faThumbsDown}/>} color="secondary" variant="contained">Decline</Button>
                                        <span style={{ color: 'red '}}>{error}</span>
                                    </>
                                    :
                                    <Typography>You do not have the correct priveleges to approve revisions. Please contact your HOD.</Typography>
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </>
        )
    }
    
}
