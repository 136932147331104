import {
  Button,
  LinearProgress,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Check, Save } from "@mui/icons-material";
import { Alert } from '@mui/material';
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDb } from "../../../../contexts/DatabaseContext";
import BackLink from "../../../shared/BackLink";
import { textFieldProps } from "../../../shared/constants";
import { useHistory } from "react-router-dom";

export default function FiveWhyAnalysis() {
  const { actionId } = useParams();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const { getAction, saveFiveWhy, updateAction } = useDb();
  const [action, setAction] = useState();
  const [stateSaved, setStateSaved] = useState(true);
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState([
    { key: "why1", label: "Why 1" },
    { key: "why2", label: "Why 2" },
    { key: "why3", label: "Why 3" },
    { key: "why4", label: "Why 4" },
    { key: "why5", label: "Why 5" },
  ]);

  const loadAction = async () => {
    const a = await getAction(actionId);
    if (a.fivewhysteps) {
      setState(a.fivewhysteps);
      setActiveStep(Object.keys(a.fivewhysteps).length - 1);
      if (Object.keys(a.fivewhysteps).length > 5) {
        let stepArray = [];
        for (let key in a.fivewhysteps) {
          if (key.charAt(3) > 5) {
            stepArray.push({
              key,
              label: `Why ${key.charAt(3)}`,
            });
          }
        }
        setSteps((steps) => [...steps, ...stepArray]);
      }
    }
    setAction(a);
  };

  useEffect(() => {
    const unsubscribe = loadAction();

    return unsubscribe;
  }, [actionId]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
    setStateSaved(false);
  };

  const handleStepAdd = () => {
    let stepArray = steps;
    steps.push({
      key: `why${steps.length + 1}`,
      label: `Why ${steps.length + 1}`,
    });
    setSteps(stepArray);
    handleBack();
  };

  const handleSave = async () => {
    await saveFiveWhy(actionId, state);
    setStateSaved(true);
  };

  const handleAnalysisComplete = async () => {
    setLoading(true);
    try {

        await updateAction(actionId, { analysisComplete: true });
        history.goBack();
    }
    catch(err) {
        setLoading(false)
        window.alert(err.message)
    }
  };

  return !action ? (
    <LinearProgress />
  ) : (
    <div>
      <h1>5 Why Root Cause Analysis</h1>
      <BackLink />
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((s, index) => (
          <Step key={index}>
            <StepLabel>
              {s.label} - {state[s.key] && `${state[s.key]}`}
            </StepLabel>
            <StepContent>
              <TextField
                onChange={handleChange}
                {...textFieldProps}
                label={s.label}
                name={s.key}
                value={state[s.key]}
              />
              <div>
                <div>
                  <Button disabled={activeStep === 0} onClick={handleBack}>
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    disabled={stateSaved}
                    startIcon={<Save />}
                  >
                    Save
                  </Button>
                  &nbsp;
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    disabled={!state[s.key]}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
        <Step>
          <StepLabel>Root Cause</StepLabel>
          <StepContent>
            <Typography>
              <Alert severity="info">{action.isoAgency?.isoAgency}</Alert>
              <TextField
                name="rootcause"
                onChange={handleChange}
                {...textFieldProps}
                label={"Root Cause"}
                value={state["rootcause"]}
              />
            </Typography>
            <br />
            <Button onClick={handleBack}>Back</Button>
            <Button
              startIcon={<Add />}
              size="small"
              color="primary"
              variant="contained"
              onClick={handleStepAdd}
            >
              Add Step Above
            </Button>
            
            &nbsp;
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={stateSaved}
              startIcon={<Save />}
              size="small"
            >
              Save
            </Button>
            &nbsp;
            <Button
              startIcon={<Check />}
              size="small"
              color="primary"
              variant="contained"
              disabled={loading}
              onClick={handleAnalysisComplete}
            >
              Mark Analysis Complete
            </Button>
          </StepContent>
        </Step>
      </Stepper>
    </div>
  );
}
