import { faEllipsisH, faFile, faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    Menu,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import moment from 'moment';
import RevisionHistory from './RevisionHistory';
import DocDropDown from './DocDropDown';
import { truncateString } from '../../shared/constants'
import { useDb } from '../../../contexts/DatabaseContext';
import FileDialog from '../../shared/FileDialog';

const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: theme.palette.cardBg
    },
    dialog: {
        backgroundColor: theme.palette.dialogBg
    },
    text: {
        color: theme.palette.isotext
    }
}))

const DocumentCard = ({ doc, user, favs, snackCall }) => { 
    // const { docName, author, dateCreated, docType, targetRevDate, department, status } = props;
    const { addDocToFavorites, removeDocFromFavorites } = useDb()
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null)
    const [dest, setDest] = useState(null)
    const [revisionDialog, setRevisionDialog] = useState(false)
    const [pdfViewOpen, setPdfViewOpen] = useState(false)
    const [isFav, setIsFav] = useState(false)
    const [favCheckDone, setFavCheckDone] = useState(false)

    const classes = useStyles()

    const handleClick = (e) => {
        console.log(e)
        setAnchorEl(e.currentTarget)
    }

    const openDialog = (dest) => {
        setDest(dest)
        setAnchorEl(null) 
        setOpen(true)
    }

    const closeDialog = () => {
        setOpen(false)
    }

    const closePdfView = () => {
        setPdfViewOpen(false)
    }

    const addToFavorites = async (docId) => {
        setIsFav(true)
        try {
            await addDocToFavorites(docId, user.uid)
            snackCall('Document Added to Favourites!')
        }
        catch(err) {
            console.log('Error saving document to Favourites: ', err.message)
        }
    }

    const removeFromFavorites = async (docId) => {
        setIsFav(false)
        try {
            await removeDocFromFavorites(docId, user.uid)
            snackCall('Document Removed from Favourites')
        }
        catch(err) {
            console.log('Error saving document to Favourites: ', doc.id)
        }
    }

    const loadCheck = () => {
        if(favs && favs.includes(doc.id)) {
            setIsFav(true)
        }
        setFavCheckDone(true)
    }

    const truncTitle = truncateString(`${doc.docCode} ${doc.docName}`, 32)
    if(!favCheckDone) {
        loadCheck()
        return <CircularProgress />
    }
    else
    return (
        <Card className={classes.card}>
            <CardHeader
                avatar={
                    <FontAwesomeIcon icon={faFile} />
                } 
                title={<Typography className="iso-title-link" color="isotext" onClick={() => setPdfViewOpen(true)} noWrap>{truncTitle}</Typography>}
                subheader={doc.docType.label}
                action={
                    <>
                    {
                        isFav ?
                        <IconButton onClick={() => removeFromFavorites(doc.id)} size="large">
                            <FontAwesomeIcon icon={faHeart} style={{ color: '#ffa900'}}/>
                        </IconButton> 
                        :
                        <IconButton onClick={() => addToFavorites(doc.id)} size="large">
                            <FontAwesomeIcon icon={faHeart}/>
                        </IconButton> 
                    }
                    
                        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} size="large">
                            <FontAwesomeIcon icon={faEllipsisH} />
                        </IconButton> 
                    </>
                }
            />

            <FileDialog open={pdfViewOpen} closeDialog={closePdfView} file={doc.docUrl} /> 

            <CardContent>
                
                <Grid container spacing={3}>
                    
                    <Grid item xs={6}>
                        <span className="smaller-text">Date Created: {moment(doc.createdDate.toDate()).format('DD/MM/YYYY')}</span><br/>
                        {/* <Typography color="textPrimary">Date Created: {moment(doc.createdDate.toDate()).format('DD/MM/YYYY')}</Typography> */}
                        
                        
                        <span className="smaller-text">Department: {doc.department.label}</span>
                        
                    </Grid>
                    <Grid item xs={6}>
                        <span className="smaller-text">Target Revision Date: {moment(doc.targetRevDate.toDate()).format('DD/MM/YYYY')}</span><br/>
                        
                    </Grid>
                </Grid>
                <span className="smaller-text">Document Owner: {doc.owner.label}</span><br/>
                <span style={{ color: doc.targetRevDate.toDate() < Date.now() ? 'red' : classes.text.color }} className="smaller-text">Status: {<b>{`Due: ${moment(doc.targetRevDate.toDate()).fromNow()}`}</b>}</span>
            </CardContent>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                
            >
                <DocDropDown doc={doc} openDialog={openDialog} closeDialog={closeDialog}/>
            </Menu>
            
            <Dialog maxWidth="sm" fullWidth open={open} onClose={() => setOpen(false)}>
                <DialogContent className={classes.dialog}>
                    { dest }
                </DialogContent>
                <DialogActions className={classes.dialog} >
                    <Button onClick={closeDialog} color="primary">Close</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={revisionDialog} onClose={() => setRevisionDialog(false)} maxWidth="md" fullWidth>
                <DialogContent>
                    <RevisionHistory doc={doc}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setRevisionDialog(false)} color="primary">Close</Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
}

export default DocumentCard;