import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import { Save } from '@mui/icons-material'
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import Select from 'react-select'
import { selectFieldProps, textFieldProps } from '../../shared/constants'

export default function EditVenue({ open, handleClose, venue }) {

  const { GetSites } = useDb()
  const sites = GetSites()  
  const [state, setState] = useState({})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const handleSelect = (v, e) => {
    setState({
      [e.name]: v.value,
      [`${e.name}Label`]: v.label
    })
  }

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setError(null)
    setLoading(true)
    try {
      venue.ref.update({
        ...state
      })
      handleClose()
    } catch (error) {
      console.log(error)
      setError(error.message)
      setLoading(false)
    }
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          Edit Training Venue
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Select
              {...selectFieldProps}
              placeholder="Select Site ..."
              options={sites}
              onChange={handleSelect}
              name="site"
              className="iso-select"
            />
            <input
              autoComplete="off"
              style={{ opacity: 0, height: 0, marginTop: -20, width: 0 }}
              value={state.site}
              required
            /><br/>
            <TextField
              {...textFieldProps}
              label="Venue Name"
              name="name"
              onChange={handleChange}
            />
            { error ? <span className="iso-error">{error}</span> : null }
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={handleClose}>Cancel</Button>
            <Button disabled={ loading } startIcon={<Save />} variant="contained" color="primary" type="submit">Save</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}