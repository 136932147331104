import { faGavel } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

export default function ActionManagerMenuItem({ classes, currentUser }) {
    const [actionMenuOpen, setActionMenuOpen] = useState(false)
    
    return (
        <>
            <ListItem button onClick={() => setActionMenuOpen(!actionMenuOpen)}>
                <ListItemIcon><FontAwesomeIcon className={classes.icons} icon={faGavel} /></ListItemIcon>
                    <ListItemText className={classes.menuText}>Action Manager</ListItemText>
                { actionMenuOpen ? <ExpandLess className={classes.icon} /> : <ExpandMore className={classes.icon} /> }
            </ListItem>
            <Collapse in={actionMenuOpen} timeout="auto" unmountOnExit>
                <List component="div" className={classes.subMenuItem} dense>
                    <ListItem>
                        <Link to='/action-manager' className="drawer-item">
                            <ListItemText className={classes.menuText}>Action Dashboard</ListItemText>
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link to='/action-manager/register' className="drawer-item">
                            <ListItemText className={classes.menuText}>Action Register</ListItemText>
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link to='/action-manager/sources' className="drawer-item">
                            <ListItemText className={classes.menuText}>Sources</ListItemText>
                        </Link>
                    </ListItem>
                    {
                        currentUser.companyAdmin
                        ?
                        <ListItem>
                            <Link to='/action-manager/priorities' className="drawer-item">
                                <ListItemText className={classes.menuText}>Priorities</ListItemText>
                            </Link>
                        </ListItem>
                        : 
                        null
                    }
                    {/* {
                        currentUser.companyAdmin
                        ?
                        <ListItem>
                            <Link to='/action-archive' className="drawer-item">
                                <ListItemText className={classes.menuText}>Archive</ListItemText>
                            </Link>
                        </ListItem>
                        : 
                        null
                    } */}
                </List>
            </Collapse>
        </>
    )
}
