import {
  Typography,
  TextField,
  CssBaseline,
  Grid,
  IconButton,
  InputAdornment,
  Button,
  Paper,
  Box,
  Divider,
  FormLabel,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import happyman from "../../img/happyman.jpg";
import isoLogo from "../../img/gold_logo.png";
import makeStyles from '@mui/styles/makeStyles';
import { functions } from "../../firebase";
import { countries, selectFieldProps } from "../shared/constants";
import Select from "react-select";

// Icons
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ArrowBack } from "@mui/icons-material";
import { Alert } from '@mui/material';
import { useDb } from "../../contexts/DatabaseContext";
import { useAuth } from "../../contexts/AuthContext";

function Copyright() {
  return (
    <div>
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <a color="inherit" href="https://isooffice.io">
          ISO Office
        </a>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${happyman})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp() {
  const classes = useStyles();

  const [state, setState] = useState({
    termsChecked: false,
    data: {},
  });
  const [showPassword, togglePassword] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { login } = useAuth();
  const { GetSalesAgents } = useDb();
  const agents = GetSalesAgents();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    const functionRef = functions.httpsCallable("createNewCompanyAndUser");

    // TODO: Below we check for either SA customers or internationals and just asign manual or stripe based on that. I think this should be ok since we want the later Paystack to be optional to larger SA customers. But check if this logic still holds when we get around to that.
    const billingType = state.data.country === "ZA" ? "manual" : "stripe";

    try {
      await functionRef({ ...state.data, billingType });
      await login(state.data.email, state.data.password);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setState({
      ...state,
      data: {
        ...state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleSelect = (v, e) => {
    setState({
      ...state,
      data: {
        ...state.data,
        [e.name]: v.value,
      },
    });
  };

  const handleTerms = (e) => {
    setState({
      ...state,
      termsChecked: e.target.checked,
    });
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <a
            href="https://iso-office.co.za"
            target="_blank"
            rel="noopener noreferred"
          >
            <img src={isoLogo} width="100" alt="ISO Logo" />
          </a>
          <Typography component="h1" variant="h5">
            Create New Account
          </Typography>
          <form onSubmit={handleSubmit} className={classes.form} noValidate>
            <Select
              {...selectFieldProps}
              options={countries}
              placeholder="Select Country ..."
              onChange={handleSelect}
              name="country"
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="First Name"
              name="firstName"
              autoFocus
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Last Name"
              name="lastName"
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              autoComplete="current-password"
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    onClick={() => togglePassword(!showPassword)}
                    position="end"
                  >
                    <IconButton size="large">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="passwordConfirm"
              label="Confirm Password"
              type={showPassword ? "text" : "password"}
              autoComplete="current-password"
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    onClick={() => togglePassword(!showPassword)}
                    position="end"
                  >
                    <IconButton size="large">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {state.data.password &&
              state.data.passwordConfirm &&
              state.data.password !== state.data.passwordConfirm && (
                <Alert severity="error">Passwords do not match</Alert>
              )}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Company Name"
              name="companyName"
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Company Address"
              name="address"
              multiline
              rows={3}
              onChange={handleChange}
            />

            {/* Temporary Agent Selector */}
            {state.data.country === "ZA" && (
              <Select
                options={agents}
                {...selectFieldProps}
                name="agent"
                onChange={handleSelect}
                placeholder="Who referred you to ISO Office? ..."
              />
            )}
            <a
              style={{ fontWeight: "bold", color: "blue" }}
              target="_blank"
              href="https://firebasestorage.googleapis.com/v0/b/iso-office.appspot.com/o/Copy%20of%20ISO-Office%20(Pty)%20Ltd%20SaaS%20Agreement.docx.pdf?alt=media&token=715de004-77e8-40bb-95df-6bc1cc120a72"
            >
              Click here for Terms and conditions
            </a>
            <br />

            <FormControlLabel
              control={
                <Checkbox
                  checked={state.termsChecked}
                  onChange={handleTerms}
                  name="termsChecked"
                  color="primary"
                />
              }
              label="I agree to the terms and conditions."
            />

            <span style={{ color: "red", textAlign: "center", width: "100%" }}>
              {error}
            </span>
            <br />
            {loading ? (
              <Button variant="outlined" color="primary" fullWidth>
                <CircularProgress size={20} />
              </Button>
            ) : (
              <Button
                type="submit"
                disabled={
                  !state.data.country ||
                  (state.data.password &&
                    state.data.passwordConfirm &&
                    state.data.password !== state.data.passwordConfirm) ||
                  !state.termsChecked
                }
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleSubmit}
              >
                Create Account
              </Button>
            )}

            <Grid container>
              <Grid item xs>
                <Link to="/" variant="body2">
                  <Button startIcon={<ArrowBack />}>Back to Login</Button>
                </Link>
              </Grid>
              <Grid item></Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
