import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  RadioGroup,
  FormControlLabel,
  Radio,
  TableHead,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  radioGroup: {
    flexDirection: "row",
  },
});

const questions = [
  "Did the Modification have the desired effect?",
  "Were the standards of design and construction to relevant Lonmin and Statutory requirements?",
  "Was testing carried out?",
  "Were Maintenance and Production Work instructions and SOP’s updated?",
  "Were redundant cabling or other services removed or if not possible terminated?",
  "Were ISO systems updated?",
  "Were emergency procedures updated?",
  "Were drawings updated?",
  "Were environmental aspects identified and the risk rated?",
  "Was training delivered and recorded?",
  "Were critical tasks, equipment and areas identified?",
  "Is training and licenses implemented for new equipment on site.",
];

export default function ApprovalQuestions({ handleUpdate }) {
  const classes = useStyles();
  const [answers, setAnswers] = useState(
    questions.map((question) => ({ question, answer: "n/a" }))
  );

  const handleRadioChange = (index) => async (event) => {
    const newAnswers = [...answers];
    newAnswers[index].answer = event.target.value;
    setAnswers(newAnswers);
    await handleUpdate({
      followUpQuestions: newAnswers,
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small" className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Question</TableCell>
            <TableCell>Response</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {answers.map((item, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {item.question}
              </TableCell>
              <TableCell>
                <RadioGroup
                  className={classes.radioGroup}
                  name={`question${index}`}
                  value={item.answer || ""}
                  onChange={handleRadioChange(index)}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel
                    value="n/a"
                    control={<Radio />}
                    label="N/A"
                  />
                </RadioGroup>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
