import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  AddBox,
  ArrowLeft,
  AssignmentTurnedIn,
  CheckCircle,
  CloudDownload,
  Delete,
  EditOutlined,
  Info,
  Person,
  ReportProblem,
  School,
  Work,
} from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useDb } from "../../../contexts/DatabaseContext";
import moment from "moment";
import { green } from "@mui/material/colors";
import RequestTraining from "./RequestTraining";
import AddWorkToEmployee from "./AddWorkToEmployee";
import EmployeeEdit from "./EmployeeEdit";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useAuth } from "../../../contexts/AuthContext";

const useStyles = makeStyles((theme) => ({
  passed: {
    color: green[500],
  },
  card: {
    marginBottom: 10,
  },
  box: {
    display: "flex",
  },
  bottomRightBox: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
}));

export default function EmployeeDetail() {
  const classes = useStyles();
  const { id } = useParams();
  const {
    GetEmployeeCompetency,
    getEmployee,
    employeeCompetencyCertificate,
    ceateEmployeePdf,
    removeOccupation,
    getTrainingSessionByModuleAndAttendee,
  } = useDb();
  const [moduleList, setModuleList] = useState([]);
  const modules = GetEmployeeCompetency(id);
  const [employee, setEmployee] = useState(null);
  const [requestOpen, setRequestOpen] = useState(false);
  const [addWorkOpen, setAddWorkOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const { currentUser } = useAuth();

  const [actionState, setActionState] = useState({
    loading: false,
    pageLoaded: false,
    error: null,
  });

  useEffect(() => {
    if (modules) {
      var moduleListArray = [];
      modules.forEach(async (item, index) => {
        const frequency = item.module?.frequency;
        const moduleId = item.id;

        const mostRecentTrainingSession =
          await getTrainingSessionByModuleAndAttendee(
            moduleId,
            currentUser.uid
          );

        if (mostRecentTrainingSession === null) {
          console.log("Session not done.");
          item.nextTrainingDue = null;
          moduleListArray.push(item);

          if (index + 1 === modules.length) {
            setModuleList(moduleListArray);
          }
        } else {
          const trainingDueDate = moment
            .unix(mostRecentTrainingSession.endTime.seconds)
            .add(frequency, "days")
            .format();

          item.nextTrainingDue = trainingDueDate;
          moduleListArray.push(item);
          if (index + 1 === modules.length) {
            setModuleList(moduleListArray);
          }
        }
      });
    }
  }, [modules]);

  const firstLoad = async () => {
    console.log("Getting Employee");
    const e = await getEmployee(id);
    setEmployee(e);
    setActionState({
      ...actionState,
      pageLoaded: true,
    });
  };

  const updateEmpJobs = (newJob) => {
    setActionState({
      ...actionState,
      pageLoaded: false,
    });
    let occupationArray = Array.isArray(employee.occupation)
      ? employee.occupation
      : [employee.occupation];
    occupationArray.push(newJob);
    setEmployee({
      ...employee,
      occupation: occupationArray,
    });
  };

  const handleDelete = async ({ occupation }) => {
    try {
      setActionState({
        ...actionState,
        loading: true,
      });
      await removeOccupation({
        employeeId: employee.id,
        occupation: occupation,
      }).then(() => {
        setActionState({
          ...actionState,
          pageLoaded: false,
          loading: false,
        });
      });
    } catch (error) {
      setActionState({
        ...actionState,
        error: error,
        loading: false,
      });
    }
  };

  const handleCertificate = async () => {
    setActionState({
      ...actionState,
      loading: true,
    });
    await employeeCompetencyCertificate(id);
    setActionState({
      ...actionState,
      loading: false,
    });
  };

  useEffect(() => {
    return firstLoad();
  }, [openEdit]);

  if (!actionState.pageLoaded) {
    return <LinearProgress />;
  }

  return (
    <div>
      <h1>
        Employee: {employee.firstName} {employee.lastName}
      </h1>
      <Card>
        <CardActions>
          <Link to="/training-manager/employees">
            <Button
              variant="contained"
              startIcon={<ArrowLeft />}
              color="primary"
            >
              Employees
            </Button>
          </Link>
          <Link to={`/training-manager/employees/evaluations/${employee.id}`}>
            <Button
              variant="contained"
              startIcon={<AssignmentTurnedIn />}
              color="primary"
            >
              Evaluations
            </Button>
          </Link>
          <Button
            onClick={() => setRequestOpen(true)}
            variant="contained"
            startIcon={<School />}
            color="primary"
          >
            Request Training
          </Button>
          <Button
            onClick={handleCertificate}
            disabled={actionState.loading}
            variant="contained"
            color="primary"
            startIcon={<CloudDownload />}
          >
            Certificate
          </Button>
          <Button
            onClick={() => ceateEmployeePdf(employee, modules)}
            disabled={actionState.loading}
            variant="contained"
            color="primary"
            startIcon={<PictureAsPdfIcon />}
          >
            PDF
          </Button>
          {currentUser.accessRights.trainingManager < 1 && (
            <Link
              to={`/training-manager/employees/detail/${employee.id}/employees/attachments`}
            >
              <Button
                variant="contained"
                color="primary"
                startIcon={<PictureAsPdfIcon />}
              >
                Attachments
              </Button>
            </Link>
          )}
        </CardActions>
      </Card>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12} md={5}>
          <Card>
            <CardHeader
              title="Employee Details"
              avatar={
                <Avatar>
                  <Person />
                </Avatar>
              }
            />
            <CardContent>
              <Typography>Contact Number: {employee.contactNumber}</Typography>
              <Typography>Email: {employee.email}</Typography>
              <Typography>
                Employee Number: {employee.employeeNumber}
              </Typography>
              <Typography>ID Number: {employee.employeeId}</Typography>
              {employee.permitNumber ? (
                <Typography>Permit Number: {employee.employeeId}</Typography>
              ) : null}
              <Typography>
                Employment Date:{" "}
                {moment(employee.employmentDate.toDate()).format("DD/MM/YYYY")}
              </Typography>
              <Typography>
                Manager:{" "}
                {employee.manager
                  ? `${employee.manager.firstName} ${employee.manager.lastName}`
                  : "None"}
              </Typography>
              <Typography>Employee Type: {employee.employeeType}</Typography>
              <CardActions>
                <Button
                  startIcon={<EditOutlined />}
                  color="primary"
                  variant="contained"
                  onClick={() => setOpenEdit(true)}
                >
                  Edit
                </Button>
              </CardActions>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={7}>
          <Card>
            <CardHeader
              title="Occupations"
              avatar={
                <Avatar>
                  <Work />
                </Avatar>
              }
            />
            <CardContent>
              <List>
                {employee.occupation && Array.isArray(employee.occupation)
                  ? employee.occupation.map((o) => (
                      <ListItem key={o.id} divider>
                        <ListItemIcon>
                          <Work />
                        </ListItemIcon>
                        <ListItemText>{o.label}</ListItemText>
                        {currentUser.accessRights.trainingManager > 3 ||
                        currentUser.companyAdmin ? (
                          <Button
                            onClick={() =>
                              handleDelete({
                                occupation: {
                                  id: o.id,
                                  label: o.label,
                                  createdBy: o.createdBy,
                                },
                              })
                            }
                          >
                            <Delete />
                          </Button>
                        ) : null}
                      </ListItem>
                    ))
                  : employee.occupation && (
                      <ListItem divider>
                        <ListItemIcon>
                          <Work />
                        </ListItemIcon>
                        <ListItemText>{employee.occupation}</ListItemText>
                      </ListItem>
                    )}
              </List>
            </CardContent>
            <CardActions>
              {currentUser.accessRights.trainingManager > 3 ||
              currentUser.companyAdmin ? (
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<AddBox />}
                  onClick={() => setAddWorkOpen(true)}
                >
                  Add Occupation
                </Button>
              ) : null}
            </CardActions>
          </Card>
          <br />
        </Grid>
      </Grid>
      <br />
      <Card>
        <CardHeader
          title="Training Competency"
          avatar={
            <Avatar>
              <School />
            </Avatar>
          }
        />
        <CardContent>
          {moduleList &&
            moduleList.map((m) => (
              <Card elevation={1} key={m.id} className={classes.card}>
                <CardHeader
                  title={m.name}
                  avatar={
                    <Avatar>
                      <Info />
                    </Avatar>
                  }
                  subheader={
                    m.nextTrainingDue ? (
                      moment(m.nextTrainingDue).isAfter(moment()) ? (
                        `Next Training Due: ${moment(m.nextTrainingDue).format(
                          "DD/MM/YYYY"
                        )}`
                      ) : (
                        <span className="iso-error">
                          {`Training Overdue: ${moment(
                            m.nextTrainingDue
                          ).fromNow()}`}
                        </span>
                      )
                    ) : (
                      " "
                    )
                  }
                  action={
                    m.compLevel > 1 ? (
                      <CheckCircle className={classes.passed} />
                    ) : (
                      <ReportProblem color="secondary" />
                    )
                  }
                />
                <CardContent>
                  <Typography>
                    Competency: <b>{m.compLevel}</b>{" "}
                    <span className="iso-small">
                      ({m.assessment?.compLevelLabel})
                    </span>
                  </Typography>
                  {m.assessment?.comment ? (
                    <span className="iso-small">
                      Facilitator Feedback: {m.assessment.comment}
                    </span>
                  ) : null}
                </CardContent>
                {m.assessment?.practicalUrl ? (
                  <CardActions>
                    <Button
                      startIcon={<CloudDownload />}
                      color="primary"
                      variant="contained"
                      href={m.assessment.practicalUrl}
                      target="_blank"
                    >
                      Practical Proof
                    </Button>
                  </CardActions>
                ) : null}
              </Card>
            ))}
        </CardContent>
      </Card>

      <RequestTraining
        open={requestOpen}
        close={() => setRequestOpen(false)}
        employee={employee}
      />
      <AddWorkToEmployee
        open={addWorkOpen}
        close={() => setAddWorkOpen(false)}
        employee={employee}
        updateEmpJobs={updateEmpJobs}
      />
      <EmployeeEdit
        open={openEdit}
        close={() => setOpenEdit(false)}
        employee={employee}
      />
    </div>
  );
}
