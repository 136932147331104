import React from 'react'
import { Redirect, Switch } from 'react-router-dom'
import Account from '../account/Account'
import AddPlan from '../account/AddPlan'
import SaTrialExpired from '../account/manual/SaTrialExpired'
import TrialExpired from '../account/TrialExpired'
import PrivateRoute from './PrivateRoute'


export default function TrialExpiredRoutes({ currentUser }) {
    
    const billingTypeLinks = {
        "stripe": TrialExpired,
        "manual": SaTrialExpired,
        "paystack": SaTrialExpired
    }

    const addPlanLinks = {
        "stripe": AddPlan,
        "manual": SaTrialExpired,
        "paystack": SaTrialExpired
    }

    return (
        <Switch>
            <PrivateRoute exact path='/' component={billingTypeLinks[currentUser.billing_type]} />  
            {/* Account */}
            <PrivateRoute exact path='/account' component={Account} />
            {/* AddPlan */}
            <PrivateRoute exact path='/addplan' component={addPlanLinks[currentUser.billing_type]} />
            <PrivateRoute path="*" component={billingTypeLinks[currentUser.billing_type]} />
        </Switch>
    )
}
