import { faFilePdf, faFolder, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AppBar, Button, Card, CardContent, Chip, Dialog, DialogActions, DialogContent, IconButton, LinearProgress, TextField, Toolbar } from '@mui/material'
import React, { useState } from 'react'
import Select from 'react-select'
import { useDb } from '../../../contexts/DatabaseContext'
import AddSupportingDocument from './AddSupportingDocument'
import PdfView from '../../shared/PdfView'
import CloseIcon from '@mui/icons-material/Close'
import { selectFieldProps } from '../../shared/constants'
import { useParams, Link } from 'react-router-dom'
import { ArrowLeftOutlined } from '@mui/icons-material'
import SubDocsTable from './SubDocsTable'
import FloatingHelpComponent from '../../help/FloatingHelpComponent'

export default function SupDocsNew() {
    const { docId } = useParams()
    const { addSubfolder, GetSubfolders, removeSubfolder, GetSupportingDocs, GetSubfoldersDropdown, getDocsSubmittedPDF, getDocument, generateSeportionDocFilterdPdf } = useDb()
    const [open, setOpen] = useState(false)
    const [folderName, setFolderName] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [addOpen, setAddOpen] = useState(false)
    const [folderFilter, setFolderFilter] = useState(null)
    const [doc, setDoc] = useState(null)
    const [loaded, setLoaded] = useState(false)

    const subFolders = GetSubfolders(docId)
    const supDocs = GetSupportingDocs(docId) 
    const subFoldersOptions = GetSubfoldersDropdown(docId)

    // PDF Stuff
    const [pdfViewOpen, setPdfViewOpen] = useState(false)
    const [pdf, setPdf] = useState(null)
    const openPdfView = (file) => {
        setPdf(file)
        setPdfViewOpen(true)
    }
    const closePdfView = () => {
        setPdfViewOpen(false)
        setPdf(null)
    }

    const closeDialog = () => {
        setFolderName('')
        setOpen(false)
    }

    const closeAddDialog = () => {
        setAddOpen(false)
    }

    const handleFolderAdd = async () => {

        setLoading(true)
        
        const result = await addSubfolder(docId, {folderName});
        if (result !== null) {
            closeDialog()
            setLoading(false)
        }
        else {
            setError('Error saving folder to database')
            setLoading(false)
        }

    }

    const handleFolderDelete = async (folderId) => {
        const result = await removeSubfolder(docId, folderId);
        if (result === null) {
            setError('Error deleting folder from database')
        }
    }

    const generatePdf = async () => {
        setLoading(true)
        setError(null)
        try {
            await getDocsSubmittedPDF(doc)
            setLoading(false)
        }
        catch(err){ 
            setError(err.message)
            setLoading(false)
        }
    }

    const firstLoad = async () => {
        const d = await getDocument(docId)
        setDoc(d)
        setLoaded(true)
    }

    const generateFilteredPdf = async () => {
        console.log('generateFilteredPdf')
        setLoading(true)
        setError(null)
        try {
            console.log(supDocs)
            await generateSeportionDocFilterdPdf(supDocs, folderFilter)
            setLoading(false)
        }
        catch(err){ 
            setError(err.message)
            setLoading(false)
        }
    }
    
    if(!loaded) {
        firstLoad()
        return <LinearProgress />
    }
    else return <>
        <h1>Supporting Documents for {doc.docCode}</h1>
        <Link to={`/doc-manager/doc-detail/${docId}`}>
            <Button disabled={ loading } color="primary" variant="contained" startIcon={<ArrowLeftOutlined />}>{doc.docCode}</Button>
        </Link>&nbsp;
        <Button onClick={() => setOpen(true)} startIcon={<FontAwesomeIcon icon={faFolder}/>} variant="contained" color="primary">Manage Folders</Button>&nbsp;
        <Button onClick={() => setAddOpen(true)} startIcon={<FontAwesomeIcon icon={faPlus}/>} variant="contained" color="primary">Add Document</Button>&nbsp;
        {
            folderFilter
            ? 
            <Button disabled={loading} onClick={generateFilteredPdf} startIcon={<FontAwesomeIcon icon={faFilePdf}/>} variant="contained" color="primary">PDF</Button>
            :
            <Button disabled={loading} onClick={generatePdf} startIcon={<FontAwesomeIcon icon={faFilePdf}/>} variant="contained" color="primary">Folders / PDF</Button>
            
        }
        
        <br/><br/>
        <Card>
            <CardContent>
                <Select
                    options={subFoldersOptions}
                    placeholder={'Subfolder ...'}
                    onChange={(val) => setFolderFilter(val !== null ? val.id : null)}
                    {...selectFieldProps}
                    isClearable={true}
                    
                />
                <br/>

                <SubDocsTable folderFilter={folderFilter} supDocs={supDocs} />
                
                <Dialog maxWidth="sm" fullWidth open={open} onClose={closeDialog} >
                    <DialogContent>
                        <div style={{ width: '100%', marginBottom: 20 }}>
                            {
                                subFolders && subFolders.map((folder) => {
                                    return (
                                        <Chip key={folder.id} size="small" label={folder.folderName} onDelete={() => handleFolderDelete(folder.id)} style={{ marginRight: 10 }}/>
                                    )
                                })
                            }
                        </div>
                        <TextField label="Add Folder" fullWidth variant="outlined" onChange={(e) => setFolderName(e.target.value)} />
                        <span className="iso-error">{error}</span>
                    </DialogContent>
                    <DialogActions>
                        { folderName !== '' ? <Button disabled={loading} onClick={handleFolderAdd} variant="contained" color="primary">Add Folder</Button> : null }
                        <Button onClick={closeDialog} color="secondary">Close</Button>
                    </DialogActions>
                </Dialog>
                <Dialog maxWidth="sm" fullWidth open={addOpen} onClose={() => setAddOpen(false)}>
                    <DialogContent>
                        <AddSupportingDocument docId={doc.id} closeAddDialog={closeAddDialog}/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setAddOpen(false)} color="secondary">Close</Button>
                    </DialogActions>
                </Dialog>

                <Dialog fullScreen open={pdfViewOpen} onClose={closePdfView} >
                <AppBar>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={closePdfView}
                            aria-label="close"
                            size="large">
                        <CloseIcon />
                        </IconButton>
                        
                        <Button autoFocus color="inherit" onClick={closePdfView}>
                        Save
                        </Button>
                    </Toolbar>
                </AppBar>
                <PdfView pdfFile={pdf} />
                </Dialog>
            </CardContent>
        </Card>
        
        <FloatingHelpComponent
            name="Supporting Doc Folders" 
            videoPath="doc_manager"
            videoIndex={1}
        />
    </>;
}
