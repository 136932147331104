import React, { useState } from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  TextField,
} from "@mui/material";
import { ArrowForward, AttachFile, Info } from "@mui/icons-material";
import Select from "react-select";
import { selectFieldProps, textFieldProps } from "../../shared/constants";
import MocRisk from "./MocRisk";
import FileUploader from "../../shared/FileUploader";

export default function RequestDetails({ moc, props }) {
  const { loading, title, setMoc, handleUpdate, handleAdvance } = props;
  const { GetSites, GetDepartments, GetUsersLevelFilter } = useDb();
  const sites = GetSites();
  const departments = GetDepartments();
  const rosters = GetUsersLevelFilter("mocManager", 2);

  const getFileObj = async (fileObj) => {
    await setMoc({
      ...moc,
      attachments: moc.attachments ? [...moc.attachments, fileObj] : [fileObj],
    });
  };

  const handleSelect = (v, e) => {
    setMoc({ ...moc, [e.name]: v });
  };

  const handleText = (e) => {
    setMoc({ ...moc, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    handleUpdate();
    handleAdvance();
  };

  return (
    <div>
      <Card>
        <CardHeader
          title={title}
          subheader={`MOC Title: ${moc.title}`}
          avatar={
            <Avatar>
              <Info />
            </Avatar>
          }
        />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Select
                {...selectFieldProps}
                options={sites}
                isMulti
                placeholder="Affected Sites ..."
                className="iso-select"
                name="sites"
                onChange={handleSelect}
              />
              <Select
                {...selectFieldProps}
                options={departments}
                isMulti
                placeholder="Affected Departments ..."
                className="iso-select"
                name="departments"
                onChange={handleSelect}
              />
              <Select
                {...selectFieldProps}
                options={[
                  { value: "Permanent", label: "Permanent" },
                  { value: "Temporary", label: "Temporary" },
                ]}
                placeholder="Change Permanence ..."
                className="iso-select"
                name="permanence"
                onChange={handleSelect}
              />
              <TextField
                {...textFieldProps}
                label="Description"
                placeholder="Describe the current situation, define the problem/opportunity and describe proposed change/solution"
                multiline
                rows={4}
                name="description"
                onChange={handleText}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                {...selectFieldProps}
                options={rosters}
                placeholder="Requested By ..."
                className="iso-select"
                name="requestedBy"
                onChange={handleSelect}
              />
              <Select
                {...selectFieldProps}
                options={rosters}
                placeholder="Prepared By ..."
                className="iso-select"
                name="preparedBy"
                onChange={handleSelect}
              />
              <Card>
                <CardHeader
                  title="Attachments"
                  avatar={
                    <Avatar>
                      <AttachFile />
                    </Avatar>
                  }
                />
                <CardContent>
                  {moc.attachments &&
                    moc.attachments.map((file, i) => (
                      <a
                        href={file.url}
                        target="_blank"
                        key={i}
                        rel="noopener noreferrer"
                      >
                        <Chip
                          style={{ cursor: "pointer" }}
                          label={file.fileName}
                        />
                      </a>
                    ))}
                  {moc.attachments && <div style={{ marginTop: 10 }} />}
                  <FileUploader getFileObj={getFileObj} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <MocRisk updates={moc} setUpdates={setMoc} />
        </CardContent>
        <CardActions
          style={{
            float: "right",
          }}
        >
          <Button
            color="primary"
            variant="contained"
            endIcon={<ArrowForward />}
            onClick={handleSubmit}
            disabled={loading}
          >
            Next
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}
