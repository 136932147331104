import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import CoModules from "./company-components/CoModules";
import CoUserList from "./company-components/CoUserList";
import { useDb } from "../../../contexts/DatabaseContext";

export default function CompanyDetail() {
  const { id } = useParams();
  const { getCompanyDocument, mgmtUpdateCompanyDocument } = useDb();
  const [company, setCompany] = useState();
  const [maxCount, setMaxCount] = useState();

  const updateMaxUsers = async () => {
    await mgmtUpdateCompanyDocument(id, {
      maxUsers: maxCount || 99,
    });
    window.location.reload();
  };

  const loadCompany = async () => {
    console.log("Loading Company");
    const c = await getCompanyDocument(id);
    setCompany(c);
    console.log(c);
    setMaxCount(c.maxUsers || 99);
  };

  useEffect(() => {
    return loadCompany();
  }, []);

  if (!company) {
    return <LinearProgress />;
  }

  return (
    <div>
      <h1 style={{ color: "#fff" }}>Company Details</h1>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} lg={8}>
          <CoUserList coId={id} />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Card>
            <CardContent>
              <TextField
                label="Max Users"
                fullWidth
                value={maxCount}
                onChange={(e) => setMaxCount(e.target.value)}
              />
            </CardContent>
            <CardActions>
              <Button onClick={updateMaxUsers} variant="contained">
                Update Max Users
              </Button>
            </CardActions>
          </Card>
          <br />
          <Card>
            <CardHeader title="Modules" />
            <CardContent>
              <CoModules coId={id} />
            </CardContent>
          </Card>
          <br />
          <Card>
            <CardHeader title="Data" />
            <CardContent>
              <Link to={`/companies/${id}/departments`}>
                <Button color="primary" variant="contained">
                  Departments
                </Button>
                <br />
                <br />
              </Link>
              <Link to={`/companies/${id}/sites`}>
                <Button color="primary" variant="contained">
                  Sites
                </Button>
                <br />
                <br />
              </Link>
              <Link to={`/companies/${id}/sources`}>
                <Button color="primary" variant="contained">
                  Action Sources
                </Button>
                <br />
                <br />
              </Link>
              <Link to={`/companies/${id}/isoclasses`}>
                <Button color="primary" variant="contained">
                  ISO Classes
                </Button>
                <br />
                <br />
              </Link>
              <Link to={`/companies/${id}/isoagencies`}>
                <Button color="primary" variant="contained">
                  ISO Agencies
                </Button>
                <br />
                <br />
              </Link>
              <Link to={`/companies/${id}/rosters`}>
                <Button color="primary" variant="contained">
                  Rosters
                </Button>
                <br />
                <br />
              </Link>
              <Link to={`/companies/${id}/priorities`}>
                <Button color="primary" variant="contained">
                  Priorities
                </Button>
                <br />
                <br />
              </Link>
              <Link to={`/companies/${id}/actions`}>
                <Button color="primary" variant="contained">
                  Actions
                </Button>
                <br />
                <br />
              </Link>
              <Link to={`/companies/${id}/doctypes`}>
                <Button color="primary" variant="contained">
                  Doc Types
                </Button>
                <br />
                <br />
              </Link>
              <Link to={`/companies/${id}/documents`}>
                <Button color="primary" variant="contained">
                  Documents
                </Button>
                <br />
                <br />
              </Link>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
