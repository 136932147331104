import React from 'react'
import { useDb } from '../../../../contexts/DatabaseContext'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { IconButton } from '@mui/material'
import { ArrowForward } from '@mui/icons-material'
import IsoDataGrid from '../../../shared/data-grid/IsoDataGrid'

export default function MatrixReportList() {
    const { GetTrainingMatrixReports } = useDb()
    const reports = GetTrainingMatrixReports()

    const columns = [
        { field: 'date', headerName: 'Ran', width: 450 },
        { 
            field: 'action', 
            headerName: 'ViewReport', 
            width: 140,
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                        <Link to={`/training-manager/matrix/${params.row.id}`}>
                            <IconButton size="large"><ArrowForward /></IconButton>
                        </Link>
                     </div>
                );
            }
        }
    ]

    const rows = reports && reports.map(r =>({
        id: r.id,
        date: moment(r.timestamp.toDate()).fromNow()
    }))

    return (
        <div>
            <IsoDataGrid
                data={rows}
                columns={columns}
            />
        </div>
    )
}
