import { faFile } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../contexts/AuthContext'


export default function DocManagerMenuItem({ classes }) {
    const { currentUser } = useAuth()
    
    const [docOpen, setDocOpen] = useState(false);
    return (
        <>
            <ListItem button onClick={() => setDocOpen(!docOpen)}>
                            
                <ListItemIcon><FontAwesomeIcon className={classes.icons} icon={faFile} /></ListItemIcon>
                
                    <ListItemText className={classes.menuText}>Doc Manager</ListItemText>
                
                { docOpen ? <ExpandLess className={classes.icon} /> : <ExpandMore className={classes.icon} /> }
            </ListItem>
            
            <Collapse in={docOpen} timeout="auto" unmountOnExit>
                <List component="div" className={classes.subMenuItem} dense>
                    <ListItem>
                        <Link to='/doc-manager' className="drawer-item">
                            <ListItemText className={classes.menuText}>Doc Dashboard</ListItemText>
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link to='/doc-manager/doc-register'>
                            <ListItemText className={classes.menuText}>Document Register</ListItemText>
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link to='/doc-manager/my-favs'>
                            <ListItemText className={classes.menuText}>My Favourites</ListItemText>
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link to='/doc-manager/pending-approval'>
                            <ListItemText className={classes.menuText}>Pending Approval</ListItemText>
                        </Link>
                    </ListItem>
                    {
                        currentUser.companyAdmin || currentUser.accessRights.docManager < 2
                        ?
                        <ListItem>
                            <Link to='/master-list/doc-types'className="drawer-item">
                                <ListItemText className={classes.menuText}>Document Types</ListItemText>
                            </Link>
                        </ListItem>
                        :
                        null
                    }
                    
                    <ListItem>
                        <Link to='/doc-manager/archives'className="drawer-item">
                            <ListItemText className={classes.menuText}>Document Archive</ListItemText>
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link to='/doc-manager/templates'className="drawer-item">
                            <ListItemText className={classes.menuText}>Document Templates</ListItemText>
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link to='/doc-manager/shared-folders'className="drawer-item">
                            <ListItemText className={classes.menuText}>Shared Folders</ListItemText>
                        </Link>
                    </ListItem>
                    {
                        currentUser.companyAdmin
                        ?
                        <ListItem>
                            <Link to='/doc-settings/'className="drawer-item">
                                <ListItemText className={classes.menuText}>Doc Settings</ListItemText>
                            </Link>
                        </ListItem>
                        :
                        null
                    }
                    
                </List>
            </Collapse>
        </>
    )
}
