import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { Save } from '@mui/icons-material'
import React, { useState } from 'react'
import { useDb } from '../../../../contexts/DatabaseContext'
import Select from 'react-select'
import { selectFieldProps } from '../../../shared/constants'
import BlraEditArea from './BlraEditArea'

export default function BlraEditPopup({ open, close, blraId, blra }) {

    const { GetSites, updateBlra } = useDb();

    const [state, setState] = useState({
        loading: false,
        error: null,
        updated: false,
        data: {
            
        }
    });

    const sites = GetSites();
    

    const handleClose = () => {
        setState({
            data: {},
            updated: false,
            loading: false,
            error: null
        });
        close();
    };

    const handleSelect = (v, e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.name]: v
            },
            updated: true
        });
        console.log(state.data)
    };

    function getStringBetween(str, start, end) {
        const result = str.match(new RegExp(start + "(.*)" + end));
        return result[1];
    }

    const handleSubmit = async () => {
        // Check if the site has changed, if so change the title of the BLRA
        const blraNumber = getStringBetween(blra.blra, "BLRA-", "-")
        const newBlra = `BLRA-${blraNumber}-${state.data.site.label}`
        setState({
            ...state,
            loading: true,
            error: null
        });
        try {
            await updateBlra(blraId, { ...state.data, blra: newBlra })
            handleClose();
        }
        catch(err) {
            setState({
                ...state,
                loading: false,
                error: err.message
            });
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>Edit Baseline</DialogTitle>
            <DialogContent>
                <Select 
                    {...selectFieldProps}
                    placeholder="Select Site ..."
                    options={sites}
                    onChange={handleSelect}
                    name="site"
                />
                {
                    state.data?.site && <BlraEditArea
                        site={state.data.site}
                        handleSelect={handleSelect}
                    />
                }
                {
                    state.error && <Typography color="secondary">{state.error}</Typography>
                }
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    onClick={handleClose}
                    disabled={ state.loading }
                >
                    Cancel
                </Button>
                {
                    state.updated && <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Save />}
                        onClick={handleSubmit}
                        disabled={ state.loading || !state.data.site || !state.data.area }
                    >
                        Confirm
                    </Button>
                }
            </DialogActions>
        </Dialog>
    )
}
