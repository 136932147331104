import { Card, CardContent, FormControlLabel, Snackbar, Switch } from '@mui/material'
import React, { useState } from 'react'
import { useAuth } from '../../../contexts/AuthContext'
import { useDb } from '../../../contexts/DatabaseContext'
import FloatingHelpComponent from '../../help/FloatingHelpComponent'

export default function DocManagerSettings() {
    const { currentUser } = useAuth()
    const { toggleExtendedDocMode } = useDb()
    
    const [loaded, setLoaded] = useState(false)
    const [state, setState] = useState({
        extended: currentUser.extendedDocs
    })
    const [snackOpen, setSnackOpen] = useState(false)

    const handleSwitch = async e => {
        setState({
            ...state,
            [e.target.name]: e.target.checked
        })
        await toggleExtendedDocMode(e.target.checked).then(() => {
            setSnackOpen(true)
            setTimeout(2000)
            window.location.reload()
        })
    }


    return (
        <div>
            <Card>
                <CardContent>
                <FormControlLabel
                    control={
                    <Switch
                        checked={state.extended}
                        onChange={handleSwitch}
                        name="extended"
                        color="primary"
                    />
                    }
                    label="Extended L Levels (Up to L8)"
                />
                </CardContent>
            </Card>

            <Snackbar
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
                }}
                open={snackOpen}
                autoHideDuration={5000}
                onClose={() => setSnackOpen(false)}
                message="Document Settings Updated!"
            />

            <FloatingHelpComponent
                name="Document Settings" 
                videoPath="doc_manager"
                videoIndex={9}
            />
        </div>
    )
}
