import { Avatar, Button, Card, CardActions, CardContent, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography } from '@mui/material'
import { AddBox, ArrowBack, ArrowForward, Delete, Person } from '@mui/icons-material'
import React from 'react'
import Select from 'react-select'
import { useDb } from '../../../../contexts/DatabaseContext'
import { selectFieldProps } from '../../../shared/constants'
import AddParticipant from './AddParticipant'
import { useHistory } from 'react-router-dom'

export default function AddBaselineRisk1() {
    const history = useHistory()
    const { GetSites, createBaseline } = useDb()
    const sites = GetSites()

    const [state, setState] = React.useState({
        loading: false,
        addOpen: false,
        error: null,
        people: [],
        site: null
    })

    const handleAdd = (person) => {
        let peopleArray = state.people
        peopleArray.push(person)
        setState({
            ...state,
            people: peopleArray
        })
    }

    const handleRemove = (i) => {
        let userArray = state.people
        userArray.splice(i, 1)
        setState({
            ...state,
            people: userArray,
        })
    }

    const handleOpen = () => {
        setState({
            ...state,
            addOpen:true
        })
    }

    const handleClose = () => {
        setState({
            ...state,
            addOpen: false
        })
    }

    const handleSubmit = async () => {
        console.log(state.people)
        setState({
            ...state,
            loading: true,
            error: null
        })
        try {
            const blraId = await createBaseline({ people: state.people, site: state.site })
            history.push(`/risk-manager/baselines/add-baseline-2/${blraId}/${state.site.value}`)
        }
        catch(err) {
            setState({
                ...state,
                loading: false,
                error: err.message
            })
        }
    }

    return (
        <div>
            <h1>New Baseline Risk Assessment</h1>
            <div className="back-link">
                <Button
                    variant="contained"
                    startIcon={<ArrowBack />}
                    color="primary"
                >
                    Back
                </Button>
            </div>
            <Typography>Select Site:</Typography>
            <Select
                {...selectFieldProps}
                options={sites}
                className="iso-select"
                onChange={val => setState({ ...state, site: val })}
            />  
            <Typography>Participants:</Typography>
            <Card>
                <CardContent>
                    <List>
                        {
                            state.people && state.people.map((u, i) => {
                                return (
                                    <ListItem key={i} divider>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <Person />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText>
                                            {u.user.label} - Occupation: {u.occupation} - Experience: {u.experience} - Department: {u.department.label}
                                        </ListItemText>
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                size="small"
                                                onClick={() => handleRemove(i)}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                    { state.error && <Typography color="secondary">{state.error}</Typography>}
                </CardContent>
                <CardActions className="iso-right">
                    <Button
                        color="primary"
                        variant="contained"
                        startIcon={<AddBox />}
                        onClick={handleOpen}
                    >
                        Add Participant
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        endIcon={<ArrowForward />}
                        disabled={
                            state.loading || !state.people || state.people.length < 1 || !state.site
                        }
                        onClick={handleSubmit}
                    >
                        Next
                    </Button>
                </CardActions>
            </Card>
            
            <AddParticipant
                open={state.addOpen}
                close={handleClose}
                add={handleAdd}
            />
        </div>
    )
}
