import { Grid } from '@mui/material'
import React from 'react'
import { useDb } from '../../../../../contexts/DatabaseContext'
import SelectedSignBlock from './SelectedSignBlock'

export default function SelectedSignsOfType({ ibraId, riskId, type }) {
    const { GetIsoSelectedSigns } = useDb()
    const signs = GetIsoSelectedSigns(ibraId, riskId, type)

    return (
        <Grid container spacing={3}>
            {
                signs && signs.map(s => (
                    <Grid item xs={4} md={3} key={s.id}>
                        <SelectedSignBlock
                            sign={s}
                            ibraId={ibraId}
                        />
                    </Grid>
                ))
            }
        </Grid>
    )
}
