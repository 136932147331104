import { Avatar, Button, Card, CardHeader, Container, Grid, LinearProgress, Typography } from '@mui/material'
import { ArrowBack, People } from '@mui/icons-material'
import React, { useState, useEffect } from 'react'
import { useDb } from '../../contexts/DatabaseContext'
import PlanCard from './PlanCard'
import EnterpriseCard from './EnterpriseCard'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { functions } from '../../firebase'

export default function AddPlan() {
    const { getProducts, checkout } = useDb()
    const { currentUser } = useAuth()
    const history = useHistory()
    const [products, setProducts] = useState()
    const [loading, setLoading] = useState(true)
    const [userCount, setUserCount] = useState()

    const getCurrentUserCount = async () => {
        const functionRef = functions
        .httpsCallable('getUserCount');

        const count = await functionRef({ companyId: currentUser.companyId });
        setUserCount(count.data)
        setLoading(false)
    }

    useEffect(() => {
        const count = getCurrentUserCount();
        return count
    }, []);

    useEffect(() => {
        const fetchProducts = async () => {
            const p = await getProducts()
            setProducts(p)
        }
        fetchProducts()
    }, [getProducts])

    const handlePriceSelect = async (price) => {
        setLoading(true)
        await checkout(price)
    }

    if(!currentUser.companyOwner || currentUser.stripe_exempt) {
        return <div>
            <Typography>
                You are not authorized to view this page.
            </Typography>
        </div>
    }
    else return (
        <div>
            {
                currentUser.subscription_id ? <Typography>You already have subscription</Typography>
                :
                <Container>
                    <h1>Select a Plan</h1>
                    <Button onClick={() => history.goBack()} color="primary" startIcon={<ArrowBack />}>Back</Button>
                    {
                        !loading && 
                        <Card>
                            <CardHeader
                                title={`Users on Plan: ${ userCount && userCount }`}
                                avatar={
                                    <Avatar>
                                        <People />
                                    </Avatar>
                                }
                            />
                        </Card>
                    }
                    <br/>
                    <Grid container spacing={3}>
                        {
                            userCount && products && products.map(p => (
                                <Grid item xs={12} md={4} key={p.id}>
                                    <PlanCard 
                                        plan={p} 
                                        selectPlan={handlePriceSelect} 
                                        loading={loading}
                                        userCount={userCount}
                                    />
                                </Grid>
                            ))
                        }
                        {
                            products && 
                            <Grid item xs={12} md={4}>
                                <EnterpriseCard loading={loading} />
                            </Grid>
                        }
                    </Grid>
                </Container>
            }
            
        </div>
    )
}
