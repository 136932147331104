import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'

export default function ActionExtensionView() {

  const { actionId, reqId } = useParams()
  const { getActionExtensionRequest } = useDb()
  const [req, setReq] = useState({})

  useEffect(async () => {
    const data = await getActionExtensionRequest(actionId, reqId)
    setReq(data)
  }, [reqId])

  return (
    <div>
      <h2>Action Extension Declined</h2>
      <p><b>Reason:</b> {req.reason}</p>
      <p><b>Declined Reason:</b> {req.declineReason}</p>
    </div>
  )
}
