import React, { useState} from 'react'
import { useParams, Link } from 'react-router-dom'
import { useDb } from '../../../../../contexts/DatabaseContext'
import { Button, Card, CardActions, CardContent, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { AddBox, ArrowLeftOutlined, ArrowRightAltSharp, Edit, Folder } from '@mui/icons-material'
import { Pagination } from '@mui/material';
import moment from 'moment'
import CoAddSupDoc from './CoAddSupDoc'

export default function CoSupDocs() {
    const { coId, docId } = useParams()
    const { MgmtGetSupportingDocs } = useDb()
    const supDocs = MgmtGetSupportingDocs(coId, docId)

    // Pagination
    const itemsPerPage = 10 // <- Not state but related to below pagination
    const [page, setPage] = useState(1)
    const [noOfPages] = useState(
        Math.ceil(supDocs.length / itemsPerPage)
    )

    const [addOpen, setAddOpen] = useState(false)

    return (
        <div>
            <h1 style={{ color: '#fff' }}>Docs Submitted on Document: {docId}</h1>
            <Card>
                <CardContent>
                    <Link to={`/companies/${coId}/documents`}>
                        <Button startIcon={<ArrowLeftOutlined />} color="primary" variant="contained">Back</Button>
                    </Link>
                    <br/><br/>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>FileName</TableCell>
                                    <TableCell>Created</TableCell>
                                    <TableCell>Expires</TableCell>
                                    <TableCell>Expiry Date</TableCell>
                                    <TableCell>Folder</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    supDocs && supDocs
                                    .slice((page -1) * itemsPerPage, page * itemsPerPage)
                                    .map(s => {
                                        return (
                                            <TableRow key={s.id}>
                                                <TableCell>{s.fileName}</TableCell>
                                                <TableCell>{moment(s.createdAt.toDate()).format('DD/MM/YYYY')}</TableCell>
                                                <TableCell>{s.expires ? 'YES' : 'NO'}</TableCell>
                                                <TableCell>{s.expires ? moment(s.expiryDate.toDate()).format('DD/MM/YYYY') : 'N/A'}</TableCell>
                                                <TableCell>{s.folder ? s.folder.label : 'N/A'}</TableCell>
                                                <TableCell>
                                                    <IconButton size="large">
                                                        <Edit />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
                <CardActions>
                    <Pagination
                        count={Math.ceil(supDocs.length / itemsPerPage)}
                        page={page}
                        onChange={(e, val) => setPage(val)}
                        defaultPage={1}
                        color="primary"
                        size="large"
                        showFirstButton
                        showLastButton
                    /> 
                    <Button onClick={() => setAddOpen(true)} variant="contained" color="primary" startIcon={<AddBox />}>Add File</Button>
                    <Link to={`/companies/${coId}/subfolders/${docId}`}>
                        <Button variant="contained" color="primary" startIcon={<Folder />}>SubFolders</Button>
                    </Link>
                </CardActions>
            </Card>

            
            <CoAddSupDoc open={addOpen} closeDialog={() => setAddOpen(false)} coId={coId} docId={docId}/>
        </div>
    );
}


