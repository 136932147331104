import { CircularProgress, FormControlLabel, FormGroup, Snackbar, Switch } from '@mui/material'
import React, { useState } from 'react'
import { useDb } from '../../../../contexts/DatabaseContext'

export default function CoModules({ coId }) {
    const { updateCompanyModules, getCompanyModules, createTrainingRiskProfile } = useDb()
    const [state, setState] = useState({
        
    })
    const [loaded,setLoaded] = useState(false)
    const [snackOpen, setSnackOpen] = useState(false)
    
    const firstLoad = async () => {
        const modules = await getCompanyModules(coId)
        setState(modules)
        setLoaded(true)
    }

    const handleChange = async (e) => {
        console.log(e.target.checked)
        setState({...state, [e.target.name]: e.target.checked })
        if(e.target.name === 'trainingManager') {
            await createTrainingRiskProfile(coId)
        }
        await updateCompanyModules(coId, {...state, [e.target.name]: e.target.checked }).then(() => {
            setSnackOpen(true)
        })
    }

    if(!loaded) {
        firstLoad()
        return <CircularProgress />
    }
    else return (
        <div>
            <FormGroup>
                <FormControlLabel
                    control={<Switch checked={state.docManager} onChange={handleChange} name="docManager"  color="primary"/>}
                    label="Doc Manager"
                />
                <FormControlLabel
                    control={<Switch checked={state.actionManager} onChange={handleChange} name="actionManager"  color="primary"/>}
                    label="Action Manager"
                />
                <FormControlLabel
                    control={<Switch checked={state.otpManager} onChange={handleChange} name="otpManager"  color="primary"/>}
                    label="OTP Manager"
                />
                <FormControlLabel
                    control={<Switch checked={state.trainingManager} onChange={handleChange} name="trainingManager"  color="primary"/>}
                    label="Training Manager"
                />
                <FormControlLabel
                    control={<Switch checked={state.riskManager} onChange={handleChange} name="riskManager"  color="primary"/>}
                    label="Risk Manager"
                />
                <FormControlLabel
                    control={<Switch checked={state.auditManager} onChange={handleChange} name="auditManager"  color="primary"/>}
                    label="Audit Manager"
                />
                <FormControlLabel
                    control={<Switch checked={state.mocManager} onChange={handleChange} name="mocManager"  color="primary"/>}
                    label="MOC Manager"
                />
                <FormControlLabel
                    control={<Switch checked={state.customerManager} onChange={handleChange} name="customerManager"  color="primary"/>}
                    label="Customer Manager"
                />
            </FormGroup>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                open={snackOpen}
                autoHideDuration={4000}
                message="Modules Updated!"
                onClose={() => setSnackOpen(false)}
            />
        </div>
    )
}
