import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Edit, Save } from '@mui/icons-material'
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import FloatingHelpComponent from '../../help/FloatingHelpComponent'
import { textFieldProps } from '../../shared/constants'

const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: theme.palette.cardBg
    },
    dialog: {
        backgroundColor: theme.palette.dialogBg
    }
}))

export default function ActionPriorities() {
    const classes = useStyles()
    const { GetPriorities, updatePriority } = useDb()
    const priorities = GetPriorities()

    // Editor State
    const [open,setOpen] = useState(false)
    const [pry, setPry] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const handleOpen = (p) => {
        setPry(p)
        setOpen(true)
    }

    const handleClose = () => {
        setPry(null)
        setOpen(false)
    }

    const handleUpdate = async () => {
        setLoading(true)
        try {
            await updatePriority(pry.id, pry.days).then(() => {
                setLoading(false)
                handleClose()
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    return (
        <div>
            <Grid container spacing={3}>
                {
                    priorities && priorities.map((p) => {
                        return (
                            <Grid item xs={12} md={4} key={p.id}>
                                <Card className={classes.card}>
                                    <CardHeader
                                        className={'iso-card-header capitalize'}
                                        title={`${p.id} Priority`}
                                        avatar={<FontAwesomeIcon icon={faExclamationTriangle}/>}
                                    />
                                    <CardContent className={'iso-card-content'}>
                                        <Typography>Alert After: {p.days} Days</Typography>
                                    </CardContent>
                                    <CardActions className={'iso-card-header'}>
                                        <Button onClick={() => handleOpen(p)} color="primary" variant="contained" startIcon={<Edit/>}>Edit</Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        )
                    })
                }
            </Grid>
            <Dialog open={open} onClose={handleClose} maxWidth={'sm'} fullWidth>
                <DialogContent className={classes.dialog}>
                    <TextField {...textFieldProps} label={'Set Days'} value={pry && pry.days} onChange={(e) => setPry({...pry, days: e.target.value})} type={'number'}/>
                </DialogContent>
                <DialogActions className={classes.dialog}>
                    { error ? <span className={'iso-error'}>{error}</span> : null }
                    <Button onClick={handleClose} disabled={loading} color={'secondary'}>Cancel</Button>
                    <Button onClick={handleUpdate} disabled={loading || pry && pry.days < 0} color={'primary'} variant={'contained'} startIcon={<Save />}>Update</Button>
                </DialogActions>
            </Dialog>

            <FloatingHelpComponent
                name="Action Priorities" 
                videoPath="action_manager"
                videoIndex={3}
            />
        </div>
    )
}
