import { Add } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
} from "@mui/material";
import React from "react";
import AddQuestion from "../questions/AddQuestion";
import { useDb } from "../../../../contexts/DatabaseContext";
import { useAuth } from "../../../../contexts/AuthContext";
import IsoQuestion from "../questions/IsoQuestion";
import EditQuestion from "../questions/EditQuestion";

export default function IsoFormSection({
  section,
  render,
  responses,
  setResponses,
  loading,
}) {
  const { currentUser } = useAuth();
  const admin = currentUser.companyId === "isoAdmin" ? true : false;
  const [addQuestion, setAddQuestion] = React.useState(false);
  const [editQuestion, setEditQuestion] = React.useState(false);
  const [selectedQuestion, setSelectedQuestion] = React.useState(null);
  const { GetCollection, deleteDocFromPath } = useDb();
  const questions = GetCollection(
    admin,
    admin
      ? `masterFormTemplate/${section.formId}/sections/${section.id}/questions`
      : `formTemplate/${section.formId || "mocChecklist"}/sections/${
          section.id
        }/questions`,
    null,
    ["order", "asc"]
  );

  const toggleAddQuestion = () => {
    setAddQuestion(!addQuestion);
  };

  const toggleEditOpen = () => {
    setEditQuestion(!editQuestion);
  };

  const handleEdit = (question) => {
    setSelectedQuestion(question);
    toggleEditOpen();
  };

  const handleDelete = async (question) => {
    console.log(question.path);

    const confirm = window.confirm(
      `Are you sure you want to delete question ${question.order}?`
    );
    if (confirm) {
      // Delete question
      await deleteDocFromPath(question.path);
    }
  };

  return (
    <div>
      <Container>
        <Card elevation={0}>
          <CardContent>
            <Grid container spacing={2}>
              {questions &&
                questions.map((q) => (
                  <Grid item xs={12} key={q.id} md={6}>
                    <IsoQuestion
                      key={q.id}
                      question={q}
                      responses={responses}
                      setResponses={setResponses}
                      loading={loading}
                      handleEdit={handleEdit}
                      render={render || false}
                      handleDelete={handleDelete}
                    />
                  </Grid>
                ))}
            </Grid>
          </CardContent>
          {!render && (
            <CardActions>
              <Button onClick={toggleAddQuestion} startIcon={<Add />}>
                Add Question
              </Button>
            </CardActions>
          )}
        </Card>
      </Container>

      <AddQuestion
        open={addQuestion}
        close={toggleAddQuestion}
        path={section.path}
        questionLength={questions ? questions.length : 0}
      />

      {selectedQuestion && (
        <EditQuestion
          open={editQuestion}
          close={() => setEditQuestion(false)}
          question={selectedQuestion}
        />
      )}
    </div>
  );
}
