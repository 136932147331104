import { CircularProgress } from '@mui/material'
import React, { useState } from 'react'
import { useAuth } from '../../../contexts/AuthContext'
import { useDb } from '../../../contexts/DatabaseContext'
import DocumentCard from '../document/DocumentCard'
import DocListItem from '../document/DocListItem'
import MyFavDocDatagrid from '../refactor/doc-register/MyFavDocDatagrid'

export default function MyFavDocs({ view }) {
    const { currentUser } = useAuth()
    const { getUserFavoriteDocs, getSingleDocument } = useDb()
    const [userFavDocs, setUserFavDocs] = useState([])
    const [loadComplete, setLoadComplete] = useState(false)
    const [docs, setDocs] = useState([])

    const firstLoad = async () => {
        const favDocs = await getUserFavoriteDocs(currentUser.uid)
        setUserFavDocs(favDocs)
        const docsArray = []
        await favDocs.forEach(async (fDocId) => {
            const fDoc = await getSingleDocument(fDocId)
            docsArray.push(fDoc)
        })
        setDocs(docsArray)
        setLoadComplete(true)   
    }

    if(!loadComplete) {
        firstLoad()
        return (
          <CircularProgress />
        )
    }
    else
    return (
        <div>
        </div>
    )
}
