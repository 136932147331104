import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Button,
  CardActions,
} from "@mui/material";
import { ArrowForward, Info } from "@mui/icons-material";
import React, { useState } from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import AddParticipants from "../../participants/AddParticipants";
import PeopleListContainer from "../../participants/PeopleListContainer";
import { functions } from "../../../firebase";

export default function MocParticipants({ moc, props }) {
  const { title, handleAdvance, collectionName, roleName, handleUpdate } =
    props;
  const { addStandardDoc } = useDb();
  const [loading, setLoading] = useState(false);

  const mocNotification = functions.httpsCallable("mocNotification");

  const handleAdd = async (participant) => {
    setLoading(true);
    try {
      await addStandardDoc(`${moc.path}/${collectionName}/`, participant);
      await handleUpdate({
        [collectionName]: moc[collectionName]
          ? [
              ...moc[collectionName],
              {
                participant: participant.user.value.id,
                approved: false,
                step: 0,
              },
            ]
          : [
              {
                participant: participant.user.value.id,
                approved: false,
                step: 0,
              },
            ],
      });
      await mocNotification({
        mocPath: moc.path,
        partyType: roleName,
        partyCollection: collectionName,
      });
    } catch (err) {
      window.alert(err.message);
    }
    setLoading(false);
  };

  return (
    <div>
      <Card>
        <CardHeader
          title={title}
          subheader={`MOC Title: ${moc.title}`}
          avatar={
            <Avatar>
              <Info />
            </Avatar>
          }
        />
        <CardContent>
          <AddParticipants
            handleAdd={handleAdd}
            moduleName={"mocManager"}
            accessLevel={2}
            roleName={roleName}
          />
          <PeopleListContainer moc={moc} collectioName={collectionName} />
        </CardContent>
        <CardActions
          style={{
            float: "right",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            endIcon={<ArrowForward />}
            disabled={loading}
            onClick={handleAdvance}
          >
            Submit
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}
