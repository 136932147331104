import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useDb } from "../../../../contexts/DatabaseContext";
import { textFieldProps } from "../../../shared/constants";

export default function AddBoneCategory({ open, close, actionId }) {
  const [category, setCategory] = useState();
  const [loading, setLoading] = useState(false);
  const { addFishboneCategory } = useDb();

  const handleClose = () => {
    setCategory();
    setLoading(false);
    close();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await addFishboneCategory(actionId, category);
      handleClose();
    } catch (err) {
      setLoading(false);
      window.alert(err.message);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add Category</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            {...textFieldProps}
            onChange={(e) => setCategory(e.target.value)}
            required
            label="Category Name"
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button
            disabled={loading}
            type="submit"
            color="primary"
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
