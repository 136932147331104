import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";

export default function IsoRadio(props) {
  const { name, onChange, type, loading, value } = props;
  const handleChange = (event, newValue) => {
    if (onChange) {
      onChange(name, newValue, type);
    }
  };

  return (
    <FormControl>
      <RadioGroup
        key={value}
        row
        aria-labelledby="demo-radio-buttons-group-label"
        name={name}
        onChange={handleChange}
        disabled={loading}
        value={value}
      >
        <FormControlLabel
          value="yes"
          control={<Radio disabled={loading} />}
          label="Yes"
        />
        <FormControlLabel
          value="no"
          control={<Radio />}
          disabled={loading}
          label="No"
        />
      </RadioGroup>
    </FormControl>
  );
}
