import { faEnvelope, faMinusCircle, faPaperPlane, faPlusSquare, faUserTag } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Button, Card, CardContent, CardHeader, Chip, IconButton, LinearProgress, List, ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material'
import React, { useState } from 'react'
import Select from 'react-select'
import { useDb } from '../../../../contexts/DatabaseContext'
import { selectFieldProps } from '../../../shared/constants'
import { useParams, Link } from 'react-router-dom'
import { ArrowLeftOutlined, ArrowRightOutlined, AttachFile } from '@mui/icons-material'

export default function DocApprovers() {
    const { docId } = useParams()
    const { 
        savePartyToDoc, 
        removePartyFromDoc, 
        bulkEmailDocParties, 
        GetInterestedParties,
        GetUsersLevelFilter,
        getDocument
    } = useDb()
    const rosters = GetUsersLevelFilter('docManager', 1)
    const parties = GetInterestedParties(docId, false, null)
    const [party, setParty] = useState(null)
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [emailDisable, setEmailDisable] = useState(false) 
    const [doc,setDoc] = useState(null)
    const [loaded, setLoaded] = useState(false)
    
    const addParty = async (party) => {
        setLoading(true) 
        try {
            await savePartyToDoc(party, doc.id).then(() => {
                setParty(null)
                setLoading(false)
                setEmailDisable(false)
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
            setEmailDisable(true)
        }
    }

    const removeParty = async (party) => {
        setLoading(true)
        setError(null)
        try {
            await removePartyFromDoc(doc.id, party.id).then(() => {
                setLoading(false)
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    const emailAll = async () => {
        setLoading(true)
        setError(null)
        try {
            await bulkEmailDocParties(doc.id, parties).then(() => {
                setLoading(false)
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    const emailOne = async (pty) => {
        setLoading(true)
        setError(null)
        try {
            await bulkEmailDocParties(doc.id, [pty]).then(() => {
                setLoading(false)
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    const firstLoad = async () => {
        const d = await getDocument(docId)
        setDoc(d)
        setLoaded(true)
    }

    if(!loaded) {
        firstLoad()
        return <LinearProgress />
    }
    else return <>
        <h1>Approvers for {doc.docCode}</h1>
        <Link to={`/doc-manager/doc-detail/${docId}`}>
            <Button disabled={ loading } color="primary" variant="contained" startIcon={<ArrowLeftOutlined />}>{doc.docCode}</Button>
        </Link>
        <Link to={`/doc-supdocs/${docId}`} style={{ float: 'right'}}>
            <Button disabled={ loading } color="primary" variant="contained" startIcon={<AttachFile />} endIcon={<ArrowRightOutlined />}>Next: Supporting Docs</Button>
        </Link>
        <br/><br/>
        <Select 
            options={rosters}
            placeholder="Add Approver ..."
            onChange={(val => setParty(val))}
            {...selectFieldProps}
        />
        <span className="iso-small" style={{ color: 'red' }}>{error}</span>
        <br/>
        <Button onClick={() => addParty(party)} disabled={party !== null ? false : true } color="primary" variant="contained" startIcon={<FontAwesomeIcon icon={faPlusSquare} />}>Add New</Button>&nbsp;
        <Button disabled={ emailDisable || loading } onClick={emailAll} color="primary" variant="contained" startIcon={<FontAwesomeIcon icon={faEnvelope} />}>Send Email</Button>
        <br/><br/>
        
        <Card>
            <CardHeader
                title="Approvers:"
                avatar={<Avatar><FontAwesomeIcon icon={faUserTag}/></Avatar>}
            />
            <CardContent>
                <List>
                    {
                        parties && parties.map((pty) => {
                            return (
                                <ListItem key={pty.id} className="iso-list-item">
                                    <ListItemText><span className="iso-small">{`${pty.firstName} ${pty.lastName}`} <Chip size="small" label={pty.status}/></span></ListItemText>
                                    <ListItemSecondaryAction>
                                        <IconButton onClick={() => removeParty(pty)} size="large"><FontAwesomeIcon icon={faMinusCircle}/></IconButton>
                                        <IconButton onClick={() => emailOne(pty)} size="large"><FontAwesomeIcon icon={faPaperPlane}/></IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })
                    }
                </List>
            </CardContent>
        </Card>
        
    </>;
}
