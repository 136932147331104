import React, { useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import {
  Drawer,
  AppBar,
  Toolbar,
  CssBaseline,
  IconButton,
  Typography,
  Avatar,
  Menu,
  MenuItem,
  Card,
  CardHeader,
  Collapse,
  Badge,
} from "@mui/material/";
import MenuIcon from "@mui/icons-material/Menu";
import clsx from "clsx";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeadset, faTachometerAlt } from "@fortawesome/free-solid-svg-icons";
import logo from "../../img/gold_logo.png";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import Routes from "../routes/Routes";
import TrialExpiredRoutes from "../routes/TrialExpiredRoutes";
import Notifications from "../dashboard/notifications/Notifications";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useDb } from "../../contexts/DatabaseContext";
import EditProfile from "../profile/EditProfile";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness5Icon from "@mui/icons-material/Brightness5";
import DocManagerMenuItem from "./menu-items/DocManagerMenuItem";
import ActionManagerMenuItem from "./menu-items/ActionManagerMenuItem";
import MasterListMenuItem from "./menu-items/MasterListMenuItem";
import OtpManagerMenuItem from "./menu-items/OtpManagerMenuItem";
import TrainingManagerMenuItem from "./menu-items/TrainingManagerMenuItem";
import RiskManagerMenuItem from "./menu-items/RiskManagerMenuItem";
import AuditManagerMenuItem from "./menu-items/AuditManagerMenuItem";
import MocManagerMenuItem from "./menu-items/MocManagerMenuItem";
import CustomerManagerMenuItem from "./menu-items/CustomerManagerMenuItem";
import SupportDialog from "./SupportDialog";
import TrialCounter from "./TrialCounter";
import AccountBlockedRoutes from "../routes/AccountBlockedRoutes";
import FacilitatorMenu from "./FacilitatorMenu";
import EmailVerification from "./EmailVerification";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  icons: {
    color: theme.palette.mainMenuIcon,
    marginLeft: theme.spacing(2),
  },
  menuText: {
    color: theme.palette.mainMenuText,
    textDecoration: "none",
  },
  subMenuItem: {
    paddingLeft: 55,
  },
  root: {
    display: "flex",
    backgroundColor: theme.palette.background,
  },
  appBar: {
    backgroundColor: theme.palette.appBarBg,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    background: theme.palette.mainMenuBg,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    // backgroundColor: '#262b49',
    height: "100vh", // <------ THIS May Cause issues remove if necessary
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const MainMenu = (props) => {
  const { userTheme } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [profileMenuOpen, toggleProfileMenu] = useState(false);
  const [notifAnchorEl, setNotifAnchorEl] = useState(null);
  const { logOut, currentUser } = useAuth();
  const history = useHistory();
  const [notifCount, setNotifCount] = useState(0);
  const [supportOpen, setSupportOpen] = useState(false);

  const { GetUserProfile, retrieveUserManual, retrieveLicense } = useDb();
  const userProfile = GetUserProfile(currentUser.uid);
  const [profileOpen, setProfileOpen] = useState(false);

  // Theme Toggler
  const [lightTheme, setLightTheme] = useState(userTheme);
  const { toggleTheme } = props;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const openProfileMenu = () => {
    toggleProfileMenu(true);
  };

  const closeProfileMenu = () => {
    toggleProfileMenu(false);
  };

  const handleSignOut = async () => {
    try {
      await logOut();
      history.push("/login");
    } catch (err) {
      console.log(err.message);
    }
  };

  const updateNotifCount = (n) => {
    setNotifCount(n);
  };

  const closeProfile = () => {
    setProfileOpen(false);
  };

  const handleThemeChange = () => {
    setLightTheme(!lightTheme);
    toggleTheme(!lightTheme);
  };

  const getUserManual = async () => {
    try {
      await retrieveUserManual().then((url) => {
        // window.location.replace(url)
        window.open(url, "_blank");
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  const getLicenseAgreement = async () => {
    try {
      await retrieveLicense().then((url) => {
        // window.location.replace(url)
        window.open(url, "_blank");
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  const billingExpiredRoutes = {
    stripe: <TrialExpiredRoutes />,
    paystack: "",
    manual: "",
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
            size="large">
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            style={{ flex: 1 }}
            className={classes.menuText}
          >
            <b>ISOMS</b>
            <span className="iso-version">1.5.0</span>
          </Typography>
          <EmailVerification />
          {currentUser.trialing && <TrialCounter currentUser={currentUser} />}
          <IconButton onClick={handleThemeChange} size="large">
            {lightTheme ? <Brightness5Icon /> : <Brightness4Icon />}
          </IconButton>
          <IconButton onClick={() => setSupportOpen(true)} size="large">
            <FontAwesomeIcon icon={faHeadset} />
          </IconButton>
          &nbsp;
          <Menu
            anchorEl={notifAnchorEl}
            open={Boolean(notifAnchorEl)}
            keepMounted
            onClose={() => setNotifAnchorEl(null)}
          >
            <Notifications
              updateCount={updateNotifCount}
              closeMenu={() => setNotifAnchorEl(null)}
            />
          </Menu>
          <div style={{ marginRight: 15, cursor: "pointer" }}>
            <IconButton onClick={(e) => setNotifAnchorEl(e.currentTarget)} size="large">
              <Badge badgeContent={notifCount} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </div>
          <Menu
            id="profile-menu"
            anchorEl={profileMenuOpen}
            //keepMounted
            open={Boolean(profileMenuOpen)}
            onClose={closeProfileMenu}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Card elevation={0}>
              <CardHeader
                avatar={<Avatar src={userProfile.profilePhoto} />}
                title={`${userProfile.firstName} ${userProfile.lastName}`}
                subheader={userProfile.email}
              />
            </Card>
            <MenuItem onClick={() => setProfileOpen(true)}>Profile</MenuItem>
            {/* TODO: Add Paystack Logic here */}
            {currentUser.companyOwner && !currentUser.stripe_exempt ? (
              <MenuItem onClick={() => history.push("/account")}>
                Account
              </MenuItem>
            ) : null}
            <MenuItem onClick={getUserManual}>User Manual</MenuItem>
            <MenuItem onClick={getLicenseAgreement}>License Agreement</MenuItem>
            <MenuItem onClick={handleSignOut}>Logout</MenuItem>
          </Menu>
          <Avatar
            src={userProfile.profilePhoto}
            onClick={openProfileMenu}
            className="profileBtn"
          />
          <EditProfile
            open={profileOpen}
            closeDialog={closeProfile}
            profile={userProfile}
          />
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <div style={{ width: "100%", padding: "10px 10px 10px 0" }}>
            <img src={logo} width="50" alt="ISO Logo" />
          </div>
          <IconButton className={classes.icon} onClick={handleDrawerClose} size="large">
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          {currentUser.facilitator ? (
            <FacilitatorMenu classes={classes} />
          ) : (
            <>
              <Link to="/" className="drawer-item">
                <ListItem>
                  <ListItemIcon>
                    <FontAwesomeIcon
                      className={classes.icons}
                      icon={faTachometerAlt}
                    />
                  </ListItemIcon>
                  <ListItemText className={classes.menuText}>
                    Dashboard
                  </ListItemText>
                </ListItem>
              </Link>
              {/*  MODULE MENU ITEMS */}
              {currentUser.companyAdmin ? (
                <MasterListMenuItem
                  classes={classes}
                  currentUser={currentUser}
                />
              ) : null}
              {currentUser.modules.docManager ? (
                currentUser.accessRights.docManager < 3 ? (
                  <DocManagerMenuItem classes={classes} />
                ) : null
              ) : null}
              {currentUser.modules.actionManager ? (
                currentUser.accessRights.actionManager < 3 ? (
                  <ActionManagerMenuItem
                    classes={classes}
                    currentUser={currentUser}
                  />
                ) : null
              ) : null}
              {currentUser.modules.otpManager ? (
                currentUser.accessRights.otpManager < 3 ? (
                  <OtpManagerMenuItem
                    classes={classes}
                    currentUser={currentUser}
                  />
                ) : null
              ) : null}
              {currentUser.modules.trainingManager ? (
                currentUser.accessRights.trainingManager < 3 ? (
                  <TrainingManagerMenuItem classes={classes} />
                ) : null
              ) : null}
              {currentUser.modules.riskManager ? (
                currentUser.accessRights.riskManager < 3 ? (
                  <RiskManagerMenuItem
                    classes={classes}
                    currentUser={currentUser}
                  />
                ) : null
              ) : null}
              {currentUser.modules.auditManager ? (
                currentUser.accessRights.auditManager < 3 ? (
                  <AuditManagerMenuItem classes={classes} />
                ) : null
              ) : null}
              {currentUser.modules.mocManager ? (
                currentUser.accessRights.mocManager < 3 ? (
                  <MocManagerMenuItem classes={classes} />
                ) : null
              ) : null}
              {currentUser.modules.customerManager ? (
                currentUser.accessRights.customerManager < 3 ? (
                  <CustomerManagerMenuItem classes={classes} />
                ) : null
              ) : null}
            </>
          )}
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <>
          {currentUser.trial_expired && currentUser.trialing ? (
            <TrialExpiredRoutes currentUser={currentUser} />
          ) : currentUser.good_standing ? (
            <Routes />
          ) : (
            <AccountBlockedRoutes />
          )}
        </>
      </main>

      <SupportDialog
        open={supportOpen}
        closeDialog={() => setSupportOpen(false)}
      />
    </div>
  );
};

export default MainMenu;
