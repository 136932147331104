import { Button, Card, CardActions, CardContent, TextField, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDb } from '../../../../../contexts/DatabaseContext';
import { textFieldProps } from '../../../../shared/constants';

export default function CoEditDoc() {
    const { coId, docId } = useParams();
    const { mgmtGetSingleDocumnet, mgmtUpdateSingleDocumnet } = useDb();
    const history = useHistory();

    const [state, setState] = useState({
        loading: false,
        error: null,
        data: {}
    })
    
    const getDoc = async () => {
        const doc = await mgmtGetSingleDocumnet(coId, docId);
        console.log(doc)
        setState({
            loading: false,
            error: null,
            data: {
                ...doc
            },
        })
    }

    useEffect(() => {
        setState({
            ...state,
            loading: true
        })
        return getDoc()
    }, [docId])

    const handleChange = (e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleSubmit = async () => {
        setState({
            ...state,
            loading: true,
            error: null
        })
        try {
            await mgmtUpdateSingleDocumnet(coId, docId, state.data)
            history.goBack()
        }
        catch(err) {
            setState({
                ...state,
                loading: false,
                error: err.message
            })
        }
    }

    if(state.loading) {
        return <LinearProgress />
    }
    else return (
        <div>
            <h1 style={{ color: "#fff" }}>Edit Doc</h1>
            <Card>
                <CardContent>
                    <TextField
                        {...textFieldProps}
                        label="Document Title"
                        value={state.data.docName}
                        onChange={handleChange}
                        name="docName"
                    />
                    <TextField
                        {...textFieldProps}
                        label="Document Code"
                        value={state.data.docCode}
                        onChange={handleChange}
                        name="docCode"
                    />
                    <TextField
                        {...textFieldProps}
                        label="Pending Approval"
                        value={state.data.pendingApproval}
                        onChange={(e) => setState({ ...state, data: { ...state.data, pendingApproval: parseInt(e.target.value)}})}
                        name="pendingApproval"
                        type="number"
                    />
                    {
                        state.error && <Typography color="secondary">{state.error}</Typography>
                    }
                </CardContent>
                <CardActions>
                    <Button disabled={state.loading} onClick={() => history.goBack()} startIcon={<ArrowBack />}>Back</Button>
                    <Button disabled={state.loading} onClick={handleSubmit} color="primary" variant="contained">Save Changes</Button>
                </CardActions>
            </Card>
        </div>
    )
}
 