import { IconButton, ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material'
import { ArrowForward } from '@mui/icons-material'
import React from 'react'
import { Link } from 'react-router-dom'

export default function BlraDetailListItem({ title, link }) {
    return (
        <ListItem divider>
            <ListItemText>{title}</ListItemText>
            <ListItemSecondaryAction>
                <Link to={link}>
                    <IconButton size="large">
                        <ArrowForward />
                    </IconButton>
                </Link>
            </ListItemSecondaryAction>
        </ListItem>
    );
}
