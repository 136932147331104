import { Avatar, Button, ButtonGroup, Card, CardActions, CardContent, CardHeader, IconButton, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from '@mui/material'
import { Equalizer, History, Schedule, School, Search } from '@mui/icons-material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import moment from 'moment'
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid'
import FloatingHelpComponent from '../../help/FloatingHelpComponent'

export default function TrainingSessions() {
    const { GetUpcomingTrainingSessions } = useDb()
    const sessions = GetUpcomingTrainingSessions()

    const columns = [
        { field: 'date', headerName: 'Date' , width: 200 },
        { field: 'module', headerName: 'Module', width: 200 },
        { field: 'facilitator', headerName: 'Facilitator', width: 200 },
        { 
            field: 'action', 
            headerName: 'Action', 
            width: 140,
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                        <Link to={`/training-manager/sessions/detail/${params.row.id}`}>
                            <IconButton size="large">
                                <Search />
                            </IconButton>
                        </Link>
                     </div>
                );
            }
        }
    ]

    const rows = sessions && sessions.map(s  => ({
        id: s.id,
        date: moment(s.startTime.toDate()).format('DD/MM/YYYY'),
        module: s.module.name,
        facilitator: s.facilitator.label
    }))

    return (
        <div>
            <h1>Training Sessions</h1>
            <Card>
                <CardActions>
                    <Link to={'/training-manager/sessions/schedule'}>
                        <Button color="primary" variant="contained" startIcon={<Schedule />}>Schedule Training</Button>
                    </Link>
                    <Link to={'/training-manager/past-sessions'}>
                        <Button color="primary" variant="contained" startIcon={<History />}>Past Sessions</Button>
                    </Link>
                </CardActions>
            </Card>
            <br/>
                <IsoDataGrid
                    columns={columns}
                    data={rows}
                />
            <br/>

            <FloatingHelpComponent
                name="Training Sessions" 
                videoPath="training_manager"
                videoIndex={10}
            />
        </div>
    )
}
