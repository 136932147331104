import React from 'react'
import FloatingHelpComponent from '../../../help/FloatingHelpComponent'
import RPendingRegisterDataGrid from './RPendingRegisterDataGrid'


export default function RPendingRegister({ data }) {
    return (
        <div>
            <RPendingRegisterDataGrid data={data} />
            <FloatingHelpComponent
                name="Documents Pending Approval" 
                videoPath="doc_manager"
                videoIndex={8}
            />
        </div>
    )
}
