import { Avatar, Card, CardContent, CardHeader } from "@mui/material";
import { Info } from "@mui/icons-material";
import React from "react";
import IsoRenderForm from "../form-builder/form-render/IsoRenderForm";

export default function MocExpectedBenefits({ moc, props, approvalView }) {
  const { handleAdvance, title } = props;
  const collectionPath = `moc/${moc.id}/responses`;

  return (
    <div>
      <Card>
        <CardHeader
          title={title}
          subheader={`MOC Title: ${moc.title}`}
          avatar={
            <Avatar>
              <Info />
            </Avatar>
          }
        />
        <CardContent>
          <IsoRenderForm
            formId="benefitChecklist"
            docString={"benefitChecklist"}
            collectionPath={collectionPath}
            handleAdvance={handleAdvance}
            approvalView={approvalView}
          />
        </CardContent>
      </Card>
    </div>
  );
}
