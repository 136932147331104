import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import { Save } from '@mui/icons-material'
import React, { useState } from 'react'
import Select from 'react-select'
import { useDb } from '../../../contexts/DatabaseContext'
import { selectFieldProps } from '../../shared/constants.js'

export default function MgUserEdit({ user, open, closeDialog }) {
    const { GetCompanies, updateUserCompany, makeUserAdmin } = useDb()
    const companies = GetCompanies()
    const [newCompany, setNewCompany] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const handleSave = async () => {
        setLoading(true)
        setError(null)
        try {
            await updateUserCompany(user.id, newCompany).then(() => {
                setLoading(false)
                closeDialog()
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    const handleAdmin = async () => {
        setLoading(true)
        setError(null)
        try {
            await makeUserAdmin(user.id).then(() => {
                setLoading(false)
                closeDialog()
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    return (
        <Dialog maxWidth={'md'} fullWidth open={open} onClose={closeDialog}>
            <DialogContent>
                <Typography>Current Company: {user && user.companyName}</Typography>
                <Select
                    options={companies}
                    {...selectFieldProps}
                    placeholder={'Change Company ...'}
                    onChange={(v) => setNewCompany(v.value)}
                />
                <br/>
                {
                    error
                    ?
                    <span className={'iso-error'}>{error}</span>
                    :
                    null
                }
                {
                    newCompany
                    ?
                    <Button disabled={loading} onClick={handleSave} color={'primary'} variant={'contained'} startIcon={<Save />}>Save Change</Button>
                    :
                    null
                }
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} onClick={handleAdmin} startIcon={<FontAwesomeIcon icon={faExclamationTriangle}/>} color={'primary'} variant={'contained'}>Make User ISO Admin</Button>
            </DialogActions>
        </Dialog>
    )
}
