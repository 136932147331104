import { faBook } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Button, Card, CardActions, CardContent, CardHeader, Grid } from '@mui/material'
import { CloudDownload } from '@mui/icons-material'
import React, { useState } from 'react'
import { useAuth } from '../../../contexts/AuthContext'
import FileUploader from '../../shared/FileUploader'
import { useHistory } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'

export default function UpdateManual() {
    const { currentUser } = useAuth()
    const { replaceUserManual, retrieveUserManual } = useDb()
    const history = useHistory()
    // Action State
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const getUrl = async (url) => {
        try {
            await replaceUserManual(url)
        }
        catch(err) {
            setError(err.message)
            console.log(err.message)
        }
    }

    const getFilename = (fName) => {

    }

    const getFile = (file) => {

    }

    const getUserManual = async () => {
        setLoading(true)
        setError(null)
        try {
            await retrieveUserManual().then(url => {
                window.location.replace(url)
            })
        }
        catch(err) {
            setError(err.message)
            console.log(err.message)
        }
    }

    return (
        <div>
            <h1 style={{ color: '#fff'}}>User Manual</h1>
            

            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                    <Card>
                        <CardHeader
                            avatar={
                                <Avatar>
                                    <FontAwesomeIcon icon={faBook} />
                                </Avatar>
                            }
                            title="Current User Manual"
                        />
                        <CardActions>
                            <Button onClick={getUserManual} variant="contained" color="primary" startIcon={<CloudDownload />}>Download</Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Card>
                        <CardHeader
                            avatar={
                                <Avatar>
                                    <FontAwesomeIcon icon={faBook} />
                                </Avatar>
                            }
                            title="Replace Manual"
                        />
                        <CardContent>
                            <FileUploader
                                getUrl={getUrl}
                                getFilename={getFilename}
                                getFileObj={getFile}
                                userId={currentUser.uid}
                            />
                        </CardContent>
                    </Card>
                    
                </Grid>
            </Grid>
        </div>
    )
}
