import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Button,
  Grid,
  Avatar,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import {
  textFieldProps,
  selectFieldProps,
  employeeTypes,
  countries,
  genders,
  races,
} from "../../shared/constants";
import Select from "react-select";
import noprofile from "../../../img/noprofile.png";
import FileUploader from "../../shared/FileUploader";
import { useAuth } from "../../../contexts/AuthContext";
import { useDb } from "../../../contexts/DatabaseContext";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { Save } from "@mui/icons-material";
import { useEffect } from "react";

export default function NewEmployeeForm({ open, closeDialog }) {
  const {
    createNewEmployee,
    GetOccupations,
    GetUsersLevelFilter,
    GetSites,
    GetDepartments,
    checkEmployeeExists,
  } = useDb();
  const jobs = GetOccupations();
  const hods = GetUsersLevelFilter("trainingManager", 1);
  const sites = GetSites();
  const depts = GetDepartments();

  // Action State
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // Form State
  const [state, setState] = useState({
    employmentDate: new Date(),
  });
  const [usePassport, setUsePassport] = useState(false);
  const [linkUser, setLinkUser] = useState(true);

  // Profile Photo Required State and Functions
  const [profilePhoto, setProfilePhoto] = useState(noprofile);
  const { currentUser } = useAuth();
  const getProfilePicUrl = (url) => {
    setProfilePhoto(url);
    setState({
      profilePhoto: url,
    });
  };
  const getFilename = (fileName) => {};
  const getFileObj = (fO) => {};

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelect = (v, e) => {
    setState({
      ...state,
      [e.name]: v ? v.value : null,
    });
  };

  const handleManagerChange = (v, e) => {
    setState({
      ...state,
      manager: v.value,
    });
    if (!linkUser) {
      setState({
        ...state,
        email: v.value.email,
        manager: v.value,
      });
    }
  };

  console.log(state);

  useEffect(() => {
    setUsePassport(false);
    setLinkUser(true);
  }, []);

  const handleMultiSelect = (val, e) => {
    const jobIdArray = val ? val.map((v) => v.id) : [];
    setState({
      ...state,
      [e.name]: val,
      occupationIds: jobIdArray,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log({ ...state, linkUser });
    setLoading(true);
    setError(null);
    try {
      if (linkUser) {
        await checkEmployeeExists(state.email);
      }
      await createNewEmployee({ ...state, linkUser }).then(() => {
        setLoading(false);
        handleClose();
      });
      window.location.reload();
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const handleClose = () => {
    setState({
      employmentDate: new Date(),
    });
    setLoading(false);
    setError(null);
    closeDialog();
  };

  return (
    <Dialog maxWidth="lg" fullWidth open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Typography>Create Employee</Typography>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <Avatar
                src={profilePhoto}
                style={{
                  height: 180,
                  width: 180,
                }}
              />
              <br />
              <FileUploader
                getUrl={getProfilePicUrl}
                userId={currentUser.uid}
                getFilename={getFilename}
                getFileObj={getFileObj}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                {...textFieldProps}
                label="First Name"
                onChange={handleChange}
                required
                name="firstName"
              />
              <TextField
                {...textFieldProps}
                label="Middle Name"
                onChange={handleChange}
                name="middleName"
              />
              <TextField
                {...textFieldProps}
                label="Last Name"
                onChange={handleChange}
                required
                name="lastName"
              />

              <TextField
                {...textFieldProps}
                label="Employee Number"
                onChange={handleChange}
                name="employeeNumber"
              />
              <FormControlLabel
                value={linkUser}
                control={
                  <Checkbox
                    defaultChecked={true}
                    value={linkUser}
                    onClick={() => setLinkUser(!linkUser)}
                  />
                }
                label="Create/Link User Account?"
              />
              {linkUser && (
                <TextField
                  {...textFieldProps}
                  label="Email"
                  onChange={handleChange}
                  name="email"
                  required
                />
              )}
              <Select
                placeholder="Employee Type ..."
                className="iso-select"
                {...selectFieldProps}
                onChange={handleSelect}
                name="employeeType"
                options={employeeTypes}
              />
              <input
                autoComplete="off"
                style={{ opacity: 0, height: 0, marginTop: -20, width: 0 }}
                value={state.employeeType}
                required
              />
              <br />
              <TextField
                {...textFieldProps}
                label="Contact Number"
                onChange={handleChange}
                name="contactNumber"
                required
              />
              {currentUser.nationality || currentUser.nationality === "ZA" ? (
                <Select
                  placeholder="Race / Ethnicity ..."
                  className="iso-select"
                  {...selectFieldProps}
                  onChange={handleSelect}
                  name="race"
                  options={races}
                />
              ) : null}
              <br />
              <Select
                placeholder="Gender ..."
                className="iso-select"
                {...selectFieldProps}
                onChange={handleSelect}
                name="gender"
                options={genders}
              />
              <input
                autoComplete="off"
                style={{ opacity: 0, height: 0, marginTop: -20, width: 0 }}
                value={state.gender}
                required
              />
              <br />
              <TextField
                {...textFieldProps}
                label="Disability"
                onChange={handleChange}
                name="disability"
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Employment Date"
                  onChange={(d) =>
                    setState({
                      ...state,
                      employmentDate: d.toDate(),
                    })
                  }
                  defaultValue={dayjs(state.employmentDate)}
                />
              </LocalizationProvider>

              <br />
              <br />
              <Select
                placeholder="Occupation ..."
                className="iso-select"
                {...selectFieldProps}
                onChange={handleMultiSelect}
                name="occupation"
                options={jobs}
                isMulti
              />
              <Select
                placeholder="Manager ..."
                className="iso-select"
                {...selectFieldProps}
                onChange={handleManagerChange}
                name="manager"
                options={hods}
              />
              <br />
              <Select
                placeholder="Department ..."
                className="iso-select"
                {...selectFieldProps}
                onChange={handleSelect}
                name="department"
                options={depts}
              />
              <input
                autoComplete="off"
                style={{ opacity: 0, height: 0, marginTop: -20, width: 0 }}
                value={state.department}
                required
              />
              <br />
              <Select
                placeholder="Nationality ..."
                className="iso-select"
                {...selectFieldProps}
                onChange={handleSelect}
                name="nationality"
                options={countries}
              />
              <input
                autoComplete="off"
                style={{ opacity: 0, height: 0, marginTop: -20, width: 0 }}
                value={state.nationality}
                required
              />
              <br />
              <FormControlLabel
                label="Use Passport Number"
                control={
                  <Checkbox
                    defaultValue={false}
                    value={usePassport}
                    onClick={() => setUsePassport(!usePassport)}
                  />
                }
              />
              <br />
              {usePassport ? (
                <TextField
                  {...textFieldProps}
                  label="Passport Number"
                  onChange={handleChange}
                  required
                  name="passport"
                />
              ) : (
                <TextField
                  {...textFieldProps}
                  label="Employee ID"
                  onChange={handleChange}
                  required
                  name="employeeId"
                />
              )}
              <TextField
                {...textFieldProps}
                label="Work Permit Number"
                onChange={handleChange}
                name="permitNumber"
              />
              <Select
                placeholder="Site ..."
                className="iso-select"
                {...selectFieldProps}
                onChange={handleSelect}
                name="site"
                options={sites}
              />
              <input
                autoComplete="off"
                style={{ opacity: 0, height: 0, marginTop: -20, width: 0 }}
                value={state.site}
                required
              />
              <br />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {error ? <span className="iso-error">{error}</span> : null}
          <Button disabled={loading} onClick={closeDialog} color="secondary">
            Cancel
          </Button>
          <Button
            disabled={
              loading ||
              !state.occupation ||
              !state.employmentDate ||
              !state.manager
            }
            variant="contained"
            startIcon={<Save />}
            type="submit"
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
