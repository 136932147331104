import { faSave, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  CardContent,
  LinearProgress,
  CardActions,
  Button,
  Box,
  Typography,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { storage } from "../../../firebase";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Select from "react-select";
import { useDb } from "../../../contexts/DatabaseContext";
import { selectFieldProps } from "../../shared/constants";
import { useParams, Link, useHistory } from "react-router-dom";
import { ArrowLeftOutlined } from "@mui/icons-material";

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function AddRevisionN() {
  const { docId, docCode } = useParams();
  const history = useHistory();
  const [doc, setDoc] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const { GetRosters, saveRevision, getRevisionLength, getDocById } = useDb();
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");
  const [docUrl, setDocUrl] = useState(null);
  const [fileSelected, setFileSelected] = useState(false);
  const [file, setFile] = useState(null);
  const storageRef = storage.ref("companies").child("z9idrZEm3nIz8QBZQJwz");
  const [description, setDescription] = useState("");
  // const [targetRevDate, setTargetRevDate] = useState(doc.targetRevDate.toDate());
  const [revDate, setRevDate] = useState(new Date());
  const [requestedBy, setRequestedBy] = useState(null);
  const [revisedBy, setRevisedBy] = useState(null);
  const rosters = GetRosters();
  const [requiresNoApproval, setRequiresNoApproval] = useState(false);

  const [state, setState] = useState({});

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      setFileSelected(true);
    }
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleUploadStart = () => {
    setIsUploading(true);
    setProgress(0);
    const uploadTask = storageRef.child(`documents/${file.name}`).put(file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var upProgress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(upProgress);
      },
      (error) => {
        setError("Error during File Upload");
        setIsUploading(false);
        setFileSelected(false);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((url) => {
          setDocUrl(url);
          setProgress(100);
          setIsUploading(false);
          console.log("File available at: ", url);
          setFileSelected(false);
        });
      }
    );
  };

  const validateDoc = () => {
    if (
      docUrl !== "" &&
      state.description !== "" &&
      state.requestedBy !== null &&
      state.revisedBy !== null
    ) {
      return true;
    } else {
      setError("Please complete all fields");
      return false;
    }
  };

  const saveDocument = async (data) => {
    if (validateDoc()) {
      setIsUploading(true);
      const length = await getRevisionLength(doc.id);
      const result = await saveRevision(doc.id, data, doc.docCode, length);
      if (result !== null) {
        history.push(`/doc-manager/revision/${docId}/${result.id}/${docCode}`);
      } else {
        setError("Error saving to database");
      }
    } else {
      setError("Please complete All Fields");
    }
  };

  const firstLoad = async () => {
    const d = await getDocById(docId);
    console.log(d);
    setDoc(d);
    setState({
      ...d,
      targetRevDate: new Date(),
    });
    setLoaded(true);
  };

  if (!loaded) {
    firstLoad();
    return <LinearProgress />;
  } else
    return (
      <div>
        <h1>Add Revision to {docCode}</h1>
        <Link to={`/doc-manager/revisions/${docId}/${docCode}`}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<ArrowLeftOutlined />}
          >
            {docCode} Revision History
          </Button>
        </Link>
        <br />
        <br />
        <Card>
          <CardContent>
            <Card>
              <CardContent>
                Upload Document:
                <br />
                <input type="file" onChange={handleFileChange} />
                <LinearProgressWithLabel value={progress} />
                {error !== "" ? `Error Uploading file` : null}
              </CardContent>
              <CardActions className="iso-right">
                <Button
                  disabled={!fileSelected || isUploading}
                  onClick={handleUploadStart}
                  startIcon={<FontAwesomeIcon icon={faUpload} />}
                  variant="contained"
                  color="primary"
                >
                  Upload
                </Button>
              </CardActions>
            </Card>
            <br />
            <TextField
              name="description"
              rows={2}
              multiline
              label="Description of Changes"
              fullWidth
              variant="outlined"
              onChange={handleChange}
            />
            <br />
            <br />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Target Revision Date"
                onChange={(d) => {
                  setState({
                    ...state,
                    targetRevDate: d.toDate(),
                  });
                }}
                defaultValue={dayjs(state.targetRevDate)}
              />
            </LocalizationProvider>
            <br />
            <br />
            <Select
              options={rosters}
              onChange={(val) => setState({ ...state, requestedBy: val })}
              placeholder="Requested By ..."
              {...selectFieldProps}
            />
            <br />
            <Select
              options={rosters}
              isMulti
              onChange={(val) => setState({ ...state, revisedBy: val })}
              placeholder="Revised By ..."
              {...selectFieldProps}
            />
            <br />
            <Typography style={{ color: "red " }}>{error}</Typography>
            <br />
            <Button
              disabled={
                isUploading ||
                docUrl === null ||
                state.requestedBy === null ||
                state.revisedBy === null
              }
              onClick={() =>
                saveDocument({
                  ...state,
                  docUrl,
                  createdDate: new Date(),
                  revDate: new Date(),
                  requiresNoApproval,
                })
              }
              color="primary"
              variant="contained"
              startIcon={<FontAwesomeIcon icon={faSave} />}
            >
              Save
            </Button>{" "}
            &nbsp;
          </CardContent>
        </Card>
      </div>
    );
}
