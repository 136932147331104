import { Button, Card, CardContent, Grid, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'
import React, { useState } from 'react'
import AddSupplier from './AddSupplier'
import AddProduct from './products/AddProduct'
import Products from './products/Products'
import Suppliers from './Suppliers'

export default function ContraPro() {

    const [addOpen, setAddOpen] = useState(false)
    const [addProductOpen, setAddProductOpen] = useState(false)

    return (
        <div>
            <h1>ContraPro (Supplier Management)</h1>       

            <Grid container spacing={3} >
                <Grid item xs={12} md={6} lg={7}>
                    <Card>
                        <CardContent>
                            <Typography>Suppliers</Typography>
                            <div className="back-link">
                                <Button
                                    variant="contained"
                                    startIcon={<Add />}
                                    color="primary"
                                    onClick={() => setAddOpen(true)}
                                >
                                    Add Supplier
                                </Button>
                            </div>
                            <Suppliers />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={5}>
                    <Card>
                        <CardContent>
                            <Typography>Products / Services</Typography>
                            <div className="back-link">
                                <Button
                                    variant="contained"
                                    startIcon={<Add />}
                                    color="primary"
                                    onClick={() => setAddProductOpen(true)}
                                >
                                    Add Product / Service
                                </Button>
                            </div>
                            <Products />
                        </CardContent>
                    </Card>
                    
                </Grid>
                

                
            </Grid>

            <AddSupplier
                open={addOpen}
                close={() => setAddOpen(false)}
            />

            <AddProduct
                open={addProductOpen}
                close={() => setAddProductOpen(false)}
            />
        </div>
    )
}
