import { Button, ButtonGroup, Card, CardActions, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import { AddBox, Edit, Save, Search } from '@mui/icons-material'
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import Select from 'react-select'
import { selectFieldProps, textFieldProps } from '../../shared/constants'
import { useHistory, Link } from 'react-router-dom'
import EditVenue from './EditVenue'
import FloatingHelpComponent from '../../help/FloatingHelpComponent'

export default function TrainingVenues() {
    const { GetSites, addVenue, GetTrainingVenues } = useDb()
    const sites = GetSites()
    const venues = GetTrainingVenues()
    const history = useHistory()
    
    const [editOpen, setEditOpen] = useState(false)
    const [addOpen, setAddOpen] = useState(false)
    const [state, setState] = useState({})
    const [venue, setVenue] = useState(null)

    // Action State
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const handleSelect = (v, e) => {
        setState({
            [e.name]: v.value,
            [`${e.name}Label`]: v.label
        })
    }

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }
    
    const handleClose = () => {
        setState({})
        setError(null)
        setLoading(false)
        setAddOpen(false)
        setEditOpen(false)
        setVenue(null)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setError(null)
        setLoading(true)
        try {
            await addVenue(state).then(venueId => {
                handleClose()
                history.push(`/training-manager/venue/${venueId}`)
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    return (
        <div>
            <h1>Training Venues</h1>
            <Card>
                <CardContent>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Venue Name</TableCell>
                                    <TableCell>Site</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    venues && venues.map(v => (
                                        <TableRow key={v.id}>
                                            <TableCell>{v.name}</TableCell>
                                            <TableCell>{v.siteLabel}</TableCell>
                                            <TableCell align="right">
                                                <IconButton
                                                    onClick={() => {
                                                        setEditOpen(true)
                                                        setVenue(v)
                                                    }}
                                                    size="large">
                                                    <Edit />
                                                </IconButton>
                                                <Link to={`/training-manager/venue/${v.id}`}>
                                                    <IconButton size="large">
                                                        <Search />
                                                    </IconButton>
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
                <CardActions>
                    <Button onClick={() => setAddOpen(true)} color="primary" variant="contained" startIcon={<AddBox />}>Add Venue</Button>
                </CardActions>
            </Card>

            {
                venue
                ?
                <EditVenue open={editOpen} handleClose={handleClose} venue={venue} />
                :
                null
            }

            <Dialog open={addOpen} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>
                    Add Training Venue
                </DialogTitle>
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <Select
                            {...selectFieldProps}
                            placeholder="Select Site ..."
                            options={sites}
                            onChange={handleSelect}
                            name="site"
                            className="iso-select"
                        />
                        <input
                            autoComplete="off"
                            style={{ opacity: 0, height: 0, marginTop: -20, width: 0 }}
                            value={state.site}
                            required
                        /><br/>
                        <TextField
                            {...textFieldProps}
                            label="Venue Name"
                            name="name"
                            required
                            onChange={handleChange}
                        />
                        { error ? <span className="iso-error">{error}</span> : null }
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={ loading } color="secondary" onClick={handleClose}>Cancel</Button>
                        <Button disabled={ loading } startIcon={<Save />} variant="contained" color="primary" type="submit">Create Venue</Button>
                    </DialogActions>
                </form>
            </Dialog>

            <FloatingHelpComponent
                name="Training Venues" 
                videoPath="training_manager"
                videoIndex={11}
            />
        </div>
    );
}
