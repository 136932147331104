import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
} from "@mui/material";
import { Save } from "@mui/icons-material";
import React, { useState } from "react";
import { useDb } from "../../../../../contexts/DatabaseContext";
import { selectFieldProps } from "../../../../shared/constants";
import Select from "react-select";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { useAuth } from "../../../../../contexts/AuthContext";
import FileUploader from "../../../../shared/FileUploader";

export default function CoAddSupDoc({ open, closeDialog, coId, docId }) {
  const { mgmtCreateSupDoc, MgmtGetSubFolders } = useDb();
  const { currentUser } = useAuth();
  const folders = MgmtGetSubFolders(coId, docId);
  const [state, setState] = useState({
    expires: false,
    expiryDate: new Date(),
  });

  // Action State
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setError(null);
    setLoading(false);
    setState({});
    closeDialog();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    try {
      await mgmtCreateSupDoc(coId, docId, state).then(() => {
        handleClose();
      });
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const getUrl = (url) => {};

  const getFile = (f) => {
    setState({
      ...state,
      fUrl: f.url,
      fileName: f.fileName,
    });
  };

  const getFileName = (fN) => {
    setState({
      ...state,
      fileName: fN,
    });
  };
  console.log(state);
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <FileUploader
            userId={currentUser.uid}
            getUrl={getUrl}
            getFilename={getFileName}
            getFileObj={getFile}
          />
          <br />
          <Select
            {...selectFieldProps}
            className="iso-select"
            options={folders}
            onChange={(v) =>
              setState({
                ...state,
                folder: v,
              })
            }
            placeholder="Select Folder (Optional)"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.expires}
                onChange={(e) =>
                  setState({
                    ...state,
                    expires: e.target.checked,
                  })
                }
              />
            }
            label="Expires?"
          />
          {state.expires ? (
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Expiry Date"
                  onChange={(d) => setState({ ...state, expiryDate: d })}
                  defaultValue={dayjs(state.expiryDate)}
                />
              </LocalizationProvider>
              <br />
              <br />
            </div>
          ) : null}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Created Date"
              onChange={(d) => setState({ ...state, createdAt: d })}
              defaultValue={dayjs(state.createdAt)}
            />
          </LocalizationProvider>

          <br />
          <br />
        </DialogContent>
        <DialogActions>
          {error ? <span className="iso-error">{error}</span> : null}
          <Button disabled={loading} color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={
              loading || !state.fUrl || !state.fileName || !state.createdAt
            }
            color="primary"
            type="submit"
            variant="contained"
            startIcon={<Save />}
          >
            Add File
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
