import { CircularProgress, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import FloatingHelpComponent from '../help/FloatingHelpComponent'

export default function ActionManager() {
    const { currentUser } = useAuth()
    var params = {
        "ds0.company": currentUser.companyId
    }
    var paramsAsString = JSON.stringify(params);
    var encodedParams = encodeURIComponent(paramsAsString)
    
    return (
        <div>
            <h1>Action Manager Dashboard</h1>
            <iframe width="1200" height="1200" src={`https://datastudio.google.com/embed/reporting/8b4db0bc-9620-455c-9f68-1fca166a2252/page/ambrC?params=${encodedParams}`} frameBorder="0"></iframe>
            <FloatingHelpComponent
                name="Using Action Dashboard" 
                videoPath="action_manager"
                videoIndex={1}
            />
        </div>
    )
}
