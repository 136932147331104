import React, { useEffect, useState } from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Edit, Save } from "@mui/icons-material";
import { textFieldProps } from "../../shared/constants";
import FloatingHelpComponent from "../../help/FloatingHelpComponent";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.cardBg,
  },
  dialog: {
    backgroundColor: theme.palette.dialogBg,
  },
}));

export default function TrainingSettings() {
  const classes = useStyles();
  const { GetTrainingRisks, updateRisk, checkCompanyTrainingRiskAsync } =
    useDb();
  const risks = GetTrainingRisks();
  const [loaded, setLoaded] = useState(false);

  // Editor State
  const [open, setOpen] = useState(false);
  const [risk, setRisk] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleOpen = (r) => {
    setRisk(r);
    setOpen(true);
  };

  const handleClose = () => {
    setRisk(null);
    setOpen(false);
  };

  const handleUpdate = async () => {
    setLoading(true);
    try {
      await updateRisk(risk.id, risk.days).then(() => {
        setLoading(false);
        handleClose();
      });
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const checkAndCreateTrainingRisks = async () => {
    // First Check to see if the company has trainingRisks
    try {
      await checkCompanyTrainingRiskAsync();
      setLoaded(true);
    } catch (err) {
      window.alert(err.message);
    }
  };

  useEffect(() => {
    const unsubscribe = checkAndCreateTrainingRisks();
    return unsubscribe;
  }, []);

  return !loaded ? (
    <LinearProgress />
  ) : (
    <div>
      <h1>Training Manager Settings</h1>
      <h2>Risks</h2>
      <Grid container spacing={3}>
        {risks &&
          risks.map((r) => {
            return (
              <Grid item xs={12} md={4} key={r.id}>
                <Card className={classes.card}>
                  <CardHeader
                    className={"iso-card-header capitalize"}
                    title={`${r.id} Risk`}
                    avatar={<FontAwesomeIcon icon={faExclamationTriangle} />}
                  />
                  <CardContent className={"iso-card-content"}>
                    <Typography>Alert After: {r.days} Days</Typography>
                  </CardContent>
                  <CardActions className={"iso-card-header"}>
                    <Button
                      onClick={() => handleOpen(r)}
                      color="primary"
                      variant="contained"
                      startIcon={<Edit />}
                    >
                      Edit
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
      </Grid>
      <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth>
        <DialogContent className={classes.dialog}>
          <TextField
            {...textFieldProps}
            label={"Set Days"}
            value={risk && risk.days}
            onChange={(e) => setRisk({ ...risk, days: e.target.value })}
            type={"number"}
          />
        </DialogContent>
        <DialogActions className={classes.dialog}>
          {error ? <span className={"iso-error"}>{error}</span> : null}
          <Button onClick={handleClose} disabled={loading} color={"secondary"}>
            Cancel
          </Button>
          <Button
            onClick={handleUpdate}
            disabled={loading || (risk && risk.days < 0)}
            color={"primary"}
            variant={"contained"}
            startIcon={<Save />}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>

      <FloatingHelpComponent
        name="How to use Training Manager Settings"
        videoPath="training_manager"
        videoIndex={6}
      />
    </div>
  );
}
