import { Button, Chip, LinearProgress } from "@mui/material";
import { Add, Check, Delete, Texture } from "@mui/icons-material";
import FishboneChart from "fishbone-chart";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDb } from "../../../../contexts/DatabaseContext";
import BackLink from "../../../shared/BackLink";
import AddBoneCategory from "./AddBoneCategory";
import AddBoneCause from "./AddBoneCause";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

export default function FishboneAnalysis() {
  const { actionId } = useParams();
  const {
    getAction,
    GetFishboneCategories,
    deleteFishboneCause,
    updateAction,
    deleteFishboneCategory
  } = useDb();
  const history = useHistory();
  const [action, setAction] = useState();
  const [addCatOpen, setAddCatOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [addBoneOpen, setAddBoneOpen] = useState(false);
  const categories = GetFishboneCategories(actionId);
  const [loading, setLoading] = useState(false);

  const openBoneCause = (cat) => {
    setSelectedCategory(cat);
    setAddBoneOpen(true);
  };

  const closeBoneCause = () => {
    setAddBoneOpen(false);
    setSelectedCategory(null);
  };

  const deleteBoneCause = async (catId, causeBone) => {
    try {
      await deleteFishboneCause(actionId, catId, causeBone);
    } catch (err) {
      window.alert(err.message);
    }
  };

  const deleteCategory = async (catId) => {
    const confirm = window.confirm(
      "This will also delete any causes in the category. This can not be undone. Are you sure?"
    );
    if (confirm) {
      try {
        await deleteFishboneCategory(actionId, catId);
      } catch (err) {
        window.alert(err.message);
      }
    }
  };

  const handleAnalysisComplete = async () => {
    setLoading(true);
    try {

        await updateAction(actionId, { analysisComplete: true });
        history.goBack();
    }
    catch(err) {
        setLoading(false)
        window.alert(err.message)
    }
  };

  let data = {};

  action &&
    categories &&
    categories.map((cat) => {
      let catBones = [];
      cat.bones?.map((b) => {
        catBones.push(
          <Chip
            label={b}
            onDelete={() => deleteBoneCause(cat.id, b)}
            style={{ marginBottom: 5 }}
          />
        );
        catBones.push(<br />);
      });
      data[action.isoAgency.isoAgency] = {
        ...data[action.isoAgency.isoAgency],
        [cat.category]: [
          cat.bones
            ? [
                ...catBones,
                <Button
                  size="small"
                  startIcon={<Add />}
                  onClick={() => openBoneCause(cat.id)}
                  variant="contained"
                  color="primary"
                >
                  Add Cause to {cat.category}
                </Button>,
                <Button
                  color="secondary"
                  endIcon={<Delete />}
                  size="small"
                  onClick={() => deleteCategory(cat.id)}
                >
                  Delete Category
                </Button>,
              ]
            : [
                <Button
                  size="small"
                  startIcon={<Add />}
                  onClick={() => openBoneCause(cat.id)}
                  variant="contained"
                  color="primary"
                >
                  Add Cause to {cat.category}
                </Button>,
                <Button
                  color="secondary"
                  endIcon={<Delete />}
                  size="small"
                  onClick={() => deleteCategory(cat.id)}
                >
                  Delete Category
                </Button>,
              ],
        ],
      };
    });

  const loadAction = async () => {
    const a = await getAction(actionId);
    setAction(a);
  };

  useEffect(() => {
    const unsubscribe = loadAction();
    return unsubscribe;
  }, [actionId]);

  return !action ? (
    <LinearProgress />
  ) : (
    <div>
      <BackLink />
      { Object.keys(data).length === 0 ?
        <>
        <br />
        <Link to={`${actionId}/templates`}>
          <Button
            color="primary"
            startIcon={<Texture />}
            variant="contained"
            >Select Template</Button>
        </Link>
        <br/>
        </>
        :
        null
      }
      <br/>
      <Button
        color="primary"
        startIcon={<Add />}
        onClick={() => setAddCatOpen(true)}
        variant="contained"
      >
        Add Category
      </Button>
      <Button
        color="primary"
        startIcon={<Check />}
        variant="contained"
        style={{ float: "right" }}
        onClick={handleAnalysisComplete}
        disabled={loading}
      >
        Mark Analysis as Complete
      </Button>
      <div
        style={{
          marginTop: 20,
          padding: 20,
          border: "1px solid lightgrey",
          borderRadius: 10,
        }}
      >
        <FishboneChart data={data} />
      </div>
      <AddBoneCategory
        open={addCatOpen}
        close={() => setAddCatOpen(false)}
        actionId={actionId}
      />

      {selectedCategory && (
        <AddBoneCause
          open={addBoneOpen}
          close={closeBoneCause}
          actionId={actionId}
          categoryId={selectedCategory}
        />
      )}
    </div>
  );
}
