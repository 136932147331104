import { Button, Card, CardActions, CardContent, CircularProgress, Typography } from '@mui/material'
import { Add, KeyboardArrowRight } from '@mui/icons-material'
import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useAuth } from '../../../contexts/AuthContext'
import { useDb } from '../../../contexts/DatabaseContext'
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid'
import AddAreas from './components/AddAreas'

export default function R2ChooseAreas() {
  
  const { GetRiskAreas, updateProgress } = useDb()
  const { currentUser } = useAuth()
  const history = useHistory()
  const riskAreas = GetRiskAreas()
  
  const [state, setState] = useState({
    open: false,
    loading: false,
    error: null
  })

  const progress = async () => {
    await updateProgress(3)
  }

  const handleSubmit = async () => {
    setState({
      ...state,
      loading: true,
      error: null
    })
    try {
      progress()
      setState({
        open: false,
        loading: false,
        error: null
      })
      history.push('/risk-manager/setup/add-risk-types')
    } catch (err) {
      setState({
        ...state,
        loading: false,
        error: err.message
      })
    }
  }

  const handleOpen = () => {
    setState({
      ...state,
      open: true
    })
  }

  const closeDialog = () => {
    setState({
      ...state,
      open: false
    })
  }

  const rows = riskAreas && riskAreas.map(r => ({
    id: r.id,
    areaName: r.areaName,
    siteName: r.site?.name
  }))

  const columns = [
    {
      field: 'areaName',
      headerName: 'Area Name',
      width: 300
    },
    {
      field: 'siteName',
      headerName: 'Site',
      width: 300
    }
  ]


  return (
    <div>
      {
        state.loading
        ? 
        <>
          <CircularProgress />
        </>
        :
        <>
        {
          currentUser.riskSetupComplete
          ?
          <>
            <Typography variant={'h5'}>Geographical Areas:</Typography>
            <br />
          </>
          :
          <>
            <Typography variant={'h5'}>Risk Manager: Initial Setup</Typography>
            <br />
            <Typography variant={'h5'}>Step 2: Add Geographical Areas:</Typography>
            <br />
          </>
        }
          <CardActions>
            <Button onClick={() => handleOpen()} variant={'contained'} color={'primary'} startIcon={<Add/>} >Add Area</Button>
          </CardActions>
          <Card>
            <CardContent>
              <IsoDataGrid
                  data={rows}
                  columns={columns}
              />
            </CardContent>
            <CardActions className="iso-right">
              {
                currentUser.riskSetupComplete
                ?
                null
                :
                <Button onClick={handleSubmit} disabled={state.loading} endIcon={<KeyboardArrowRight />} variant={'contained'} color={'primary'} >Next</Button>
              }
            </CardActions>
          </Card>
          { state.error && <Typography color="secondary">{state.error}</Typography> }

          {/* Dialog */}
          <AddAreas open={state.open} closeDialog={closeDialog}/>
        </>
      }
    </div>
  )
}
