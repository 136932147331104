import {
  AppBar,
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoxOpen,
  faCalendarPlus,
  faDoorClosed,
  faPaperclip,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../contexts/AuthContext";
import CloseOutAction from "./CloseOutAction";
import {
  ArrowLeftOutlined,
  Assessment,
  Check,
  Info,
  People,
} from "@mui/icons-material";
import { useDb } from "../../../contexts/DatabaseContext";
import { Link, useParams, useHistory } from "react-router-dom";
import { textFieldProps } from "../../shared/constants";

export default function ActionDetail() {
  const { actionId } = useParams();
  const history = useHistory();
  const [closeoutOpen, setCloseoutOpen] = useState(false);
  const { currentUser } = useAuth();
  const { reOpenAction, getAction, addActionComment, GetActionComments } = useDb();
  const [action, setAction] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [comment, setComment] = useState()
  const comments = GetActionComments(actionId)

  const reOpen = async () => {
    try {
      reOpenAction(action.id);
    } catch (err) {
      console.log(err.message);
    }
  };

  const firstLoad = async () => {
    const a = await getAction(actionId);
    setAction(a);
    setLoaded(true);
  };

  if (!loaded) {
    firstLoad();
    return <LinearProgress />;
  }

  const handleComment = (e) => {
    setComment({
      timestamp: new Date(),
      user: `${currentUser.firstName} ${currentUser.lastName} - ${currentUser.email}`,
      comment: e.target.value
    })
  }

  const handleSubmit = () => {
    setComment(null)
    addActionComment(comment, actionId)
  }

  return <>
    <h1>Action: {action.actionNumber}</h1>
    <Link to={`/action-manager/register`}>
      <Button
        color="primary"
        variant="contained"
        startIcon={<ArrowLeftOutlined />}
      >
        Action Register
      </Button>
    </Link>
    &nbsp;
    {currentUser.uid === action.hod.uid ||
    currentUser.uid === action.hod.id ? (
      <>
        <Link to={`/action-manager/request-extension/${action.id}`}>
          <Button
            startIcon={<FontAwesomeIcon icon={faCalendarPlus} />}
            color={"primary"}
            variant={"contained"}
          >
            Request Extension
          </Button>
        </Link>
        &nbsp;
      </>
    ) : null}
    {action.open ? (
      currentUser.companyAdmin ||
      action.hod.uid === currentUser.uid ||
      action.hod.id === currentUser.uid ? (
        <>
          <Button
            startIcon={<FontAwesomeIcon icon={faDoorClosed} />}
            color={"primary"}
            variant={"contained"}
            disabled={action.methodology && !action.analysisComplete}
            onClick={() =>
              history.push(`/action-manager/close-out-action/${action.id}`)
            }
          >
            Close out Action
          </Button>
          &nbsp;
        </>
      ) : null
    ) : (
      <>
        <Button
          onClick={reOpen}
          startIcon={<FontAwesomeIcon icon={faBoxOpen} />}
          color={"primary"}
          variant={"contained"}
        >
          Re-Open Action
        </Button>
        &nbsp;
      </>
    )}
    <Link to={`/action-attachments/${actionId}`}>
      <Button
        startIcon={<FontAwesomeIcon icon={faPaperclip} />}
        color={"primary"}
        variant={"contained"}
      >
        Attachments
      </Button>
    </Link>
    &nbsp;
    <Link to={`/action-int-parties/${action.id}/${action.actionNumber}`}>
      <Button startIcon={<People />} color={"primary"} variant={"contained"}>
        Interested Parties
      </Button>
    </Link>
    &nbsp;
    {action.methodology === "5why" && (
      <Link to={`/5-why-analysis/${action.id}`}>
        <Button
          startIcon={<Assessment />}
          color={"primary"}
          variant={"contained"}
        >
          5-Why Analysis
        </Button>
      </Link>
    )}
    {action.methodology === "fishbone" && (
      <Link to={`/fishbone-analysis/${action.id}`}>
        <Button
          startIcon={<Assessment />}
          color={"primary"}
          variant={"contained"}
        >
          Fishbone Analysis
        </Button>
      </Link>
    )}
    {action.analysisComplete && (
      <Chip
        icon={<Check style={{ color: "#fff" }} />}
        style={{ marginLeft: 10, background: "#4caf4f", color: "#fff" }}
        label="Root Cause Analysis Completed"
      />
    )}
    <br />
    <br />
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant={"h5"}>Action Source</Typography>
                <Divider />
                Action Code: {action.actionNumber}
                <br />
                Department: {action.department.name} <br />
                Site: {action.site.label} <br />
                Source: {action.source.sourceName} <br />
                <br />
                <Typography variant={"h5"}>Action Responsibility</Typography>
                <Divider />
                Responsible HOD:{" "}
                {`${action.hod.firstName} ${action.hod.lastName}`} <br />
                Originator:{" "}
                {`${action.originator.firstName} ${action.originator.lastName}`}{" "}
                <br />
                ISO Classification: {action.isoClass} <br />
                ISO Agency: {action.isoAgency.isoAgency} <br />
                Priority: {action.priority} <br />
                Target Date:{" "}
                {moment(action.targetDate.toDate()).format("DD/MM/YYYY")}
                <br />
              </Grid>
              <Grid item xs={12}>
                <Typography variant={"h5"}>Action Description</Typography>
                <Divider />
                <p>Action Description: {action.description} </p>
                <p>Immediate Action: {action.immAction}</p>
                <p>
                  Date Created:{" "}
                  {moment(action.timestamp.toDate()).format("DD/MM/YYYY")}
                </p>
                <br />
                {!action.open ? (
                  <Card>
                    <CardHeader
                      title={"Close Out Details"}
                      avatar={
                        <Avatar>
                          <Info />
                        </Avatar>
                      }
                    />
                    <CardContent>
                      <p>
                        Root Cause:
                        <br />
                        <span className={"iso-small"}>{action.rootCause}</span>
                      </p>
                      <p>
                        Preventive Measures:
                        <br />
                        <span className={"iso-small"}>{action.preventive}</span>
                      </p>
                      <p>
                        Review Baseline Risk:
                        <br />
                        <span className={"iso-small"}>
                          {action.baselineText ? action.baselineText : "Not Set"}
                        </span>
                      </p>
                      <p>
                        Close Out and Verification Date:
                        <br />
                        <span className={"iso-small"}>
                          {action.closeOutDate
                            ? moment(action.closeOutDate.toDate()).format(
                                "DD/MM/YYYY"
                              )
                            : ""}
                        </span>
                      </p>
                    </CardContent>
                  </Card>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Card>
              <CardContent>
                <TextField maxRows={3} value={comment ? comment.comment : ''} label="Comment" onChange={(e) => handleComment(e)} {...textFieldProps}/>
              </CardContent>
              <CardActions>
                {
                  comment
                  ?
                    comment.comment 
                    ?
                      <>
                        <Button onClick={() => handleSubmit()} variant="contained" color='primary'>Add Comment</Button>
                      </>
                    :
                    null
                  :
                  null
                }
              </CardActions>
            </Card>
            <br />
            {
              comments.length > 0
              ?
              comments.map(c => (
                <>
                <Card>
                  <CardContent>
                  {c.user} <br />
                  {c.comment} <br />
                  {moment(c.timestamp.toDate()).fromNow()} <br />
                  </CardContent>
                  <Divider />
                </Card>
                <br />
                </>
                ))
              :
              null
            }
          </Grid>
        </Grid>
      </CardContent>
    </Card>
    <CloseOutAction
      open={closeoutOpen}
      closeDialog={() => setCloseoutOpen(false)}
      action={action}
    />
  </>;
}
