import { Button, Card, CardActions, CardContent, CircularProgress, Typography } from '@mui/material'
import { Add, KeyboardArrowRight } from '@mui/icons-material'
import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useAuth } from '../../../contexts/AuthContext'
import { useDb } from '../../../contexts/DatabaseContext'
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid'
import AddRiskEntities from './components/AddRiskEntities'

export default function R4RiskEntities() {
  
  const { GetRiskEntities, updateProgress } = useDb()
  const { currentUser } = useAuth()
  const history = useHistory()
  const riskEntities = GetRiskEntities()
  
  const [state, setState] = useState({
    open: false,
    loading: false,
    error: null
  })

  const progress = async () => {
    await updateProgress(5)
  }

  const handleSubmit = async () => {
    setState({
      ...state,
      loading: true,
      error: null
    })
    try {
      progress()
      setState({
        open: false,
        loading: false,
        error: null
      })
      history.push('/risk-manager/setup/add-risk-exposure-pattern')
    } catch (err) {
      setState({
        ...state,
        loading: false,
        error: err.message
      })
    }
  }

  const handleOpen = () => {
    setState({
      ...state,
      open: true
    })
  }

  const closeDialog = () => {
    setState({
      ...state,
      open: false
    })
  }

  const rows = riskEntities && riskEntities.map(t => ({
    id: t.id,
    riskEntity: t.riskEntity
  }))

  const columns = [
    {
      field: 'riskEntity',
      headerName: 'Entity Name',
      width: 300
    }
  ]

  return (
    <>
      {
        state.loading
        ?
        <CircularProgress />
        :
        <div>
          {
            currentUser.riskSetupComplete
            ?
            <>
              <Typography variant={'h5'}>Entities Exposed:</Typography>
              <br />
            </>
            :
            <>
              <Typography variant={'h5'}>Risk Manager: Initial Setup</Typography>
              <br />
              <Typography variant={'h5'}>Step 4: Add Entities Exposed:</Typography>
              <br />
            </>
          }
          <Card>
            <CardContent>
              <IsoDataGrid
                  data={rows}
                  columns={columns}
              />
            </CardContent>
            <CardActions className="iso-right">
              <Button onClick={() => handleOpen()} variant={'contained'} color={'primary'} startIcon={<Add/>} >ADD</Button>
              {
                currentUser.riskSetupComplete
                ?
                null
                :
                <Button onClick={handleSubmit} disabled={state.loading} endIcon={<KeyboardArrowRight />} variant={'contained'} color={'primary'} >Next</Button>
              }
            </CardActions>
          </Card>
          { state.error && <Typography color="secondary">{state.error}</Typography> }
        
          {/* Dialog */}
          <AddRiskEntities open={state.open} closeDialog={closeDialog}/>
        </div>
      }
    </>
  )
}
