import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import React, { useState } from "react";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { useDb } from "../../../../contexts/DatabaseContext";
import Select from "react-select";
import { selectFieldProps } from "../../../shared/constants";
import { useHistory } from "react-router-dom";

export default function NewSessionsReport({ open, close }) {
  const [state, setState] = useState({});
  const { GetSites, getSessionReport, GetTrainingModules, saveSessionReport } =
    useDb();
  const sites = GetSites();
  const modules = GetTrainingModules();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setState({});
    close();
  };

  const handleSubmit = async () => {
    setLoading(true);
    const report = await getSessionReport(
      state.startDate,
      state.endDate,
      state.filters
    );
    const reportId = await saveSessionReport(report);
    setLoading(false);
    history.push(`/training-manager/reports/sessions/${reportId}`);
  };

  const handleSelect = (v, e) => {
    setState({
      ...state,
      filters: {
        ...state.filters,
        [e.name]: v.id || v.value,
      },
    });
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={handleClose}>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Sessions Started Between:"
            onChange={(d) => setState({ ...state, startDate: d.toDate() })}
            defaultValue={dayjs(state.startDate)}
          />
        </LocalizationProvider>
        <br />
        <br />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="And:"
            onChange={(d) => setState({ ...state, endDate: d.toDate() })}
            defaultValue={dayjs(state.endDate)}
          />
        </LocalizationProvider>
        <br />
        <br />
        <Select
          options={sites}
          {...selectFieldProps}
          className="iso-select"
          placeholder="Site (Leave blank for all sites)"
          onChange={handleSelect}
          name="site"
        />
        <Select
          options={modules}
          {...selectFieldProps}
          className="iso-select"
          placeholder="Module (Leave blank for all modules)"
          onChange={handleSelect}
          name="module.id"
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          disabled={!state.startDate || !state.endDate || loading}
          color="primary"
          onClick={handleSubmit}
          variant="contained"
        >
          Run Report
        </Button>
      </DialogActions>
    </Dialog>
  );
}
