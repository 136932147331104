import {
  Tab,
  Tabs,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Fab,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  InputAdornment,
  Chip,
  CircularProgress,
  FormControlLabel,
  Switch,
} from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import AddIcon from "@mui/icons-material/Add";
import makeStyles from '@mui/styles/makeStyles';
import { amber } from "@mui/material/colors";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEmpty, includes } from "lodash";
import {
  faFilePdf,
  faHeart,
  faList,
  faPlus,
  faSearch,
  faSlidersH,
  faTh,
} from "@fortawesome/free-solid-svg-icons";
import DocumentCard from "../document/DocumentCard";
import AddDocument from "./AddDocument";
import { useDb } from "../../../contexts/DatabaseContext";
import { useAuth } from "../../../contexts/AuthContext";
import DocListItem from "../document/DocListItem";
import Filters from "./filters/Filters";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <CardContent className="iso-card-content">
          <Typography component={"span"}>{children}</Typography>
        </CardContent>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    width: 500,
    position: "relative",
    minHeight: 200,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(6),
    right: theme.spacing(7),
    backgroundColor: amber[500],
    "&:hover": {
      backgroundColor: amber[600],
    },
  },
  tab: {
    indicatorColor: theme.palette.primary,
  },
  icon: {
    color: theme.palette.primary,
  },
  tabPanel: {
    backgroundColor: theme.palette.main,
  },
  dialog: {
    backgroundColor: theme.palette.dialogBg,
  },
  search: {
    color: theme.palette.isotext,
  },
}));

const DocRegister = () => {
  const {
    GetDocs,
    getUserGroups,
    getUserFavoriteDocs,
    createDocsPdf,
  } = useDb();
  const { currentUser } = useAuth();
  const [dialogOpen, setDialog] = useState(false);
  const [value, setTab] = useState(0);
  const classes = useStyles();
  const [docDialog, setDocDialog] = useState(false);
  const docs = GetDocs();
  const [listView, setListView] = useState(false);
  const [search, setSearch] = useState(null);
  const [loadComplete, setLoadComplete] = useState(false);
  const [userGroups, setUserGroups] = useState([]);
  const [userFavDocs, setUserFavDocs] = useState([]);
  const [filterFavs, setFilterFavs] = useState(false);
  const [lFilter, setLFilter] = useState([0, ""]);

  // Switch Filters
  const [overdue, setOverdue] = useState(false);
  const [due30, setDue30] = useState(false);

  // filteredData
  let filteredData = [];

  //Action State
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const styles = useStyles();

  const firstLoad = async () => {
    const myGroups = await getUserGroups(currentUser.uid);
    const favDocs = await getUserFavoriteDocs(currentUser.uid);
    setUserFavDocs(favDocs);
    setUserGroups(myGroups);
    setLoadComplete(true);
  };

  const filterObj = {
    department: {},
    owner: {},
    docType: {},
    site: {},
  };

  const [filters, setFilters] = useState(filterObj);

  const closeDocDialog = () => {
    setDocDialog(!docDialog);
  };

  const toggleView = () => {
    setListView(!listView);
  };

  const filterSearch = (doc) => {
    if (overdue && !(doc.targetRevDate.toDate() < Date.now())) {
      return null;
    }
    let dayDiff = moment(Date.now())
      .add(30, "days")
      .diff(moment(doc.targetRevDate.toDate()), "days");
    if (due30 && !(dayDiff > 0 && dayDiff <= 30)) {
      return null;
    }
    if (!filterByDropdowns(doc)) return null;
    if (search !== null && !filterBySearch(doc)) return null;
    if (!includes(filteredData, doc)) filteredData.push(doc);
    return listOrGrid(listView, doc);
  };

  const listOrGrid = (listView, doc) => {
    
    if (filterFavs) {
      if (userFavDocs && userFavDocs.includes(doc.id)) {
        return listView ? (
          <Grid item xs={12}>
            <DocListItem
              key={doc.id}
              doc={doc}
              user={currentUser}
              favs={userFavDocs}
            />
          </Grid>
        ) : (
          <Grid item xs={12} md={4} key={doc.id}>
            <DocumentCard doc={doc} user={currentUser} favs={userFavDocs} />
          </Grid>
        );
      } else {
        return null;
      }
    } else {
      return listView ? (
        <Grid item xs={12}>
          <DocListItem
            key={doc.id}
            doc={doc}
            user={currentUser}
            favs={userFavDocs}
          />
        </Grid>
      ) : (
        <Grid item xs={12} md={4} key={doc.id}>
          <DocumentCard doc={doc} user={currentUser} favs={userFavDocs} />
        </Grid>
      );
    }
  };

  const filterBySearch = (doc) => {
    const downcaseSearch = search.toLowerCase();
    return (
      doc.docCode.toLowerCase().includes(downcaseSearch) ||
      doc.docName.toLowerCase().includes(downcaseSearch) ||
      doc.docType.label.toLowerCase().includes(downcaseSearch) ||
      doc.owner.label.toLowerCase().includes(downcaseSearch) ||
      doc.department.label.toLowerCase().includes(downcaseSearch)
    );
  };

  const filterByDropdowns = (doc) => {
    if (
      isEmpty(filters.department) &&
      isEmpty(filters.docType) &&
      isEmpty(filters.owner) &&
      isEmpty(filters.site)
    )
      return true;
    let dpt = filters.department?.label
      ? doc.department.label === filters.department?.label
      : true;
    let type = filters.docType?.label
      ? doc.docType.label === filters.docType?.label
      : true;
    let owner = filters.owner?.label
      ? doc.owner.label === filters.owner?.label
      : true;
    let site = filters.site?.label
      ? doc.site.label === filters.site?.label
      : true;
    return dpt && type && owner && site;
  };

  const receiveFilters = (f) => {
    setFilters(f);
    setDialog(false);
  };

  const handleChipDelete = (f) => {
    setFilters({ ...filters, [f]: {} });
  };

  const myChip = (name, filter) => {
    const lowerCase = name.toLowerCase();
    const descLabel = name.charAt(0).toUpperCase() + lowerCase.slice(1);
    return (
      <Chip
        label={`${descLabel}: ${filter.label}`}
        key={filter.id}
        onDelete={() => handleChipDelete(name)}
        style={{ marginRight: 5 }}
      />
    );
  };

  const getPdfReport = async () => {
    setLoading(true);
    try {
      await createDocsPdf(filteredData);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const myTab = (index, tab) => {
    return (
      <TabPanel className={classes.tabPanel} value={value} index={index}>
        <Grid container spacing={3}>
          {docs?.map((doc) => {
            if (!doc.obsolete) {
              // The below checks if this document is limited to certain groups only then checks that users groups array (userGroups) for that group Id. If it matches, show the doc if not dont
              if (doc.groups && doc.groups.length > 0) {
                if (doc.groups.some((r) => userGroups.indexOf(r) >= 0)) {
                  return isEmpty(tab) || doc.category === tab
                    ? filterSearch(doc)
                    : null;
                } else {
                  return null;
                }
              } else {
                return isEmpty(tab) || doc.category === tab
                  ? filterSearch(doc)
                  : null;
              } // End Groups If
            } else {
              return null;
            }
          })}
        </Grid>
      </TabPanel>
    );
  };

  if (!loadComplete) {
    firstLoad();
    return <CircularProgress />;
  } else {
    return <>
      <Card>
        <CardHeader
          className={classes.tabPanel}
          title={
            <>
              <IconButton className={classes.icon} onClick={() => setDialog(true)} size="large">
                <FontAwesomeIcon icon={faSlidersH} />
              </IconButton>

              {loading ? (
                <CircularProgress />
              ) : (
                <IconButton className={classes.icon} onClick={getPdfReport} size="large">
                  <FontAwesomeIcon icon={faFilePdf} />
                </IconButton>
              )}

              {currentUser.companyAdmin ||
              currentUser.accessRights.docManager <= 1 ? (
                <IconButton onClick={() => setDocDialog(true)} className={classes.icon} size="large">
                  <FontAwesomeIcon icon={faPlus} />
                </IconButton>
              ) : null}
              <IconButton onClick={() => toggleView()} className={classes.icon} size="large">
                {listView ? (
                  <FontAwesomeIcon icon={faList} />
                ) : (
                  <FontAwesomeIcon icon={faTh} />
                )}
              </IconButton>
              <IconButton
                className={classes.icon}
                onClick={() => setFilterFavs(!filterFavs)}
                size="large">
                <FontAwesomeIcon
                  icon={faHeart}
                  style={{
                    color: filterFavs ? "#ffa900" : classes.icon.color,
                  }}
                />
              </IconButton>
            </>
          }
          action={
            currentUser.extendedDocs
            ?
            <Tabs
              value={value}
              onChange={(e, v) => setTab(v)}
              className={classes.tab}
              indicatorColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              scrollButtons
              allowScrollButtonsMobile>
              <Tab
                onClick={() => setLFilter([0, ""])}
                label={<span className="iso-small">All</span>}
                {...a11yProps(0)}
                style={{ minWidth: '100px'}}
              />
              <Tab
                onClick={() => setLFilter([1, "L1"])}
                label={<span className="iso-small">L1</span>}
                {...a11yProps(1)}
                style={{ minWidth: '100px'}}
              />
              <Tab
                onClick={() => setLFilter([2, "L2"])}
                label={<span className="iso-small">L2</span>}
                {...a11yProps(2)}
                style={{ minWidth: '100px'}}
              />
              <Tab
                onClick={() => setLFilter([3, "L3"])}
                label={<span className="iso-small">L3</span>}
                {...a11yProps(3)}
                style={{ minWidth: '100px'}}
              />
              <Tab
                onClick={() => setLFilter([4, "L4"])}
                label={<span className="iso-small">L4</span>}
                {...a11yProps(4)}
                style={{ minWidth: '100px'}}
              />
              <Tab
              
                onClick={() => setLFilter([5, "L5"])}
                label={<span className="iso-small">L5</span>}
                {...a11yProps(5)}
                style={{ minWidth: '100px'}}
              />
              <Tab
                onClick={() => setLFilter([6, "L6"])}
                label={<span className="iso-small">L6</span>}
                {...a11yProps(5)}
                style={{ minWidth: '100px'}}
              />
              <Tab
                onClick={() => setLFilter([7, "L7"])}
                label={<span className="iso-small">L7</span>}
                {...a11yProps(5)}
                style={{ minWidth: '100px'}}
              />
              <Tab
                onClick={() => setLFilter([8, "L8"])}
                label={<span className="iso-small">L8</span>}
                {...a11yProps(5)}
                style={{ minWidth: '100px'}}
              />
            </Tabs>
            :
            <Tabs
              value={value}
              onChange={(e, v) => setTab(v)}
              className={classes.tab}
              indicatorColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              scrollButtons
              allowScrollButtonsMobile>
              <Tab
                onClick={() => setLFilter([0, ""])}
                label="All"
                {...a11yProps(0)}
              />
              <Tab
                onClick={() => setLFilter([1, "L1"])}
                label="L1"
                {...a11yProps(1)}
              />
              <Tab
                onClick={() => setLFilter([2, "L2"])}
                label="L2"
                {...a11yProps(2)}
              />
              <Tab
                onClick={() => setLFilter([3, "L3"])}
                label="L3"
                {...a11yProps(3)}
              />
              <Tab
                onClick={() => setLFilter([4, "L4"])}
                label="L4"
                {...a11yProps(4)}
              />
              <Tab
                onClick={() => setLFilter([5, "L5"])}
                label="L5"
                {...a11yProps(5)}
              />
            </Tabs>
          }
        />
        <CardContent className={classes.tabPanel}>
          <TextField
            className={classes.search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search ..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faSearch} />
                </InputAdornment>
              ),
            }}
            fullWidth
            variant="outlined"
          />

          {filters ? (
            <div>
              <br />
              Filters:
              <br />
              <FormControlLabel
                label={<span className="iso-small">Overdue</span>}
                control={
                  <Switch
                    checked={overdue}
                    onChange={(e) => {
                      setDue30(false);
                      setOverdue(e.target.checked);
                    }}
                    color="primary"
                  />
                }
              />
              <FormControlLabel
                label={<span className="iso-small">Due in 30 Days</span>}
                control={
                  <Switch
                    checked={due30}
                    onChange={(e) => {
                      setOverdue(false);
                      setDue30(e.target.checked);
                    }}
                    color="primary"
                  />
                }
              />
              {overdue && due30 ? (
                <span className="iso-small iso-error">
                  {/* You have selected two conflicting filters. Please deactivate
                  one. */}
                </span>
              ) : null}
              <br />
              {Object.keys(filters).map(
                (filter) =>
                  !isEmpty(filters[filter]) && myChip(filter, filters[filter])
              )}
            </div>
          ) : null}
        </CardContent>

        {/* Filters */}
        <Dialog
          maxWidth="sm"
          fullWidth
          open={dialogOpen}
          onClose={() => setDialog(false)}
        >
          <DialogTitle className={classes.dialog}>Filters</DialogTitle>
          <DialogContent className={classes.dialog}>
            <Filters sendFilters={receiveFilters} selectedFilter={filters} />
          </DialogContent>
        </Dialog>

        <Dialog
          maxWidth="sm"
          fullWidth
          open={docDialog}
          onClose={() => setDocDialog(false)}
        >
          <DialogContent className={classes.dialog}>
            <AddDocument closeDialog={closeDocDialog} />
          </DialogContent>
        </Dialog>
        {myTab(lFilter[0], lFilter[1])}
        {/* {myTab(1, "L1")}
        {myTab(2, "L2")}
        {myTab(3, "L3")}
        {myTab(4, "L4")}
        {myTab(5, "L5")} */}
      </Card>
      {currentUser.companyAdmin ||
      currentUser.accessRights.docManager <= 1 ? (
        <Fab onClick={() => setDocDialog(true)} className={classes.fab}>
          <AddIcon />
        </Fab>
      ) : null}
    </>;
  }
};

export default DocRegister;
