import { faEllipsisH, faFile, faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    Menu,
    Typography,
    Chip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import moment from 'moment';
import DocDropDown from '../document/DocDropDown';
import { truncateString } from '../../shared/constants'
import FileDialog from '../../shared/FileDialog';
import PendingDropDown from './PendingDropDown';

const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: 'lightgrey',
        position: 'relative'
    },
    dialog: {
        backgroundColor: theme.palette.dialogBg
    },
    text: {
        color: 'grey'
    }
}))

const PendingDocCard = ({ doc, user }) => { 
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null)
    const [dest, setDest] = useState(null)
    const [revisionDialog, setRevisionDialog] = useState(false)
    const [pdfViewOpen, setPdfViewOpen] = useState(false)
    const [isFav, setIsFav] = useState(false)
    const [favCheckDone, setFavCheckDone] = useState(false)

    const classes = useStyles()

    const handleClick = (e) => {
        console.log(e)
        setAnchorEl(e.currentTarget)
    }

    const openDialog = (dest) => {
        setDest(dest)
        setAnchorEl(null) 
        setOpen(true)
    }

    const closeDialog = () => {
        setOpen(false)
    }

    const closePdfView = () => {
        setPdfViewOpen(false)
    }


    const truncTitle = truncateString(`${doc.docCode} ${doc.docName}`, 32)

    return (
        <Card className={classes.card}>
            <CardHeader
                avatar={
                    <FontAwesomeIcon icon={faFile} />
                } 
                title={<Typography style={{ color: 'grey'}} color="isotext" noWrap>{truncTitle}</Typography>}
                subheader={doc.docType.label}
                action={
                    user.uid === doc.owner.value.uid || user.companyAdmin ?
                    <>
                        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} size="large">
                            <FontAwesomeIcon icon={faEllipsisH} />
                        </IconButton> 
                    </>
                    : 
                    null
                }
            />

            <FileDialog open={pdfViewOpen} closeDialog={closePdfView} file={doc.docUrl} /> 

            <CardContent>
                
                <Grid container spacing={3}>
                    
                    <Grid item xs={6}>
                        <span style={{ color: 'grey'}} className="smaller-text">Date Created: {moment(doc.createdDate.toDate()).format('DD/MM/YYYY')}</span><br/>
                        {/* <Typography color="textPrimary">Date Created: {moment(doc.createdDate.toDate()).format('DD/MM/YYYY')}</Typography> */}
                        
                        
                        <span style={{ color: 'grey'}} className="smaller-text">Department: {doc.department.label}</span>
                        
                    </Grid>
                    <Grid item xs={6}>
                        <span style={{ color: 'grey'}} className="smaller-text">Target Revision Date: {moment(doc.targetRevDate.toDate()).format('DD/MM/YYYY')}</span><br/>
                        
                    </Grid>
                </Grid>
                <span style={{ color: 'grey'}} className="smaller-text">Document Owner: {doc.owner.label}</span><br/>
                <span style={{ color: 'grey'}} className="smaller-text">Status: {<b>{`Due: ${moment(doc.targetRevDate.toDate()).fromNow()}`}</b>}</span>


                <Chip
                    style={{ 
                        position: 'absolute',
                        top: '40%',
                        left: '30%'
                    }}
                    color="secondary"
                    label={`Pending Approval ${doc.approvers - doc.pendingApproval}/${doc.approvers}`}
                />
                {/* <h1 style={{ 
                    position: 'absolute',
                    top: '20%',
                    left: '40%',
                    
                }}>Hey</h1> */}
            </CardContent>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                
            >
                <PendingDropDown doc={doc} openDialog={openDialog} closeDialog={closeDialog}/>
            </Menu>
            
            <Dialog maxWidth="sm" fullWidth open={open} onClose={() => setOpen(false)}>
                <DialogContent className={classes.dialog}>
                    { dest }
                </DialogContent>
                <DialogActions className={classes.dialog} >
                    <Button onClick={closeDialog} color="primary">Close</Button>
                </DialogActions>
            </Dialog>

        </Card>
    );
}

export default PendingDocCard;