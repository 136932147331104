export const sections = [
  { name: "Process and Process Control", order: 1 },
  { name: "Alterations", order: 2 },
];

export const questionsA = [
  {
    title: "Introduce new or alter existing process control software?",
    order: 1,
  },
  {
    title:
      "May affect the process or equipment upstream or downstream of the change?",
    order: 1,
  },
  {
    title:
      "May affect any existing trip or alarm system or require additional trip or alarm protection?",
    order: 1,
  },
  {
    title: "Alter the composition of, or means of disposal of waste streams?",
    order: 1,
  },
  {
    title: "Alter noise, dust, fume, lighting and vibration levels?",
    order: 1,
  },
  {
    title:
      "Require connection to, alteration of, or disconnection from, any site utilities?",
    order: 1,
  },
];

export const questionsB = [
  {
    title:
      "Introduce new or alter existing equipment (mechanical, electrical) or hardware?",
    order: 1,
  },
  {
    title: "Change the original design intent of plant or equipment?",
    order: 2,
  },
  { title: "Require excavation work or demolition work? ", order: 3 },
  { title: "Introduce new or alter existing civil structures?", order: 4 },
  {
    title: "Alter the type or specification of parts or equipment?",
    order: 5,
  },
];
