import React from "react";
import MgmtAgents from "../management/agents/MgmtAgents";
import MgBilling from "../management/billing/MgBilling";
import Companies from "../management/companies/Companies";
import CoActionAttachments from "../management/companies/company-components/actions/CoActionAttachments";
import CoActionIntParties from "../management/companies/company-components/actions/CoActionIntParties";
import CoActions from "../management/companies/company-components/actions/CoActions";
import CoActionsImport from "../management/companies/company-components/actions/CoActionsImport";
import CoCreateAction from "../management/companies/company-components/actions/CoCreateAction";
import CoDepartments from "../management/companies/company-components/CoDepartments";
import CoIsoAgencies from "../management/companies/company-components/CoIsoAgencies";
import CoIsoClasses from "../management/companies/company-components/CoIsoClasses";
import CoPriorities from "../management/companies/company-components/CoPriorities";
import CoRosters from "../management/companies/company-components/CoRosters";
import CoSites from "../management/companies/company-components/CoSites";
import CoSources from "../management/companies/company-components/CoSources";
import CoAddRevision from "../management/companies/company-components/documents/CoAddRevision";
import CoCreateDoc from "../management/companies/company-components/documents/CoCreateDoc";
import CoDocIntParties from "../management/companies/company-components/documents/CoDocIntParties";
import CoDocRevisions from "../management/companies/company-components/documents/CoDocRevisions";
import CoDocs from "../management/companies/company-components/documents/CoDocs";
import CoDocTypes from "../management/companies/company-components/documents/CoDocTypes";
import CoEditDoc from "../management/companies/company-components/documents/CoEditDoc";
import CoSubFolders from "../management/companies/company-components/documents/CoSubFolders";
import CoSupDocs from "../management/companies/company-components/documents/CoSupDocs";
import CompanyDetail from "../management/companies/CompanyDetail";
import TrainingCompetencyData from "../management/data/TrainingCompetencyData";
import DataManagement from "../management/DataManagement";
import IwanTestArea from "../management/IwanTestArea";
import ManagementDashboard from "../management/ManagementDashboard";
import UpdateManual from "../management/manual/UpdateManual";
import DocTemplates from "../management/templates/DocTemplates";
import ManagementUsers from "../management/users/ManagementUsers";
import MgmtVideos from "../management/videos/MgmtVideos";
import VideoCategoryDetail from "../management/videos/VideoCategoryDetail";
import PrivateRoute from "./PrivateRoute";
import MocTemplates from "../management/templates/MocTemplates";
import EditMocChecklist from "../management/templates/EditMocChecklist.js";
import NotificationLog from "../master_list/notification-log/NotificationLog";

export default function AdminRoutes() {
  return (
    <>
      <PrivateRoute exact path="/" component={ManagementDashboard} />
      <PrivateRoute exact path="/companies" component={Companies} />
      <PrivateRoute exact path="/companies/:id" component={CompanyDetail} />
      <PrivateRoute
        path="/companies/:coId/departments"
        component={CoDepartments}
      />
      <PrivateRoute path="/companies/:coId/sites" component={CoSites} />
      <PrivateRoute path="/companies/:coId/sources" component={CoSources} />
      <PrivateRoute
        path="/companies/:coId/isoclasses"
        component={CoIsoClasses}
      />
      <PrivateRoute
        path="/companies/:coId/isoagencies"
        component={CoIsoAgencies}
      />
      <PrivateRoute path="/companies/:coId/rosters" component={CoRosters} />
      <PrivateRoute
        path="/companies/:coId/priorities"
        component={CoPriorities}
      />
      <PrivateRoute path="/companies/:coId/actions" component={CoActions} />
      <PrivateRoute
        path="/companies/:coId/create-action"
        component={CoCreateAction}
      />
      <PrivateRoute
        path="/companies/:coId/action-intparties/:actionId"
        component={CoActionIntParties}
      />
      <PrivateRoute
        path="/companies/:coId/action-attachments/:actionId"
        component={CoActionAttachments}
      />
      <PrivateRoute path="/companies/:coId/documents" component={CoDocs} />
      <PrivateRoute
        path="/companies/:coId/create-doc"
        component={CoCreateDoc}
      />
      <PrivateRoute path="/companies/:coId/doctypes" component={CoDocTypes} />
      <PrivateRoute
        path="/companies/:coId/doc-intparties/:docId"
        component={CoDocIntParties}
      />
      <PrivateRoute
        path="/companies/:coId/doc-revisions/:docId"
        component={CoDocRevisions}
      />
      <PrivateRoute
        path="/companies/:coId/add-revision/:docId"
        component={CoAddRevision}
      />
      <PrivateRoute
        path="/companies/:coId/docs-submitted/:docId"
        component={CoSupDocs}
      />
      <PrivateRoute
        path="/companies/:coId/subfolders/:docId"
        component={CoSubFolders}
      />
      <PrivateRoute path="/users" component={ManagementUsers} />
      <PrivateRoute path="/billing" component={MgBilling} />
      <PrivateRoute path="/templates" component={DocTemplates} />
      <PrivateRoute path="/update-manual" component={UpdateManual} />
      <PrivateRoute path="/iwan-testing" component={IwanTestArea} />
      <PrivateRoute path="/agents" component={MgmtAgents} />
      <PrivateRoute
        path="/companies/:coId/edit-doc/:docId"
        component={CoEditDoc}
      />
      <PrivateRoute path="/videos" component={MgmtVideos} />
      <PrivateRoute path="/video-cat/:id" component={VideoCategoryDetail} />
      <PrivateRoute path="/moc-templates" component={MocTemplates} />
      <PrivateRoute
        path="/edit-moc-checklist/:formId"
        component={EditMocChecklist}
      />

      {/* Import Stuff */}
      <PrivateRoute
        exact
        path="/companies/:coId/import-actions"
        component={CoActionsImport}
      />
      <PrivateRoute path="/data-mgmt" component={DataManagement} />
      <PrivateRoute
        path="/training-comp-data"
        component={TrainingCompetencyData}
      />
      
    </>
  );
}
