import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Save } from "@mui/icons-material";
import React, { useState } from "react";
import {
  textFieldProps,
  countries,
  selectFieldProps,
} from "../../shared/constants";
import Select from "react-select";
import FileUploader from "../../shared/FileUploader";
import { useAuth } from "../../../contexts/AuthContext";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { useDb } from "../../../contexts/DatabaseContext";

export default function AddProvider({ open, closeDialog }) {
  const { currentUser } = useAuth();
  const { createTrainingProvider } = useDb();

  const [state, setState] = useState({});

  // Action State
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleClose = () => {
    setState({});
    setError(null);
    setLoading(false);
    closeDialog();
  };

  const handleSubmit = async (e) => {
    // ... TODO: Handle DB Submit
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      await createTrainingProvider(state).then(() => {
        handleClose();
      });
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  // File Upload Functions
  const getUrl = (url) => {
    setState({
      ...state,
      beeCert: url,
    });
  };

  const getFilename = (fName) => {
    console.log(fName);
  };

  const getFileObj = (fObj) => {
    console.log(fObj);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Add Training Provider</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                {...textFieldProps}
                label="Company Name"
                name="name"
                onChange={handleChange}
                required
              />
              <TextField
                {...textFieldProps}
                label="Contact Email"
                name="email"
                onChange={handleChange}
                required
              />
              <Divider />
              <Typography>Company Address</Typography>
              <TextField
                {...textFieldProps}
                label="Street Address"
                name="street"
                onChange={handleChange}
                required
              />
              <TextField
                {...textFieldProps}
                label="City"
                name="city"
                onChange={handleChange}
                required
              />
              <TextField
                {...textFieldProps}
                label="Province / State"
                name="province"
                onChange={handleChange}
                required
              />
              <Select
                {...selectFieldProps}
                className="iso-select"
                options={countries}
                placeholder="Country ..."
                onChange={(v) =>
                  setState({
                    ...state,
                    country: v,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>BBBEEE Certificate</Typography>
              <br />
              <FileUploader
                userId={currentUser.uid}
                getUrl={getUrl}
                getFilename={getFilename}
                getFileObj={getFileObj}
              />
              <br />
              <br />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Certificate Expiry Date"
                  onChange={(d) =>
                    setState({ ...state, beeExpiry: d.toDate() })
                  }
                  defaultValue={dayjs(state.beeExpiry)}
                />
              </LocalizationProvider>
              <br />
              <br />
            </Grid>
          </Grid>
          {error ? <span className="iso-error">{error}</span> : null}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={loading || !state.country}
            startIcon={<Save />}
            variant="contained"
            color="primary"
            type="submit"
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
