import React from 'react'
import { useDb } from '../../../../contexts/DatabaseContext'
import FloatingHelpComponent from '../../../help/FloatingHelpComponent'
import DocRegisterData from './DocRegisterData'
import MyFavDocDatagrid from './MyFavDocDatagrid'

export default function MyFavouriteDocs() {
    const { GetUserFavouriteDocs } = useDb()
    const docs = GetUserFavouriteDocs()

    console.log(docs)
    return (
        <div>
            <h1>My Favourite Docs</h1>
            <MyFavDocDatagrid favDocuments={docs} />
            <FloatingHelpComponent
                name="Managing Favourite Docs" 
                videoPath="doc_manager"
                videoIndex={4}
            />
        </div>
    )
}
