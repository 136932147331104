import { Button, Card, CardContent, CardHeader, CircularProgress, IconButton } from '@mui/material';
import React, { useState } from 'react';
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid';
import { useParams } from 'react-router-dom'
import { Add, ArrowBack, Search } from '@mui/icons-material';
import AddSharedDocuments from './AddSharedDocuments';
import { useDb } from '../../../contexts/DatabaseContext';
import moment from 'moment';
import FileDialog from '../../shared/FileDialog';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export default function SharedFolderDocuments() {
    const { folderId } = useParams()
    const { GetSharedFolderDocs, getSharedFolder } = useDb()
    const [folder, setFolder] = useState({})
    const [open, setOpen] = useState(false)
    const [viewOpen, setViewOpen] = useState(false)
    const [docUrl, setDocUrl] = useState(false)
    const history = useHistory()

    useEffect(async () => {
      const d = await getSharedFolder(folderId)
      setFolder(d)
    }, [folderId])

    console.log(folder)

    const handleClose = () => {
        setOpen(false)
    }

    const handleViewClose = () => {
        setViewOpen(false)
    }

    const handleViewOpen = (url) => {
      console.log(url)
        setDocUrl(url)
        setViewOpen(true)
    }

    let tempArray = []

    GetSharedFolderDocs(folderId).map(d => {
        tempArray.push({
            ... d ,
            dateCreated: moment(d.dateCreated.toDate()).format('DD/MM/YY - hh:mm:ss')
        })
    })

    const rows = tempArray

    const columns = [
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => {
              return <IconButton onClick={() => {handleViewOpen(params.row.url)}} size="large"><Search /></IconButton>;
            }
          },
          {
            field: 'fileName',
            headerName: 'Name',
            width: 600
          },
          {
            field: 'createdBy',
            headerName: 'Created By',
            width: 600
          },
          {
            field: 'dateCreated',
            headerName: 'Date Created',
            width: 200
          },
    ]

    return !rows ? (<CircularProgress />) : (
        <div>
            <Button variant='contained' color='primary' startIcon={<ArrowBack />} onClick={() => history.goBack()}>Back</Button>
            <br />
            <h1>Shared Folder Documents: {folder.name}</h1>
            <br />
            <Card>
            <CardHeader
                    title={
                    <>       
                        <IconButton onClick={() => setOpen(true)} size="large">
                            <Add />
                        </IconButton>
                    </>
                    } 
                />
                <CardContent>
                  <IsoDataGrid
                    data={rows}
                    columns={columns}
                  />
                </CardContent>
            </Card>
            <AddSharedDocuments open={open} handleClose={handleClose} folderId={folderId} />
            <FileDialog closeDialog={handleViewClose} file={docUrl} open={viewOpen}/>
        </div>
    );
}


