import React from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid'
import { Button, IconButton } from '@mui/material'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import { Link, useHistory } from 'react-router-dom'
import moment from 'moment'

export default function OTPTempSearch() {
    const { GetOTPSearch } = useDb()
    const otp = GetOTPSearch()
    const history = useHistory()

    const rows = otp && otp.map(o  => ({
        id: o.id,
        loggedBy: `${o.loggedBy.email}`,
        dateCreated: moment(o.timestamp.toDate()).format('DD/MM/YYYY'),
        department: o.department.name,
        criteria: `${o.criteria.name}`,
        defineObjectives: o.defineObjectives
    }))

    const columns = [
        { 
            field: 'action', 
            headerName: 'Detail', 
            width: 140,
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                        <Link to={`/otp/${params.row.id}/targets`}>
                            <IconButton size="large">
                                <ArrowForward />
                            </IconButton>
                        </Link>
                     </div>
                );
            }
        },
        { field: 'loggedBy', headerName: 'Logged By.' , width: 200 },
        { field: 'dateCreated', headerName: 'Date Created' , width: 200 },
        { field: 'department', headerName: 'Department' , width: 200 },
        { field: 'criteria', headerName: 'Criteria' , width: 200 },
        { field: 'defineObjectives', headerName: 'Define Objectives' , width: 200 }
        
    ]

    return (
        <div>
            <div className="back-link">
                <Button onClick={() => history.goBack()} color="primary" variant="contained" startIcon={<ArrowBack />}>Back</Button>
            </div>
            <IsoDataGrid
                data={rows}
                columns={columns}
            />
        </div>
    )
}
