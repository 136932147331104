import React, { useState } from "react";
import Select from "react-select";
import { useDb } from "../../../contexts/DatabaseContext";
import { useAuth } from "../../../contexts/AuthContext";
import { selectFieldProps } from "../../shared/constants";
import { Button, Chip, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import BulkFileUploader from "../../shared/BulkFileUploader";

export default function AddEmployeeSupportingDocument({
  docId,
  closeAddDialog,
}) {
  const { GetEmpSubfoldersDropdown, addEmpSupportingDoc } = useDb();
  const { currentUser } = useAuth();
  const subFoldersOptions = GetEmpSubfoldersDropdown(docId);
  const [docUrl, setUrl] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [error, setError] = useState("");
  const [file, setFile] = useState("");
  const [expires, setExpires] = useState(false);
  const [expiryDate, setExpiryDate] = useState(new Date());
  const [fileObj, setFileObj] = useState(null);
  const [loading, setLoading] = useState(false);

  // For Bulk Uploads
  const [bulkFiles, setBulkFiles] = useState();

  const handleExpiryDate = (d) => {
    setExpires(true);
    setExpiryDate(d);
  };

  const addDocument = async (fUrl, fName) => {
    return new Promise(async (res, rej) => {
      await addEmpSupportingDoc(
        docId,
        {
          fUrl,
          folder: selectedFolder,
          fileName: fName,
          expires,
          expiryDate,
        },
        currentUser.email
      ).then(() => {
        res();
      });
    });
  };

  const addMultiple = async () => {
    await bulkFiles.forEach(async (f) => {
      await addDocument(f.url, f.fileName);
    });

    closeAddDialog();
  };

  const getFiles = async (f) => {
    setBulkFiles(f);
  };

  const toggleBusy = (busy) => {
    setLoading(busy);
  };

  return (
    <>
      <Select
        options={subFoldersOptions}
        placeholder={"Select Sub-Folder"}
        {...selectFieldProps}
        isSearchable={true}
        onChange={(val) => setSelectedFolder(val)}
      />
      <br />
      {/* <FileUploader getUrl={getUrl} userId={currentUser.uid} getFilename={getFilename} getFileObj={(obj) => setFileObj(obj)}/><br/> */}
      <BulkFileUploader
        getFiles={getFiles}
        userId={currentUser.uid}
        setBusy={toggleBusy}
      />{" "}
      <br />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Expiry Date (Optional)"
          onChange={(d) => handleExpiryDate(d.toDate())}
          defaultValue={dayjs(expiryDate)}
        />
      </LocalizationProvider>
      <br />
      <br />
      <span className="iso-error">{error}</span>
      {bulkFiles ? <Typography>Files to be attached: </Typography> : null}
      {bulkFiles &&
        bulkFiles.map((f) => {
          return <Chip size="small" label={f.fileName} key={f.url} />;
        })}
      <br />
      <br />
      <Button
        disabled={loading}
        onClick={addMultiple}
        fullWidth
        color="primary"
        startIcon={<FontAwesomeIcon icon={faSave} />}
        variant="contained"
      >
        Save
      </Button>
    </>
  );
}
