import { Avatar, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Grid, Slider, Typography } from '@mui/material'
import { ArrowForward, Info } from '@mui/icons-material'
import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useAuth } from '../../../../../contexts/AuthContext'
import { useDb } from '../../../../../contexts/DatabaseContext'
import RiskMatrix from '../../../risk-matrix/RiskMatrix'

export default function IBRiskMatrix() {
    const { currentUser } = useAuth()
    const { ibraId, riskId } = useParams()
    const { getIBRisk, updateIbraRisk } = useDb()
    const history = useHistory()
    const riskNum = parseInt(currentUser.riskSettings.riskMatrix.split('x')[0])

    const [risk, setRisk] = useState(null)

    const [compState, setCompState] = useState({
        loading: false,
        error: null
    })
    const [state, setState] = useState({
    })

    const [final, setFinal] = useState({
        like: 0,
        cons: 0
    })

    const handleChange = (e, val, name) => {
        setState({
            ...state,
            [name]: val
        })
    }

    const handleSubmit = async () => {
        setCompState({
            ...compState,
            loading: true,
            error: null
        })
        try {
            await updateIbraRisk(ibraId, riskId, state)
            history.push(`/risk-manager/issue-based/${ibraId}/residual-risk/${riskId}`)
        }
        catch(err) {
            setCompState({
                ...compState,
                loading: false,
                error: err.message
            })
        }
    }

    const firstLoad = async () => {
        const r = await getIBRisk(ibraId, riskId)
        setState({
            ...state,
            finalLike: 2,
            finalCons: 2,
            initialLike: 2,
            initialCons: 2
        })
        setRisk({
            ...r,
            initialValue: 2,
            finalValue: 2,
            finalLike: 2,
            finalCons: 2
        })
    }

    if(!risk) {
        firstLoad()
        return <CircularProgress />
    }
    else return (
        <div>
            <h1>Risk Matrix</h1>
            <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                    <Card>
                        <CardContent>
                            <CardHeader
                                title="Inherent Risk"
                                avatar={
                                    <Avatar>
                                        <Info />
                                    </Avatar>
                                }
                            />
                            <Typography>
                                Likelihood
                            </Typography>
                            <br/>
                            <Slider
                                defaultValue={1}
                                step={1}
                                marks
                                min={1}
                                max={riskNum}
                                value={state.initialLike}
                                valueLabelDisplay="on"
                                onChangeCommitted={(e,v) => handleChange(e,v,'initialLike')}
                            />
                            <Typography>
                                Consequences
                            </Typography>
                            <br/>
                            <Slider
                                defaultValue={1}
                                step={1}
                                marks
                                min={1}
                                max={riskNum}
                                value={state.initialCons}
                                valueLabelDisplay="on"
                                onChangeCommitted={(e,v) => handleChange(e,v,'initialCons')}
                            />
                        </CardContent>
                    </Card>
                    <br/>
                    <Card>
                        <CardContent>
                            <CardHeader
                                title="Residual Risk"
                                avatar={
                                    <Avatar>
                                        <Info />
                                    </Avatar>
                                }
                            />
                            <Typography>
                                Likelihood
                            </Typography>
                            <br/>
                            <Slider
                                defaultValue={1}
                                step={1}
                                marks
                                min={1}
                                max={riskNum}
                                value={state.finalLike}
                                valueLabelDisplay="on"
                                onChangeCommitted={(e,v) => handleChange(e,v,'finalLike')}
                            />
                            <Typography>
                                Consequences
                            </Typography>
                            <br/>
                            <Slider
                                defaultValue={1}
                                step={1}
                                marks
                                min={1}
                                max={riskNum}
                                value={state.finalCons}
                                valueLabelDisplay="on"
                                onChangeCommitted={(e,v) => handleChange(e,v,'finalCons')}
                            />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={9}>
                    {
                        risk.finalValue > 0
                        ?
                        <RiskMatrix
                            type={currentUser.riskSettings.riskMatrix}
                            initialLike={state.initialLike}
                            initialCons={state.initialCons}
                            finalLike={state.finalLike}
                            finalCons={state.finalCons}
                        />
                        :
                        <Typography>
                            Risk Eliminated
                        </Typography>
                    }
                    
                </Grid>
            </Grid>
            <br/>
            <CardActions className="iso-right">
                {
                    state.error && <Typography color="secondary">{state.error}</Typography>
                }
                <Button
                    variant="contained"
                    color="primary"
                    endIcon={<ArrowForward />}
                    disabled={
                        compState.loading
                    }
                    onClick={handleSubmit}
                >
                    Next: Residual Risk
                </Button>
            </CardActions>
        </div>
    )
}
