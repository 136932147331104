import {
    Avatar,
    Button,
    Card,
    CardContent,
    CardHeader,
    Container,
    CssBaseline,
    TextField,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react'
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import EmailIcon from '@mui/icons-material/Email';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext'

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      //display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    card: {
        backgroundColor: '#d5f0d9',
        marginBottom: theme.spacing(2),
        color: '#3f6e50'
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
}));

export default function ForgotPassword({ setForgot }) {
    const classes = useStyles();
    const [email, setEmail] = useState('')
    const { resetPassword } = useAuth()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')

    async function handleSubmit(e) {
        e.preventDefault()
        
        try {
            setMessage('')
            setError('')
            setLoading(true)
            await resetPassword(email)
            setMessage('Check your inbox for reset instructions')
        } catch(err) {
            setError(err.message)
        }
        setLoading(false)
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Card className={classes.paper}>
                <CardHeader
                    title={'Forgot Password'}
                    avatar={<Avatar><LiveHelpIcon /></Avatar>}
                    subheader={'Enter the email where we can send the password reset'}
                />
                <CardContent>
                    <form className={classes.form} onSubmit={handleSubmit}>
                        <TextField type="email" onChange={(e) => setEmail(e.target.value)} fullWidth label="Email" variant="outlined" />
                        <span style={{color: 'red'}}>{error}</span>
                        <br/><br/>
                        {
                            message !== '' ? <Card elevation={0} className={classes.card}><CardHeader avatar={<Avatar><EmailIcon /></Avatar>} title={<Typography>{message}</Typography>} /></Card> : null
                        }
                        <Button
                            disabled={ loading ? true : false }
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                        >Reset Password</Button>
                    </form>
                    <br/>
                    <div style={{textAlign: 'center'}}>
                        <Button onClick={setForgot} color="secondary">Cancel</Button>
                    </div>
                </CardContent>
            </Card>
        </Container>
    )
}
