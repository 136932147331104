import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import React, { useState } from "react";
import AddParticipant from "./AddParticipant";

export default function AddParticipants({
  handleAdd,
  moduleName,
  accessLevel,
  roleName,
}) {
  const [addOpen, setAddOpen] = useState(false);

  const toggleAddOpen = (openBool) => {
    setAddOpen(openBool);
  };

  return (
    <div>
      <Button
        color="primary"
        size="small"
        variant="contained"
        startIcon={<Add />}
        onClick={() => toggleAddOpen(true)}
      >
        Add {roleName}
      </Button>

      <AddParticipant
        open={addOpen}
        close={() => toggleAddOpen(false)}
        add={handleAdd}
        moduleName={moduleName}
        accessLevel={accessLevel}
        roleName={roleName}
      />
    </div>
  );
}
