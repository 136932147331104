import { Button, Card, CardActions, CardContent, CircularProgress, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import { useDb } from '../../../../contexts/DatabaseContext'
import Select from 'react-select'
import { selectFieldProps } from '../../../shared/constants'
import { ArrowForward } from '@mui/icons-material'
import ExistingProcesses from './ExistingProcesses'

export default function AddBaselineRisk2() {
    const { blraId, siteId } = useParams()
    const history = useHistory()
    const { getBaseline, GetRiskAreasForSite, GetRiskProcesses, advanceBaseline, addProcessesToBaseline } = useDb()
    const areas = GetRiskAreasForSite(siteId)
    const procs = GetRiskProcesses()

    const [state, setState] = useState({
        loading: false,
        error: null,
        data: {}
    })

    const [blra, setBlra] = useState(null)

    const handleSelect = (v, e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.name]: v.value
            }
        })
    }

    const handleMultiSelect = (v,e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.name]: v
            }
        })
    }

    const handleSubmit = async () => {
        setState({
            ...state,
            error: null,
            loading: true
        })
        try {
            await addProcessesToBaseline(blraId, state.data.processes)
            await advanceBaseline(blra.blra, blraId, { area: state.data.area })
            history.push(`/risk-manager/baselines/add-baseline-3/${blraId}/`)
        }
        catch(err) {
            setState({
                ...state,
                error: err.message,
                loading: false
            })
        }
    }

    const firstLoad = async () => {
        const b = await getBaseline(blraId)
        setBlra(b)
    }

    if(!blra) {
        firstLoad()
        return <CircularProgress />
    }
    else return (
        <div>
            <div>
                <h1>{blra.blra}</h1>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardContent>
                                <Select
                                    options={areas}
                                    {...selectFieldProps}
                                    className="iso-select"
                                    placeholder="Select Workplace/Area ..."
                                    name="area"
                                    onChange={handleSelect}
                                />
                                <Select
                                    options={procs}
                                    {...selectFieldProps}
                                    className="iso-select"
                                    placeholder="Select Process ..."
                                    name="processes"
                                    onChange={handleMultiSelect}
                                    isMulti
                                />      
                                Don't see a process you need? <Link style={{ color: 'blue' }} to={'/risk-manager/processes/add'}>Create a new process</Link>
                            </CardContent>
                            <CardActions className="iso-right">
                                {
                                    state.error && <Typography color="secondary">{ state.error }</Typography>
                                }
                                <Button
                                    color="primary"
                                    variant="contained"
                                    endIcon={<ArrowForward />}
                                    onClick={handleSubmit}
                                    disabled={
                                        state.loading ||
                                        !state.data.processes ||
                                        !state.data.area
                                    }
                                >
                                    Next
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ExistingProcesses 
                            blraId={blraId}
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}
