import {
  Card,
  CardContent,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Table,
  TextField,
  CardActions,
  Chip,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Delete, Edit } from "@mui/icons-material";
import { Pagination } from '@mui/material';
import React, { useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { useDb } from "../../../contexts/DatabaseContext";
import { textFieldProps } from "../../shared/constants";
import EditSource from "./EditSource";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.cardBg,
  },
}));

export default function SourceList() {
  const classes = useStyles();
  const { GetSources, deleteSource } = useDb();
  const sources = GetSources();
  const { currentUser } = useAuth();
  const [search, setSearch] = useState(null);
  const [error, setError] = useState(null);

  // Pagination
  const itemsPerPage = 10; // <- Not state but related to below pagination
  const [page, setPage] = useState(1);
  const [noOfPages] = useState(Math.ceil(sources.length / itemsPerPage));

  //Editing
  const [editOpen, setEditOpen] = useState(false);
  const [selectedSource, setSelectedSource] = useState(null);
  const handleEdit = (s) => {
    setSelectedSource(s);
    setEditOpen(true);
  };
  const handleDelete = async (s) => {
    try {
      await deleteSource(s.id);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <TextField
          className={"iso-text-field"}
          {...textFieldProps}
          label="Search Sources"
          onChange={(e) => setSearch(e.target.value)}
        />
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Source Name</TableCell>
                <TableCell>Root Cause Required?</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search !== null || ""
                ? sources &&
                  sources.map((source) => {
                    if (
                      source.sourceName.toLowerCase().includes(search) ||
                      source.sourceName.includes(search)
                    ) {
                      return (
                        <TableRow key={source.id}>
                          <TableCell>{source.sourceName}</TableCell>
                          <TableCell>
                            {source.rootCauseRequired ? <Chip label="Yes" color="primary" /> : "No"}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton onClick={() => handleEdit(source)} size="large">
                              <Edit />
                            </IconButton>
                            {currentUser.accessRights.actionManager < 1 ? (
                              <IconButton onClick={() => handleDelete(source)} size="large">
                                <Delete />
                              </IconButton>
                            ) : null}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  })
                : sources &&
                  sources
                    .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                    .map((source) => {
                      return (
                        <TableRow key={source.id}>
                          <TableCell>{source.sourceName}</TableCell>
                          <TableCell>
                            {source.rootCauseRequired ? <Chip label="Yes" color="primary" /> : "No"}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton onClick={() => handleEdit(source)} size="large">
                              <Edit />
                            </IconButton>
                            {currentUser.accessRights.actionManager < 1 ? (
                              <IconButton onClick={() => handleDelete(source)} size="large">
                                <Delete />
                              </IconButton>
                            ) : null}
                          </TableCell>
                        </TableRow>
                      );
                    })}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <CardActions>
        <Pagination
          count={Math.ceil(sources.length / itemsPerPage)}
          page={page}
          onChange={(e, val) => setPage(val)}
          defaultPage={1}
          color="primary"
          size="large"
          showFirstButton
          showLastButton
        />
        {error ? <span className="iso-error">{error}</span> : null}
      </CardActions>

      {selectedSource ? (
        <EditSource
          open={editOpen}
          closeDialog={() => setEditOpen(false)}
          source={selectedSource}
        />
      ) : null}
    </Card>
  );
}
