import React, { useState } from 'react'
import { Avatar, Card, CardContent, CardHeader, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Button } from '@mui/material'
import { Info, Search } from '@mui/icons-material'
import { useDb } from '../../contexts/DatabaseContext'
import moment from 'moment'
import SearchAuditTrail from './SearchAuditTrail'
import FloatingHelpComponent from '../help/FloatingHelpComponent'

export default function AuditTrail() {
    const { GetAuditTrail100 } = useDb()
    const logs = GetAuditTrail100()
    const [searchOpen, setSearchOpen] = useState(false)

    return (
        <div>
            <h1>Audit Trail</h1>
            <Card>
                <CardHeader
                    title="Most Recent 100 Events:"
                    avatar={<Avatar><Info /></Avatar>}
                    action={
                        <Button startIcon={<Search />} onClick={() => setSearchOpen(true)} color="primary" variant="contained">
                            Search User Logs
                        </Button>
                    }
                />
                <CardContent>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Time</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>User</TableCell>
                                    <TableCell>Description</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    logs && logs.map(log => {
                                        const user = log.user
                                        return (
                                            <TableRow key={log.id}>
                                                <TableCell>{moment(log.timestamp.toDate()).format('HH:mm')}</TableCell>
                                                <TableCell>{moment(log.timestamp.toDate()).format('DD/MM/YYYY')}</TableCell>
                                                <TableCell>{`${user.firstName} ${user.lastName} - ${user.email}`}</TableCell>
                                                <TableCell>{log.event}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>

            <SearchAuditTrail open={searchOpen} closeDialog={() => setSearchOpen(false)} />
            
            <FloatingHelpComponent
                name="How to use Audit Trail" 
                videoPath="master_list"
                videoIndex={0}
            />
        </div>
    )
}
