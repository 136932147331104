import {
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Divider,
  Typography,
  Grid,
} from "@mui/material";
import { Save } from "@mui/icons-material";

import { useEffect } from "react";
import { useState } from "react";
import {
  textFieldProps,
  countries,
  selectFieldProps,
} from "../../shared/constants";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { useAuth } from "../../../contexts/AuthContext";
import FileUploader from "../../shared/FileUploader";
import Select from "react-select";

export default function EditProvider({ open, closeDialog, data }) {
  const [state, setState] = useState({});
  const { currentUser } = useAuth();

  useEffect(async () => {
    setState({
      email: data.email,
      street: data.street,
      city: data.city,
      province: data.province,
      country: data.country,
      beeCert: data.beeCert ? data.beeCert : null,
      beeExpiry: data.beeExpiry ? data.beeExpiry.toDate() : null,
    });
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      data.ref.update({
        ...state,
      });
    } catch (error) {
      console.log(error);
    }
    console.log(state);
    setState({});
    closeDialog();
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  // File Upload Functions
  const getUrl = (url) => {
    setState({
      ...state,
      beeCert: url,
    });
  };

  const getFilename = (fName) => {
    console.log(fName);
  };

  const getFileObj = (fObj) => {
    console.log(fObj);
  };

  return (
    <div>
      <Dialog open={open} maxWidth="md" fullWidth onClose={closeDialog}>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  {...textFieldProps}
                  defaultValue={data.name}
                  label="Company Name"
                  name="name"
                  onChange={handleChange}
                  required
                />
                <TextField
                  {...textFieldProps}
                  defaultValue={data.email}
                  label="Contact Email"
                  name="email"
                  onChange={handleChange}
                  required
                />
                <Divider />
                <Typography>Company Address</Typography>
                <TextField
                  {...textFieldProps}
                  label="Street Address"
                  defaultValue={data.street}
                  name="street"
                  onChange={handleChange}
                  required
                />
                <TextField
                  {...textFieldProps}
                  defaultValue={data.city}
                  label="City"
                  name="city"
                  onChange={handleChange}
                  required
                />
                <TextField
                  {...textFieldProps}
                  label="Province / State"
                  defaultValue={data.province}
                  name="province"
                  onChange={handleChange}
                  required
                />
                <Select
                  {...selectFieldProps}
                  className="iso-select"
                  options={countries}
                  placeholder="Country ..."
                  onChange={(v) =>
                    setState({
                      ...state,
                      country: v,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography>BBBEEE Certificate</Typography>
                <br />
                <FileUploader
                  userId={currentUser.uid}
                  getUrl={getUrl}
                  getFilename={getFilename}
                  getFileObj={getFileObj}
                />
                <br />
                <br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Certificate Expiry Date"
                    onChange={(d) =>
                      setState({ ...state, beeExpiry: d.toDate() })
                    }
                    defaultValue={dayjs(state.beeExpiry)}
                  />
                </LocalizationProvider>

                <br />
                <br />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              startIcon={<Save />}
              variant="contained"
              color="primary"
              type="submit"
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
