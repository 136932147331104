import { Avatar, Button, Card, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { textFieldProps } from '../../../../shared/constants'
import FileUploader from '../../../../shared/FileUploader'
import { useAuth } from '../../../../../contexts/AuthContext'
import { Info } from '@mui/icons-material'
import { useDb } from '../../../../../contexts/DatabaseContext'

export default function UploadCustomSign({ open, close, riskId, ibraId, type, setSign }) {
    const { currentUser } = useAuth()
    const { addCustomSign } = useDb()

    const [state, setState] = useState({
        loading: false,
        error: null,
        data: {}
    })
    
    const handleClose = () => {
        setState({
            loading: false,
            error: null,
            data: {}
        })
        close()
    }

    const handleChange = (e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.target.name]: e.target.value
            }
        })
    }

    const getUrl = (url) => {
        setState({
            ...state,
            data: {
                ...state.data,
                png: url
            }
        })
    }

    const getFile = (f) => {}

    const handleSubmit = async () => {
        setState({
            ...state,
            loading: true,
            error: null
        })
        try { 
            const id = await addCustomSign({...state.data, type })
            setSign({ ...state.data, type, id })
            handleClose()
        }
        catch(err) {
            setState({
                ...state,
                loading: false,
                error: err.message
            })
        }
    }
    
    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>Upload new {type} Sign</DialogTitle>
            <DialogContent>
                <TextField
                    {...textFieldProps}
                    required
                    label="Sign Code"
                    name="code"
                    onChange={handleChange}
                />
                <TextField
                    {...textFieldProps}
                    required
                    label="Name"
                    name="name"
                    onChange={handleChange}
                />
                <Card style={{ margin: '10px 0'}}>
                    <CardHeader
                        title="Please Note"
                        subheader="File types: PNG or JPG. Recommended image size: 512px x 512px"
                        avatar={
                            <Avatar>
                                <Info />
                            </Avatar>
                        }
                    />
                </Card>
                <FileUploader 
                    getUrl={getUrl}
                    getFileObj={getFile}
                    getFilename={getFile}
                    getFile={getFile}
                    userId={currentUser.uid}
                />
                {
                    state.error && <Typography color="secondary">{state.error}</Typography>
                }
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    disabled={
                        state.loading ||
                        !state.data.code ||
                        !state.data.name ||
                        !state.data.png
                    }
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
}
