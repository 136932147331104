import { Avatar, Card, CardContent, CardHeader, IconButton, Menu, MenuItem, Dialog, DialogContent } from '@mui/material'
import React, { useState } from 'react'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import InterestedParties from './interested-parties/InterestedParties'
import { useDb } from '../../../contexts/DatabaseContext'
import RevisionListItem from './RevisionListItem'
import { Pagination } from '@mui/material';

export default function RevisionHistory({ doc }) {
    const { GetRevisions } = useDb()
    const revisions = GetRevisions(doc.id);
    
    // Pagination
    const itemsPerPage = 5; // <- Not state but related to below pagination
    const [page, setPage] = useState(1);
    const [noOfPages] = useState(
        Math.ceil(revisions.length / itemsPerPage)
    );
    
    var counter = 0;
    return (
        <>
            <h3>Revision History: {doc.docCode}</h3>
            {  revisions && revisions
                .slice((page -1) * itemsPerPage, page * itemsPerPage)
                .map((rev) => {
                counter ++
                return (
                    <RevisionListItem key={rev.id} rev={rev} doc={doc} counter={counter}/>
                )
            })}
            <br/>
            <Pagination
                count={Math.ceil(revisions.length / itemsPerPage)}
                page={page}
                onChange={(e, val) => setPage(val)}
                defaultPage={1}
                color="primary"
                size="large"
                showFirstButton
                showLastButton
            />
        </>
    )
}
