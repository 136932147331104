import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Slider,
    TextField,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react'
import { useAuth } from '../../../contexts/AuthContext';
import { useDb } from '../../../contexts/DatabaseContext';
import { textFieldProps } from '../../shared/constants'
import FileUploader from '../../shared/FileUploader';
import InfoBlurb from '../../shared/InfoBlurb';

const marks = [
    {
      value: 1,
      label: '1',
      compValue: 'Not Competent'
    },
    {
      value: 2,
      label: '2',
      compValue: 'Needs Improvement'
    },
    {
      value: 3,
      label: '3',
      compValue: 'Competent'
    },
    {
      value: 4,
      label: '4',
      compValue: 'Competent - Can Instruct'
    },
    {
      value: 5,
      label: '5',
      compValue: 'Competent - Has Instructed'
    },
];

const useStyles = makeStyles((theme) => ({
    sectionDiv: {
        border: '1px solid lightgrey',
        borderRadius: 10,
        padding: 20,
        marginBottom: 10
    }
}))

export default function ScoreAttendee({ employee, open, close, session }) {
    const { assessCompetence } = useDb()
    const { currentUser } = useAuth()
    const classes = useStyles()

    const [state, setState] = useState({
        
    })
    const [actionState, setActionState] = useState({ loading: false, error: null })

    const handleClose = () => {
        setActionState({ loading: false, error: null })
        setState({})
        close()
    }

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const handleSlide = (e, val) => {
        var level = marks.filter(mark => {
            return mark.value === val
        })
        setState({
            ...state,
            compLevel: val,
            compLevelLabel: level[0].compValue
        })
    }

    const handleSubmit = async () => {
        setActionState({ loading: true, error: null })
        try {
            await assessCompetence(session.id, employee.id, state)
            handleClose()
        }
        catch(err) {
            setActionState({ loading: false, error: err.message })
        }
    }

    const getUrl = (url) => {
        setState({
            ...state,
            practicalUrl: url
        })
    }

    const getFileOther = () => {

    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>Assess Attendee Competence</DialogTitle>
            <DialogContent>
                <Slider
                    defaultValue={1}
                    step={1}
                    marks={marks}
                    min={1}
                    max={5}
                    onChangeCommitted={handleSlide}
                    valueLabelDisplay="on"
                    // getAriaValueText={valuetext}
                />
                <div style={{ marginBottom: 10 }}>
                    <Typography>
                        Competency: {state.compLevelLabel}
                    </Typography>
                </div>
                {
                    session.module.practicalRequired
                    ?
                    <div className={classes.sectionDiv}>
                        <InfoBlurb 
                            message="This module requires proof of a practical assessment. Please upload practical proof below."
                        />
                        <FileUploader
                            getUrl={getUrl}
                            getFileObj={getFileOther}
                            getFilename={getFileOther}
                            userId={currentUser.uid}
                        />
                    </div>
                    :
                    null
                }
                <TextField
                    {...textFieldProps}
                    label="Comments"
                    name="comment"
                    onChange={handleChange}
                />
                { actionState.error ? <span className="iso-error">{actionState.error}</span> : null }
            </DialogContent>
            <DialogActions>
                <Button disabled={ actionState.loading } color="secondary" onClick={handleClose}>Cancel</Button>
                {
                    session.module.practicalRequired
                    ?
                    <Button disabled={ actionState.loading || !state.compLevel || !state.practicalUrl } color="primary" variant="contained" onClick={handleSubmit}>Confirm</Button>
                    :
                    <Button disabled={ actionState.loading || !state.compLevel } color="primary" variant="contained" onClick={handleSubmit}>Confirm</Button>
                }
            </DialogActions>
        </Dialog>
    )
}
