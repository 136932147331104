import React, { useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import RiskMatrix from "../../riskmanager/risk-matrix/RiskMatrix";
import { Info } from "@mui/icons-material";
import { textFieldProps } from "../../shared/constants";

export default function MocRisk({ updates, setUpdates }) {
  const { currentUser } = useAuth();
  const riskNum = parseInt(currentUser.riskSettings.riskMatrix.split("x")[0]);

  const [risk, setRisk] = useState({
    initialValue: 2,
    finalValue: 2,
    finalLike: 2,
    finalCons: 2,
  });

  const [state, setState] = useState({});

  const handleChange = (e, val, name) => {
    setUpdates({
      ...updates,
      [name]: val,
    });
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <CardHeader
                title="Inherent Risk"
                avatar={
                  <Avatar>
                    <Info />
                  </Avatar>
                }
                subheader={
                  <TextField
                    size="small"
                    {...textFieldProps}
                    placeholder="Risk Description relevant to this MOC"
                    value={updates.riskDescription}
                    onChange={(e) => {
                      handleChange(e, e.target.value, "riskDescription");
                    }}
                  />
                }
              />
              <Typography>Likelihood</Typography>
              <br />
              <Slider
                defaultValue={1}
                step={1}
                marks
                min={1}
                max={riskNum}
                value={updates.initialLike}
                valueLabelDisplay="on"
                onChangeCommitted={(e, v) => handleChange(e, v, "initialLike")}
              />
              <Typography>Consequences</Typography>
              <br />
              <Slider
                defaultValue={1}
                step={1}
                marks
                min={1}
                max={riskNum}
                value={updates.initialCons}
                valueLabelDisplay="on"
                onChangeCommitted={(e, v) => handleChange(e, v, "initialCons")}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={8}>
          {risk.finalValue > 0 ? (
            <RiskMatrix
              type={currentUser.riskSettings.riskMatrix}
              initialLike={updates.initialLike}
              initialCons={updates.initialCons}
              finalLike={updates.finalLike}
              finalCons={updates.finalCons}
            />
          ) : (
            <Typography>Risk Eliminated</Typography>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
