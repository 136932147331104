import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import React from 'react'
import moment from 'moment'

export default function RevisionDetails({ rev, open, closeDialog }) {
    return (
        <Dialog open={open} onClose={closeDialog} maxWidth="sm" fullWidth>
            <DialogTitle>
                Revision: {rev.revCode}
            </DialogTitle>
            <DialogContent>
                <Typography>Requested By: {rev.requestedBy.label}</Typography>
                <Typography>Request Date: {moment(rev.createdDate.toDate()).format('DD/MM/YYYY')}</Typography>
                <Typography>Target Revision Date: {moment(rev.targetRevDate.toDate()).format('DD/MM/YYYY')}</Typography>
                <Typography>Description: {rev.description}</Typography>
                <Typography>Revised By:</Typography>
                <ul>
                    {rev.revisedBy && rev.revisedBy.map(r => {
                        return (
                            <li key={r.uid}>{r.label}</li>
                        )
                    })}
                </ul>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={closeDialog}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}
