export const mocDeclined = (moc, approverName, declineReason) => {
  const template = {
    message: `MOC ${moc.mocNumber} has been declined by ${approverName} for the following reason: ${declineReason}`,
    subject: `MOC ${moc.mocNumber} Declined`,
    btnLabel: moc.mocNumber,
    btnRoute: `moc-manager/moc/${moc.mocNumber}`,
    preheader: `MOC ${moc.mocNumber} Declined`,
  };
  return template;
};

export const mocApproved = (moc, approverName) => {
  const template = {
    message: `MOC ${moc.mocNumber} has been approved by ${approverName}.`,
    subject: `MOC ${moc.mocNumber} Approved`,
    btnLabel: moc.mocNumber,
    btnRoute: `moc-manager/moc/${moc.mocNumber}`,
    preheader: `MOC ${moc.mocNumber} Approved`,
  };
  return template;
};
