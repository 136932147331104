import { Fab } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Help, PlayCircleFilled } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useDb } from '../../contexts/DatabaseContext';
import VideoDialog from './VideoDialog';

const useStyles = makeStyles((theme) => ({
    fab: {
        margin: theme.spacing.unit, // You might not need this now
        position: "fixed",
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 3,
        fontSize: 12
    }
}))

export default function FloatingHelpComponent({ name, videoPath, videoIndex }) {
    const classes = useStyles();
    const { getVideo } = useDb();
    const [video, setVideo] = useState();
    const [videoId, setVideoId] = useState();
    const [open, setOpen] = useState(false);

    function getVideoId(url){
        url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
        return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
    }

    const retrieveVideo = async () => {
        const v = await getVideo(videoPath, videoIndex);
        const vidId = getVideoId(v.url);
        setVideoId(vidId);
        setVideo(v);
    }

    useEffect(() => {
      const unsubscribe = retrieveVideo();
      return unsubscribe;
    }, []);

    
    
    return (
        <>
            <Fab 
                variant="extended" 
                className={classes.fab}
                color="primary"
                onClick={() => setOpen(true)}
            >
                <PlayCircleFilled />
                &nbsp; {name}
            </Fab>

            {
                video && <VideoDialog 
                    open={open}
                    close={() => setOpen(false)}
                    videoId={videoId}
                    title={name}
                />
            }
        </>
    )
}
