import { faSkullCrossbones } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, CardActions, CardContent, TextField } from '@mui/material'
import { Warning } from '@mui/icons-material'
import React, { useState } from 'react'
import { useDb } from '../../contexts/DatabaseContext'
import { textFieldProps } from '../shared/constants'

export default function IwanTestArea() {
    const { MgmtGetAllRisks, mgmtBuildControls  } = useDb()

    const risks = MgmtGetAllRisks();

    console.log("Risks:")
    console.log(risks)

    const handleRiskUpdate = async () => {
        console.log("Risk Job Complete!")
        try {
            await mgmtBuildControls(risks)
        }
        catch(err) {
            window.alert(err.message)
        }
    }

    return (
        <div>
           <Button onClick={handleRiskUpdate} color="secondary" variant="contained">Execute Risk Controls Update</Button>
        </div>
    )
}
