import { Card, CardContent, CardHeader, Grid } from '@mui/material'
import React from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import VideoCategory from './VideoCategory';

export default function MgmtVideos() {
    const { MgmtGetVideoCategories } = useDb();
    const cats = MgmtGetVideoCategories();

    return (
        <div>
            <Card>
                <CardHeader
                    title="Videos"
                />
                <CardContent>
                    <Grid container spacing={3}>
                        {
                            cats && cats.map(cat => (
                                <Grid item xs={12} md={4}>
                                    <VideoCategory cat={cat} />
                                </Grid>
                            ))
                        }
                    </Grid>
                </CardContent>
            </Card>
        </div>
    )
}
