import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    Card,
    CardContent,
    TextField,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    CardActions,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Delete, Edit } from '@mui/icons-material'
import { Pagination } from '@mui/material';
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import { textFieldProps } from '../../shared/constants'
import EditIsoAgency from './EditIsoAgency'
import IsoAgencyDataGrid from './IsoAgencyDataGrid'

const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: theme.palette.cardBg
    },
    dialog: {
        backgroundColor: theme.palette.dialogBg
    }
}))

export default function IsoAgencyList() {
    const classes = useStyles()
    const { GetIsoAgencies, deleteIsoAgency } = useDb()
    const agencies = GetIsoAgencies()
    const [search, setSearch] = useState(null)

    // Pagination
    const itemsPerPage = 10; // <- Not state but related to below pagination
    const [page, setPage] = useState(1);
    const [noOfPages] = useState(
        Math.ceil(agencies.length / itemsPerPage)
    );

    const [error, setError] = useState(null)

    // Editing
    const [selectedAgency, setSelectedAgency] = useState(null)
    const [editOpen, setEditOpen] = useState(false)
    const handleEdit = (a) => {
        setSelectedAgency(a)
        setEditOpen(true)
    }

    const handleDelete = async (a) => {
        setError(null)
        try {
            await deleteIsoAgency(a.id)
        }
        catch(err) {
            setError(err.message)
        }
    }

    return (
        <Card className={classes.card}>
            <CardContent>
                <IsoAgencyDataGrid data={agencies} handleDelete={handleDelete} handleEdit={handleEdit} />
            </CardContent>

            {
                selectedAgency 
                ?
                <EditIsoAgency open={editOpen} closeDialog={() => setEditOpen(false)} agency={selectedAgency} />
                :
                null
            }
        </Card>
    )
}
