import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material'
import React, { useState } from 'react'
import { textFieldProps, standardOptions, selectFieldProps } from '../../shared/constants'
import Select from 'react-select'
import { Save } from '@mui/icons-material'
import { useDb } from '../../../contexts/DatabaseContext'

export default function EditIsoClass({ open, closeDialog, isoClass }) {
    const { updateIsoClass } = useDb()

    // Form State
    const [state, setState] = useState({
        standard: isoClass.standard,
        code: isoClass.code
    })
    const [updated, setUpdated] = useState(false)

    // Action State 
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
        setUpdated(true)
    }

    const handleSelect = (v) => {
        setState({ 
            ...state, 
            standard: v.value 
        })
        setUpdated(true)
    }

    const handleClose = () => {
        setUpdated(false)
        setLoading(false)
        closeDialog()
    }

    const handleOpen = () => {
        setState({
            standard: isoClass.standard,
            code: isoClass.code
        })
    }

    const handleSubmit = async () => {
        setLoading(true)
        setError(null)
        try {
            await updateIsoClass(isoClass.id, state).then(() => {
                handleClose()
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
            TransitionProps={{
                onEnter: handleOpen
            }}>
            <DialogContent>
                Standard: {state.standard}
                <Select
                    options={standardOptions}
                    { ...selectFieldProps }
                    placeholder="Change Standard ..."
                    onChange={handleSelect}
                />
                <br/>
                <TextField
                    {...textFieldProps}
                    value={state.code}
                    onChange={handleChange}
                    name="code"
                />
            </DialogContent>
            <DialogActions>
                { error ? <span className="iso-error">{error}</span> : null }
                <Button disabled={loading} color="secondary" onClick={handleClose}>Cancel</Button>
                <Button disabled={!updated || loading} color="primary" variant="contained" onClick={handleSubmit} startIcon={<Save />}>Update</Button>
            </DialogActions>
        </Dialog>
    );
}
