import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent
} from "@mui/material";

export default function EmailVerification() {
  const { emailVerified, sendEmailVerification } = useAuth();
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  return (
    <div>
      {!emailVerified && (
        <>
          <Alert
            severity="warning"
            style={{ display: "flex", flexDirection: "row" }}
          >
            Your email address has not been verified.
            <Button
              onClick={() => {
                setLoading(true);
                sendEmailVerification()
                  .then(() => {
                    console.log("Gere????");
                    setLoading(false);
                    setShowSuccess(true);
                  })
                  .catch(err => {
                    alert(err.message);
                  });
              }}
              disabled={loading}
              style={{ marginLeft: 10 }}
            >
              {loading ? "Loading..." : "Verify"}
            </Button>
          </Alert>
          <Dialog
            maxWidth="sm"
            fullWidth
            open={showSuccess}
            onClose={() => setShowSuccess(false)}
          >
            <DialogTitle>Success</DialogTitle>
            <DialogContent>
              <p>
                Please check your inbox for a link to verify your email address.
                Once done, refresh this page to clear the alert.
              </p>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setShowSuccess(false);
                }}
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </div>
  );
}
