import { FormControlLabel, Grid, Switch } from '@mui/material'
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import Select from 'react-select'
import { selectFieldProps } from '../../shared/constants'

export default function OtpRegisterFilters({ updateFilters }) {
    const { GetDepartments, GetCriteria, GetUsersLevelFilter } = useDb()
    const departments = GetDepartments()
    const criteria = GetCriteria()
    // const hod = GetUserList()
    const hod = GetUsersLevelFilter("otpManager", 2)
    // When calling this function, be sure to use a ternary operator in the last argument for a possible null value. See examples in Selects below.
    const handleSelect = (v, key, fieldName, value) => {
        console.log(value)
        if(!v) {
            updateFilters({
                key,
                data: {
                    fieldName,
                    value: null
                }
            })
        }
        else {
            updateFilters({
                key,
                data: {
                    fieldName,
                    value
                }
            })
        }
    }

    return (
        <div>
            <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
                <Select
                    className="iso-select"
                    {...selectFieldProps}
                    options={departments}
                    onChange={(v) => handleSelect(v, 'department', 'department.name', v  ? v.value.name : null)}
                    placeholder="Department ..."
                    isClearable
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <Select
                    className="iso-select"
                    {...selectFieldProps}
                    options={criteria}
                    onChange={(v) => handleSelect(v, 'criteria', 'criteria.name', v  ? v.name : null)}
                    placeholder="Criteria ..."
                    isClearable
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <Select
                    className="iso-select"
                    {...selectFieldProps}
                    options={hod}
                    onChange={(v) => handleSelect(v, 'loggedBy', 'loggedBy.email', v  ? v.value.email : null)}
                    placeholder="Logged By ..."
                    isClearable
                />
            </Grid>
        </Grid>
        </div>
    )
}
