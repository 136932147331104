import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  IconButton,
  CardHeader,
  Avatar,
} from "@mui/material";
import SentimentVeryDissatisfied from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfied from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfied from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAlt from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentVerySatisfied from "@mui/icons-material/SentimentVerySatisfied";
import { Info } from "@mui/icons-material";

function SatisfactionSelector({ handleUpdate }) {
  const [selected, setSelected] = useState(null);

  const sentiments = [
    {
      level: 1,
      icon: <SentimentVeryDissatisfied />,
      name: "Very Dissatisfied",
    },
    { level: 2, icon: <SentimentDissatisfied />, name: "Dissatisfied" },
    { level: 3, icon: <SentimentSatisfied />, name: "Satisfied" },
    { level: 4, icon: <SentimentSatisfiedAlt />, name: "More than Satisfied" },
    { level: 5, icon: <SentimentVerySatisfied />, name: "Very Satisfied" },
  ];

  const handleSelect = async (level) => {
    setSelected(level);
    await handleUpdate({
      satisfactionLevel: level,
    });
  };

  return (
    <Card>
      <CardHeader
        title="Satisfaction Rating"
        avatar={
          <Avatar>
            <Info />
          </Avatar>
        }
      />
      <CardContent>
        <Grid container spacing={3}>
          {sentiments.map((sentiment) => (
            <Grid item key={sentiment.level} xs={3} md={2}>
              <Card
                style={
                  selected === sentiment.level
                    ? { backgroundColor: "lightblue" }
                    : { cursor: "pointer" }
                }
                onClick={() => handleSelect(sentiment.level)}
              >
                <CardContent>
                  <IconButton onClick={() => handleSelect(sentiment.level)} size="large">
                    {sentiment.icon}
                  </IconButton>
                  <p>{sentiment.name}</p>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default SatisfactionSelector;
