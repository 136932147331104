import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDb } from "../../../contexts/DatabaseContext";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  TextField,
} from "@mui/material";
import { ArrowForward, Info } from "@mui/icons-material";
import Select from "react-select";
import { selectFieldProps, textFieldProps } from "../../shared/constants";
import MocRisk from "./MocRisk";

export default function Phase2() {
  const { mocId } = useParams();
  const history = useHistory();
  const {
    getCompanyDoc,
    GetSites,
    GetDepartments,
    GetUsersLevelFilter,
    updateDocFromPath,
  } = useDb();
  const [moc, setMoc] = useState();
  const [updates, setUpdates] = useState({});
  const sites = GetSites();
  const departments = GetDepartments();
  const rosters = GetUsersLevelFilter("mocManager", 2);
  const [loading, setLoading] = useState(false);

  const handleSelect = (v, e) => {
    setUpdates({ ...updates, [e.name]: v });
  };

  const handleText = (e) => {
    setUpdates({ ...updates, [e.target.name]: e.target.value });
  };

  const getMoc = async () => {
    const mocDoc = await getCompanyDoc("moc", mocId);
    setMoc(mocDoc);
  };

  useEffect(() => {
    return getMoc();
  }, [mocId]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await updateDocFromPath(moc.path, updates);
      const path = `/moc/phase-2-benefits/${mocId}`;
      history.push(path);
    } catch (err) {
      window.alert(err.message);
    }
    setLoading(false);
  };

  return !moc ? (
    <LinearProgress />
  ) : (
    <div>
      <h1>Phase 2: Request for Change</h1>
      <Card>
        <CardHeader
          title={`1: Summary`}
          subheader={`MOC Title: ${moc.title}`}
          avatar={
            <Avatar>
              <Info />
            </Avatar>
          }
        />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Select
                {...selectFieldProps}
                options={sites}
                isMulti
                placeholder="Affected Sites ..."
                className="iso-select"
                name="sites"
                onChange={handleSelect}
              />
              <Select
                {...selectFieldProps}
                options={departments}
                isMulti
                placeholder="Affected Departments ..."
                className="iso-select"
                name="departments"
                onChange={handleSelect}
              />
              <Select
                {...selectFieldProps}
                options={[
                  { value: "Permanent", label: "Permanent" },
                  { value: "Temporary", label: "Temporary" },
                ]}
                placeholder="Change Permanence ..."
                className="iso-select"
                name="permanence"
                onChange={handleSelect}
              />
              <TextField
                {...textFieldProps}
                label="Description"
                placeholder="Describe the current situation, define the problem/opportunity and describe proposed change/solution"
                multiline
                rows={4}
                name="description"
                onChange={handleText}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                {...selectFieldProps}
                options={rosters}
                placeholder="Requested By ..."
                className="iso-select"
                name="requestedBy"
                onChange={handleSelect}
              />
              <Select
                {...selectFieldProps}
                options={rosters}
                placeholder="Prepared By ..."
                className="iso-select"
                name="preparedBy"
                onChange={handleSelect}
              />
            </Grid>
          </Grid>
          <MocRisk updates={updates} setUpdates={setUpdates} />
        </CardContent>
        <CardActions
          style={{
            float: "right",
          }}
        >
          <Button
            color="primary"
            variant="contained"
            endIcon={<ArrowForward />}
            onClick={handleSubmit}
            disabled={loading}
          >
            Next
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}
