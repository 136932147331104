import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'

export default function RiskManager() {
  const { currentUser } = useAuth()
  
  const riskStepComps = [
    <Redirect to={'/risk-manager/setup/choose-matrix'} />,
    <Redirect to={'/risk-manager/setup/choose-areas'} />,
    <Redirect to={'/risk-manager/setup/add-risk-types'} />,
    <Redirect to={'/risk-manager/setup/add-risk-entities'} />,
    <Redirect to={'/risk-manager/setup/add-risk-exposure-pattern'} />,
    <Redirect to={'/risk-manager/setup/critical-control-threshold'} />,
    <Redirect to={'/risk-manager/setup/heirarchy-of-control'} />
  ]
  let page = riskStepComps[currentUser.riskSetupProgress -1]

  useEffect(() => {

    page = riskStepComps[currentUser.riskSetupProgress -1]

  }, [])

  return (
    currentUser.riskSetupComplete 
    ? 
    <Redirect to={'/risk-manager/dashboard'} />
    :
      currentUser.riskSetupProgress
      ?
        page
      :
      <Redirect to={'/risk-manager/setup/choose-matrix'} />
  )
}
