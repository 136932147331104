import { Button, Card, CardContent, Grid, Typography } from '@mui/material'
import { Phone } from '@mui/icons-material'
import { Alert } from '@mui/material';
import React from 'react'
import { useAuth } from '../../../contexts/AuthContext'
import TrialExpiredUsers from './../TrialExpiredUsers'

export default function SaTrialExpired() {
    const { currentUser } = useAuth()

    if(!currentUser.companyOwner) {
        return <TrialExpiredUsers />
    }
    else return (
        <div>
            <Card>
                <CardContent>
                    {
                        currentUser.trial_expired ?
                        <>
                            <Typography variant='h4'>Your Free Trial has expired</Typography>
                            <br/>
                            <Alert severity="info">
                                It seems you have been using our service for more than 14 days. We hope you enjoyed it. Please contact us to arrange for onboarding:
                            </Alert>
                            <br/>
                        </>
                        :
                        <>
                            <Typography variant='h6'>Ready to start using the service?</Typography>
                        </>
                    }
                    
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardContent>
                                    <Typography>Let's schedule a brief call to go over your needs</Typography>
                                    <br/>
                                    <Button href="https://calendly.com/iso-office/iso-software-introduction" target="_blank" rel="noopener noreferrer" variant='contained' color='primary' fullWidth endIcon={<Phone />}>
                                        Schedule a call
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            
        </div>
    )
}
