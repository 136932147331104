import {
    Paper,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    TableBody,
    Button,
    Card,
    CardContent,
    CardActions,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import { useParams, Link } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import moment from 'moment'
import { AddBox, Search } from '@mui/icons-material'
import FloatingHelpComponent from '../../help/FloatingHelpComponent'

const useStyles = makeStyles({
    ellipsis: {
      maxWidth: 200, // percentage also works
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
});

export default function RevisionHistoryN() {
    const classes = useStyles()
    const { docId, docCode } = useParams()
    const { GetRevisions } = useDb()
    const revisions = GetRevisions(docId)
    
    return (
        <div>
            <h1>Revision History for {docCode}</h1>
            <Card>
                <CardContent>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography className={classes.ellipsis}>Rev. Code</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography className={classes.ellipsis}>Description</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography className={classes.ellipsis}>Rev. Date</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography className={classes.ellipsis}>Target Rev. Date</Typography>
                                    </TableCell>
                                    <TableCell>
                                        
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    revisions && revisions.map(r => (
                                        <TableRow key={r.id}>
                                            <TableCell>
                                                <Typography className={classes.ellipsis}>{r.revCode}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography className={classes.ellipsis}>{r.description}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography className={classes.ellipsis}>{moment(r.revDate.toDate()).format('DD/MM/YYYY')}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography className={classes.ellipsis}>{moment(r.targetRevDate.toDate()).format('DD/MM/YYYY')}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Link to={`/doc-manager/revision/${docId}/${r.id}/${docCode}`}>
                                                    <Button color="primary" variant="contained" startIcon={<Search />}>Details</Button>
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
                <CardActions>
                    <Link to={`/doc-manager/add-revision/${docId}/${docCode}`}>
                        <Button color="primary" variant="contained" startIcon={<AddBox />}>Add Revision</Button>
                    </Link>
                </CardActions>
            </Card>

            <FloatingHelpComponent
                name="Creating Revisions" 
                videoPath="doc_manager"
                videoIndex={6}
            />
        </div>
    )
}
