import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material'
import { Send } from '@mui/icons-material'
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import { textFieldProps } from '../../shared/constants'

export default function ActionShareEmail({ action, attachment, open, closeDialog }) {
    const { shareEmailAttachment } = useDb()
    const [email, setEmail] = useState(null)

    // Action State
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    
    const handleClose = () => {
        setLoading(false)
        setError(null)
        setEmail(null)
        closeDialog()
    }

    const handleChange = e => {
        e.preventDefault()
        setEmail(e.target.value)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setError(null)
        setLoading(true)
        try {
            await shareEmailAttachment({
                email,
                attachment
            }, action.id).then(() => {
                handleClose()
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }
    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        {...textFieldProps}
                        required
                        label="Email Address"
                        onChange={handleChange}
                        type="email"
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={ loading } color="secondary" onClick={handleClose}>Cancel</Button>
                    <Button disabled={ loading } type="submit" color="primary" variant="contained" startIcon={<Send />}>Share</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
