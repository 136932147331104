import { faBalanceScale } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

export default function RiskManagerMenuItem({ classes, currentUser }) {
    const [otpMenuOpen, setOtpMenuOpen] = useState(false)
    return (
        <>
            {
                currentUser.riskSetupComplete
                ? 
                    <>
                        <ListItem button onClick={() => setOtpMenuOpen(!otpMenuOpen)}>
                            <ListItemIcon><FontAwesomeIcon className={classes.icons} icon={faBalanceScale} /></ListItemIcon>
                                <ListItemText className={classes.menuText}>Risk Manager</ListItemText>
                            { otpMenuOpen ? <ExpandLess className={classes.icon} /> : <ExpandMore className={classes.icon} /> }
                        </ListItem>
                        <Collapse in={otpMenuOpen} timeout="auto" unmountOnExit>
                            <List component="div" className={classes.subMenuItem} dense>
                                <ListItem>
                                    <Link to='/risk-manager' className="drawer-item">
                                        <ListItemText className={classes.menuText}>Risk Dashboard</ListItemText>
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link to='/risk-manager/baselines' className="drawer-item">
                                        <ListItemText className={classes.menuText}>Baseline RA's</ListItemText>
                                    </Link>
                                </ListItem>
                                {
                                    currentUser.companyAdmin || currentUser.accessRights.riskManager <= 2
                                    ?
                                    <ListItem>
                                        <Link to='/risk-manager/issue-based' className="drawer-item">
                                            <ListItemText className={classes.menuText}>Issue Based RA's</ListItemText>
                                        </Link>
                                    </ListItem>
                                    :
                                    null
                                }
                                {
                                    currentUser.companyAdmin || currentUser.accessRights.riskManager <= 2
                                    ?
                                    <ListItem>
                                        <Link to='/risk-manager/areas' className="drawer-item">
                                            <ListItemText className={classes.menuText}>Areas</ListItemText>
                                        </Link>
                                    </ListItem>
                                    :
                                    null
                                }
                                {
                                    currentUser.companyAdmin || currentUser.accessRights.riskManager <= 2
                                    ?
                                    <ListItem>
                                        <Link to='/risk-manager/risk-types' className="drawer-item">
                                            <ListItemText className={classes.menuText}>Risk Types</ListItemText>
                                        </Link>
                                    </ListItem>
                                    :
                                    null
                                }
                                {
                                    currentUser.companyAdmin || currentUser.accessRights.riskManager <= 2
                                    ?
                                    <ListItem>
                                        <Link to='/risk-manager/entities' className="drawer-item">
                                            <ListItemText className={classes.menuText}>Entities</ListItemText>
                                        </Link>
                                    </ListItem>
                                    :
                                    null
                                }
                                {
                                    currentUser.companyAdmin || currentUser.accessRights.riskManager <= 2
                                    ?
                                    <ListItem>
                                        <Link to='/risk-manager/patterns' className="drawer-item">
                                            <ListItemText className={classes.menuText}>Patterns</ListItemText>
                                        </Link>
                                    </ListItem>
                                    :
                                    null
                                }
                                {
                                    currentUser.companyAdmin || currentUser.accessRights.riskManager <= 2
                                    ?
                                    <Link to='/risk-manager/processes' className="drawer-item">
                                        <ListItem>
                                                <ListItemText className={classes.menuText}>Processes</ListItemText>
                                        </ListItem>
                                    </Link>
                                    :
                                    null
                                }
                                {
                                    currentUser.companyAdmin || currentUser.accessRights.riskManager <= 2
                                    ?
                                    <Link to='/risk-manager/critical-controls' className="drawer-item">
                                        <ListItem>
                                                <ListItemText className={classes.menuText}>Critical Controls</ListItemText>
                                        </ListItem>
                                    </Link>
                                    :
                                    null
                                }
                                {
                                    currentUser.companyAdmin || currentUser.accessRights.riskManager <= 2
                                    ?
                                    <ListItem>
                                        <Link to='/risk-manager/settings' className="drawer-item">
                                            <ListItemText className={classes.menuText}>Settings</ListItemText>
                                        </Link>
                                    </ListItem>
                                    :
                                    null
                                }
                            </List>
                        </Collapse>
                    </>
                :
                    <>
                        <Link to='/risk-manager' className="drawer-item">
                            <ListItem>
                                <ListItemIcon><FontAwesomeIcon className={classes.icons} icon={faBalanceScale} /></ListItemIcon>
                                <ListItemText className={classes.menuText}>Risk Manager</ListItemText>
                            </ListItem>
                        </Link>
                    </>
            }
        </>
    )
}
