import { ButtonGroup, IconButton } from '@mui/material'
import { Delete, Edit } from '@mui/icons-material'
import React from 'react'
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid'

export default function IsoClassDataGrid({isoClasses, handleEdit, handleDelete}) {

    const rows = isoClasses.map(isoClass => ({
      id: isoClass.id,
      code: isoClass.standard,
      standard: isoClass.code,
      isoClass: isoClass
    }))

    const columns = [
      {
        field: 'code',
        headerName: 'Code',
        width: 200
      },
      {
        field: 'standard',
        headerName: 'Standard',
        width: 200
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 150,
        renderCell: (params) => {
          return (
            <ButtonGroup>              
              <IconButton onClick={() => handleEdit(params.row.isoClass)} size="large"><Edit /></IconButton>
              <IconButton onClick={() => handleDelete(params.row.isoClass)} size="large"><Delete /></IconButton>
            </ButtonGroup>
          );
        }
      }
    ]

  return (
    <div>
      <IsoDataGrid data={rows} columns={columns} />
    </div>
  )
}
