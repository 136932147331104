import React, { useState, useEffect, useRef } from 'react'
import { CircularProgress, Container } from '@mui/material'

export default function IsoDocViewer({ doc }) {
    var extension = doc.match(/\.([^\./\?]+)($|\?)/)[1];

    const morphedUrl = encodeURIComponent(doc)
    const source = "https://docs.google.com/viewer?url=" + morphedUrl + "&embedded=true"
    const [isLoaded, setLoaded] = useState(false)
    const iframeRef = useRef("iframe")

    const handleLoad = (s) => {
        setLoaded(true)
    }

    const handleError = () => {
        console.log('There has been an error')
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if ( ! isLoaded ) {
                iframeRef.current.src = source;
            } 
        }, 3000);
        return () => clearInterval(interval);
    });
    
    return (
        <div style={{ height: '100%'}}>
            <div 
                style=
                {{ 
                    display: isLoaded ? "none" : "block", 
                    position: 'absolute', 
                    left: '50%', 
                    top: '50%',
                    transform: 'translate(-50%, -50%)' 
                }}
            >
                <CircularProgress/>
            </div>
            <iframe
                style={{ display: isLoaded ? "block" : "none", paddingTop: 50 }}
                ref={iframeRef}
                src={source}
                title="file"
                width="100%"
                height="100%"
                onLoad={(s) => handleLoad(s)}
                onError={handleError}
            ></iframe>        
        </div>
    )
}
