import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { textFieldProps } from "../../../shared/constants";
import { Delete, Save } from "@mui/icons-material";
import { useDb } from "../../../../contexts/DatabaseContext";

export default function EditSectionName({ open, close, section }) {
  const { updateDocFromPath, deleteDocFromPath } = useDb();
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const unsubscribe = setTitle(section.name || "");
    console.log(section);
    return unsubscribe;
  }, [open]);

  const handleUpdate = async () => {
    const docPath = section.path;
    setLoading(true);
    try {
      await updateDocFromPath(docPath, {
        name: title,
      });
      close();
    } catch (err) {
      window.alert(err.message);
    }
    setLoading(false);
  };

  const handleDelete = async () => {
    const confirm = window.confirm(
      "Are you sure you want to delete this entire section? This action can not be undone."
    );
    if (confirm) {
      // Do the delete
      try {
        await deleteDocFromPath(section.path);

        close();
      } catch (err) {
        window.alert(err.message);
      }
    }
  };

  return (
    <Dialog open={open} onClose={close} maxWidth="sm" fullWidth>
      <DialogContent>
        <TextField
          {...textFieldProps}
          label="Edit Section Title ..."
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          disabled={loading}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={close}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<Delete />}
          onClick={handleDelete}
          disabled={loading}
        >
          Delete Section
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Save />}
          onClick={handleUpdate}
          disabled={loading}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
