import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material'
import { Save } from '@mui/icons-material'
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import { textFieldProps } from '../../shared/constants'

export default function AddMetric({ open, closeDialog }) {
    const { createOtpMetric } = useDb()

    // Action State
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const handleChange = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }
    
    const handleClose = () => {
        setState({})
        setLoading(false)
        setError(null)
        closeDialog()

    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        setError(null)
        try {
            await createOtpMetric(state).then(() => {
                handleClose()
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(true)
        }
    }

    const [state, setState] = useState({})

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        {...textFieldProps}
                        required
                        label="Metric Name"
                        onChange={handleChange}
                        name="metricName"
                    />
                    <TextField
                        {...textFieldProps}
                        required
                        label="Metric Unit"
                        onChange={handleChange}
                        name="metricUnit"
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={handleClose}>Cancel</Button>
                    <Button color="primary" type="submit" variant="contained" startIcon={<Save />}>Submit</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
