import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material'
import { Save } from '@mui/icons-material'
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import { textFieldProps } from '../../shared/constants'

export default function EditCriteria({ criteria, open, closeDialog }) {
    const { updateCriteria } = useDb()

    const [state, setState] = useState({
        name: criteria.name
    })

    // Action State
    const [updated, setUpdated] = useState(false)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleClose = () => {
        setError(null)
        setLoading(false)
        setUpdated(false)
        closeDialog()
    }

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
        setUpdated(true)
    }

    const handleSubmit = async () => {
        setError(null)
        setLoading(true)
        try {
            await updateCriteria(criteria.id, state).then(() => {
                handleClose()
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogContent>
                <TextField
                    {...textFieldProps}
                    label="Criteria Name"
                    name="name"
                    value={state.name}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button disabled={ loading } color="secondary" onClick={handleClose}>Cancel</Button>
                <Button disabled={ loading || !updated } color="primary" onClick={handleSubmit} variant="contained" startIcon={<Save />}>Submit</Button>
            </DialogActions>
            
        </Dialog>
    )
}
