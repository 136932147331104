import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useAuth } from "../../../contexts/AuthContext";
import { useHistory } from "react-router-dom";

const MocReviewApprove = ({ moc, props }) => {
  console.log(moc);
  const { handleUpdate, handleAdvance } = props;
  const history = useHistory();
  const { currentUser } = useAuth();
  const loggedInUserId = currentUser.uid; // Set this to the correct user ID

  const handleConfirmClick = async () => {
    // If reviewers exist in the moc object and it's an array
    if (moc?.reviewers && Array.isArray(moc.reviewers)) {
      // Search for the reviewer with the same participant ID as the logged-in user
      const reviewer = moc.reviewers.find(
        (r) => r.participant === loggedInUserId
      );
      if (reviewer) {
        // Set the approved status to true
        reviewer.approved = true;
        console.log(
          `Reviewer ${loggedInUserId} approval status updated to ${reviewer.approved}`
        );
      }
      await handleUpdate({
        reviewers: moc.reviewers,
      });
      // Check if all reviewers have approved
      if (moc.reviewers.every((r) => r.approved)) {
        console.log("All reviewers have approved");
        // Perform an action when all reviewers have approved
        handleAdvance();
      } else {
        console.log("Not all reviewers have approved yet");
        history.push("/moc-register");
      }
    }
  };

  const handleGoBackClick = async () => {
    await handleUpdate({
      currentStepIndex: moc.currentStepIndex - 1,
    });
    history.push("/moc-register");
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <h1>Confirm review complete</h1>
      <Button variant="contained" color="primary" onClick={handleConfirmClick}>
        Confirm
      </Button>
      <Button startIcon={<ArrowBackIcon />} onClick={handleGoBackClick}>
        Go Back
      </Button>
    </Box>
  );
};

export default MocReviewApprove;
