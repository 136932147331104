import { Avatar, Button, Card, CardActions, CardContent, CardHeader, Chip, Grid, IconButton, LinearProgress, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@mui/material'
import { ArrowDownward, ArrowLeftOutlined, ArrowRightOutlined, AttachFile, Delete, Email, People, Person } from '@mui/icons-material'
import { Pagination } from '@mui/material';
import React, { useState } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGavel, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { useDb } from '../../../../contexts/DatabaseContext'
import { selectFieldProps } from '../../../shared/constants'
import FloatingHelpComponent from '../../../help/FloatingHelpComponent'

export default function DocIntPartiesNew() {
    const { docId } = useParams()
    const { 
        GetDocIntPartiesNew, 
        GetDynamicIntPartyGroups, 
        removeDynamicIntParty, 
        removeDynamicIntGroup, 
        GetRosters, 
        GetGroups, 
        addDynamicIntParty, 
        addDynamicIntGroup,
        emailDynamicIndividualParty,
        emailDynamicGroup,
        emailAllPartiesDynamic,
        getDocument
    } = useDb()
    const history = useHistory()
    const parties = GetDocIntPartiesNew(docId)
    const partyGroups = GetDynamicIntPartyGroups('documents', docId)
    const rosters = GetRosters()
    const groups = GetGroups()

    // Action State
    const [loaded, setLoaded] = useState(false)
    const [intParty, setIntParty] = useState(null)
    const [group, setGroup] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [doc, setDoc] = useState(null)

    // Pagination
    const itemsPerPage = 10; // <- Not state but related to below pagination
    const [page, setPage] = useState(1);
    const [noOfPages] = useState(
        Math.ceil(parties.length / itemsPerPage)
    );

    const handleAdd = async () => {
        setLoading(true)
        try {
            await addDynamicIntParty('documents', docId, intParty).then(() => {
                setIntParty(null)
                setLoading(false)
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    const handleGroupAdd = async () => {
        setLoading(true)
        setError(null)
        try {
            await addDynamicIntGroup('documents', docId, group).then(() => {
                setGroup(null)
                setLoading(false)
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    const handleRemove = async (pty) => {
        setLoading(true)
        try {
            await removeDynamicIntParty('documents', docId, pty).then(() => {
                setIntParty(null)
                setLoading(false)
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    const handleGroupRemove = async (grp) => {
        setLoading(true)
        setError(null)
        try {
            await removeDynamicIntGroup('documents', docId, grp).then(() => {
                setGroup(null)
                setLoading(false)
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    const handleSingleSend = async (pty) => {
        setLoading(true)
        setError(null)
        try {
            await emailDynamicIndividualParty('documents', doc, pty, false).then(() => {
                setLoading(false)
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    const handleGroupSend = async (grp) => {
        setLoading(true)
        setError(null)
        try {
            await emailDynamicGroup('documents', doc, grp.id).then(() => {
                setLoading(false)
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    const handleAllSend = async () => {
        setLoading(true)
        setError(null)
        try {
            await emailAllPartiesDynamic('documents', doc).then(() => {
                setLoading(false)
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    const firstLoad = async () => {
        const d = await getDocument(docId)
        setDoc(d)
        setLoaded(true)
    }

    if(!loaded) {
        firstLoad()
        return <LinearProgress />
    }
    else return doc.pendingApproval > 0
    ?
    <div>
        <h1>Interested Parties for Document: {doc.docCode}</h1>
        <Link to={`/doc-manager/doc-detail/${docId}`}>
            <Button disabled={ loading } color="primary" variant="contained" startIcon={<ArrowLeftOutlined />}>{doc.docCode}</Button>
        </Link>
        <br/><br/>
        <Typography>Interested Parties cannot be added until all Approvers have approved this document.</Typography>
    </div>
    :
    <div>
        <h1>Interested Parties for Document: {doc.docCode}</h1>
        <Link to={`/doc-manager/doc-detail/${docId}`}>
            <Button disabled={ loading } color="primary" variant="contained" startIcon={<ArrowLeftOutlined />}>{doc.docCode}</Button>
        </Link>&nbsp;
        <Button disabled={ loading } onClick={handleAllSend} color="primary" variant="contained" startIcon={<Email />}>Email All</Button>
        
        <br/><br/>
        <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
                <Card>
                    <CardHeader
                        title="Individuals"
                        avatar={
                            <Avatar>
                                <Person />
                            </Avatar>
                        }
                    />
                    <CardContent>
                        <Select
                            options={rosters}
                            placeholder={'Add Interested Party ...'}
                            {...selectFieldProps}
                            onChange={(val) => setIntParty(val.value)}
                        />
                        { 
                            intParty 
                            ?
                            <div>
                                <br/>
                                <Button disabled={loading} onClick={handleAdd} endIcon={<ArrowDownward />} color={'primary'} variant={'contained'}>Add Party</Button>
                                <br/>
                            </div>
                            :
                            null
                        }
                        <br/>
                        <List>
                            {
                                parties && parties
                                .slice((page -1) * itemsPerPage, page * itemsPerPage)
                                .map((pty) => {
                                    let ackStatus = '';
                                    if(pty.status && pty.status === 'Acknowledged') {
                                        ackStatus = 'Acknowledged'
                                    }
                                    else {
                                        ackStatus = pty.sent ? 'Email Sent' : 'Pending Email'
                                    }
                                    return (
                                        <ListItem key={pty.id} >
                                            <ListItemText>
                                                <span className="iso-small">
                                                    {`${pty.firstName} ${pty.lastName}`} 
                                                    
                                                    &nbsp;<Chip size="small" label={ackStatus}/> &nbsp;
                                                    
                                                </span>
                                            </ListItemText>
                                            <ListItemSecondaryAction>
                                                <IconButton onClick={() => handleRemove(pty)} size="large"><Delete /></IconButton>
                                                <IconButton onClick={() => handleSingleSend(pty)} size="large"><FontAwesomeIcon icon={faPaperPlane}/></IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    );
                                })
                            }
                        </List>
                        { error ? <span className="iso-error">{error}</span> : null }
                    </CardContent>
                    <CardActions>
                        <Pagination
                            count={Math.ceil(parties.length / itemsPerPage)}
                            page={page}
                            onChange={(e, val) => setPage(val)}
                            defaultPage={1}
                            color="primary"
                            size="large"
                            showFirstButton
                            showLastButton
                        /> 
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
                <Card>
                    <CardHeader
                        title="Groups"
                        avatar={
                            <Avatar>
                                <People />
                            </Avatar>
                        }
                    />
                    <CardContent>
                        <Select
                            options={groups}
                            placeholder={'Add Groups ...'}
                            {...selectFieldProps}
                            onChange={(val) => setGroup(val)}
                        />
                        {
                            group
                            ?
                            <div>
                                <br/>
                                <Button disabled={loading} onClick={handleGroupAdd} endIcon={<ArrowDownward />} color={'primary'} variant={'contained'}>Add Group</Button>
                                <br/>
                            </div>
                            :
                            null
                        }
                        <br/>
                        {
                            partyGroups ?
                            <CardContent>
                                <List>
                                    {
                                        partyGroups && partyGroups.map((g) => {
                                            return (
                                                <ListItem key={g.id}>
                                                    <ListItemText><span className="iso-small">{`${g.label}`} </span></ListItemText>
                                                    <ListItemSecondaryAction>
                                                        <IconButton onClick={() => handleGroupRemove(g)} size="large"><Delete /></IconButton>
                                                        <IconButton onClick={() => handleGroupSend(g)} size="large"><FontAwesomeIcon icon={faPaperPlane}/></IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            );
                                        })
                                    }
                                </List>
                                
                            </CardContent>
                            : null
                        }
                    </CardContent>
                </Card>
            </Grid>
        </Grid>

        <FloatingHelpComponent
            name="Document Communication" 
            videoPath="doc_manager"
            videoIndex={1}
        />
    </div>;
}
