import { Avatar, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Grid, IconButton, List } from '@mui/material'
import { AddBox, ArrowBack, PictureAsPdf, Warning } from '@mui/icons-material'
import React from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import { useAuth } from '../../../contexts/AuthContext'
import { useDb } from '../../../contexts/DatabaseContext'
import BlraDetailListItem from '../baseline/blra-detail/BlraDetailListItem'


export default function IbraDetail() {
    const history = useHistory()
    const { ibraId, refNo } = useParams()
    const { GetHazardsForIB, GetIbraRisks, GetIbraSigns, getIbraPdf } = useDb()
    const { currentUser } = useAuth()
    const hazards = GetHazardsForIB(ibraId)
    const risks = GetIbraRisks(ibraId)
    const signs = GetIbraSigns(ibraId)

    const [loading, setLoading] = React.useState(false)

    const getPdf = async () => {
        setLoading(true)
        await getIbraPdf(ibraId, hazards, risks, signs)
        setLoading(false)
    }

    return (
        <div>
            <h1>{refNo}</h1>
            <div className="back-link">
                <CardActions>
                    <Button
                        color="primary"
                        variant="contained"
                        startIcon={<ArrowBack />}
                        onClick={() => history.goBack()}
                    >
                        Back
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        startIcon={<PictureAsPdf />}
                        onClick={getPdf}
                        disabled={loading}
                    >
                        Download Report
                    </Button>
                    {
                        loading && <CircularProgress />
                    }
                </CardActions>
            </div>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardHeader
                            title="Hazards"
                            avatar={
                                <Avatar>
                                    <Warning />
                                </Avatar>
                            }
                        />
                        <CardContent>
                            <List>
                                {
                                    hazards && hazards.map(h => (
                                        <BlraDetailListItem
                                            key={h.id}
                                            title={h.name}
                                            link={`/risk-manager/issue-based/${ibraId}/hazards/${h.id}/risks`}
                                        />
                                    ))
                                }
                            </List>
                        </CardContent>
                        <CardActions>
                            {
                                currentUser.accessRights.riskManager > 2 || currentUser.companyAdmin
                                &&
                                <Link
                                    to={`/risk-manager/issue-based/${ibraId}/2`}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<AddBox />}
                                        size="small"
                                    >
                                        Add
                                    </Button>
                                </Link>
                            }
                            {
                                currentUser.accessRights.riskManager > 2 || currentUser.companyAdmin
                                &&
                                <Link
                                    to={`/risk-manager/issue-based/${ibraId}/3`}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<AddBox />}
                                        size="small"
                                    >
                                        All Hazards
                                    </Button>
                                </Link>
                            }
                        </CardActions>
                    </Card>
                </Grid> 
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardHeader
                            title="Risks"
                            avatar={
                                <Avatar>
                                    <Warning />
                                </Avatar>
                            }
                        />
                        <CardContent>
                            <List>
                                {
                                    risks && risks.map(r => (
                                        <BlraDetailListItem
                                            key={r.id}
                                            title={`${r.ref} - ${r.description}`}
                                            link={`/risk-manager/ibra-risk-detail/${ibraId}/${r.id}`}
                                        />
                                    ))
                                }
                            </List>
                        </CardContent>
                    </Card>
                </Grid>     
                    
                <Grid item xs={12} md={12}>
                    <Card>
                        <CardHeader
                            title="PPE Signage"
                            avatar={
                                <Avatar>
                                    <Warning />
                                </Avatar>
                            }
                        />
                        <CardContent>
                            <Grid container spacing={3}>
                                {
                                    signs && signs.map(sign => (
                                        <Grid item xs={4} md={1} key={sign.id}>
                                            <img src={sign.svg ? sign.svg : sign.png } style={{ width: '100%' }} />
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>     
            </Grid>
        </div>
    )
}
