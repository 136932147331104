import { faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, CardActions, CardContent, CardHeader, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { Pagination } from '@mui/material';
import React, { useState } from 'react'
import { useDb } from '../../../../contexts/DatabaseContext'
import ConfirmUserDelete from '../../../master_list/users/ConfirmUserDelete'
import AddUser from '../../users/AddUser'
import CoChangeUserPw from './CoChangeUserPw'
import CoUserTile from './CoUserTile'

export default function CoUserList({ coId }) {
    const { GetUsersForCompany } = useDb()
    const users = GetUsersForCompany(coId)
    const [addOpen, setAddOpen] = useState(false)
    const [changeOpen, setChangeOpen] = useState(false)
    const [selectedUid, setSelectedUid] = useState(null)

    // Pagination
    const itemsPerPage = 10; // <- Not state but related to below pagination
    const [page, setPage] = useState(1);
    const [noOfPages] = useState(
        Math.ceil(users.length / itemsPerPage)
    );

     // Deleting Users
     const [selectedUser, setSelectedUser] = useState(null)
     const [deleteOpen, setDeleteOpen] = useState(false)
     const handleDelete = (u) => {
         setSelectedUser(u)
         setDeleteOpen(true)
     }
     const deleteComplete = () => {
         // TODO: DO a snackbar
     }

     const handlePwChangeOpen = (uid) => {
        console.log(uid)
        setSelectedUid(uid)
        setChangeOpen(true)
     }

     const handlePwChangeClose = () => {
        setChangeOpen(false)
        setSelectedUid(null)
     }

    return (
        <>
            <Card>
                <CardHeader
                    title="Users"
                />
                <CardContent>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Email</TableCell>
                                    <TableCell>First Name</TableCell>
                                    <TableCell>Last Name</TableCell>
                                    <TableCell>Admin</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    users && users
                                    .slice((page -1) * itemsPerPage, page * itemsPerPage)
                                    .map(u => {
                                        return <CoUserTile u={u} key={u.id} remove={() => handleDelete(u)} change={() => handlePwChangeOpen(u.id)}/>
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
                <CardActions>
                    <Pagination
                        count={Math.ceil(users.length / itemsPerPage)}
                        page={page}
                        onChange={(e, val) => setPage(val)}
                        defaultPage={1}
                        color="primary"
                        size="large"
                        showFirstButton
                        showLastButton
                    /> 
                    <Button onClick={() => setAddOpen(true)} variant="contained" color="primary" startIcon={<FontAwesomeIcon icon={faUserPlus}/>}>Add User</Button>
                </CardActions>
            </Card>

            <AddUser open={addOpen} closeDialog={() => setAddOpen(false)} coId={coId}/>
            {
                selectedUser
                ?
                <ConfirmUserDelete open={deleteOpen} closeDialog={() => setDeleteOpen(false)} user={selectedUser} deleteComplete={deleteComplete} fromManage={true}/>
                :
                null
            }
            {
                selectedUid && <CoChangeUserPw 
                    open={changeOpen}
                    userId={selectedUid}
                    close={handlePwChangeClose}
                />
            }
        </>
    )
}
