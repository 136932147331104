import {
  faBuilding,
  faCalendar,
  faDatabase,
  faFile,
  faFileAlt,
  faGlobeAfrica,
  faPencilAlt,
  faSearch,
  faTrashAlt,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Button,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { useDb } from "../../../contexts/DatabaseContext";
import EditTextField from "./edits/EditTextField";
import EditSelect from "./edits/EditSelect";
import EditDocGroups from "./EditDocGroups";

const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: theme.palette.dialogBg,
  },
}));

export default function EditDocument({ doc, closeDialog }) {
  const classes = useStyles();

  const { editTargetRevDate } = useDb();
  const [open, setOpen] = useState(false);
  const [comp, setComp] = useState(null);
  const [revDate, setRevDate] = useState(doc.targetRevDate.toDate());

  const openDialog = (c) => {
    setComp(c);
    setOpen(true);
  };

  const closeEditor = () => {
    setOpen(false);
    setComp(null);
    closeDialog();
  };

  const editRevDate = async (newDate) => {
    const result = await editTargetRevDate(newDate, doc.id);
    if (result !== null) {
      closeDialog();
    } else {
      console.log("Error Saving to Database");
    }
  };

  return (
    <div className={classes.dialog}>
      <CardHeader
        avatar={
          <Avatar>
            <FontAwesomeIcon icon={faFile} />
          </Avatar>
        }
        title={`${doc.docCode} - ${doc.docName}`}
        subheader={"Document Name"}
        action={
          <IconButton
            onClick={() =>
              openDialog(
                <EditTextField
                  oldValue={doc.docName}
                  doc={doc}
                  field={"docName"}
                  closeEditor={closeEditor}
                />
              )
            }
            size="large"
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </IconButton>
        }
      />
      <CardHeader
        avatar={
          <Avatar>
            <FontAwesomeIcon icon={faFile} />
          </Avatar>
        }
        title={`${doc.customId}`}
        subheader={"Custom Doc ID"}
        action={
          <IconButton
            onClick={() =>
              openDialog(
                <EditTextField
                  oldValue={doc.customId}
                  doc={doc}
                  field={"customId"}
                  closeEditor={closeEditor}
                />
              )
            }
            size="large"
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </IconButton>
        }
      />
      <CardHeader
        avatar={
          <Avatar>
            <FontAwesomeIcon icon={faCalendar} />
          </Avatar>
        }
        title={
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Target Revision Date"
              onChange={(d) => editRevDate(d.toDate())}
              defaultValue={dayjs(revDate)}
            />
          </LocalizationProvider>
        }
      />

      <CardHeader
        avatar={
          <Avatar>
            <FontAwesomeIcon icon={faBuilding} />
          </Avatar>
        }
        title={doc.department.label}
        subheader={"Department"}
        action={
          <IconButton
            onClick={() =>
              openDialog(
                <EditSelect
                  type={"department"}
                  doc={doc}
                  closeEditor={closeEditor}
                />
              )
            }
            size="large"
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </IconButton>
        }
      />
      <CardHeader
        avatar={
          <Avatar>
            <FontAwesomeIcon icon={faGlobeAfrica} />
          </Avatar>
        }
        title={doc.site.label}
        subheader={"Site"}
        action={
          <IconButton
            onClick={() =>
              openDialog(
                <EditSelect type={"site"} doc={doc} closeEditor={closeEditor} />
              )
            }
            size="large"
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </IconButton>
        }
      />
      <CardHeader
        avatar={
          <Avatar>
            <FontAwesomeIcon icon={faFileAlt} />
          </Avatar>
        }
        title={doc.docType.label}
        subheader={"Document Type"}
        action={
          <IconButton
            onClick={() =>
              openDialog(
                <EditSelect
                  type={"docType"}
                  doc={doc}
                  closeEditor={closeEditor}
                />
              )
            }
            size="large"
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </IconButton>
        }
      />
      <CardHeader
        avatar={
          <Avatar>
            <FontAwesomeIcon icon={faUser} />
          </Avatar>
        }
        title={doc.owner.label}
        subheader={"Document Owner"}
        action={
          <IconButton
            onClick={() =>
              openDialog(
                <EditSelect
                  type={"owner"}
                  doc={doc}
                  closeEditor={closeEditor}
                />
              )
            }
            size="large"
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </IconButton>
        }
      />
      <CardHeader
        avatar={
          <Avatar>
            <FontAwesomeIcon icon={faDatabase} />
          </Avatar>
        }
        title={`${doc.retentionPeriod.label}`}
        subheader={"Retention Period"}
        action={
          <IconButton
            onClick={() =>
              openDialog(
                <EditSelect
                  type={"retentionPeriod"}
                  doc={doc}
                  closeEditor={closeEditor}
                />
              )
            }
            size="large"
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </IconButton>
        }
      />
      <CardHeader
        avatar={
          <Avatar>
            <FontAwesomeIcon icon={faTrashAlt} />
          </Avatar>
        }
        title={doc.dispositionMethod.label}
        subheader={"Disposition Method"}
        action={
          <IconButton
            onClick={() =>
              openDialog(
                <EditSelect
                  type={"dispositionMethod"}
                  doc={doc}
                  closeEditor={closeEditor}
                />
              )
            }
            size="large"
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </IconButton>
        }
      />
      <CardHeader
        avatar={
          <Avatar>
            <FontAwesomeIcon icon={faUsers} />
          </Avatar>
        }
        title={"Groups"}
        action={
          <IconButton
            onClick={() => openDialog(<EditDocGroups doc={doc} />)}
            size="large"
          >
            <FontAwesomeIcon icon={faSearch} />
          </IconButton>
        }
      />

      <Dialog open={open} maxWidth="sm" fullWidth onClose={closeEditor}>
        <DialogContent>{comp}</DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
