import { Button, Dialog, DialogActions, DialogContent, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Menu, IconButton, LinearProgress, Card, CardActions, CardContent } from '@mui/material'
import React, { useState } from 'react'
import { useDb } from '../../contexts/DatabaseContext'
import { Pagination } from '@mui/material';
import ViewPrograms from './programs/ViewPrograms'
import EditTarget from './targets/EditTarget'
import { AddBox, ArrowLeftOutlined, Edit } from '@mui/icons-material'
import AddTarget from './AddTarget'
import { useParams, useHistory, Link } from 'react-router-dom'

export default function ViewTargets() {
    const { otpId } = useParams()
    const { GetOTPTargets, getSingleOtp } = useDb()
    const targets = GetOTPTargets(false, otpId)
    const history = useHistory()

    // Component State
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [selectedTarget, setSelectedTarget] = useState(null)
    const [programsOpen, setProgramsOpen] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [otp, setOtp] = useState(null)
    
    // Form State
    const [otpid, setOtpId] = useState(otpId)

    // Pagination
    const itemsPerPage = 10; // <- Not state but related to below pagination
    const [page, setPage] = useState(1);
    const [noOfPages] = useState(
        Math.ceil(targets.length / itemsPerPage)
    );
 
    const openPrograms = (t) => {
        setSelectedTarget(t)
        setProgramsOpen(true)
    }

    const closePrograms = () => {
        setProgramsOpen(false)
        setSelectedTarget(null)
    }

    // Edits 
    const [editOpen, setEditOpen] = useState(false)
    const handleEdit = (t) => {
        setSelectedTarget(t)
        setEditOpen(true)
    }
    const closeEdit = () => {
        setSelectedTarget(null)
        setEditOpen(true)
    }
    const [addOpen, setAddOpen] = useState(false)

    const firstLoad = async () => {
        const o = await getSingleOtp(otpId)
        setOtp(o)
        setLoaded(true)
    }
    
    if(!loaded) {
        firstLoad()
        return <LinearProgress />
    }
    else return <>
        <Card>
            <CardContent>
                    <Typography variant="h5">Targets for Objective # { otp.otpNumber }</Typography>
                    <br/>
                    <Link to={'/otp-manager/register'}>
                        <Button variant="contained" color="primary" startIcon={<ArrowLeftOutlined />}>OTP Register</Button>
                    </Link>
                    <br/><br/>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell><strong>Target #</strong></TableCell>
                                    <TableCell><strong>Description</strong></TableCell>
                                    <TableCell><strong>Programs</strong></TableCell>
                                    <TableCell><strong>Progress</strong></TableCell>
                                    <TableCell align="right"><strong></strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {targets
                            .slice((page -1) * itemsPerPage, page * itemsPerPage)
                            .map((doc) => {
                                console.log(doc)
                                return (
                                    <TableRow key={doc.id}>
                                        <TableCell>{doc.targetNo}</TableCell>
                                        <TableCell component="th" scope="row">
                                            {doc.targetDescription}
                                        </TableCell>
                                        <TableCell>
                                            <Link to={`/target/${otpId}/${doc.id}/programs`}>
                                                <Button size="small" variant="contained" color="primary">View Programs</Button>
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                            {
                                                doc.closedCount && doc.closedCount > 0
                                                ?
                                                <Button size="small" variant="outlined" style={{ color: '#72a55e'}}>{`${((doc.closedCount / doc.programCount) * 100).toFixed(2)}%`}</Button>
                                                :
                                                <Button size="small" variant="outlined" color="secondary">0%</Button>
                                            }
                                            
                                        </TableCell>
                                        <TableCell align="right">
                                            <IconButton onClick={() => handleEdit(doc)} size="large">
                                                <Edit />
                                            </IconButton>       
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            </TableBody>
                        </Table>
                    </TableContainer>      
                { error ? <span className={'iso-error'}>{error}</span> : null }
            </CardContent>
            <CardActions>
                <Button onClick={() => setAddOpen(true)} variant="contained" color="primary" startIcon={<AddBox />}>Add Target</Button>
                <Pagination
                    count={Math.ceil(targets.length / itemsPerPage)}
                    page={page}
                    onChange={(e, val) => setPage(val)}
                    defaultPage={1}
                    color="primary"
                    size="large"
                    showFirstButton
                    showLastButton
                /> 
            </CardActions>
            {
                selectedTarget
                ?
                <EditTarget target={selectedTarget} open={editOpen} closeDialog={closeEdit} />
                :
                null
            }
            <AddTarget open={addOpen} closeDialog={() => setAddOpen(false)} otp={otp} />
        </Card>
    </>;
}
