import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import BackLink from "../../shared/BackLink";
import Select from "react-select";
import { useDb } from "../../../contexts/DatabaseContext";
import { selectFieldProps, textFieldProps } from "../../shared/constants";
import { Button, Container, LinearProgress, TextField } from "@mui/material";
import { Save } from "@mui/icons-material";

export default function OtpEditNew() {
  const { otpId } = useParams();
  const history = useHistory();
  const [otp, setOtp] = useState();
  const { getSingleOtp, GetSites, GetDepartments, GetCriteria, updateOTP } =
    useDb();
  const sites = GetSites();
  const departments = GetDepartments();
  const criteria = GetCriteria();

  const loadOtp = async () => {
    const o = await getSingleOtp(otpId);
    setState({
      ...o,
    });
    setOtp(o);
  };

  useEffect(() => {
    const unsubscribe = loadOtp();

    return unsubscribe;
  }, [otpId]);

  const [state, setState] = useState();
  const [loading, setLoading] = useState(false);

  const handleUpdate = async () => {
    setLoading(true);
    try {
      await updateOTP(otpId, state);
      history.goBack();
    } catch (err) {
      setLoading(false);
      window.alert(err.message);
    }
  };

  if (!otp) return <LinearProgress />;
  else
    return (
      <div>
        <h1>Edit OTP: {otp.otpNumber}</h1>
        <Container>
          <BackLink />
          <div>
            Current Department: {otp.department && otp.department.name}
            <Select
              options={departments}
              {...selectFieldProps}
              className="iso-select"
              placeholder="Change Department"
              onChange={(val) =>
                setState({
                  ...state,
                  department: val.value,
                })
              }
            />
            Current Criteria: {otp.criteria && otp.criteria.name}
            <Select
              options={criteria}
              {...selectFieldProps}
              className="iso-select"
              placeholder="Change Criteria"
              onChange={(val) =>
                setState({
                  ...state,
                  criteria: val.value,
                })
              }
            />
            Current Site: {otp.site && otp.site.name}
            <Select
              options={sites}
              {...selectFieldProps}
              className="iso-select"
              placeholder="Change Site"
              onChange={(val) =>
                setState({
                  ...state,
                  site: val.value,
                })
              }
            />
            <TextField
              {...textFieldProps}
              label="Defined Objectives"
              value={state?.defineObjectives || ""}
              onChange={(e) =>
                setState({
                  ...state,
                  defineObjectives: e.target.value,
                })
              }
              multiline
              rows={3}
            />
          </div>
          {state && (
            <Button
              size="large"
              fullWidth
              variant="contained"
              startIcon={<Save />}
              color="primary"
              disabled={loading}
              onClick={handleUpdate}
            >
              Save Changes
            </Button>
          )}
        </Container>
      </div>
    );
}
