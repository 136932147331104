import { Button } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import React from 'react'
import { useHistory } from 'react-router-dom'
// Algolia Imports
import algoliasearch from 'algoliasearch/lite';
import {
    InstantSearch,
    Hits,
    SearchBox,
    Configure
} from 'react-instantsearch-dom';
import DocSearchResult from './DocSearchResult';

function Hit(props) {
    const doc = {
        ...props.hit,
        createdDate: props.hit.createdDate
    }
    return (
        <DocSearchResult doc={doc} />
    );
}

const searchClient = algoliasearch(
    // TODO: Get these back in
);

export default function DocSearch() {
    const history = useHistory()

    return (
        <div>
            <h1>Document Search</h1>
            <div className="back-link">
                <Button onClick={() => history.goBack()} color="primary" variant="contained" startIcon={<ArrowBack />}>Doc Register</Button>
            </div>
                <InstantSearch indexName="documents" searchClient={searchClient}>
                    <Configure hitsPerPage={6} />
                    <div className="right-panel">
                        <SearchBox />
                        <br/>
                        <Hits hitComponent={Hit} />
                    </div>
                </InstantSearch>
        </div>
    )
}

