import { Button, Dialog, DialogActions, DialogContent, Grid, TextField, Typography } from '@mui/material'
import { Save } from '@mui/icons-material'
import React, { useState } from 'react'
import { useDb } from '../../../../contexts/DatabaseContext'
import { selectFieldProps, textFieldProps } from '../../../shared/constants'

export default function AddRiskEntities({open, closeDialog}) {

  const { addRiskEntities } = useDb()
  
  const [state, setState] = useState({
    error: null,
    loading: false,
    data: {}
  })

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      setState({
        ...state,
        loading: true,
        error: null
      })
      await addRiskEntities(state.data)
      setState({
        ...state,
        loading: false
      })
      closeDialog()
    } catch (err) {
      setState({
        ...state,
        loading: false,
        error: err
      })
    }
  }

  return (
    <div>
      <Dialog open={open} maxWidth="sm" fullWidth onClose={closeDialog} >
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Typography>Add Risk Entity</Typography>
              <br/>
              <TextField 
                  className={'iso-text-field'} 
                  {...textFieldProps} 
                  label="Entity Name"
                  required 
                  onChange={(e) => setState({
                    ...state,
                    data: {
                      riskEntity: e.target.value,
                    }
                  })}
              />
                { state.error ? <span className={'iso-error'}>{state.error}</span> : null }
            </DialogContent>
            <DialogActions>
              <Button disabled={state.loading} onClick={closeDialog} color="secondary">Cancel</Button>
              <Button disabled={state.loading} onClick={handleSubmit} type="submit" variant="contained" color="primary" startIcon={<Save />}>Submit</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  )
}
