import React from "react";
import IsoTextField from "./IsoTextField";
import IsoSlider from "./IsoSlider";
import { CheckBox, RadioButtonChecked } from "@mui/icons-material";
import IsoRadio from "./IsoRadio";
import IsoCheckbox from "./IsoCheckbox";

export const fieldTypes = {
  text: (name, props) => <IsoTextField name={name} {...props} />,
  slider: (name, props) => <IsoSlider name={name} {...props} />,
  radio: (name, props) => <IsoRadio name={name} {...props} />,
  checkbox: (name, props) => <IsoCheckbox name={name} {...props} />,
};

export const componentList = [
  {
    name: "Radio",
    type: "radio",
    icon: <RadioButtonChecked />,
    props: { type: "radio" },
  },
  {
    name: "Checkbox",
    type: "checkbox",
    icon: <CheckBox />,
    props: { type: "checkbox" },
  },
];
