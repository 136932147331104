import { ButtonGroup, IconButton } from '@mui/material'
import { Delete, Edit } from '@mui/icons-material'
import React from 'react'
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid'

export default function DepartmentDataGrid({data, handleEdit, handleDelete}) {

  const rows = data.map(d => ({
    id: d.id,
    name: d.name,
    code: d.code ? `(${d.code})` : ' ',
    dept: d
  }))

  const colunms = [
    {
      field: 'name',
      headerName: 'Name',
      width: 300
    },
    {
      field: 'code', 
      headerName: 'Code',
      width: 200
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => {
        return (
          <ButtonGroup>
            <IconButton onClick={() => handleEdit(params.row.dept)} size="large"><Edit /></IconButton>
            <IconButton onClick={() => handleDelete(params.row.dept)} size="large"><Delete /></IconButton>
          </ButtonGroup>
        );
      }
    }
  ]

  return (
    <div>
      <IsoDataGrid
        data={rows}
        columns={colunms}
      />
    </div>
  )
}
