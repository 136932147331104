import { jsPDF } from "jspdf";
import logo from '../../../img/ISO.png'
import 'jspdf-autotable'
import moment from 'moment'

export async function documentsSubmittedPdf(companyRef, doc) {
    const folders = await getSubfolders(companyRef, doc.id)
    
    if(!folders || folders.length === 0) {
        console.log('No Folders Run')
        return await noFoldersPdf(companyRef, doc)
    }

    const data = await getDocumentsSubmittedData(companyRef, doc.id)
    if(!data) {
        throw({
            message: 'Error: No documents to retrieve.'
        })
    }

    async function getCompanyLogo() {
        return await companyRef.get()
        .then(doc => {
            if(doc.data().logo) return doc.data().logo
            else return logo
        })
    }

    const pdf = new jsPDF()
    var img = new Image;
    img.src = await getCompanyLogo()
        
    pdf.addImage(img, 'PNG', 80, 10, pdf.canvas.width * 0.3, pdf.canvas.height * 0.15)
    pdf.setFontSize(14)
    pdf.text(`Documents Submitted To: ${doc.docCode} ${doc.docName}`, 13, 60)

    const foldersObj = await buildBigFolderArray(folders, data)
    
    folders.map(async (f, index) => {
        
        const createUrl = (uri) => {
            const eUrl = encodeURIComponent(uri)
            const eDouble = encodeURIComponent(eUrl)
            return eDouble
        }
        
        await pdf.autoTable({
            head: [foldersObj.head[index]],
            body: foldersObj.body[index], 
            didParseCell: function(hookData) {
                if(hookData.column.dataKey === 4) {
                    hookData.cell.text = ''
                }
            },
            didDrawCell: function(hookData) {
                const fName = hookData.row.raw[1]
                const fUrl = createUrl(hookData.row.raw[4])
                if(hookData.column.dataKey === 4) {
                    pdf.textWithLink('LINK', hookData.cell.x, hookData.cell.y + 5, { url: `https://public.isoms.co.za/view-supdoc/${fUrl}/${fName}/` })
                }
            },
            theme: 'striped',
            bodyStyles: {
                fontSize: 8
            },
            margin: { top: 80 },
            styles: {
                cellWidth: 36.5,
                overflow: 'ellipsize'
            }
        })
    })

    pdf.autoTable({
        head: [[`No Folder`, 'Document Name', 'Date Created', 'Expiry Date','LINK']],
        theme: 'striped',
        margin: { top: 80 },
        styles: {
            cellWidth: 36.5,
            overflow: 'ellipsize'
        }
    })

    // const noFolderArray = await getDocsNoFolder(data);
    data.map(d => {
        const encodedUri = encodeURIComponent(d.fUrl)
        const doubleEncode = encodeURIComponent(encodedUri)
        console.log(d)
        if (!d.folder) {
            pdf.autoTable({
                body: [['', d.fileName, moment(d.createdAt.toDate()).format('MM/DD/YYYY'), moment(d.expiryDate.toDate()).format('MM/DD/YYYY'),'']],
                didDrawCell: function(hookData) {
                    if(hookData.column.dataKey === 4) {
                        pdf.textWithLink('LINK', hookData.cell.x, hookData.cell.y + 5, { url: `https://public.isoms.co.za/view-supdoc/${doubleEncode}/${d.fileName}/` })
                    }
                },
                theme: 'striped',
                bodyStyles: {
                    fontSize: 8,
                },
                margin: { top: 0, bottom: 0 },
                styles: {
                    cellWidth: 36.5,
                    overflow: 'ellipsize'
                }
            })
        }
    })

    
    return pdf.save('Documents Submitted.pdf')
}

async function buildBigFolderArray(folders, data) {
    let bigFolderArray = []
    let bigHeadArray = []
    return new Promise(async (res, rej) => {
        await folders.map(async f => {
            bigHeadArray.push([`Folder: ${f.folderName}`, 'Document Name', 'Date Created', 'Expiry Date', ''])
            const folderBody = await buildFolderBodyArray(f.id, data)
            bigFolderArray.push(folderBody)
        })
        res({
            head: bigHeadArray,
            body: bigFolderArray
        })
    })
}

async function buildFolderBodyArray(folderId, data) {
    return new Promise(async (res, rej) => {
        let folderBody = []
        await data.map(d => {
            if(d.folder && d.folder.id === folderId) {
                folderBody.push([d.folder ? d.folder.value.folderName : 'None', d.fileName, moment(d.createdAt.toDate()).format('DD/MM/YYYY'), d.expires ? moment(d.expiryDate.toDate()).format('MM/DD/YYYY') : 'No Expiry Date', d.fUrl])
            }
        })
        res(folderBody)
    })
}

async function getDocsNoFolder(data) {
    return new Promise(async (res, rej) => {
        let docsArray = []
        await data.map(d => {
            if(d.folder === null || !d.folder) {
                console.log(d)
                docsArray.push([d.folder ? d.folder.value.folderName : 'None', d.fileName, moment(d.createdAt.toDate()).format('DD/MM/YYYY'), d.expires ? moment(d.expiryDate.toDate()).format('MM/DD/YYYY') : 'No Expiry Date', d.fUrl])
            }
        })
        res(docsArray)
    })
}

async function noFoldersPdf(companyRef, doc) {
    async function getCompanyLogo() {
        return await companyRef.get()
        .then(doc => {
            if(doc.data().logo) return doc.data().logo
            else return logo
        })
    }

    const data = await getDocumentsSubmittedData(companyRef, doc.id)
    if(!data) {
        throw({
            message: 'Error: No documents to retrieve.'
        })
    }
    
    const pdf = new jsPDF()
    var img = new Image;
    img.src = await getCompanyLogo()
        
    pdf.addImage(img, 'PNG', 80, 10, pdf.canvas.width * 0.3, pdf.canvas.height * 0.15)
    pdf.setFontSize(14)
    pdf.text(`Documents Submitted To: ${doc.docCode} ${doc.docName}`, 13, 60)

    const noFolderArray = []
    data.map(d => {
        console.log(d)
        noFolderArray.push([d.folder ? d.folder.value.folderName : 'None', d.fileName, moment(d.createdAt.toDate()).format('DD/MM/YYYY'), d.expires ? moment(d.expiryDate.toDate()).format('MM/DD/YYYY') : 'No Expiry Date', d.fUrl])
        
    })
    
    pdf.autoTable({
        head: [[`No Folder`, 'Document Name', 'Date Created', 'Expiry Date', '']],
        body: noFolderArray,
        didParseCell: function(hookData) {
            if(hookData.column.dataKey === 4) {
                hookData.cell.text = ''
            }
        },
        didDrawCell: function(hookData) {
            const encode = encodeURIComponent(hookData.row.raw[4])
            const doubleEncode = encodeURIComponent(encode)
            const fileName = hookData.row.raw[1]
            
            if(hookData.column.dataKey === 4) {
                pdf.textWithLink('LINK', hookData.cell.x, hookData.cell.y + 5, { url: `https://public.isoms.co.za/view-supdoc/${doubleEncode}/${fileName}/` })
            }
        },
        theme: 'striped',
        bodyStyles: {
            fontSize: 8
        },
        margin: { top: 80 },
        styles: {
            cellWidth: 36.5,
            overflow: 'ellipsize'
        }
    })
    return pdf.save('Documents Submitted.pdf')
}

async function getDocumentsSubmittedData(companyRef, docId) {
    let docsArray = []
    await companyRef.collection('documents').doc(docId)
    .collection('supportingDocs')
    .orderBy('createdAt', 'desc')
    .get()
    .then(snap => {
        docsArray = snap.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        }))
    })
    return docsArray
}

async function getSubfolders(companyRef, docId) {
    let foldersArray = []
    await companyRef.collection('documents').doc(docId)
    .collection('subfolders')
    .orderBy('folderName')
    .get()
    .then(snap => {
        foldersArray = snap.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        }))
    })
    return foldersArray
}

export async function docRegisterPdf(companyRef, docData) {
    async function getCompanyLogo() {
        return await companyRef.get()
        .then(doc => {
            if(doc.data().logo) return doc.data().logo
            else return logo
        })
    }

    const data = await getDocReport(docData, companyRef)
    if(!data) {
        throw({
            message: 'No Documents to retrieve.'
        })
    }
    const doc = new jsPDF();

    var img = new Image;
    img.src = await getCompanyLogo()
        
    doc.addImage(img, 'PNG', 80, 10, doc.canvas.width * 0.3, doc.canvas.height * 0.15)
    doc.text('Document Report', 80, 60)
    data.map(dd => {
        doc.setFontSize(8)
        doc.autoTable({
            head: [[`${dd.docName}`,``]],
            theme: 'striped',
            margin: { top: 80 },
            didDrawCell: function(hookData) {
                console.log(hookData)
                if(hookData.column.dataKey === 1) {
                    doc.textWithLink('LINK', hookData.cell.x, hookData.cell.y + 5, { url: dd.docUrl })
                }
            }
        })
        doc.autoTable({
            body:[
                [`Owner: ${dd.owner.label}`, `Date Created: ${moment(dd.createdDate.toDate()).format('MM/DD/YYYY')}`],
                [`Type: ${dd.docType.label}`, `Target Revision Date: ${moment(dd.targetRevDate.toDate()).format('MM/DD/YYYY')}`],
                [`Department: ${dd.department.label}`, `Due: ${moment(dd.targetRevDate.toDate()).fromNow()}`],
                [`Retention: ${dd.retentionPeriod.label}`, `Disposition: ${dd.dispositionMethod.label}`]
            ],
            theme: 'plain',
            bodyStyles: {
                fontSize: 8,
            }
        })
        if(dd.revisions) {
            const revBody = dd.revisions.map(r => ([
                r.description,
                moment(r.createdDate.toDate()).format('DD/MM/YYYY'),
                r.revisedBy[0].label,
                r.requestedBy.label,
                `${r.numApproved}/${r.numApprovers}`
            ]))
            console.log('REV BODY', revBody)
            doc.autoTable({
                head:[
                    ['Revisions','Date','Revised By','Requested By', 'Confirmed']
                ],
                body: revBody,
                headStyles:{ 
                    fillColor: [128, 130, 127],
                    fontSize: 8
                },
                bodyStyles: {
                    fillColor: [255,255,255],
                    fontSize: 7
                } 
            })
        }
    })
    return doc.save('Documents Report.pdf')
}

async function getDocReport(docsArray, companyRef) {
    const newArray = await Promise.all(docsArray.map(async d => {
            const revs = await getRevisionsForDoc(d.id, companyRef)
            if(revs === null) {
                return d
            }
            else {
                return {
                    ...d,
                    revisions: revs
                }
            }
        })
    )
    return newArray
}

function getRevisionsForDoc(docId, companyRef) {
    return companyRef.collection('documents')
    .doc(docId)
    .collection('revisions')
    .get()
    .then(snap => {
        if(snap.empty) {
            return null
        }
        else {
            const revList = snap.docs.map(rev => ({
                ...rev.data(),
                id: rev.id
            }))
            return revList
        }
    })
}

// --------------- Single Action PDF -----------------------

export async function generateSingleActionPdf(action, companyRef, createdByUser) {
    async function getCompanyLogo() {
        return await companyRef.get()
        .then(doc => {
            if(doc.data().logo) return doc.data().logo
            else return logo
        })
    }

    const attachments = await companyRef.collection('actions').doc(action.id)
    .collection('attachments').get().then(snap => {
        if(!snap.empty) {
            const attachList = snap.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }))
            return attachList
        }
        else {
            return []
        }
    }).catch(err => { console.log(err.message)})

    const pdf = new jsPDF()
    var img = new Image;
    img.src = await getCompanyLogo()
        
    pdf.addImage(img, 'PNG', 80, 10, pdf.canvas.width * 0.3, pdf.canvas.height * 0.15)
    pdf.setFontSize(14)
    pdf.text(`Action Report`, 13, 60)

    pdf.autoTable({
        head: [[`Source`]],
        theme: 'striped',
        margin: { top: 80 }  
    })

    pdf.autoTable({
        body:[
            [`Action Number: ${action.actionNumber}`, `Department: ${action.department.name}`],
            [`Site: ${action.site.label}`, `Source: ${action.source.sourceName}`]
        ],
        theme: 'plain',
        bodyStyles: {
            fontSize: 8,
        }
    })

    pdf.autoTable({
        head: [[`Responsibility`]],
        theme: 'striped'
    })

    pdf.autoTable({
        body:[
            [`HOD: ${action.hod.firstName} ${action.hod.lastName}`, `Originator: ${action.originator.firstName} ${action.originator.lastName}`],
            [`ISO Classification: ${action.isoClass}`, `ISO Agency: ${action.isoAgency.isoAgency}`],
            [`Priority: ${action.priority}`, `Target Date: ${moment(action.targetDate.toDate()).format('DD/MM/YYYY')}`],
            [`Close Out Date: ${action.open ? 'Open' : moment(action.closeOutDate.toDate()).format('DD/MM/YYYY')}`]
        ],
        theme: 'plain',
        bodyStyles: {
            fontSize: 8,
        }
    })

    pdf.autoTable({
        head: [[`Description`]],
        theme: 'striped',
        body:[
            [action.description]
        ],
        bodyStyles: {
            fontSize: 8,
        }
    })

    pdf.autoTable({
        head: [[`Immediate Action`]],
        theme: 'striped',
        body:[
            [action.immAction]
        ],
        bodyStyles: {
            fontSize: 8,
        }
    })

    pdf.autoTable({
        head: [[`Root Cause`, `Preventive Measures`]],
        theme: 'striped',
        body:[
            [action.rootCause, action.preventive]
        ],
        bodyStyles: {
            fontSize: 8,
        }
    })

    pdf.autoTable({
        head: [[`Baseline Risk`]],
        body:[
            [action.baselineText]
        ],
        theme: 'striped',
        bodyStyles: {
            fontSize: 8,
        }
    })

    pdf.autoTable({
        body:[
            [``, ``, ``],
            [`Head of Department`, `Originator`, `Logged By`],
            [`${action.hod.firstName} ${action.hod.lastName}`, `Originator: ${action.originator.firstName} ${action.originator.lastName}`, `${createdByUser.firstName} ${createdByUser.lastName}`],
            
        ],
        theme: 'plain',
        bodyStyles: {
            fontSize: 8,
            halign: 'center',
            marginTop: 20
        },
    })

    if (attachments.length > 0) {
        pdf.autoTable({
            head: [[`Attachments`]],
            theme: 'striped'
        })
        attachments && attachments.map(a => {
            const encodedUri = encodeURIComponent(a.fileUrl)
            const doubleEncode = encodeURIComponent(encodedUri)
            pdf.autoTable({
                body:[
                    ['']
                ],
                didDrawCell: function(hookData) {
                    if(hookData.column.dataKey === 0) {
                        pdf.textWithLink(a.fileName, hookData.cell.x, hookData.cell.y + 5, { url: `https://public.isoms.co.za/actions/attachment/${doubleEncode}/${a.fileName}` })
                        
                    }
                },
                theme: 'striped',
                bodyStyles: {
                    fontSize: 8,
                }
            })
        })
    }

    return pdf.save(`${action.actionNumber}`)

}



