import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import { textFieldProps } from "../../shared/constants";
import { Save } from "@mui/icons-material";
import { useDb } from "../../../contexts/DatabaseContext";

export default function EditSource({ source, open, closeDialog }) {
  const { updateSource } = useDb();

  const [state, setState] = useState({
    sourceName: source.sourceName,
    rootCauseRequired: false,
  });

  useEffect(() => {
    return setState({
      sourceName: source.sourceName,
      rootCauseRequired: source?.rootCauseRequired || false,
    });
  }, [source, open]);

  // Action State
  const [updated, setUpdated] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setLoading(false);
    setError(null);
    setUpdated(false);
    closeDialog();
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
    setUpdated(true);
  };

  const handleSwitch = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.checked,
    });
    setUpdated(true);
  };

  const handleSubmit = async () => {
    setError(null);
    setLoading(true);
    try {
      await updateSource(source.id, state).then(() => {
        handleClose();
      });
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
      <DialogContent>
        <TextField
          {...textFieldProps}
          value={state.sourceName}
          name="sourceName"
          label="Source Name"
          onChange={handleChange}
        />
        <FormControlLabel
          control={
            <Switch
              checked={state.rootCauseRequired}
              onChange={handleSwitch}
              name="rootCauseRequired"
              color="primary"
            />
          }
          label="Root Cause Analysis Required"
        />
      </DialogContent>
      <DialogActions>
        {error ? <span className="iso-error">{error}</span> : null}
        <Button disabled={loading} color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          disabled={loading || !updated}
          color="primary"
          onClick={handleSubmit}
          variant="contained"
          startIcon={<Save />}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}
