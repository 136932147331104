import React from "react";
import { ImporterField } from "react-csv-importer";

export default function ProgramImportSchema() {
  return (
    <>
      <ImporterField name="measurement" label="Measurement" />
      <ImporterField name="timestamp" label="Datetime (YYYY-MM-DD HH:MM)" />
      <ImporterField name="comment" label="Comment" optional />
    </>
  );
}
