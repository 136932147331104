import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    Avatar,
    Button,
    Card,
    CardHeader,
    Dialog,
    DialogActions,
    IconButton,
    Menu,
    DialogContent,
    Chip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react'
import moment from 'moment';
import DocDropDown from '../document/DocDropDown';
import FileDialog from '../../shared/FileDialog';
import PendingDropDown from './PendingDropDown';

const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: 'lightgrey',
        position: 'relative'
    },
    dialog: {
        backgroundColor: theme.palette.dialogBg
    },
    text: {
        color: 'grey'
    }
}))

export default function PendingDocListItem({ doc, user }) {
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null)
    const [dest, setDest] = useState(null)
    const [revisionDialog, setRevisionDialog] = useState(false)
    const [pdfViewOpen, setPdfViewOpen] = useState(false)
    const classes = useStyles()

    const openDialog = (dest) => {
        setDest(dest)
        setAnchorEl(null)
        setOpen(true)
    }

    const closeDialog = () => {
        setOpen(false)
    }

    const closePdfView = () => {
        setPdfViewOpen(false)
    }

    return <>
        <Card className={classes.card}>
            <CardHeader
                
                avatar={
                    <Avatar>{doc.category}</Avatar>
                }
                title={<span style={{ color: 'grey'}} className={'iso-title-link'} onClick={() => setPdfViewOpen(true)}>{`${doc.docCode} ${doc.docName} - Created: ${moment(doc.createdDate.toDate()).format('DD/MM/YYYY')} - Department: ${doc.department.label}`}</span>}
                //subheader={`${doc.docType.label} | Created: ${moment(doc.createdDate.toDate()).format('DD/MM/YYYY')} | Target Revision Date: ${moment(doc.targetRevDate.toDate()).fromNow()}`}
                subheader={<span style={{ color: 'grey'}} className="smaller-text">Status: {<b>{`Due: ${moment(doc.targetRevDate.toDate()).fromNow()}`}</b>}</span>}
                action={
                    user.uid === doc.owner.value.uid || user.companyAdmin ?
                    <>
                        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} size="large">
                            <FontAwesomeIcon icon={faEllipsisH} />
                        </IconButton> 
                    </>
                    : 
                    null
                }
            />

            <FileDialog open={pdfViewOpen} closeDialog={closePdfView} file={doc.docUrl} />
            <Chip
                style={{ 
                    position: 'absolute',
                    top: '30%',
                    left: '40%'
                }}
                color="secondary"
                label={`Pending Approval ${doc.approvers - doc.pendingApproval}/${doc.approvers}`}
            />
        </Card>

        <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            
        >
            <PendingDropDown doc={doc} openDialog={openDialog} closeDialog={closeDialog} />
        </Menu>
        <Dialog maxWidth="sm" fullWidth open={open} onClose={() => setOpen(false)}>
            <DialogContent>
                { dest }
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog} color="primary">Close</Button>
            </DialogActions>
        </Dialog>
    </>;
}
