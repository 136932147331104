import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  TextField,
  Typography,
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from "react";
import { useDb } from "../../contexts/DatabaseContext";
import { useAuth } from "../../contexts/AuthContext";
import Select from "react-select";
import { selectFieldProps, textFieldProps } from "../shared/constants";
import { Save } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import FloatingHelpComponent from "../help/FloatingHelpComponent";

const useStyles = makeStyles((theme) => ({
  menu: {
    backgroundColor: theme.palette.menuBg,
  },
}));

export default function CreateAction({ open, closeDialog }) {
  const { currentUser } = useAuth();
  const {
    GetDepartments,
    GetSites,
    GetUsersLevelFilter,
    GetSources,
    createAction,
    GetIsoAgenciesPerClass,
    GetPriorities,
    GetIsoClasses,
  } = useDb();
  const history = useHistory();

  // Database Calls
  const departments = GetDepartments();
  const sites = GetSites();
  const rosters = GetUsersLevelFilter("actionManager", 1);
  const originators = GetUsersLevelFilter("actionManager", 2);
  const sources = GetSources();
  const isoClassifications = GetIsoClasses();

  const priorities = GetPriorities();

  // Component State
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // Form State
  const [department, setDepartment] = useState(null);
  const [site, setSite] = useState(null);
  const [source, setSource] = useState(null);
  const [hod, setHod] = useState(null);
  const [originator, setOriginator] = useState(null);
  const [isoClass, setIsoClass] = useState(null);
  const [isoAgency, setIsoAgency] = useState(null);
  const [priority, setPriority] = useState(null);
  const [targetDate, setTargetDate] = useState(new Date());
  const [description, setDescription] = useState(null);
  const [immAction, setImmAction] = useState(null);
  const [methodology, setMethodology] = useState(null);

  const isoAgencies = GetIsoAgenciesPerClass(isoClass);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const thisDate = new Date();
    const thisYear = thisDate.getFullYear();

    try {
      await createAction({
        department,
        site,
        source,
        hod,
        originator,
        isoClass,
        isoAgency,
        priority,
        targetDate,
        description,
        immAction,
        timestamp: new Date(),
        createdBy: currentUser.uid,
        year: thisYear,
        methodology: methodology ? methodology : ""
      }).then((actionId) => {
        setLoading(false);
        closeDialog();
        history.push(`/action-attachments/${actionId}`);
      });
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const handlePriorityChange = (v) => {
    const prDays = parseInt(v.days);
    const tDate = new Date();

    Date.prototype.addDays = function (days) {
      var date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };

    setPriority(v.value.name);
    setTargetDate(tDate.addDays(prDays));
  };

  return (
    <>
      <Dialog open={open} maxWidth="md" fullWidth onClose={closeDialog}>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Typography>Action Source</Typography>
                <br />
                <Select
                  options={departments}
                  {...selectFieldProps}
                  placeholder={"Select Department ..."}
                  onChange={(val) => setDepartment(val.value)}
                  className={"iso-select"}
                />
                <Select
                  options={sites}
                  {...selectFieldProps}
                  placeholder={"Select Site ..."}
                  onChange={(val) => setSite(val)}
                  className={"iso-select"}
                />
                <Select
                  options={sources}
                  {...selectFieldProps}
                  placeholder={"Select Source ..."}
                  onChange={(val) => setSource(val.value)}
                  className={"iso-select"}
                />
                <br />
                <Divider />
                <br />
                <Typography>Action Responsiblity</Typography>
                <br />
                <Select
                  options={rosters}
                  {...selectFieldProps}
                  placeholder={"Select Responsible HOD ..."}
                  // onChange={(val) => console.log(val.value)}
                  onChange={(val) => setHod(val.value)}
                  className={"iso-select"}
                />
                <Select
                  options={originators}
                  {...selectFieldProps}
                  placeholder={"Select Originator ..."}
                  onChange={(val) => setOriginator(val.value)}
                  className={"iso-select"}
                />
                <Select
                  options={isoClassifications}
                  {...selectFieldProps}
                  placeholder={"Select ISO Classification ..."}
                  onChange={(val) => setIsoClass(val.value)}
                  className={"iso-select"}
                />
                {isoClass ? (
                  <Select
                    options={isoAgencies}
                    {...selectFieldProps}
                    placeholder={"Select ISO Agency ..."}
                    onChange={(val) => setIsoAgency(val.value)}
                    className={"iso-select"}
                  />
                ) : null}
                <Select
                  options={priorities}
                  {...selectFieldProps}
                  placeholder={"Select Priority ..."}
                  onChange={handlePriorityChange}
                  className={"iso-select capitalize"}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <Typography>Action Description</Typography>
                <br />
                <TextField
                  className={"iso-text-field"}
                  {...textFieldProps}
                  label="Action Description"
                  multiline
                  rows={3}
                  required
                  onChange={(e) => setDescription(e.target.value)}
                />
                <TextField
                  className={"iso-text-field"}
                  {...textFieldProps}
                  label="Immediate Action Taken"
                  multiline
                  rows={3}
                  required
                  onChange={(e) => setImmAction(e.target.value)}
                />
                { source?.rootCauseRequired && 
                  <div>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        Root Cause Analysis Methodology
                      </FormLabel>
                      <RadioGroup
                        value={methodology}
                        onChange={(e) => setMethodology(e.target.value)}
                      >
                        <FormControlLabel
                          value="5why"
                          control={<Radio />}
                          label="5 Why"
                        />
                        <FormControlLabel
                          value="fishbone"
                          control={<Radio />}
                          label="Fishbone"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                }
              </Grid>
            </Grid>

            {error ? <span className={"iso-error"}>{error}</span> : null}
          </DialogContent>
          <DialogActions>
            <Button disabled={loading} onClick={closeDialog} color="secondary">
              Cancel
            </Button>
            <Button
              disabled={
                loading ||
                !source ||
                !site ||
                !department ||
                !hod ||
                !originator ||
                !isoClass ||
                !isoAgency ||
                !priority ||
                source?.rootCauseRequired && !methodology
              }
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<Save />}
            >
              Submit Action
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <FloatingHelpComponent
        name="Creating an Action"
        videoPath="action_manager"
        videoIndex={0}
      />
    </>
  );
}
