import { Button, Card, CardContent, LinearProgress, Typography } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'


export default function FacilitatorDetail() {
    const { id } = useParams()
    const { getFacilitator } = useDb()
    const [facilitator, setFacilitator] = useState()
    const history = useHistory()

    useEffect(async () => {
        const f = await getFacilitator(id)
        setFacilitator(f)
        return f
    }, [])

    return (
        <div>
            {
                facilitator 
                ?
                <div>
                    <h1>Facilitator: {facilitator.firstName} {facilitator.lastName}</h1>
                    <div className="back-link">
                        <Button onClick={() => history.goBack()} color="primary" variant="contained" startIcon={<ArrowBack />}>Back</Button>
                    </div>
                    <Card>
                        <CardContent>
                            <Typography>Provider: {facilitator.external ? facilitator.provider?.name : 'In House'}</Typography>
                            

                        </CardContent>
                    </Card>
                </div>
                :
                <LinearProgress />
            }
        </div>
    )
}
