import { LinearProgress, Button, Card, CardActions, Grid, CardContent, Typography } from '@mui/material'
import { ArrowLeftOutlined, Person, Search } from '@mui/icons-material'
import React, { useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import moment from 'moment'
import FileDialog from '../../shared/FileDialog'

export default function RevisionDetailN() {
    const { docId, revId, docCode } = useParams()
    const { getRevisionById } = useDb()

    const [rev, setRev] = useState(null)
    const [loaded, setLoaded] = useState(false)

    const [viewerOpen, setViewerOpen] = useState(false)

    const firstLoad = async () => {
        const r = await getRevisionById(docId, revId)
        setRev(r)
        setLoaded(true)
    }
    
    if(!loaded) {
        firstLoad()
        return <LinearProgress />
    }
    else return (
        <div>
            <h1>Revision: {rev.revCode}</h1>
            <Link to={`/doc-manager/revisions/${docId}/${docCode}`}>
                <Button variant="contained" color="primary" startIcon={<ArrowLeftOutlined />}>{docCode} Revisions</Button>
            </Link>
            <br/><br/>
            <Card>
                <CardActions>
                    <Link to={`/doc-manager/revision-approvals/${docId}/${revId}`}> 
                        <Button color="primary" variant="contained" startIcon={<Person />}>Approvers</Button>
                    </Link>
                    <Button onClick={() => setViewerOpen(true)} color="primary" variant="contained" startIcon={<Search />}>View Document</Button>
                </CardActions>
            </Card>
            <br/>
            <Card>
                <CardContent>
                    <Typography>
                        <b>Description:</b> {rev.description}
                    </Typography>
                    <br/>
                    <Typography>
                        <b>Revised By:</b>
                    </Typography>
                    <ul>
                        {rev.revisedBy && rev.revisedBy.map(p => (<li key={p.value.id}>{p.label}</li>))}
                    </ul>
                    <Typography><b>Revision Date:</b> {moment(rev.revDate.toDate()).format('DD/MM/YYYY')}</Typography>
                    <Typography><b>Target Revision Date:</b> {moment(rev.targetRevDate.toDate()).format('DD/MM/YYYY')}</Typography>
                </CardContent>
            </Card>
            <FileDialog open={viewerOpen} closeDialog={() => setViewerOpen(false)} file={rev.docUrl} /> 
        </div>
    )
}
