import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import { ArrowForward } from '@mui/icons-material'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDb } from '../../../../contexts/DatabaseContext'
import { textFieldProps } from '../../../shared/constants'


export default function AddHazard({ open, close, blraId, process, step }) {
    const { createHazard } = useDb()
    const history = useHistory()

    const [state, setState] = useState({
        loading: false,
        error: null,
        data: {}
    })

    const handleClose = () => {
        setState({
            loading: false,
            error: null,
            data: {}
        })
        close()
    }

    const handleChange = (e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleSubmit = async () => {
        setState({
            ...state,
            loading: true,
            error: null
        })
        try {
            const hazardId = await createHazard(blraId, process, step, state.data.name)
            history.push(`/risk-manager/baselines/${blraId}/hazards/${hazardId}`)
        }
        catch(err) {
            setState({
                ...state,
                loading: false,
                error: err.message
            })
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>Add Hazard</DialogTitle>
            <DialogContent>
                <TextField
                    {...textFieldProps}
                    label="Hazard Name"
                    onChange={handleChange}
                    name="name"
                />
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    endIcon={<ArrowForward />}
                    disabled={
                        state.loading ||
                        !state.data.name
                    }
                    onClick={handleSubmit}
                >
                    Create Hazard
                </Button>
            </DialogActions>
        </Dialog>
    )
}
