import { Grid } from '@mui/material'
import React from 'react'
import { useDb } from '../../contexts/DatabaseContext'
import AnnualStats from './dashboard/AnnualStats'
import RecentMisses from './dashboard/RecentMisses'
import TotalSuppliers from './dashboard/TotalSuppliers'

export default function AuditManager() {
    const { GetMissedInspectionsThisYear, GetCompleteInspectionsThisYear } = useDb()

    const missed = GetMissedInspectionsThisYear()
    const complete = GetCompleteInspectionsThisYear()

    

    return (
        <div>
            <h1>Audit Manager</h1>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <AnnualStats 
                        missed={missed}
                        complete={complete}
                    />
                    <br/>
                    <TotalSuppliers />
                </Grid>
                <Grid item xs={12} md={6}>
                    <RecentMisses
                        missed={missed}
                    />
                </Grid>
            </Grid>
        </div>
    )
}
