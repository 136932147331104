import { HelpOutline } from "@mui/icons-material";
import {
  Avatar,
  Card,
  CardActionArea,
  CardHeader,
  IconButton,
} from "@mui/material";
import React from "react";

export default function CatalogItem({ comp, handlePick }) {
  return (
    <Card>
      <CardActionArea onClick={() => handlePick(comp)}>
        <CardHeader
          title={comp.name}
          avatar={<Avatar>{comp.icon}</Avatar>}
          action={
            <IconButton size="small">
              <HelpOutline />
            </IconButton>
          }
        />
      </CardActionArea>
    </Card>
  );
}
