import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { textFieldProps } from "../../shared/constants";
import { ArrowForward } from "@mui/icons-material";
import { useDb } from "../../../contexts/DatabaseContext";
import { useHistory } from "react-router-dom";

export default function AddRequest({ open, close }) {
  const history = useHistory();
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const { addCompanyDoc, incrementMocCount } = useDb();

  const handleClose = () => {
    setTitle("");
    close();
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const mocNumber = await incrementMocCount();
      const mocId = await addCompanyDoc("moc", {
        title,
        draft: true,
        status: "Draft",
        phase: 1,
        step: "phase1",
        mocNumber,
        changeRequired: true,
        currentStepIndex: 0
      });
      history.push(`/moc-request/${mocId}`);
      handleClose();
    } catch (err) {
      window.alert(err.message);
    }
    setLoading(false);
    handleClose();
  };

  return (
    <Dialog open={open} maxWidth="sm" onClose={handleClose} fullWidth>
      <DialogTitle>New Change Request</DialogTitle>
      <DialogContent>
        <TextField
          {...textFieldProps}
          label="Request Title"
          required
          onChange={(e) => setTitle(e.target.value)}
          value={title}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button
          disabled={loading || !title}
          color="primary"
          variant="contained"
          endIcon={<ArrowForward />}
          onClick={handleSubmit}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
