import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDb } from '../../../../contexts/DatabaseContext'
import moment from 'moment'
import IsoDataGrid from '../../../shared/data-grid/IsoDataGrid'
import { useHistory } from 'react-router-dom'
import { Button, LinearProgress } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'

export default function SessionReportDetail() {
    const { reportId } = useParams()
    const { singleSessionReport } = useDb()
    const [report, setReport] = useState()
    const history = useHistory()

    useEffect(async () => {
        const unsubscribe = await singleSessionReport(reportId)
        setReport(unsubscribe)
        return unsubscribe
    }, [])

    const columns = [
        { field: 'startTime', headerName: 'Started', width: 200 },
        { field: 'endTime', headerName: 'Ended', width: 200 },
        { field: 'attendees', headerName: 'Attendees' , width: 150 },
        { field: 'cost', headerName: 'Session Total Cost' , width: 200 },
        { field: 'module', headerName: 'Module', width: 200 },
        { field: 'inhouse', headerName: 'In House', width: 200 },
        { field: 'site', headerName: 'Site', width: 200 },
        { field: 'venue', headerName: 'Venue', width: 200 },
        
    ]

    const rows = report && report.sessions.map(s  => ({
        id: s.id,
        startTime: moment(s.startTime.toDate()).format('DD/MM/YY, h:mm:ss a'),
        endTime: moment(s.startTime.toDate()).format('DD/MM/YY, h:mm:ss a'),
        module: `${s.moduleCode} - ${s.moduleName}`,
        cost: s.cost,
        site: s.site,
        venue: s.venue,
        inhouse: s.inhouse ? 'In House' : 'External',
        attendees: s.attendees
    }))

    return (
        <div>
            <h1>Session Report</h1>
            <div className="back-link">
                <Button onClick={() => history.goBack()} color="primary" variant="contained" startIcon={<ArrowBack />}>Back</Button>
            </div>
            {
                report 
                ?
                <IsoDataGrid 
                    data={rows}
                    columns={columns}
                />
                :
                <LinearProgress />
            }
        </div>
    )
}
