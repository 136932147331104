import { Button, Card, CardContent, Container } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import { useAuth } from "../../../contexts/AuthContext";
import { benefitQuestions } from "./benefitsTemplate";
import IsoFormBuilder from "../form-builder/IsoFormBuilder";
const sections = [{ name: "Reasons for Request", order: 1 }];

export default function ExpectedBenefits() {
  const { currentUser } = useAuth();
  const { createNewFormTemplate, getCompanyDoc } = useDb();
  const [loading, setLoading] = useState(false);
  const [pageLoad, setPageLoad] = useState(true);
  const [checklist, setChecklist] = useState();
  const [newFormCreated, setNewFormCreated] = useState(false);

  const loadChecklist = async () => {
    const formTemplate = await getCompanyDoc(
      "formTemplate",
      "benefitChecklist"
    );
    if (formTemplate) {
      setChecklist(formTemplate);
    }
    setPageLoad(false);
  };

  useEffect(() => {
    return loadChecklist();
  }, [newFormCreated]);

  const createTemplateChecklist = async () => {
    // Load up the template checklist from Firestore master templates
    setLoading(true);
    try {
      await createNewFormTemplate(
        sections,
        [benefitQuestions],
        "benefitChecklist"
      );
      setNewFormCreated(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      window.alert(err.message);
    }
    // Create a new checklist for the user in their checklist collection
    // Render the checklist
  };

  return (
    <div>
      <div>
        <Container>
          <Card>
            <CardContent>
              {!checklist && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={createTemplateChecklist}
                  disabled={loading}
                >
                  Create Benefit Checklist
                </Button>
              )}
              {checklist && <IsoFormBuilder formDocId={checklist.id} />}
            </CardContent>
          </Card>
        </Container>
      </div>
    </div>
  );
}
