import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import React, { useState } from "react";
import Select from "react-select";
import { useDb } from "../../contexts/DatabaseContext";
import { selectFieldProps } from "../shared/constants";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { Search } from "@mui/icons-material";
import { useHistory } from "react-router-dom";

export default function SearchAuditTrail({ open, closeDialog }) {
  const { GetUserList } = useDb();
  const users = GetUserList();
  const history = useHistory();
  const [state, setState] = useState({
    uid: null,
    fromDate: new Date(),
    toDate: new Date(),
  });

  // Action State
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    closeDialog();
  };

  const handleSubmit = async () => {
    setError(null);
    setLoading(true);

    try {
      const fromDateE = encodeURIComponent(state.fromDate);
      const toDateE = encodeURIComponent(state.toDate);
      const searchUrl = `/audit-trail/search/user/${state.uid}/${state.fromDate}/${state.toDate}/`;
      // const res = await getUserLogs(state.uid, state.fromDate, state.toDate)
      setLoading(false);
      history.push(searchUrl);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <Select
          options={users}
          {...selectFieldProps}
          placeholder="Select User ..."
          onChange={(val) => setState({ ...state, uid: val.id })}
          // onChange={(val) => console.log(val)}
        />
        <br />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="From Date ..."
            onChange={(d) => setState({ ...state, fromDate: d.toDate() })}
            defaultValue={dayjs(state.fromDate)}
          />
        </LocalizationProvider>
        <br />
        <br />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="To Date ..."
            onChange={(d) => setState({ ...state, toDate: d.toDate() })}
            defaultValue={dayjs(state.toDate)}
          />
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        {error ? <span className="iso-error">{error}</span> : null}
        <Button disabled={loading} color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          disabled={loading || !state.uid}
          color="primary"
          variant="contained"
          startIcon={<Search />}
          onClick={handleSubmit}
        >
          Search
        </Button>
      </DialogActions>
    </Dialog>
  );
}
