import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";

export default function IsoCheckbox(props) {
  const { name, onChange, type, loading, value, label } = props;
  const handleChange = (event) => {
    const newValue = event.target.checked;
    if (onChange) {
      onChange(name, newValue, type);
    }
  };
  return (
    <FormControlLabel
      control={<Checkbox key={value} checked={value} onChange={handleChange} />}
      label={label}
    />
  );
}
