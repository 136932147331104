import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React, { useState } from 'react'
import { useDb } from '../../contexts/DatabaseContext'

export default function DeleteConfirm({ collection, docId, open, closeDialog }) {
    const { obsoleteDoc } = useDb()
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    console.log(collection)
    const handleDelete = async () => {
        setLoading(true)
        try {
            await obsoleteDoc(collection, docId).then(() => {
                setLoading(false)
                closeDialog()
                window.location.reload()
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    return (
        <Dialog open={open} onClose={closeDialog} maxWidth={'sm'} fullWidth>
            <DialogTitle>Obsolete Confirmation</DialogTitle>
            <DialogContent>Are you sure you want to make this obsolete? You will be able to view this in your Archives.</DialogContent>
            { error ? <span className={'iso-error'}>{error}</span> : null }
            <DialogActions>
                <Button disabled={loading} onClick={() => closeDialog()}>Cancel</Button>
                <Button disabled={loading} onClick={handleDelete} variant={'contained'} startIcon={<FontAwesomeIcon icon={faTrash}/>} color={'secondary'} >Confirm Obsolete</Button>
            </DialogActions>
        </Dialog>
    )
}
