import React, { useState } from 'react';
import FishboneChart from 'fishbone-chart'
import { Button, Card, CardActions, CardContent, Grid } from '@mui/material';
import { fishboneTemplateCats } from '../../../shared/constants';
import { ChevronLeftSharp } from '@mui/icons-material';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import { useDb } from '../../../../contexts/DatabaseContext';

export default function FishboneTemplates() {

    const history = useHistory()
    const { actionId } = useParams()
    const { addFishboneCategory } = useDb()
    const [loading, setLoading] = useState(false)

    const handleTemplateSelect = (temp) => {
        setLoading(true)
        for (const key in temp) {
            if (temp.hasOwnProperty(key)) {
                addFishboneCategory(actionId, key)
            }
        }   
        history.goBack()
        setLoading(false)
    }

    return (
        <div>
            <Button startIcon={<ChevronLeftSharp />} variant='contained' color='primary' onClick={() => history.goBack()}>Start From Scratch</Button>
            <br/>
            <br/>
            <h1>Select Template: </h1>
            <Grid container spacing={2}>
                {
                    fishboneTemplateCats.map((cat) => (
                        <Grid item xs={6}>
                            <Card>
                                <CardContent>
                                    <FishboneChart data={cat}/>
                                </CardContent>
                                <CardActions>
                                    <Button disabled={loading} variant='contained' color='primary' onClick={() => {
                                            for (const key in cat) {
                                                if (cat.hasOwnProperty(key)) {
                                                    handleTemplateSelect(cat[key]);
                                                }
                                            }
                                        }}>Use Template</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))
                }
            </Grid>
        </div>
    );
}
