import { Avatar, Button, Card, CardActions, CardContent, Container, FormControlLabel, FormLabel, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useDb } from '../../../../contexts/DatabaseContext'
import Select from 'react-select'
import { selectFieldProps, textFieldProps } from '../../../shared/constants'
import { AddBox, ArrowForward, Delete, Person, PersonAdd } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'
import AddParticipant from '../../baseline/new-assessment/AddParticipant'

export default function NewIssueBasedRA() {
    const { GetBaselines, addIssueBasedRa } = useDb()
    const history = useHistory()
    const [state, setState] = useState({
        loading: false,
        error: null,
        data: {},
        addOpen: false,
        people: []
    })


    const baselines = GetBaselines()

    const handleRadio = (e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                initiateFrom: e.target.value
            }
        })
    }

    const handleSelect = (v, e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.name]: v.value
            }
        })
    }

    const handleChange = (e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleOpen = () => {
        setState({
            ...state,
            addOpen:true
        })
    }

    const handleClose = () => {
        setState({
            ...state,
            addOpen: false
        })
    }

    const handleAdd = (person) => {
        let peopleArray = state.people
        peopleArray.push(person)
        setState({
            ...state,
            people: peopleArray
        })
    }

    const handleRemove = (i) => {
        let userArray = state.people
        userArray.splice(i, 1)
        setState({
            ...state,
            people: userArray,
        })
    }

    const handleSubmit = async () => {
        setState({
            ...state,
            loading: true,
            error: null
        })
        try {
            const ibraId = await addIssueBasedRa({...state.data, participants: state.people })

            state.initiateFrom === 'blra' 
            ?
            history.push(`/risk-manager/issue-based/${ibraId}/${state.data.blra}/1`)
            :
            history.push(`/risk-manager/issue-based/${ibraId}/2`)
        }
        catch(err) {
            setState({
                ...state,
                loading: false,
                error: err.message
            })
        }
    }

    return (
        <Container>
            <h1>New Issue Based Risk Assessment</h1>
            <FormLabel component="legend">Initiate From:</FormLabel>
            <RadioGroup>
                <FormControlLabel onChange={handleRadio} value="baseline" control={<Radio />} label="Baseline" />
                <FormControlLabel onChange={handleRadio} value="moc" control={<Radio />} label="MOC" />
            </RadioGroup>
            {
                state.data.initiateFrom === 'baseline' ? 
                <Select
                    {...selectFieldProps}
                    className="iso-select"
                    placeholder="Select Baseline ..."
                    options={baselines}
                    name="blra"
                    onChange={handleSelect}
                />
                :
                <TextField
                    {...textFieldProps}
                    label="MOC Description"
                    onChange={handleChange}
                    name="mocDesc"
                />
            }
            <Typography>Participants:</Typography>
            <Card>
                <CardContent>
                    <List>
                        {
                            state.people && state.people.map((u, i) => {
                                return (
                                    <ListItem key={i} divider>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <Person />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText>
                                            {u.user.label} - Occupation: {u.occupation} - Experience: {u.experience} - Department: {u.department.label}
                                        </ListItemText>
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                size="small"
                                                onClick={() => handleRemove(i)}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                    { state.error && <Typography color="secondary">{state.error}</Typography>}
                </CardContent>
                <CardActions className="iso-right">
                    <Button
                        color="primary"
                        variant="contained"
                        startIcon={<PersonAdd />}
                        onClick={handleOpen}
                    >
                        Add Participant
                    </Button>
                </CardActions>
            </Card>
            <AddParticipant
                open={state.addOpen}
                close={handleClose}
                add={handleAdd}
            />
            
            { state.error && <Typography color="secondary">{state.error}</Typography> }
            <CardActions className="iso-right">
                {
                    state.data.initiateFrom && state.data.blra
                    ?
                    <Button
                        color="primary"
                        variant="contained"
                        endIcon={<ArrowForward />}
                        onClick={handleSubmit}
                        disabled={
                            state.loading ||
                            state.people.length < 1
                        }
                    >
                        Create IBRA From Baseline
                    </Button>
                    : null
                }
                {
                    state.data.initiateFrom && state.data.mocDesc
                    ?
                    <Button
                        color="primary"
                        variant="contained"
                        endIcon={<ArrowForward />}
                        onClick={handleSubmit}
                        disabled={
                            state.loading ||
                            state.people.length < 1
                        }
                    >
                        Create IBRA From MOC
                    </Button>
                    : null
                }

            </CardActions>
        </Container>
    )
}
