import {
  Card,
  CardHeader,
  IconButton,
  CircularProgress,
  CardContent,
  Typography,
  CardActions,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Menu,
  ButtonGroup,
  Snackbar,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { amber } from "@mui/material/colors";
import { Apps, List, PictureAsPdf, Search, Warning } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from "prop-types";
import { useAuth } from "../../../../contexts/AuthContext";
import Add from "@mui/icons-material/Add";
import LTabs from "./LTabs";
import DocRegisterData from "./DocRegisterData";
import { useDb } from "../../../../contexts/DatabaseContext";
import DocRegisterFilters from "./DocRegisterFilters";
import CreateDocument from "./CreateDocument";
import { Link } from "react-router-dom";
import ExtendedTabsDropdown from "./ExtendedTabsDropdown";
import moment from "moment";
import RevisionHistory from "../../document/RevisionHistory";
import DocDropDown from "../../document/DocDropDown";
import IsoDataGrid from "../../../shared/data-grid/IsoDataGrid";
import FileDialog from "../../../shared/FileDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faHeart } from "@fortawesome/free-solid-svg-icons";
import FloatingHelpComponent from "../../../help/FloatingHelpComponent";

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background,
      width: 500,
      position: "relative",
      minHeight: 200,
    },
    fab: {
      position: "fixed",
      bottom: theme.spacing(6),
      right: theme.spacing(7),
      backgroundColor: amber[500],
      "&:hover": {
        backgroundColor: amber[600],
      },
    },
    tab: {
      indicatorColor: theme.palette.primary,
    },
    icon: {
      color: theme.palette.primary,
    },
    tabPanel: {
      backgroundColor: theme.palette.main,
      padding: '0px 10px'
    },
    dialog: {
      backgroundColor: theme.palette.dialogBg,
    },
    search: {
      color: theme.palette.isotext,
    },
    chip: {
      marginRight: 5
    },
    filtersTab: {
      marginBottom: 20
    },
    loaderDiv: {
      width: '100%',
      textAlign: 'center'
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <CardContent className="iso-card-content">
            <Typography component={"span"}>{children}</Typography>
          </CardContent>
        )}
      </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
}



export default function RDocRegister() {
    const classes = useStyles()
    const { getRDocuments, createDocsPdf, addDocToFavorites, removeDocFromFavorites } = useDb()
    const [documents, setDocuments] = useState([])
    const [loading, setLoading] = useState(false)
    const [listView, setListView] = useState(false)
    const { currentUser } = useAuth()
    const [value, setTab] = useState(0)
    const [lFilter, setLFilter] = useState([1, "L1"])
    const [filtersOpen, setFiltersOpen] = useState(false)
    const [createOpen, setCreateOpen] = useState(false)
    const [lastDoc, setLastDoc] = useState(null)
    const divScrollRef = useRef()
    const [gettingData, setGettingData] = useState(false)
    const [overdue, setOverdue] = useState(false)
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null)
    const [dest, setDest] = useState(null)
    const [doc, setDoc] = useState(null)
    const [revisionDialog, setRevisionDialog] = useState(false)
    const [pdfViewOpen, setPdfViewOpen] = useState(false)
    const [snackOpen, setSnackOpen] = useState(false)
    const [snackMessage, setSnackMessage] = useState('')
    const [update, setUpdate] = useState(0)

    const [filters, setFilters] = useState({
      category: {
        fieldName: 'category',
        value: null
      },
      department: {
        fieldName: 'department.id',
        value: null
      },
      site: {
        fieldName: 'site.value',
        value: null
      },
      owner: {
        fieldName: 'owner.value.id',
        value: null
      },
      docType: {
        fieldName: 'docType.label',
        value: null
      },
      targetRevDate: {
        fieldName: 'targetRevDate',
        value: null
      }
    })

    useEffect( async () => {
      const d = await getRDocuments()
      setDocuments(d.data)
    }, [update])

    const getPdfReport = async () => {
      setLoading(true);
      try {
        await createDocsPdf(filters);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    const updateFilterState = (filter) => {
      setFilters({
        ...filters,
        [filter.key]: filter.data
      })
    }

    const handleOverdue = (e) => {
      setOverdue(e.target.checked)
      if(e.target.checked) {
        setFilters({
          ...filters,
          targetRevDate: {
            fieldName: 'targetRevDate',
            value: new Date()
          }
        })
      }
      else {
        setFilters({
          ...filters,
          targetRevDate: {
            fieldName: 'targetRevDate',
            value: null
          }
        })
      }
    }

    // The below use effect fires every time the filters state changes to get new documents
    useEffect(async () => {
      setGettingData(true)
      const data = await getRDocuments(filters)
      setDocuments(data.data)
      setGettingData(false)
    }, [filters, update])

    const handleSnack = (msg) => {
      setSnackMessage(msg)
      setSnackOpen(true)
    }

    const closeSnack = () => {
      setSnackOpen(false)
      setSnackMessage('')
    }

    const openDialog = (dest) => {
      setDest(dest)
      setAnchorEl(null) 
      setOpen(true)
    }

    const closeDialog = () => {
      setOpen(false)
    }

    const closePdfView = () => {
      setPdfViewOpen(false)
    }

    const addToFavorites = async (docId) => {
      try {
        await addDocToFavorites(docId, currentUser.uid)
        handleSnack('Document Added to Favourites!')
      }
      catch(err) {
        console.log('Error saving document to Favourites: ', err.message)
      }
    }

    const removeFromFavorites = async (docId) => {
      try {
        await removeDocFromFavorites(docId, currentUser.uid)
        handleSnack('Document Removed from Favourites')
      }
      catch(err) {
        console.log('Error saving document to Favourites: ', doc.id)
      }
    }

    let tempArray = [];

    documents.map((doc, i) => {

      const docData = {
        id: doc.id,
        index: i,
        code: doc.docCode,
        name: doc.docName,
        dateCreated: moment(doc.createdDate.toDate()).format('DD/MM/YYYY'),
        department: doc.department.label,
        targetRevDate: moment(doc.targetRevDate.toDate()).format('DD/MM/YYYY'),
        docOwner: doc.owner.label,
        status: `Due: ${moment(doc.targetRevDate.toDate()).fromNow()}`,
        tRefDate: doc.targetRevDate,
        customId: doc.customId
      }

      if (doc.pendingApproval < 1 || !doc.pendingApproval) {
        // Filter out grouped docs 
        if(doc.groups && doc.groups.length > 0) {
          console.log(currentUser.groups)
          if(currentUser.groups && currentUser.groups.some(r => doc.groups.includes(r))) {
            tempArray.push(docData)
          }
        } else {
          tempArray.push(docData)
        }
      }
    });

    const rows = tempArray

    const columns = [
      {
        field: 'actions',
        headerName: 'Actions',
        width: 150,
        renderCell: (params) => {
          return (
            <ButtonGroup>
              <IconButton
                onClick={(e) => {
                  setDoc(documents[params.row.index])
                  setAnchorEl(e.currentTarget)
                }}
                size="large">
                  <FontAwesomeIcon icon={faEllipsisH} />
              </IconButton> 
              <IconButton
                onClick={() => {
                  setDoc(documents[params.row.index])
                  setPdfViewOpen(true)
                }}
                size="large"><Search /></IconButton>
              {
                currentUser.favoriteDocs && currentUser.favoriteDocs.includes(params.row.id)
                ?
                <IconButton
                  onClick={() => {
                    removeFromFavorites(params.row.id)
                    .then(() => setUpdate(update +1))
                  }}
                  size="large">
                  <FontAwesomeIcon icon={faHeart} style={{ color: '#ffa900'}}/>
                </IconButton> 
                :
                <IconButton
                  onClick={() => {
                    addToFavorites(params.row.id)
                    .then(() => setUpdate(update +1))
                  }}
                  size="large">
                    <FontAwesomeIcon icon={faHeart}/>
                </IconButton>
              }
            </ButtonGroup>
          );
        }
      },
      {
        field: 'code',
        headerName: 'Doc Number',
        width: 200
      },
      {
        field: 'customId',
        headerName: 'Doc ID',
        width: 200
      },
      {
        field: 'name',
        headerName: 'Name',
        width: 600
      },
      {
        field: 'dateCreated',
        headerName: 'Date Created',
        width: 200
      },
      {
        field: 'department',
        headerName: 'Department',
        width: 200
      },
      {
        field: 'targetRevDate',
        headerName: 'Target Revision Date',
        width: 200
      },
      {
        field: 'docOwner',
        headerName: 'Document Owner',
        width: 200
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 200,
      },
    ]

    return (
      <div>
          <Card>
              <CardHeader
                  className={classes.tabPanel}
                  title={
                  <>
                      {loading ? (
                      <CircularProgress />
                      ) : (
                      <IconButton className={classes.icon} onClick={getPdfReport} size="large">
                          <PictureAsPdf />
                      </IconButton>
                      )}                        
                      {currentUser.companyAdmin ||
                      currentUser.accessRights.docManager <= 1 ? (
                      <IconButton onClick={() => setCreateOpen(true)} className={classes.icon} size="large">
                          <Add />
                      </IconButton>
                      ) : null}
                      <Link to="/doc-manager/overdue-docs">
                        <Button disableElevation variant="contained" color="primary" startIcon={<Warning />}>Overdue</Button>
                      </Link>
                  </>
                  }
                  
              />
              <div className={classes.tabPanel}>
                <span className="iso-small">Filters: </span>
                <DocRegisterFilters
                  open={filtersOpen}
                  close={() => setFiltersOpen(false)}
                  updateFilters={updateFilterState}
                />
                  {
                    currentUser.extendedDocs
                    ?
                      <ExtendedTabsDropdown 
                        updateFilters={updateFilterState}
                      />
                      :
                      <LTabs 
                        a11yProps={a11yProps}
                        classes={classes}
                        setTab={setTab}
                        setLFilter={setLFilter}
                        value={value}
                        handleL={updateFilterState}
                      />
                  }
                </div>
              <CardContent>
                {
                  gettingData
                  ?
                  <CircularProgress />
                  :
                  <IsoDataGrid
                    data={rows}
                    columns={columns}
                  />
                }
              </CardContent>
          </Card>

          <CreateDocument 
            open={createOpen}
            close={() => setCreateOpen(false)}
          />
          {
            doc 
            ?
            <>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                
              >
                <DocDropDown doc={doc} openDialog={openDialog} closeDialog={closeDialog}/>
              </Menu>
              
              <Dialog maxWidth="sm" fullWidth open={open} onClose={() => setOpen(false)}>
                  <DialogContent className={classes.dialog}>
                    { dest }
                  </DialogContent>
                  <DialogActions className={classes.dialog} >
                    <Button onClick={closeDialog} color="primary">Close</Button>
                  </DialogActions>
              </Dialog>

              <Dialog open={revisionDialog} onClose={() => setRevisionDialog(false)} maxWidth="md" fullWidth>
                  <DialogContent>
                    <RevisionHistory doc={doc}/>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setRevisionDialog(false)} color="primary">Close</Button>
                  </DialogActions>
              </Dialog>
              <FileDialog open={pdfViewOpen} closeDialog={closePdfView} file={doc.docUrl} /> 
            </>
            :
            null
          }
          <Snackbar
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
            }}
            open={snackOpen}
            autoHideDuration={5000}
            onClose={closeSnack}
            message={snackMessage}
          />

          <FloatingHelpComponent
              name="Uploading New Documents" 
              videoPath="doc_manager"
              videoIndex={5}
          />
      </div>
    );
}

