import { Button, Card, CardActions, CardContent, Checkbox, CircularProgress, FormControlLabel, TextField, Typography } from '@mui/material'
import { Save } from '@mui/icons-material'
import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import { textFieldProps } from '../../shared/constants'

export default function TestCloseOut() {
    const { actionId } = useParams()
    const { closeOutAction, getAction } = useDb()
    const history = useHistory()
    const [state, setState] = useState()
    const [reviewBaseline, setReviewBaseline] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const [loaded, setLoaded] = useState(false)

    const handleSubmit = async (e) => {
        setLoading(true)
        try {
            await closeOutAction(actionId, {
                ...state,
                closeOutDate: new Date()
            }).then(() => {
                history.push('/action-manager/register')
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    const firstLoad = async () => {
        const action = await getAction(actionId)
        setState({
            rootCause: action.rootCause ? action.rootCause : '',
            preventive: action.preventive ? action.preventive : ''
        })
        setLoaded(true)
    }

    if(!loaded) {
        firstLoad()
        return <CircularProgress />
    }
    return (
        <div>
            <Card>
                <CardContent>
                    <form onSubmit={handleSubmit}>
                            <Typography variant={'h5'}>Close Out Action</Typography>
                            <br/>
                            <TextField value={state.rootCause} required {...textFieldProps} className={'iso-text-field'} label={'Root Cause'} multiline rows={3} onChange={(e) => setState({...state, rootCause: e.target.value})}/>
                            <TextField value={state.preventive} required {...textFieldProps} className={'iso-text-field'} label={'Preventive Measures'} multiline rows={3} onChange={(e) => setState({...state, preventive: e.target.value})}/>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={reviewBaseline}
                                        onChange={() => setReviewBaseline(!reviewBaseline)}
                                        color={'primary'}
                                    />
                                }
                                label={'Review Baseline Risk'}
                            />
                            {
                                reviewBaseline 
                                ?
                                <TextField required label={'Review Baseline Risk'} {...textFieldProps} onChange={(e) => setState({...state, baselineText: e.target.value})}/>
                                :
                                null
                            }
                        <CardActions>
                            { error ? <span className={'iso-error'}>{error}</span> : null }
                            <Button color={'secondary'} onClick={() => history.push('/action-manager/register')}>Cancel</Button>
                            <Button type={'submit'} disabled={ loading || !state.rootCause || !state.preventive} color={'primary'} variant={'contained'} startIcon={<Save />} onClick={handleSubmit}>Close Out Action</Button>
                        </CardActions>
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}
