import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, CircularProgress, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useDb } from '../../../../../contexts/DatabaseContext'
import SignBlock from './SignBlock'

export default function SelectExistingSign({ type, open, close, handleSelect, loading }) {
    const { GetIsoSignsOfType } = useDb()
    const signs = GetIsoSignsOfType(type)


    const handleClose = () => {
        close()
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle>Set {type} Sign</DialogTitle>
            <DialogContent>
                {
                    loading 
                    ?
                    <CircularProgress />
                    :
                    <Grid container spacing={3}>
                        {
                            signs && signs.map(s => (
                                <Grid item xs={12} md={4} key={s.id}>
                                    <SignBlock
                                        sign={s}
                                        selectSign={handleSelect}
                                    />
                                </Grid>
                            ))
                        }
                    </Grid>
                }
            </DialogContent>
        </Dialog>
    )
}
