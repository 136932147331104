import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  LinearProgress,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { storage } from "../../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../contexts/AuthContext";
import { Info } from "@mui/icons-material";

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function FileUploader({
  userId,
  getUrl,
  getFilename,
  getFileObj,
  label,
}) {
  const { currentUser } = useAuth();
  const storageRef = storage.ref("users").child(userId || currentUser.uid);
  const [fileSelected, setFileSelected] = useState(false);
  const [docUrl, setDocUrl] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      setFileSelected(true);
    }
  };

  const handleUploadStart = () => {
    setIsUploading(true);
    setProgress(0);
    console.log(file);
    const fileName = Math.random().toString(36).slice(-10);
    const uploadTask = storageRef
      .child(`documents/${fileName}/${file.name}`)
      .put(file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var upProgress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(upProgress);
      },
      (error) => {
        setError("Error during File Upload");
        setIsUploading(false);
        setFileSelected(false);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((url) => {
          getUrl && getUrl(url);
          getFilename && getFilename(file.name);
          getFileObj && getFileObj({ url, fileName: file.name });
          setProgress(100);
          setIsUploading(false);
          setFileSelected(false);
        });
      }
    );
  };

  return (
    <Card>
      {label && (
        <CardHeader
          title={label}
          avatar={
            <Avatar>
              <Info />
            </Avatar>
          }
        />
      )}
      <CardContent>
        Upload File:
        <br />
        <input type="file" onChange={handleFileChange} />
        <LinearProgressWithLabel value={progress} />
        {error !== "" ? `Error Uploading file` : null}
      </CardContent>
      <CardActions className="iso-right">
        <Button
          disabled={!fileSelected || isUploading}
          onClick={handleUploadStart}
          startIcon={<FontAwesomeIcon icon={faUpload} />}
          variant="contained"
          color="primary"
        >
          Upload
        </Button>
      </CardActions>
    </Card>
  );
}
