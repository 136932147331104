import React, { useContext, useState, useEffect } from "react";
import { db, fieldValue, functions } from "../firebase";
import moment from "moment";
import { useAuth } from "./AuthContext";
import { jsPDF } from "jspdf";
import logo from "../img/ISO.png";
import "jspdf-autotable";
import firebase from "firebase/app";
import {
  documentsSubmittedPdf,
  docRegisterPdf,
  generateSingleActionPdf,
} from "./functions/database/PdfFunctions";
import {
  editRevision,
  editAddGroupsToDoc,
  editRemoveGroupFromDoc,
  removeFolderFromDocSubmitted,
} from "./functions/database/DocFunctions";
import { changeUserLevel } from "./functions/database/UserFunctions";

const DatabaseContext = React.createContext();

export function useDb() {
  return useContext(DatabaseContext);
}

export default function DatabaseProvider({ children, coRef }) {
  const { currentUser, updateCurrentUser } = useAuth();
  const companyRef = db.collection("companies").doc(coRef);

  // ---------------------- AUDIT TRAIL FUNCTION ------------------------

  async function logAuditTrail(userId, event) {
    const user = await db
      .collection("users")
      .doc(currentUser.uid)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

    return await companyRef.collection("auditTrail").add({
      user,
      event,
      timestamp: new Date(),
    });
  }

  async function getDocCode(docId) {
    return await companyRef
      .collection("documents")
      .doc(docId)
      .get()
      .then((doc) => {
        return doc.data().docCode;
      });
  }

  // ---------------------- GETTING DATA --------------------------

  // TODO: The below should really only be accessible to Management
  function getUsers() {
    return db.collection("users").get();
  }

  async function getCompanyDocument(companyId) {
    const coSnap = await db.collection("companies").doc(companyId).get();
    return coSnap.data();
  }

  function GetDocs() {
    const [documents, setDocuments] = useState([]);
    useEffect(() => {
      let docList = [];
      const unsubscribe = companyRef
        .collection("documents")
        .orderBy("order", "asc")
        .where("obsolete", "==", false)
        .onSnapshot((snapshot) => {
          snapshot.docs.map((document) => {
            if (document.data().pendingApproval) {
              if (document.data().pendingApproval <= 0) {
                docList.push({
                  ...document.data(),
                  id: document.id,
                });
              }
            } else {
              docList.push({
                ...document.data(),
                id: document.id,
              });
            }
          });
          setDocuments(docList);
        });
      return unsubscribe;
    }, []);
    return documents;
  }

  function GetPendingDocs() {
    const [documents, setDocuments] = useState([]);
    useEffect(() => {
      let docList = [];
      const unsubscribe = companyRef
        .collection("documents")
        .orderBy("order", "asc")
        .where("obsolete", "==", false)
        .onSnapshot((snapshot) => {
          snapshot.docs.map((document) => {
            if (document.data().pendingApproval > 0) {
              docList.push({
                ...document.data(),
                id: document.id,
              });
            }
          });
          // const docList = snapshot.docs.map((document) => ({
          //     id: document.id,
          //     ...document.data()
          // }))
          setDocuments(docList);
        });
      return unsubscribe;
    }, []);
    return documents;
  }

  function GetDepartments() {
    const [depts, setDepts] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("departments")
        .onSnapshot((snapshot) => {
          const deptList = snapshot.docs.map((doc) => ({
            id: doc.id,
            label: doc.data().name,
            value: doc.data(),
          }));
          setDepts(deptList);
        });
      return unsubscribe;
    }, []);
    return depts;
  }

  function GetDepartmentList() {
    const [depts, setDepts] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("departments")
        .orderBy("name", "asc")
        .onSnapshot((snapshot) => {
          const deptList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setDepts(deptList);
        });
      return unsubscribe;
    }, []);
    return depts;
  }

  function GetRosters() {
    const [roster, setRoster] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("rosters")
        .where("uid", "!=", null)
        .onSnapshot((snapshot) => {
          const rosterList = snapshot.docs.map((doc) => ({
            value: { ...doc.data(), id: doc.id },
            label: `${doc.data().firstName} ${doc.data().lastName} - ${
              doc.data().email
            }`,
          }));
          rosterList.sort((a, b) =>
            a.label > b.label ? 1 : b.label > a.label ? -1 : 0
          );
          setRoster(rosterList);
        });
      return unsubscribe;
    }, []);
    return roster;
  }

  function GetSites() {
    const [sites, setSites] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("sites")
        .orderBy("name", "asc")
        .onSnapshot((snapshot) => {
          const siteList = snapshot.docs.map((doc) => ({
            value: doc.id,
            label: doc.data().name,
          }));
          setSites(siteList);
        });
      return unsubscribe;
    }, []);
    return sites;
  }

  async function getSite(siteId) {
    return await companyRef
      .collection("sites")
      .doc(siteId)
      .get()
      .then((doc) => ({
        ...doc.data(),
      }));
  }

  function GetDocTypes() {
    const [docTypes, setDocTypes] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("docTypes")
        .orderBy("type", "asc")
        .onSnapshot((snapshot) => {
          const docList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setDocTypes(docList);
        });
      return unsubscribe;
    }, []);
    return docTypes;
  }

  function GetDocTypesDropdown() {
    const [docTypes, setDocTypes] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("docTypes")
        .orderBy("type", "asc")
        .onSnapshot((snapshot) => {
          const docList = snapshot.docs.map((doc) => ({
            id: doc.id,
            value: doc.data(),
            label: doc.data().type,
          }));
          setDocTypes(docList);
        });
      return unsubscribe;
    }, []);
    return docTypes;
  }

  function GetCriteria() {
    const [criterias, setCriterias] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("criterias")
        .onSnapshot((snapshot) => {
          const criteriasList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            value: doc.data(),
            label: doc.data().name,
          }));
          setCriterias(criteriasList);
        });
      return unsubscribe;
    }, []);
    return criterias;
  }

  async function returnDocTypes(category) {
    var docTypes = [];
    await companyRef
      .collection("docTypes")
      .where("category", "==", category)
      .get()
      .then((snapshot) => {
        const typeList = snapshot.docs.map((doc) => ({
          value: doc.data(),
          label: doc.data().type,
        }));
        docTypes = typeList;
      });
    return docTypes;
  }

  async function getDocById(docId) {
    var isoDoc = {};
    const docRef = companyRef.collection("documents").doc(docId);
    const doc = await docRef.get();
    if (!doc.exists) {
      return null;
    } else {
      isoDoc = {
        ...doc.data(),
        id: doc.id,
      };
    }
    return isoDoc;
  }

  async function getRevisionById(docId, revId) {
    var isoDoc = {};
    const docRef = companyRef
      .collection("documents")
      .doc(docId)
      .collection("revisions")
      .doc(revId);
    const doc = await docRef.get();
    if (!doc.exists) {
      return null;
    } else {
      isoDoc = {
        ...doc.data(),
        id: doc.id,
      };
    }
    return isoDoc;
  }

  // async function getDocLength(data) {
  //     return new Promise(async (res, rej) => {
  //         await companyRef.collection('docCatCount')
  //         .doc(data.category)
  //         .get().then(doc => {
  //             if(doc.exists) {
  //                 res(doc.data().count)
  //             }
  //             else {
  //                 res(0)
  //             }
  //         })
  //     })
  // }
  async function getDocLength(data) {
    var length = 0;
    await companyRef
      .collection("documents")
      .where("category", "==", data.category)
      .get()
      .then((snapshot) => {
        snapshot.docs.map((doc) => {
          length++;
        });
      });
    return length;
  }

  async function getRevisionLength(docId) {
    var length = 0;
    await companyRef
      .collection("documents")
      .doc(docId)
      .collection("revisions")
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          length = 0;
        } else {
          snapshot.docs.map((doc) => {
            length++;
          });
        }
      });
    return length;
  }

  async function returnDocParties(docId) {
    var parties = [];
    await companyRef
      .collection("documents")
      .doc(docId)
      .collection("approvals")
      .get()
      .then((snapshot) => {
        const partyList = snapshot.docs.map((doc) => ({
          value: doc.data(),
          label: `${doc.data().firstName} ${doc.data().lastName}`,
        }));
        parties = partyList;
      });
    return parties;
  }

  async function GetDocInterestedParties(docId) {
    const [parties, setParties] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("documents")
        .doc(docId)
        .collection("approvals")
        .onSnapshot((snapshot) => {
          const partyList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setParties(partyList);
        });
      return unsubscribe;
    }, []);
    return parties;
  }

  function GetRosterList() {
    const [roster, setRoster] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("rosters")
        .orderBy("firstName", "asc")
        .onSnapshot((snapshot) => {
          const rosterList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setRoster(rosterList);
        });
      return unsubscribe;
    }, []);
    return roster;
  }

  function GetSiteList() {
    const [sites, setSites] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("sites")
        .orderBy("name")
        .onSnapshot((snapshot) => {
          const siteList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            value: { ...doc.data(), id: doc.id },
            label: doc.data().name,
          }));
          setSites(siteList);
        });
      return unsubscribe;
    }, []);
    return sites;
  }

  function GetUserList() {
    const [userList, setUserList] = useState([]);

    useEffect(() => {
      const unsubscribe = db
        .collection("users")
        .where("companyId", "==", coRef)
        .where("rosterOnly", "==", false)
        .orderBy("firstName", "asc")
        .onSnapshot((snapshot) => {
          const userList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            label: `${doc.data().firstName} ${doc.data().lastName} - ${
              doc.data().email
            }`,
          }));
          setUserList(userList);
        });
      return unsubscribe;
    }, []);

    return userList;
  }

  function GetSubfolders(docId) {
    const [folders, setFolders] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("documents")
        .doc(docId)
        .collection("subfolders")
        .onSnapshot((snapshot) => {
          const folderList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setFolders(folderList);
        });
      return unsubscribe;
    }, [docId]);
    return folders;
  }

  function GetSubfoldersDropdown(docId) {
    const [folders, setFolders] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("documents")
        .doc(docId)
        .collection("subfolders")
        .orderBy("folderName")
        .onSnapshot((snapshot) => {
          const folderList = snapshot.docs.map((doc) => ({
            id: doc.id,
            value: { ...doc.data() },
            label: doc.data().folderName,
          }));
          setFolders(folderList);
        });
      return unsubscribe;
    }, [docId]);
    return folders;
  }

  function GetSupportingDocs(docId) {
    const [supDocs, setSupDocs] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("documents")
        .doc(docId)
        .collection("supportingDocs")
        .orderBy("fileName")
        .onSnapshot((snapshot) => {
          const docList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setSupDocs(docList);
        });
      return unsubscribe;
    }, [docId]);
    return supDocs;
  }

  function GetInterestedParties(docId, isRevision, revId) {
    const [parties, setParties] = useState([]);

    const docPath = isRevision
      ? companyRef
          .collection("documents")
          .doc(docId)
          .collection("revisions")
          .doc(revId)
          .collection("interestedParties")
      : companyRef.collection("documents").doc(docId).collection("approvals");

    useEffect(() => {
      const unsubscribe = docPath.onSnapshot((snapshot) => {
        const partyList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setParties(partyList);
      });
      return unsubscribe;
    }, [docId, isRevision, revId]);
    return parties;
  }

  function GetSubscribers(docId, isRevision, revId) {
    const [parties, setParties] = useState([]);

    const docPath = isRevision
      ? companyRef
          .collection("documents")
          .doc(docId)
          .collection("revisions")
          .doc(revId)
          .collection("subscribers")
      : companyRef.collection("documents").doc(docId).collection("subscribers");

    useEffect(() => {
      const unsubscribe = docPath.onSnapshot((snapshot) => {
        const partyList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setParties(partyList);
      });
      return unsubscribe;
    }, [docId, isRevision, revId]);
    return parties;
  }

  function GetRevisions(docId) {
    const [revisions, setRevisions] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("documents")
        .doc(docId)
        .collection("revisions")
        .orderBy("createdDate", "desc")
        .onSnapshot((snapshot) => {
          const revList = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          setRevisions(revList);
        });
      return unsubscribe;
    }, [docId]);
    return revisions;
  }

  function GetUnreadNotifications(userId) {
    const [notifications, setNotifications] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("users")
        .doc(userId)
        .collection("notifications")
        .where("read", "!=", true)
        .onSnapshot((snapshot) => {
          const notifList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setNotifications(notifList);
        });
      return unsubscribe;
    }, [userId]);
    return notifications;
  }

  function GetNotifications(userId) {
    const [notifications, setNotifications] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("users")
        .doc(userId)
        .collection("notifications")
        .orderBy("timestamp", "desc")
        .limit(50)
        .onSnapshot((snapshot) => {
          const notifList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setNotifications(notifList);
        });
      return unsubscribe;
    }, [userId]);
    return notifications;
  }

  function GetGroups() {
    const [groups, setGroups] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("groups")
        .orderBy("name", "asc")
        .onSnapshot((snapshot) => {
          const groupList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            value: doc.data(),
            label: doc.data().name,
          }));
          setGroups(groupList);
        });
      return unsubscribe;
    }, []);
    return groups;
  }

  function GetSubscriberGroups(docId) {
    const [groups, setGroups] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("documents")
        .doc(docId)
        .collection("subscriberGroups")
        .onSnapshot((snapshot) => {
          const groupList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            value: doc.data(),
            label: doc.data().name,
          }));
          setGroups(groupList);
        });
      return unsubscribe;
    }, [docId]);
    return groups;
  }

  function GetGroupMembers(groupId) {
    const [members, setMembers] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("groups")
        .doc(groupId)
        .collection("members")
        .onSnapshot((snapshot) => {
          const memberList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setMembers(memberList);
        });
      return unsubscribe;
    }, [groupId]);
    return members;
  }

  async function getUserGroups(userId) {
    let groupsArray = [];
    return db
      .collection("users")
      .doc(userId)
      .get()
      .then((doc) => {
        if (doc.data().groups) {
          groupsArray = doc.data().groups;
          return groupsArray;
        } else {
          return groupsArray;
        }
      });
  }

  function GetOverdueDocs() {
    const [documents, setDocuments] = useState([]);
    useEffect(() => {
      const unsubscribe = companyRef
        .collection("documents")
        .where("targetRevDate", "<=", moment(Date.now()).toDate())
        .where("obsolete", "==", false)
        .onSnapshot((snapshot) => {
          const docList = snapshot.docs.map((document) => ({
            id: document.id,
            ...document.data(),
          }));
          setDocuments(docList);
        });
      return unsubscribe;
    }, []);
    return documents;
  }

  function GetDocsForNextDays(days) {
    const [documents, setDocuments] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("documents")
        .where("obsolete", "==", false)
        .where("targetRevDate", ">=", moment(Date.now()).toDate())
        .where(
          "targetRevDate",
          "<=",
          moment(Date.now() + days * 86400000).toDate()
        )
        .onSnapshot((snapshot) => {
          const docList = snapshot.docs.map((document) => ({
            id: document.id,
            ...document.data(),
          }));
          setDocuments(docList);
        });
      return unsubscribe;
    }, [days]);
    return documents;
  }

  async function getUserFavoriteDocs(uid) {
    return await db
      .collection("users")
      .doc(uid)
      .get()
      .then((doc) => {
        return doc.data().favoriteDocs;
      });
  }

  async function getSingleDocument(docId) {
    return await companyRef
      .collection("documents")
      .doc(docId)
      .get()
      .then((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });
  }

  function GetEmployees() {
    const [employees, setEmployees] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("employees")
        .onSnapshot((snapshot) => {
          const employeeList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            label: `${doc.data().firstName} ${doc.data().lastName}`,
            value: { ...doc.data(), id: doc.id },
          }));
          setEmployees(employeeList);
        });
      return unsubscribe;
    }, []);
    return employees;
  }

  function GetSources() {
    const [sources, setSources] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("sources")
        .onSnapshot((snapshot) => {
          const sourceList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            label: `${doc.data().sourceName}`,
            value: { ...doc.data() },
          }));
          setSources(sourceList);
        });
      return unsubscribe;
    }, []);
    return sources;
  }

  function GetIsoAgencies() {
    const [agencies, setAgencies] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("isoAgencies")
        .orderBy("isoAgency", "asc")
        .onSnapshot((snapshot) => {
          const agencyList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            label: `${doc.data().isoAgency}`,
            value: { ...doc.data() },
          }));
          setAgencies(agencyList);
        });
      return unsubscribe;
    }, []);
    return agencies;
  }

  function GetIsoAgenciesPerClass(c) {
    const [agencies, setAgencies] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("isoAgencies")
        .where("isoClass", "==", c)
        .onSnapshot((snapshot) => {
          const agencyList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            label: `${doc.data().isoAgency}`,
            value: { ...doc.data() },
          }));
          setAgencies(agencyList);
        });
      return unsubscribe;
    }, [c]);
    return agencies;
  }

  async function getIsoAgenciesPerClassAsync(c) {
    const agencies = await companyRef
      .collection("isoAgencies")
      .where("isoClass", "==", c)
      .get()
      .then((snapshot) => {
        const agencyList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          label: `${doc.data().isoAgency}`,
          value: { ...doc.data() },
        }));
        return agencyList;
      });
    console.log(agencies);
    return agencies;
  }

  function GetPriorities() {
    const [priorities, setPriorities] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("prioritySettings")
        .orderBy("order")
        .onSnapshot((snapshot) => {
          const priList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            label: `${doc.data().name}`,
            value: doc.data(),
          }));
          setPriorities(priList);
        });
      return unsubscribe;
    }, []);
    return priorities;
  }

  function GetActions() {
    const [actions, setActions] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("actions")
        .orderBy("timestamp", "desc")

        .onSnapshot((snapshot) => {
          let actionList = [];
          snapshot.docs.map((doc) => {
            actionList.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          setActions(actionList);
        });
      return unsubscribe;
    }, []);
    return actions;
  }

  function GetActionsAsc() {
    const [actions, setActions] = useState([]);

    const thisYear = new Date().getFullYear();
    console.log(thisYear);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("actions")
        .where("year", "==", thisYear)
        .onSnapshot((snapshot) => {
          const actionList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setActions(actionList);
        });
      return unsubscribe;
    }, []);
    console.log(actions);
    return actions;
  }

  function GetActionAttachments(actionId) {
    const [attachments, setAttachments] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("actions")
        .doc(actionId)
        .collection("attachments")
        .onSnapshot((snapshot) => {
          const attachmentList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setAttachments(attachmentList);
        });
      return unsubscribe;
    }, [actionId]);
    return attachments;
  }

  function GetOverdueActions() {
    const [actions, setActions] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("actions")
        .where("targetDate", "<=", moment(Date.now()).toDate())
        .where("open", "==", true)
        .onSnapshot((snapshot) => {
          const actionList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setActions(actionList);
        });
      return unsubscribe;
    }, []);
    return actions;
  }

  function GetObsoleteDocs() {
    const [obDocs, setObDocs] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("documents")
        .where("obsolete", "==", true)
        .onSnapshot((snapshot) => {
          const docList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setObDocs(docList);
        });
      return unsubscribe;
    }, []);
    return obDocs;
  }

  // -------------------------------------------- SETTING DATA --------------------------------------------------------------------------------------------

  async function addDocument(data, length) {
    return new Promise(async (res, rej) => {
      var multiplier;
      if (data.category === "L1") {
        multiplier = 1;
      } else if (data.category === "L2") {
        multiplier = 100;
      } else if (data.category === "L3") {
        multiplier = 1000;
      } else if (data.category === "L4") {
        multiplier = 10000;
      } else if (data.category === "L5") {
        multiplier = 100000;
      } else if (data.category === "L6") {
        multiplier = 1000000;
      } else if (data.category === "L7") {
        multiplier = 10000000;
      } else if (data.category === "L8") {
        multiplier = 100000000;
      }
      var orderString = `${data.category}${length + 1}`;
      orderString = orderString.replace("L", "");
      const orderInt = parseInt(orderString);

      var docCodeString = `${data.category}${
        data.docType.value.code ? `-${data.docType.value.code}` : ""
      }${data.department.value.code ? `-${data.department.value.code}` : ""}-${
        length + 1
      }`;

      const groupsObj = data.groups;
      let groups = [];

      console.log(groupsObj);

      if (Array.isArray(groupsObj)) {
        groups = groupsObj.map((g) => g.id);
      } else {
        groups = [groupsObj.id];
      }

      await companyRef
        .collection("docCatCount")
        .doc(data.category)
        .set(
          {
            count: fieldValue.increment(1),
          },
          { merge: true }
        );

      await companyRef
        .collection("documents")
        .add({
          ...data,
          groups,
          docCode: docCodeString,
          order: orderInt * multiplier,
          pendingAcknowledge: 0,
          subscribers: 0,
          approvers: 0,
          pendingApproval: 0,
          originalDocument: data.docUrl,
          obsolete: false,
        })
        .then(async (doc) => {
          await groupsObj.forEach(async (g) => {
            await addGroupDataToDoc(doc.id, g);
          });
          await logAuditTrail(
            currentUser.uid,
            `Created a new Document: ${docCodeString}`
          );
          res(doc.id);
        });
    });
  }

  async function addGroupDataToDoc(docId, group) {
    return await companyRef
      .collection("documents")
      .doc(docId)
      .collection("groups")
      .doc(group.id)
      .set({
        ...group,
      })
      .then(async () => {
        await logAuditTrail(currentUser.uid, `Added group data to a document.`);
      });
  }

  async function editTextField(field, docId, text) {
    return companyRef
      .collection("documents")
      .doc(docId)
      .update({
        [field]: text,
      })
      .then(async (doc) => {
        await logAuditTrail(currentUser.uid, `Edited ${field} on a document`);
        return doc;
      })
      .catch((err) => {
        console.log("Error Saving to Database: ", err.message);
        return null;
      });
  }

  async function editSelectField(field, docId, value) {
    return companyRef
      .collection("documents")
      .doc(docId)
      .update({
        [field]: value,
      })
      .then(async (doc) => {
        await logAuditTrail(currentUser.uid, `Edited ${field} on a document`);
        return doc;
      })
      .catch((err) => {
        console.log("Error Saving to Database: ", err.message);
        return null;
      });
  }

  async function editTargetRevDate(newDate, docId) {
    const docCode = await companyRef
      .collection("documents")
      .doc(docId)
      .get()
      .then((doc) => {
        return doc.data().docCode;
      });

    return await companyRef
      .collection("documents")
      .doc(docId)
      .update({
        targetRevDate: newDate,
      })
      .then(async (doc) => {
        await logAuditTrail(
          currentUser.uid,
          `Changed Target Revision date on document: ${docCode}`
        );
        return doc;
      })
      .catch((err) => {
        console.log(err.message);
        return null;
      });
  }

  async function saveDocumentType(data) {
    return companyRef
      .collection("docTypes")
      .add({
        ...data,
      })
      .then(async (doc) => {
        await logAuditTrail(
          currentUser.uid,
          `Added a document type: ${data.category} - ${data.type}`
        );
        return doc;
      })
      .catch((error) => {
        console.log("Error: ", error);
        return null;
      });
  }

  async function savePartyToDoc(party, docId) {
    const docCode = await getDocCode(docId);

    await companyRef
      .collection("documents")
      .doc(docId)
      .update({
        pendingApproval: fieldValue.increment(1),
        approvers: fieldValue.increment(1),
      });

    return companyRef
      .collection("documents")
      .doc(docId)
      .collection("approvals")
      .doc(party.value.id)
      .set({ ...party.value, status: "Pending Email" })
      .then(async (doc) => {
        await logAuditTrail(
          currentUser.uid,
          `Added an approver to doc: ${docCode}`
        );
        return doc;
      })
      .catch((err) => {
        console.log("Error: ", err.message);
        return null;
      });
  }

  async function saveSubscriberToDoc(party, docId) {
    await companyRef
      .collection("documents")
      .doc(docId)
      .collection("subscribers")
      .doc(party.value.id)
      .set({ ...party.value, status: "Pending Email" })
      .then(async (doc) => {
        await updateDocTotalSubscribers(docId, 1);
        await logAuditTrail(
          currentUser.uid,
          `Added a subscriber to a document`
        );
        return doc;
      })
      .catch((err) => {
        console.log("Error: ", err.message);
        return null;
      });
  }

  async function updateDocTotalSubscribers(docId, val) {
    await companyRef
      .collection("documents")
      .doc(docId)
      .update({
        subscribers: fieldValue.increment(val),
      });
  }

  async function updateRevTotalSubscribers(docId, revId) {}

  async function savePartyToRevision(party, docId, revId) {
    const docCode = await getDocCode(docId);
    const docTitle = await companyRef
      .collection("documents")
      .doc(docId)
      .get()
      .then((d) => {
        return d.data().docName;
      });

    await companyRef
      .collection("documents")
      .doc(docId)
      .collection("revisions")
      .doc(revId)
      .update({
        numApprovers: fieldValue.increment(1),
      });

    await companyRef
      .collection("documents")
      .doc(docId)
      .collection("revisions")
      .doc(revId)
      .collection("interestedParties")
      .doc(party.value.uid ? party.value.uid : party.value.id)
      .set({ ...party.value, status: "Pending Email", docTitle })
      .then(async (doc) => {
        await logAuditTrail(
          currentUser.uid,
          `Added an interested party to document: ${docCode}`
        );
        return doc;
      })
      .catch((err) => {
        console.log("Error: ", err.message);
        return null;
      });
  }

  async function saveSubscriberToRevision(subscriber, docId, revId) {
    await companyRef
      .collection("documents")
      .doc(docId)
      .collection("revisions")
      .doc(revId)
      .collection("subscribers")
      .doc(subscriber.value.id)
      .set({ ...subscriber.value, status: "Pending Email" })
      .then((doc) => {
        return doc;
      })
      .catch((err) => {
        console.log("Error: ", err.message);
        return null;
      });
  }

  async function removePartyFromDoc(docId, partyId) {
    const docCode = await getDocCode(docId);

    await companyRef
      .collection("documents")
      .doc(docId)
      .update({
        approvers: fieldValue.increment(-1),
        pendingApproval: fieldValue.increment(-1),
      });

    return companyRef
      .collection("documents")
      .doc(docId)
      .collection("approvals")
      .doc(partyId)
      .delete()
      .then(async (res) => {
        await logAuditTrail(
          currentUser.uid,
          `Removed an approver from document: ${docCode}`
        );
        return res;
      })
      .catch((err) => {
        console.log("Error: ", err.message);
        return null;
      });
  }

  async function removeSubscriberFromDoc(docId, partyId) {
    await companyRef
      .collection("documents")
      .doc(docId)
      .collection("subscribers")
      .doc(partyId)
      .delete()
      .then(async (res) => {
        console.log("Success");
        await updateDocTotalSubscribers(docId, -1);
        return res;
      })
      .catch((err) => {
        console.log("Error: ", err.message);
        return null;
      });
  }

  async function removePartyFromRevision(docId, partyId, revId) {
    await companyRef
      .collection("documents")
      .doc(docId)
      .collection("revisions")
      .doc(revId)
      .update({
        approvers: fieldValue.increment(-1),
        pendingApproval: fieldValue.increment(-1),
      });

    await companyRef
      .collection("documents")
      .doc(docId)
      .collection("revisions")
      .doc(revId)
      .collection("interestedParties")
      .doc(partyId)
      .delete()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("Error: ", err.message);
        return null;
      });
  }

  async function removeSubscriberFromRevision(docId, partyId, revId) {
    await companyRef
      .collection("documents")
      .doc(docId)
      .collection("revisions")
      .doc(revId)
      .collection("subscribers")
      .doc(partyId)
      .delete()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("Error: ", err.message);
        return null;
      });
  }

  async function bulkEmailDocParties(docId, parties) {
    const docCode = await getDocCode(docId);

    var result;
    await parties.forEach((pty) => {
      companyRef
        .collection("documents")
        .doc(docId)
        .collection("approvalEmails")
        .add({
          ...pty,
        })
        .then(async (res) => {
          result = res;
          await companyRef
            .collection("documents")
            .doc(docId)
            .collection("approvals")
            .where("id", "==", pty.id)
            .get()
            .then((snap) => {
              snap.docs.map((d) => {
                d.ref.update({
                  status: "Email Sent",
                });
              });
            });
        })
        .catch((err) => {
          console.log(err.message);
          return null;
        });
    });
    await logAuditTrail(
      currentUser.uid,
      `Emailed Interested Parties for document: ${docCode}`
    );

    return result;
  }

  async function bulkEmailDocSubscribers(docId, parties) {
    var result;
    await parties.forEach((pty) => {
      companyRef
        .collection("documents")
        .doc(docId)
        .collection("subEmailLog")
        .add({
          ...pty,
        })
        .then((res) => {
          result = res;
        })
        .catch((err) => {
          console.log(err.message);
          return null;
        });
    });
    return result;
  }

  async function bulkEmailRevParties(docId, revId, parties) {
    const docCode = await getDocCode(docId);

    var result;
    await parties.forEach(async (pty) => {
      console.log(docId);
      console.log(pty);
      await companyRef
        .collection("documents")
        .doc(docId)
        .collection("revisions")
        .doc(revId)
        .collection("interestedParties")
        .where("id", "==", pty.id)
        .get()
        .then((snap) => {
          snap.docs.map((doc) => {
            doc.ref.update({
              status: "Email Sent",
            });
          });
        });

      await companyRef
        .collection("documents")
        .doc(docId)
        .collection("revisions")
        .doc(revId)
        .collection("emailLog")
        .add({
          ...pty,
        })
        .then((res) => {
          result = res;
        })
        .catch((err) => {
          console.log(err.message);
          return null;
        });
    });
    await logAuditTrail(
      currentUser.uid,
      `Emailed Interested Parties for document: ${docCode}`
    );
    return result;
  }

  async function bulkEmailRevSubscribers(docId, revId, parties) {
    var result;
    await parties.forEach((pty) => {
      companyRef
        .collection("documents")
        .doc(docId)
        .collection("revisions")
        .doc(revId)
        .collection("subEmailLog")
        .add({
          ...pty,
        })
        .then((res) => {
          result = res;
        })
        .catch((err) => {
          console.log(err.message);
          return null;
        });
    });
    return result;
  }

  async function saveRevision(docId, data, docCode, length) {
    console.log(data);
    return companyRef
      .collection("documents")
      .doc(docId)
      .collection("revisions")
      .add({
        ...data,
        docCode,
        order: length + 1,
        revCode: `${docCode}-${length + 1}`,
        numApproved: 0,
      })
      .then(async (doc) => {
        await logAuditTrail(
          currentUser.uid,
          `Added revision ${docCode}-${length + 1} to document: ${docCode}`
        );
        return doc;
      })
      .catch((err) => {
        console.log("Error saving to database: ", err.message);
        return null;
      });
  }

  async function addDepartment(data) {
    return companyRef
      .collection("departments")
      .add({
        ...data,
      })
      .then(async (doc) => {
        await logAuditTrail(
          currentUser.uid,
          `Created a new Department: ${data.name}`
        );
        return doc;
      })
      .catch((err) => {
        console.log("Error: ", err.message);
        return null;
      });
  }

  async function addRoster(roster) {
    const companyName = await getCompanyName();
    // Check if the user's email is already in use
    return await db
      .collection("users")
      .where("email", "==", roster.email)
      .get()
      .then(async (snapshot) => {
        if (snapshot.empty) {
          await companyRef
            .collection("rosters")
            .add({
              ...roster,
              userStatus: false,
              companyName,
            })
            .then(async (doc) => {
              await logAuditTrail(
                currentUser.uid,
                `Created a new Roster: ${roster.email}`
              );
              return doc;
            })
            .catch((err) => {
              console.log("Error: ", err.message);
              return null;
            });
        } else {
          throw {
            message: "This person is already an ISOMS user.",
          };
        }
      });
  }

  async function addSite(name) {
    return companyRef
      .collection("sites")
      .add({
        name,
      })
      .then(async (doc) => {
        await logAuditTrail(currentUser.uid, `Added a new Site: ${name}`);
        return doc;
      })
      .catch((err) => {
        console.log("Error: ", err.message);
        return null;
      });
  }

  async function approveDocument(docId, userId, approval) {
    const docCode = await getDocCode(docId);

    if (approval !== "Declined") {
      await companyRef
        .collection("documents")
        .doc(docId)
        .update({
          pendingApproval: fieldValue.increment(-1),
          approvedLog: fieldValue.arrayUnion(userId),
        });
    }

    return companyRef
      .collection("documents")
      .doc(docId)
      .collection("approvals")
      .doc(userId)
      .update({
        status: approval,
      })
      .then(async (doc) => {
        await logAuditTrail(currentUser.uid, `Approved Document: ${docCode}`);
        return doc;
      })
      .catch((err) => {
        console.log("Error approving Document: ", err.message);
        return null;
      });
  }

  async function acknowledgeDoc(docId, userId) {
    const docCode = await getDocCode(docId);

    return await companyRef
      .collection("documents")
      .doc(docId)
      .collection("interestedParties")
      .doc(userId)
      .set(
        {
          acknowledged: true,
          status: "Acknowledged",
        },
        { merge: true }
      )
      .then(async (doc) => {
        await logAuditTrail(
          currentUser.uid,
          `Acknowledged Document: ${docCode}`
        );
      });
  }

  async function approveRevision(docId, revId, userId, approval) {
    const docCode = await getDocCode(docId);
    if (approval === "Declined") {
      return await companyRef
        .collection("documents")
        .doc(docId)
        .collection("revisions")
        .doc(revId)
        .collection("interestedParties")
        .doc(userId)
        .update({
          status: approval,
        })
        .then(async (doc) => {
          await logAuditTrail(
            currentUser.uid,
            `Declined Revision for document: ${docCode}`
          );
          return doc;
        })
        .catch((err) => {
          console.log("Error approving Document: ", err.message);
          return null;
        });
    } else {
      await companyRef
        .collection("documents")
        .doc(docId)
        .collection("revisions")
        .doc(revId)
        .update({
          numApproved: fieldValue.increment(1),
        });

      return await companyRef
        .collection("documents")
        .doc(docId)
        .collection("revisions")
        .doc(revId)
        .collection("interestedParties")
        .doc(userId)
        .update({
          status: approval,
        })
        .then(async (doc) => {
          await logAuditTrail(
            currentUser.uid,
            `Approved Revision for document: ${docCode}`
          );
          return doc;
        })
        .catch((err) => {
          console.log("Error approving Document: ", err.message);
          return null;
        });
    }
  }

  async function addSubfolder(docId, data) {
    const docCode = await getDocCode(docId);
    return companyRef
      .collection("documents")
      .doc(docId)
      .collection("subfolders")
      .add({
        ...data,
      })
      .then(async (doc) => {
        await logAuditTrail(
          currentUser.uid,
          `Added a Sub-Folder for document: ${docCode}`
        );
        return doc;
      })
      .catch((err) => {
        console.log("Error Adding Subfolder: ", err.message);
        return null;
      });
  }

  async function removeSubfolder(docId, folderId) {
    const docCode = await getDocCode(docId);
    return companyRef
      .collection("documents")
      .doc(docId)
      .collection("subfolders")
      .doc(folderId)
      .delete()
      .then(async (doc) => {
        await logAuditTrail(
          currentUser.uid,
          `Removed a Sub-Folder for document: ${docCode}`
        );
        return doc;
      })
      .catch((err) => {
        console.log("Error Deleting Subfolder: ", err.message);
        return null;
      });
  }

  async function addSupportingDoc(docId, data, user) {
    const docCode = await getDocCode(docId);
    return companyRef
      .collection("documents")
      .doc(docId)
      .collection("supportingDocs")
      .add({
        ...data,
        folderId: data.folder ? data.folder.id : "",
        createdAt: new Date(),
        submittedBy: user,
        parentDocId: docId,
      })
      .then(async (doc) => {
        await logAuditTrail(
          currentUser.uid,
          `Added a supporting document for document: ${docCode}`
        );
        return doc;
      })
      .catch((err) => {
        console.log("Error Adding Supporting Document: ", err.message);
        return null;
      });
  }

  async function markNotificationAsRead(uid, notifId) {
    const notifDetails = await db
      .collection("users")
      .doc(uid)
      .collection("notifications")
      .doc(notifId)
      .get()
      .then((doc) => {
        return doc.data().text;
      });

    return db
      .collection("users")
      .doc(uid)
      .collection("notifications")
      .doc(notifId)
      .update({
        read: true,
      })
      .then(async (doc) => {
        await logAuditTrail(
          currentUser.uid,
          `Marked this Notification as Read: ${notifDetails}`
        );
      });
  }

  async function markNotificationAsUnread(uid, notifId) {
    const notifDetails = await db
      .collection("users")
      .doc(uid)
      .collection("notifications")
      .doc(notifId)
      .get()
      .then((doc) => {
        return doc.data().text;
      });

    return db
      .collection("users")
      .doc(uid)
      .collection("notifications")
      .doc(notifId)
      .update({
        read: false,
      })
      .then(async (doc) => {
        await logAuditTrail(
          currentUser.uid,
          `Marked this Notification as Unread: ${notifDetails}`
        );
      });
  }

  async function markAllNotificationsRead(uid) {
    let promiseArray = [];
    let batch = db.batch();

    await db
      .collection("users")
      .doc(uid)
      .collection("notifications")
      .where("read", "==", false)
      .get()
      .then(async (snapshot) => {
        if (snapshot.docs.length < 500) {
          // Do a batch
          snapshot.forEach((doc) => {
            const docRef = doc.ref;
            batch.update(docRef, {
              read: true,
            });
          });
          await batch.commit();
        } else {
          snapshot.forEach((doc) => {
            promiseArray.push(
              doc.ref.update({
                read: true,
              })
            );
          });
          await Promise.all(promiseArray);
        }
      });
    await logAuditTrail(currentUser.uid, `Marked All Notifications As Read`);
  }

  async function createGroup(name, memberArray) {
    const allowedMembers = memberArray.map((m) => m.value.id);
    let docId = "";
    await companyRef
      .collection("groups")
      .add({
        name,
        members: allowedMembers,
      })
      .then(async (doc) => {
        await logAuditTrail(currentUser.uid, `Created Group ${name}`);
        docId = doc.id;
      });

    await memberArray.forEach((member) => {
      companyRef
        .collection("groups")
        .doc(docId)
        .collection("members")
        .doc(member.value.uid)
        .set({
          ...member.value,
        });
    });

    await memberArray.forEach((member) => {
      db.collection("users")
        .doc(member.value.uid)
        .update({
          groups: fieldValue.arrayUnion(docId),
        });
    });
  }

  async function addSubscriberGroup(documentId, groupArray) {
    await groupArray.forEach(async (group) => {
      await group.members.forEach(async (m) => {
        const mData = await companyRef
          .collection("groups")
          .doc(group.id)
          .collection("members")
          .doc(m)
          .get()
          .then((doc) => {
            return doc.data();
          });

        await companyRef
          .collection("documents")
          .doc(documentId)
          .collection("subscribers")
          .doc(m.id)
          .set({
            ...mData,
            status: "Email Pending",
          });
      });

      await companyRef
        .collection("documents")
        .doc(documentId)
        .collection("subscriberGroups")
        .doc(group.id)
        .set({
          ...group,
          status: "Pending Email",
        });

      await companyRef
        .collection("documents")
        .doc(documentId)
        .update({
          groups: fieldValue.arrayUnion(group.id),
        });

      await logAuditTrail(
        currentUser.uid,
        `Added Members to Group ${group.name}`
      );
    });
  }

  async function removeSubscriberGroup(documentId, groupId) {
    const memberArray = await companyRef
      .collection("groups")
      .doc(groupId)
      .get()
      .then((doc) => {
        return doc.data().members;
      });

    await memberArray.forEach(async (m) => {
      await companyRef
        .collection("documents")
        .doc(documentId)
        .collection("subscribers")
        .doc(m)
        .delete();
    });

    await companyRef
      .collection("documents")
      .doc(documentId)
      .collection("subscriberGroups")
      .doc(groupId)
      .delete();

    await companyRef
      .collection("documents")
      .doc(documentId)
      .update({
        groups: fieldValue.arrayRemove(groupId),
      });

    await logAuditTrail(currentUser.uid, `Removed Members from Group`);
  }

  async function emailSubscriberGroup(documentId, group, groupId) {
    await group.members.forEach(async (m) => {
      const mData = await companyRef
        .collection("groups")
        .doc(groupId)
        .collection("members")
        .doc(m)
        .get()
        .then((doc) => {
          return doc.data();
        });

      await companyRef
        .collection("documents")
        .doc(documentId)
        .collection("subEmailLog")
        .add({
          email: mData.email,
          firstName: mData.firstName,
          id: mData.id,
          lastName: mData.lastName,
          status: "Pending Email",
        });
    });

    await companyRef
      .collection("documents")
      .doc(documentId)
      .collection("subscriberGroups")
      .doc(groupId)
      .update({
        status: "Emails Sent",
      });
  }

  async function removeMemberFromGroup(groupId, memberId) {
    return new Promise(async (resolve, reject) => {
      await companyRef
        .collection("documents")
        .where("groups", "array-contains", groupId)
        .get()
        .then(async (snapshot) => {
          console.log(snapshot.docs.length);
          await snapshot.docs.forEach((doc) => {
            doc.ref.collection("subscribers").doc(memberId).delete();
          });
        })
        .catch((err) => {
          reject(err);
        });

      await db
        .collection("users")
        .doc(memberId)
        .update({
          groups: fieldValue.arrayRemove(groupId),
        });

      await companyRef
        .collection("groups")
        .doc(groupId)
        .collection("members")
        .doc(memberId)
        .delete()
        .then(() => {
          db.collection("users")
            .doc(memberId)
            .update({
              groups: fieldValue.arrayRemove(groupId),
            });
        })
        .then(async () => {
          await logAuditTrail(
            currentUser.uid,
            `Removed Member from Group ${groupId}`
          );
          resolve();
        });
    });
  }

  async function addMembersToGroup(groupId, memberArray) {
    await memberArray.forEach(async (m) => {
      await companyRef
        .collection("groups")
        .doc(groupId)
        .update({
          members: fieldValue.arrayUnion(m.uid),
        });

      await companyRef
        .collection("groups")
        .doc(groupId)
        .collection("members")
        .doc(m.uid)
        .set({
          ...m,
        });

      await db
        .collection("users")
        .doc(m.uid)
        .update({
          groups: fieldValue.arrayUnion(groupId),
        });

      // Subscribe this new member to any documents that the group has access to
      await companyRef
        .collection("documents")
        .where("groups", "array-contains", groupId)
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((doc) => {
            doc.ref
              .collection("subscribers")
              .doc(m.uid)
              .set({
                ...m,
              });
          });
        });

      await logAuditTrail(currentUser.uid, `Added Members to Group ${groupId}`);
      window.location.reload();
    });
  }

  async function convertRosterToUser(rosterId, uid) {
    return await db
      .collection("users")
      .doc(uid)
      .update({
        rosterOnly: false,
      })
      .then(() => {
        companyRef
          .collection("rosters")
          .doc(rosterId)
          .update({
            userStatus: true,
          })
          .then(async (doc) => {
            await logAuditTrail(
              currentUser.uid,
              `Converted a roster to a user. RosterID: ${rosterId}`
            );
          });
      });
  }

  async function addDocToFavorites(docId, uid) {
    const docCode = await getDocCode(docId);
    await companyRef
      .collection("documents")
      .doc(docId)
      .update({
        favoritedBy: fieldValue.arrayUnion(uid),
      });

    return await db
      .collection("users")
      .doc(uid)
      .update({
        favoriteDocs: fieldValue.arrayUnion(docId),
      })
      .then(async () => {
        await logAuditTrail(currentUser.uid, `Favorited Doc ${docCode}`);
      });
  }

  async function removeDocFromFavorites(docId, uid) {
    const docCode = await getDocCode(docId);
    await companyRef
      .collection("documents")
      .doc(docId)
      .update({
        favoritedBy: fieldValue.arrayRemove(uid),
      });

    return await db
      .collection("users")
      .doc(uid)
      .update({
        favoriteDocs: fieldValue.arrayRemove(docId),
      })
      .then(async () => {
        await logAuditTrail(currentUser.uid, `Un-Favorited Doc ${docCode}`);
      });
  }

  // async function createEmployee(data) {
  //     return companyRef.collection('employees')
  //     .add({
  //         ...data
  //     }).then(async (doc) => {
  //         await companyRef.collection('rosters').add({
  //             ...data,
  //             employeeId: doc.id
  //         })
  //     })
  // }

  function createSource(data) {
    return companyRef
      .collection("sources")
      .add({
        ...data,
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Created a New Source ${data.sourceName}`
        );
      });
  }

  async function createAction(data) {
    return new Promise(async (res, rej) => {
      const year = data.year.toString();

      const currentActionCounter = await companyRef
        .collection("currentActionCounter")
        .doc(year)
        .get()
        .then((doc) => {
          if (doc.exists) return doc.data().actions;
          else return 0;
        });

      const newActionCounter = currentActionCounter + 1;
      await companyRef
        .collection("actions")
        .add({
          ...data,
          actionNumber: `000${newActionCounter}/${year}`,
          open: true,
        })
        .then(async (doc) => {
          await logAuditTrail(
            currentUser.uid,
            `Created a New Action: 000${newActionCounter}/${year}`
          );

          if (currentActionCounter === 0) {
            await companyRef
              .collection("currentActionCounter")
              .doc(year)
              .set({
                actions: currentActionCounter + 1,
              });
          } else {
            await companyRef
              .collection("currentActionCounter")
              .doc(year)
              .update({
                actions: fieldValue.increment(1),
              });
          }
          res(doc.id);
        });
    });
  }

  function updatePriority(id, days) {
    return companyRef
      .collection("prioritySettings")
      .doc(id)
      .update({
        days,
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Changed ${id} priority to ${days} days`
        );
      });
  }

  async function addActionAttachment(actionId, fileUrl, fileName) {
    const action = await companyRef
      .collection("actions")
      .doc(actionId)
      .get()
      .then((doc) => {
        return doc.data();
      });

    return companyRef
      .collection("actions")
      .doc(actionId)
      .collection("attachments")
      .add({
        fileUrl,
        timestamp: new Date(),
        fileName,
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Added an action attachment: ${fileName} to Action: ${action.actionNumber}`
        );
      });
  }

  function deleteActionAttachment(actionId, attachmentId) {
    return companyRef
      .collection("actions")
      .doc(actionId)
      .collection("attachments")
      .doc(attachmentId)
      .delete()
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Deleted an action attachment. ID: ${actionId}`
        );
      });
  }

  async function closeOutAction(actionId, data) {
    const actionNumber = await getActionNumber(actionId);
    return companyRef
      .collection("actions")
      .doc(actionId)
      .update({
        ...data,
        open: false,
        closeOutDate: new Date(),
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Closed out action: ${actionNumber}`
        );
      });
  }

  async function reOpenAction(actionId) {
    const actionNumber = await getActionNumber(actionId);

    return companyRef
      .collection("actions")
      .doc(actionId)
      .update({
        open: true,
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Re-Opened Action: ${actionNumber}`
        );
      });
  }

  async function obsoleteAction(actionId) {
    const actionNumber = await companyRef
      .collection("actions")
      .doc(actionId)
      .get()
      .then((doc) => {
        return doc.data().actionNumber;
      });

    return await companyRef
      .collection("actions")
      .doc(actionId)
      .update({
        obsolete: true,
        obsoleteTimestamp: new Date(),
        obsoletedBy: currentUser.uid,
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Made Action Obsolete: ${actionNumber}`
        );
      });
  }
  async function restoreAction(actionId) {
    const actionNumber = await companyRef
      .collection("actions")
      .doc(actionId)
      .get()
      .then((doc) => {
        return doc.data().actionNumber;
      });

    return await companyRef
      .collection("actions")
      .doc(actionId)
      .update({
        obsolete: false,
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Restored Obsolete Action: ${actionNumber}`
        );
      });
  }

  async function obsoleteDoc(collection, docId) {
    if ((collection = "documents")) {
      return await obsoleteDocument(docId);
    }

    const identifier = await companyRef
      .collection(collection)
      .doc(docId)
      .get()
      .then((doc) => {
        // if(collection === 'documents') {
        //     return doc.data().docCode
        // }
        if (collection === "actions") {
          return doc.data().actionNumber;
        } else if (collection === "otps") {
          return doc.data().otpNumber;
        } else {
          return doc.id;
        }
      });

    return companyRef
      .collection(collection)
      .doc(docId)
      .update({
        obsolete: true,
        obsoleteTimestamp: new Date(),
        obsoletedBy: currentUser.uid,
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Obsoleted Item from ${collection}: ${identifier}`
        );
      });
  }

  async function obsoleteDocument(docId) {
    const document = await companyRef
      .collection("documents")
      .doc(docId)
      .get()
      .then((doc) => {
        return doc.data();
      });

    return await companyRef
      .collection("documents")
      .doc(docId)
      .update({
        obsolete: true,
        obsoleteTimestamp: new Date(),
        obsoletedBy: currentUser.uid,
      })
      .then(async () => {
        await companyRef
          .collection("docCatCount")
          .doc(document.category)
          .update({
            count: fieldValue.increment(-1),
          });
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Obsoleted Document ${document.docCode}`
        );
      });
  }

  async function restoreObsoleteDocument(docId) {
    const document = await companyRef
      .collection("documents")
      .doc(docId)
      .get()
      .then((doc) => {
        return doc.data();
      });

    return await companyRef
      .collection("documents")
      .doc(docId)
      .update({
        obsolete: false,
        obsoleteTimestamp: new Date(),
        obsoletedBy: currentUser.uid,
      })
      .then(async () => {
        await companyRef
          .collection("docCatCount")
          .doc(document.category)
          .update({
            count: fieldValue.increment(1),
          });
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Restored Obsoleted Document ${document.docCode}`
        );
      });
  }

  async function restoreObsolete(collection, docId) {
    if ((collection = "documents")) {
      return await restoreObsoleteDocument(docId);
    }

    const identifier = await companyRef
      .collection(collection)
      .doc(docId)
      .get()
      .then((doc) => {
        if (collection === "documents") {
          return doc.data().docCode;
        } else if (collection === "actions") {
          return doc.data().actionNumber;
        } else if (collection === "otps") {
          return doc.data().otpNumber;
        } else {
          return doc.id;
        }
      });

    return companyRef
      .collection(collection)
      .doc(docId)
      .update({
        obsolete: false,
        obsoleteTimestamp: new Date(),
        obsoletedBy: currentUser.uid,
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Restored Obsoleted Item. Collection ${collection}: ${identifier}`
        );
      });
  }

  // ------------------ New Refactored Interested Parties ------------------
  async function addDynamicIntParty(collection, docId, party) {
    const identifier = await companyRef
      .collection(collection)
      .doc(docId)
      .get()
      .then((doc) => {
        if (collection === "documents") {
          return doc.data().docCode;
        } else if (collection === "actions") {
          return doc.data().actionNumber;
        } else if (collection === "otps") {
          return doc.data().otpNumber;
        } else {
          return doc.id;
        }
      });

    return companyRef
      .collection(collection)
      .doc(docId)
      .collection("interestedParties")
      .doc(party.uid)
      .set(
        {
          timestamp: new Date(),
          ...party,
          sent: false,
        },
        { merge: true }
      )
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Added An Interested Party. Collection ${collection}: ${identifier}. Party: ${party.email}`
        );
        await companyRef
          .collection(collection)
          .doc(docId)
          .update({
            interestedParties: fieldValue.arrayUnion(party.uid),
          });
      });
  }

  async function removeDynamicIntParty(collection, docId, party) {
    const identifier = await companyRef
      .collection(collection)
      .doc(docId)
      .get()
      .then((doc) => {
        if (collection === "documents") {
          return doc.data().docCode;
        } else if (collection === "actions") {
          return doc.data().actionNumber;
        } else if (collection === "otps") {
          return doc.data().otpNumber;
        } else {
          return doc.id;
        }
      });

    return companyRef
      .collection(collection)
      .doc(docId)
      .collection("interestedParties")
      .doc(party.uid)
      .delete()
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Removed An Interested Party. Collection ${collection}: ${identifier}. Party: ${party.email}`
        );
      });
  }

  function GetDynamicIntParties(collection, docId) {
    const [parties, setParties] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection(collection)
        .doc(docId)
        .collection("interestedParties")
        .orderBy("firstName", "asc")
        .onSnapshot((snapshot) => {
          const partyList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setParties(partyList);
        });
      return unsubscribe;
    }, [collection, docId]);
    return parties;
  }

  async function addDynamicIntGroup(collection, docId, group) {
    console.log(group);
    return new Promise(async (resolve, reject) => {
      for (let i = 0; i < group.members.length; i++) {
        await companyRef
          .collection(collection)
          .doc(docId)
          .update({
            interestedParties: fieldValue.arrayUnion(group.members[i]),
          });
      }

      await companyRef
        .collection(collection)
        .doc(docId)
        .collection("intPartyGroups")
        .doc(group.id)
        .set({
          ...group,
        })
        .then(async () => {
          await logAuditTrail(
            currentUser.uid,
            `Added a Group to Interested Parties. Collection ${collection}. ID: ${docId}. Group: ${group}`
          );
        })
        .catch((err) => reject(err));
      resolve();
    });
  }

  async function removeDynamicIntGroup(collection, docId, group) {
    const identifier = await companyRef
      .collection(collection)
      .doc(docId)
      .get()
      .then((doc) => {
        if (collection === "documents") {
          return doc.data().docCode;
        } else if (collection === "actions") {
          return doc.data().actionNumber;
        } else if (collection === "otps") {
          return doc.data().otpNumber;
        } else {
          return doc.id;
        }
      });

    return new Promise(async (resolve, reject) => {
      // await companyRef.collection(collection)
      // .doc(docId)
      // .update({
      //     groups: fieldValue.arrayRemove(group.id)
      // }).catch((err) => reject(err))

      for (let i = 0; i < group.members.length; i++) {
        await companyRef
          .collection(collection)
          .doc(docId)
          .update({
            interestedParties: fieldValue.arrayRemove(group.members[i]),
          });
      }

      await companyRef
        .collection(collection)
        .doc(docId)
        .collection("intPartyGroups")
        .doc(group.id)
        .delete()
        .then(async () => {
          await logAuditTrail(
            currentUser.uid,
            `Removed a Group from Interested Parties. Collection ${collection}: ${identifier}. Group: ${group.name}`
          );
        })
        .catch((err) => reject(err));

      resolve();
    });
  }

  function GetDynamicIntPartyGroups(collection, docId) {
    const [groups, setGroups] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection(collection)
        .doc(docId)
        .collection("intPartyGroups")
        .onSnapshot((snapshot) => {
          const groupList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setGroups(groupList);
        });
      return unsubscribe;
    }, [collection, docId]);
    return groups;
  }

  async function emailDynamicIndividualParty(collection, doc, party, isGroup) {
    console.log(doc);
    const identifier = await companyRef
      .collection(collection)
      .doc(doc.id)
      .get()
      .then((d) => {
        if (collection === "documents") {
          return d.data().docCode;
        } else if (collection === "actions") {
          return d.data() ? d.data().actionNumber : d.actionNumber;
        } else if (collection === "otps") {
          return d.data().otpNumber;
        } else {
          return d.id;
        }
      });

    return await companyRef
      .collection(collection)
      .doc(doc.id)
      .collection("emailLog")
      .add({
        ...party,
        ...doc,
        timestamp: new Date(),
      })
      .then(async () => {
        if (isGroup) {
          return;
        } else {
          await companyRef
            .collection(collection)
            .doc(doc.id)
            .collection("interestedParties")
            .doc(party.uid)
            .update({
              sent: true,
              acknowledged: false,
            })
            .then(async () => {
              await logAuditTrail(
                currentUser.uid,
                `Emailed Interested Parties in ${collection}: ${identifier}. Party: ${party.email}`
              );
            });
        }
      });
  }

  async function emailDynamicGroup(collection, doc, groupId) {
    const identifier = await companyRef
      .collection(collection)
      .doc(doc.id)
      .get()
      .then((d) => {
        if (collection === "documents") {
          return d.data().docCode;
        } else if (collection === "actions") {
          return d.data().actionNumber;
        } else if (collection === "otps") {
          return d.data().otpNumber;
        } else {
          return d.id;
        }
      });

    await companyRef
      .collection("groups")
      .doc(groupId)
      .collection("members")
      .get()
      .then(async (snapshot) => {
        if (!snapshot.empty) {
          snapshot.docs.forEach(async (snap) => {
            await emailDynamicIndividualParty(collection, doc, snap.data());
            await logAuditTrail(
              currentUser.uid,
              `Emailed Interested Party Group. Collection ${collection}: ${identifier}.`
            );
          });
        } else {
          return;
        }
      });
  }

  async function emailAllPartiesDynamic(collection, doc) {
    const identifier = await companyRef
      .collection(collection)
      .doc(doc.id)
      .get()
      .then((d) => {
        if (collection === "documents") {
          return d.data().docCode;
        } else if (collection === "actions") {
          return d.data().actionNumber;
        } else if (collection === "otps") {
          return d.data().otpNumber;
        } else {
          return d.id;
        }
      });

    await companyRef
      .collection(collection)
      .doc(doc.id)
      .collection("interestedParties")
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          snapshot.docs.forEach(async (snap) => {
            await emailDynamicIndividualParty(collection, doc, snap.data());
            await logAuditTrail(
              currentUser.uid,
              `Emailed All Interested Parties. Collection ${collection}: ${identifier}.`
            );
          });
        } else {
          return;
        }
      });

    // await companyRef.collection(collection)
    // .doc(doc.id)
    // .get()
    // .then(async (d) => {
    //     if(d.data().groups) {
    //         await d.data().groups.forEach(async (grp) => {
    //             await emailDynamicGroup(collection, doc, grp)
    //         })
    //     }
    //     else {
    //         return
    //     }
    // })
  }

  // ---------------------- Documents Submitted ----------------------

  function editSupportingDoc(docId, supDocId, data) {
    return companyRef
      .collection("documents")
      .doc(docId)
      .collection("supportingDocs")
      .doc(supDocId)
      .update({
        ...data,
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Edited a supporting doc. ID: ${docId}.`
        );
      });
  }

  function editEmployeeSupportingDoc(docId, supDocId, data) {
    return companyRef
      .collection("employees")
      .doc(docId)
      .collection("supportingDocs")
      .doc(supDocId)
      .update({
        ...data,
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Edited a supporting doc. ID: ${docId}.`
        );
      });
  }

  // ---------------------- New Refactored Universal Functions ----------------------

  function deleteDynamicDocument(collection, docId) {
    return companyRef
      .collection(collection)
      .doc(docId)
      .delete()
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Deleted from ${collection}. ID: ${docId}.`
        );
      });
  }

  // ---------------------- Management Functions ----------------------

  function GetCompanies() {
    const [companies, setCompanies] = useState([]);
    useEffect(() => {
      const unsubscribe = db.collection("companies").onSnapshot((snapshot) => {
        const compList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          label: doc.data().companyName,
          value: { ...doc.data(), id: doc.id },
        }));
        setCompanies(compList);
      });
      return unsubscribe;
    }, []);
    return companies;
  }

  function GetAllUsers() {
    const [users, setUsers] = useState([]);
    useEffect(() => {
      const unsubscribe = db.collection("users").onSnapshot((snapshot) => {
        const userList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          value: { ...doc.data(), id: doc.id },
          label: `${doc.data().firstName} ${doc.data().lastName} (${
            doc.data().email
          })`,
        }));
        setUsers(userList);
      });
      return unsubscribe;
    }, []);
    return users;
  }

  function GetUsersForCompany(coId) {
    const [users, setUsers] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("users")
        .where("companyId", "==", coId)
        .onSnapshot((snapshot) => {
          const userList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            value: { ...doc.data(), id: doc.id },
            label: `${doc.data().firstName} ${doc.data().lastName} (${
              doc.data().email
            })`,
          }));
          setUsers(userList);
        });
      return unsubscribe;
    }, [coId]);
    return users;
  }

  async function updateUserCompany(userId, data) {
    return await db.collection("users").doc(userId).update({
      companyId: data.id,
      companyName: data.companyName,
    });
  }

  async function makeUserAdmin(userId) {
    return await db.collection("users").doc(userId).update({
      companyAdmin: true,
    });
  }

  async function revokeAdmin(userId) {
    return await db.collection("users").doc(userId).update({
      companyAdmin: false,
    });
  }

  async function addCompany(companyName) {
    return await db.collection("companies").add({
      companyName,
      modules: {
        docManager: false,
        actionManager: false,
        otpManager: false,
        trainingManager: false,
        riskManager: false,
        auditManager: false,
        mocManager: false,
        customerManager: false,
      },
    });
  }

  async function createNewSuperUser(data) {
    const companyName = await getCompanyNameById(data.companyId);
    await db
      .collection("users")
      .where("email", "==", data.email)
      .get()
      .then(async (snap) => {
        if (snap.empty) {
          return await db.collection("superUserRequests").add({
            ...data,
            companyName,
          });
        } else {
          throw { message: "This email is already in use. " };
        }
      });
  }

  async function updateCompanyModules(coId, modules) {
    return db.collection("companies").doc(coId).update({
      modules,
    });
  }

  async function checkCompanyTrainingRiskAsync() {
    const trainingRiskRef = db
      .collection("companies")
      .doc(currentUser.companyId)
      .collection("trainingRisk");
    const trainingRisk = await trainingRiskRef.get();
    if (trainingRisk.empty) {
      await createTrainingRiskProfile(currentUser.companyId);
      await updateCurrentUser();
    } else return;
  }

  async function createTrainingRiskProfile(coId) {
    return new Promise(async (res, rej) => {
      const trainingRiskRef = db
        .collection("companies")
        .doc(coId)
        .collection("trainingRisk");

      await trainingRiskRef.doc("low").set({
        days: "90",
        order: 1,
        name: "Low",
      });

      await trainingRiskRef.doc("medium").set({
        days: "30",
        order: 2,
        name: "Medium",
      });

      await trainingRiskRef.doc("high").set({
        days: "7",
        order: 3,
        name: "High",
      });
    });
  }

  async function getCompanyModules(coId) {
    let modules = {};
    await db
      .collection("companies")
      .doc(coId)
      .get()
      .then((doc) => {
        modules = doc.data().modules;
      });
    return modules;
  }

  function addTemplate(template) {
    return db.collection("templates").add({
      ...template,
    });
  }

  function deleteTemplate(templateId) {
    return db.collection("templates").doc(templateId).delete();
  }

  function GetTemplates() {
    const [templates, setTemplates] = useState([]);
    useEffect(() => {
      const unsubscribe = db.collection("templates").onSnapshot((snapshot) => {
        const tempList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTemplates(tempList);
      });
      return unsubscribe;
    }, []);
    return templates;
  }

  function replaceUserManual(url) {
    return db.collection("manual").doc("manual").set({
      url,
    });
  }

  async function retrieveUserManual() {
    return await db
      .collection("manual")
      .doc("manual")
      .get()
      .then((doc) => {
        return doc.data().url;
      });
  }

  async function retrieveLicense() {
    return await db
      .collection("manual")
      .doc("license")
      .get()
      .then((doc) => {
        return doc.data().url;
      });
  }

  function MgmtGetCoDepartments(coId) {
    const [depts, setDepts] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("companies")
        .doc(coId)
        .collection("departments")
        .onSnapshot((snapshot) => {
          const deptList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            value: { ...doc.data() },
            label: doc.data().name,
          }));
          setDepts(deptList);
        });
      return unsubscribe;
    }, []);
    return depts;
  }

  function mgmtCreateDepartment(coId, data) {
    return db
      .collection("companies")
      .doc(coId)
      .collection("departments")
      .add({
        ...data,
      });
  }

  function mgmtCreateSite(coId, data) {
    return db
      .collection("companies")
      .doc(coId)
      .collection("sites")
      .add({
        ...data,
      });
  }

  function mgmtCreateDocTypes(coId, data) {
    return db
      .collection("companies")
      .doc(coId)
      .collection("docTypes")
      .add({
        ...data,
      });
  }

  function mgmtCreateSource(coId, data) {
    return db
      .collection("companies")
      .doc(coId)
      .collection("sources")
      .add({
        ...data,
      });
  }

  function mgmtCreateCode(coId, data) {
    return db
      .collection("companies")
      .doc(coId)
      .collection("isoCodes")
      .add({
        ...data,
      });
  }

  function mgmtCreateClass(coId, data) {
    return db
      .collection("companies")
      .doc(coId)
      .collection("isoClasses")
      .add({
        ...data,
      });
  }

  function mgmtCreateIsoAgency(coId, data) {
    return db
      .collection("companies")
      .doc(coId)
      .collection("isoAgencies")
      .add({
        ...data,
      });
  }

  async function mgmtCreateRoster(coId, roster) {
    const companyName = await mgmtGetCompanyName(coId);
    // Check if the user's email is already in use
    return await db
      .collection("users")
      .where("email", "==", roster.email)
      .get()
      .then(async (snapshot) => {
        if (snapshot.empty) {
          await db
            .collection("companies")
            .doc(coId)
            .collection("rosters")
            .add({
              ...roster,
              userStatus: false,
              companyName,
            })
            .then(async (doc) => {
              await logAuditTrail(
                currentUser.uid,
                `Created a new Roster: ${roster.email}`
              );
              return doc;
            })
            .catch((err) => {
              console.log("Error: ", err.message);
              return null;
            });
        } else {
          throw {
            message: "This email is already in use.",
          };
        }
      });
  }

  async function mgmtGetCompanyName(coId) {
    return await db
      .collection("companies")
      .doc(coId)
      .get()
      .then((doc) => {
        return doc.data().companyName;
      });
  }

  async function mgmtUpdatePriority(coId, pryId, days) {
    return await db
      .collection("companies")
      .doc(coId)
      .collection("prioritySettings")
      .doc(pryId)
      .update({
        days,
      });
  }

  async function mgmtCreateAction(coId, data) {
    return new Promise(async (res, rej) => {
      const year = data.year.toString();
      const parsedYear = parseInt(year);

      const currentActionCounter = await db
        .collection("companies")
        .doc(coId)
        .collection("currentActionCounter")
        .doc(year)
        .get()
        .then((doc) => {
          if (doc.exists) return doc.data().actions;
          else return 0;
        });

      await db
        .collection("companies")
        .doc(coId)
        .collection("actions")
        .add({
          ...data,
          year: parsedYear,
          addedFromBackend: true,
        })
        .then(async (doc) => {
          await db
            .collection("companies")
            .doc(coId)
            .collection("currentActionCounter")
            .doc(year)
            .set(
              {
                actions: fieldValue.increment(1),
              },
              { merge: true }
            );
          res(doc);
        });
    });
  }

  async function mgmtAddActionAttachment(coId, actionId, fileUrl, fileName) {
    return await db
      .collection("companies")
      .doc(coId)
      .collection("actions")
      .doc(actionId)
      .collection("attachments")
      .add({
        fileUrl,
        timestamp: new Date(),
        fileName,
      });
  }

  async function mgmtUpdateAction(coId, actionId, data) {
    return await db
      .collection("companies")
      .doc(coId)
      .collection("actions")
      .doc(actionId)
      .update({
        ...data,
      });
  }

  async function mgmtGetDocLength(coId, category) {
    const length = await db
      .collection("companies")
      .doc(coId)
      .collection("docCatType")
      .doc(category)
      .get()
      .then((doc) => {
        if (doc.exists) {
          return doc.data().count;
        } else return 0;
      });
    return length;
  }

  async function mgmtCreateRevision(coId, docId, data) {
    return await db
      .collection("companies")
      .doc(coId)
      .collection("documents")
      .doc(docId)
      .collection("revisions")
      .add({
        ...data,
      });
  }

  async function mgmtCreateDoc(coId, data) {
    return new Promise(async (res, rej) => {
      const length = await mgmtGetDocLength(coId, data.category);
      var multiplier;
      if (data.category === "L1") {
        multiplier = 1;
      } else if (data.category === "L2") {
        multiplier = 10;
      } else if (data.category === "L3") {
        multiplier = 100;
      } else if (data.category === "L4") {
        multiplier = 1000;
      } else if (data.category === "L5") {
        multiplier = 10000;
      } else if (data.category === "L6") {
        multiplier = 100000;
      } else if (data.category === "L7") {
        multiplier = 1000000;
      } else if (data.category === "L8") {
        multiplier = 10000000;
      }
      var orderString = `${data.category}${length + 1}`;
      orderString = orderString.replace("L", "");
      const orderInt = parseInt(orderString);

      await db
        .collection("companies")
        .doc(coId)
        .collection("docCatCount")
        .doc(data.category)
        .set(
          {
            count: fieldValue.increment(1),
          },
          { merge: true }
        );

      await db
        .collection("companies")
        .doc(coId)
        .collection("documents")
        .add({
          ...data,
          order: orderInt * multiplier,
          obsolete: false,
        })
        .then((doc) => {
          res(doc.id);
        })
        .catch((err) => {
          rej(err.message);
        });
    });
  }

  async function mgmtAddActionIntParty(coId, actionId, data) {
    return new Promise(async (res, rej) => {
      if (data.partyArray.length > 0) {
        for (let i = 0; i < data.partyArray.length; i++) {
          console.log(data.partyArray[i]);
          await db
            .collection("companies")
            .doc(coId)
            .collection("actions")
            .doc(actionId)
            .collection("interestedParties")
            .doc(data.partyArray[i].value.uid)
            .set({
              ...data.partyArray[i].value,
            });
        }
        res();
      }
    });
  }

  async function mgmtAddDocIntParty(coId, docId, data) {
    return new Promise(async (res, rej) => {
      if (data.partyArray.length > 0) {
        for (let i = 0; i < data.partyArray.length; i++) {
          console.log(data.partyArray[i]);
          await db
            .collection("companies")
            .doc(coId)
            .collection("documents")
            .doc(docId)
            .collection("interestedParties")
            .doc(data.partyArray[i].value.uid)
            .set({
              ...data.partyArray[i].value,
            });
        }
        res();
      }
    });
  }

  function MgmtGetSites(coId) {
    const [sites, setSites] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("companies")
        .doc(coId)
        .collection("sites")
        .onSnapshot((snapshot) => {
          const siteList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            value: { ...doc.data(), label: doc.data().name },
            label: doc.data().name,
          }));
          setSites(siteList);
        });
      return unsubscribe;
    }, []);
    return sites;
  }

  function MgmtGetDocTypes(coId) {
    const [docTypes, setDocTypes] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("companies")
        .doc(coId)
        .collection("docTypes")
        .onSnapshot((snapshot) => {
          const typeList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            value: { ...doc.data(), label: doc.data().type },
            label: doc.data().type,
          }));
          setDocTypes(typeList);
        });
      return unsubscribe;
    }, []);
    return docTypes;
  }

  function MgmtGetSources(coId) {
    const [sources, setSources] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("companies")
        .doc(coId)
        .collection("sources")
        .onSnapshot((snapshot) => {
          const sourceList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            value: doc.data(),
            label: doc.data().sourceName,
          }));
          setSources(sourceList);
        });
      return unsubscribe;
    }, []);
    return sources;
  }

  function MgmtGetIsoClasses(coId) {
    const [classes, setClasses] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("companies")
        .doc(coId)
        .collection("isoClasses")
        .onSnapshot((snapshot) => {
          const classList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            value: doc.data(),
            label: `${doc.data().code} ${doc.data().standard}`,
          }));
          setClasses(classList);
        });
      return unsubscribe;
    }, []);
    return classes;
  }

  function MgmtGetIsoCodes(coId) {
    const [codes, setCodes] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("companies")
        .doc(coId)
        .collection("isoCodes")
        .onSnapshot((snapshot) => {
          const codeList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            value: { ...doc.data() },
            label: doc.data().code,
          }));
          setCodes(codeList);
        });
      return unsubscribe;
    }, []);
    return codes;
  }

  function MgmtGetIsoAgencies(coId) {
    const [agencies, setAgencies] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("companies")
        .doc(coId)
        .collection("isoAgencies")
        .onSnapshot((snapshot) => {
          const agencyList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            value: { ...doc.data() },
            label: doc.data().isoAgency,
          }));
          setAgencies(agencyList);
        });
      return unsubscribe;
    }, []);
    return agencies;
  }

  function MgmtGetRosters(coId) {
    const [rosters, setRosters] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("companies")
        .doc(coId)
        .collection("rosters")
        .where("uid", "!=", null)
        .onSnapshot((snapshot) => {
          const rosterList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            value: { ...doc.data() },
            label: `${doc.data().firstName} ${doc.data().lastName} - (${
              doc.data().email
            })`,
          }));
          setRosters(rosterList);
        });
      return unsubscribe;
    }, []);
    return rosters;
  }

  function MgmtGetPriorities(coId) {
    const [priorities, setPriorities] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("companies")
        .doc(coId)
        .collection("prioritySettings")
        .orderBy("order", "asc")
        .onSnapshot((snapshot) => {
          const priorityList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            value: doc.data().name,
            label: doc.data().name,
          }));
          setPriorities(priorityList);
        });
      return unsubscribe;
    }, []);
    return priorities;
  }

  function MgmtGetActions(coId) {
    const [actions, setActions] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("companies")
        .doc(coId)
        .collection("actions")
        .orderBy("timestamp", "desc")
        .onSnapshot((snapshot) => {
          const actionList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setActions(actionList);
        });
      return unsubscribe;
    }, []);
    return actions;
  }

  function MgmtGetDocs(coId) {
    const [documents, setDocuments] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("companies")
        .doc(coId)
        .collection("documents")
        .orderBy("createdDate", "desc")
        .onSnapshot((snapshot) => {
          const docList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setDocuments(docList);
        });
      return unsubscribe;
    }, []);
    return documents;
  }

  function MgmtGetActionIntParties(coId, actionId) {
    const [intParties, setIntParties] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("companies")
        .doc(coId)
        .collection("actions")
        .doc(actionId)
        .collection("interestedParties")
        .orderBy("firstName", "asc")
        .onSnapshot((snapshot) => {
          const partyList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            value: doc.data(),
            label: `${doc.data().firstName} ${doc.data().lastName}`,
          }));
          setIntParties(partyList);
        });
      return unsubscribe;
    }, []);
    return intParties;
  }

  function MgmtGetDocIntParties(coId, docId) {
    const [intParties, setIntParties] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("companies")
        .doc(coId)
        .collection("documents")
        .doc(docId)
        .collection("interestedParties")
        .orderBy("firstName", "asc")
        .onSnapshot((snapshot) => {
          const partyList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            value: doc.data(),
            label: `${doc.data().firstName} ${doc.data().lastName}`,
          }));
          setIntParties(partyList);
        });
      return unsubscribe;
    }, []);
    return intParties;
  }

  function MgmtGetDocRevisions(coId, docId) {
    const [revisions, setRevisions] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("companies")
        .doc(coId)
        .collection("documents")
        .doc(docId)
        .collection("revisions")
        .onSnapshot((snapshot) => {
          const revList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setRevisions(revList);
        });
      return unsubscribe;
    }, []);
    return revisions;
  }

  function MgmtGetSupportingDocs(coId, docId) {
    const [supDocs, setSupDocs] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("companies")
        .doc(coId)
        .collection("documents")
        .doc(docId)
        .collection("supportingDocs")
        .onSnapshot((snapshot) => {
          const docList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setSupDocs(docList);
        });
      return unsubscribe;
    }, []);
    return supDocs;
  }

  function MgmtGetSubFolders(coId, docId) {
    const [folders, setFolders] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("companies")
        .doc(coId)
        .collection("documents")
        .doc(docId)
        .collection("subfolders")
        .onSnapshot((snapshot) => {
          const folderList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            value: doc.data(),
            label: doc.data().folderName,
          }));
          setFolders(folderList);
        });
      return unsubscribe;
    }, []);
    return folders;
  }

  async function mgmtCreateSubfolder(coId, docId, data) {
    return await db
      .collection("companies")
      .doc(coId)
      .collection("documents")
      .doc(docId)
      .collection("subfolders")
      .add({
        ...data,
      });
  }

  async function mgmtCreateSupDoc(coId, docId, data) {
    return await db
      .collection("companies")
      .doc(coId)
      .collection("documents")
      .doc(docId)
      .collection("supportingDocs")
      .add({
        ...data,
      });
  }

  function MgmtGetActionAttachments(coId, actionId) {
    const [files, setFiles] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("companies")
        .doc(coId)
        .collection("actions")
        .doc(actionId)
        .collection("attachments")
        .onSnapshot((snapshot) => {
          const fileList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setFiles(fileList);
        });
      return unsubscribe;
    }, []);
    return files;
  }

  async function mgmtGetDocCatLength(data) {
    return new Promise(async (res, rej) => {
      const docLength = await db
        .collection("companies")
        .doc(data.coId)
        .collection("documents")
        .where("category", "==", data.category)
        .get()
        .then((snap) => {
          if (!snap.empty) {
            return snap.docs.length;
          } else {
            return 0;
          }
        });
      res(docLength);
    });
  }

  async function createSharedFolder(folder) {
    return companyRef.collection("shared-folders").add({
      ...folder,
      count: 0,
      dateUpdated: new Date(),
    });
  }

  async function addSharedDocument(url, fileName, folderId) {
    const folderRef = await companyRef
      .collection("shared-folders")
      .doc(folderId)
      .get()
      .then((doc) => doc.ref);

    folderRef.update({
      count: fieldValue.increment(1),
    });

    folderRef.collection("documents").add({
      url,
      fileName,
      dateCreated: new Date(),
      createdBy: `${currentUser.firstName} ${currentUser.lastName} - ${currentUser.email}`,
    });
  }

  function GetSharedFolderDocs(folderId) {
    const [documents, setDocuments] = useState([]);
    useEffect(() => {
      const unsubscribe = companyRef
        .collection("shared-folders")
        .doc(folderId)
        .collection("documents")
        .onSnapshot((snapshot) => {
          const docList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setDocuments(docList);
        });
      return unsubscribe;
    }, []);
    return documents;
  }

  function GetSharedFolders() {
    const [folders, setFolders] = useState([]);
    useEffect(() => {
      const unsubscribe = companyRef
        .collection("shared-folders")
        .onSnapshot((snapshot) => {
          const folderList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setFolders(folderList);
        });
      return unsubscribe;
    }, []);
    return folders;
  }

  async function getSharedFolder(id) {
    return await companyRef
      .collection("shared-folders")
      .doc(id)
      .get()
      .then((snap) => ({ ...snap.data() }));
  }

  // ----------------------- OTPs --------------------------------

  /*
   * function to retrieve OTPs from database
   * @param 'state' = Boolean - true|false
   */
  function GetOTPs(state) {
    const [otps, setOTPs] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("otps")
        .where("obsolete", "==", state)
        .orderBy("otpNumber", "desc")
        .onSnapshot((snapshot) => {
          const otpList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setOTPs(otpList);
        });
      return unsubscribe;
    }, []);
    return otps;
  }

  function GetOTPSearch() {
    const [otps, setOtps] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("otps")
        .orderBy("timestamp", "desc")
        .onSnapshot((snapshot) => {
          const otpsList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setOtps(otpsList);
        });
      return unsubscribe;
    }, []);
    return otps;
  }

  async function CreateOTP(data) {
    return new Promise(async (res, rej) => {
      const otpCounterRef = companyRef
        .collection("currentOtpCounter")
        .doc("currentOtp");

      const currentOtpCounter = await otpCounterRef.get().then((doc) => {
        if (!doc.exists) {
          return 0;
        } else return doc.data().otps;
      });

      await companyRef
        .collection("otps")
        .add({
          ...data,
          otpNumber: currentOtpCounter + 1,
        })
        .then(async (doc) => {
          await logAuditTrail(
            currentUser.uid,
            `Created a new OTP. OTP Number: ${currentOtpCounter + 1}.`
          );
          await otpCounterRef.set({
            otps: currentOtpCounter + 1,
          });
          res(doc.id);
        });
    });
  }

  async function AddOTPTarget(otpId, data, user) {
    return new Promise(async (res, rej) => {
      const otpRef = companyRef.collection("otps").doc(otpId);

      const targetCount = await otpRef.get().then((doc) => {
        if (doc.data().targetCount) return doc.data().targetCount;
        else return 0;
      });

      const otpData = await otpRef.get().then((doc) => {
        return { ...doc.data() };
      });

      await otpRef
        .collection("targets")
        .add({
          ...data,
          targetNo: `${data.otpNumber}.${targetCount + 1}`,
          otpId: otpId ? otpId : "",
          createdAt: new Date(),
          submittedBy: user,
          otp: { ...otpData },
        })
        .then(async (doc) => {
          //increment the "targetCount" everytime a target is added
          var ref = companyRef.collection("otps").doc(otpId);
          await ref.update({
            targetCount: firebase.firestore.FieldValue.increment(1),
          });
          await companyRef
            .collection("otpTargets")
            .doc(doc.id)
            .set({
              ...data,
              targetNo: `${data.otpNumber}.${targetCount + 1}`,
              otpId: otpId ? otpId : "",
              createdAt: new Date(),
              submittedBy: user,
              otp: { ...otpData },
            });
          await logAuditTrail(
            currentUser.uid,
            `Created a new Target. Target Number: ${data.otpNumber}.${
              targetCount + 1
            }.`
          );
          res(doc.id);
        });
    });
  }

  function GetOTPTargets(state, otpId) {
    const [targets, setTargets] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("otps")
        .doc(otpId)
        .collection("targets")
        .where("obsolete", "==", state)
        .orderBy("targetNo", "asc")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setTargets(data);
        });
      return unsubscribe;
    }, []);
    return targets;
  }

  function addCriteria(data) {
    return companyRef
      .collection("criterias")
      .add({
        ...data,
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Created a new Criteria: ${data.name}`
        );
      });
  }

  function updateCriteria(critId, data) {
    return companyRef
      .collection("criterias")
      .doc(critId)
      .update({
        ...data,
      })
      .then(async () => {
        await logAuditTrail(currentUser.uid, `Edited a Criteria: ${data.name}`);
      });
  }

  function deleteCriteria(critId) {
    return companyRef
      .collection("criterias")
      .doc(critId)
      .delete()
      .then(async () => {
        await logAuditTrail(currentUser.uid, `Deleted a Criteria: ${critId}`);
      });
  }

  function GetAllOtpTargets() {
    const [targets, setTargets] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("otpTargets")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setTargets(data);
        });
      return unsubscribe;
    }, []);
    return targets;
  }

  async function getAllTargets() {
    let targets = [];
    await companyRef
      .collection("otps")
      .get()
      .then(async (snap) => {
        if (!snap.empty) {
          for (let i = 0; i < snap.docs.length; i++) {
            await snap.docs[i].ref
              .collection("targets")
              .get()
              .then((snap2) => {
                snap2.docs.map((d) => {
                  targets.push({
                    ...d.data(),
                    id: d.id,
                  });
                });
              });
          }
        }
      });
    return targets;
  }

  async function addProgram(otpId, target, data) {
    const userData = await getUserData();
    const targetRef = companyRef
      .collection("otps")
      .doc(otpId)
      .collection("targets")
      .doc(target.id);

    const programTarget = parseFloat(data.targetNum);

    const intervalDays = parseInt(data.measurementInterval);
    Date.prototype.addDays = function (days) {
      var date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
    let date = new Date();
    const measurementDueDate = date.addDays(intervalDays);

    const programCount = await targetRef.get().then((doc) => {
      if (doc.data().programCount) return doc.data().programCount;
      else return 0;
    });

    return await targetRef
      .collection("programs")
      .add({
        ...data,
        open: true,
        createdAt: new Date(),
        createdBy: {
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
        },
        programNo: `${target.targetNo}.${programCount + 1}`,
        measurementDueDate,
        intervalDays,
        programTarget,
        otpV2: true,
      })
      .then(async (doc) => {
        await companyRef
          .collection("otps")
          .doc(otpId)
          .update({
            programCount: fieldValue.increment(1),
          });
        await targetRef.update({
          programCount: fieldValue.increment(1),
        });
        await logAuditTrail(
          currentUser.uid,
          `Created a Program. Program No: ${target.targetNo}.${
            programCount + 1
          }`
        );
        await companyRef
          .collection("otpPrograms")
          .doc(doc.id)
          .set({
            ...data,
            targetId: target.id,
            target,
            otpId,
          });
      });
  }

  function GetProgramsForTarget(otpId, targetId) {
    const [programs, setPrograms] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("otps")
        .doc(otpId)
        .collection("targets")
        .doc(targetId)
        .collection("programs")
        .orderBy("programNo", "asc")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setPrograms(data);
        });
      return unsubscribe;
    }, [targetId]);
    return programs;
  }

  function GetPrograms() {
    const [programs, setPrograms] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("otpPrograms")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setPrograms(data);
        });
      return unsubscribe;
    }, []);
    return programs;
  }

  function GetAllPrograms() {
    const [programs, setPrograms] = useState([]);

    useEffect(() => {
      const unsubscribe = db
        .collectionGroup("programs")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            ref: doc.ref,
          }));
          setPrograms(data);
        });
      return unsubscribe;
    }, []);
    return programs;
  }

  async function fixPrograms(data) {
    return await data.ref.update({
      proFixed: true,
      closer: {
        firstName: data.closer.firstName,
        lastName: data.closer.lastName,
        email: data.closer.email,
        id: data.closer.id,
      },
      responsible: {
        firstName: data.responsible.firstName,
        lastName: data.responsible.lastName,
        email: data.responsible.email,
        id: data.responsible.id,
      },
      createdBy: {
        firstName: data.createdBy.firstName,
        lastName: data.createdBy.lastName,
        email: data.createdBy.email,
      },
    });
  }

  function GetOverduePrograms() {
    const [programs, setPrograms] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("otpPrograms")
        .where("targetDate", "<", new Date())
        .where("open", "==", true)
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setPrograms(data);
        });
      return unsubscribe;
    }, []);
    return programs;
  }

  async function updateOTP(otpId, data) {
    return await companyRef
      .collection("otps")
      .doc(otpId)
      .update({
        ...data,
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Updated an Otp. OTP No: ${data.otpNumber}`
        );
      });
  }

  async function updateTarget(otpId, targetId, data) {
    const target = await companyRef
      .collection("otps")
      .doc(otpId)
      .collection("targets")
      .doc(targetId)
      .get()
      .then((doc) => {
        return doc.data();
      });

    return companyRef
      .collection("otps")
      .doc(otpId)
      .collection("targets")
      .doc(targetId)
      .update({
        ...data,
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Updated a Target. Target No: ${target.targetNo}`
        );
      });
  }

  async function obsoleteTarget(otpId, targetId) {
    const target = await companyRef
      .collection("otps")
      .doc(otpId)
      .collection("targets")
      .doc(targetId)
      .get()
      .then((doc) => {
        return doc.data();
      });

    return companyRef
      .collection("otps")
      .doc(otpId)
      .collection("targets")
      .doc(targetId)
      .update({
        obsolete: true,
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Obsoleted a Target. Target No: ${target.targetNo}`
        );
      });
  }

  async function updateProgram(otpId, targetId, programId, data) {
    const targetRef = companyRef
      .collection("otps")
      .doc(otpId)
      .collection("targets")
      .doc(targetId);

    const programRef = targetRef.collection("programs").doc(programId);

    const program = await programRef.get().then((doc) => {
      return doc.data();
    });

    return await programRef
      .update({
        ...data,
        otpV2: true,
        programTarget: parseFloat(data.targetNum),
        intervalDays: parseInt(data.intervalDays),
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Edited a Program. Program No: ${program.programNo}`
        );
      });
  }

  async function obsoleteProgram(otpId, targetId, programId) {
    const programRef = companyRef
      .collection("otps")
      .doc(otpId)
      .collection("targets")
      .doc(targetId)
      .collection("programs")
      .doc(programId);

    const program = await programRef.get().then((doc) => {
      return doc.data();
    });

    return await programRef
      .update({
        obsolete: true,
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Obsoleted a Program. Program No: ${program.programNo}`
        );
      });
  }

  async function closeProgram(otpId, targetId, programId) {
    const programRef = companyRef
      .collection("otps")
      .doc(otpId)
      .collection("targets")
      .doc(targetId)
      .collection("programs")
      .doc(programId);

    const program = await programRef.get().then((doc) => {
      return doc.data();
    });

    // Set the denormalized collection as well
    await companyRef.collection("otpPrograms").doc(programId).update({
      open: false,
    });

    await companyRef
      .collection("otps")
      .doc(otpId)
      .update({
        closedPrograms: fieldValue.increment(1),
      });

    await companyRef
      .collection("otps")
      .doc(otpId)
      .collection("targets")
      .doc(targetId)
      .update({
        closedCount: fieldValue.increment(1),
      });

    return programRef
      .update({
        open: false,
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Closed a Program. Program No: ${program.programNo}`
        );
      });
  }

  async function addProgramAttachment(
    otpId,
    targetId,
    programId,
    url,
    fileName
  ) {
    const otp = await companyRef
      .collection("otps")
      .doc(otpId)
      .get()
      .then((doc) => {
        return doc.data();
      });

    return companyRef
      .collection("otps")
      .doc(otpId)
      .collection("targets")
      .doc(targetId)
      .collection("programs")
      .doc(programId)
      .collection("attachments")
      .add({
        url,
        timestamp: new Date(),
        fileName,
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Added a program attachment to Objective: ${otp.otpNumber}`
        );
      });
  }

  async function deleteProgramAttachment(
    otpId,
    targetId,
    programId,
    attachmentId
  ) {
    const otp = await companyRef
      .collection("otps")
      .doc(otpId)
      .get()
      .then((doc) => {
        return doc.data();
      });

    return companyRef
      .collection("otps")
      .doc(otpId)
      .collection("targets")
      .doc(targetId)
      .collection("programs")
      .doc(programId)
      .collection("attachments")
      .doc(attachmentId)
      .delete()
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Deleted a program attachment on Objective: ${otp.otpNumber}`
        );
      });
  }

  function GetProgramAttachments(otpId, targetId, programId) {
    const [attachments, setAttachments] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("otps")
        .doc(otpId)
        .collection("targets")
        .doc(targetId)
        .collection("programs")
        .doc(programId)
        .collection("attachments")
        .orderBy("timestamp", "desc")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setAttachments(data);
        });
      return unsubscribe;
    }, [otpId, targetId, programId]);
    return attachments;
  }

  async function getSingleOtp(otpId) {
    return await companyRef
      .collection("otps")
      .doc(otpId)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
  }

  async function getSingleTarget(otpId, targetId) {
    return await companyRef
      .collection("otps")
      .doc(otpId)
      .collection("targets")
      .doc(targetId)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
  }

  async function getSingleProgram(otpId, targetId, programId) {
    return await companyRef
      .collection("otps")
      .doc(otpId)
      .collection("targets")
      .doc(targetId)
      .collection("programs")
      .doc(programId)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
  }

  async function addProgramMeasurement(otpId, targetId, programId, data) {
    const program = await companyRef
      .collection("otps")
      .doc(otpId)
      .collection("targets")
      .doc(targetId)
      .collection("programs")
      .doc(programId)
      .get()
      .then((doc) => {
        return doc.data();
      });

    const user = await db
      .collection("users")
      .doc(currentUser.uid)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          uid: doc.id,
        };
      });

    const measurement = parseFloat(data.measurementData);
    console.log(measurement);

    Date.prototype.addDays = function (days) {
      let thisDate = new Date();
      thisDate.setDate(thisDate.getDate() + days);
      return thisDate;
    };

    const today = new Date();
    const nextDueDate = today.addDays(program.intervalDays);

    console.log(targetId);
    console.log(programId);

    await companyRef
      .collection("otps")
      .doc(otpId)
      .collection("targets")
      .doc(targetId)
      .collection("programs")
      .doc(programId)
      .update({
        currentProgress: fieldValue.increment(measurement),
        measurementDueDate: nextDueDate,
      })
      .catch((err) => {
        console.log(err.message);
      });

    return await companyRef
      .collection("otps")
      .doc(otpId)
      .collection("targets")
      .doc(targetId)
      .collection("programs")
      .doc(programId)
      .collection("measurements")
      .add({
        ...data,
        timestamp: new Date(),
        createdBy: user,
        measurement,
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Recorded a Measurement on Program: ${program.programNo}`
        );
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  async function editProgramMeasurement(
    otpId,
    targetId,
    programId,
    measurementId,
    data
  ) {
    const measurementRef = await companyRef
      .collection("otps")
      .doc(otpId)
      .collection("targets")
      .doc(targetId)
      .collection("programs")
      .doc(programId)
      .collection("measurements")
      .doc(measurementId)
      .get()
      .then((doc) => doc.ref);

    const programRef = await companyRef
      .collection("otps")
      .doc(otpId)
      .collection("targets")
      .doc(targetId)
      .collection("programs")
      .doc(programId)
      .get()
      .then((doc) => doc.ref);

    const measurementBefore = await measurementRef
      .get()
      .then((doc) => doc.data().measurement);
    const currentProgress = await programRef
      .get()
      .then((doc) => doc.data().currentProgress);
    const measurement = parseFloat(data.measurementData);
    const newProgress =
      parseFloat(currentProgress) - parseFloat(measurementBefore) + measurement;

    console.log(
      `measurementBefore: ${measurementBefore}\n`,
      `currentProgress: ${currentProgress}\n`,
      `measurement: ${measurement}\n`,
      `newProgress: ${newProgress}\n`
    );

    await companyRef
      .collection("otps")
      .doc(otpId)
      .collection("targets")
      .doc(targetId)
      .collection("programs")
      .doc(programId)
      .update({
        currentProgress: newProgress,
      })
      .catch((err) => {
        console.log(err.message);
      });

    return await companyRef
      .collection("otps")
      .doc(otpId)
      .collection("targets")
      .doc(targetId)
      .collection("programs")
      .doc(programId)
      .collection("measurements")
      .doc(measurementId)
      .update({
        ...data,
        measurement,
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  function GetProgramMeasurements(otpId, targetId, programId) {
    const [records, setRecords] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("otps")
        .doc(otpId)
        .collection("targets")
        .doc(targetId)
        .collection("programs")
        .doc(programId)
        .collection("measurements")
        .orderBy("timestamp", "desc")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setRecords(data);
        });
      return unsubscribe;
    }, [otpId, targetId, programId]);
    return records;
  }

  async function createOtpMetric(data) {
    return await companyRef
      .collection("otpMetrics")
      .add({
        ...data,
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Created OTP Measurement Metric: ${data.metricName} (${data.metricUnit})`
        );
      });
  }

  async function updateOtpMetric(metricId, data) {
    return await companyRef
      .collection("otpMetrics")
      .doc(metricId)
      .update({
        ...data,
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Updated OTP Measurement Metric: ${data.metricName} (${data.metricUnit})`
        );
      });
  }

  async function reOpenProgram(otpId, targetId, programId) {
    const program = await companyRef
      .collection("otps")
      .doc(otpId)
      .collection("targets")
      .doc(targetId)
      .collection("programs")
      .doc(programId)
      .get()
      .then((doc) => {
        return doc.data();
      });

    await companyRef
      .collection("otps")
      .doc(otpId)
      .update({
        closedPrograms: fieldValue.increment(-1),
      });

    await companyRef
      .collection("otps")
      .doc(otpId)
      .collection("targets")
      .doc(targetId)
      .update({
        closedCount: fieldValue.increment(-1),
      });

    return await companyRef
      .collection("otps")
      .doc(otpId)
      .collection("targets")
      .doc(targetId)
      .collection("programs")
      .doc(programId)
      .update({
        open: true,
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Re-Opened Program: ${program.programNo}`
        );
      });
  }

  function GetOtpMetrics() {
    const [metrics, setMetrics] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("otpMetrics")
        .orderBy("metricName", "asc")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            label: `${doc.data().metricName} (${doc.data().metricUnit})`,
            value: { ...doc.data(), id: doc.id },
          }));
          setMetrics(data);
        });
      return unsubscribe;
    }, []);
    return metrics;
  }

  // ----------------------- Actions ------------------------------

  async function getActionNumber(actionId) {
    return await companyRef
      .collection("actions")
      .doc(actionId)
      .get()
      .then((doc) => {
        return doc.data().actionNumber;
      });
  }

  function updateSource(sourceId, data) {
    return companyRef
      .collection("sources")
      .doc(sourceId)
      .update({
        ...data,
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Updated a Source: ${data.sourceName}`
        );
      });
  }

  function deleteSource(sourceId) {
    return companyRef
      .collection("sources")
      .doc(sourceId)
      .delete()
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Deleted a Source. Source ID:${sourceId}`
        );
      });
  }

  function shareEmailAttachment(data, actionId) {
    return companyRef
      .collection("actions")
      .doc(actionId)
      .collection("shareLog")
      .add({
        ...data,
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Emailed Action Attachments. Action ID:${actionId}`
        );
      });
  }

  function updateAction(actionId, data) {
    return companyRef
      .collection("actions")
      .doc(actionId)
      .update({
        ...data,
      })
      .then(async () => {
        await logAuditTrail(currentUser.uid, `Updated Action:${actionId}`);
      });
  }

  // ----------------------- User Profile ------------------------------
  function GetUserProfile(userId) {
    const [profile, setProfile] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("users")
        .doc(userId)
        .get()
        .then((doc) => {
          const userProfile = { ...doc.data(), id: doc.id };
          setProfile(userProfile);
        });
      return unsubscribe;
    }, []);
    return profile;
  }

  function changeProfilePhoto(userId, photo) {
    return db
      .collection("users")
      .doc(userId)
      .update({
        profilePhoto: photo,
      })
      .then(async () => {
        await logAuditTrail(currentUser.uid, `Changed their profile picture.`);
      });
  }

  function getUserData() {
    return db
      .collection("users")
      .doc(currentUser.uid)
      .get()
      .then((doc) => {
        return doc.data();
      });
  }

  // ---------------------- Firebase Functions Directly Called ----------------------

  // Write client side callable function here. Instructions: https://firebase.google.com/docs/functions/callable?authuser=0#call_the_function
  async function generateDocRegisterPdf() {
    const docRegisterPdfRequest = functions.httpsCallable(
      "docRegisterPdfRequest"
    );
    await docRegisterPdfRequest({ companyId: coRef })
      .then((result) => {
        // TODO: Return the pdf Document Url
        console.log(result);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function testCallFunction() {
    const callTest = functions.httpsCallable("callTest");
    return await callTest({ companyId: coRef })
      .then((result) => {
        console.log(result);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // ----------------- PDF Reports ----------------------

  // TODO: Move all these to the PdfFunctions.js file

  async function getCompanyLogo() {
    return await companyRef.get().then((doc) => {
      if (doc.data().logo) return doc.data().logo;
      else return logo;
    });
  }

  async function getDocsSubmittedPDF(docId) {
    return await documentsSubmittedPdf(companyRef, docId).then(async () => {
      await logAuditTrail(currentUser.uid, `Generated a Docs Submitted PDF`);
    });
  }

  async function getDocRegisterPdf(docData) {
    return await docRegisterPdf(companyRef, docData).then(async () => {
      await logAuditTrail(currentUser.uid, `Generated a Doc Register PDF`);
    });
  }

  async function getDocReport(filteredDocs) {
    let docsArray = filteredDocs;

    const newArray = await Promise.all(
      docsArray.map(async (d) => {
        const revs = await getRevisionsForDoc(d.id);
        if (revs === null) {
          return d;
        } else {
          return {
            ...d,
            revisions: revs,
          };
        }
      })
    );
    return newArray;
  }

  function getRevisionsForDoc(docId) {
    return companyRef
      .collection("documents")
      .doc(docId)
      .collection("revisions")
      .get()
      .then((snap) => {
        if (snap.empty) {
          return null;
        } else {
          const revList = snap.docs.map((rev) => ({
            ...rev.data(),
            id: rev.id,
          }));
          return revList;
        }
      });
  }

  async function retrieveAllDocsFiltered(filters) {
    const docsCollection = companyRef.collection("documents");
    let query = docsCollection.where("obsolete", "==", false);

    for (let key in filters) {
      console.log(filters[key].value);
      if (filters[key].value) {
        query = query.where(filters[key].fieldName, "==", filters[key].value);
      }
    }

    return await query
      .orderBy("order")
      .get()
      .then((snap) => {
        if (!snap.empty) {
          const documents = snap.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          return documents;
        } else {
          return null;
        }
      });
  }

  async function ceateEmployeePdf(employee, modules) {
    const pdf = new jsPDF();

    var img = new Image();
    img.src = await getCompanyLogo();

    pdf.addImage(
      img,
      "PNG",
      80,
      10,
      pdf.canvas.width * 0.3,
      pdf.canvas.height * 0.15
    );

    pdf.setFontSize(18);
    pdf.text("Personal Infomation", 75, 60);

    pdf.setFontSize(12);
    pdf.text(`${employee.firstName} ${employee.lastName}`, 18, 70);
    pdf.text(`Email: ${employee.email}`, 18, 80);
    pdf.text(`ID Number: ${employee.employeeId}`, 18, 90);
    pdf.text(`Contact Number: ${employee.contactNumber}`, 18, 100);
    pdf.text(`Race: ${employee.race}`, 18, 110);

    pdf.setFontSize(18);
    pdf.text("Company Related Information", 60, 120);

    pdf.setFontSize(12);
    pdf.text(`Company Name: ${employee.manager.companyName}`, 18, 130);
    pdf.text(`Department: ${employee.department.name}`, 18, 140);
    pdf.text(`Employee Number: ${employee.employeeNumber}`, 18, 150);
    pdf.text(`Employee Type: ${employee.employeeType}`, 18, 160);

    let occupationBodyArray = [];

    employee.occupation.map((o) => {
      occupationBodyArray.push([`${o.title}`]);
    });

    pdf.setFontSize(18);
    pdf.text("Occupation", 85, 170);

    pdf.autoTable({
      head: [["Title"]],
      body: occupationBodyArray,
      margin: { top: 180 },
    });

    let competencieBodyArray = [];

    modules.map((m) => {
      competencieBodyArray.push([
        `${m.name}`,
        `${m.compLevel}`,
        `${
          m.value.assessment.comment && parseInt(m.compLevel) > 0
            ? m.value.assessment.comment
            : "No Comment"
        }`,
      ]);
    });

    pdf.addPage();

    pdf.text("Training Competency", 75, 20);

    pdf.autoTable({
      head: [["Name", "Competencie Level", "Facilitator Feedback"]],
      body: competencieBodyArray,
      margin: { top: 30 },
    });

    return pdf.save(`${employee.firstName}_${employee.lastName}.pdf`);
  }

  async function createDocsPdf(filters) {
    const filteredDocs = await retrieveAllDocsFiltered(filters);

    const data = await getDocReport(filteredDocs);
    if (!data) {
      throw {
        message: "No Documents to retrieve.",
      };
    }
    const doc = new jsPDF();

    var img = new Image();
    img.src = await getCompanyLogo();

    doc.addImage(
      img,
      "PNG",
      80,
      10,
      doc.canvas.width * 0.3,
      doc.canvas.height * 0.15
    );
    doc.text("Document Report", 80, 60);
    data.map((dd) => {
      const encodedUri = encodeURIComponent(dd.docUrl);
      const doubleEncode = encodeURIComponent(encodedUri);
      console.log(dd.owner.value.firstName);
      if (dd.groups && dd.groups.length > 0) {
        if (
          currentUser.groups &&
          currentUser.groups.some((r) => dd.groups.includes(r))
        ) {
          doc.setFontSize(8);
          doc.autoTable({
            head: [[`${dd.docCode} - ${dd.docName}`, ``]],
            theme: "striped",
            margin: { top: 80 },
            didDrawCell: function (hookData) {
              if (hookData.column.dataKey === 1) {
                doc.textWithLink("LINK", hookData.cell.x, hookData.cell.y + 5, {
                  url: `https://public.isoms.co.za/view-doc/${doubleEncode}/${dd.owner.value.firstName}/${dd.docCode}/${dd.docName}`,
                });
              }
            },
          });
          doc.autoTable({
            body: [
              [
                `Owner: ${dd.owner.label}`,
                `Date Created: ${moment(dd.createdDate.toDate()).format(
                  "MM/DD/YYYY"
                )}`,
              ],
              [
                `Type: ${dd.docType.label}`,
                `Target Revision Date: ${moment(
                  dd.targetRevDate.toDate()
                ).format("MM/DD/YYYY")}`,
              ],
              [
                `Department: ${dd.department.label}`,
                `Due: ${moment(dd.targetRevDate.toDate()).fromNow()}`,
              ],
              [
                `Retention: ${dd.retentionPeriod.label}`,
                `Disposition: ${dd.dispositionMethod.label}`,
              ],
            ],
            theme: "plain",
            bodyStyles: {
              fontSize: 8,
            },
          });
          if (dd.revisions) {
            const revBody = dd.revisions.map((r) => [
              r.description,
              moment(r.createdDate.toDate()).format("DD/MM/YYYY"),
              r.revisedBy ? r.revisedBy[0].label : "None",
              r.requestedBy ? r.requestedBy.label : "None",
              `${r.numApproved}/${r.numApprovers ? r.numApprovers : 0}`,
            ]);
            doc.autoTable({
              head: [
                [
                  "Revisions",
                  "Date",
                  "Revised By",
                  "Requested By",
                  "Confirmed",
                ],
              ],
              body: revBody,
              headStyles: {
                fillColor: [128, 130, 127],
                fontSize: 8,
              },
              bodyStyles: {
                fillColor: [255, 255, 255],
                fontSize: 7,
              },
            });
          }
        }
      } else {
        doc.setFontSize(8);
        doc.autoTable({
          head: [[`${dd.docCode} - ${dd.docName}`, ``]],
          theme: "striped",
          margin: { top: 80 },
          didDrawCell: function (hookData) {
            if (hookData.column.dataKey === 1) {
              doc.textWithLink("LINK", hookData.cell.x, hookData.cell.y + 5, {
                url: `https://public.isoms.co.za/view-doc/${doubleEncode}/${dd.owner.value.firstName}/${dd.docCode}/${dd.docName}`,
              });
            }
          },
        });
        doc.autoTable({
          body: [
            [
              `Owner: ${dd.owner.label}`,
              `Date Created: ${moment(dd.createdDate.toDate()).format(
                "MM/DD/YYYY"
              )}`,
            ],
            [
              `Type: ${dd.docType.label}`,
              `Target Revision Date: ${moment(dd.targetRevDate.toDate()).format(
                "MM/DD/YYYY"
              )}`,
            ],
            [
              `Department: ${dd.department.label}`,
              `Due: ${moment(dd.targetRevDate.toDate()).fromNow()}`,
            ],
            [
              `Retention: ${dd.retentionPeriod.label}`,
              `Disposition: ${dd.dispositionMethod.label}`,
            ],
          ],
          theme: "plain",
          bodyStyles: {
            fontSize: 8,
          },
        });
        if (dd.revisions) {
          const revBody = dd.revisions.map((r) => [
            r.description,
            moment(r.createdDate.toDate()).format("DD/MM/YYYY"),
            r.revisedBy ? r.revisedBy[0].label : "None",
            r.requestedBy ? r.requestedBy.label : "None",
            `${r.numApproved}/${r.numApprovers ? r.numApprovers : 0}`,
          ]);
          doc.autoTable({
            head: [
              ["Revisions", "Date", "Revised By", "Requested By", "Confirmed"],
            ],
            body: revBody,
            headStyles: {
              fillColor: [128, 130, 127],
              fontSize: 8,
            },
            bodyStyles: {
              fillColor: [255, 255, 255],
              fontSize: 7,
            },
          });
        }
      }
    });

    await logAuditTrail(currentUser.uid, `Generated a Doc Register PDF`);
    return doc.save("Documents Report.pdf");
  }

  async function getDocumentsSubmittedForPdf(docId) {
    let docsArray = [];
    await companyRef
      .collection("documents")
      .doc(docId)
      .collection("supportingDocs")
      .orderBy("createdAt", "desc")
      .get()
      .then((snap) => {
        docsArray = snap.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
      });
    return docsArray;
  }

  async function getExternalDocLink(doc) {
    const { docUrl, docCode, docName, owner } = doc;
    const encodedUri = encodeURIComponent(docUrl);
    const doubleEncode = encodeURIComponent(encodedUri);
    return `https://public.isoms.co.za/view-doc/${doubleEncode}/${
      owner.value?.firstName || "User"
    }/${docCode}/${docName}`;
  }

  async function generateSingleDocPdf(data) {
    const pdf = new jsPDF();
    var img = new Image();
    img.src = await getCompanyLogo();

    const encodedUri = encodeURIComponent(data.docUrl);
    const doubleEncode = encodeURIComponent(encodedUri);

    pdf.addImage(
      img,
      "PNG",
      80,
      10,
      pdf.canvas.width * 0.3,
      pdf.canvas.height * 0.15
    );
    pdf.setFontSize(10);
    pdf.textWithLink(`${data.docCode} ${data.docName} (Link)`, 15, 60, {
      url: `https://public.isoms.co.za/view-doc/${doubleEncode}/${data.owner.value.firstName}/${data.docCode}/${data.docName}`,
    });

    const revs = await getRevisionsForDoc(data.id);
    const docsSubmitted = await getDocumentsSubmittedForPdf(data.id);

    pdf.autoTable({
      body: [
        [
          `Owner: ${data.owner.value.firstName} ${data.owner.value.lastName}`,
          `Date Created: ${moment(data.createdDate.toDate()).format(
            "DD/MM/YYYY"
          )}`,
        ],
        [
          `Type: ${data.docType.label}`,
          `Target Revision Date: ${moment(data.targetRevDate.toDate()).format(
            "DD/MM/YYYY"
          )}`,
        ],
        [
          `Department: ${data.department.label}`,
          `Status: Due in: ${moment(data.targetRevDate.toDate()).fromNow()}`,
        ],
        [
          `Retention: ${data.retentionPeriod.label}`,
          `Disposition: ${data.dispositionMethod.label}`,
        ],
      ],
      theme: "plain",
      bodyStyles: {
        fontSize: 8,
      },
      margin: { top: 70 },
    });

    if (revs && revs.length > 0) {
      const revBody = revs.map((r) => [
        r.description,
        moment(r.createdDate.toDate()).format("DD/MM/YYYY"),
        r.revisedBy[0].label,
        r.requestedBy.label,
        `${r.numApproved}/${r.numApprovers}`,
      ]);
      pdf.autoTable({
        head: [["Revision", "Date", "Revised By", "Requested By", "Confirmed"]],
        body: revBody,
        headStyles: {
          fillColor: [128, 130, 127],
          fontSize: 8,
        },
        bodyStyles: {
          fillColor: [255, 255, 255],
          fontSize: 7,
        },
      });
    }

    if (docsSubmitted && docsSubmitted.length > 0) {
      const docsBody = docsSubmitted.map((d) => [
        d.fileName,
        moment(d.createdAt.toDate()).format("MM/DD/YYYY"),
        d.submittedBy,
      ]);
      pdf.autoTable({
        head: [["Doc Submitted", "Date", "Submitted By"]],
        body: docsBody,
        headStyles: {
          fillColor: [128, 130, 127],
          fontSize: 8,
        },
        bodyStyles: {
          fillColor: [255, 255, 255],
          fontSize: 7,
        },
      });
    }

    return pdf.save(`${data.docCode} - PDF Report`);
  }

  async function generateSeportionDocFilterdPdf(supDocs, folderFilter) {
    const pdf = new jsPDF();
    var img = new Image();
    img.src = await getCompanyLogo();

    pdf.addImage(
      img,
      "PNG",
      80,
      10,
      pdf.canvas.width * 0.3,
      pdf.canvas.height * 0.15
    );

    const tableBody = [];

    // TODO: add LINK
    supDocs.map((d) => {
      if (d.folder !== null && d.folder.id === folderFilter) {
        tableBody.push([
          `${d.folder.label}`,
          `${moment(d.createdAt.toDate()).format("DD/MM/YYYY")}`,
          `${moment(d.expiryDate.toDate()).format("DD/MM/YYYY")}`,
          // LINK
        ]);
      }
    });

    // TODO: add LINK
    pdf.autoTable({
      head: [
        ["Folder", "Document Name", "Date Created", "Expiry Date" /*LINK*/],
      ],
      body: tableBody,
      margin: { top: 60 },
      headStyles: {
        fillColor: [128, 130, 127],
        fontSize: 8,
      },
      bodyStyles: {
        fillColor: [255, 255, 255],
        fontSize: 7,
      },
    });

    return pdf.save(`Single Folder.pdf`);
  }

  async function getActionReportData(filters) {
    let query = companyRef.collection("actions");

    for (let key in filters) {
      console.log(filters[key].value);
      if (filters[key].value) {
        query = query.where(filters[key].fieldName, "==", filters[key].value);
      }
    }

    return await query
      .orderBy("timestamp")
      .get()
      .then((snap) => {
        if (!snap.empty) {
          let actions = [];
          snap.docs.map((doc) => {
            if (!doc.data().obsolete) {
              actions.push({
                ...doc.data(),
                id: doc.id,
              });
            }
          });
          // const actions = snap.docs.map(doc => ({
          //     ...doc.data(),
          //     id: doc.id
          // }))
          return actions;
        } else return [];
      });
  }

  async function createActionsReportPdf(filters) {
    // const data = actionData
    const data = await getActionReportData(filters);
    if (!data) {
      throw {
        message: "No actions to retrieve.",
      };
    }
    const doc = new jsPDF();

    var img = new Image();
    img.src = await getCompanyLogo();

    doc.addImage(
      img,
      "PNG",
      80,
      10,
      doc.canvas.width * 0.3,
      doc.canvas.height * 0.15
    );
    doc.text("Actions Report", 85, 60);

    data.map((d) => {
      doc.setFontSize(8);
      doc.autoTable({
        head: [[`Action Number: ${d.actionNumber}`]],
        theme: "striped",
        margin: { top: 80 },
      });
      doc.autoTable({
        body: [
          [`Site: ${d.site.label}`, `Department: ${d.department.name}`],
          [
            `HOD: ${d.hod.firstName} ${d.hod.lastName}`,
            `Source: ${d.source.sourceName}`,
          ],
          [
            `Originator: ${d.originator.firstName} ${d.originator.lastName}`,
            `ISO Classification: ${d.isoClass}`,
          ],
          [`ISO Agency: ${d.isoAgency.isoAgency}`, `Priority: ${d.priority}`],
          [
            `Target Date: ${moment(d.targetDate.toDate()).format(
              "DD/MM/YYYY"
            )}`,
            `Close-Out Date: ${
              d.closeOutDate
                ? moment(d.closeOutDate.toDate()).format("DD/MM/YYYY")
                : "Open"
            }`,
          ],
        ],
        theme: "plain",
        bodyStyles: {
          fontSize: 8,
        },
      });
      doc.autoTable({
        body: [
          ["Description"],
          [d.description],
          ["Immediate Action"],
          [d.immAction],
          ["Root Cause"],
          [d.rootCause],
          ["Preventive Measures"],
          [d.preventive],
        ],
        theme: "striped",
        bodyStyles: {
          fontSize: 8,
        },
      });
    });
    await logAuditTrail(currentUser.uid, `Generated an Action Register PDF`);
    return doc.save("Actions Report");
  }

  async function getSingleActionPdf(data) {
    const createdByUser = await db
      .collection("users")
      .doc(data.createdBy ? data.createdBy : data.originator.uid)
      .get()
      .then((doc) => {
        return doc.data();
      });
    await generateSingleActionPdf(data, companyRef, createdByUser);
    await logAuditTrail(
      currentUser.uid,
      `Generated a Single Action PDF. Action: ${data.actionNumber}`
    );
  }

  async function getOTPReportData(filters) {
    let query = companyRef.collection("otps");

    for (let key in filters) {
      console.log(filters[key].value);
      if (filters[key].value) {
        query = query.where(filters[key].fieldName, "==", filters[key].value);
      }
    }

    return await query
      .orderBy("timestamp")
      .get()
      .then((snap) => {
        if (!snap.empty) {
          const otps = snap.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          return otps;
        } else return [];
      });
  }

  async function generateOtpRegisterPdf(filters) {
    const data = await getOTPReportData(filters);
    if (!data) {
      throw {
        message: "No actions to retrieve.",
      };
    }

    const pdf = new jsPDF();
    var img = new Image();
    img.src = await getCompanyLogo();

    pdf.addImage(
      img,
      "PNG",
      80,
      10,
      pdf.canvas.width * 0.3,
      pdf.canvas.height * 0.15
    );
    pdf.setFontSize(14);

    for (let i = 0; i < data.length; i++) {
      if (data[i].targetCount && data[i].targetCount > 0) {
        await companyRef
          .collection("otps")
          .doc(data[i].id)
          .collection("targets")
          .orderBy("targetNo", "asc")
          .get()
          .then(async (snap) => {
            let targetList = [];
            for (let t = 0; t < snap.docs.length; t++) {
              targetList.push({
                ...snap.docs[t].data(),
                id: snap.docs[t].id,
              });
              await companyRef
                .collection("otps")
                .doc(data[i].id)
                .collection("targets")
                .doc(targetList[t].id)
                .collection("programs")
                .orderBy("programNo", "asc")
                .get()
                .then(async (snapshot) => {
                  let programList = [];
                  for (let p = 0; p < snapshot.docs.length; p++) {
                    programList.push({
                      ...snapshot.docs[p].data(),
                      id: snapshot.docs[p].id,
                    });
                  }
                  targetList[t].programs = programList;
                });
            }
            data[i].targets = targetList;
          });
      }
    }
    pdf.autoTable({
      head: [[`Objective, Target & Program Report`]],
      theme: "plain",
      margin: { top: 80 },
    });
    data.map((d) => {
      pdf.autoTable({
        head: [[`OTP Number: ${d.otpNumber}`]],
        theme: "striped",
      });
      pdf.autoTable({
        body: [
          [`Logged By: ${d.loggedBy.email}`, `Criteria: ${d.criteria.name}`],
          [
            `Date Created: ${moment(d.timestamp.toDate()).format(
              "DD/MM/YYYY"
            )}`,
            `Department: ${d.department.name}`,
          ],
        ],
        theme: "plain",
        bodyStyles: {
          fontSize: 8,
        },
      });
      if (d.targets) {
        pdf.autoTable({
          head: [[`Targets`]],
          theme: "striped",
          headStyles: {
            fillColor: [128, 130, 127],
            fontSize: 7,
          },
        });
        d.targets.map((t) => {
          pdf.autoTable({
            head: [[`${t.targetNo} - ${t.targetDescription}`]],
            theme: "plain",
          });
          if (t.programs && t.programs.length > 0) {
            pdf.autoTable({
              head: [
                [
                  `Program No`,
                  `Description`,
                  `HOD Responsible`,
                  `Program Executor`,
                  `Target Date`,
                  `Status`,
                ],
              ],
              theme: "striped",
              headStyles: {
                fillColor: [128, 130, 127],
                fontSize: 7,
              },
              bodyStyles: {
                fillColor: [255, 255, 255],
                fontSize: 7,
              },
              body: t.programs.map((p) => [
                p.programNo,
                p.description,
                `${p.responsible.firstName} ${p.responsible.lastName}`,
                `${p.closer.firstName} ${p.closer.lastName}`,
                moment(p.targetDate.toDate()).format("DD/MM/YYYY"),
                p.open ? "Open" : "Closed",
              ]),
            });
          }
        });
      }
    });

    return pdf.save("OTP Report");
  }

  async function generateSingleOtpPdf(otp) {
    const pdf = new jsPDF();
    var img = new Image();
    img.src = await getCompanyLogo();

    pdf.addImage(
      img,
      "PNG",
      80,
      10,
      pdf.canvas.width * 0.3,
      pdf.canvas.height * 0.15
    );
    pdf.setFontSize(14);
    pdf.text(`OTP Report. OTP: ${otp.otpNumber}`, 80, 60);
    pdf.setFontSize(8);
    pdf.text(`Objective: ${otp.defineObjectives}`, 15, 70);

    pdf.autoTable({
      body: [
        [`Logged By: ${otp.loggedBy.email}`, `Criteria: ${otp.criteria.name}`],
        [
          `Date Created: ${moment(otp.timestamp.toDate()).format(
            "DD/MM/YYYY"
          )}`,
          `Department: ${otp.department.name}`,
        ],
      ],
      theme: "plain",
      bodyStyles: {
        fontSize: 8,
      },
      margin: { top: 80 },
    });

    if (otp.targetCount && otp.targetCount > 0) {
      await companyRef
        .collection("otps")
        .doc(otp.id)
        .collection("targets")
        .orderBy("targetNo", "asc")
        .get()
        .then(async (snap) => {
          let targetList = [];
          for (let t = 0; t < snap.docs.length; t++) {
            targetList.push({
              ...snap.docs[t].data(),
              id: snap.docs[t].id,
            });
            await companyRef
              .collection("otps")
              .doc(otp.id)
              .collection("targets")
              .doc(targetList[t].id)
              .collection("programs")
              .orderBy("programNo", "asc")
              .get()
              .then(async (snapshot) => {
                let programList = [];
                for (let p = 0; p < snapshot.docs.length; p++) {
                  programList.push({
                    ...snapshot.docs[p].data(),
                    id: snapshot.docs[p].id,
                  });
                }
                targetList[t].programs = programList;
              });
          }
          otp.targets = targetList;
        });
    }
    if (otp.targets && otp.targets.length > 0) {
      pdf.autoTable({
        head: [[`Targets`]],
        theme: "striped",
      });
      otp.targets.map((t) => {
        pdf.autoTable({
          head: [[`${t.targetNo} - ${t.targetDescription}`]],
          theme: "plain",
        });
        if (t.programs && t.programs.length > 0) {
          pdf.autoTable({
            head: [
              [
                `Program No`,
                `Description`,
                `HOD Responsible`,
                `Program Executor`,
                `Measurement`,
                `Measurement Progress`,
                `Progress`,
                `Target Date`,
                `Status`,
              ],
            ],
            theme: "striped",
            headStyles: {
              fillColor: [128, 130, 127],
              fontSize: 7,
            },
            bodyStyles: {
              fillColor: [255, 255, 255],
              fontSize: 7,
            },
            body: t.programs.map((p) => [
              p.programNo,
              p.description,
              `${p.responsible.firstName} ${p.responsible.lastName}`,
              `${p.closer.firstName} ${p.closer.lastName}`,
              p.measurement,
              `${((p.currentProgress / p.programTarget) * 100).toFixed(2)}%`,
              p.currentProgress,
              moment(p.targetDate.toDate()).format("DD/MM/YYYY"),
              p.open ? "Open" : "Closed",
            ]),
          });
        }
      });
    }

    return pdf.save(`OTP Report: ${otp.otpNumber}`);
  }

  async function getAuditsInspections(id) {
    await companyRef
      .collection("auditInspections")
      .doc(id)
      .get()
      .then((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
  }

  async function generateAuditsInspectionsPdf(data) {
    const date = new Date();
    let docs = [];
    if (typeof data[0] === "string") {
      // TODO:
      // for (let i = 0; i < data.length; i++) {
      //     const d = data[i];
      //     const aiData = await getAuditsInspections(d)
      //     docs.push(...aiData)
      // }
    } else if (typeof data[0] !== "string") {
      docs = data;
    }

    console.log(data);

    const pdf = new jsPDF();
    var img = new Image();
    img.src = await getCompanyLogo();

    pdf.addImage(
      img,
      "PNG",
      80,
      10,
      pdf.canvas.width * 0.3,
      pdf.canvas.height * 0.15
    );
    pdf.setFontSize(14);
    pdf.text(
      `Inspections / Audits Report: ${
        docs[0].status === "missed"
          ? "Missed"
          : docs[0].status === "pending"
          ? "Upcoming"
          : "Completed"
      }`,
      60,
      60
    );

    pdf.autoTable({
      margin: { top: 80 },
      head: [["Code", "Title", "Type", "Due Date", "Assignee"]],
      theme: "striped",
      headStyles: {
        fillColor: [128, 130, 127],
        fontSize: 7,
      },
      bodyStyles: {
        fillColor: [255, 255, 255],
        fontSize: 7,
      },
      body: docs.map((d) => [
        d.jobRef,
        d.title,
        d.type,
        moment(d.dueDate.toDate()).format("DD/MM/YY"),
        `${d.assignedTo.label}`,
      ]),
    });

    pdf.save(
      `${moment(date).format(
        "DD/MM/YY hh:mm:ss"
      )} - Inspections / Audits Report`
    );
  }

  async function generateSingleAuditsInspectionPdf(data) {
    const pdf = new jsPDF();
    var img = new Image();
    img.src = await getCompanyLogo();

    const assignedTo = await companyRef
      .collection("auditInspections")
      .doc(data[0].inspectionId)
      .get()
      .then((doc) => ({ ...doc.data().assignedTo }));

    pdf.addImage(
      img,
      "PNG",
      80,
      10,
      pdf.canvas.width * 0.3,
      pdf.canvas.height * 0.15
    );
    pdf.setFontSize(14);
    pdf.text(`Inspection / Audit Report: ${assignedTo.label} `, 10, 60);

    pdf.autoTable({ margin: { top: 60 } });
    data.map((ins, i) => {
      pdf.autoTable({
        theme: "plain",
        styles: { fontSize: 16 },
        head: [[ins.sectionName]],
      });
      ins.data.map((d) => {
        pdf.autoTable({
          head: [
            // d.name
            // ?
            // [d.name]
            // :
            // d.field
            // ?
            // [d.field, 'Issue']
            // :
            // ['No Question Recorded']
            d.commentValue && d.issueValue
              ? [d.name, "Comment", "Issue"]
              : d.commentValue
              ? [d.name, "Comment"]
              : d.issueValue
              ? [d.name, "Issue"]
              : [d.name],
          ],
          body: [
            d.commentValue && d.issueValue
              ? [
                  d.type === "text"
                    ? d.value
                    : d.type === "checkbox"
                    ? d.value.map((val, i) => [Object.keys(val)[0]])
                    : d.type === "radio"
                    ? [Object.values(d.value)[0]]
                    : d.type === "file"
                    ? "View in ISOMS to see attachments"
                    : null,
                  d.commentValue,
                  d.issueValue,
                ]
              : d.commentValue
              ? [
                  d.type === "text"
                    ? d.value
                    : d.type === "checkbox"
                    ? d.value.map((val, i) => [Object.keys(val)[0]])
                    : d.type === "radio"
                    ? [Object.values(d.value)[0]]
                    : d.type === "file"
                    ? "View in ISOMS to see attachments"
                    : null,
                  d.commentValue,
                ]
              : d.issueValue
              ? [
                  d.type === "text"
                    ? d.value
                    : d.type === "checkbox"
                    ? d.value.map((val, i) => [Object.keys(val)[0]])
                    : d.type === "radio"
                    ? [Object.values(d.value)[0]]
                    : d.type === "file"
                    ? "View in ISOMS to see attachments"
                    : null,
                  d.issueValue,
                ]
              : [
                  d.type === "text"
                    ? d.value
                    : d.type === "checkbox"
                    ? d.value.map((val, i) => [Object.keys(val)[0]])
                    : d.type === "radio"
                    ? [Object.values(d.value)[0]]
                    : d.type === "file"
                    ? "View in ISOMS to see attachments"
                    : null,
                ],
          ],
        });
      });
    });

    pdf.save(`Inspection Audit Report ${assignedTo.label}.pdf`);
  }

  // ---------------------- User Creation Functions --------------------

  async function checkIfEmailExists(email) {
    const emailAvailable = await db
      .collection("users")
      .where("email", "==", email)
      .get()
      .then((snap) => {
        if (snap.empty) {
          return true;
        } else {
          return false;
        }
      });
    return emailAvailable;
  }

  async function requestUserCreation(data) {
    const emailAvailable = await checkIfEmailExists(data.email);
    const companyName = await getCompanyName();

    if (emailAvailable) {
      return companyRef
        .collection("userRequests")
        .add({
          ...data,
          companyName,
        })
        .then(async () => {
          await logAuditTrail(currentUser.uid, `Created a new User`);
        });
    } else {
      throw {
        message: "Error: That Email is already in use by another account.",
      };
    }
  }

  // ---------------------- Company Specific Functions ---------------------

  async function getCompanyName() {
    return await companyRef.get().then((doc) => {
      return doc.data().companyName;
    });
  }

  async function getCompanyNameById(companyId) {
    return await db
      .collection("companies")
      .doc(companyId)
      .get()
      .then((doc) => {
        return doc.data().companyName;
      });
  }

  // ---------------------- New Place for Document Functions ----------------------

  async function updateRevision(data, docId, revId) {
    return await editRevision(companyRef, docId, revId, data).then(async () => {
      await logAuditTrail(
        currentUser.uid,
        `Edited a Revision. Document ID: ${docId}. Revision ID: ${revId}`
      );
    });
  }

  function GetGroupsForDocs(docId) {
    const [groups, setGroups] = useState([]);
    useEffect(() => {
      const unsubscribe = companyRef
        .collection("documents")
        .doc(docId)
        .collection("groups")
        .onSnapshot((snapshot) => {
          const groupList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setGroups(groupList);
        });
      return unsubscribe;
    }, [docId]);
    return groups;
  }

  async function addAdditionalGroupsToDoc(docId, groups) {
    return await editAddGroupsToDoc(companyRef, docId, groups).then(
      async () => {
        await logAuditTrail(
          currentUser.uid,
          `Added additional groups to a document. Doc ID: ${docId}`
        );
      }
    );
  }

  async function removeGroupFromDoc(docId, groupId) {
    return await editRemoveGroupFromDoc(companyRef, docId, groupId).then(
      async () => {
        await logAuditTrail(
          currentUser.uid,
          `Removed Groups From Document: ${docId}`
        );
      }
    );
  }

  async function removeFolderFromSupDoc(docId, supDocId) {
    return await removeFolderFromDocSubmitted(companyRef, docId, supDocId).then(
      async () => {
        await logAuditTrail(
          currentUser.uid,
          `Removed a Subfolder From Docs Submitted: ${docId}`
        );
      }
    );
  }

  function updateDocType(docTypeId, data) {
    return companyRef
      .collection("docTypes")
      .doc(docTypeId)
      .update({
        ...data,
      })
      .then(async () => {
        await logAuditTrail(currentUser.uid, `Updated Doc Type: ${docTypeId}`);
      });
  }

  function deleteDocType(docTypeId) {
    return companyRef
      .collection("docTypes")
      .doc(docTypeId)
      .delete()
      .then(async () => {
        await logAuditTrail(currentUser.uid, `Deleted Doc Type: ${docTypeId}`);
      });
  }

  function getSupportingDoc(docId, supDocId) {
    return companyRef
      .collection("documents")
      .doc(docId)
      .collection("supportingDocs")
      .doc(supDocId)
      .get()
      .then((doc) => {
        return doc.data();
      });
  }

  function deleteSupportingDoc(docId, supDocId) {
    return companyRef
      .collection("documents")
      .doc(docId)
      .collection("supportingDocs")
      .doc(supDocId)
      .delete()
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Deleted Doc Submitted Type: Doc ID: ${docId}`
        );
      });
  }

  function deleteEmployeeSupportingDoc(docId, supDocId) {
    return companyRef
      .collection("employees")
      .doc(docId)
      .collection("supportingDocs")
      .doc(supDocId)
      .delete()
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Deleted Doc Submitted Type: Doc ID: ${docId}`
        );
      });
  }

  async function removeEmployeeFolderFromDocSubmitted(
    companyRef,
    docId,
    supDocId
  ) {
    return await companyRef
      .collection("employees")
      .doc(docId)
      .collection("supportingDocs")
      .doc(supDocId)
      .update({
        folder: null,
      });
  }

  // ----------------------- New User Functions ---------------------------------

  async function updateUserLevel(uid, accessRights) {
    return await changeUserLevel(db, uid, accessRights).then(async () => {
      await logAuditTrail(
        currentUser.uid,
        `Changed User Access Rights: ${uid}`
      );
    });
  }

  async function toggleAdminStatus(uid, status) {
    return await db
      .collection("users")
      .doc(uid)
      .update({
        companyAdmin: status,
      })
      .then(async () => {
        await logAuditTrail(currentUser.uid, `Made User an Admin: ${uid}`);
      });
  }

  // ----------------------- New Admin User Only Functions ---------------------------

  async function getCompanySettings() {
    return await companyRef.get().then((doc) => {
      return doc.data();
    });
  }

  function updateCompanySettings(data) {
    return companyRef
      .update({
        ...data,
      })
      .then(async () => {
        await logAuditTrail(currentUser.uid, `Updated Company Settings`);
      });
  }

  async function deleteUser(userId) {
    // Delete from Rosters Collection
    await companyRef
      .collection("rosters")
      .where("uid", "==", userId)
      .get()
      .then((snap) => {
        snap.docs.forEach((doc) => {
          doc.ref.delete();
        });
      });

    // Delete From Groups
    // await companyRef.collection('groups')
    // .where('members', 'array-contains', userId)
    // .get().then(snap => {
    //     snap.docs.forEach(doc => {
    //         doc.ref.delete()
    //     })
    // })
    // Groups Collection Group Query - this is powerful
    const membersQuery = db.collectionGroup("members");
    await membersQuery
      .where("uid", "==", userId)
      .get()
      .then((snap) => {
        snap.docs.forEach((doc) => {
          doc.ref.delete();
        });
      });

    // Delete From All Documents and Actions Interested Parties and Approvers
    const intPartiesQuery = db.collectionGroup("interestedParties");
    await intPartiesQuery
      .where("uid", "==", userId)
      .get()
      .then((snap) => {
        snap.docs.forEach((doc) => {
          doc.ref.delete();
        });
      });
    const approvalsQuery = db.collectionGroup("approvals");
    await approvalsQuery
      .where("uid", "==", userId)
      .get()
      .then((snap) => {
        snap.docs.forEach((doc) => {
          doc.ref.delete();
        });
      });

    // Finally, delete the user document which will trigger the CF to delete the actual user account
    await db
      .collection("users")
      .doc(userId)
      .delete()
      .then(async () => {
        await logAuditTrail(currentUser.uid, `Deleted User: ${userId}`);
      });
  }

  async function managerDeleteUser(companyId, userId) {
    const companyReference = db.collection("companies").doc(companyId);

    // Delete from Rosters Collection
    await companyReference
      .collection("rosters")
      .where("uid", "==", userId)
      .get()
      .then((snap) => {
        snap.docs.forEach((doc) => {
          doc.ref.delete();
        });
      });

    // Delete From Groups
    await companyReference
      .collection("groups")
      .where("members", "array-contains", userId)
      .get()
      .then((snap) => {
        snap.docs.forEach((doc) => {
          doc.ref.delete();
        });
      });
    // Groups Collection Group Query - this is powerful
    const membersQuery = db.collectionGroup("members");
    await membersQuery
      .where("uid", "==", userId)
      .get()
      .then((snap) => {
        snap.docs.forEach((doc) => {
          doc.ref.delete();
        });
      });

    // Delete From All Documents and Actions Interested Parties and Approvers
    const intPartiesQuery = db.collectionGroup("interestedParties");
    await intPartiesQuery
      .where("uid", "==", userId)
      .get()
      .then((snap) => {
        snap.docs.forEach((doc) => {
          doc.ref.delete();
        });
      });
    const approvalsQuery = db.collectionGroup("approvals");
    await approvalsQuery
      .where("uid", "==", userId)
      .get()
      .then((snap) => {
        snap.docs.forEach((doc) => {
          doc.ref.delete();
        });
      });

    // Finally, delete the user document which will trigger the CF to delete the actual user account
    await db.collection("users").doc(userId).delete();
  }

  function GetAuditTrail100() {
    const [events, setEvents] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("auditTrail")
        .orderBy("timestamp", "desc")
        .limit(100)
        .onSnapshot((snapshot) => {
          const logList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setEvents(logList);
        });
      return unsubscribe;
    }, []);
    return events;
  }

  async function userLogsPdf(data) {
    const pdf = new jsPDF();
    let img = new Image();
    img.src = await getCompanyLogo();

    pdf.addImage(
      img,
      "PNG",
      80,
      10,
      pdf.canvas.width * 0.3,
      pdf.canvas.height * 0.15
    );
    pdf.setFontSize(14);
    pdf.text(`User Log: ${data[0].user}`, 30, 60);

    pdf.autoTable({
      margin: { top: 60 },
    });

    pdf.autoTable({
      head: [["Time", "Date", "User", "Description"]],
      body: data.map((d) => [d.timestamp, d.date, d.user, d.description]),
    });

    pdf.save(`User Log: ${data[0].user}.pdf`);
  }

  function GetUserLogsDates(userId, fromDate, toDate) {
    const [events, setEvents] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("auditTrail")
        .where("user.id", "==", userId)
        .where("timestamp", ">=", fromDate)
        .where("timestamp", "<=", toDate)
        .onSnapshot((snapshot) => {
          const logList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setEvents(logList);
        });
      return unsubscribe;
    }, [userId, fromDate, toDate]);
    return events;
  }

  async function getUserLogs(userId, fromDate, toDate) {
    console.log(fromDate);
    return await companyRef
      .collection("auditTrail")
      .where("user.id", "==", userId)
      .where("timestamp", ">=", fromDate)
      .where("timestamp", "<=", toDate)
      .orderBy("timestamp", "desc")
      .get()
      .then((snap) => {
        if (!snap.empty) {
          const logList = snap.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          return logList;
        } else {
          throw {
            message: "No logs found for that user.",
          };
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  // ----------------------- Filtered Roster Dropdowns ------------------------

  function GetUsersLevelFilter(targetModule, level) {
    const [users, setUsers] = useState([]);

    useEffect(() => {
      const unsubscribe = db
        .collection("users")
        .where("companyId", "==", coRef)
        .where(`accessRights.${targetModule}`, "<=", level)
        // .where("rosterOnly", "==", false)
        .onSnapshot((snapshot) => {
          const userList = snapshot.docs.map((doc) => ({
            value: {
              id: doc.id,
              firstName: doc.data().firstName,
              lastName: doc.data().lastName,
              email: doc.data().email,
            },
            label: `${doc.data().firstName} ${doc.data().lastName} - ${
              doc.data().email
            }`,
          }));
          setUsers(userList);
        });
      return unsubscribe;
    }, []);
    return users;
  }

  // ------------------------ ISO Classifications -------------------------------

  function GetIsoClasses() {
    const [classes, setClasses] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("isoClasses")
        .onSnapshot((snapshot) => {
          const classList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            value: `${doc.data().standard} ${doc.data().code}`,
            label: `${doc.data().standard} ${doc.data().code}`,
          }));
          setClasses(classList);
        });
      return unsubscribe;
    }, []);
    return classes;
  }

  function createIsoClass(data) {
    return companyRef
      .collection("isoClasses")
      .add({
        ...data,
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Created A new ISO Classification: ${data.standard} ${data.code}`
        );
      });
  }

  async function updateIsoClass(isoId, data) {
    // Update any agencies with old isoClass
    const oldRef = await companyRef
      .collection("isoClasses")
      .doc(isoId)
      .get()
      .then((classDoc) => {
        return `${classDoc.data().standard} ${classDoc.data().code}`;
      });

    await companyRef
      .collection("isoClasses")
      .doc(isoId)
      .update({
        ...data,
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Updated ISO Classification: ${isoId}`
        );
      });

    const promiseArray = await companyRef
      .collection("isoAgencies")
      .where("isoClass", "==", oldRef)
      .get()
      .then((snap) => {
        return snap.docs.map((doc) => {
          return doc.ref.update({
            isoClass: `${data.standard} ${data.code}`,
          });
        });
      });

    return await Promise.all(promiseArray);
  }

  function deleteIsoClass(isoId) {
    return companyRef
      .collection("isoClasses")
      .doc(isoId)
      .delete()
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Deleted ISO Classification: ${isoId}`
        );
      });
  }

  async function addIsoCode(code) {
    return await companyRef
      .collection("isoCodes")
      .add({
        code,
      })
      .then(async () => {
        await logAuditTrail(currentUser.uid, `Added a new Code: ${code}`);
      });
  }

  function GetIsoCodes() {
    const [codes, setCodes] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("isoCodes")
        .onSnapshot((snapshot) => {
          const codeList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            value: `${doc.data().code}`,
            label: `${doc.data().code}`,
          }));
          setCodes(codeList);
        });
      return unsubscribe;
    }, []);
    return codes;
  }

  // ---------------------- ISO Agencies ---------------------------

  function createIsoAgency(data) {
    return companyRef
      .collection("isoAgencies")
      .add({
        ...data,
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Created ISO Agency: ${data.isoAgency}`
        );
      });
  }

  function updateIsoAgency(agencyId, data) {
    return companyRef
      .collection("isoAgencies")
      .doc(agencyId)
      .update({
        ...data,
      })
      .then(async () => {
        await logAuditTrail(currentUser.uid, `Updated ISO Agency: ${agencyId}`);
      });
  }

  function deleteIsoAgency(agencyId) {
    return companyRef
      .collection("isoAgencies")
      .doc(agencyId)
      .delete()
      .then(async () => {
        await logAuditTrail(currentUser.uid, `Deleted ISO Agency: ${agencyId}`);
      });
  }

  // ---------------------- Departments ----------------------

  function updateDepartment(deptId, data) {
    return companyRef
      .collection("departments")
      .doc(deptId)
      .update({
        ...data,
      })
      .then(async () => {
        await logAuditTrail(currentUser.uid, `Updated Department: ${deptId}`);
      });
  }

  function deleteDepartment(deptId) {
    return companyRef
      .collection("departments")
      .doc(deptId)
      .delete()
      .then(async () => {
        await logAuditTrail(currentUser.uid, `Deleted Department: ${deptId}`);
      });
  }

  // ---------------------- Sites ----------------------

  function updateSite(siteId, data) {
    return companyRef
      .collection("sites")
      .doc(siteId)
      .update({
        ...data,
      })
      .then(async () => {
        await logAuditTrail(currentUser.uid, `Updated Site: ${siteId}`);
      });
  }

  function deleteSite(siteId) {
    return companyRef
      .collection("sites")
      .doc(siteId)
      .delete()
      .then(async () => {
        await logAuditTrail(currentUser.uid, `Deleted Site: ${siteId}`);
      });
  }

  // ----------------------- New Groups ---------------------------

  function updateGroup(groupId, data) {
    return companyRef
      .collection("groups")
      .doc(groupId)
      .update({
        ...data,
      })
      .then(async () => {
        await logAuditTrail(currentUser.uid, `Updated Group: ${groupId}`);
      });
  }

  // ----------------------- New Groups ---------------------------

  function submitSupportRequest(data) {
    return db.collection("supportRequests").add({
      ...data,
      timestamp: new Date(),
      user: {
        uid: currentUser.uid,
        email: currentUser.email,
        accessRights: currentUser.accessRights,
        modules: currentUser.modules,
        companyId: currentUser.companyId,
      },
    });
  }

  // --------------------- Action Manager --------------------------

  async function actionExtensionRequest(actionId, data) {
    const action = await companyRef
      .collection("actions")
      .doc(actionId)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

    return await companyRef
      .collection("actions")
      .doc(actionId)
      .collection("extensionRequests")
      .add({
        ...data,
        action,
        requestedBy: currentUser.uid,
        timestamp: new Date(),
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Requested an extension on action: ${action.actionNumber}`
        );
      });
  }

  async function extensionApproval(
    actionId,
    requestId,
    approval,
    status,
    reason
  ) {
    const action = await companyRef
      .collection("actions")
      .doc(actionId)
      .get()
      .then((doc) => {
        return doc.data();
      });

    if (status === "Declined") {
      return await companyRef
        .collection("actions")
        .doc(actionId)
        .collection("extensionRequests")
        .doc(requestId)
        .update({
          approved: approval,
          status,
          declineReason: reason,
        })
        .then(async () => {
          await logAuditTrail(
            currentUser.uid,
            `${status} an extension on action: ${action.actionNumber}`
          );
        });
    } else {
      return await companyRef
        .collection("actions")
        .doc(actionId)
        .collection("extensionRequests")
        .doc(requestId)
        .update({
          approved: approval,
          status,
          // reason,
        })
        .then(async () => {
          await logAuditTrail(
            currentUser.uid,
            `${status} an extension on action: ${action.actionNumber}`
          );
        });
    }
  }

  async function getActionExtensionRequest(actionId, reqId) {
    return await companyRef
      .collection("actions")
      .doc(actionId)
      .collection("extensionRequests")
      .doc(reqId)
      .get()
      .then((doc) => ({
        ...doc.data(),
      }));
  }

  function GetActionExtensionRequests(actionId) {
    const [reqs, setReqs] = useState([]);
    useEffect(() => {
      const unsubscribe = companyRef
        .collection("actions")
        .doc(actionId)
        .collection("extensionRequests")
        .orderBy("timestamp", "desc")
        .onSnapshot((snapshot) => {
          const reqList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setReqs(reqList);
        });
      return unsubscribe;
    }, [actionId]);
    return reqs;
  }

  async function getAction(actionId) {
    return await companyRef
      .collection("actions")
      .doc(actionId)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
  }

  function GetActionIntParties(actionId) {
    const [parties, setParties] = useState([]);
    useEffect(() => {
      const unsubscribe = companyRef
        .collection("actions")
        .doc(actionId)
        .collection("interestedParties")
        .orderBy("firstName", "asc")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setParties(data);
        });
      return unsubscribe;
    }, [actionId]);
    return parties;
  }

  async function getActionsForYear(year) {
    return await companyRef
      .collection("currentActionCounter")
      .doc(year)
      .get()
      .then((doc) => {
        return doc.data().actions;
      });
  }

  async function addActionComment(comment, actionId) {
    companyRef
      .collection("actions")
      .doc(actionId)
      .collection("comments")
      .add({
        ...comment,
      });
  }

  function GetActionComments(actionId) {
    const [comments, setComments] = useState([]);
    useEffect(() => {
      const unsubscribe = companyRef
        .collection("actions")
        .doc(actionId)
        .collection("comments")
        .orderBy("timestamp", "desc")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setComments(data);
        });
      return unsubscribe;
    }, [actionId]);
    return comments;
  }

  // --------------------- Updated Doc Manager -------------------

  async function markSupDocReplaced(docId, supDocId) {
    return await companyRef
      .collection("documents")
      .doc(docId)
      .collection("supportingDocs")
      .doc(supDocId)
      .update({
        expires: false,
      });
  }

  async function markEmployeeSupDocReplaced(docId, supDocId) {
    return await companyRef
      .collection("employees")
      .doc(docId)
      .collection("supportingDocs")
      .doc(supDocId)
      .update({
        expires: false,
      });
  }

  function GetDocIntPartiesNew(docId) {
    const [parties, setParties] = useState([]);
    useEffect(() => {
      const unsubscribe = companyRef
        .collection("documents")
        .doc(docId)
        .collection("interestedParties")
        .orderBy("firstName", "asc")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setParties(data);
        });
      return unsubscribe;
    }, [docId]);
    return parties;
  }

  async function getDocument(docId) {
    return await companyRef
      .collection("documents")
      .doc(docId)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
  }

  async function getTotalDocs() {
    let docsArray = [];
    let counter = 0;
    return new Promise(async (res, rej) => {
      await companyRef
        .collection("docCatCount")
        .get()
        .then(async (snap) => {
          if (!snap.empty) {
            for (let i = 0; i < snap.docs.length; i++) {
              docsArray.push({
                count: snap.docs[i].data().count,
                label: snap.docs[i].id,
              });
              counter = counter + snap.docs[i].data().count;
            }
          } else return null;
        })
        .then(() => {
          res(docsArray);
        });
    });
  }

  async function getTotalDocsOverdue() {
    return await companyRef
      .collection("documents")
      .where("obsolete", "==", false)
      .where("targetRevDate", "<=", new Date())
      .get()
      .then((snap) => {
        if (!snap.empty) {
          return snap.docs.length;
        } else return 0;
      });
  }

  async function getTotalDocsForCategory(category) {
    return await companyRef
      .collection("docCatCount")
      .doc(category)
      .get()
      .then((doc) => {
        if (doc.exists) {
          return doc.data().count;
        } else {
          return null;
        }
      });
  }

  async function getNumOverdueDocsPerCategory(category) {
    return await companyRef
      .collection("documents")
      .where("category", "==", category)
      .where("targetRevDate", "<=", new Date())
      .where("obsolete", "==", false)
      .get()
      .then((snap) => {
        if (!snap.empty) {
          return snap.docs.length;
        } else return 0;
      });
  }

  async function toggleExtendedDocMode(mode) {
    return await companyRef.update({
      extendedDocs: mode,
    });
  }

  // --------------------- User Managers -------------------------

  async function updateReportsTo(userId, officer, data) {
    console.log(data);
    return await db
      .collection("users")
      .doc(userId)
      .update({
        [officer]: {
          label: data.label,
          id: data.value.id,
          value: {
            id: data.value.id,
            email: data.value.email,
          },
        },
      });
  }

  // ----------------------- Training Manager ------------------------

  async function createTrainingProvider(data) {
    return await companyRef
      .collection("trainingProviders")
      .add({
        ...data,
        createdAt: new Date(),
        // TODO: Add A user record reference here by createdBy
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Created a new Training Provider: ${data.name}`
        );
      });
  }

  function GetTrainingProviders() {
    const [providers, setProviders] = useState([]);
    useEffect(() => {
      const unsubscribe = companyRef
        .collection("trainingProviders")
        .orderBy("name", "asc")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ref: doc.ref,
            ...doc.data(),
            label: doc.data().name,
            value: {
              id: doc.id,
              name: doc.data().name,
            },
          }));
          setProviders(data);
        });
      return unsubscribe;
    }, []);
    return providers;
  }

  async function getTrainingProvider(id) {
    return await companyRef
      .collection("trainingProviders")
      .doc(id)
      .get()
      .then((doc) => ({
        id: doc.id,
        ref: doc.ref,
        ...doc.data(),
      }));
  }

  async function facilitatorCheck(email) {
    return new Promise(async (res, rej) => {
      await companyRef
        .collection("trainingFacilitators")
        .where("email", "==", email)
        .get()
        .then((snap) => {
          if (!snap.empty) {
            rej({
              message: "This user is already a facilitator.",
            });
          }
        })
        .catch((err) => {
          throw err;
        });

      await db
        .collection("users")
        .where("email", "==", email)
        .get()
        .then((snap) => {
          if (!snap.empty) {
            res({
              ...snap.docs[0].data(),
              id: snap.docs[0].id,
            });
          } else {
            res(null);
          }
        })
        .catch((err) => {
          throw err;
        });
    });
  }

  function GetTrainingRisks() {
    const [risks, setRisks] = useState([]);
    useEffect(() => {
      const unsubscribe = companyRef
        .collection("trainingRisk")
        .orderBy("order", "asc")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            label: doc.data().name,
            value: {
              id: doc.id,
              ...doc.data(),
            },
          }));
          setRisks(data);
        });
      return unsubscribe;
    }, []);
    return risks;
  }

  async function updateRisk(riskId, days) {
    return await companyRef
      .collection("trainingRisk")
      .doc(riskId)
      .update({
        days,
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Training Manager: Changed ${riskId} risk to ${days} days`
        );
      });
  }

  async function createTrainingModule(data) {
    return new Promise(async (res, rej) => {
      const countRef = companyRef
        .collection("trainingCount")
        .doc("moduleCount");

      const createTrainingCount = async () => {
        await countRef.set({
          moduleCount: 1,
        });
      };

      const moduleCount = await countRef.get().then(async (doc) => {
        if (!doc.exists) {
          await createTrainingCount();
          return 1;
        } else return doc.data().moduleCount;
      });

      await companyRef
        .collection("trainingModules")
        .add({
          ...data,
          createdBy: currentUser.uid,
          timestamp: new Date(),
          code: `TM-${moduleCount + 1}`,
        })
        .then(async (doc) => {
          await countRef.update({
            moduleCount: fieldValue.increment(1),
          });
          await logAuditTrail(
            currentUser.uid,
            `Created a new Training Module: ${data.name}`
          );
          res({
            id: doc.id,
            code: `TM-${moduleCount + 1}`,
          });
        });
    });
  }

  function GetTrainingModules() {
    const [modules, setModules] = useState([]);
    useEffect(() => {
      const unsubscribe = companyRef
        .collection("trainingModules")
        .orderBy("timestamp", "desc")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ref: doc.ref,
            ...doc.data(),
            label: `${doc.data().code} - ${doc.data().name}`,
            value: {
              id: doc.id,
              ...doc.data(),
            },
          }));
          setModules(data);
        });
      return unsubscribe;
    }, []);
    return modules;
  }

  function fixModules(data) {
    data.ref.update({
      modulefixed: true,
      owner: {
        id: data.owner.id,
        firstName: data.owner.firstName,
        lastName: data.owner.lastName,
        email: data.owner.email,
      },
    });
  }

  async function getTrainingModule(id) {
    return await companyRef
      .collection("trainingModules")
      .doc(id)
      .get()
      .then((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });
  }

  async function createOccupation(data) {
    return await companyRef
      .collection("occupations")
      .add({
        ...data,
        requiredModules: data.requiredModules ? data.requiredModules : [],
        timestamp: new Date(),
        createdBy: currentUser.uid,
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Added an Occupation: ${data.title}`
        );
      });
  }

  function GetOccupations() {
    const [occupations, setOccupations] = useState([]);
    useEffect(() => {
      const unsubscribe = companyRef
        .collection("occupations")
        .orderBy("title")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            label: doc.data().title,
            value: {
              id: doc.id,
              title: doc.data().title,
            },
          }));
          setOccupations(data);
        });
      return unsubscribe;
    }, []);
    return occupations;
  }

  async function getOccupation(id) {
    return await companyRef
      .collection("occupations")
      .doc(id)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
  }

  async function requireModule(occupationId, modules, titles) {
    const jobRef = companyRef.collection("occupations").doc(occupationId);

    const job = await jobRef.get().then((doc) => {
      return { id: doc.id, ...doc.data() };
    });

    return await jobRef
      .update({
        requiredModules: modules,
        requiredTitles: titles,
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Updated Occupation Required Training: ${job.title}`
        );
      });
  }

  async function updateOccupationName(occId, data) {
    return await companyRef.collection("occupations").doc(occId).update({
      title: data,
    });
  }

  async function addVenue(data) {
    return new Promise(async (res, rej) => {
      await companyRef
        .collection("trainingVenues")
        .add({
          ...data,
          timestamp: new Date(),
          createdBy: currentUser.uid,
        })
        .then(async (doc) => {
          await logAuditTrail(
            currentUser.uid,
            `Created a new training venue at ${data.siteLabel}: ${data.name}`
          );
          res(doc.id);
        });
    });
  }

  function GetTrainingVenues() {
    const [venues, setVenues] = useState([]);
    useEffect(() => {
      const unsubscribe = companyRef
        .collection("trainingVenues")
        .orderBy("name")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ref: doc.ref,
            ...doc.data(),
            label: doc.data().name,
            value: {
              id: doc.id,
              ...doc.data(),
            },
          }));
          setVenues(data);
        });
      return unsubscribe;
    }, []);
    return venues;
  }

  async function getTrainingVenue(id) {
    return await companyRef
      .collection("trainingVenues")
      .doc(id)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
  }

  async function createNewEmployee(data) {
    return new Promise(async (res, rej) => {
      await companyRef
        .collection("employeeCreateRequests")
        .add({
          ...data,
        })
        .then(async () => {
          await logAuditTrail(
            currentUser.uid,
            `Requested to create a new employee ${data.firstName} ${data.lastName}`
          );
          res();
        });
    });
  }

  async function checkEmployeeExists(email) {
    return await companyRef
      .collection("employees")
      .where("email", "==", email)
      .get()
      .then((snap) => {
        if (!snap.empty) {
          throw {
            message:
              "An employee with this email already exists. Switch off Create/Link user to create an employee with no account.",
          };
        } else return;
      });
  }

  async function addFacilitator(data, newUser) {
    if (!data.external) {
      data.external = false;
    }
    return new Promise(async (res, rej) => {
      if (newUser) {
        await companyRef
          .collection("newUserFacilitator")
          .add({
            ...data,
            timestamp: new Date(),
            createdBy: currentUser.uid,
          })
          .then(async () => {
            await logAuditTrail(
              currentUser.uid,
              `Created a new facilitator ${data.firstName} ${data.lastName}`
            );
            res();
          });
      } else {
        await companyRef
          .collection("trainingFacilitators")
          .doc(data.id)
          .set({
            ...data,
            timestamp: new Date(),
            createdBy: currentUser.uid,
          })
          .then(async () => {
            await logAuditTrail(
              currentUser.uid,
              `Created a new facilitator ${data.firstName} ${data.lastName}`
            );
            res();
          });
      }
    });
  }

  async function getTrainingVenuesForSite(siteId) {
    let venues = [];
    await companyRef
      .collection("trainingVenues")
      .where("site", "==", siteId)
      .orderBy("name")
      .get()
      .then((snap) => {
        snap.docs.map((doc) => {
          venues.push({
            id: doc.id,
            ...doc.data(),
            label: doc.data().name,
            value: {
              id: doc.id,
              ...doc.data(),
            },
          });
        });
      });
    return venues;
  }

  async function getTrainingFacilitators(externalBool, providerId) {
    if (externalBool) {
      return await companyRef
        .collection("trainingFacilitators")
        .where("external", "==", true)
        .where("provider.id", "==", providerId)
        .get()
        .then((snap) => {
          if (!snap.empty) {
            const providers = snap.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
              label: `${doc.data().firstName} ${doc.data().lastName}`,
              value: {
                id: doc.id,
                label: `${doc.data().firstName} ${doc.data().lastName}`,
                email: doc.data().email,
              },
            }));
            return providers;
          } else return [];
        });
    } else {
      return await companyRef
        .collection("trainingFacilitators")
        .where("external", "==", false)
        .get()
        .then((snap) => {
          if (!snap.empty) {
            const providers = snap.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
              label: `${doc.data().firstName} ${doc.data().lastName}`,
              value: {
                id: doc.id,
                label: `${doc.data().firstName} ${doc.data().lastName}`,
                email: doc.data().email,
              },
            }));
            return providers;
          } else return [];
        });
    }
  }

  async function scheduleTraining(data) {
    return new Promise(async (res, rej) => {
      // Create the session
      await companyRef
        .collection("trainingSessions")
        .add({
          ...data,
        })
        .then(async (doc) => {
          // Update the booked times on the venue
          await companyRef
            .collection("trainingVenues")
            .doc(data.venue.id)
            .update({
              bookings: fieldValue.arrayUnion({
                endTime: data.endTime,
                startTime: data.startTime,
                id: doc.id,
              }),
            });

          const date = new Date();
          const year = date.getFullYear();
          const month = date.toLocaleString("default", { month: "long" });

          // Update the counter for the dashboard
          await companyRef
            .collection("trainingCount")
            .doc("sessionCount")
            .set(
              {
                [year]: {
                  [month]: fieldValue.increment(1),
                },
              },
              { merge: true }
            );

          // log audit and send data back to client
          await logAuditTrail(
            currentUser.uid,
            `Schedules ${data.module.name} for ${moment(data.startTime).format(
              "DD/MM/YYYY"
            )}`
          );
          res(doc.id);
        });
    });
  }

  async function getTrainingSessionByModuleAndAttendee(moduleId, uid) {
    return new Promise((resolve, reject) => {
      companyRef
        .collection("trainingSessions")
        .where("attendees", "array-contains", uid)
        .where("module.id", "==", moduleId)
        .orderBy("endTime", "desc")
        .get()
        .then((docs) => {
          if (docs.empty) {
            resolve(null);
          } else {
            resolve(docs.docs[0].data());
          }
        });
    });
  }

  async function getTrainingSession(sessionId) {
    return await companyRef
      .collection("trainingSessions")
      .doc(sessionId)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
  }

  function GetUpcomingTrainingSessions() {
    const [sessions, setSessions] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("trainingSessions")
        .where("startTime", ">=", new Date())
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setSessions(data);
        });
      return unsubscribe;
    }, []);
    return sessions;
  }

  function GetPastTrainingSessions() {
    const [sessions, setSessions] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("trainingSessions")
        .where("startTime", "<=", new Date())
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setSessions(data);
        });
      return unsubscribe;
    }, []);
    return sessions;
  }

  function GetPastTrainingSessionsDropdown() {
    const [sessions, setSessions] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("trainingSessions")
        .where("endTime", "<=", new Date())
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            value: doc.id,
            label: `${moment(doc.data().startTime.toDate()).format(
              "DD/MM/YY"
            )}: ${doc.data().module.name}`,
          }));
          setSessions(data);
        });
      return unsubscribe;
    }, []);
    return sessions;
  }

  function GetUserUpcomingTrainingSessions() {
    const [sessions, setSessions] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("trainingSessions")
        .where("startTime", ">=", new Date())
        .where("attendees", "array-contains", currentUser.uid)
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setSessions(data);
        });
      return unsubscribe;
    }, []);
    return sessions;
  }

  async function addAttendeesToSession(sessionId, data) {
    return new Promise(async (res, rej) => {
      const sessionRef = companyRef
        .collection("trainingSessions")
        .doc(sessionId);

      for (let i = 0; i < data.attendees.length; i++) {
        let uid;
        if (data.attendees[i].email) {
          await db
            .collection("users")
            .where("email", "==", data.attendees[i].email)
            .get()
            .then((snap) => {
              if (!snap.empty) {
                uid = snap.docs[0].id;
              }
            });
        }

        await sessionRef.update({
          attendees: fieldValue.arrayUnion(uid ? uid : data.attendees[i].id),
        });

        await sessionRef
          .collection("attendees")
          .doc(data.attendees[i].id)
          .set({
            ...data.attendees[i],
            id: uid ? uid : data.attendees[i].id,
          });
      }
      res();
    });
  }

  async function attendeeInviteResponse(sessionId, resBool, reason) {
    return await companyRef
      .collection("trainingSessions")
      .doc(sessionId)
      .collection("attendees")
      .doc(currentUser.uid)
      .update({
        responded: true,
        attending: resBool,
        reason: reason,
      });
  }

  function GetAttendees(sessionId) {
    const [plebs, setPlebs] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("trainingSessions")
        .doc(sessionId)
        .collection("attendees")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          setPlebs(data);
        });
      return unsubscribe;
    }, [sessionId]);
    return plebs;
  }

  function GetFacilitatorUpcomingSessions() {
    const [sessions, setSessions] = useState([]);

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("trainingSessions")
        .where("facilitator.id", "==", currentUser.uid)
        .where("startTime", ">=", yesterday)
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setSessions(data);
        });
      return unsubscribe;
    }, []);
    return sessions;
  }

  function GetFacilitatorPreviousSessions() {
    const [sessions, setSessions] = useState([]);

    const today = new Date();

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("trainingSessions")
        .where("facilitator.id", "==", currentUser.uid)
        .where("startTime", "<", today)
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setSessions(data);
        });
      return unsubscribe;
    }, []);
    return sessions;
  }

  async function confirmAbsence(sessionId, attendeeId) {
    return await companyRef
      .collection("trainingSessions")
      .doc(sessionId)
      .collection("attendees")
      .doc(attendeeId)
      .update({
        assessment: null,
        assessed: true,
        attended: false,
      });
  }

  async function confirmAttend(sessionId, attendeeId) {
    return await companyRef
      .collection("trainingSessions")
      .doc(sessionId)
      .collection("attendees")
      .doc(attendeeId)
      .update({
        assessment: null,
        assessed: false,
        attended: true,
      });
  }

  async function assessCompetence(sessionId, attendeeId, data) {
    return new Promise(async (res, rej) => {
      const sessionRef = companyRef
        .collection("trainingSessions")
        .doc(sessionId);

      const employeeRef = companyRef.collection("employees").doc(attendeeId);

      await sessionRef.collection("attendees").doc(attendeeId).update({
        assessment: data,
        assessed: true,
        attended: true,
      });

      const session = await sessionRef.get().then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      const attendee = await employeeRef.get().then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      Date.prototype.addDays = function (days) {
        let thisDate = new Date();
        thisDate.setDate(thisDate.getDate() + days);
        return thisDate;
      };

      const frequency = parseInt(session.module.frequency);
      const nextTrainingDue = new Date().addDays(frequency);

      await employeeRef
        .collection("trainingCompetency")
        .doc(session.module.id)
        .set(
          {
            name: session.module.name,
            assessment: data,
            compLevel: data.compLevel,
            nextTrainingDue,
            evaluationRequested: session.module.evaluationRequested,
            practicalRequired: session.module.practicalRequired,
          },
          { merge: true }
        )
        .then(async (doc) => {
          await logAuditTrail(
            currentUser.uid,
            `Assessed Training: ${session.module.name} for ${attendee.firstName} ${attendee.lastName}`
          );
        });
      res();
    });
  }

  function GetAllCompetency() {
    const [modules, setModules] = useState([]);

    useEffect(() => {
      const unsubscribe = db
        .collectionGroup("trainingCompetency")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            compId: doc.id,
            ...doc.data(),
            ref: doc.ref,
          }));
          setModules(data);
        });
      return unsubscribe;
    }, []);
    return modules;
  }

  async function updateCompentancy(data) {
    return await data.ref.update({
      compFixed: true,
      module: {
        code: data.module.code,
        name: data.module.name,
      },
    });
  }

  function GetEmployeeCompetency(empId) {
    const [modules, setModules] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("employees")
        .doc(empId)
        .collection("trainingCompetency")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            label: doc.data().name,
            value: { ...doc.data(), id: doc.id },
          }));
          setModules(data);
        });
      return unsubscribe;
    }, [empId]);
    return modules;
  }

  function GetEmployeeCompetencyEvaluationsOnly(empId) {
    const [modules, setModules] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("employees")
        .doc(empId)
        .collection("trainingCompetency")
        .where("module.evaluationRequested", "==", true)
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            label: doc.data().name,
            value: { ...doc.data(), id: doc.id },
          }));
          setModules(data);
        });
      return unsubscribe;
    }, [empId]);
    return modules;
  }

  async function getEmployee(empId) {
    return await companyRef
      .collection("employees")
      .doc(empId)
      .get()
      .then((doc) => {
        if (doc.exists)
          return {
            ...doc.data(),
            id: doc.id,
          };
        else return null;
      });
  }

  async function updateEmployeeDetails(empId, data) {
    try {
      return await companyRef
        .collection("employees")
        .doc(empId)
        .update({
          ...data,
        });
    } catch (error) {
      console.log(error.message);
    }
  }

  async function createAttendanceRegister(session) {
    let attendees = [];
    await companyRef
      .collection("trainingSessions")
      .doc(session.id)
      .collection("attendees")
      .get()
      .then((snap) => {
        if (!snap.empty) {
          snap.docs.map((doc) => {
            attendees.push({
              ...doc.data(),
              id: doc.id,
            });
          });
        }
      });

    let bodyArray = [];
    attendees.map((a) => {
      bodyArray.push([
        `${a.firstName} ${a.lastName}`,
        a.attended ? "YES" : "NO",
        a.assessment ? a.assessment.compLevel : "None",
        "_______________",
      ]);
    });

    const pdf = new jsPDF();
    let img = new Image();
    img.src = await getCompanyLogo();

    pdf.addImage(
      img,
      "PNG",
      80,
      10,
      pdf.canvas.width * 0.3,
      pdf.canvas.height * 0.15
    );
    pdf.setFontSize(14);
    pdf.text(`Attendance Register: ${session.module.name}`, 13, 60);

    pdf.autoTable({
      head: [["Attendee", "Attended", "Competency", "Signature"]],
      body: bodyArray,
      margin: { top: 80 },
    });

    return pdf.save("Attendance Register.pdf");
  }

  async function requestTraining(data) {
    return await companyRef
      .collection("trainingRequests")
      .add({
        ...data,
        createdBy: currentUser.uid,
        timestamp: new Date(),
      })
      .then(async (doc) => {
        await logAuditTrail(
          currentUser.uid,
          `Requested Training: ${data.module.name} for ${data.employee.firstName} ${data.employee.lastName} on behalf of ${data.requestedBy.firstName} ${data.requestedBy.lastName}`
        );
      });
  }

  async function newEmployeeEvaluation(data) {
    const employee = await companyRef
      .collection("employees")
      .doc(data.empId)
      .get()
      .then((doc) => {
        return { ...doc.data(), id: doc.id };
      });

    return await companyRef
      .collection("trainingEvaluations")
      .add({
        ...data,
        employee: {
          firstName: employee.firstName,
          lastName: employee.lastName,
        },
        createdBy: currentUser.uid,
        timestamp: new Date(),
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Evaluated: ${employee.firstName} ${employee.lastName} for ${data.module.name}`
        );
      });
  }

  function GetEvaluations() {
    const [evaluations, setEvaluations] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("trainingEvaluations")
        .orderBy("timestamp", "desc")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setEvaluations(data);
        });
      return unsubscribe;
    }, []);
    return evaluations;
  }

  function GetEmployeeEvaluations(empId) {
    const [evaluations, setEvaluations] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("trainingEvaluations")
        .where("empId", "==", empId)
        .orderBy("timestamp", "desc")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setEvaluations(data);
        });
      return unsubscribe;
    }, [empId]);
    return evaluations;
  }

  async function employeeCompetencyCertificate(empId) {
    return new Promise(async (res, rej) => {
      const employee = await companyRef
        .collection("employees")
        .doc(empId)
        .get()
        .then((doc) => {
          return { ...doc.data(), id: doc.id };
        });

      let mods = [];
      await companyRef
        .collection("employees")
        .doc(empId)
        .collection("trainingCompetency")
        .get()
        .then((snap) => {
          if (!snap.empty) {
            snap.docs.map((doc) => {
              const mod = doc.data();
              if (mod.certificateRequired && mod.compLevel > 2) {
                mods.push({
                  ...mod,
                  id: doc.id,
                });
              }
            });
          }
        });

      let bodyArray = [];
      mods.map((m) => {
        bodyArray.push([
          `${m.name}`,
          `${moment(m.nextTrainingDue.toDate()).format("DD/MM/YYYY")}`,
        ]);
      });

      const pdf = new jsPDF();
      let img = new Image();
      img.src = await getCompanyLogo();

      pdf.addImage(
        img,
        "PNG",
        80,
        10,
        pdf.canvas.width * 0.3,
        pdf.canvas.height * 0.15
      );
      pdf.setFontSize(12);
      pdf.text(`Competency Certificate`, 13, 60);
      pdf.text(
        `This is to Certify that: ${employee.firstName}${
          employee.middleName ? ` ${employee.middleName}` : ""
        } ${employee.lastName}`,
        13,
        70
      );
      pdf.text(
        `Has completed training of the following modules, and passed successfully:`,
        13,
        80
      );

      pdf.autoTable({
        head: [["Module", "Expiry Date"]],
        body: bodyArray,
        margin: { top: 90 },
      });

      res(
        pdf.save(
          `${employee.firstName} ${employee.lastName} - Competency Certificate`
        )
      );
    });
  }

  async function newTrainingMatrixReport(request) {
    return new Promise(async (res, rej) => {
      let rows = [];
      await companyRef
        .collection("employees")
        .get()
        .then(async (snap) => {
          if (!snap.empty) {
            for (let i = 0; i < snap.docs.length; i++) {
              let competencies = [];
              await snap.docs[i].ref
                .collection("trainingCompetency")
                .get()
                .then((snap2) => {
                  if (!snap2.empty) {
                    return snap2.docs.forEach((compDoc) => {
                      competencies.push({
                        ...compDoc.data(),
                        id: compDoc.id,
                      });
                    });
                  }
                });
              const employee = {
                ...snap.docs[i].data(),
                id: snap.docs[i].id,
                competencies,
              };
              rows.push(employee);
            }
          } else rej({ message: "No Employees Found" });
        });

      await companyRef
        .collection("trainingMatrixReports")
        .add({
          timestamp: new Date(),
          createdBy: currentUser.uid,
          results: rows,
        })
        .then(async (doc) => {
          await logAuditTrail(
            currentUser.uid,
            `Created a new Training Matrix Report`
          );
          res(doc.id);
        });
    });
  }

  async function getTrainingMatrix(id) {
    return await companyRef
      .collection("trainingMatrixReports")
      .doc(id)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
  }

  function GetTrainingMatrixReports() {
    const [reports, setReports] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("trainingMatrixReports")
        .orderBy("timestamp", "desc")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setReports(data);
        });
      return unsubscribe;
    }, []);
    return reports;
  }

  function GetFacilitators() {
    const [facils, setFacils] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("trainingFacilitators")
        .orderBy("firstName")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ref: doc.ref,
            ...doc.data(),
          }));
          setFacils(data);
        });
      return unsubscribe;
    }, []);
    return facils;
  }

  function GetTrainingRequests() {
    const [requests, setRequests] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("trainingRequests")
        .orderBy("timestamp", "desc")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setRequests(data);
        });
      return unsubscribe;
    }, []);
    return requests;
  }

  function GetSessionsOfModule(moduleId) {
    const [sessions, setSessions] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("trainingSessions")
        .where("module.id", "==", moduleId)
        .where("startTime", ">=", new Date())
        .orderBy("startTime")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setSessions(data);
        });
      return unsubscribe;
    }, [moduleId]);
    return sessions;
  }

  async function deleteTrainingRequest(requestId) {
    return await companyRef
      .collection("trainingRequests")
      .doc(requestId)
      .delete()
      .then(async () => {
        await logAuditTrail(currentUser.uid, `Assigned Training Request`);
      });
  }

  async function getYearSessionCount() {
    const year = new Date().getFullYear();
    return await companyRef
      .collection("trainingCount")
      .doc("sessionCount")
      .get()
      .then((doc) => {
        if (!doc.exists) {
          return {
            [year]: {},
          };
        } else return doc.data()[year];
      });
  }

  async function getSessionReport(start, end, filters) {
    const sessionsRef = companyRef.collection("trainingSessions");
    let query = sessionsRef
      .where("startTime", ">=", start)
      .where("startTime", "<=", end);

    for (let key in filters) {
      query = query.where(key, "==", filters[key]);
    }

    return await query.get().then((snap) => {
      if (!snap.empty) {
        const sessions = snap.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        return sessions;
      } else return [];
    });
  }

  async function saveSessionReport(data) {
    return new Promise(async (res, rej) => {
      const sessions = data.map((s) => ({
        id: s.id,
        attendees: s.attendees ? s.attendees.length : 0,
        cost: s.attendees ? s.attendees.length * parseFloat(s.cost) : 0,
        startTime: s.startTime,
        endTime: s.endTime,
        site: s.siteLabel,
        inhouse: s.source === "inhosue" ? true : false,
        venue: s.venue.name,
        moduleCode: s.module.code,
        moduleName: s.module.name,
      }));

      await companyRef
        .collection("sessionReports")
        .add({
          timestamp: new Date(),
          sessions,
          createdBy: currentUser.uid,
        })
        .then(async (doc) => {
          await logAuditTrail(
            currentUser.uid,
            `Ran a Training Sessions Report`
          );
          res(doc.id);
        });
    });
  }

  function GetSessionReports() {
    const [reports, setReports] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("sessionReports")
        .orderBy("timestamp", "desc")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setReports(data);
        });
      return unsubscribe;
    }, []);
    return reports;
  }

  async function singleSessionReport(reportId) {
    return await companyRef
      .collection("sessionReports")
      .doc(reportId)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
  }

  async function generateAttendanceReport(sessionId) {
    return new Promise(async (res, rej) => {
      await companyRef
        .collection("trainingSessions")
        .doc(sessionId)
        .collection("attendees")
        .get()
        .then((snap) => {
          if (snap.empty) {
            rej({
              message: "This session has no attendees",
            });
          } else {
            const attendees = snap.docs.map((doc) => ({
              id: doc.id,
              attended: doc.data().attended,
              name: `${doc.data().firstName} ${doc.data().lastName}`,
            }));
            res(attendees);
          }
        });
    });
  }

  async function saveAttendanceReport(data) {
    return new Promise(async (res, rej) => {
      await companyRef
        .collection("attendanceReports")
        .add({
          attendees: data,
          createdBy: currentUser.uid,
          timestamp: new Date(),
        })
        .then(async (doc) => {
          await logAuditTrail(currentUser.uid, `Ran an Attendace Report`);
          res(doc.id);
        });
    });
  }

  async function retrieveAttendaceReport(reportId) {
    return await companyRef
      .collection("attendanceReports")
      .doc(reportId)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
  }

  async function getFacilitator(facilitatorId) {
    return await companyRef
      .collection("trainingFacilitators")
      .doc(facilitatorId)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
  }

  async function addOccupationRequest(employeeId, newOccupation) {
    let employee = await companyRef
      .collection("employees")
      .doc(employeeId)
      .get();

    console.log(employee.data());

    let occupation = await employee.data().occupation;

    await companyRef
      .collection("employees")
      .doc(employeeId)
      .collection("occupationUpdates")
      .add({
        createdBy: currentUser.uid,
        id: newOccupation.id,
        label: newOccupation.title,
      })
      .then(async (doc) => {
        await logAuditTrail(currentUser.uid, `Updated Employee occupations`);
      });

    await companyRef
      .collection("employees")
      .doc(employeeId)
      .update({
        occupation: fieldValue.arrayUnion({
          createdBy: currentUser.uid,
          id: newOccupation.id,
          label: newOccupation.title,
        }),
      });
  }

  async function removeOccupation({ employeeId, occupation }) {
    // console.log(employeeId)
    // console.log(occupationId)

    await companyRef
      .collection("employees")
      .doc(employeeId)
      .collection("occupationDelete")
      .add({
        deletedBy: currentUser.uid,
        id: occupation.id,
        occupation: { ...occupation },
      })
      .then(async () => {
        const occupationArray = await companyRef
          .collection("employees")
          .doc(employeeId)
          .get()
          .then((doc) => doc.data().occupation);

        companyRef
          .collection("employees")
          .doc(employeeId)
          .update({
            occupation: occupationArray.filter((val) => {
              return val.id != occupation.id;
            }),
          });
      })
      .then(async (doc) => {
        await logAuditTrail(currentUser.uid, `Delete Employee occupation`);
      });
  }

  async function addEmpSubfolder(docId, data) {
    return companyRef
      .collection("employees")
      .doc(docId)
      .collection("subfolders")
      .add({
        ...data,
      })
      .then(async (doc) => {
        await logAuditTrail(currentUser.uid, `Added attachment to employee`);
        return doc;
      })
      .catch((err) => {
        console.log("Error Added attachment to employee: ", err.message);
        return null;
      });
  }

  function GetEmpSubfolders(docId) {
    const [folders, setFolders] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("employees")
        .doc(docId)
        .collection("subfolders")
        .onSnapshot((snapshot) => {
          const folderList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setFolders(folderList);
        });
      return unsubscribe;
    }, [docId]);
    return folders;
  }

  async function removeEmpSubfolder(docId, folderId) {
    return companyRef
      .collection("employees")
      .doc(docId)
      .collection("subfolders")
      .doc(folderId)
      .delete()
      .then(async (doc) => {
        await logAuditTrail(
          currentUser.uid,
          `Removed a Sub-Folder for employee`
        );
        return doc;
      })
      .catch((err) => {
        console.log("Error Deleting Employee Subfolder: ", err.message);
        return null;
      });
  }

  function GetEmpSupportingDocs(docId) {
    const [supDocs, setSupDocs] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("employees")
        .doc(docId)
        .collection("supportingDocs")
        .orderBy("fileName")
        .onSnapshot((snapshot) => {
          const docList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setSupDocs(docList);
        });
      return unsubscribe;
    }, [docId]);
    return supDocs;
  }

  function GetEmpSubfoldersDropdown(docId) {
    const [folders, setFolders] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("employees")
        .doc(docId)
        .collection("subfolders")
        .orderBy("folderName")
        .onSnapshot((snapshot) => {
          const folderList = snapshot.docs.map((doc) => ({
            id: doc.id,
            value: { ...doc.data() },
            label: doc.data().folderName,
          }));
          setFolders(folderList);
        });
      return unsubscribe;
    }, [docId]);
    return folders;
  }

  async function addEmpSupportingDoc(docId, data, user) {
    return companyRef
      .collection("employees")
      .doc(docId)
      .collection("supportingDocs")
      .add({
        ...data,
        folderId: data.folder ? data.folder.id : "",
        createdAt: new Date(),
        submittedBy: user,
        parentDocId: docId,
      })
      .then(async (doc) => {
        await logAuditTrail(
          currentUser.uid,
          `Added a supporting document employee`
        );
        return doc;
      })
      .catch((err) => {
        console.log("Error Adding Employee Supporting Document: ", err.message);
        return null;
      });
  }

  // -------------------------------------------------- Risk Manager --------------------------------------------------

  async function updateProgress(progress) {
    await companyRef.update({
      riskSetupProgress: progress,
    });
  }

  async function updateSetupComplete(bool) {
    await companyRef.update({
      riskSetupComplete: bool,
    });
  }

  async function updateRiskSetting(data) {
    return await companyRef.update({
      riskSettings: { ...data },
    });
  }

  function GetRiskAreas() {
    const [areas, setAreas] = useState([]);

    useEffect(() => {
      const areasCollection = companyRef.collection("riskAreas");

      const unsubscribe = areasCollection.onSnapshot((snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAreas(data);
      });
      return unsubscribe;
    }, []);
    return areas;
  }

  function GetRiskAreasForSite(siteId) {
    const [areas, setAreas] = useState([]);

    useEffect(() => {
      const areasCollection = companyRef.collection("riskAreas");

      const unsubscribe = areasCollection
        .where("site.id", "==", siteId)
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            value: {
              id: doc.id,
              ...doc.data(),
            },
            label: doc.data().areaName,
          }));
          setAreas(data);
        });
      return unsubscribe;
    }, [siteId]);
    return areas;
  }

  async function addRiskAreas(data) {
    await companyRef.collection("riskAreas").add({
      ...data,
    });
  }

  function GetRiskTypes() {
    const [riskTypes, setRiskTypes] = useState([]);

    useEffect(() => {
      const riskTypeCollection = companyRef.collection("riskTypes");

      const unsubscribe = riskTypeCollection.onSnapshot((snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          value: {
            id: doc.id,
            ...doc.data(),
          },
          label: doc.data().riskType,
        }));
        setRiskTypes(data);
      });
      return unsubscribe;
    }, []);
    return riskTypes;
  }

  async function addRiskType(data) {
    await companyRef.collection("riskTypes").add({
      ...data,
    });
  }

  function GetRiskEntities() {
    const [riskEntities, setRiskEntities] = useState([]);

    useEffect(() => {
      const riskEntitiesCollection = companyRef.collection("riskEntities");

      const unsubscribe = riskEntitiesCollection.onSnapshot((snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          value: {
            id: doc.id,
            ...doc.data(),
          },
          label: doc.data().riskEntity,
        }));
        setRiskEntities(data);
      });
      return unsubscribe;
    }, []);
    return riskEntities;
  }

  async function addRiskEntities(data) {
    await companyRef.collection("riskEntities").add({
      ...data,
    });
  }

  function GetRiskPatterns() {
    const [riskPatterns, setRiskPatterns] = useState([]);

    useEffect(() => {
      const riskPatternsCollection = companyRef.collection("riskPatterns");

      const unsubscribe = riskPatternsCollection.onSnapshot((snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          value: {
            id: doc.id,
            ...doc.data(),
          },
          label: doc.data().frequency,
        }));
        setRiskPatterns(data);
      });
      return unsubscribe;
    }, []);
    return riskPatterns;
  }

  async function addRiskPatterns(data) {
    await companyRef.collection("riskPatterns").add({
      ...data,
    });
  }

  async function addCriticalThreshold({ low, med, high }) {
    await companyRef.collection("riskCriticalControls").doc("low").set({
      value: low,
    });

    await companyRef.collection("riskCriticalControls").doc("medium").set({
      value: med,
    });

    await companyRef.collection("riskCriticalControls").doc("high").set({
      value: high,
    });

    await companyRef.update({
      riskControls: {
        low,
        med,
        high,
      },
    });
  }

  async function updateCriticalThreshold(low, med, high) {
    await companyRef.collection("riskCriticalControls").doc("low").update({
      value: low,
    });

    await companyRef
      .collection("riskCriticalControls")
      .doc("med")
      .get()
      .then((snap) => {
        if (snap.exists) {
          snap.ref.update({
            value: med,
          });
        } else {
          companyRef.collection("riskCriticalControls").doc("medium").update({
            value: med,
          });
        }
      });

    await companyRef.update({
      riskControls: {
        low,
        med,
        high,
      },
    });
  }

  async function addRiskHierarchy({
    elimination,
    substitution,
    isolation,
    engineering,
    administrativeControls,
    ppe,
  }) {
    await companyRef.collection("riskHierarchy").doc("elimination").set({
      value: elimination,
      label: "Elimination",
    });

    await companyRef.collection("riskHierarchy").doc("substitution").set({
      value: substitution,
      label: "Substitution",
    });

    await companyRef.collection("riskHierarchy").doc("isolation").set({
      value: isolation,
      label: "Isolation",
    });

    await companyRef.collection("riskHierarchy").doc("engineering").set({
      value: engineering,
      label: "Engineering",
    });

    await companyRef
      .collection("riskHierarchy")
      .doc("administrativeControls")
      .set({
        value: administrativeControls,
        label: "Administrative Controls",
      });

    await companyRef.collection("riskHierarchy").doc("ppe").set({
      value: ppe,
      label: "PPE",
    });
  }

  function createBaseline(data) {
    return new Promise(async (res, rej) => {
      // Determine what baseline number this is. First check what number the baselines are for year
      const year = new Date().getFullYear().toString();

      let currentYearBaselines = await companyRef
        .collection("riskBaselines")
        .doc(year)
        .get()
        .then((doc) => {
          console.log("Inside Get Doc");
          if (!doc.exists) return null;
          else return doc.data().count;
        });

      // If none exists, create it
      if (!currentYearBaselines) {
        await companyRef.collection("riskBaselines").doc(year).set({
          count: 1,
        });

        currentYearBaselines = 0;
      }

      // Generate the baseline number
      const blra = `BLRA-${currentYearBaselines + 1}-${year}-${
        data.site.label
      }`;

      await companyRef
        .collection("riskBaselineAssessments")
        .add({
          blra,
          participants: data.people,
          site: data.site,
          created: new Date(),
          currentStep: 2,
        })
        .then(async (doc) => {
          await logAuditTrail(
            currentUser.uid,
            `Created a new Baseline Risk Assessment: ${blra}`
          );
          await companyRef
            .collection("riskBaselines")
            .doc(year)
            .update({ count: fieldValue.increment(1) });
          res(doc.id);
        });
    });
  }

  async function getBaseline(blraId) {
    return await companyRef
      .collection("riskBaselineAssessments")
      .doc(blraId)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
  }

  function GetBaselines() {
    const [blras, setBlras] = useState([]);
    useEffect(() => {
      const unsubscribe = companyRef
        .collection("riskBaselineAssessments")
        .orderBy("created", "desc")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            label: doc.data().blra,
            value: doc.id,
          }));
          setBlras(data);
        });
      return unsubscribe;
    }, []);
    return blras;
  }

  async function addRiskProcess(data) {
    return await companyRef
      .collection("riskProcesses")
      .add({
        ...data,
        timestamp: new Date(),
        createdBy: currentUser.uid,
      })
      .then(async (doc) => {
        await logAuditTrail(
          currentUser.uid,
          `Created a new Risk Process: ${data.name}`
        );
      });
  }

  async function updateRiskProcess(processId, data) {
    return await companyRef
      .collection("riskProcesses")
      .doc(processId)
      .update({
        ...data,
      })
      .then(async (doc) => {
        await logAuditTrail(
          currentUser.uid,
          `Updated a new Process: ${data.name}`
        );
      });
  }

  async function getRiskProcess(processId) {
    return await companyRef
      .collection("riskProcesses")
      .doc(processId)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
  }

  function deleteProcessStep(processId, step) {
    console.log(processId, step);
    return companyRef
      .collection("riskProcesses")
      .doc(processId)
      .update({
        steps: fieldValue.arrayRemove(step),
      });
  }

  function GetRiskProcesses() {
    const [procs, setProcs] = useState([]);
    useEffect(() => {
      const unsubscribe = companyRef
        .collection("riskProcesses")
        .orderBy("name")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            value: doc.id,
            label: doc.data().name,
          }));
          setProcs(data);
        });
      return unsubscribe;
    }, []);
    return procs;
  }

  async function advanceBaseline(blra, blraId, data) {
    return await companyRef
      .collection("riskBaselineAssessments")
      .doc(blraId)
      .update({
        ...data,
        currentStep: fieldValue.increment(1),
      })
      .then(async (doc) => {
        await logAuditTrail(
          currentUser.uid,
          `Progressed on Baseline Risk Assessment: ${blra}`
        );
      });
  }

  async function addProcessesToBaseline(blraId, processArray) {
    return new Promise(async (res, rej) => {
      const blraRef = companyRef
        .collection("riskBaselineAssessments")
        .doc(blraId);

      // Map through the process array
      // In each process, add to a promise array that creates processes, using the existing id as the doc id
      const processPromises = processArray.map((p) => {
        return blraRef.collection("processes").doc(p.id).set({
          id: p.id,
          name: p.name,
        });
      });

      // Also map through the steps in each process and add that to a step array, making sure to reference the parent process
      const stepPromises = processArray.map((p) => {
        return p.steps.map((s) => {
          return blraRef.collection("processSteps").add({
            value: s,
            process: p.id,
          });
        });
      });

      // Execute the promise arrays
      await Promise.all(processPromises);
      await Promise.all(stepPromises);

      // Resolve
      res();
    });
  }

  function GetBaselineProcesses(blraId) {
    const [procs, setProcs] = useState([]);
    const blraRef = companyRef
      .collection("riskBaselineAssessments")
      .doc(blraId);

    useEffect(() => {
      const unsubscribe = blraRef
        .collection("processes")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            label: doc.data().name,
            value: doc.id,
          }));
          setProcs(data);
        });
      return unsubscribe;
    }, [blraId]);
    return procs;
  }

  async function retrieveBaselineProcesses(blraId) {
    const blraRef = companyRef
      .collection("riskBaselineAssessments")
      .doc(blraId);
    const procs = await blraRef
      .collection("processes")
      .get()
      .then((snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          label: doc.data().name,
          value: doc.id,
        }));
        return data;
      });

    return procs;
  }

  function GetAllBaselineProcessSteps(blraId) {
    const [steps, setSteps] = useState([]);
    const blraRef = companyRef
      .collection("riskBaselineAssessments")
      .doc(blraId);

    useEffect(() => {
      const unsubscribe = blraRef
        .collection("processSteps")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setSteps(data);
        });
      return unsubscribe;
    }, [blraId]);
    return steps;
  }

  function GetBaselineProcessSteps(blraId, processId) {
    const [steps, setSteps] = useState([]);
    const blraRef = companyRef
      .collection("riskBaselineAssessments")
      .doc(blraId);

    useEffect(() => {
      const unsubscribe = blraRef
        .collection("processSteps")
        .where("process", "==", processId)
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            label: doc.data().value,
            value: doc.id,
          }));
          setSteps(data);
        });
      return unsubscribe;
    }, [blraId, processId]);
    return steps;
  }

  async function createHazard(blraId, process, step, name) {
    const blraRef = companyRef
      .collection("riskBaselineAssessments")
      .doc(blraId);
    const blra = await blraRef.get().then((doc) => {
      return doc.data().blra;
    });

    return await blraRef
      .collection("hazards")
      .add({
        name,
        process,
        step,
      })
      .then(async (doc) => {
        await logAuditTrail(
          currentUser.uid,
          `Added hazard to Baseline Risk Assessment: ${blra}`
        );
        return doc.id;
      });
  }

  async function getHazard(blraId, hazardId) {
    return await companyRef
      .collection("riskBaselineAssessments")
      .doc(blraId)
      .collection("hazards")
      .doc(hazardId)
      .get()
      .then((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });
  }

  async function addRisk(blraId, hazardId, data) {
    const blraRef = companyRef
      .collection("riskBaselineAssessments")
      .doc(blraId);
    const blra = await blraRef.get().then((doc) => {
      return doc.data().blra;
    });

    const ref = await blraRef
      .collection("risks")
      .get()
      .then((snap) => {
        if (snap.empty) {
          return 1;
        } else return snap.docs.length + 1;
      });

    const year = new Date().getFullYear().toString();

    const hod = db
      .collection("users")
      .doc(data.hod)
      .get()
      .then((doc) => {
        return {
          uid: doc.id,
          ...doc,
        };
      });

    const ifExists = (
      await companyRef.collection("riskHodCount").doc(hod.uid).get()
    ).exists;

    if (ifExists) {
      await companyRef
        .collection("riskHodCount")
        .doc(hod.uid)
        .set({
          [year]: fieldValue.increment(1),
        });
    } else {
      await companyRef
        .collection("riskHodCount")
        .doc(hod.uid)
        .set({
          [year]: 1,
          name: `${hod.firstName} ${hod.lastName}`,
        });
    }

    return await blraRef
      .collection("risks")
      .add({
        ...data,
        ref,
        hazard: hazardId,
      })
      .then(async (doc) => {
        await logAuditTrail(
          currentUser.uid,
          `Added risk to Baseline Risk Assessment: ${blra}`
        );
        return doc.id;
      });
  }

  const getHodCounts = async () => {
    let hodCounts = [];
    await companyRef
      .collection("riskHodCount")
      .get()
      .then((docs) => {
        docs.docs.forEach((doc) => {
          hodCounts.push({
            ...doc.data(),
          });
        });
      });
    return hodCounts;
  };

  async function getRisk(blraId, riskId) {
    return await companyRef
      .collection("riskBaselineAssessments")
      .doc(blraId)
      .collection("risks")
      .doc(riskId)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
  }

  async function getIbraRisk(blraId, riskId) {
    return await companyRef
      .collection("riskIBAssessments")
      .doc(blraId)
      .collection("risks")
      .doc(riskId)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
  }

  async function getCompleteRisk(blraId, riskId) {
    const risk = await companyRef
      .collection("riskBaselineAssessments")
      .doc(blraId)
      .collection("risks")
      .doc(riskId)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

    const entity = await companyRef
      .collection("riskEntities")
      .doc(risk.entity)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

    const hazard = await companyRef
      .collection("riskBaselineAssessments")
      .doc(blraId)
      .collection("hazards")
      .doc(risk.hazard)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

    const hod = await db
      .collection("users")
      .doc(risk.hod)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

    const pattern = await companyRef
      .collection("riskPatterns")
      .doc(risk.pattern)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

    const type = await companyRef
      .collection("riskTypes")
      .doc(risk.type)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

    return {
      ...risk,
      entity,
      hazard,
      hod,
      pattern,
      type,
    };
  }

  async function getCompleteIbraRisk(ibraId, riskId) {
    const risk = await companyRef
      .collection("riskIBAssessments")
      .doc(ibraId)
      .collection("risks")
      .doc(riskId)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

    const entity = await companyRef
      .collection("riskEntities")
      .doc(risk.entity)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

    const hazard = await companyRef
      .collection("riskIBAssessments")
      .doc(ibraId)
      .collection("hazards")
      .doc(risk.hazard)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

    const hod = await db
      .collection("users")
      .doc(risk.hod)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

    const pattern = await companyRef
      .collection("riskPatterns")
      .doc(risk.pattern)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

    const type = await companyRef
      .collection("riskTypes")
      .doc(risk.type)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

    return {
      ...risk,
      entity,
      hazard,
      hod,
      pattern,
      type,
    };
  }

  async function updateBlraRisk(blraId, riskId, data) {
    const blraRef = companyRef
      .collection("riskBaselineAssessments")
      .doc(blraId);
    const blra = blraRef.get().then((doc) => {
      return doc.data().blra;
    });

    return await blraRef
      .collection("risks")
      .doc(riskId)
      .update({
        ...data,
      })
      .then(async (doc) => {
        await logAuditTrail(currentUser.uid, `Updated Risk on: ${blra}`);
      });
  }

  function GetRiskHierarchy() {
    const [steps, setSteps] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("riskHierarchy")
        .orderBy("value", "desc")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setSteps(data);
        });
      return unsubscribe;
    }, []);
    return steps;
  }

  function GetBlraHazards(blraId) {
    const [hazards, setHazards] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("riskBaselineAssessments")
        .doc(blraId)
        .collection("hazards")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setHazards(data);
        });
      return unsubscribe;
    }, [blraId]);
    return hazards;
  }

  async function getBlraName(blraId) {
    await companyRef
      .collection("riskBaselineAssessments")
      .doc(blraId)
      .get()
      .then((doc) => ({
        ...doc.data().blra,
      }));
  }

  function GetIbraRisks(ibraId) {
    const [risks, setRisks] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("riskIBAssessments")
        .doc(ibraId)
        .collection("risks")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setRisks(data);
        });
      return unsubscribe;
    }, [ibraId]);
    return risks;
  }

  function GetIbraSigns(ibraId) {
    const [signs, setSigns] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("riskIBAssessments")
        .doc(ibraId)
        .collection("signs")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setSigns(data);
        });
      return unsubscribe;
    }, [ibraId]);
    return signs;
  }

  function GetHazardsForStep(blraId, stepId) {
    const [hazards, setHazards] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("riskBaselineAssessments")
        .doc(blraId)
        .collection("hazards")
        .where("step", "==", stepId)
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            label: doc.data().name,
            value: doc.id,
          }));
          setHazards(data);
        });
      return unsubscribe;
    }, [blraId, stepId]);
    return hazards;
  }

  function GetBlraRisks(blraId) {
    const [risks, setRisks] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("riskBaselineAssessments")
        .doc(blraId)
        .collection("risks")
        .orderBy("ref", "asc")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setRisks(data);
        });
      return unsubscribe;
    }, [blraId]);
    return risks;
  }

  function GetRisksForHazard(blraId, hazardId) {
    const [risks, setRisks] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("riskBaselineAssessments")
        .doc(blraId)
        .collection("risks")
        .where("hazard", "==", hazardId)
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setRisks(data);
        });
      return unsubscribe;
    }, [blraId, hazardId]);
    return risks;
  }

  async function addRiskControls(
    blraId,
    riskId,
    totalControlValue,
    controlsArray
  ) {
    const blraRef = companyRef
      .collection("riskBaselineAssessments")
      .doc(blraId);
    const blra = await blraRef.get().then((doc) => {
      return doc.data().blra;
    });

    const controlsPromises = controlsArray.map((c) => {
      if (c.description)
        return companyRef.collection("riskControls").add({
          ...c,
          risk: riskId,
        });
    });

    await Promise.all(controlsPromises);

    return await blraRef
      .collection("risks")
      .doc(riskId)
      .update({
        totalControlValue,
        controlsArray,
        currentStep: 4,
      })
      .then(async (doc) => {
        await logAuditTrail(
          currentUser.uid,
          `Added Controls to Risk on: ${blra}`
        );
      });
  }
  async function addIbraRiskControls(
    ibraId,
    riskId,
    totalControlValue,
    controlsArray
  ) {
    const ibraRef = companyRef.collection("riskIBAssessments").doc(ibraId);
    const ibra = await ibraRef.get().then((doc) => {
      return doc.data().ibra;
    });

    const controlsPromises = controlsArray.map((c) => {
      if (c.description)
        return companyRef.collection("riskControls").add({
          ...c,
          risk: riskId,
          ibra,
          timestamp: new Date(),
        });
    });

    await Promise.all(controlsPromises);

    return await ibraRef
      .collection("risks")
      .doc(riskId)
      .update({
        totalControlValue,
        controlsArray,
        currentStep: 4,
      })
      .then(async (doc) => {
        await logAuditTrail(
          currentUser.uid,
          `Added Controls to Risk on: ${ibra}`
        );
      });
  }

  async function addFinalRisk(blraId, riskId, data) {
    const blraRef = companyRef
      .collection("riskBaselineAssessments")
      .doc(blraId);
    const blra = await blraRef.get().then((doc) => {
      return doc.data().blra;
    });

    return await blraRef
      .collection("risks")
      .doc(riskId)
      .update({
        ...data,
        currentStep: 5,
      })
      .then(async (doc) => {
        await logAuditTrail(
          currentUser.uid,
          `Added a final risk score to: ${blra}`
        );
      });
  }

  async function addResidualRisk(blraId, riskId, data) {
    const blraRef = companyRef
      .collection("riskBaselineAssessments")
      .doc(blraId);
    const blra = await blraRef.get().then((doc) => {
      return doc.data().blra;
    });

    return await blraRef
      .collection("risks")
      .doc(riskId)
      .update({
        ...data,
      })
      .then(async (doc) => {
        await logAuditTrail(
          currentUser.uid,
          `Added residual risk controls to a risk on: ${blra}`
        );
      });
  }

  async function addIssueBasedRa(data) {
    return new Promise(async (res, rej) => {
      // Determine what ibra number this is. First check what number the ibras are for year
      const year = new Date().getFullYear().toString();

      let currentYearIbras = await companyRef
        .collection("riskIbras")
        .doc(year)
        .get()
        .then((doc) => {
          if (!doc.exists) return null;
          else return doc.data().count;
        });

      // If none exists, create it
      if (!currentYearIbras) {
        await companyRef.collection("riskIbras").doc(year).set({
          count: 0,
        });
        currentYearIbras = 0;
      }

      // Generate the ibra number, also check if it is baseline based then add the site name to the end of the string
      let ibra = "";
      if (data.blra) {
        const site = await companyRef
          .collection("riskBaselineAssessments")
          .doc(data.blra)
          .get()
          .then((blraDoc) => {
            return blraDoc.data().site.label;
          });
        ibra = `IBRA-${currentYearIbras + 1}-${year}-${site}`;
      } else {
        ibra = `IBRA-${currentYearIbras + 1}-${year}`;
      }
      // Add the data & audit log
      return companyRef
        .collection("riskIBAssessments")
        .add({
          ...data,
          ibra,
          timestamp: new Date(),
          createdBy: currentUser.uid,
        })
        .then(async (doc) => {
          await logAuditTrail(
            currentUser.uid,
            `Created a new Issue Based Risk Assessment: ${ibra}`
          );
          await companyRef
            .collection("riskIbras")
            .doc(year)
            .update({ count: fieldValue.increment(1) });
          res(doc.id);
        });
    });
  }

  async function getIbra(ibraId) {
    return await companyRef
      .collection("riskIBAssessments")
      .doc(ibraId)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
  }

  async function updateIbra(ibraId, data) {
    console.log(data);
    const ibraRef = companyRef.collection("riskIBAssessments").doc(ibraId);
    const ibra = await ibraRef.get().then((doc) => {
      return doc.data().ibra;
    });

    return await ibraRef
      .update({
        ...data,
      })
      .then(async (doc) => {
        await logAuditTrail(currentUser.uid, `Updated Issue Based RA: ${ibra}`);
      });
  }

  function addIbraParticipant(ibraId, participant) {
    return companyRef
      .collection("riskIBAssessments")
      .doc(ibraId)
      .update({
        participants: fieldValue.arrayUnion(participant),
      });
  }

  async function createIbHazard(ibraId, data) {
    const ibraRef = companyRef.collection("riskIBAssessments").doc(ibraId);
    const ibra = await ibraRef.get().then((doc) => {
      return doc.data().ibra;
    });

    return await ibraRef
      .collection("hazards")
      .add({
        ...data,
        timestamp: new Date(),
      })
      .then(async (doc) => {
        await logAuditTrail(
          currentUser.uid,
          `Add a Hazard to Issue Based RA: ${ibra}`
        );
        return doc.id;
      });
  }

  function GetHazardsForIB(ibraId) {
    const [hazards, setHazards] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("riskIBAssessments")
        .doc(ibraId)
        .collection("hazards")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setHazards(data);
        });
      return unsubscribe;
    }, [ibraId]);
    return hazards;
  }

  function GetRisksForIBHazard(ibraId, hazardId) {
    const [risks, setRisks] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("riskIBAssessments")
        .doc(ibraId)
        .collection("risks")
        .where("hazard", "==", hazardId)
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setRisks(data);
        });
      return unsubscribe;
    }, [ibraId, hazardId]);
    return risks;
  }

  async function getIbraHazard(ibraId, hazardId) {
    return await companyRef
      .collection("riskIBAssessments")
      .doc(ibraId)
      .collection("hazards")
      .doc(hazardId)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
  }

  async function updateIbraHazard(ibraId, hazardId, name) {
    return await companyRef
      .collection("riskIBAssessments")
      .doc(ibraId)
      .collection("hazards")
      .doc(hazardId)
      .update({
        name: name,
      });
  }

  async function addIBRisk(ibraId, data) {
    const ibraRef = companyRef.collection("riskIBAssessments").doc(ibraId);
    const ibra = await ibraRef.get().then((doc) => {
      return doc.data().ibra;
    });

    const ref = await ibraRef
      .collection("risks")
      .get()
      .then((snap) => {
        if (snap.empty) {
          return 1;
        } else return snap.docs.length + 1;
      });

    return await ibraRef
      .collection("risks")
      .add({
        ...data,
        timestamp: new Date(),
        ref,
      })
      .then(async (doc) => {
        await logAuditTrail(
          currentUser.uid,
          `Add a Risk to Issue Based RA: ${ibra}`
        );
        return doc.id;
      });
  }

  function GetIBAssessments() {
    const [ibras, setIbras] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("riskIBAssessments")
        .orderBy("timestamp", "desc")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setIbras(data);
        });
      return unsubscribe;
    }, []);
    return ibras;
  }

  function GetIsoSignsOfType(type) {
    const [signs, setSigns] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("iso7010")
        .where("type", "==", type)
        .orderBy("code")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setSigns(data);
        });
      return unsubscribe;
    }, [type]);
    return signs;
  }

  function GetCustomSignsOfType(type) {
    const [signs, setSigns] = useState([]);
    useEffect(() => {
      const unsubscribe = companyRef
        .collection("riskSignage")
        .where("type", "==", type)
        .orderBy("code")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setSigns(data);
        });
      return unsubscribe;
    }, [type]);
    return signs;
  }

  async function addCustomSign(sign) {
    return await companyRef
      .collection("riskSignage")
      .add({
        ...sign,
      })
      .then(async (doc) => {
        await logAuditTrail(
          currentUser.uid,
          `Uploaded a new custom ${sign.type} sign: ${sign.code} - ${sign.name}`
        );
        return doc.id;
      });
  }

  async function addSignToIbra(ibraId, sign, riskId) {
    const ibraRef = companyRef.collection("riskIBAssessments").doc(ibraId);
    const ibra = await ibraRef.get().then((doc) => {
      return doc.data().ibra;
    });

    return await ibraRef
      .collection("signs")
      .doc(sign.id)
      .set({
        ...sign,
        risk: riskId,
      })
      .then(async (doc) => {
        await logAuditTrail(
          currentUser.uid,
          `Add a PPE Sign to Issue Based RA: ${ibra}`
        );
      });
  }

  async function removeSignFromIbra(ibraId, signId) {
    const ibraRef = companyRef.collection("riskIBAssessments").doc(ibraId);
    const ibra = await ibraRef.get().then((doc) => {
      return doc.data().ibra;
    });

    return await ibraRef
      .collection("signs")
      .doc(signId)
      .delete()
      .then(async (doc) => {
        await logAuditTrail(
          currentUser.uid,
          `Removed a PPE Sign to Issue Based RA: ${ibra}`
        );
      });
  }

  function GetIsoSelectedSigns(ibraId, riskId, type) {
    const [signs, setSigns] = useState([]);
    useEffect(() => {
      const unsubscribe = companyRef
        .collection("riskIBAssessments")
        .doc(ibraId)
        .collection("signs")
        .where("type", "==", type)
        .where("risk", "==", riskId)
        .orderBy("code")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setSigns(data);
        });
      return unsubscribe;
    }, [ibraId, riskId, type]);
    return signs;
  }

  async function updateIbraRisk(ibraId, riskId, data) {
    const ibraRef = companyRef.collection("riskIBAssessments").doc(ibraId);
    const ibra = await ibraRef.get().then((doc) => {
      return doc.data().ibra;
    });

    return await ibraRef
      .collection("risks")
      .doc(riskId)
      .update({
        ...data,
      })
      .then(async (doc) => {
        await logAuditTrail(currentUser.uid, `Updated Risk on: ${ibra}`);
      });
  }

  async function getIBRisk(ibraId, riskId) {
    return await companyRef
      .collection("riskIBAssessments")
      .doc(ibraId)
      .collection("risks")
      .doc(riskId)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
  }

  async function getBlraPdf(blraId, hazards, activities, risks) {
    const pdf = new jsPDF();
    var img = new Image();
    img.src = await getCompanyLogo();

    const blraRef = companyRef
      .collection("riskBaselineAssessments")
      .doc(blraId);
    const blra = await blraRef.get().then((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
      };
    });

    pdf.addImage(
      img,
      "PNG",
      80,
      10,
      pdf.canvas.width * 0.3,
      pdf.canvas.height * 0.15
    );
    pdf.setFontSize(10);
    pdf.text(blra.blra, 13, 60);

    pdf.autoTable({
      body: [
        [
          `Area: ${blra.area.areaName}`,
          `Date Created: ${moment(blra.created.toDate()).format("DD/MM/YYYY")}`,
        ],
        [`Site: ${blra.area.site.name}`, ``],
      ],
      theme: "plain",
      bodyStyles: {
        fontSize: 8,
      },
      margin: { top: 70 },
    });

    if (activities && activities.length > 0) {
      const revBody = activities.map((a) => {
        let tempArray = [];
        hazards.map((h, i) => {
          if (h.step === a.id) tempArray.push(`1.${i + 1}) ${h.name}\n`);
        });
        return [a.value, tempArray];
      });
      pdf.autoTable({
        head: [["Activities:", "Hazards"]],
        body: revBody,
        headStyles: {
          fillColor: [128, 130, 127],
          fontSize: 8,
        },
        bodyStyles: {
          fillColor: [255, 255, 255],
          fontSize: 7,
        },
      });
    }

    if (hazards && hazards.length > 0) {
      const hazBody = hazards.map((h, i) => {
        let tempArray = [];
        risks.map((r, i) => {
          if (r.hazard === h.id) {
            tempArray.push(`1.1.${i + 1}) ${r.description}\n`);
          }
        });
        return [`1.${i + 1} ${h.name}\n`, tempArray];
      });
      pdf.autoTable({
        head: [["Hazards", "Risks"]],
        body: hazBody,
        headStyles: {
          fillColor: [128, 130, 127],
          fontSize: 8,
        },
        bodyStyles: {
          fillColor: [255, 255, 255],
          fontSize: 7,
        },
      });
    }

    pdf.autoTable({
      head: [["Risks"]],
      headStyles: {
        // rgb(255, 193, 7)
        fillColor: [255, 193, 7],
        fontSize: 8,
      },
      bodyStyles: {
        fillColor: [255, 255, 255],
        fontSize: 7,
      },
    });
    if (risks && risks.length > 0) {
      const riskBody = risks.map((r, i) => {
        let tempArray = [];
        r.controlsArray?.map((c, i) => {
          if (c.description) {
            tempArray.push(`${c.description}(${c.controlValue}) `);
          }
        });
        return [
          `1.1.${i + 1}`,
          r.description,
          r.cause,
          r.routine,
          r.initialLike && r.initialCons
            ? r.initialLike * r.initialCons
            : "Not Set",
          r.finalLike && r.finalCons ? r.finalLike * r.finalCons : "Not Set",
          tempArray,
        ];
      });
      pdf.autoTable({
        head: [
          [
            "",
            "Description",
            "Cause",
            "Routine/Non-Routine",
            "Inherent Rating",
            "Residual Rating",
            "Controls",
          ],
        ],
        body: riskBody,
        headStyles: {
          fillColor: [128, 130, 127],
          fontSize: 8,
        },
        bodyStyles: {
          fillColor: [255, 255, 255],
          fontSize: 7,
        },
      });
    }

    let controlsArray = [];
    risks &&
      risks.map((r) => {
        if (r.controlsArray && r.controlsArray.length > 0) {
          r.controlsArray.map((c) => {
            if (c.description)
              controlsArray.push([c.label, c.description, c.controlValue]);
          });
        }
      });

    pdf.autoTable({
      head: [["Controls"]],
      headStyles: {
        // rgb(255, 193, 7)
        fillColor: [255, 193, 7],
        fontSize: 8,
      },
      bodyStyles: {
        fillColor: [255, 255, 255],
        fontSize: 7,
      },
    });

    if (controlsArray && controlsArray.length > 0) {
      pdf.autoTable({
        head: [["Type", "Description", "Control Value"]],
        body: controlsArray,
        headStyles: {
          fillColor: [128, 130, 127],
          fontSize: 8,
        },
        bodyStyles: {
          fillColor: [255, 255, 255],
          fontSize: 7,
        },
      });
    }

    if (blra.participants) {
      const partBody = blra.participants.map((p, i) => [
        p.user.label,
        p.department.label,
        p.experience,
      ]);
      pdf.autoTable({
        head: [["Participants", "Department", "Years Experience"]],
        body: partBody,
        headStyles: {
          fillColor: [128, 130, 127],
          fontSize: 8,
        },
        bodyStyles: {
          fillColor: [255, 255, 255],
          fontSize: 7,
        },
      });
    }

    return pdf.save(`${blra.blra}`);
  }

  async function getIbraPdf(ibraId, hazards, risks, signs) {
    const pdf = new jsPDF();
    var img = new Image();
    img.src = await getCompanyLogo();

    const ibraRef = companyRef.collection("riskIBAssessments").doc(ibraId);
    const ibra = await ibraRef.get().then((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
      };
    });

    pdf.addImage(
      img,
      "PNG",
      80,
      10,
      pdf.canvas.width * 0.3,
      pdf.canvas.height * 0.15
    );
    pdf.setFontSize(10);
    pdf.text(ibra.ibra, 13, 60);

    pdf.autoTable({
      body: [
        [
          `Initiated From: ${ibra.initiateFrom}`,
          `Date Created: ${moment(ibra.timestamp.toDate()).format(
            "DD/MM/YYYY"
          )}`,
        ],
      ],
      theme: "plain",
      bodyStyles: {
        fontSize: 8,
      },
      margin: { top: 70 },
    });

    if (hazards && hazards.length > 0) {
      const hazBody = hazards.map((h) => [h.name]);
      pdf.autoTable({
        head: [["Hazards"]],
        body: hazBody,
        headStyles: {
          fillColor: [128, 130, 127],
          fontSize: 8,
        },
        bodyStyles: {
          fillColor: [255, 255, 255],
          fontSize: 7,
        },
      });
    }

    pdf.autoTable({
      head: [["Risks"]],
      headStyles: {
        // rgb(255, 193, 7)
        fillColor: [255, 193, 7],
        fontSize: 8,
      },
      bodyStyles: {
        fillColor: [255, 255, 255],
        fontSize: 7,
      },
    });
    if (risks && risks.length > 0) {
      const riskBody = risks.map((r) => [
        r.description,
        r.cause,
        r.routine,
        r.initialLike && r.initialCons
          ? r.initialLike * r.initialCons
          : "Not Set",
        r.finalLike && r.finalCons ? r.finalLike * r.finalCons : "Not Set",
      ]);
      pdf.autoTable({
        head: [
          [
            "Description",
            "Cause",
            "Routine/Non-Routine",
            "Inherent Rating",
            "Residual Rating",
          ],
        ],
        body: riskBody,
        headStyles: {
          fillColor: [128, 130, 127],
          fontSize: 8,
        },
        bodyStyles: {
          fillColor: [255, 255, 255],
          fontSize: 7,
        },
      });
    }

    let controlsArray = [];
    risks &&
      risks.map((r) => {
        if (r.controlsArray && r.controlsArray.length > 0) {
          r.controlsArray.map((c) => {
            console.log(c);
            controlsArray.push([c.label, c.description, c.controlValue]);
          });
        }
      });

    pdf.autoTable({
      head: [["Controls"]],
      headStyles: {
        // rgb(255, 193, 7)
        fillColor: [255, 193, 7],
        fontSize: 8,
      },
      bodyStyles: {
        fillColor: [255, 255, 255],
        fontSize: 7,
      },
    });

    if (controlsArray && controlsArray.length > 0) {
      pdf.autoTable({
        head: [["Control Type", "Description", "Control Value"]],
        body: controlsArray,
        headStyles: {
          fillColor: [128, 130, 127],
          fontSize: 8,
        },
        bodyStyles: {
          fillColor: [255, 255, 255],
          fontSize: 7,
        },
      });
    } else if (!controlsArray || controlsArray.length === 0) {
      // Get the controls documents for this risk assessment
      const riskIdArray = risks && risks.map((r) => r.id);
      if (riskIdArray && riskIdArray.length > 0) {
        const controlsRef = companyRef.collection("riskControls");
        const controls = await controlsRef
          .where("riskId", "in", riskIdArray)
          .get()
          .then((querySnapshot) => {
            let controlsArray = [];
            querySnapshot.forEach((doc) => {
              controlsArray.push({
                ...doc.data(),
                id: doc.id,
              });
            });
          });
        pdf.autoTable({
          head: [["Control Type", "Description", "Control Value"]],
          body: controlsArray,
          headStyles: {
            fillColor: [128, 130, 127],
            fontSize: 8,
          },
          bodyStyles: {
            fillColor: [255, 255, 255],
            fontSize: 7,
          },
        });
      }
    }

    if (signs && signs.length > 0) {
      let signsBody = [];
      signs.map((s, i) => {
        var signImg = new Image();
        signImg.src = s.png;
        signsBody.push([s.code, s.name, signImg]);
      });
      pdf.autoTable({
        head: [["Code", "Description", "Symbol"]],
        body: signsBody,
        didDrawCell: (data) => {
          if (data.column.index === 2 && data.cell.section === "body") {
            var dim = data.cell.height - data.cell.padding("vertical");
            pdf.addImage(data.cell.raw, data.cell.x, data.cell.y, dim, dim);
          }
        },
        headStyles: {
          fillColor: [128, 130, 127],
          fontSize: 8,
        },
        bodyStyles: {
          fillColor: [255, 255, 255],
          fontSize: 7,
          minCellHeight: 20,
        },
      });
    }

    if (ibra.participants) {
      const partBody = ibra.participants.map((p) => [
        p.user.label,
        p.department.label,
        p.experience,
      ]);
      pdf.autoTable({
        head: [["Participants", "Department", "Years Experience"]],
        body: partBody,
        headStyles: {
          fillColor: [128, 130, 127],
          fontSize: 8,
        },
        bodyStyles: {
          fillColor: [255, 255, 255],
          fontSize: 7,
        },
      });
    }

    return pdf.save(`${ibra.ibra}`);
  }

  async function saveCcms(ccmArray, ibraId) {
    const ibra = await companyRef
      .collection("riskIBAssessments")
      .doc(ibraId)
      .get()
      .then((doc) => {
        return doc.data().ibra;
      });

    const promiseArray = ccmArray.map((ccm) => {
      return companyRef.collection("riskCcms").add({
        ...ccm,
        ibraId,
        ibra,
      });
    });

    return await Promise.all(promiseArray);
  }

  function GetCriticalControls() {
    const [controls, setControls] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("riskCcms")
        .orderBy("ibra")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setControls(data);
        });
      return unsubscribe;
    }, []);
    return controls;
  }

  async function getRiskCounts() {
    const year = new Date().getFullYear().toString();

    const ibraCount = await companyRef
      .collection("riskIbras")
      .doc(year)
      .get()
      .then((doc) => {
        return doc.exists ? doc.data().count : 0;
      });

    const blraCount = await companyRef
      .collection("riskBaselines")
      .doc(year)
      .get()
      .then((doc) => {
        return doc.exists ? doc.data().count : 0;
      });

    return {
      ibraCount,
      blraCount,
    };
  }

  function GetTopRisksPerHod(hodId) {
    const [risks, setRisks] = useState([]);

    useEffect(() => {
      const unsubscribe = db
        .collectionGroup("risks")
        .where("hod", "==", hodId)
        .orderBy("riskValue")
        .limit(10)
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            type: doc.ref.path.includes("riskBaselineAssessments")
              ? "blra"
              : "ibra",
            assessmentId: doc.ref.parent.parent.id,
          }));
          setRisks(data);
        });
      return unsubscribe;
    }, [hodId]);
    return risks;
  }

  function GetTopRiskPerSite(siteId) {
    const [risks, setRisks] = useState([]);

    useEffect(() => {
      const unsubscribe = db
        .collectionGroup("risks")
        .orderBy("riskValue")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map(async (doc) => {
            const site = await doc.ref.parent.parent
              .get()
              .then((d) => ({ ...d.data() })).site.value;
            if (site === siteId) {
              return {
                id: doc.id,
                ...doc.data(),
                type: doc.ref.path.includes("riskBaselineAssessments")
                  ? "blra"
                  : "ibra",
                assessmentId: doc.ref.parent.parent.id,
              };
            }
          });
          setRisks(data);
        });
      return unsubscribe;
    }, [siteId]);
    return risks;
  }

  // ---------------------------------------------- Refactored Doc Manager ---------------------------------------------

  function GetFilteredDocuments(filters) {
    const [documents, setDocuments] = useState([]);

    useEffect(() => {
      const docsCollection = companyRef.collection("documents");

      let query = docsCollection.where("obsolete", "==", false);

      for (let key in filters) {
        if (filters[key].value) {
          query = query.where(filters[key].fieldName, "==", filters[key].value);
        }
      }

      const unsubscribe = query.onSnapshot((snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setDocuments(data);
      });
      return unsubscribe;
    }, [filters]);
    return documents;
  }

  function GetFilteredDocuments(filters) {
    const [documents, setDocuments] = useState([]);

    useEffect(() => {
      const docsCollection = companyRef.collection("documents");

      let query = docsCollection.where("obsolete", "==", false);

      for (let key in filters) {
        if (filters[key].value) {
          query = query.where(filters[key].fieldName, "==", filters[key].value);
        }
      }

      const unsubscribe = query.onSnapshot((snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setDocuments(data);
      });
      return unsubscribe;
    }, [filters]);
    return documents;
  }

  async function getRDocuments(filters) {
    const docsCollection = companyRef.collection("documents");
    let query = docsCollection.where("obsolete", "==", false);

    for (let key in filters) {
      if (filters[key].value) {
        query = query.where(filters[key].fieldName, "==", filters[key].value);
      }
    }

    return await query
      .orderBy("order")
      .get()
      .then((snap) => {
        if (!snap.empty) {
          const documents = snap.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          return {
            data: documents,
            lastDoc: snap.docs[snap.docs.length - 1],
          };
        } else {
          console.log("Done");
          return {
            data: [],
            lastDoc: {
              done: true,
            },
          };
        }
      });
  }

  function GetUserFavouriteDocs() {
    const [documents, setDocuments] = useState([]);
    useEffect(() => {
      const unsubscribe = companyRef
        .collection("documents")
        .where("favoritedBy", "array-contains", currentUser.uid)
        .where("obsolete", "==", false)
        .orderBy("order")
        .onSnapshot((snapshot) => {
          const docList = snapshot.docs.map((document) => ({
            id: document.id,
            ...document.data(),
          }));
          setDocuments(docList);
        });
      return unsubscribe;
    }, []);
    return documents;
  }

  async function getRActions(filters, lastDoc) {
    let query = companyRef.collection("actions");

    for (let key in filters) {
      if (filters[key].value) {
        query = query.where(filters[key].fieldName, "==", filters[key].value);
      }
    }

    return await query

      .orderBy("timestamp")
      .get()
      .then((snap) => {
        if (!snap.empty) {
          // let actions = []
          // snap.docs.map(doc => {
          //     if(!doc.data().obsolete) {
          //         actions.push({
          //             ...doc.data(),
          //             id: doc.id
          //         })
          //     }
          // })
          const actions = snap.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));

          return {
            data: actions,
            lastDoc: snap.docs[snap.docs.length - 1],
          };
        } else {
          return {
            data: [],
            lastDoc: {
              done: true,
            },
          };
        }
      });
  }

  async function getROtp(filters) {
    let query = companyRef.collection("otps");

    for (let key in filters) {
      if (filters[key].value) {
        query = query.where(filters[key].fieldName, "==", filters[key].value);
      }
    }

    return await query
      .orderBy("timestamp")
      // .startAfter(!lastDoc || lastDoc.done ? 0 : lastDoc)
      // .limit(12)
      .get()
      .then((snap) => {
        if (!snap.empty) {
          const otps = snap.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));

          return {
            data: otps,
            lastDoc: snap.docs[snap.docs.length - 1],
          };
        } else {
          console.log("Done");
          return {
            data: [],
            lastDoc: {
              done: true,
            },
          };
        }
      });
  }

  // --------------------------------------------------------------------------------------------  Iwan Only functions---------------------------------------------------------------------------------------------

  async function updateAllOtpsWithProgramCount() {
    return await db
      .collectionGroup("programs")
      .get()
      .then(async (snap) => {
        for (let i = 0; i < snap.docs.length; i++) {
          console.log(snap.docs[i].ref.parent.parent.parent.parent.path);
          await snap.docs[i].ref.parent.parent.parent.parent.update({
            programCount: fieldValue.increment(1),
          });
        }
      });
  }

  async function updateAllTargetsWithProgramCount() {
    return await db
      .collectionGroup("programs")
      .get()
      .then(async (snap) => {
        for (let i = 0; i < snap.docs.length; i++) {
          console.log(snap.docs[i].ref.parent.parent.path);
          await snap.docs[i].ref.parent.parent.update({
            programCount: fieldValue.increment(1),
          });
        }
      });
  }

  async function getTotalActionsForYear(coId, year) {
    return await db
      .collection("companies")
      .doc(coId)
      .collection("actions")
      .where("year", "==", year)
      .get()
      .then((snap) => {
        console.log(snap.docs.length);
        return snap.docs.length;
      });
  }

  async function makeRosterOnlyFalse(coId) {
    return new Promise(async (res, rej) => {
      await db
        .collection("users")
        .where("companyId", "==", coId)
        .get()
        .then(async (snap) => {
          for (let i = 0; i < snap.docs.length; i++) {
            await snap.docs[i].ref.update({
              rosterOnly: false,
            });
          }
        });
      console.log("DONE");
      res();
    });
  }

  async function fixProgramCounts(coId) {
    return new Promise(async (res, rej) => {
      const coRef = db.collection("companies").doc(coId);

      let pLength = 0;
      // Get All OTPS
      await coRef
        .collection("otps")
        .get()
        .then(async (snap) => {
          for (let i = 0; i < snap.docs.length; i++) {
            // Get all targets in each otp
            await snap.docs[i].ref
              .collection("targets")
              .get()
              .then(async (tSnap) => {
                // In each target get programs length
                for (let t = 0; t < tSnap.docs.length; t++) {
                  await tSnap.docs[t].ref
                    .collection("programs")
                    .get()
                    .then((pSnap) => {
                      pLength = pSnap.docs.length;
                    });
                  // Update each target with correct program count
                  await tSnap.docs[t].ref.update({
                    programCount: pLength,
                  });
                }
              });
            // Update each otp with correct program count
            await snap.docs[i].ref.update({
              programCount: pLength,
            });
          }
        });
      console.log("Job Done");
      res();
    });
  }

  async function addIntProgramTargets() {
    return await db
      .collectionGroup("programs")
      .get()
      .then(async (snap) => {
        for (let i = 0; i < snap.docs.length; i++) {
          if (!snap.docs[i].data().programTarget) {
            const targetNum = snap.docs[i].data().targetNum;
            console.log(targetNum);
            if (targetNum && targetNum !== "NaN") {
              const programTarget = parseInt(targetNum);
              console.log(programTarget);
              await snap.docs[i].ref.update({
                programTarget,
              });
            }
          } else {
            console.log("Program Data exists already");
          }
        }
      });
  }

  async function reverseFixProgramTargets() {
    return await db
      .collectionGroup("programs")
      .get()
      .then(async (snap) => {
        for (let i = 0; i < snap.docs.length; i++) {
          if (snap.docs[i].data().programTarget) {
            const targetNum = snap.docs[i].data().programTarget.toString();
            console.log(targetNum);
            await snap.docs[i].ref.update({
              targetNum,
            });
          }
        }
      });
  }

  async function fixIntervalDays() {
    return await db
      .collectionGroup("programs")
      .get()
      .then(async (snap) => {
        for (let i = 0; i < snap.docs.length; i++) {
          if (snap.docs[i].data().measurementInterval) {
            const intervalDays = parseInt(
              snap.docs[i].data().measurementInterval
            );
            console.log(intervalDays);
            await snap.docs[i].ref.update({
              intervalDays,
            });
          }
        }
      });
  }

  async function fixClosedCount() {
    return await db
      .collectionGroup("targets")
      .get()
      .then(async (snap) => {
        for (let i = 0; i < snap.docs.length; i++) {
          const path = snap.docs[i].ref.path;
          let closedCount = 0;
          await snap.docs[i].ref
            .collection("programs")
            .get()
            .then(async (snap2) => {
              if (!snap2.empty) {
                snap2.docs.map((p) => {
                  if (!p.data().open) {
                    closedCount++;
                  }
                });
              }
            });
          await snap.docs[i].ref.update({
            closedCount,
          });
          console.log(
            `Path: ${path} - Closed count for Target ${snap.docs[i].id} is ${closedCount}`
          );
        }
      });
  }

  async function fixClosedOnOtp() {
    return await db
      .collectionGroup("otps")
      .get()
      .then(async (snap) => {
        for (let i = 0; i < snap.docs.length; i++) {
          const path = snap.docs[i].ref.path;
          let closedPrograms = 0;
          await snap.docs[i].ref
            .collection("targets")
            .get()
            .then(async (snap2) => {
              if (!snap2.empty) {
                snap2.docs.map((t) => {
                  if (t.data().closedCount) {
                    closedPrograms = closedPrograms + t.data().closedCount;
                  }
                });
              }
            });
          await snap.docs[i].ref.update({
            closedPrograms,
          });
          console.log(
            `Path: ${path} - Closed Programs for OTP ${snap.docs[i].id} is ${closedPrograms}`
          );
        }
      });
  }

  async function changeYearStringsToInts() {
    const thisYear = new Date().getFullYear();
    return await db
      .collectionGroup("actions")
      .get()
      .then(async (snap) => {
        for (let i = 0; i < snap.docs.length; i++) {
          if (snap.docs[i].data().year === "2021") {
            await snap.docs[i].ref.update({
              year: thisYear,
            });
          }
        }
      })
      .catch((err) => console.log(err));
  }

  // ---------------------------------------------- Audit Manager ---------------------------------------------

  async function createChecklist(data) {
    return await companyRef
      .collection("auditChecklists")
      .add({
        ...data,
        createdBy: currentUser.uid,
        created: new Date(),
      })
      .then(async (doc) => {
        await logAuditTrail(
          currentUser.uid,
          `Created a new Audit Checklist: ${data.title}`
        );
        return doc.id;
      });
  }

  async function deleteCheckList(id) {
    return await companyRef.collection("auditChecklists").doc(id).delete();
  }

  async function getChecklist(checklistId) {
    return await companyRef
      .collection("auditChecklists")
      .doc(checklistId)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
  }

  async function createChecklistSection(checklistId, data) {
    return await companyRef
      .collection("auditChecklists")
      .doc(checklistId)
      .collection("sections")
      .add({
        ...data,
        created: new Date(),
        createdBy: currentUser.uid,
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Created a new Audit Checklist Section: ${data.name}`
        );
      });
  }

  function GetChecklistSections(checklistId) {
    const [sections, setSections] = useState([]);
    useEffect(() => {
      const unsubscribe = companyRef
        .collection("auditChecklists")
        .doc(checklistId)
        .collection("sections")
        .orderBy("created")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setSections(data);
        });
      return unsubscribe;
    }, [checklistId]);
    return sections;
  }

  async function updateChecklistSectionScore(
    checklistId,
    sectionId,
    scoreValue,
    type
  ) {
    return await companyRef
      .collection("auditChecklists")
      .doc(checklistId)
      .collection("sections")
      .doc(sectionId)
      .update({
        maxScore:
          type == "text"
            ? fieldValue.increment(scoreValue)
            : type == "file"
            ? fieldValue.increment(scoreValue)
            : type == "checkbox"
            ? fieldValue.increment(
                scoreValue.reduce((a, b) => {
                  return a + b;
                })
              )
            : type == "radio"
            ? fieldValue.increment(scoreValue.sort((a, b) => b - a)[0])
            : 0,
      });
  }

  async function addChecklistField(checklistId, sectionId, type, data) {
    return await companyRef
      .collection("auditChecklists")
      .doc(checklistId)
      .collection("sections")
      .doc(sectionId)
      .collection("fields")
      .add({
        timestamp: new Date(),
        ...data,
        type,
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Created a new Audit Checklist Field: ${data.name}`
        );
      });
  }

  async function getChecklistField(checklistId, sectionId, fieldId) {
    return await companyRef
      .collection("auditChecklists")
      .doc(checklistId)
      .collection("sections")
      .doc(sectionId)
      .collection("fields")
      .doc(fieldId)
      .get()
      .then((doc) => {
        console.log(doc.data());
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
  }

  async function updateChecklistField(
    checklistId,
    sectionId,
    fieldId,
    type,
    data
  ) {
    return await companyRef
      .collection("auditChecklists")
      .doc(checklistId)
      .collection("sections")
      .doc(sectionId)
      .collection("fields")
      .doc(fieldId)
      .update({
        ...data,
      })
      .then(async () => {
        await logAuditTrail(
          currentUser.uid,
          `Updated Audit Checklist Field: ${data.name}`
        );
      });
  }

  function GetChecklistSectionFields(checklistId, sectionId) {
    const [fields, setFields] = useState([]);
    useEffect(() => {
      const unsubscribe = companyRef
        .collection("auditChecklists")
        .doc(checklistId)
        .collection("sections")
        .doc(sectionId)
        .collection("fields")
        .orderBy("timestamp")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            ref: doc.ref,
            id: doc.id,
            ...doc.data(),
          }));
          setFields(data);
        });
      return unsubscribe;
    }, [checklistId, sectionId]);
    return fields;
  }

  function GetChecklists() {
    const [lists, setLists] = useState([]);
    useEffect(() => {
      const unsubscribe = companyRef
        .collection("auditChecklists")
        .orderBy("created", "desc")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setLists(data);
        });
      return unsubscribe;
    }, []);
    return lists;
  }

  async function scheduleChecklist(checklistId, data) {
    // Get the checklist details
    const checklist = await companyRef
      .collection("auditChecklists")
      .doc(checklistId)
      .get()
      .then((doc) => {
        return doc.data();
      });

    return await companyRef
      .collection("auditSchedule")
      .add({
        checklist: checklistId,
        title: checklist.title,
        type: checklist.type,
        timestamp: new Date(),
        createdBy: currentUser.uid,
        ...data,
        window: data.window ? data.window : 1,
      })
      .then(async (doc) => {
        await logAuditTrail(
          currentUser.uid,
          `Scheduled Checklist: ${checklist.title} to run every ${
            data.frequency
          } days, starting on ${moment(data.nextDate).format("DD/MM/YYYY")}`
        );
      });
  }

  async function deleteSchedules(id) {
    return await companyRef.collection("auditSchedule").doc(id).delete();
  }

  function GetSchedules() {
    const [schedules, setSchedules] = useState([]);
    useEffect(() => {
      const unsubscribe = companyRef
        .collection("auditSchedule")
        .orderBy("timestamp", "desc")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setSchedules(data);
        });
      return unsubscribe;
    }, []);
    return schedules;
  }

  function GetInspections() {
    const [inspections, setInspections] = useState([]);
    const today = new Date();
    useEffect(() => {
      const unsubscribe = companyRef
        .collection("auditInspections")
        .where("status", "==", "pending")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setInspections(data);
        });
      return unsubscribe;
    }, []);
    return inspections;
  }

  function GetMissedInspections() {
    const [inspections, setInspections] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("auditInspections")
        .where("status", "==", "missed")
        // .orderBy('dueDate')
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setInspections(data);
        });
      return unsubscribe;
    }, []);
    return inspections;
  }

  function GetMissedInspectionsThisYear() {
    const [inspections, setInspections] = useState([]);
    const date = new Date();
    const year = date.getFullYear();
    const yearStart = new Date(year, 0, 1);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("auditInspections")
        .where("status", "==", "missed")
        .where("dueDate", ">=", yearStart)
        .orderBy("dueDate", "desc")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setInspections(data);
        });
      return unsubscribe;
    }, []);
    return inspections;
  }

  function GetCompleteInspections() {
    const [inspections, setInspections] = useState([]);
    useEffect(() => {
      const unsubscribe = companyRef
        .collection("auditInspections")
        .where("status", "==", "complete")
        // .orderBy('dueDate')
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setInspections(data);
        });
      return unsubscribe;
    }, []);
    return inspections;
  }

  function GetCompleteInspectionsThisYear() {
    const [inspections, setInspections] = useState([]);
    const date = new Date();
    const year = date.getFullYear();
    const yearStart = new Date(year, 0, 1);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("auditInspections")
        .where("status", "==", "complete")
        .where("dueDate", ">=", yearStart)
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setInspections(data);
        });
      return unsubscribe;
    }, []);
    return inspections;
  }

  async function saveSectionSubmission(
    inspectionId,
    sectionId,
    data,
    order,
    sectionName
  ) {
    return await companyRef
      .collection("auditInspections")
      .doc(inspectionId)
      .collection("sectionData")
      .doc(sectionId)
      .set({
        ...data,
        order,
        sectionName,
      })
      .then(async () => {
        // TODO: Make this a job code after you've implemented job codes
        await logAuditTrail(
          currentUser.uid,
          `Saved Progress on Inspection: ${inspectionId}`
        );
      });
  }

  async function getSectionSubmission(inspectionId, sectionId) {
    return await companyRef
      .collection("auditInspections")
      .doc(inspectionId)
      .collection("sectionData")
      .doc(sectionId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          return doc.data().data;
        } else return null;
      });
  }

  async function advanceInspection(inspectionId, currentStep) {
    return await companyRef
      .collection("auditInspections")
      .doc(inspectionId)
      .update({
        currentStep,
      })
      .then(async () => {
        // TODO: Make this a job code after you've implemented job codes
        await logAuditTrail(
          currentUser.uid,
          `Moved on to section ${currentStep} Progress on Inspection: ${inspectionId}`
        );
      });
  }

  async function getInspection(inspectionId) {
    return await companyRef
      .collection("auditInspections")
      .doc(inspectionId)
      .get()
      .then((doc) => {
        return doc.data();
      });
  }

  function GetInspectionSectionData(inspectionId) {
    const [sectionData, setInspectionData] = useState([]);
    useEffect(() => {
      const unsubscribe = companyRef
        .collection("auditInspections")
        .doc(inspectionId)
        .collection("sectionData")
        .orderBy("order", "asc")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            inspectionId: inspectionId,
            id: doc.id,
            ...doc.data(),
          }));
          setInspectionData(data);
        });
      return unsubscribe;
    }, []);
    return sectionData;
  }

  async function completeInspection(inspectionId) {
    return await companyRef
      .collection("auditInspections")
      .doc(inspectionId)
      .update({
        status: "complete",
        completionDate: new Date(),
      })
      .then(async () => {
        // TODO: Make this a job code after you've implemented job codes
        await logAuditTrail(
          currentUser.uid,
          `Completed Inspection: ${inspectionId}`
        );
      });
  }

  async function raiseIssue(inspectionId, data, field) {
    const inspection = await getInspection(inspectionId);

    return await companyRef
      .collection("auditIssues")
      .add({
        timestamp: new Date(),
        createdBy: {
          id: currentUser.uid,
          label: `${currentUser.firstName} ${currentUser.lastName}`,
        },
        ...data,
        field,
        inspectionId,
        jobRef: inspection.jobRef,
      })
      .then(async (doc) => {
        await logAuditTrail(
          currentUser.uid,
          `Raised an issue on ${inspection.type}: ${inspection.jobRef}`
        );
        return doc.id;
      });
  }

  function GetIssues() {
    const [issues, setIssues] = useState([]);
    useEffect(() => {
      const unsubscribe = companyRef
        .collection("auditIssues")
        .orderBy("timestamp", "desc")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setIssues(data);
        });
      return unsubscribe;
    }, []);
    return issues;
  }

  async function getIssue(issueId) {
    const issue = await companyRef
      .collection("auditIssues")
      .doc(issueId)
      .get()
      .then((doc) => {
        return doc.data();
      });
    const inspection = await getInspection(issue.inspectionId);
    return {
      ...issue,
      inspection,
    };
  }

  async function createProduct(data) {
    return await companyRef
      .collection("products")
      .add({
        ...data,
        timestamp: new Date(),
        createdBy: currentUser.uid,
      })
      .then(async (doc) => {
        await logAuditTrail(
          currentUser.uid,
          `Created a new Product/Service ${data.name}`
        );
        return doc.id;
      });
  }

  function GetProducts() {
    const [products, setProducts] = useState([]);
    useEffect(() => {
      const unsubscribe = companyRef
        .collection("products")
        .orderBy("name")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            value: {
              id: doc.id,
              label: doc.data().name,
            },
            label: doc.data().name,
          }));
          setProducts(data);
        });
      return unsubscribe;
    }, []);
    return products;
  }

  async function createSupplier(data) {
    const date = new Date();
    const year = date.getFullYear().toString();

    // First check if that email is already in use
    await db
      .collection("users")
      .where("email", "==", data.email)
      .get()
      .then((snap) => {
        if (!snap.empty) {
          throw {
            message: "That email is already in use with another account.",
          };
        }
      });

    // Get Supplier Count
    const count = await companyRef
      .collection("supplierCount")
      .doc(year)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          return 1;
        } else return doc.data().count + 1;
      });

    const vendorCode = `${year}-${count}`;

    return await companyRef
      .collection("suppliers")
      .add({
        ...data,
        timestamp: new Date(),
        createdBy: currentUser.uid,
        vendorCode,
      })
      .then(async (doc) => {
        await logAuditTrail(
          currentUser.uid,
          `Created a new Supplier ${data.companyName}`
        );
        await companyRef.collection("supplierCount").doc(year).set({ count });
        return doc.id;
      });
  }

  function GetSuppliers() {
    const [suppliers, setSuppliers] = useState([]);
    useEffect(() => {
      const unsubscribe = companyRef
        .collection("suppliers")
        .orderBy("companyName")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            value: {
              id: doc.id,
              label: doc.data().companyName,
            },
            label: doc.data().nacompanyNameme,
          }));
          setSuppliers(data);
        });
      return unsubscribe;
    }, []);
    return suppliers;
  }

  function GetAuditCount() {
    const [count, setCount] = useState();
    const date = new Date();
    const year = date.getFullYear().toString();
    useEffect(() => {
      const unsubscribe = companyRef
        .collection("auditCounts")
        .doc(year)
        .onSnapshot((doc) => {
          if (doc.exists) {
            const data = doc.data().count;
            setCount(data);
          } else {
            setCount(0);
          }
        });
      return unsubscribe;
    }, []);
    return count;
  }
  function GetSupplierCount() {
    const [count, setCount] = useState();
    const date = new Date();
    const year = date.getFullYear().toString();
    useEffect(() => {
      const unsubscribe = companyRef
        .collection("supplierCount")
        .doc(year)
        .onSnapshot((doc) => {
          if (doc.exists) {
            const data = doc.data().count;
            setCount(data);
          } else {
            setCount(0);
          }
        });
      return unsubscribe;
    }, []);
    return count;
  }

  async function updateHazard(blraId, hazardId, data) {
    return await companyRef
      .collection("riskBaselineAssessments")
      .doc(blraId)
      .collection("hazards")
      .doc(hazardId)
      .update({
        ...data,
      })
      .then(async (doc) => {
        await logAuditTrail(
          currentUser.uid,
          `Changed name of Hazard: ${data.name}`
        );
      });
  }

  function userPasswordChangeRequest(uid, password) {
    return db.collection("pwChangeRequests").add({
      uid,
      password,
      timestamp: new Date(),
      requestedBy: currentUser.uid,
    });
  }

  async function updateTrainingModule(moduleId, data) {
    return companyRef
      .collection("trainingModules")
      .doc(moduleId)
      .update({
        ...data,
      })
      .then(async (doc) => {
        await logAuditTrail(
          currentUser.uid,
          `Updated Training Module: ${data.code}`
        );
      });
  }

  function GetArchivedActions() {
    const [actions, setActions] = useState([]);
    useEffect(() => {
      const unsubscribe = companyRef
        .collection("actions")
        .where("obsolete", "==", true)
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setActions(data);
        });
      return unsubscribe;
    }, []);
    return actions;
  }

  async function createBatches(records) {
    return new Promise(async (resolve, reject) => {
      if (records.length < 500) {
        const smallArray = [];
        await records.forEach((row) => {
          smallArray.push(row);
          resolve({ data: smallArray, nested: false });
        });
      } else {
        const chunkedArray = [];
        for (let i = 0; i < records.length; i++) {
          const last = chunkedArray[chunkedArray.length - 1];
          if (!last || last.length === 500) {
            chunkedArray.push([records[i]]);
          } else {
            last.push(records[i]);
          }
        }
        resolve({ data: chunkedArray, nested: true });
      }
    });
  }

  async function importActionsBQ(actions, companyId) {
    const batches = await createBatches(actions);
    console.log(batches);

    if (batches.nested) {
      // More than 500 records so handle each item in array as a batch separately
    }
    // let batch = db.batch()
    // actions.forEach(a => {
    //     const actionRef = db.collection('companies')
    //     .doc(companyId)
    //     .collection('actions')
    //     .doc(a.id)

    //     batch.update(actionRef, {
    //         bqImport: true
    //     })
    // })
    // return await batch.commit()
  }

  // Stripe Accounts START

  // Get a customers subscription
  function GetSubscription() {
    const [sub, setSub] = useState();
    useEffect(() => {
      const unsubscribe = db
        .collection("users")
        .doc(currentUser.uid)
        .collection("subscriptions")
        .where("status", "in", ["trialing", "active"])
        .onSnapshot(async (snapshot) => {
          if (!snapshot.empty) {
            // In this implementation we only expect one active or trialing subscription to exist.
            const doc = snapshot.docs[0];
            setSub({
              ...doc.data(),
              id: doc.id,
            });
          }
        });
      return unsubscribe;
    }, []);
    return sub;
  }

  // Get Stripe Products
  async function getProducts() {
    return db
      .collection("products")
      .where("active", "==", true)
      .orderBy("name")
      .get()
      .then(async (snap) => {
        let products = [];
        for (let i = 0; i < snap.docs.length; i++) {
          const doc = snap.docs[i];
          const priceSnap = await doc.ref
            .collection("prices")
            .where("active", "==", true)
            .get();

          const prices = priceSnap.docs.map((priceDoc) => ({
            ...priceDoc.data(),
            id: priceDoc.id,
          }));

          products.push({
            ...doc.data(),
            prices,
            id: doc.id,
            // meta: doc.data().metadata
          });
        }
        return products;
      });
  }

  // User Checkout
  async function checkout(price) {
    const docRef = await db
      .collection("users")
      .doc(currentUser.uid)
      .collection("checkout_sessions")
      .add({
        price: price,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });
    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
      const { error, url } = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
  }

  async function getCompanyMaxUsers() {
    // First check if user is on manual billing
    if (currentUser.billing_type === "manual") {
      return currentUser.maxUsers || 99;
    } else {
      const productRef = await db
        .collection("users")
        .doc(currentUser.uid)
        .collection("subscriptions")
        .where("status", "==", "active")
        .get()
        .then((snap) => {
          return snap.docs[0].data().product;
        });
      const maxUsers = await productRef.get().then((doc) => {
        return doc.data().metadata.maxUsers;
      });
      return maxUsers;
    }
  }

  function mgmtUpdateCompanyDocument(companyId, data) {
    return db
      .collection("companies")
      .doc(companyId)
      .update({
        ...data,
      });
  }

  function GetSalesAgents() {
    const [agents, setAgents] = useState();
    useEffect(() => {
      const unsubscribe = db
        .collection("sales_agents")
        .orderBy("firstName")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            value: doc.id,
            label: `${doc.data().firstName} ${doc.data().lastName}`,
          }));
          setAgents(data);
        });
      return unsubscribe;
    }, []);
    return agents;
  }

  function addSalesAgent(data) {
    return db.collection("sales_agents").add({
      ...data,
      createdAt: new Date(),
      createdBy: currentUser.uid,
    });
  }

  function updateSalesAgent(data) {
    return db
      .collection("sales_agents")
      .doc(data.id)
      .update({
        ...data,
        updatedAt: new Date(),
        updatedBy: currentUser.uid,
      });
  }

  function deleteSalesAgent(id) {
    return db.collection("sales_agents").doc(id).delete();
  }

  function blockCompany(id) {
    return db.collection("companies").doc(id).update({
      good_standing: false,
    });
  }

  function unblockCompany(id) {
    return db.collection("companies").doc(id).update({
      good_standing: true,
    });
  }

  async function mgmtGetSingleDocumnet(companyId, docId) {
    return await db
      .collection("companies")
      .doc(companyId)
      .collection("documents")
      .doc(docId)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
  }

  function mgmtUpdateSingleDocumnet(companyId, docId, data) {
    return db
      .collection("companies")
      .doc(companyId)
      .collection("documents")
      .doc(docId)
      .update({
        ...data,
      });
  }

  function MgmtGetAllTrainingSessions() {
    const [sessions, setSessions] = useState([]);

    useEffect(() => {
      const unsubscribe = db
        .collectionGroup("trainingSessions")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            data: {
              id: doc.id,
              ...doc.data(),
            },
            ref: doc.ref,
          }));
          setSessions(data);
        });
      return unsubscribe;
    }, []);
    return sessions;
  }

  async function mgmtUpdateOwnerOfSession(docArray) {
    const promiseArray = docArray.map((doc) => {
      const owner = doc.data.module.owner;
      const { firstName, lastName, email, id } = owner;
      return doc.ref.update({
        ...doc.data,
        module: {
          ...doc.data.module,
          owner: {
            firstName,
            lastName,
            email,
            id,
          },
        },
      });
    });
    return await Promise.all(promiseArray);
  }

  function MgmtGetAllAttendees() {
    const [attendees, setAttendees] = useState([]);

    useEffect(() => {
      const unsubscribe = db
        .collectionGroup("attendees")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            data: {
              id: doc.id,
              ...doc.data(),
            },
            ref: doc.ref,
          }));
          setAttendees(data);
        });
      return unsubscribe;
    }, []);
    return attendees;
  }

  async function mgmtUpdateAttendees(docArray) {
    const promiseArray = docArray.map((doc) => {
      const manager = doc.data.manager;
      const { firstName, lastName, email, id } = manager;
      return doc.ref.update({
        ...doc.data,
        manager: {
          firstName,
          lastName,
          email,
          id,
        },
        actionOfficer: "",
        auditOfficer: "",
        customerOfficer: "",
        docOfficer: "",
        mocOfficer: "",
        otpOfficer: "",
        riskOfficer: "",
        trainingOfficer: "",
        value: "",
      });
    });
    return await Promise.all(promiseArray);
  }

  function MgmtGetAllRisks() {
    const [risks, setRisks] = useState([]);

    useEffect(() => {
      const unsubscribe = db.collectionGroup("risks").onSnapshot((snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          data: {
            id: doc.id,
            ...doc.data(),
            companyId: doc.ref.parent.parent.parent.parent.id,
          },
          ref: doc.ref,
        }));
        setRisks(data);
      });
      return unsubscribe;
    }, []);
    return risks;
  }

  async function mgmtUpdateAllRisks(docArray) {
    const today = new Date();
    const promiseArray = docArray.map((doc) => {
      return doc.ref.update({
        manualUpdateRuntime: today,
      });
    });
    return await Promise.all(promiseArray);
  }

  function MgmtGetVideoCategories() {
    const [cats, setCats] = useState([]);

    useEffect(() => {
      const unsubscribe = db.collection("videos").onSnapshot((snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCats(data);
      });
      return unsubscribe;
    }, []);
    return cats;
  }

  async function mgmtGetVideoCats(catId) {
    const cat = await db
      .collection("videos")
      .doc(catId)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
    return cat;
  }

  function mgmtVideoUpdate(catId, videoArray) {
    return db.collection("videos").doc(catId).update({
      videos: videoArray,
    });
  }

  async function getVideo(docId, index) {
    const video = await db
      .collection("videos")
      .doc(docId)
      .get()
      .then((doc) => {
        return doc.data().videos[index];
      });
    return video;
  }

  async function getDocumentInterestedParties(docId) {
    const parties = await companyRef
      .collection("documents")
      .doc(docId)
      .collection("interestedParties")
      .get()
      .then((snap) => {
        if (!snap.empty) {
          let array = [];
          snap.docs.forEach((doc) => {
            array.push(doc.id);
          });
          console.log(array);
          return array;
        } else return [];
      });
    return parties;
  }

  function addParticipantToBlra(blraId, person) {
    return companyRef
      .collection("riskBaselineAssessments")
      .doc(blraId)
      .update({
        participants: fieldValue.arrayUnion(person),
      });
  }

  function updateBlra(blraId, data) {
    return companyRef
      .collection("riskBaselineAssessments")
      .doc(blraId)
      .update({
        ...data,
        updated: new Date(),
        updatedBy: currentUser.uid,
      });
  }

  async function getSingleStep(blraId, stepId) {
    return await companyRef
      .collection("riskBaselineAssessments")
      .doc(blraId)
      .collection("processSteps")
      .doc(stepId)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
  }

  async function getSingleProcess(processId) {
    return await companyRef
      .collection("riskProcesses")
      .doc(processId)
      .get()
      .then((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
  }

  async function mgmtBuildControls(risks) {
    const batch = db.batch();
    risks &&
      risks.map((r) => {
        r.data.controlsArray &&
          r.data.controlsArray.map((a) => {
            const controlsRef = db
              .collection("companies")
              .doc(r.data.companyId)
              .collection("riskControls")
              .doc();
            if (a.description) {
              batch.set(controlsRef, {
                ...a,
                risk: r.data.id,
              });
            }
          });
      });

    return await batch.commit();
  }

  function addFishboneCategory(actionId, category) {
    return companyRef
      .collection("actions")
      .doc(actionId)
      .collection("fishbone_categories")
      .add({
        actionId,
        created: new Date(),
        addedBy: currentUser.uid,
        category,
      });
  }

  function GetFishboneCategories(actionId) {
    const [cats, setCats] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("actions")
        .doc(actionId)
        .collection("fishbone_categories")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setCats(data);
        });
      return unsubscribe;
    }, [actionId]);
    return cats;
  }

  function addFishboneCause(actionId, categoryId, cause) {
    return companyRef
      .collection("actions")
      .doc(actionId)
      .collection("fishbone_categories")
      .doc(categoryId)
      .update({
        bones: fieldValue.arrayUnion(cause),
      });
  }

  function deleteFishboneCause(actionId, categoryId, cause) {
    return companyRef
      .collection("actions")
      .doc(actionId)
      .collection("fishbone_categories")
      .doc(categoryId)
      .update({
        bones: fieldValue.arrayRemove(cause),
      });
  }

  function deleteFishboneCategory(actionId, categoryId) {
    return companyRef
      .collection("actions")
      .doc(actionId)
      .collection("fishbone_categories")
      .doc(categoryId)
      .delete();
  }

  function saveFiveWhy(actionId, steps) {
    return companyRef.collection("actions").doc(actionId).update({
      fivewhysteps: steps,
    });
  }

  async function updateTrainingSession(session, data) {
    await companyRef
      .collection("trainingSessions")
      .doc(session.id)
      .update({
        ...data,
      });
    await logAuditTrail(
      currentUser.uid,
      `Updated Training Session: ${session.module?.name || session.id}`
    );
  }

  function GetIbraRiskControls(riskId) {
    const [controls, setControls] = useState([]);

    useEffect(() => {
      const unsubscribe = companyRef
        .collection("riskControls")
        .where("risk", "==", riskId)
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setControls(data);
        });
      return unsubscribe;
    }, [riskId]);
    return controls;
  }

  function removeIbraParticipant(ibraId, participant) {
    return companyRef
      .collection("riskIBAssessments")
      .doc(ibraId)
      .update({
        participants: fieldValue.arrayRemove(participant),
      });
  }

  function GetMOCTemplates() {
    const [templates, setTemplates] = useState([]);

    useEffect(() => {
      const unsubscribe = db
        .collection("masterFormTemplate")
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setTemplates(data);
        });
      return unsubscribe;
    }, []);
    return templates;
  }

  // ---------------------- START OF v2 Reusable Calls ------------------------------

  async function getStandardDoc(collectionPath, docId) {
    const doc = await db.doc(`${collectionPath}/${docId}`).get();
    return {
      id: doc.id,
      ...doc.data(),
      path: doc.ref.path,
    };
  }

  async function getCompanyDoc(collectionPath, docId) {
    const doc = await companyRef.collection(collectionPath).doc(docId).get();
    if (doc.exists) {
      return {
        id: doc.id,
        ...doc.data(),
        path: doc.ref.path,
      };
    } else return null;
  }

  async function updateDocFromPath(path, data) {
    const docRef = db.doc(path);
    await docRef.update({
      ...data,
      updatedAt: new Date(),
      updatedBy: currentUser.uid,
    });
  }

  async function deleteDocFromPath(path) {
    const docRef = db.doc(path);
    await docRef.delete();
  }

  async function updateCompanyDoc(collectionPath, docId, data) {
    const doc = collectionPath
      ? companyRef.collection(collectionPath).doc(docId)
      : companyRef;
    await doc.update({
      ...data,
      updatedAt: new Date(),
      updatedBy: currentUser.uid,
    });
  }

  async function addStandardDoc(collectionPath, data) {
    const model = await db.collection(collectionPath).add({
      ...data,
      createdAt: new Date(),
      owner: currentUser.uid,
      companyId: currentUser.companyId,
    });
    return model.id;
  }

  async function addCompanyDoc(collectionPath, data) {
    const doc = await companyRef.collection(collectionPath).add({
      ...data,
      createdAt: new Date(),
      owner: currentUser.uid,
      companyId: currentUser.companyId,
    });
    return doc.id;
  }

  async function setCompanyDoc(collectionPath, docId, data) {
    return await companyRef
      .collection(collectionPath)
      .doc(docId)
      .set(
        {
          ...data,
          createdAt: new Date(),
          owner: currentUser.uid,
          companyId: currentUser.companyId,
        },
        { merge: true }
      );
  }

  function GetCollection(
    admin,
    collection,
    whereClause = null,
    orderByClause = null,
    limit = null
  ) {
    const [data, setData] = useState();
    useEffect(() => {
      let query = admin
        ? db.collection(collection)
        : companyRef.collection(collection);
      if (whereClause !== null) {
        query = query.where(...whereClause);
      }
      if (orderByClause !== null) {
        query = query.orderBy(...orderByClause);
      }
      if (limit) {
        query = query.limit(limit);
      }

      const unsubscribe = query.onSnapshot((snapshot) => {
        const docs = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          value: doc.id,
          label: doc.data().name || doc.id,
          path: doc.ref.path,
        }));
        setData(docs);
      });
      return unsubscribe;
    }, [collection]);
    return data;
  }

  function GetCollectionPath(
    collection,
    whereClause = null,
    orderByClause = null
  ) {
    const [data, setData] = useState();
    useEffect(() => {
      let query = db.collection(collection);
      if (whereClause !== null) {
        query = query.where(...whereClause);
      }
      if (orderByClause !== null) {
        query = query.orderBy(...orderByClause);
      }

      const unsubscribe = query.onSnapshot((snapshot) => {
        const docs = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          value: doc.id,
          label: doc.data().name || doc.id,
          path: doc.ref.path,
        }));
        setData(docs);
      });
      return unsubscribe;
    }, [collection]);
    return data;
  }

  async function createNewFormTemplate(sections, questionsArray, formName) {
    const formRef = companyRef.collection("formTemplate").doc(formName);

    await formRef.set({
      companyId: currentUser.companyId,
      createdAt: new Date(),
      createdBy: currentUser.uid,
    });

    const sectionPromises = sections.map(async (sec, index) => {
      return new Promise(async (res, rej) => {
        const section = await formRef.collection("sections").add({
          ...sec,
          formId: formName,
        });
        const questionPromises = questionsArray[index].map((q) => {
          return section.collection("questions").add({
            ...q,
            type: "radio",
          });
        });
        await Promise.all(questionPromises);
        res();
      });
    });
    return await Promise.all(sectionPromises);
  }

  function updateCompanyRootDoc(data) {
    return companyRef.update({
      ...data,
    });
  }

  async function incrementMocCount() {
    const date = new Date();
    const year = date.getFullYear();
    const coDoc = await companyRef.get();
    const mocCount = coDoc.data().mocCount?.[year] || 0;
    await companyRef.update({
      mocCount: {
        [year]: mocCount + 1,
      },
    });
    return `MOC-${mocCount + 1}/${year}`;
  }

  async function importOtpMeasurements(otpId, targetId, programId, records) {
    const batchSize = 500;
    const totalRecords = records.length;
    let processedRecords = 0;
    const batchPromises = [];

    while (processedRecords < totalRecords) {
      const batch = db.batch();

      const batchRecords = records.slice(
        processedRecords,
        processedRecords + batchSize
      );
      batchRecords.forEach((row) => {
        const measurementsRef = companyRef
          .collection("otps")
          .doc(otpId)
          .collection("targets")
          .doc(targetId)
          .collection("programs")
          .doc(programId)
          .collection("measurements")
          .doc(); // Use .doc() to generate a new document reference
        batch.set(measurementsRef, row);
      });

      batchPromises.push(batch.commit());

      processedRecords += batchRecords.length;
    }

    await Promise.all(batchPromises);
  }

  // ---------------------- END DATA ------------------------------

  const value = {
    addChecklistField,
    updateBlra,
    getChecklistField,
    updateChecklistField,
    getUsers,
    GetDocs,
    editTextField,
    GetDepartments,
    GetRosters,
    GetSites,
    getSite,
    returnDocTypes,
    getDocLength,
    addDocument,
    editTargetRevDate,
    saveDocumentType,
    GetDocTypes,
    GetDocInterestedParties,
    returnDocParties,
    savePartyToDoc,
    savePartyToRevision,
    removePartyFromDoc,
    removePartyFromRevision,
    bulkEmailDocParties,
    bulkEmailRevParties,
    saveRevision,
    addDepartment,
    GetDepartmentList,
    GetRosterList,
    addRoster,
    addSite,
    GetSiteList,
    GetUserList,
    editSelectField,
    GetDocTypesDropdown,
    getRevisionLength,
    getDocById,
    approveDocument,
    getRevisionById,
    approveRevision,
    addSubfolder,
    GetSubfolders,
    removeSubfolder,
    GetSubfoldersDropdown,
    addSupportingDoc,
    GetSupportingDocs,
    GetInterestedParties,
    GetRevisions,
    saveSubscriberToRevision,
    saveSubscriberToDoc,
    removeSubscriberFromRevision,
    removeSubscriberFromDoc,
    bulkEmailDocSubscribers,
    bulkEmailRevSubscribers,
    GetSubscribers,
    acknowledgeDoc,
    GetNotifications,
    markNotificationAsRead,
    markNotificationAsUnread,
    markAllNotificationsRead,
    createGroup,
    GetGroups,
    addSubscriberGroup,
    GetSubscriberGroups,
    removeSubscriberGroup,
    emailSubscriberGroup,
    GetGroupMembers,
    removeMemberFromGroup,
    addMembersToGroup,
    getUserGroups,
    GetOverdueDocs,
    GetDocsForNextDays,
    convertRosterToUser,
    addDocToFavorites,
    getUserFavoriteDocs,
    removeDocFromFavorites,
    getSingleDocument,
    GetEmployees,
    createSource,
    GetSources,
    createAction,
    createIsoAgency,
    GetIsoAgencies,
    GetPriorities,
    updatePriority,
    GetActions,
    addActionAttachment,
    GetActionAttachments,
    deleteActionAttachment,
    closeOutAction,
    reOpenAction,
    addDynamicIntParty,
    GetDynamicIntParties,
    addDynamicIntGroup,
    GetDynamicIntPartyGroups,
    removeDynamicIntParty,
    removeDynamicIntGroup,
    deleteDynamicDocument,
    GetOverdueActions,
    emailDynamicIndividualParty,
    emailDynamicGroup,
    obsoleteDoc,
    GetObsoleteDocs,
    restoreObsolete,
    emailAllPartiesDynamic,
    GetCompanies,
    GetAllUsers,
    updateUserCompany,
    makeUserAdmin,
    addCompany,
    CreateOTP,
    GetCriteria,
    GetOTPs,
    GetOTPSearch,
    GetUserProfile,
    changeProfilePhoto,
    createNewSuperUser,
    editSupportingDoc,
    editEmployeeSupportingDoc,
    generateDocRegisterPdf,
    testCallFunction,
    GetIsoAgenciesPerClass,
    createDocsPdf,
    ceateEmployeePdf,
    createActionsReportPdf,
    AddOTPTarget,
    GetOTPTargets,
    requestUserCreation,
    getDocsSubmittedPDF,
    updateRevision,
    GetGroupsForDocs,
    addAdditionalGroupsToDoc,
    removeGroupFromDoc,
    removeFolderFromSupDoc,
    getDocRegisterPdf,
    updateUserLevel,
    GetUsersForCompany,
    updateCompanyModules,
    getCompanyModules,
    getCompanySettings,
    updateCompanySettings,
    revokeAdmin,
    GetUsersLevelFilter,
    toggleAdminStatus,
    GetIsoClasses,
    createIsoClass,
    addCriteria,
    GetAllOtpTargets,
    addProgram,
    GetProgramsForTarget,
    GetAllPrograms,
    fixPrograms,
    GetPrograms,
    getCompanyNameById,
    updateIsoClass,
    deleteIsoClass,
    updateIsoAgency,
    deleteIsoAgency,
    updateDepartment,
    deleteDepartment,
    updateSite,
    deleteSite,
    updateDocType,
    deleteDocType,
    updateSource,
    deleteSource,
    updateCriteria,
    deleteCriteria,
    updateGroup,
    deleteUser,
    managerDeleteUser,
    updateTarget,
    obsoleteTarget,
    updateProgram,
    obsoleteProgram,
    closeProgram,
    GetOverduePrograms,
    getSupportingDoc,
    addTemplate,
    GetTemplates,
    deleteTemplate,
    shareEmailAttachment,
    updateAction,
    deleteSupportingDoc,
    deleteEmployeeSupportingDoc,
    removeEmployeeFolderFromDocSubmitted,
    GetAuditTrail100,
    userLogsPdf,
    getUserLogs,
    GetUserLogsDates,
    getSingleActionPdf,
    generateOtpRegisterPdf,
    generateSingleOtpPdf,
    updateOTP,
    submitSupportRequest,
    addIsoCode,
    GetIsoCodes,
    replaceUserManual,
    retrieveUserManual,
    retrieveLicense,
    generateSingleDocPdf,
    generateSeportionDocFilterdPdf,
    MgmtGetCoDepartments,
    mgmtCreateDepartment,
    MgmtGetSites,
    mgmtCreateSite,
    MgmtGetSources,
    mgmtCreateSource,
    MgmtGetIsoClasses,
    mgmtCreateCode,
    mgmtCreateClass,
    MgmtGetIsoCodes,
    MgmtGetRosters,
    mgmtCreateRoster,
    MgmtGetPriorities,
    mgmtUpdatePriority,
    MgmtGetActions,
    MgmtGetIsoAgencies,
    mgmtCreateIsoAgency,
    mgmtCreateAction,
    MgmtGetActionIntParties,
    mgmtAddActionIntParty,
    MgmtGetActionAttachments,
    mgmtAddActionAttachment,
    mgmtUpdateAction,
    MgmtGetDocs,
    MgmtGetDocTypes,
    mgmtCreateDocTypes,
    mgmtCreateDoc,
    MgmtGetDocIntParties,
    mgmtAddDocIntParty,
    MgmtGetDocRevisions,
    mgmtCreateRevision,
    MgmtGetSupportingDocs,
    MgmtGetSubFolders,
    mgmtCreateSubfolder,
    mgmtCreateSupDoc,
    actionExtensionRequest,
    GetActionExtensionRequests,
    getActionExtensionRequest,
    extensionApproval,
    getAction,
    markSupDocReplaced,
    markEmployeeSupDocReplaced,
    updateReportsTo,
    addProgramAttachment,
    GetProgramAttachments,
    deleteProgramAttachment,
    GetUnreadNotifications,
    getSingleOtp,
    getSingleTarget,
    GetActionIntParties,
    addActionComment,
    GetActionComments,
    GetDocIntPartiesNew,
    getDocument,
    mgmtGetDocCatLength,
    createSharedFolder,
    addSharedDocument,
    GetSharedFolderDocs,
    GetSharedFolders,
    getSharedFolder,
    createOtpMetric,
    GetOtpMetrics,
    getSingleProgram,
    addProgramMeasurement,
    editProgramMeasurement,
    GetProgramMeasurements,
    updateOtpMetric,
    reOpenProgram,
    updateAllOtpsWithProgramCount,
    updateAllTargetsWithProgramCount,
    getTotalDocsForCategory,
    getNumOverdueDocsPerCategory,
    toggleExtendedDocMode,
    getTotalDocs,
    getTotalDocsOverdue,
    getTotalActionsForYear,
    makeRosterOnlyFalse,
    getActionsForYear,
    GetActionsAsc,
    fixProgramCounts,
    addIntProgramTargets,
    reverseFixProgramTargets,
    fixIntervalDays,
    fixClosedCount,
    fixClosedOnOtp,
    changeYearStringsToInts,
    GetPendingDocs,
    getAllTargets,
    createTrainingProvider,
    GetTrainingProviders,
    getTrainingProvider,
    facilitatorCheck,
    GetTrainingRisks,
    updateRisk,
    createTrainingModule,
    GetTrainingModules,
    fixModules,
    getTrainingModule,
    createOccupation,
    GetOccupations,
    getOccupation,
    requireModule,
    updateOccupationName,
    addVenue,
    GetTrainingVenues,
    getTrainingVenue,
    createNewEmployee,
    addFacilitator,
    getTrainingVenuesForSite,
    getTrainingFacilitators,
    scheduleTraining,
    getTrainingSession,
    GetUpcomingTrainingSessions,
    addAttendeesToSession,
    attendeeInviteResponse,
    GetAttendees,
    GetFacilitatorUpcomingSessions,
    assessCompetence,
    GetAllCompetency,
    updateCompentancy,
    GetEmployeeCompetency,
    getEmployee,
    createAttendanceRegister,
    confirmAttend,
    confirmAbsence,
    requestTraining,
    GetEmployeeCompetencyEvaluationsOnly,
    newEmployeeEvaluation,
    GetEmployeeEvaluations,
    GetEvaluations,
    employeeCompetencyCertificate,
    GetUserUpcomingTrainingSessions,
    newTrainingMatrixReport,
    getTrainingMatrix,
    GetFacilitators,
    GetTrainingMatrixReports,
    GetTrainingRequests,
    GetSessionsOfModule,
    deleteTrainingRequest,
    getYearSessionCount,
    getRDocuments,
    GetFilteredDocuments,
    getSessionReport,
    saveSessionReport,
    GetSessionReports,
    singleSessionReport,
    GetPastTrainingSessions,
    GetPastTrainingSessionsDropdown,
    generateAttendanceReport,
    saveAttendanceReport,
    retrieveAttendaceReport,
    GetUserFavouriteDocs,
    createTrainingRiskProfile,
    getRActions,
    getROtp,
    getFacilitator,
    addOccupationRequest,
    removeOccupation,
    updateEmployeeDetails,
    updateProgress,
    updateSetupComplete,
    updateRiskSetting,
    GetRiskAreas,
    addRiskAreas,
    GetRiskTypes,
    addRiskType,
    GetRiskEntities,
    addRiskEntities,
    GetRiskPatterns,
    addRiskPatterns,
    addCriticalThreshold,
    updateCriticalThreshold,
    addRiskHierarchy,
    createBaseline,
    getBaseline,
    GetRiskAreasForSite,
    GetBaselines,
    addRiskProcess,
    GetRiskProcesses,
    advanceBaseline,
    addProcessesToBaseline,
    GetBaselineProcesses,
    GetBaselineProcessSteps,
    createHazard,
    getHazard,
    addRisk,
    updateBlraRisk,
    GetRiskHierarchy,
    GetHazardsForStep,
    GetRisksForHazard,
    addRiskControls,
    getRisk,
    addFinalRisk,
    addResidualRisk,
    addIssueBasedRa,
    getIbra,
    updateIbra,
    createIbHazard,
    GetRisksForIBHazard,
    getIbraHazard,
    updateIbraHazard,
    addIBRisk,
    GetIBAssessments,
    GetIsoSignsOfType,
    addSignToIbra,
    GetIsoSelectedSigns,
    removeSignFromIbra,
    GetCustomSignsOfType,
    addCustomSign,
    updateIbraRisk,
    GetHazardsForIB,
    getIBRisk,
    GetBlraHazards,
    getBlraName,
    GetBlraRisks,
    GetAllBaselineProcessSteps,
    getCompleteRisk,
    getCompleteIbraRisk,
    GetIbraRisks,
    GetIbraSigns,
    getBlraPdf,
    getIbraPdf,
    saveCcms,
    GetCriticalControls,
    getRiskCounts,
    getIbraRisk,
    getRiskProcess,
    updateRiskProcess,
    GetTopRisksPerHod,
    GetTopRiskPerSite,
    createChecklist,
    deleteCheckList,
    getChecklist,
    createChecklistSection,
    GetChecklistSections,
    GetChecklistSectionFields,
    GetChecklists,
    scheduleChecklist,
    deleteSchedules,
    GetSchedules,
    GetInspections,
    saveSectionSubmission,
    getSectionSubmission,
    advanceInspection,
    getInspection,
    GetInspectionSectionData,
    completeInspection,
    raiseIssue,
    GetIssues,
    getIssue,
    createProduct,
    GetProducts,
    createSupplier,
    GetSuppliers,
    GetMissedInspections,
    GetCompleteInspections,
    GetAuditCount,
    GetMissedInspectionsThisYear,
    GetCompleteInspectionsThisYear,
    GetSupplierCount,
    GetFacilitatorPreviousSessions,
    updateHazard,
    getHodCounts,
    retrieveBaselineProcesses,
    addEmpSubfolder,
    GetEmpSubfolders,
    removeEmpSubfolder,
    addEmpSupportingDoc,
    GetEmpSupportingDocs,
    GetEmpSubfoldersDropdown,
    checkEmployeeExists,
    userPasswordChangeRequest,
    updateTrainingModule,
    generateAuditsInspectionsPdf,
    obsoleteAction,
    GetArchivedActions,
    restoreAction,
    generateSingleAuditsInspectionPdf,
    importActionsBQ,
    GetSubscription,
    getProducts,
    checkout,
    getCompanyMaxUsers,
    getIsoAgenciesPerClassAsync,
    GetSalesAgents,
    addSalesAgent,
    updateSalesAgent,
    deleteSalesAgent,
    blockCompany,
    mgmtGetSingleDocumnet,
    mgmtUpdateSingleDocumnet,
    unblockCompany,
    MgmtGetAllTrainingSessions,
    mgmtUpdateOwnerOfSession,
    MgmtGetAllAttendees,
    mgmtUpdateAttendees,
    MgmtGetAllRisks,
    mgmtUpdateAllRisks,
    MgmtGetVideoCategories,
    mgmtGetVideoCats,
    mgmtVideoUpdate,
    getVideo,
    getDocumentInterestedParties,
    deleteProcessStep,
    addParticipantToBlra,
    getSingleStep,
    getSingleProcess,
    mgmtBuildControls,
    addFishboneCategory,
    GetFishboneCategories,
    addFishboneCause,
    deleteFishboneCause,
    deleteFishboneCategory,
    saveFiveWhy,
    updateTrainingSession,
    GetIbraRiskControls,
    addIbraRiskControls,
    removeIbraParticipant,
    addIbraParticipant,
    checkCompanyTrainingRiskAsync,
    getTrainingSessionByModuleAndAttendee,
    GetMOCTemplates,
    getStandardDoc, // V2
    addStandardDoc, // V2
    GetCollection, // V2?
    createNewFormTemplate,
    getCompanyDoc, // V2?
    updateCompanyDoc, // V2?
    addCompanyDoc, // V2?
    setCompanyDoc, // V2?
    updateDocFromPath, // V2
    GetCollectionPath, // V2
    updateCompanyRootDoc, // V2
    incrementMocCount,
    deleteDocFromPath, // V2
    updateChecklistSectionScore,
    getExternalDocLink,
    importOtpMeasurements,
    getCompanyDocument,
    mgmtUpdateCompanyDocument,
  };
  return (
    <DatabaseContext.Provider value={value}>
      {children}
    </DatabaseContext.Provider>
  );
}
