import {
  faBuilding,
  faCalendar,
  faDatabase,
  faFile,
  faFileAlt,
  faGlobeAfrica,
  faPencilAlt,
  faSearch,
  faTrashAlt,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Button,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  LinearProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { useParams, useHistory } from "react-router-dom";
import { useDb } from "../../../../contexts/DatabaseContext";
import EditSelect from "../../doc-register/edits/EditSelect";
import EditTextField from "../../doc-register/edits/EditTextField";
import EditDocGroups from "../../doc-register/EditDocGroups";
import { ArrowBack } from "@mui/icons-material";

export default function REditDoc() {
  const { editTargetRevDate, getSingleDocument } = useDb();
  const { docId } = useParams();
  const [open, setOpen] = useState(false);
  const [comp, setComp] = useState(null);
  const [doc, setDoc] = useState();
  const [updated, setUpdated] = useState(false);
  const history = useHistory();

  const openDialog = (c) => {
    setComp(c);
    setOpen(true);
  };

  const closeEditor = () => {
    setOpen(false);
    setComp(null);
    setUpdated(!updated);
  };

  const editRevDate = async (newDate) => {
    try {
      await editTargetRevDate(newDate.toDate(), docId);
      setUpdated(true);
    } catch (err) {
      window.alert(err.message);
    }
  };

  useEffect(async () => {
    console.log(docId);
    const document = await getSingleDocument(docId);
    console.log(document);
    setDoc(document);
    return document;
  }, [updated]);

  if (doc)
    return (
      <div>
        <h1>Edit Document: {doc.docCode}</h1>
        <div className="back-link">
          <Button
            variant="contained"
            color="primary"
            startIcon={<ArrowBack />}
            onClick={() => history.goBack()}
          >
            Back
          </Button>
        </div>
        <CardHeader
          avatar={
            <Avatar>
              <FontAwesomeIcon icon={faFile} />
            </Avatar>
          }
          title={`${doc.docCode} - ${doc.docName}`}
          subheader={"Document Name"}
          action={
            <IconButton
              onClick={() =>
                openDialog(
                  <EditTextField
                    oldValue={doc.docName}
                    doc={doc}
                    field={"docName"}
                    closeEditor={closeEditor}
                  />
                )
              }
              size="large"
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </IconButton>
          }
        />
        <CardHeader
          avatar={
            <Avatar>
              <FontAwesomeIcon icon={faFile} />
            </Avatar>
          }
          title={`${doc.customId || "N/A"}`}
          subheader={"Custom Doc ID"}
          action={
            <IconButton
              onClick={() =>
                openDialog(
                  <EditTextField
                    oldValue={doc.customId}
                    doc={doc}
                    field={"customId"}
                    closeEditor={closeEditor}
                  />
                )
              }
              size="large"
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </IconButton>
          }
        />
        <CardHeader
          avatar={
            <Avatar>
              <FontAwesomeIcon icon={faCalendar} />
            </Avatar>
          }
          title={
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Target Revision Date"
                onChange={editRevDate}
                defaultValue={dayjs(doc.targetRevDate.toDate())}
              />
            </LocalizationProvider>
          }
        />

        <CardHeader
          avatar={
            <Avatar>
              <FontAwesomeIcon icon={faBuilding} />
            </Avatar>
          }
          title={doc.department.label}
          subheader={"Department"}
          action={
            <IconButton
              onClick={() =>
                openDialog(
                  <EditSelect
                    type={"department"}
                    doc={doc}
                    closeEditor={closeEditor}
                  />
                )
              }
              size="large"
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </IconButton>
          }
        />
        <CardHeader
          avatar={
            <Avatar>
              <FontAwesomeIcon icon={faGlobeAfrica} />
            </Avatar>
          }
          title={doc.site.label}
          subheader={"Site"}
          action={
            <IconButton
              onClick={() =>
                openDialog(
                  <EditSelect
                    type={"site"}
                    doc={doc}
                    closeEditor={closeEditor}
                  />
                )
              }
              size="large"
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </IconButton>
          }
        />
        <CardHeader
          avatar={
            <Avatar>
              <FontAwesomeIcon icon={faFileAlt} />
            </Avatar>
          }
          title={doc.docType.label}
          subheader={"Document Type"}
          action={
            <IconButton
              onClick={() =>
                openDialog(
                  <EditSelect
                    type={"docType"}
                    doc={doc}
                    closeEditor={closeEditor}
                  />
                )
              }
              size="large"
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </IconButton>
          }
        />
        <CardHeader
          avatar={
            <Avatar>
              <FontAwesomeIcon icon={faUser} />
            </Avatar>
          }
          title={doc.owner.label}
          subheader={"Document Owner"}
          action={
            <IconButton
              onClick={() =>
                openDialog(
                  <EditSelect
                    type={"owner"}
                    doc={doc}
                    closeEditor={closeEditor}
                  />
                )
              }
              size="large"
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </IconButton>
          }
        />
        <CardHeader
          avatar={
            <Avatar>
              <FontAwesomeIcon icon={faDatabase} />
            </Avatar>
          }
          title={`${doc.retentionPeriod.label}`}
          subheader={"Retention Period"}
          action={
            <IconButton
              onClick={() =>
                openDialog(
                  <EditSelect
                    type={"retentionPeriod"}
                    doc={doc}
                    closeEditor={closeEditor}
                  />
                )
              }
              size="large"
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </IconButton>
          }
        />
        <CardHeader
          avatar={
            <Avatar>
              <FontAwesomeIcon icon={faTrashAlt} />
            </Avatar>
          }
          title={doc.dispositionMethod.label}
          subheader={"Disposition Method"}
          action={
            <IconButton
              onClick={() =>
                openDialog(
                  <EditSelect
                    type={"dispositionMethod"}
                    doc={doc}
                    closeEditor={closeEditor}
                  />
                )
              }
              size="large"
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </IconButton>
          }
        />
        <CardHeader
          avatar={
            <Avatar>
              <FontAwesomeIcon icon={faUsers} />
            </Avatar>
          }
          title={"Groups"}
          action={
            <IconButton
              onClick={() => openDialog(<EditDocGroups doc={doc} />)}
              size="large"
            >
              <FontAwesomeIcon icon={faSearch} />
            </IconButton>
          }
        />

        <Dialog open={open} maxWidth="sm" fullWidth onClose={closeEditor}>
          <DialogContent>{comp}</DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  else return <LinearProgress />;
}
