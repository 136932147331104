import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import five_consequences from "./models/5x5model/five_consequences.json";
import five_likelihoods from "./models/5x5model/five_likelihoods.json";
import six_consequences from "./models/6x6model/six_consequences.json";
import six_likelihoods from "./models/6x6model/six_likelihoods.json";
import eight_consequences from "./models/8x8model/eight_consequences.json";
import eight_likelihoods from "./models/8x8model/eight_likelihoods.json";
import RiskDetail from "./RiskDetail";

function RiskMatrix({ type, initialLike, initialCons, finalLike, finalCons }) {
  const { currentUser } = useAuth();

  const types = {
    "5x5": {
      consequences: five_consequences,
      likelihoods: five_likelihoods,
      lower: currentUser.riskControls
        ? currentUser.riskControls.low.toString()
        : "8",
      upper: currentUser.riskControls
        ? currentUser.riskControls.med.toString()
        : "16",
      // boundries: [8, 22]
    },
    "6x6": {
      consequences: six_consequences,
      likelihoods: six_likelihoods,
      lower: "8",
      upper: "18",
      // boundries: [12, 29]
    },
    "8x8": {
      consequences: eight_consequences,
      likelihoods: eight_likelihoods,
      lower: "14",
      upper: "55",
      // boundries: [14, 55]
    },
  };

  return (
    <div className="risk-matrix">
      <Rows
        cats={types[type].consequences.sort((a, b) =>
          a.value < b.value ? 1 : b.value < a.value ? -1 : 0
        )}
        // cats={types[type].consequences.reverse()}
        likes={types[type].likelihoods}
        lower={types[type].lower}
        upper={types[type].upper}
        boundries={types[type].boundries}
        initialLike={initialLike}
        initialCons={initialCons}
        finalLike={finalLike}
        finalCons={finalCons}
      />
    </div>
  );
}

function Rows({
  cats,
  likes,
  lower,
  upper,
  initialLike,
  initialCons,
  finalLike,
  finalCons,
}) {
  const [detail, setDetail] = useState(null);
  const [open, setOpen] = useState(false);

  const handleDetailOpen = (risk) => {
    setDetail(risk);
    setOpen(true);
  };

  const handleDetailClose = () => {
    setOpen(false);
    setDetail(null);
  };
  //need a way of getting button values
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={1}>
          <Typography className="rotate-text">Consequence</Typography>
        </Grid>
        <Grid item xs={11}>
          <>
            {cats.map((category, index) => {
              return (
                <React.Fragment key={`category${category.id}`}>
                  <div className="row">
                    <div className="level">{category.level}</div>
                    <div className="word-model">{category.wordModel}</div>

                    {likes.map((likelihood, i) => {
                      const btnValue = category.value * likelihood.value;
                      const btn2 = category.value + i;
                      const riskLevels = {
                        low: "low",
                        medium: "medium",
                        high: "high",
                      };
                      let riskLevel = riskLevels.low;
                      switch (true) {
                        case btnValue > upper:
                          riskLevel = riskLevels.high;
                          break;
                        case btnValue > lower:
                          riskLevel = riskLevels.medium;
                          break;
                        default:
                          riskLevel = riskLevels.low;
                      }
                      if (
                        initialLike === likelihood.value &&
                        initialCons === category.value
                      ) {
                        return (
                          <button
                            onClick={() =>
                              handleDetailOpen({ category, likelihood })
                            }
                            className={riskLevel}
                            key={`like${likelihood.id}`}
                            style={{
                              boxShadow: "0px 0px 5px black",
                              zIndex: 99,
                            }}
                          >
                            <Typography
                              style={{ fontWeight: "bold", color: "#fff" }}
                            >
                              Inherent
                            </Typography>
                            <br />
                            <br />
                            {btnValue}
                            <br />
                            {/* {likelihood.likelihood} */}
                          </button>
                        );
                      }
                      if (
                        finalLike === likelihood.value &&
                        finalCons === category.value
                      ) {
                        return (
                          <button
                            onClick={() =>
                              handleDetailOpen({ category, likelihood })
                            }
                            className={riskLevel}
                            key={`like${likelihood.id}`}
                            style={{
                              boxShadow: "0px 0px 5px black",
                              zIndex: 99,
                            }}
                          >
                            <Typography
                              style={{ fontWeight: "bold", color: "#fff" }}
                            >
                              Residual
                            </Typography>
                            <br />
                            {btnValue}
                            <br />
                            {/* {likelihood.likelihood} */}
                          </button>
                        );
                      } else
                        return (
                          <button
                            onClick={() =>
                              handleDetailOpen({ category, likelihood })
                            }
                            className={riskLevel}
                            key={`like${likelihood.id}`}
                          >
                            {btnValue}
                            <br />
                            {/* {likelihood.likelihood} */}
                          </button>
                        );
                    })}
                  </div>
                </React.Fragment>
              );
            })}
            {detail && (
              <RiskDetail open={open} close={handleDetailClose} risk={detail} />
            )}
          </>
          <div style={{ textAlign: "center", width: "100%", marginTop: 10 }}>
            <b>
              <Typography fontWeight={"bold"}>Likelihood</Typography>
            </b>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default RiskMatrix;
