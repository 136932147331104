import { Avatar, CardContent } from "@mui/material";
import { Info } from "@mui/icons-material";
import { Card, CardHeader, InputAdornment, TextField } from "@mui/material";
import React from "react";
import { selectFieldProps, textFieldProps } from "../../shared/constants";
import Select from "react-select";

export default function RiskLevelCard({
  level,
  riskControls,
  index,
  handleMonetary,
  riskMonetary,
  error,
  groups,
  handleGroupSelect,
}) {
  return (
    <Card>
      <CardHeader
        title={level.description}
        avatar={
          <Avatar>
            <Info />
          </Avatar>
        }
        subheader={`Risk Threshold: ${
          riskControls[level.name] ? riskControls[level.name] : "Not Assigned"
        }`}
      />
      <CardContent>
        <TextField
          {...textFieldProps}
          label="Spending Threshold"
          onChange={(e) => handleMonetary(e, index)}
          type="number"
          InputProps={{
            startAdornment: <InputAdornment position="start">R</InputAdornment>,
            inputProps: {
              min: index > 0 ? riskMonetary[index - 1].value + 1 : 0,
            },
          }}
          value={riskMonetary[index].value}
          error={error}
          helperText={
            error ? "Value must be higher than the previous risk level" : ""
          }
        />
        <Select
          {...selectFieldProps}
          options={groups}
          isMulti
          placeholder="Select Groups ..."
          onChange={(v, e) => handleGroupSelect(v, index)}
        />
      </CardContent>
    </Card>
  );
}
