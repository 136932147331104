import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { KeyboardArrowRight, Send } from "@mui/icons-material";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import { useDb } from "../../../contexts/DatabaseContext";
import fivePreview from "../../../img/5x5.png";
import sixPreview from "../../../img/6x6.png";
import eightPreview from "../../../img/8x8.png";

export default function R1ChooseMatrix() {
  const { updateProgress, updateRiskSetting } = useDb();
  const { updateCurrentUser } = useAuth();
  const history = useHistory();

  const [state, setState] = useState({
    loading: false,
    error: null,
  });

  const progress = async () => {
    await updateProgress(2);
  };

  const handleSubmit = async (data) => {
    setState({
      ...state,
      loading: true,
      error: null,
    });
    try {
      await updateRiskSetting(data);
      await updateCurrentUser();
      progress();
      setState({
        loading: false,
        error: null,
      });
      history.push("/risk-manager/setup/choose-areas");
    } catch (err) {
      setState({
        ...state,
        loading: false,
        error: err.message,
      });
    }
  };

  const matrixData = [
    {
      title: "5 x 5 Matrix",
      preview: "/risk-manager/setup/choose-matrix/5x5preview",
      val: 25,
      matrix: "5x5",
      img: fivePreview,
    },
    {
      title: "6 x 6 Matrix",
      preview: "/risk-manager/setup/choose-matrix/6x6preview",
      val: 36,
      matrix: "6x6",
      img: sixPreview,
    },
    {
      title: "8 x 8 Matrix",
      preview: "/risk-manager/setup/choose-matrix/8x8preview",
      val: 64,
      matrix: "8x8",
      img: eightPreview,
    },
  ];

  return (
    <>
      {state.loading ? (
        <CircularProgress />
      ) : (
        <div>
          <Typography variant={"h5"}>Risk Manager: Initial Setup</Typography>
          <br />
          <Typography variant={"h6"}>
            Step 1: Please select a Risk Matrix that is appropriate for your
            company:
          </Typography>
          <br />
          <Grid container spacing={3}>
            {matrixData.map((matrix) => (
              <Grid item xs={12} lg={4} key={matrix.matrix}>
                <Card elevation={6}>
                  <CardContent>
                    <Typography>{matrix.title}</Typography>
                    <hr />
                    <img src={matrix.img} width="100%" />
                  </CardContent>
                  <CardActions className="iso-right">
                    <Link to={matrix.preview}>
                      <Button variant={"contained"} color={"primary"}>
                        Preview
                      </Button>
                    </Link>
                    <Button
                      onClick={() =>
                        handleSubmit({
                          riskMatrix: matrix.matrix,
                          riskMax: matrix.val,
                        })
                      }
                      disabled={state.loading}
                      endIcon={<KeyboardArrowRight />}
                      variant={"contained"}
                      color={"primary"}
                    >
                      Select
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
          {state.error && (
            <Typography color="secondary">{state.error}</Typography>
          )}
        </div>
      )}
    </>
  );
}
