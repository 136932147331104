import React from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Button } from '@mui/material'

export default function AuditIssues() {
    const { GetIssues } = useDb()
    const issues = GetIssues()

    const rows = issues && issues.map(issue => ({
        id: issue.id,
        createdBy: issue.createdBy.label,
        description: issue.description,
        field: issue.field.name,
        date: moment(issue.timestamp.toDate()).format('DD/MM/YYYY'),
        inspectionId: issue.inspectionId,
        jobRef: issue.jobRef
    }))

    const columns = [
        { field: 'date', headerName: 'Date', width: 200 },
        { field: 'createdBy', headerName: 'Created By', width: 200 },
        { field: 'jobRef', headerName: 'Audit/Inspection', width: 200 },
        { field: 'field', headerName: 'Field', width: 200 },
        { 
            field: 'status', 
            headerName: 'Status', 
            width: 200,
            renderCell: (params) => {
                return (
                    <Link to={`/audit-manager/issues/${params.row.id}`}>
                        <Button
                            color="primary"
                            size="small"
                            variant="contained"
                        >
                            View
                        </Button>
                    </Link>
                );
            }
        },
    ]
    return (
        <div>
            <h1>Audit / Inspection Issues</h1>
            
            <IsoDataGrid
                data={rows}
                columns={columns}
            />
        </div>
    )
}
