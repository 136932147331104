import { fieldValue } from '../../../firebase'

export function editRevision(companyRef, docId, revId, data) {
    return companyRef.collection('documents')
    .doc(docId)
    .collection('revisions')
    .doc(revId)
    .update({
        ...data
    })
}

export async function editAddGroupsToDoc(companyRef, docId, groups) {
    return new Promise(async (res, rej) => {
        for(let i = 0; i < groups.length; i++) {
            await companyRef.collection('documents')
            .doc(docId)
            .collection('groups')
            .doc(groups[i].id)
            .set({
                ...groups[i]
            })

            await companyRef.collection('documents')
            .doc(docId)
            .update({
                groups: fieldValue.arrayUnion(groups[i].id)
            })
        }
        res()
    })
}

export async function editRemoveGroupFromDoc(companyRef, docId, groupId) {
    return new Promise(async (res, rej) => {
        await companyRef.collection('documents')
        .doc(docId)
        .collection('groups')
        .doc(groupId)
        .delete()

        await companyRef.collection('documents')
        .doc(docId)
        .update({
            groups: fieldValue.arrayRemove(groupId)
        })

        res()
    })
}

export async function removeFolderFromDocSubmitted(companyRef, docId, supDocId) {
    return await companyRef.collection('documents')
    .doc(docId)
    .collection('supportingDocs')
    .doc(supDocId)
    .update({
        folder: null
    })
}