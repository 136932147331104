import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
    IconButton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AddBox, Delete, Save } from '@mui/icons-material'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDb } from '../../../../contexts/DatabaseContext'
import { textFieldProps } from '../../../shared/constants'

const useStyles = makeStyles((theme) => ({
    header: {
        background: theme.palette.primary.main
    }
}))

export default function EditCheckbox({open, close, checklistId, sectionId, fieldData}) {
    const classes = useStyles()
    const { updateChecklistField } = useDb()

    const [state, setState] = useState({
        ...fieldData.data
    })

    useEffect(()=> {
      setState({...fieldData.data})
    }, [open])

    const [actionState, setActionState] = useState({ loading: false, error: null })

    const [option, setOption] = useState()

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const handleOptionChange = (e) => {
        setOption(e.target.value)
    }
    
    const handleClose = () => {
        setState({
            options: []
        })
        setOption(null)
        setActionState({ error: null, loading:false })
        close()
    }

    const handleAdd = () => {
        if(state.options.includes(option)) {
            window.alert('That option has already been added')
            return
        }
        setState({
            ...state,
            options: [
                ...state.options,
                option
            ]
        })
        setOption('')
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setActionState({ loading: true, error: null})
        try {
            await updateChecklistField(checklistId, sectionId, fieldData.id, 'checkbox', state)
            handleClose()
        }
        catch(err) {
            setActionState({ error: err.message, loading:false })
        }
    }

    const handleDelete = (o) => {
        var filtered = state.options.filter(function(value, index, arr){ 
            return value !== o;
        });
        setState({
            ...state,
            options: filtered
        }) 
    } 

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle className={classes.header}>Edit Checkboxes</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        defaultValue={state.name}
                        {...textFieldProps}
                        label="Field Name"
                        onChange={handleChange}
                        name="name"
                    />

                    <Typography>Options:</Typography>
                    <FormGroup>
                        {
                            state.options && state.options.map(o => (
                                <>
                                   <div> 
                                       <FormControlLabel
                                            key={o}
                                            control={<Checkbox />}
                                            label={o}
                                        />
                                        <IconButton className='iso-right' onClick={() => handleDelete(o)} size="large"><Delete /></IconButton>
                                    </div>
                                </>
                            ))
                        }
                    </FormGroup>
                    <br/>
                    <TextField
                        label="Option Name"
                        {...textFieldProps}
                        onChange={handleOptionChange}
                        value={option}
                    />
                    <Button disabled={!option} color="primary" onClick={handleAdd} variant="contained" fullWidth startIcon={<AddBox />}>Add Option</Button>
                    { actionState.error ? <span className="wireline-error">{actionState.error}</span> : null }
                </DialogContent>
                <DialogActions>
                    <Button disabled={ actionState.loading } color="secondary" onClick={handleClose}>Cancel</Button>
                    <Button disabled={ actionState.loading } variant="contained" type="submit" color="primary" startIcon={<Save />}>Update</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
