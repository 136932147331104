import { faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useDb } from '../../../../contexts/DatabaseContext'

export default function EditTextField({ doc, field, oldValue, closeEditor }) {
    const [text, setText] = useState(oldValue)
    const [loading, setLoading] = useState(false)
    const { editTextField } = useDb()

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        const result = await editTextField(field, doc.id, text);

        if (result !== null) {
            closeEditor()
        }
        else {
            setLoading(false)
            console.log('Error Saving Data')
        }

    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <TextField value={text} label="New Document Name" onChange={(e) => setText(e.target.value)} variant="outlined" fullWidth />
                <br/><br/>
                <Button disabled={loading} type="submit" className="iso-right" variant="contained" color="primary" startIcon={<FontAwesomeIcon icon={faSave}/>}>Save</Button>
            </form>
        </div>
    )
}
