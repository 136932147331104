import { Avatar, Card, CardContent, CardHeader, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { School, Search } from '@mui/icons-material'
import React from 'react'
import FloatingHelpComponent from '../../help/FloatingHelpComponent'
import FacilPastSessions from './FacilPastSessions'
import FacilUpcomingSessions from './FacilUpcomingSessions'

export default function FacilitatorDashboard() {
    
    return (
        <div>
            <h1>Facilitator Dashboard</h1>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FacilUpcomingSessions />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FacilPastSessions />
                </Grid>
            </Grid>
            
            <FloatingHelpComponent
                name="Using Facilitator Dashboard" 
                videoPath="training_manager"
                videoIndex={1}
            />
        </div>
    )
}
