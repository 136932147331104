import { faChalkboardTeacher } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  RadioGroup,
  TextField,
  Typography,
  Radio,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  ArrowLeft,
  Info,
  LocationCity,
  Save,
  School,
} from "@mui/icons-material";
import React, { useState } from "react";
import Select from "react-select";
import { selectFieldProps, textFieldProps } from "../../shared/constants";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import { Link, useHistory } from "react-router-dom";
import { useDb } from "../../../contexts/DatabaseContext";
import moment from "moment";
import { useAuth } from "../../../contexts/AuthContext";

const useStyles = makeStyles((theme) => ({
  chip: {
    marginBottom: 5,
    clear: "both",
  },
}));

export default function ScheduleSession() {
  const classes = useStyles();
  const {
    GetSites,
    GetTrainingProviders,
    getTrainingVenuesForSite,
    GetTrainingModules,
    getTrainingFacilitators,
    scheduleTraining,
  } = useDb();
  const { currentUser } = useAuth();
  const history = useHistory();

  const [state, setState] = useState({});
  const [compState, setCompState] = useState({ loading: false, error: null });

  const modules = GetTrainingModules();
  const sites = GetSites();
  const providers = GetTrainingProviders();
  const [venues, setVenues] = useState(null);
  const [facilitators, setFacilitators] = useState(null);
  const [bookings, setBookings] = useState([]);

  const handleSelect = async (v, e) => {
    if (e.name === "site") {
      await getVenues(v.value);
      setState({
        ...state,
        [e.name]: v.value,
        siteLabel: v.label,
      });
    } else if (e.name === "provider") {
      await getFacilitators(true, v.value.id);
    } else if (e.name === "venue") {
      setState({
        ...state,
        venue: null,
        startTime: null,
        endTime: null,
      });
      setBookings(v.value.bookings ? v.value.bookings : []);
      setState({
        ...state,
        [e.name]: v.value,
      });
    } else {
      setState({
        ...state,
        [e.name]: v.value,
      });
    }
  };

  const getVenues = async (siteId) => {
    const v = await getTrainingVenuesForSite(siteId);
    setVenues(v);
  };

  const getFacilitators = async (externalBool, providerId) => {
    const f = await getTrainingFacilitators(externalBool, providerId);
    setFacilitators(f);
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleDatePick = (d, type) => {
    try {
      if (bookings.length > 0) {
        bookings.map((b) => {
          const start = b.startTime.toDate();
          const end = b.endTime.toDate();

          if (d > start && d < end) {
            throw {
              message:
                "This time / date is already booked for this venue. Please pick a different date/time.",
            };
          } else {
            setState({
              ...state,
              [type]: d,
            });
          }
        });
      } else {
        setState({
          ...state,
          [type]: d,
        });
      }
    } catch (err) {
      window.alert(err.message);
    }
  };

  const handleRadio = async (e) => {
    if (e.target.value === "inhouse") {
      await getFacilitators(false, null);
    }
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    setCompState({ loading: true, error: null });
    try {
      await scheduleTraining(state).then((sessionId) => {
        history.push(`/training-manager/sessions/detail/${sessionId}`);
      });
    } catch (err) {
      setCompState({ loading: false, error: err.message });
    }
  };

  return (
    <div>
      <h1>Schedule Training Session</h1>
      <Link to={"/training-manager/sessions/"}>
        <Button color="primary" variant="contained" startIcon={<ArrowLeft />}>
          Training Sessions
        </Button>
      </Link>
      <br />
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader
              title="Training Provider"
              avatar={
                <Avatar>
                  <FontAwesomeIcon icon={faChalkboardTeacher} />
                </Avatar>
              }
            />
            <CardContent>
              <RadioGroup name="source" onChange={handleRadio}>
                <FormControlLabel
                  control={<Radio value="external" />}
                  label="External"
                />
                <FormControlLabel
                  control={<Radio value="inhouse" />}
                  label="In-House"
                />
              </RadioGroup>
              {state.source === "external" ? (
                <>
                  <Select
                    {...selectFieldProps}
                    placeholder="Select Training Provider"
                    className="iso-select"
                    options={providers}
                    name="provider"
                    onChange={handleSelect}
                  />
                  {facilitators ? (
                    <Select
                      {...selectFieldProps}
                      placeholder="Select External Facilitator"
                      className="iso-select"
                      name="facilitator"
                      options={facilitators}
                      onChange={handleSelect}
                    />
                  ) : null}
                </>
              ) : null}
              {state.source === "inhouse" ? (
                <Select
                  {...selectFieldProps}
                  placeholder="Select In-House Facilitator"
                  className="iso-select"
                  name="facilitator"
                  options={facilitators}
                  onChange={handleSelect}
                />
              ) : null}
            </CardContent>
          </Card>
          <br />
          <Card>
            <CardHeader
              title="Venue"
              avatar={
                <Avatar>
                  <LocationCity />
                </Avatar>
              }
            />
            <CardContent>
              <Select
                {...selectFieldProps}
                className="iso-select"
                placeholder="Select Site"
                options={sites}
                name="site"
                onChange={handleSelect}
              />
              {venues ? (
                <Select
                  {...selectFieldProps}
                  className="iso-select"
                  placeholder="Select Venue"
                  options={venues}
                  name="venue"
                  onChange={handleSelect}
                />
              ) : null}
              {state.venue ? (
                <div style={{ marginTop: 5, marginBottom: 20 }}>
                  <Divider />
                  <br />
                  Scheduled Bookings for this venue:
                  <br />
                  {bookings &&
                    bookings.map((b) =>
                      b.startTime.toDate() >= new Date() ? (
                        <Chip
                          color="secondary"
                          className={classes.chip}
                          key={b.id}
                          label={`${moment(b.startTime.toDate()).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )} - ${moment(b.endTime.toDate()).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )}`}
                        />
                      ) : null
                    )}
                  <br />
                  <br />
                  <Divider />
                </div>
              ) : null}
              {state.venue ? (
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label="Start Date & Time"
                      onChange={(d) => handleDatePick(d.toDate(), "startTime")}
                      defaultValue={dayjs(state.startTime)}
                    />
                  </LocalizationProvider>

                  <br />
                  <br />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label="End Date & Time"
                      onChange={(d) => handleDatePick(d.toDate(), "endTime")}
                      defaultValue={dayjs(state.endTime)}
                    />
                  </LocalizationProvider>
                </div>
              ) : null}

              <br />
              <br />
              {/*  TODO: Display Errors if dates conflict */}
            </CardContent>
          </Card>
          <br />
          <Card>
            <CardHeader
              title="Session Details"
              avatar={
                <Avatar>
                  <School />
                </Avatar>
              }
            />
            <CardContent>
              <Select
                {...selectFieldProps}
                placeholder="Select Module"
                className="iso-select"
                name="module"
                options={modules}
                onChange={handleSelect}
              />
              <TextField
                {...textFieldProps}
                label="Cost of Training Per Delegate"
                type="number"
                name="cost"
                onChange={handleChange}
              />
              <TextField
                {...textFieldProps}
                label="Minimum Amt. of Delegates"
                type="number"
                name="minDelegates"
                onChange={handleChange}
              />
              <TextField
                {...textFieldProps}
                label="Maximum Amt. of Delegates"
                type="number"
                name="maxDelegates"
                onChange={handleChange}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <br />
          <Card>
            <CardHeader
              title="Session Preview"
              avatar={
                <Avatar>
                  <Info />
                </Avatar>
              }
            />
            <CardContent>
              <Typography>
                Training Module:{" "}
                {state.module
                  ? `${state.module.code} ${state.module.name}`
                  : null}
              </Typography>
              <Typography>
                External Provider: {state.source === "external" ? "Yes" : "No"}
              </Typography>
              <Typography>
                Facilitator: {state.facilitator && state.facilitator.label}
              </Typography>
              <Typography>Site: {state.siteLabel}</Typography>
              <Typography>
                Venue: {state.venue ? state.venue.name : null}
              </Typography>
              <Typography>
                Cost of Training:{" "}
                {currentUser.currency ? currentUser.currency.value : ""}{" "}
                {state.cost}
              </Typography>
              <Typography>
                Max No. of Delegates: {state.maxDelegates}
              </Typography>
              <Typography>
                Min No. of Delegates: {state.minDelegates}
              </Typography>
              <Typography>
                Min Cost:{" "}
                {currentUser.currency ? currentUser.currency.value : ""}{" "}
                {state.minDelegates && state.cost
                  ? state.minDelegates * state.cost
                  : null}
              </Typography>
              <Typography>
                Max Cost:{" "}
                {currentUser.currency ? currentUser.currency.value : ""}{" "}
                {state.maxDelegates && state.cost
                  ? state.maxDelegates * state.cost
                  : null}
              </Typography>
              <Typography>
                Start Date/Time:{" "}
                {moment(state.startTime).format("MMMM Do YYYY, h:mm:ss a")}
              </Typography>
              <Typography>
                End Date/Time:{" "}
                {moment(state.endTime).format("MMMM Do YYYY, h:mm:ss a")}
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                color="primary"
                variant="contained"
                startIcon={<Save />}
                disabled={
                  !state.startTime ||
                  !state.endTime ||
                  !state.module ||
                  !state.source ||
                  !state.facilitator ||
                  !state.site ||
                  !state.venue ||
                  !state.cost ||
                  !state.minDelegates ||
                  !state.maxDelegates ||
                  !state.cost ||
                  compState.loading
                }
                onClick={handleSubmit}
              >
                Create Session
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
