import { Button, Card, CardActions, CardContent, Typography } from '@mui/material'
import { Edit, Settings } from '@mui/icons-material'
import React, { useState } from 'react'
import { useAuth } from '../../../contexts/AuthContext'
import EditControlsDialog from './EditControlsDialog'

export default function RiskSettings() {
    const { currentUser } = useAuth()
    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }

    console.log(currentUser)
    return (
        <div>
            <h1>Risk Manager Settings</h1>
            <Card>
                <CardContent>
                    <Typography>Risk Matrix: {currentUser.riskSettings.riskMatrix}</Typography>
                    <br/>
                    <Typography>Critical Control Thresholds: </Typography>
                    <Typography>Low: {currentUser.riskControls.low}</Typography>
                    <Typography>Medium: {currentUser.riskControls.med}</Typography>
                    <Typography>High: {currentUser.riskControls.high}</Typography>
                </CardContent>
                <CardActions>
                    <Button
                        color="primary"
                        variant="contained"
                        startIcon={<Settings />}
                    >
                        Reconfigure Risk Manager
                    </Button>
                    {
                        currentUser.companyAdmin
                        ?
                        <Button
                            color="primary"
                            variant="contained"
                            startIcon={<Edit />}
                            onClick={() => setOpen(true)}
                        >
                            Edit Risk Controls
                        </Button>
                        :
                        null  
                    }
                </CardActions>
            </Card>
            <EditControlsDialog data={currentUser.riskControls} open={open} handleClose={handleClose} riskSettings={currentUser.riskSettings} currentUser={currentUser} />
        </div>
    )
}
