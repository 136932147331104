import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'

export default function CloseProgram({ target, program, open, closeDialog }) {
    const { closeProgram } = useDb()

    // Action State
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleClose = () => {
        setError(null)
        setLoading(false)
        closeDialog()
    }

    const handleSubmit = async () => {
        setError(null)
        setLoading(true)
        try {
            await closeProgram(target.otpId, target.id, program.id).then(() => {
                handleClose()
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogContent>
                Are you sure you want to close this program? 
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} color="secondary" onClick={handleClose}>Cancel</Button>
                <Button disabled={loading} color="primary" onClick={handleSubmit} variant="contained">Close Program</Button>
            </DialogActions>
        </Dialog>
    )
}
