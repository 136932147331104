import { Chip, IconButton } from '@mui/material'
import { Check, Search } from '@mui/icons-material'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDb } from '../../../../contexts/DatabaseContext'
import IsoDataGrid from '../../../shared/data-grid/IsoDataGrid'

export default function BlraHazardRisks({ blraId, hazardId }) {
    const history = useHistory()
    const { GetRisksForHazard } = useDb()
    const risks = GetRisksForHazard(blraId, hazardId)


    const handleNav = (step, r) => {
        switch(step) {
            case 2:
                history.push(`/risk-manager/inherent-risk/${blraId}/risk/${r.id}/2/blra`)
                break;
            case 3: 
                history.push(`/risk-manager/baselines/${blraId}/risk/${r.id}/3`)
                break;
            case 4: 
                history.push(`/risk-manager/residual-risk/${blraId}/risk/${r.id}/4/blra`)
                break;
            case 5: 
                history.push(`/risk-manager/baselines/${blraId}/risk/${r.id}/5/${r.hazard}`)
                break;
            default: 
                history.push(`/risk-manager/baseline-risk-detail/${blraId}/${r.id}`)
                break
        }
    }

    const rows = risks && risks.map(r =>({
        id: r.id,
        cause: r.cause,
        description: r.description,
        routine: r.routine === 'routine' ? 'Routine Task' : 'Non-Routine Task',
        complete: r.currentStep === 5 ? true : false,
        hazard: r.hazard,
        currentStep: r.currentStep
    }))
    
    const columns = [
        { field: 'description', headerName: 'Description' , width: 300 },
        { field: 'cause', headerName: 'Risk Cause' , width: 200 },
        { field: 'routine', headerName: 'Routine Task', width: 200 },
        { 
            field: 'status', 
            headerName: 'Status', 
            width: 140,
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-between align-items-center">
                        {
                            params.row.complete ? <Chip icon={<Check />} label="Complete" color="primary" /> : <Chip label="Draft" />
                        }
                    </div>
                );
            }
        },
        { 
            field: 'action', 
            headerName: 'Action', 
            width: 140,
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                        <Link to={`/risk-manager/baseline-risk-detail/${blraId}/${params.row.id}`}>
                            <IconButton
                                onClick={() => handleNav(params.row.currentStep, params.row)}
                                size="large">
                                <Search />
                            </IconButton>
                        </Link>
                        {/* <IconButton onClick={() => handleNav(params.row.currentStep, params.row)}>
                            <Search />
                        </IconButton> */}
                     </div>
                );
            }
        }
    ]

    return (
        <div>
            <IsoDataGrid
                data={rows}
                columns={columns}
            />
        </div>
    )
}
