import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React from 'react'
import { useDb } from '../../../../contexts/DatabaseContext'
import Select from 'react-select'
import { selectFieldProps } from '../../../shared/constants';
import { useState } from 'react';
import { Alert } from '@mui/material';
import { Link } from 'react-router-dom';

export default function AddProcessToBaseline({ open, blraId, close, blra }) {
    const { GetRiskProcesses, addProcessesToBaseline } = useDb();
    const procs = GetRiskProcesses();
    const [state, setState] = useState({
        data: {},
        loading: false,
        error: null
    })
    
    const handleClose = () => {
        setState({
            data: {},
            loading: false,
            error: null
        })
        close()
    }

    const handleSelect = (val) => {
        setState({
            ...state,
            data: {
                process: [val]
            }
        })
    }

    const handleConfirm = async () => {
        setState({
            ...state,
            loading: true,
            error: null
        })
        try {
            await addProcessesToBaseline(blraId, state.data.process)
            handleClose()
        }   
        catch(err) {
            setState({
                ...state,
                loading: false,
                error: err.message
            })
        }
    }

    
    return (
        <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
            <DialogTitle>Add Process to {blra.blra}</DialogTitle>
            <DialogContent>
                <Select
                    {...selectFieldProps}
                    options={procs}
                    onChange={handleSelect}
                    className="iso-select"
                />
                
                <Alert severity='info'>
                    If you need to create a whole new process, you can do that <Link to="/risk-manager/processes/add/"><b style={{ textDecoration: "underline"}}>here</b></Link>, then return to this screen to add it to the BLRA
                </Alert>
                <br/>
                {
                    state.error && <Alert severity="error">Error: {state.error}</Alert>
                }
            </DialogContent>
            <DialogActions>
                <Button disabled={state.loading} color="secondary" onClick={handleClose}>Cancel</Button>
                <Button disabled={state.loading} color="primary" variant="contained" onClick={handleConfirm}>Confirm</Button>
            </DialogActions>
        </Dialog>
    )
}
