import React from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  IconButton,
} from "@mui/material";
import { AddBox, Delete, Edit, Search } from "@mui/icons-material";
import AddFacilitator from "./AddFacilitator";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useDb } from "../../../contexts/DatabaseContext";
import IsoDataGrid from "../../shared/data-grid/IsoDataGrid";
import EditFacilitator from "./EditFacilitator";
import { useAuth } from "../../../contexts/AuthContext";
import FloatingHelpComponent from "../../help/FloatingHelpComponent";

export default function Facilitators() {
  const { GetFacilitators } = useDb();
  const { currentUser } = useAuth();
  const facils = GetFacilitators();
  const [addOpen, setAddOpen] = React.useState(false);
  const [facil, setFacil] = React.useState();
  const [editOpen, setEditOpen] = React.useState(false);

  const rows =
    facils &&
    facils.map((f, i) => ({
      id: f.id,
      ref: f.ref,
      index: i,
      fullName: `${f.firstName} ${f.lastName}`,
      external: f.external ? f.provider?.name : "In House",
    }));

  const columns = [
    { field: "fullName", headerName: "Facilitator Name", width: 200 },
    { field: "external", headerName: "External", width: 200 },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <ButtonGroup>
              <Link to={`/facilitator/${params.row.id}`}>
                <IconButton
                  onClick={() => console.log(params.row)}
                  size="large"
                >
                  <Search />
                </IconButton>
              </Link>
              {currentUser.companyAdmin ? (
                <>
                  <IconButton
                    onClick={() => {
                      setFacil(facils[params.row.index]);
                      setEditOpen(true);
                    }}
                    size="large"
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    onClick={() => params.row.ref.delete()}
                    size="large"
                  >
                    <Delete />
                  </IconButton>
                </>
              ) : null}
            </ButtonGroup>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <h1>Training Facilitators</h1>
      <Card>
        <CardContent>
          <IsoDataGrid columns={columns} data={rows} />
        </CardContent>
        <CardActions>
          <Button
            onClick={() => setAddOpen(true)}
            color="primary"
            variant="contained"
            startIcon={<AddBox />}
          >
            Add Facilitator
          </Button>
        </CardActions>
      </Card>

      <AddFacilitator open={addOpen} closeDialog={() => setAddOpen(false)} />
      {facil ? (
        <EditFacilitator
          open={editOpen}
          closeDialog={() => setEditOpen(false)}
          data={facil}
        />
      ) : null}

      <FloatingHelpComponent
        name="Managing Training Facilitators"
        videoPath="training_manager"
        videoIndex={4}
      />
    </div>
  );
}
