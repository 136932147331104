import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material'

export default function ViewComments({ close, open, comment }) {

  return (
    <div>
      <Dialog open={open} onClose={close} maxWidth="sm" fullWidth>
      <DialogTitle>Comment</DialogTitle>
        <DialogContent>
          <Typography>
            { comment && comment }
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  )
}