import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AppBar, Button, Card, CardContent, CardHeader, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Toolbar, Typography } from '@mui/material'
import { Close, CloudDownload } from '@mui/icons-material'
import React, { useState } from 'react'
import IsoDocViewer from './IsoDocViewer'

const downloadHtml = async (f) => {
    console.log(f)
    const file = await fetch(f).then(res => res.blob())
    .then(blob => {
        console.log(blob)
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `IsomsHtmlExport.html`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    })
}
const downloadMsg = async (f) => {
    console.log(f)
    const file = await fetch(f).then(res => res.blob())
    .then(blob => {
        console.log(blob)
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `IsomsEmailExport.msg`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    })
}

export default function FileDialog({ open, closeDialog, file }) {
    // TODO: Download the file from firebase storage first and then pass the file to the ISO Doc Viewer
    var extension = file ? file.match(/\.([^\./\?]+)($|\?)/)[1] : null;
    const popOut = document.getElementsByClassName('ndfHFb-c4YZDc-Wrql6b')
    
    if (extension === 'jpg' || extension ===  'png' || extension === 'jpeg') {
        return (
            <Dialog open={open} maxWidth="md" fullWidth onClose={closeDialog}>
                
                <DialogContent>
                    <div style={{ float: 'right'}}>
                        <IconButton onClick={closeDialog} size="large">
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </IconButton>
                    </div>
                    <img src={file} width="100%" />
                </DialogContent>
            </Dialog>
        );
    }
    else if (extension === 'mp4' || extension === 'mkv') {
        return (
            <Dialog open={open} maxWidth="md" fullWidth onClose={closeDialog}>
                <DialogContent>
                    
                    <Card>
                        
                        <CardMedia
                            component="video"
                            image={file}
                            autoPlay
                        />
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={closeDialog}>Close</Button>
                </DialogActions>
            </Dialog>
        )
    }
    else if (extension === 'html' || extension === 'htm' ) {
        return (
            <Dialog open={open} maxWidth="md" fullWidth onClose={closeDialog}>
                <DialogContent>
                    <Card>
                        <CardContent>
                            <Typography>This file type can only be downloaded.</Typography>
                            <Button onClick={() => downloadHtml(file)} startIcon={<CloudDownload />} color="primary" variant="contained">Download File</Button>
                            {/* <a href={file} download={file}>
                                <Button startIcon={<CloudDownload />} color="primary" variant="contained">Download File</Button>
                            </a> */}
                        </CardContent>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={closeDialog}>Close</Button>
                </DialogActions>
            </Dialog>
        )
    }
    else if (extension === 'msg' ) {
        return (
            <Dialog open={open} maxWidth="md" fullWidth onClose={closeDialog}>
                <DialogContent>
                    <Card>
                        <CardContent>
                            <Typography>This file type can only be downloaded.</Typography>
                            <Button onClick={() => downloadMsg(file)} startIcon={<CloudDownload />} color="primary" variant="contained">Download File</Button>
                            {/* <a href={file} download={file}>
                                <Button startIcon={<CloudDownload />} color="primary" variant="contained">Download File</Button>
                            </a> */}
                        </CardContent>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={closeDialog}>Close</Button>
                </DialogActions>
            </Dialog>
        )
    }
    else return (
        <Dialog fullScreen open={open} onClose={closeDialog}>
            <AppBar color="primary">
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={closeDialog}
                        aria-label="close"
                        size="large">
                        <Close />
                        
                    </IconButton>
                </Toolbar>
            </AppBar>
            {/* <PdfView pdfFile={file}/> */}
            <IsoDocViewer doc={file} />
            {/* <IsoDocViewerTest doc={file} /> */}
        </Dialog>
    );
}
