import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { selectFieldProps, textFieldProps } from "../../shared/constants";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { Save } from "@mui/icons-material";
import { useDb } from "../../../contexts/DatabaseContext";

export default function EditProgram({ target, program, open, closeDialog }) {
  const { GetUsersLevelFilter, updateProgram, GetOtpMetrics } = useDb();
  const closers = GetUsersLevelFilter("otpManager", 1);
  const responsibles = GetUsersLevelFilter("otpManager", 2);
  const metrics = GetOtpMetrics();
  const [state, setState] = useState({
    closer: program.closer,
    description: program.description,
    measurement: program.measurement,
    open: program.open,
    progress: "...",
    responsible: program.responsible,
    targetDate: program.targetDate.toDate(),
    resources: program.resources ? program.resources : null,
    measurementInterval: program.measurementInterval
      ? program.measurementInterval
      : null,
    metric: program.metric ? program.metric : null,
    targetNum: program.targetNum ? program.targetNum : null,
    intervalDays: program.intervalDays,
  });

  useEffect(() => {
    setState({
      closer: program.closer,
      description: program.description,
      measurement: program.measurement,
      open: program.open,
      progress: "...",
      responsible: program.responsible,
      targetDate: program.targetDate.toDate(),
      resources: program.resources ? program.resources : null,
      measurementInterval: program.measurementInterval
        ? program.measurementInterval
        : null,
      metric: program.metric ? program.metric : null,
      targetNum: program.targetNum ? program.targetNum : null,
    });
  }, [open]);

  // Action State
  const [updated, setUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleClose = () => {
    setUpdated(false);
    setLoading(false);
    setError(null);
    setState({});
    closeDialog();
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
    setUpdated(true);
  };

  const handleSelect = (v, target) => {
    setState({
      ...state,
      [target]: v.value,
    });
    setUpdated(true);
  };

  const handleSubmit = async () => {
    setError(null);
    setLoading(true);
    try {
      await updateProgram(target.otpId, target.id, program.id, state).then(
        () => {
          handleClose();
        }
      );
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const handleOpen = () => {
    setState({
      closer: program.closer,
      description: program.description,
      measurement: program.measurement,
      open: program.open,
      progress: "...",
      responsible: program.responsible,
      targetDate: program.targetDate.toDate(),
      resources: program.resources ? program.resources : null,
      measurementInterval: program.measurementInterval
        ? program.measurementInterval
        : null,
      metric: program.metric ? program.metric : null,
      targetNum: program.targetNum ? program.targetNum : null,
      intervalDays: program.intervalDays,
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      TransitionProps={{
        onEnter: handleOpen,
      }}
    >
      <DialogContent>
        <TextField
          {...textFieldProps}
          value={state.description && state.description}
          name="description"
          onChange={handleChange}
          label="Description"
        />
        <TextField
          {...textFieldProps}
          required
          // value={state.resources}
          label="Resources Required"
          onChange={handleChange}
          name="resources"
          value={state.resources && state.resources}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Target Date"
            onChange={(d) => {
              setState({ ...state, targetDate: d.toDate() });
              setUpdated(true);
            }}
            defaultValue={dayjs(state.targetDate)}
          />
        </LocalizationProvider>
        <br />
        <br />
        Responsible HOD:{" "}
        {state.responsible &&
          `${state.responsible.firstName} ${state.responsible.lastName}`}
        <Select
          {...selectFieldProps}
          placeholder="Change Responsible HOD ..."
          onChange={(v) => handleSelect(v, "responsible")}
          options={responsibles}
        />
        <br />
        Program Executor:{" "}
        {state.closer && `${state.closer.firstName} ${state.closer.lastName}`}
        <Select
          {...selectFieldProps}
          placeholder="Change Program Executor ..."
          onChange={(v) => handleSelect(v, "closer")}
          options={closers}
        />
        <br />
        <TextField
          {...textFieldProps}
          value={state.measurement && state.measurement}
          name="measurement"
          onChange={handleChange}
          label="Measurement"
        />
        <TextField
          {...textFieldProps}
          required
          type="number"
          label="Measurement Interval (In Days)"
          onChange={handleChange}
          value={state.intervalDays && state.intervalDays}
          name="intervalDays"
        />
        Program Metric:{" "}
        {state.metric ? state.metric.metricName : "None Selected"}
        <Select
          {...selectFieldProps}
          options={metrics}
          required
          onChange={(v) => handleSelect(v, "metric")}
          placeholder="Change Measurement Metric..."
          className="iso-select"
        />
        {state.metric ? (
          <TextField
            {...textFieldProps}
            required
            type="number"
            name="targetNum"
            label={`Target ${state.metric.metricName}s for Program`}
            onChange={handleChange}
            value={state.targetNum}
          />
        ) : null}
      </DialogContent>
      <DialogActions>
        {error ? <span className="iso-error">{error}</span> : null}
        <Button disabled={loading} color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          disabled={
            loading ||
            !updated ||
            !state.resources ||
            !state.intervalDays ||
            !state.metric ||
            !state.targetNum
          }
          color="primary"
          variant="contained"
          startIcon={<Save />}
          onClick={handleSubmit}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}
