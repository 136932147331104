import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import React from 'react';

export default function VideoDialog({ open, close, videoId, title }) {
    
    return (
        <Dialog open={open} onClose={close} maxWidth="md" fullWidth>
            <DialogActions>
                {title}
                <IconButton onClick={close} size="large">
                    <Close />
                </IconButton>
            </DialogActions>
            <div className="video-responsive">
                    <iframe
                        width="853"
                        height="480"
                        src={`https://www.youtube.com/embed/${videoId}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                    />
                </div>
        </Dialog>
    );
}

