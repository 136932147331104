import { LinearProgress, Button, Grid, Card, CardContent, Typography, CardHeader, Avatar, CardActions, Dialog, DialogTitle, DialogActions, DialogContent, IconButton, TextField } from '@mui/material'
import { AddBox, ArrowLeft, Edit, Remove, RemoveCircle, Save, School, SchoolOutlined, Search } from '@mui/icons-material'
import React, { useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import Select from 'react-select'
import { selectFieldProps, textFieldProps } from '../../shared/constants'
import { useAuth } from '../../../contexts/AuthContext'

export default function OccupationDetail() {
    const { id } = useParams()
    const { getOccupation, GetTrainingModules, requireModule, updateOccupationName } = useDb()
    const modules = GetTrainingModules()
    const { currentUser } = useAuth()
    console.log(currentUser)

    // Action State
    const [loaded, setLoaded] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const [job, setJob] = useState(null)
    const [requiredModules, setRequiredModules] = useState([])
    const [requiredTitles, setRequiredTitles] = useState([])
    const [editTitle, setEditTitle] = useState({
        bool: false,
        data: null
    })

    // Add Modules Dialog
    const [addOpen, setAddOpen] = useState(false)
    const [module, setModule] = useState(null)
    
    const addRequiredModule = async () => {
        setLoading(true)
        setError(null)

        if(requiredModules.includes(module.id)) {
            setError('You have already added this module')
            setLoading(false)
            return
        }
        const reqModules = [
            ...requiredModules,
            module.id
        ]
        const reqModuleTitles = [
            ...requiredTitles,
            module.name
        ]
        setRequiredModules(reqModules)
        setRequiredTitles(reqModuleTitles)
        try {
            await requireModule(id, reqModules, reqModuleTitles).then(() => {
                closeDialog()
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    const handleSave = async () => {
        setLoading(true)
        try {
            await updateOccupationName(id, editTitle.data).then(() => {
                setEditTitle({
                    bool: false,
                    data: null
                })
                setLoading(false)
            })
        } catch (err) {
            setError(err.message)
            setEditTitle({
                bool: false,
                data: null
            })
            setLoading(false)
        }
        setLoaded(false)
    }

    const handleDelete = async (reqModules, reqModuleTitles) => {
        setLoading(true)
        try {
            await requireModule(id, reqModules, reqModuleTitles).then(() => {
                setLoading(false)
            })
        } catch (err) {
            setError(err.message)
            setLoading(false)
        }
    }

    const closeDialog = () => {
        setModule(null)
        setAddOpen(false)
        setError(null)
        setLoading(false)
    }

    const firstLoad = async () => {
        const j = await getOccupation(id)
        setJob(j)
        setEditTitle({
            ...editTitle,
            data: j.title
        })
        setRequiredModules(j.requiredModules ? j.requiredModules : [])
        setRequiredTitles(j.requiredTitles ? j.requiredTitles : [])
        setLoaded(true)
    }

    if(!loaded) {
        firstLoad()
        return <LinearProgress />
    }
    else return (
        <div>
            <h1>{job.title}</h1>
            <Link to={'/training-manager/occupations'}>
                <Button variant="contained" startIcon={<ArrowLeft />} color="primary">Occupations</Button>
            </Link>
            <br/><br/>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Card>
                        <CardContent>
                        {
                            currentUser.companyAdmin 
                            ?
                            <>
                                {
                                    editTitle.bool
                                    ?
                                    <TextField 
                                        {...textFieldProps}
                                        defaultValue={editTitle.data}
                                        onChange={(e) => setEditTitle({
                                            ...editTitle,
                                            data: e.target.value
                                        })}
                                    />
                                    :
                                    <span style={{fontSize: 22}}>{editTitle.data}</span> 
                                }
                                &nbsp;
                                {
                                    editTitle.bool
                                    ?
                                    <Button
                                        startIcon={<Save />}
                                        onClick={handleSave}
                                        variant="contained" 
                                        color="primary"
                                    >
                                        Save
                                    </Button>
                                    :
                                    <Button
                                        onClick={() => setEditTitle({
                                            ...editTitle,
                                            bool: true
                                        })}
                                    >
                                        <Edit />
                                    </Button>
                                }
                            </>
                            :
                            <Typography style={{fontSize: 22}}>{job.title}</Typography>
                        }
                            <br/>
                            {
                                job.code 
                                ?
                                    <Typography>Code: {job.code}</Typography>
                                :
                                null
                            }
                            <br/>
                            <Typography>Required Modules: {requiredModules ? requiredModules.length : '0'}</Typography>
                        </CardContent>
                        
                    </Card>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Card>
                        <CardHeader
                            title="Required Training"
                        />
                        <CardContent>
                            {
                                requiredTitles && requiredTitles.map(t => (
                                    <Card key={t} style={{ marginBottom: 5}}>
                                        <CardHeader
                                            title={t}
                                            avatar={<Avatar><SchoolOutlined /></Avatar>}
                                            action={
                                                <IconButton
                                                    onClick={() => {
                                                        // console.log(requiredTitles.indexOf(t))
                                                        const index = requiredTitles.indexOf(t)
                                                        requiredTitles.splice(index, 1)
                                                        requiredModules.splice(index, 1)
                                                        handleDelete(requiredModules, requiredTitles)
                                                    }}
                                                    size="large">
                                                    <RemoveCircle />
                                                </IconButton>
                                            }
                                        />
                                    </Card>
                                ))
                            }
                        </CardContent>
                        <CardActions>
                            <Button onClick={() => setAddOpen(true)} startIcon={<AddBox />} variant="contained" color="primary">Add Required Module</Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
            

            <Dialog onClose={closeDialog} maxWidth="sm" fullWidth open={addOpen}>
                <DialogTitle>Require Training Module</DialogTitle>
                <DialogContent>
                    <Select
                        {...selectFieldProps}
                        className="iso-select"
                        options={modules}
                        onChange={v => setModule(v.value)}
                    />
                </DialogContent>
                <DialogActions>
                    { error ? <span className="iso-error">{error}</span> : null }
                    <Button disabled={ loading } onClick={addRequiredModule} color="primary" variant="contained" startIcon={<AddBox />}>Add Module</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
