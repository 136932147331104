import { Button, ButtonGroup, IconButton } from '@mui/material'
import { DeleteForever, Search } from '@mui/icons-material'
import React from 'react'
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid'

export default function RosterDataGrid({data, handleDelete, handleOpen}) {

  const rows = data.map(d => ({
    id: d.id,
    name: `${d.firstName} ${d.lastName} - ${d.email}`,
    roster: d
  }))

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 400
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => {
        return (
          <ButtonGroup>
            <IconButton onClick={() => handleOpen(params.row.roster)} size="large"><Search /></IconButton>
            <IconButton onClick={() => handleDelete(params.row.roster)} size="large"><DeleteForever /></IconButton>
          </ButtonGroup>
        );
      }
    }
  ]

  return (
    <div>
      <IsoDataGrid 
        data={rows}
        columns={columns}
      />
    </div>
  )
}
