import { Button, ButtonGroup, Card, CardActions, CardContent, IconButton } from '@mui/material'
import { Delete, Edit } from '@mui/icons-material'
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid'
import AddAgent from './AddAgent'
import EditAgent from './EditAgent'

export default function MgmtAgents() {
    const { GetSalesAgents, deleteSalesAgent } = useDb()
    const agents = GetSalesAgents()
    const [addOpen, setAddOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [selectedAgent, setSelectedAgent] = useState(null)

    const rows = agents && agents.map(a => ({
        id: a.id,
        firstName: a.firstName,
        lastName: a.lastName,
        email: a.email,
    }))

    const handleEdit = (agent) => {
        setSelectedAgent(agent)
        setEditOpen(true)
    }

    const handleDelete = async (agent) => {
        // Display an alert to confirm deletion 
        // If confirmed, delete the agent
        // If not confirmed, do nothing

        const deleteConfirm = window.confirm(`Delete ${agent.firstName} ${agent.lastName}?`)
        if (deleteConfirm) {
            // Delete the agent
            await deleteSalesAgent(agent.id)
        }   
    }

    const columns = [
        { field: "firstName", headerName: "First Name", width: 200 },
        { field: "lastName", headerName: "Last Name", width: 200 },
        { field: "email", headerName: "Email", width: 200 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => {
              return (
                  <ButtonGroup>
                    <IconButton onClick={() => handleEdit(params.row)} size="large"><Edit /></IconButton>
                    <IconButton onClick={() => handleDelete(params.row)} size="large"><Delete /></IconButton>
                  </ButtonGroup>
              );
            }
        }
    ]

    return (
        <div>
            <Card>
                <CardContent>
                    {rows && <IsoDataGrid data={rows} columns={columns} />}
                </CardContent>
                <CardActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setAddOpen(true)}
                    >
                        Add Agent
                    </Button>
                </CardActions>
            </Card>

            <AddAgent
                open={addOpen}
                close={() => setAddOpen(false)}
            />

            {
                selectedAgent &&
                <EditAgent
                    agent={selectedAgent}
                    open={editOpen}
                    close={() => setEditOpen(false)}
                />
            }
        </div>
    )
}
