import React from "react";
import IsoRenderForm from "../form-builder/form-render/IsoRenderForm";
import { useDb } from "../../../contexts/DatabaseContext";

export default function Phase1Checklist({ moc, props }) {
  const { handleAdvance, handleUpdate } = props;
  const { getCompanyDoc } = useDb();
  const docString = "mocChecklist";
  const collectionPath = `moc/${moc.id}/responses`;
  const nextRoute = `/moc/phase-2/${moc.id}`;

  const getResponses = async () => {
    const responseDoc = await getCompanyDoc(collectionPath, docString);
    if (responseDoc && responseDoc.responses) {
      return responseDoc.responses;
    }
  };

  function checkChangeRequired(arr) {
    return arr.some(function (obj) {
      return obj.value === "yes";
    });
  }

  const receiveAdvance = async () => {
    const r = await getResponses();
    // Check if any value here is yes which means MOC is required
    const mocRequired = checkChangeRequired(r);
    await handleUpdate({
      changeRequired: mocRequired,
      status: mocRequired ? "Requires Change" : "No Change Required",
    });
    handleAdvance();
  };

  return (
    <div>
      <IsoRenderForm
        formId="mocChecklist"
        docString={docString}
        collectionPath={collectionPath}
        nextRoute={nextRoute}
        handleAdvance={receiveAdvance}
      />
    </div>
  );
}
