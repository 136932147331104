import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import { Save } from '@mui/icons-material'
import React, { useState } from 'react'
import { useDb } from '../../contexts/DatabaseContext'
import FileUploader from '../shared/FileUploader'

export default function ChangeProfilePicture({ pOpen, pCloseDialog, profile }) {
    const [photo, setPhoto] = useState(profile.profilePhoto)
    const { changeProfilePhoto } = useDb()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const receiveFile = (file) => {

    }

    const receiveFileUrl = (url) => {
        setPhoto(url)
    }

    const receiveFilename = (fName) => {

    }

    const handleSubmit = async () => {
        setError(null)
        setLoading(true)
        try {
            await changeProfilePhoto(profile.id, photo).then(() => {
                pCloseDialog()
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    return (
        <Dialog open={pOpen} onClose={pCloseDialog} maxWidth={'sm'} fullWidth>
            <DialogContent>
                <Typography>Current Photo:</Typography>
                <img src={photo} height={200} />
                <FileUploader 
                    getFileObj={receiveFile} 
                    getFilename={receiveFilename}
                    getUrl={receiveFileUrl}
                    userId={profile.id}
                />
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} color={'secondary'} onClick={pCloseDialog}>Cancel</Button>
                {
                    photo !== profile.profilePhoto
                    ?
                    <Button disabled={loading} color={'primary'} onClick={handleSubmit} startIcon={<Save />}>Save Changes</Button>
                    :
                    null
                }
            </DialogActions>
        </Dialog>
    )
}
