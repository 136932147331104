import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useDb } from '../../../../contexts/DatabaseContext'
import { textFieldProps } from '../../../shared/constants'

export default function CoChangeUserPw({ open, close, userId }) {

    const { userPasswordChangeRequest } = useDb()


    const [state, setState] = useState({
        loading: false,
        error: null,
        data: {}
    })

    const handleClose = () => {
        setState({
            loading: false,
            error: null,
            data: {}
        })
        close()
    }

    const handleChange = (e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setState({
            ...state,
            loading: true,
            error: null
        })
        try {
            await userPasswordChangeRequest(userId, state.data.password)
            handleClose()
        }
        catch(err) {
            setState({
                ...state,
                loading: false,
                error: err.message
            })
        }
    }
    
    return (
        <Dialog onClose={handleClose} maxWidth="sm" fullWidth open={open}>
            <DialogTitle>Change User PW</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField 
                        label="New PW"
                        {...textFieldProps}
                        onChange={handleChange}
                        name="password"
                        required
                    />
                    <TextField 
                        label="Confirm PW"
                        {...textFieldProps}
                        onChange={handleChange}
                        name="confirmPassword"
                        required
                    />
                </DialogContent>
                <DialogActions>
                    {
                        state.error && <Typography color="secondary">{state.error}</Typography>
                    }
                    <Button
                        color="secondary"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        type="submit"
                        variant="contained"
                        disabled={state.loading || state.data.password !== state.data.confirmPassword || state.data.password && state.data.password.length < 6 }
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
