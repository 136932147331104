import { Button, CardActions, CircularProgress, Typography } from '@mui/material'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router'
import { useDb } from '../../../../contexts/DatabaseContext'
import AddHazard from './AddHazard'
import BlraProcesses from './BlraProcesses'

export default function AddBaselineRisk3() {
    const { blraId } = useParams()
    const history = useHistory()
    const { getBaseline, advanceBaseline } = useDb()

    const [blra, setBlra] = useState(null)
    const [state, setState] = useState({
        process: '',
        step: '',
        addOpen: false
    })

    const firstLoad = async () => {
        const b = await getBaseline(blraId)
        setBlra(b)
    }

    const handleAddOpen = (process, step) => {
        setState({
            process,
            step,
            addOpen: true
        })
    }

    if(!blra) {
        firstLoad()
        return <CircularProgress />
    }
    else return (
        <div>
            <Typography variant="h5">{blra.blra}</Typography>
            <Typography variant="h6">Activities & Hazard Identification</Typography>
            <div className="back-link">
                <Button
                    startIcon={<ArrowBack />}
                    color="primary"
                    variant="contained"
                    onClick={() => history.goBack()}
                >
                    Back
                </Button>
            </div>
            <BlraProcesses blraId={blraId} open={handleAddOpen} blra={blra} />
            
            {/* <CardActions className="iso-right">
                <Button
                    color="primary"
                    variant="contained"
                    endIcon={<ArrowForward />}
                >
                    Next: Risk Identification
                </Button>
            </CardActions> */}

            <AddHazard
                open={state.addOpen}
                close={() => setState({ ...state, addOpen: false })}
                process={state.process}
                step={state.step}
                blraId={blraId}
            />
        </div>
    )
}
