import { MenuItem } from '@mui/material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../contexts/AuthContext'
import { useDb } from '../../../contexts/DatabaseContext'
import DeleteConfirm from '../../shared/DeleteConfirm'
import IntPartiesTemplate from '../../shared/interested-parties/IntPartiesTemplate'
import ActionAttachments from '../views/ActionAttachments'
import ActionDetail from '../views/ActionDetail'
import CloseOutAction from '../views/CloseOutAction'
import ObsoleteAction from './ObsoleteAction'

export default function ActionDropDown({ action, shareAction, editAction }) {
    const [detailOpen, setDetailOpen] = useState(false)
    const [attachmentOpen, setAttachmentOpen] = useState(false)
    const [closeoutOpen, setCloseoutOpen] = useState(false)
    const [intPartiesOpen, setIntPartiesOpen] = useState(false)
    const { currentUser } = useAuth()
    const { reOpenAction } = useDb()
    const [deleteOpen, setDeleteOpen] = useState(false)
    const { getSingleActionPdf } = useDb()
    const [loading, setLoading] = useState(false)

    const reOpen = async () => {
        try {
            reOpenAction(action.id) 
        }
        catch(err) {
            console.log(err.message)
        }
    }

    const getPdf = async () => {
        setLoading(true)
        try {
            await getSingleActionPdf(action).then(() => {
                setLoading(false)
            })
        }
        catch(err) {
            console.log(err.message)
        }
    }
     
    return (
        <>
            <Link to={`/action/${action.id}`}>
                <MenuItem>View Details</MenuItem>
            </Link>
            <Link to={`/edit-action/${action.id}`}>
                <MenuItem>Edit</MenuItem>
            </Link>
            <Link to={`/action-int-parties/${action.id}/${action.actionNumber}`}>
                <MenuItem>Interested Parties</MenuItem>
            </Link>
            <Link to={`/action-attachments/${action.id}`}>
                <MenuItem>Attachments</MenuItem>
            </Link>
            <MenuItem disabled={loading} onClick={getPdf}>Produce PDF</MenuItem>
            {
                action.open 
                ?
                currentUser.companyAdmin || action.hod.id === currentUser.uid ? <Link to={`/action-manager/close-out-action/${action.id}`}><MenuItem>Close Out Action</MenuItem></Link> : null 
                :
                currentUser.companyAdmin || action.hod.id === currentUser.uid ? <MenuItem onClick={reOpen}>Re-Open Action</MenuItem> : null 
            }
            {/* {
                currentUser.companyAdmin || currentUser.accessRights.actionManager <= 1 ?
                <MenuItem onClick={() => setDeleteOpen(true)}>Make Obsolete</MenuItem>
                :
                null
            } */}

            {/* Views */}
            <CloseOutAction open={closeoutOpen} closeDialog={() => setCloseoutOpen(false)} action={action}/>
            <IntPartiesTemplate open={intPartiesOpen} closeDialog={() => setIntPartiesOpen(false)} collectionType={'actions'} docId={action.id} doc={action} />

            {/* <ObsoleteAction 
                open={deleteOpen}
                closeDialog={() => setDeleteOpen(false)}
                actionId={action.id}
            /> */}
        </>
    )
}
