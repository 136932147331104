import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { Send } from '@mui/icons-material'
import React, { useState } from 'react'
import Select from 'react-select'
import { useDb } from '../../../contexts/DatabaseContext'
import { selectFieldProps } from '../../shared/constants'

export default function InviteAttendees({ open, close, sessionId }) {
    const { GetEmployees, addAttendeesToSession } = useDb()
    const plebs = GetEmployees()
    const [state, setState] = useState({})
    const [compState, setCompState] = useState({ loading: false, error: null })

    const handleClose = () => {
        setState({})
        setCompState({ loading: false, error: null })
        close()
    }

    const handleSelect = (v, e) => {
        setState({
            ...state,
            [e.name]: v
        })
    }

    const handleSubmit = async () => {
        setCompState({ loading: true, error: null })
        try {
            await addAttendeesToSession(sessionId, state)
            handleClose()
        }
        catch(err) {
            setCompState({ loading: false, error: err.message })
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>Invite Attendees</DialogTitle>
            <DialogContent>
                <Select
                    className="iso-select"
                    {...selectFieldProps}
                    placeholder="Select Employees ..."
                    options={plebs}
                    isMulti
                    name="attendees"
                    onChange={handleSelect}
                />
                {
                    compState.error ? <span className="iso-error">{compState.error}</span> : null 
                }
            </DialogContent>
            <DialogActions>
                <Button disabled={ compState.loading } color="secondary" onClick={handleClose}>Cancel</Button>
                <Button disabled={ compState.loading } variant="contained" color="primary" startIcon={<Send />} onClick={handleSubmit}>Send Invites</Button>
            </DialogActions>
        </Dialog>
    )
}
