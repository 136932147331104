import { Avatar, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Grid, Slider, Typography } from '@mui/material'
import { ArrowForward, Info } from '@mui/icons-material'
import React, { useState } from 'react'
import { useAuth } from '../../../../contexts/AuthContext'
import { useDb } from '../../../../contexts/DatabaseContext'
import RiskMatrix from '../../risk-matrix/RiskMatrix'
import { useParams, useHistory } from 'react-router-dom'

export default function AddRisk4FinalRisk() {
    const { currentUser } = useAuth()
    const { assId, riskId, type } = useParams()
    const { getRisk, addFinalRisk, updateIbraRisk, getIBRisk } = useDb()
    const history = useHistory()
    const riskNum = parseInt(currentUser.riskSettings.riskMatrix.split('x')[0])

    const [risk, setRisk] = useState(null)

    const [compState, setCompState] = useState({
        loading: false,
        error: null
    })
    const [state, setState] = useState({
    })

    const [final, setFinal] = useState({
        like: 0,
        cons: 0
    })

    const handleChange = (e, val, name) => {
        setState({
            ...state,
            [name]: val
        })
    }

    const handleSubmit = async () => {
        setCompState({
            ...compState,
            loading: true,
            error: null
        })
        try {
            type === 'blra'
            ?
            await addFinalRisk(assId, riskId, state).then(() => {
                history.push(`/risk-manager/baselines/${assId}/risk/${riskId}/5/${risk.hazard}`)
            })
            :
            await updateIbraRisk(assId, riskId, { ...state, currentStep: 5 }).then(() => {
                history.push(`/risk-manager/issue-based/${assId}/residual-risk/${riskId}`)
            })
        }
        catch(err) {
            setCompState({
                ...compState,
                loading: false,
                error: err.message
            })
        }
    }

    const firstLoad = async () => {
        const r = type === 'blra' ? await getRisk(assId, riskId) : await getIBRisk(assId, riskId)
        
        const initialValue = r.initialCons * r.initialLike
        const finalValue = initialValue - r.totalControlValue < 100 ? (initialValue * (r.totalControlValue / 100)) : 1

        const finalLike = r.totalControlValue < 100 ? Math.ceil((r.initialLike * (r.totalControlValue / 100))) : 1
        const finalCons = r.totalControlValue < 100 ? Math.ceil((r.initialCons * (r.totalControlValue / 100))) : 1

        setState({
            ...state,
            finalLike,
            finalCons,
            initialLike: r.initialLike,
            initialCons: r.initialCons
        })
        setRisk({
            ...r,
            initialValue,
            finalValue: finalValue > 0 ? finalValue : 1,
            finalLike,
            finalCons
        })
    }

    if(!risk) {
        firstLoad()
        return <CircularProgress />
    }
    else return (
        <div>
            <h1>Residual Risk</h1>
            <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                    <Card>
                        <CardContent>
                            <CardHeader
                                title="Initial Risk"
                                avatar={
                                    <Avatar>
                                        <Info />
                                    </Avatar>
                                }
                            />
                            <Typography>
                                Likelihood
                            </Typography>
                            <br/>
                            <Slider
                                defaultValue={1}
                                step={1}
                                marks
                                min={1}
                                max={riskNum}
                                value={state.initialLike}
                                valueLabelDisplay="on"
                                onChangeCommitted={(e,v) => handleChange(e,v,'initialLike')}
                            />
                            <Typography>
                                Consequences
                            </Typography>
                            <br/>
                            <Slider
                                defaultValue={1}
                                step={1}
                                marks
                                min={1}
                                max={riskNum}
                                value={state.initialCons}
                                valueLabelDisplay="on"
                                onChangeCommitted={(e,v) => handleChange(e,v,'initialCons')}
                            />
                        </CardContent>
                    </Card>
                    <br/>
                    <Card>
                        {
                            risk.totalControlValue === 100
                            ?
                            <CardContent>
                                Risk Eliminated
                            </CardContent>
                            :
                            <CardContent>
                                <CardHeader
                                    title="Residual Risk"
                                    avatar={
                                        <Avatar>
                                            <Info />
                                        </Avatar>
                                    }
                                />
                                <Typography>
                                    Likelihood
                                </Typography>
                                <br/>
                                <Slider
                                    defaultValue={1}
                                    step={1}
                                    marks
                                    min={1}
                                    max={riskNum}
                                    value={state.finalLike}
                                    valueLabelDisplay="on"
                                    onChangeCommitted={(e,v) => handleChange(e,v,'finalLike')}
                                />
                                <Typography>
                                    Consequences
                                </Typography>
                                <br/>
                                <Slider
                                    defaultValue={1}
                                    step={1}
                                    marks
                                    min={1}
                                    max={riskNum}
                                    value={state.finalCons}
                                    valueLabelDisplay="on"
                                    onChangeCommitted={(e,v) => handleChange(e,v,'finalCons')}
                                />
                            </CardContent>
                        }
                        
                    </Card>
                </Grid>
                <Grid item xs={12} md={9}>
                    {
                        risk.finalValue > 0
                        ?
                        <div style={{ float: 'right' }}>
                            <RiskMatrix 
                                type={currentUser.riskSettings.riskMatrix}
                                initialLike={state.initialLike}
                                initialCons={state.initialCons}
                                finalLike={state.finalLike}
                                finalCons={state.finalCons}
                            />
                        </div>
                        :
                        <Typography>
                            Risk Eliminated
                        </Typography>
                    }
                    
                </Grid>
            </Grid>
            <br/>
            <CardActions className="iso-right">
                {
                    state.error && <Typography color="secondary">{state.error}</Typography>
                }
                <Button
                    variant="contained"
                    color="primary"
                    endIcon={<ArrowForward />}
                    disabled={
                        compState.loading
                    }
                    onClick={handleSubmit}
                >
                    Next: Additional Controls
                </Button>
            </CardActions>
        </div>
    )
}
