import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material'
import { Save } from '@mui/icons-material'
import React, { useState } from 'react'
import { useDb } from '../../../../../contexts/DatabaseContext'
import { textFieldProps } from '../../../../shared/constants'

export default function CoAddDocType({ open, closeDialog, coId }) {
    const { mgmtCreateDocTypes } = useDb()

    const [state, setState] = useState({})

    // Action State
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleClose = () => {
        setError(null)
        setLoading(false)
        setState({})
        closeDialog()
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setError(null)
        setLoading(true)
        try {
            await mgmtCreateDocTypes(coId, state).then(() => {
                handleClose()
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        {...textFieldProps}
                        required
                        label="Category (ie L1, L2)"
                        onChange={e => setState({ ...state, category: e.target.value })}
                    />
                    <TextField
                        {...textFieldProps}
                        required
                        label="Type Name"
                        onChange={e => setState({ ...state, type: e.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    { error ? <span className="iso-error">{error}</span> : null }
                    <Button disabled={ loading } color="secondary" onClick={handleClose}>Cancel</Button>
                    <Button disabled={ loading } color="primary" type="submit" variant="contained" startIcon={<Save />}>Confirm</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
