import {
    Avatar,
    Button,
    ButtonGroup,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    IconButton,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ArrowLeft, Cancel, Check, CheckCircle, Info, People, PictureAsPdf } from '@mui/icons-material'
import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import moment from 'moment'
import ScoreAttendee from './ScoreAttendee'
import DidNotAttend from './DidNotAttend'
import { green } from '@mui/material/colors'
import DidAttend from './DidAttend'
import { useAuth } from '../../../contexts/AuthContext'

const useStyles = makeStyles((theme) => ({
    successBtn: {
        color: green[500]
    }
}))

export default function FacilitatorSession() {
    const classes = useStyles()
    const { getTrainingSession, GetAttendees, createAttendanceRegister } = useDb()
    const { id } = useParams()
    const attendees = GetAttendees(id)
    const history = useHistory()
    const [compState, setCompState] = useState({ loading: false, pageLoaded: false, error: null })
    const [session, setSession] = useState(null)
    const [assessOpen, setAssessOpen] = useState(false)
    const [attendedOpen, setAttendedOpen] = useState(false)
    const [absentOpen, setAbsentOpen] = useState(false)
    const [employee, setEmployee] = useState(null)
    const { currentUser } = useAuth()

    const handleAssessClose = () => {
        setAssessOpen(false)
        setEmployee(null)
    }

    const handleAssessOpen = (e) => {
        setEmployee(e)
        setAssessOpen(true)
    }

    const handleAbsentClose = () => {
        setAbsentOpen(false)
        setEmployee(null)
    }

    const handleAttendedClose = () => {
        setAttendedOpen(false)
        setEmployee(null)
    }

    const handleAttendedOpen = (e) => {
        setEmployee(e)
        setAttendedOpen(true)
    }

    const handleAbsentOpen = (e) => {
        setEmployee(e)
        setAbsentOpen(true)
    }

    const handlePdf = async () => {
        setCompState({
            ...compState,
            loading: true,
            error: null
        })
        await createAttendanceRegister(session)
        setCompState({
            ...compState,
            loading: false,
            error: null
        })
    }   
    
    const firstLoad = async () => {
        const s = await getTrainingSession(id)
        setSession(s)
        setCompState({ ...compState, pageLoaded: true })
    }

    if(!compState.pageLoaded) {
        firstLoad()
        return <LinearProgress />
    }
    else return (
        <div>
            <h1>Training Session: {session.module?.name}</h1>
            <Card elevation={0}>
                <CardActions>
                    <Button onClick={() => history.goBack()} color="primary" variant="contained" startIcon={<ArrowLeft />}>Sessions</Button>
                    <Button disabled={ compState.loading } onClick={handlePdf} color="primary" variant="contained" startIcon={<PictureAsPdf />}>Attendance Register</Button>
                </CardActions>
            </Card>
            <br/>
            <Grid container spacing={3}>
                <Grid item xs={12} md={7}>
                    <Card>
                        <CardHeader
                            title="Attendees"
                            avatar={
                                <Avatar>
                                    <People />
                                </Avatar>
                            }
                        />
                        <CardContent> 
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>First Name</TableCell>
                                            <TableCell>Last Name</TableCell>
                                            <TableCell>Confirmation</TableCell>
                                            <TableCell align="right">Attended</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            attendees && attendees.map(a => (
                                                <TableRow key={a.id}>
                                                    <TableCell>{a.firstName}</TableCell>
                                                    <TableCell>{a.lastName}</TableCell>
                                                    <TableCell>
                                                        
                                                        {
                                                            a.responded
                                                            ?
                                                            a.attending ? 'Attending' : 'Declined'
                                                            :
                                                            'Pending'
                                                        }
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {
                                                            a.assessed
                                                            ?
                                                            'Assessed'
                                                            :
                                                            a.attended
                                                            ?
                                                            <Button onClick={() => handleAssessOpen(a)} variant='contained' color='primary'>Assess</Button>
                                                            :
                                                            <ButtonGroup 
                                                                size="small" 
                                                                // disabled={ session.startTime.toDate() > new Date()}
                                                            >
                                                                <IconButton color="secondary" onClick={() => handleAbsentOpen(a)} size="large">
                                                                    <Cancel />
                                                                </IconButton>
                                                                <IconButton color="primary" onClick={() => handleAttendedOpen(a)} size="large">
                                                                    <CheckCircle />
                                                                </IconButton>
                                                            </ButtonGroup>
                                                        }
                                                        
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                        <CardActions>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Card>
                        <CardHeader
                            title="Session Info"
                            avatar={<Avatar>
                                <Info />
                            </Avatar>}
                        />
                        <CardContent>
                            <Typography>Training Module: {session.module ? `${session.module.code} ${session.module.name}` : null }</Typography>
                            <Typography>External Provider: {session.source === 'external' ? 'Yes' : 'No'}</Typography>
                            <Typography>Facilitator: {session.facilitator && session.facilitator.label}</Typography>
                            <Typography>Site: {session.siteLabel}</Typography>
                            <Typography>Venue: { session.venue ? session.venue.name : null }</Typography>
                            <Typography>Cost of Training: {currentUser.currency ? currentUser.currency.value : ""} {session.cost}</Typography>
                            <Typography>Max No. of Delegates: {session.maxDelegates}</Typography>
                            <Typography>Min No. of Delegates: {session.minDelegates}</Typography>
                            <Typography>Min Cost: {currentUser.currency ? currentUser.currency.value : ""} {session.minDelegates && session.cost ? session.minDelegates * session.cost : null}</Typography>
                            <Typography>Max Cost: {currentUser.currency ? currentUser.currency.value : ""} {session.maxDelegates && session.cost ? session.maxDelegates * session.cost : null}</Typography>
                            <Typography>Start Date/Time: {moment(session.startTime.toDate()).format('MMMM Do YYYY, h:mm:ss a')}</Typography>
                            <Typography>End Date/Time: {moment(session.endTime.toDate()).format('MMMM Do YYYY, h:mm:ss a')}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <ScoreAttendee open={assessOpen} close={handleAssessClose} session={session} employee={employee}/>
            <DidAttend open={attendedOpen} close={handleAttendedClose} attendee={employee} sessionId={session.id} />
            <DidNotAttend open={absentOpen} close={handleAbsentClose} attendee={employee} sessionId={session.id} />
        </div>
    );
}
