import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Save } from "@mui/icons-material";
import React, { useState } from "react";
import Select from "react-select";
import { useDb } from "../../../../../contexts/DatabaseContext";
import { selectFieldProps, textFieldProps } from "../../../../shared/constants";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function CoEditAction({ action, open, closeDialog, coId }) {
  const {
    MgmtGetCoDepartments,
    MgmtGetRosters,
    MgmtGetIsoAgencies,
    MgmtGetIsoClasses,
    MgmtGetPriorities,
    MgmtGetSites,
    MgmtGetSources,
    mgmtUpdateAction,
  } = useDb();

  const [state, setState] = useState({
    department: action.department,
    description: action.description,
    hod: action.hod,
    immAction: action.immAction,
    isoAgency: action.isoAgency,
    isoClass: action.isoClass,
    originator: action.originator,
    priority: action.priority,
    site: action.site,
    source: action.source,
    targetDate: action.targetDate.toDate(),
    timestamp: action.timestamp.toDate(),
    closeOutDate: action.closeOutDate ? action.closeOutDate.toDate() : null,
  });

  // Database Calls
  const departments = MgmtGetCoDepartments(coId);
  const sites = MgmtGetSites(coId);
  const rosters = MgmtGetRosters(coId); // <-- this may need to change to a special HOD type of user
  const sources = MgmtGetSources(coId);
  const isoClassifications = MgmtGetIsoClasses(coId);
  const priorities = MgmtGetPriorities(coId);
  const isoAgencies = MgmtGetIsoAgencies(coId);

  // Component State
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setError(null);
    setLoading(false);
    closeDialog();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    try {
      await mgmtUpdateAction(coId, action.id, state).then(() => {
        handleClose();
      });
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };
  console.log(action);
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <Typography>Action Source</Typography>
              <br />
              Current Department: {state.department.name}
              <Select
                options={departments}
                {...selectFieldProps}
                placeholder={"Change Department ..."}
                onChange={(val) =>
                  setState({ ...state, department: val.value })
                }
                className={"iso-select"}
              />
              Current Site: {state.site.label}
              <Select
                options={sites}
                {...selectFieldProps}
                placeholder={"Change Site ..."}
                onChange={(val) => setState({ ...state, site: val })}
                className={"iso-select"}
              />
              Current Source: {state.source.sourceName}
              <Select
                options={sources}
                {...selectFieldProps}
                placeholder={"Change Source ..."}
                onChange={(val) => setState({ ...state, source: val.value })}
                className={"iso-select"}
              />
              <br />
              <Divider />
              <br />
              <Typography>Action Responsiblity</Typography>
              <br />
              Current HOD: {state.hod.firstName} {state.hod.lastName}
              <Select
                options={rosters}
                {...selectFieldProps}
                placeholder={"Change Responsible HOD ..."}
                onChange={(val) => setState({ ...state, hod: val.value })}
                className={"iso-select"}
              />
              Current Originator: {state.originator.firstName}{" "}
              {state.originator.lastName}
              <Select
                options={rosters}
                {...selectFieldProps}
                placeholder={"Select Originator ..."}
                onChange={(val) =>
                  setState({ ...state, originator: val.value })
                }
                className={"iso-select"}
              />
              Current ISO Classification: {state.isoClass}
              <Select
                options={isoClassifications}
                {...selectFieldProps}
                placeholder={"Change ISO Classification ..."}
                onChange={(val) =>
                  setState({
                    ...state,
                    isoClass: `${val.value.code} ${val.value.standard}`,
                  })
                }
                // onChange={(val) => console.log(val)}
                className={"iso-select"}
              />
              Current ISO Agency: {state.isoAgency.isoAgency}
              <Select
                options={isoAgencies}
                {...selectFieldProps}
                placeholder={"Change ISO Agency ..."}
                onChange={(val) => setState({ ...state, isoAgency: val.value })}
                className={"iso-select"}
              />
              Current Priority: {state.priority}
              <Select
                options={priorities}
                {...selectFieldProps}
                placeholder={"Change Priority ..."}
                // onChange={(val) => console.log(val)}
                onChange={(val) => setState({ ...state, priority: val.name })}
                className={"iso-select capitalize"}
                required
              />
              {/*  TODO Add Datepicker  */}
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Typography>Action Description</Typography>
              <br />
              <TextField
                value={state.description}
                className={"iso-text-field"}
                {...textFieldProps}
                label="Action Description"
                multiline
                rows={3}
                required
                onChange={(e) =>
                  setState({ ...state, description: e.target.value })
                }
              />
              <TextField
                value={state.immAction}
                className={"iso-text-field"}
                {...textFieldProps}
                label="Immediate Action Taken"
                multiline
                rows={3}
                required
                onChange={(e) =>
                  setState({ ...state, immAction: e.target.value })
                }
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Created Date"
                  onChange={(d) => setState({ ...state, timestamp: d })}
                  defaultValue={dayjs(state.timestamp)}
                />
              </LocalizationProvider>
              <br />
              <br />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Target Date"
                  onChange={(d) => setState({ ...state, targetDate: d })}
                  defaultValue={dayjs(state.targetDate)}
                />
              </LocalizationProvider>
              <br />
              <br />
              {state.closeOutDate ? (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Close Out Date"
                    onChange={(d) => setState({ ...state, closeOutDate: d })}
                    defaultValue={dayjs(state.closeOutDate)}
                  />
                </LocalizationProvider>
              ) : null}
              <br />
              <br />
            </Grid>
          </Grid>
          {error ? <span className={"iso-error"}>{error}</span> : null}
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={loading}
            color="primary"
            variant="contained"
            startIcon={<Save />}
            type="submit"
          >
            Save Changes
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
