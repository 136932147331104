import {
  faEdit,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonGroup, Chip, IconButton } from "@mui/material";
import { Check, Delete, Search } from "@mui/icons-material";
import React, { useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { useDb } from "../../../contexts/DatabaseContext";
import IsoDataGrid from "../../shared/data-grid/IsoDataGrid";
import FileDialog from "../../shared/FileDialog";
import moment from "moment";
import EditSupDoc from "./EditSupDoc";

export default function SubDocsTable({ folderFilter, supDocs }) {
  const { deleteSupportingDoc, markSupDocReplaced } = useDb();
  const { currentUser } = useAuth();

  const [editOpen, setEditOpen] = useState(false);
  const [editData, setEditData] = useState(false);
  const [fileOpen, setFileOpen] = useState(false);
  const [fileData, setFileData] = useState(false);

  const handleDelete = async (data) => {
    console.log(data);
    try {
      await deleteSupportingDoc(data.parentDocId, data.id);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleEditOpen = (data) => {
    setEditData(data);
    setEditOpen(true);
  };

  const handleFileOpen = (data) => {
    setFileData(data);
    setFileOpen(true);
  };

  const handleMarkReplaced = async (supDoc) => {
    try {
      await markSupDocReplaced(supDoc.parentDocId, supDoc.id);
    } catch (err) {
      console.log(err.message);
    }
  };

  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      renderCell: (params) => {
        return (
          <>
            {params.row.expired &&
            params.row.expires &&
            params.row.expiryDate.toDate() < new Date() ? (
              <>
                <FontAwesomeIcon icon={faExclamationTriangle} /> -{" "}
                <span className="iso-error">Expired</span>
              </>
            ) : null}
            <ButtonGroup size="small">
              <IconButton
                onClick={() => handleFileOpen(params.row)}
                size="small"
              >
                <Search color="primary" />
              </IconButton>
              {!currentUser.accessRights.docManager > 1 ? null : (
                <IconButton
                  onClick={() => handleEditOpen(params.row)}
                  color={"primary"}
                  size="small"
                >
                  <FontAwesomeIcon icon={faEdit} />
                </IconButton>
              )}
              {currentUser && currentUser.companyAdmin ? (
                <IconButton
                  onClick={() => handleDelete(params.row)}
                  color={"secondary"}
                  size="small"
                >
                  <Delete />
                </IconButton>
              ) : null}
            </ButtonGroup>
            <br />
            {params.row.expires ? (
              <Button
                onClick={() => handleMarkReplaced(params.row)}
                startIcon={<Check />}
                color="primary"
                variant="contained"
                size="small"
              >
                Expires: {moment(params.row.expiryDate.toDate()).fromNow()} Mark
                as Replaced
              </Button>
            ) : null}
          </>
        );
      },
    },
    {
      field: "expiryDate",
      headerName: "Expiry Date ",
      width: 100,
      renderCell: (params) => {
        return params.row.expiryDate
          ? moment(params.row.expiryDate.toDate()).format("DD/MM/YYYY")
          : "N/A";
      },
    },
    {
      field: "createdAt",
      headerName: "Date ",
      width: 100,
      renderCell: (params) => {
        return moment(params.row.createdAt.toDate()).format("DD/MM/YYYY");
      },
    },

    {
      field: "folder",
      headerName: "Folder",
      width: 120,
      renderCell: (params) => {
        return params.row.folder ? (
          <Chip size="small" label={params.row.folder.label} />
        ) : null;
      },
    },
    {
      field: "fileName",
      headerName: "File Name",
      width: 800,
    },
  ];

  return (
    <>
      <IsoDataGrid
        columns={columns}
        data={
          folderFilter == null
            ? supDocs
            : supDocs.filter((val) => val.folderId === folderFilter)
        }
      />
      <EditSupDoc
        open={editOpen}
        closeDialog={() => setEditOpen(false)}
        supDoc={editData}
      />
      <FileDialog
        open={fileOpen}
        closeDialog={() => setFileOpen(false)}
        file={fileData && fileData.docUrl ? fileData.docUrl : fileData.fUrl}
      />
    </>
  );
}
