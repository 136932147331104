import React, { useState } from 'react'
import { Card, CardActions, CardContent, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { useDb } from '../../../contexts/DatabaseContext'
import { Pagination } from '@mui/material';
import FloatingHelpComponent from '../../help/FloatingHelpComponent'

export default function UserTemplates() {
    const { GetTemplates } = useDb()
    const templates = GetTemplates()
    const [search, setSearch] = useState(null) 

    // Pagination
    const itemsPerPage = 10; // <- Not state but related to below pagination
    const [page, setPage] = useState(1);
    const [noOfPages] = useState(
        Math.ceil(templates.length / itemsPerPage)
    );

    return (
        <div>
            <h1>Document Templates</h1>
            <Card>
                <CardContent>
                    <TextField fullWidth variant="outlined" label='Search Templates' onChange={e => setSearch(e.target.value)}/>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Template Name</TableCell>
                                    <TableCell align="right">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    search !== null 
                                    ?
                                    templates && templates
                                    .map(t => {
                                        if(t.name.includes(search) || t.name.toLowerCase().includes(search))
                                        return (
                                            <TableRow key={t.id}>
                                                <TableCell>{t.name}</TableCell>
                                                <TableCell align="right">
                                                    <IconButton href={t.url} target="_blank" size="large">
                                                        <FontAwesomeIcon icon={faDownload} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                        else return null
                                    })
                                    :
                                    templates && templates
                                    .slice((page -1) * itemsPerPage, page * itemsPerPage)
                                    .map(t => {
                                        return (
                                            <TableRow key={t.id}>
                                                <TableCell>{t.name}</TableCell>
                                                <TableCell align="right">
                                                    <IconButton href={t.url} target="_blank" size="large">
                                                        <FontAwesomeIcon icon={faDownload} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
                <CardActions>
                    <Pagination
                        count={Math.ceil(templates.length / itemsPerPage)}
                        page={page}
                        onChange={(e, val) => setPage(val)}
                        defaultPage={1}
                        color="primary"
                        size="large"
                        showFirstButton
                        showLastButton
                    /> 
                </CardActions>
            </Card>

            <FloatingHelpComponent
                name="Document Templates" 
                videoPath="doc_manager"
                videoIndex={11}
            />
        </div>
    );
}
