import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'

const app = firebase.initializeApp({
    apiKey: "AIzaSyBvGkzmSdaQ-rtJH3sEeA25GefL51OA7IM",
    authDomain: "iso-office.firebaseapp.com",
    databaseURL: "https://iso-office.firebaseio.com",
    projectId: "iso-office",
    storageBucket: "iso-office.appspot.com",
    messagingSenderId: "917851018998",
    appId: "1:917851018998:web:b8f773420d9dad6dfd3ed7"

})

export const auth = app.auth()
export const db = app.firestore()
export const fieldValue = firebase.firestore.FieldValue 
export const storage = app.storage()
export const functions = app.functions('us-central1')

export default app