import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material'
import React from 'react'

export default function CCMGuide({ open, close }) {
    return (
        <Dialog open={open} onClose={close} maxWidth="sm" fullWidth>
            <DialogTitle>
                Critical Control Measures
            </DialogTitle>
            <DialogContent>
                <Typography>What is a critical contol?</Typography>
                <Typography>The following questions can help to determine if a control is critical:</Typography>
                <ul>
                    <li>Is the control crucial to preventing the event or minimizing the consequences of the event?</li>
                    <li>Is it the only control, or is it backed up by another control in the event the first fails?</li>
                    <li>Would its absence or failure significantly increase the risk despite the existence of the other controls?</li>
                    <li>Does it address multiple causes or mitigate multiple consequences? (In other words, if it appears in a number of places, this may indicate that it is critical.)</li>
                </ul>
                
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    onClick={close}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}
