import { Button, CircularProgress, Step, StepContent, StepLabel, Stepper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ArrowBack } from '@mui/icons-material'
import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import SectionSubmission from './SectionSubmission'

const useStyles = makeStyles((theme) => ({
    actionsContainer: {
        marginBottom: theme.spacing(2),
    }
}))

export default function InspectionFormSubmission() {
    const classes = useStyles()
    const { inspectionId, checklistId } = useParams()
    const history = useHistory()
    const { GetChecklistSections, advanceInspection, getInspection, completeInspection } = useDb()
    const sections = GetChecklistSections(checklistId)

    const [activeStep, setActiveStep] = useState(0)
    const [loaded, setLoaded] = useState(false)
    const [sectionsCompleted, setSectionsCompleted] = useState({})

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSectionComplete = async (i) => {
        setSectionsCompleted({
            ...sectionsCompleted,
            [i]: true
        })
        await advanceInspection(inspectionId, i + 1)
        if(i+1 === sections.length) {
            await completeInspection(inspectionId)
        }
    }

    const firstLoad = async () => {
        const inspection = await getInspection(inspectionId)
        console.log(inspection)
        const step = inspection.currentStep ? inspection.currentStep : 0
        setActiveStep(step)
        setLoaded(true)
    }

    if(!loaded) {
        firstLoad()
        return <CircularProgress />
    }
    return (
        <div>
            <h1>Submit Audit / Inspection</h1>
            <div className="back-link">
                <Button
                    color="primary"
                    variant="contained"
                    startIcon={<ArrowBack />}
                    onClick={() => history.goBack()}
                >
                    Back
                </Button>
            </div>
            <Stepper activeStep={activeStep} orientation="vertical">
                {
                    sections && sections.map((s, i) => {
                        return (
                            <Step key={i}>
                                <StepLabel>{s.name}</StepLabel>
                                <StepContent>
                                    <SectionSubmission
                                        checklistId={checklistId}
                                        inspectionId={inspectionId}
                                        sectionId={s.id}
                                        sectionName={s.name}
                                        order={i}
                                        completeSection={handleSectionComplete}
                                    />
                                    <div className={classes.actionsContainer}>
                                        <div>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            className={classes.button}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            className={classes.button}
                                            disabled={!sectionsCompleted[activeStep]}
                                        >
                                            {activeStep === sections.length - 1 ? 'Finish' : 'Next'}
                                        </Button>
                                        </div>
                                    </div>
                                </StepContent>
                            </Step>
                        )
                    })
                }
            </Stepper>
        </div>
    )
}
