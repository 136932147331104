import { Avatar, Button, Card, CardHeader, LinearProgress, IconButton } from '@mui/material'
import { ArrowBack, GridOn, Search } from '@mui/icons-material'
import React, { useState } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import { useDb } from '../../../../contexts/DatabaseContext'
import IsoDataGrid from '../../../shared/data-grid/IsoDataGrid'
import moment from 'moment'

export default function TrainingMatrixView() {
    
    const { matrixId } = useParams()
    const { getTrainingMatrix } = useDb()
    const history = useHistory()
    const [matrix, setMatrix] = useState(null)
    const [compState, setCompState] = useState({
        pageLoaded: false
    })
    const [mColumns, setMColumns] = useState(null)
    const [mRows, setMRows] = useState(null)

    const firstLoad = async () => {
        const m = await getTrainingMatrix(matrixId)
        setMatrix(m)

        const proficiencyMap = [
            20,
            50,
            75,
            100
        ]

        let columns = [
            { field: 'employee', headerName: 'Employee', width: 240 },
            { field: 'score', headerName: 'Score', width: 240 },
            // { 
            //     field: 'score', 
            //     headerName: 'Score', 
            //     width: 240,
            //     renderCell: (params) => {
            //         return (
            //             <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
            //                 <Link to={`/venues/${params.row.id}`}>
            //                     <IconButton onClick={() => console.log(params.row)}>
            //                         <Search />
            //                     </IconButton>
            //                 </Link>
            //              </div>
            //         );
            //     }
            // }
            
        ]
    
        let rows = []
    
        m.results.map(emp => {
            let eComps = []
            console.log(emp.competencies)
            emp.competencies.map(c => {
                columns.push({
                    field: c.id,
                    headerName: c.name,
                    width: 200
                })
                eComps.push({
                    [c.id]: {
                        name:c.name,
                        compLevel: c.compLevel
                    },
                })
            })
            // This below is the score count for overall competency, check presentation slide 10
            let scoreCount = 0
            const employeeRow = {
                employee: `${emp.firstName} ${emp.lastName}`,
                id: emp.id,
            }
            eComps.map(ec => {
                const comp = Object.values(ec)[0]
                if(comp.compLevel > 0) {
                    // The below checks if we've
                    scoreCount = scoreCount > 0 ? scoreCount + proficiencyMap[comp.compLevel-1] : proficiencyMap[comp.compLevel-1]
                }
                employeeRow[Object.keys(ec)] = comp.compLevel
                
            })
            employeeRow.score = scoreCount
            rows.push(employeeRow)
        })
        setMColumns(columns)
        setMRows(rows)
        setCompState({
            ...compState,
            pageLoaded: true
        })
    }

    if(!compState.pageLoaded) {
        firstLoad()
        return <LinearProgress />
    }
    else return (
        <div>
            <h1>Training Matrix Report</h1>
            <div className="back-link">
                <Button onClick={() => history.goBack()} color="primary" variant="contained" startIcon={<ArrowBack />}>Back</Button>
            </div>
            <Card>
                <CardHeader
                    title={`Report Ran: ${moment(matrix.timestamp.toDate()).fromNow()}`}
                    subheader="All Departments, All Sites"
                    avatar={
                        <Avatar>
                            <GridOn />
                        </Avatar>
                    }
                />
            </Card>
            <br/>
            <IsoDataGrid
                data={mRows}
                columns={mColumns}
            />
        </div>
    )
}
