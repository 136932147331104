import React from "react";
import IsoDataGrid from "../../../shared/data-grid/IsoDataGrid";
import moment from "moment";

export default function ProgramMeasurementsTable({ records, target, program }) {
  const rows =
    records &&
    records.map((row) => ({
      ...row,
      metric: program.metric?.metricName,
      unit: program.metric?.metricUnit,
      date: moment(row.timestamp?.toDate()).format("YYYY-MM-DD HH:ss"),
      recordedBy: `${row.createdBy?.firstName} ${row.createdBy?.lastName}`,
      variance: row.measurement - target,
    }));

  const columns = [
    { field: "date", headerName: "Date", width: 120 },
    { field: "measurement", headerName: "Measurement", width: 120 },
    { field: "metric", headerName: "Metric", width: 120 },
    { field: "unit", headerName: "Unit", width: 120 },
    { field: "recordedBy", headerName: "Recorded By", width: 120 },
    { field: "variance", headerName: "Variance", width: 120 },
    { field: "comment", headerName: "Comment", width: 120 },
  ];

  return (
    <div>
      <IsoDataGrid data={rows} columns={columns} />
    </div>
  );
}
