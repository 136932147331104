import React, { useState } from 'react'
import IsoDataGrid from '../../../shared/data-grid/IsoDataGrid'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import { Button, Chip, Dialog, DialogActions, DialogContent, IconButton, Menu } from '@mui/material'
import PendingDropDown from '../../pending-register/PendingDropDown'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: 'lightgrey',
    position: 'relative'
  },
  dialog: {
    backgroundColor: theme.palette.dialogBg
  },
  text: {
    color: 'grey'
  }
}))

export default function RPendingRegisterDataGrid({data}) {
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [dest, setDest] = useState(null)
  const [doc, setDoc] = useState({})

  const classes = useStyles()

  const handleClick = (e) => {
    console.log(e.currentTarget)
    setAnchorEl(e.currentTarget)
  }

  const openDialog = (dest) => {
    setDest(dest)
    setAnchorEl(null) 
    setOpen(true)
  }

  const closeDialog = () => {
    setOpen(false)
  }

  const rows = data.map((d, i) => ({
    index: i,
    id: d.id,
    docCode: d.docCode,
    docName: d.docName,
    docType: d.docType.label,
    createdDate: moment(d.createdDate.toDate()).format('DD/MM/YYYY'),
    department: d.department.label,
    owner: d.owner.label,
    site: d.site.label,
    approvers: d.approvers,
    pendingApproval: d.pendingApproval,
    targetRevDate: moment(d.targetRevDate.toDate()).format('DD/MM/YYY'),
    status: `Due: ${moment(d.targetRevDate.toDate()).fromNow()}`
  }))

  console.log(rows)

  const columns = [
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={(e) => {
              setDoc(data[params.row.index])
              handleClick(e)
            }}
            size="large">
            <FontAwesomeIcon icon={faEllipsisH} />
          </IconButton>
        );
      }
    },
    {
      field: 'Approval',
      headerName: 'Approval',
      width: 200,
      renderCell: (params) => {
        return(
          <Chip
            color="secondary"
            label={`Pending Approval ${parseInt(params.row.approvers) - parseInt(params.row.pendingApproval)}/${params.row.approvers}`}
          />
        )
      }
    },
    {
      field: 'docName',
      headerName: 'Name',
      width: 600
    },
    {
      field: 'docCode',
      headerName: 'Doc Number',
      width: 200
    },
    {
      field: 'docType',
      headerName: 'Doc Type',
      width: 200
    },
    {
      field: 'createdDate',
      headerName: 'Date Created',
      width: 200
    },
    {
      field: 'department',
      headerName: 'Department',
      width: 200
    },
    {
      field: 'owner',
      headerName: 'Doc Owner',
      width: 400
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 200
    }
  ]

  return (
    <div>
      <IsoDataGrid 
        data={rows}
        columns={columns}
      />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <PendingDropDown doc={doc} openDialog={openDialog} closeDialog={closeDialog}/>
      </Menu>
      
      <Dialog maxWidth="sm" fullWidth open={open} onClose={() => setOpen(false)}>
        <DialogContent className={classes.dialog}>
          { dest }
        </DialogContent>
        <DialogActions className={classes.dialog} >
          <Button onClick={closeDialog} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
