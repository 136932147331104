import { Button, IconButton } from '@mui/material'
import { AddBox, Search } from '@mui/icons-material'
import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../contexts/AuthContext'
import { useDb } from '../../../contexts/DatabaseContext'
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid'


export default function RiskProcesses() {
    const { GetRiskProcesses } = useDb()
    const procs = GetRiskProcesses()
    const { currentUser } = useAuth()

    const rows = procs && procs.map(p  => ({
        id: p.id,
        name: p.name,
        steps: p.steps.length
    }))

    const columns = [
        { field: 'name', headerName: 'Name' , width: 200 },
        { field: 'steps', headerName: 'Steps', width: 200 },
        { 
            field: 'action', 
            headerName: 'Action', 
            width: 140,
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                            <Link to={`/risk-manager/process-detail/${params.row.id}`}>
                                <IconButton size="large">
                                    <Search />
                                </IconButton>
                            </Link>
                     </div>
                );
            }
        }
    ]

    return (
        <div>
            <h1>Risk Processes</h1>
            {
                currentUser.accessRights.riskManager > 2 || currentUser.companyAdmin
                &&
                <Link
                    to="/risk-manager/processes/add"
                >
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddBox />}
                    >
                        New Process
                    </Button>
                </Link>
            }
            <br/><br/>
            <IsoDataGrid
                data={rows}
                columns={columns}
            />
        </div>
    )
}
