import { Button, Chip, Dialog, DialogContent, Snackbar, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import Select from 'react-select'
import { selectFieldProps } from '../../shared/constants'
import { Save } from '@mui/icons-material'

const useStyles = makeStyles(theme => ({
    snackBar: {
        backgroundColor: '#ffbf17',
        color: '#333'
    }
}))

export default function EditDocGroups({ doc }) {
    const { GetGroups, GetGroupsForDocs, addAdditionalGroupsToDoc, removeGroupFromDoc } = useDb()
    const groups = GetGroups()
    const existingGroups = GetGroupsForDocs(doc.id)
    const [newGroups, setNewGroups] = useState()

    const classes = useStyles()

    // Action State
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [snackOpen, setSnackOpen] = useState(false)
    const [snackMsg, setSnackMsg] = useState('')

    const handleRemove = async (groupId) => {
        setError(null)
        setLoading(true)
        try {
            await removeGroupFromDoc(doc.id, groupId).then(() => {
                setLoading(false)
                setSnackMsg('Group Removed Successfully')
                setSnackOpen(true)
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    const handleSubmit = async () => {
        setError(null)
        setLoading(true)
        try {
            await addAdditionalGroupsToDoc(doc.id, newGroups).then(() => {
                setLoading(false)
                setNewGroups()
                setSnackMsg('Group Added Successfully')
                setSnackOpen(true)
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    return (
        <>
            <Select
                options={groups}
                {...selectFieldProps}
                placeholder="Add Groups"
                isMulti
                onChange={v => setNewGroups(v)}
            />
            <br/>
            {
                newGroups || newGroups && newGroups.length < 1 
                ?
                <Button disabled={loading} startIcon={<Save />} variant="contained" color="primary" fullWidth onClick={handleSubmit}>Save Changes</Button>
                :
                null
            }
            <br/> 
            {
                error 
                ?
                <span className="iso-error">{error}</span>
                :
                null
            }
            <Typography>Document Limited to Groups:</Typography>
            {
                existingGroups && existingGroups.map(g => {
                    return (
                        <Chip size="small" label={g.name} key={g.id} onDelete={() => handleRemove(g.id)}/>
                    )
                })
            }

            <Snackbar
                open={snackOpen}
                message={snackMsg}
                autoHideDuration={5000}
                onClose={() => setSnackOpen(false)}
                color="primary"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                ContentProps={{
                    className: classes.snackBar
                }}
            />
        </>
    )
}
