import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Card,
    CardHeader,
    FormControl,
    CardContent,
    MenuItem,
    Select,
    Avatar,
    Button,
    CardActions,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { useDb } from '../../../contexts/DatabaseContext';
import DocListItem from '../document/DocListItem';
import { Pagination } from '@mui/material';
import { selectFieldProps } from '../../shared/constants.js'

const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: theme.palette.cardBg
    } 
}))

const DocsDueDates = ({ favs, userGroups }) => {
    const classes = useStyles()
    const [days, setDays] = useState(7);
    const { GetDocsForNextDays } = useDb()
    const docs = GetDocsForNextDays(days)
    
    // Pagination
    const itemsPerPage = 5; // <- Not state but related to below pagination
    const [page, setPage] = useState(1);
    const [noOfPages] = useState(
        Math.ceil(docs.length / itemsPerPage)
    );
    return ( 
        <Card className={classes.card}>
            <CardHeader 
                className="iso-card-header"
                avatar={
                    <FontAwesomeIcon icon={faFileAlt} />
                }
                title={`Docs Due In Next: ${days} days`}
                action={
                    <FormControl>
                        <Select
                            value={days}
                            onChange={(e) => setDays(e.target.value)}
                            
                            {...selectFieldProps}
                        >
                            <MenuItem value={7}>7 Days</MenuItem>
                            <MenuItem value={14}>14 Days</MenuItem>
                            <MenuItem value={30}>30 Days</MenuItem>
                        </Select>
                    </FormControl>
                }
            />
            <CardContent className="iso-card-content">
                {
                    docs && docs
                    .slice((page -1) * itemsPerPage, page * itemsPerPage)
                    .map((doc) => {

                        if(doc.groups && doc.groups.length > 0) {
                            if(doc.groups.some(r=> userGroups.indexOf(r) >= 0)) {
                                return (
                                    <div key={doc.id} style={{ marginBottom: 10 }}>
                                        <DocListItem doc={doc} favs={favs}/>
                                    </div>
                                )
                            }
                            else {
                              return null
                            }
                        }
                        else {
                            return (
                                <div key={doc.id} style={{ marginBottom: 10 }}>
                                    <DocListItem doc={doc} favs={favs}/>
                                </div>
                            )
                        }

                        
                    })
                }
            </CardContent>
            <CardActions className="iso-card-header">
                <Pagination
                    count={Math.ceil(docs.length / itemsPerPage)}
                    page={page}
                    onChange={(e, val) => setPage(val)}
                    defaultPage={1}
                    color="primary"
                    size="large"
                    showFirstButton
                    showLastButton
                />   
            </CardActions>
        </Card>
    );
}

export default DocsDueDates;