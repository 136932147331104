import { Restore } from '@mui/icons-material'
import { useDb } from '../../contexts/DatabaseContext'
import { IconButton } from '@mui/material'
import IsoDataGrid from '../shared/data-grid/IsoDataGrid'

export default function FixTrainingComp() {

  const { GetAllCompetency, updateCompentancy } = useDb()
  const moduals = GetAllCompetency()

  const handleClick = async (data) => {
    await updateCompentancy(data)
  }

  const columns = [
    { field: "num", headerName: "Num", width: 180 },
    { field: "id", headerName: "Compentency Id", width: 250 },
    { field: "name", headerName: "Name", width: 180 },
    { field: "module", headerName: "Module", width: 180 },
    { field: "moduleName", headerName: "ModuleName", width: 180 },
    { field: 'fix', headerName: 'Fix', width: 200,
      renderCell: (params) => {
        return (
          <IconButton
            disabled={params.row.data.compFixed}
            onClick={() => handleClick(params.row.data)}
            size="large">
              <Restore />
          </IconButton>
        );
      }
    }
  ]
  
  const rows = moduals && moduals.map((m, i) => ({
    num: i + 1,
    name: m.name,
    id: `${m.compId} ${i + 1}`,
    data: m,
    module: m.module ? m.module.code : null,
    moduleName: m.module ? m.module.name : null
  }))

  return (
    <div>
      <h2>Fix Competancy</h2>
      <IsoDataGrid columns={columns} data={rows} />
    </div>
  )
}
