import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import React, { useState } from 'react'
import Select from 'react-select';
import { useDb } from '../../../../contexts/DatabaseContext';
import { docCategories, retention, disposition, selectFieldProps } from '../../../shared/constants'

export default function EditSelect({ type, closeEditor, oldValue, doc }) {
    const { GetDepartments, editSelectField, GetDocTypesDropdown, GetRosters, GetSites } = useDb()
    var label = '';
    var options;
    if( type === 'department' ) {
        options = GetDepartments()
        label = 'Department'
    }
    else if(type === 'site') {
        options = GetSites()
        label = 'Site'
    }
    else if (type === 'docType') {
        options = GetDocTypesDropdown()
        label = 'Document Type'
    }
    else if (type === 'owner') {
        options = GetRosters()
        label = 'Document Owner'
    }
    else if (type === 'dispositionMethod') {
        options = disposition
        label = 'Disposition Method'
    }
    else if (type === 'retentionPeriod'){
        options = retention
        label = 'Retention Period'
    }

    const [selected, setSelected] = useState(false)
    const [value,setValue] = useState(null)
    const [error,setError] = useState('')

    const handleChange = (val) => {
        setValue(val)
        setSelected(true)
    }

    const handleSave = async () => {
        setSelected(false)
        const result = await editSelectField(type, doc.id, value)
        if (result !== null) {
            closeEditor()
        }
        else {
            setSelected(true)
            setError('Error saving to database')
        }
    }

    return (
        <>
            <Select 
                options={options}
                {...selectFieldProps}
                placeholder={`Select New ${label} ...`}
                onChange={handleChange}
                isSearchable={true}
            /><br/>
            <span style={{ color: 'red'}}>{error}</span>
            <Button onClick={handleSave} disabled={!selected} variant="contained" startIcon={<FontAwesomeIcon icon={faSave}/>} fullWidth className="iso-right" color="primary">Save</Button>
        </>
    )
}
