import React, { useState} from 'react'
import { useParams, Link } from 'react-router-dom'
import { useDb } from '../../../../../contexts/DatabaseContext'
import { Button, Card, CardActions, CardContent, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { AddBox, ArrowLeftOutlined, ArrowRightAltSharp, Edit } from '@mui/icons-material'
import { Pagination } from '@mui/material';
import CoDocAddIntParty from './CoDocAddIntParty'

export default function CoDocIntParties() {
    const { coId, docId } = useParams()
    const { MgmtGetDocIntParties } = useDb()
    const parties = MgmtGetDocIntParties(coId, docId)

    const [addOpen, setAddOpen] = useState(false)

    // Pagination
    const itemsPerPage = 10 // <- Not state but related to below pagination
    const [page, setPage] = useState(1)
    const [noOfPages] = useState(
        Math.ceil(parties.length / itemsPerPage)
    )

    return (
        <div>
            <h1 style={{ color: '#fff' }}>Interested Parties on Document: {docId}</h1>
            <Card>
                <CardContent>
                    <Link to={`/companies/${coId}/documents`}>
                        <Button startIcon={<ArrowLeftOutlined />} color="primary" variant="contained">Back</Button>
                    </Link>
                    <br/><br/>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>First Name</TableCell>
                                    <TableCell>Last Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>UID</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    parties && parties
                                    .slice((page -1) * itemsPerPage, page * itemsPerPage)
                                    .map(p => {
                                        return (
                                            <TableRow key={p.id}>
                                                <TableCell>{p.firstName}</TableCell>
                                                <TableCell>{p.lastName}</TableCell>
                                                <TableCell>{p.email}</TableCell>
                                                <TableCell>{p.uid}</TableCell>
                                                <TableCell>
                                                    <IconButton size="large">
                                                        <Edit />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
                <CardActions>
                    <Pagination
                        count={Math.ceil(parties.length / itemsPerPage)}
                        page={page}
                        onChange={(e, val) => setPage(val)}
                        defaultPage={1}
                        color="primary"
                        size="large"
                        showFirstButton
                        showLastButton
                    /> 
                    <Button onClick={() => setAddOpen(true)} variant="contained" color="primary" startIcon={<AddBox />}>Add Parties</Button>
                    <Link to={`/companies/${coId}/doc-revisions/${docId}/`}>
                        <Button color="primary" endIcon={<ArrowRightAltSharp />}>Next: Add Revisions</Button>
                    </Link>
                </CardActions>
            </Card>
            <CoDocAddIntParty open={addOpen} closeDialog={() => setAddOpen(false)} coId={coId} docId={docId} />
        </div>
    );
}


