import { faFilePdf, faFolder, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AppBar, Button, Chip, Dialog, DialogActions, DialogContent, IconButton, Slide, TextField, Toolbar } from '@mui/material'
import React, { useState } from 'react'
import Select from 'react-select'
import { useDb } from '../../../contexts/DatabaseContext'
import { Pagination } from '@mui/material';
import { textFieldProps, selectFieldProps } from '../../shared/constants'
import SupDoc from '../../docmanager/supporting-documents/SupDoc'
import AddSupportingDocument from '../../docmanager/supporting-documents/AddSupportingDocument'
import AddEmployeeSupportingDocument from './AddEmployeeSupportingDocument'
import { useParams } from 'react-router-dom'
import EmpSupDoc from './supportingDocs/EmpSupDoc'

export default function EmployeeAttachments() {

  const { empId } = useParams()
  const { addEmpSubfolder, GetEmpSubfolders, removeEmpSubfolder, GetEmpSupportingDocs, GetEmpSubfoldersDropdown } = useDb()
  const [open, setOpen] = useState(false)
  const [folderName, setFolderName] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [addOpen, setAddOpen] = useState(false)
  const [folderFilter, setFolderFilter] = useState(null)
  const [search, setSearch] = useState('')
  

  const subFolders = GetEmpSubfolders(empId)
  const supDocs = GetEmpSupportingDocs(empId) 
  const subFoldersOptions = GetEmpSubfoldersDropdown(empId)

  // Pagination
  const itemsPerPage = 5; // <- Not state but related to below pagination
  const [page, setPage] = useState(1);
  const [noOfPages] = useState(
      Math.ceil(supDocs.length / itemsPerPage)
  );

  const closeDialog = () => {
      setFolderName('')
      setOpen(false)
  }

  const closeAddDialog = () => {
      setAddOpen(false)
  }

  const handleFolderAdd = async () => {

      setLoading(true)
      
      const result = await addEmpSubfolder(empId, {folderName});
      if (result !== null) {
          closeDialog()
          setLoading(false)
      }
      else {
          setError('Error saving folder to database')
          setLoading(false)
      }

  }

  const handleFolderDelete = async (folderId) => {
      const result = await removeEmpSubfolder(empId, folderId);
      if (result === null) {
          setError('Error deleting folder from database')
      }
  }

  return(
  <>
    <Button onClick={() => setOpen(true)} startIcon={<FontAwesomeIcon icon={faFolder}/>} variant="contained" color="primary">Manage Folders</Button>&nbsp;
    <Button onClick={() => setAddOpen(true)} startIcon={<FontAwesomeIcon icon={faPlus}/>} variant="contained" color="primary">Add Document</Button>&nbsp;
    <br/><br/>
    <Select
        options={subFoldersOptions}
        placeholder={'Subfolder ...'}
        onChange={val => {
            setFolderFilter(val ? val.id : null)
        }}
        {...selectFieldProps}
        isClearable={true}
        
    />
    <br/>
    <TextField 
        {...textFieldProps}
        label={'Search Supporting Docs...'}
        onChange={(e) => setSearch(e.target.value)}
        className={'iso-text-field'}
    />
    
    {
        folderFilter === null ?
        supDocs && supDocs
        .slice((page -1) * itemsPerPage, page * itemsPerPage)
        .map((sDoc) => {
            if(search === null || '') {
                return (
                    <EmpSupDoc supDoc={sDoc} key={sDoc.id} />
                )
            }
            else {
                if (sDoc.fileName.toLowerCase().includes(search)) {
                    return (
                        <EmpSupDoc supDoc={sDoc} key={sDoc.id} />
                    )
                }
            }
            
        })
        :
        supDocs && supDocs.map((sDoc) => {
            if(sDoc.folder) {
                if(sDoc.folder.id === folderFilter) {
                    if(search === null || '') {
                        return (
                            <EmpSupDoc supDoc={sDoc} key={sDoc.id} /> 
                        )
                    }
                    else {
                        if (sDoc.fileName.toLowerCase().includes(search)) {
                            return (
                                <EmpSupDoc supDoc={sDoc} key={sDoc.id} />
                            )
                        }
                    }
                }
                else {
                    return null
                }
            }
            else {
                return null
            }
        })
        
    }
    <Pagination
        count={Math.ceil(supDocs.length / itemsPerPage)}
        page={page}
        onChange={(e, val) => setPage(val)}
        defaultPage={1}
        color="primary"
        size="large"
        showFirstButton
        showLastButton
    />

    
    <Dialog maxWidth="sm" fullWidth open={open} onClose={closeDialog} >
        <DialogContent>
            <div style={{ width: '100%', marginBottom: 20 }}>
                {
                    subFolders && subFolders.map((folder) => {
                        return (
                            <Chip key={folder.id} size="small" label={folder.folderName} onDelete={() => handleFolderDelete(folder.id)} style={{ marginRight: 10 }}/>
                        )
                    })
                }
            </div>
            <TextField label="Add Folder" fullWidth variant="outlined" onChange={(e) => setFolderName(e.target.value)} />
            <span className="iso-error">{error}</span>
        </DialogContent>
        <DialogActions>
            { folderName !== '' ? <Button disabled={loading} onClick={handleFolderAdd} variant="contained" color="primary">Add Folder</Button> : null }
            <Button onClick={closeDialog} color="secondary">Close</Button>
        </DialogActions>
    </Dialog>
    <Dialog maxWidth="sm" fullWidth open={addOpen} onClose={() => setAddOpen(false)}>
        <DialogContent>
            <AddEmployeeSupportingDocument docId={empId} closeAddDialog={closeAddDialog}/>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setAddOpen(false)} color="secondary">Close</Button>
        </DialogActions>
    </Dialog>
  </>
  )
}
