import {
  Box,
  Button,
  IconButton,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import IsoFormSection from "./IsoFormSection";
import { Snackbar } from "@mui/material";
import { useDb } from "../../../../contexts/DatabaseContext";
import { Edit } from "@mui/icons-material";
import EditSectionName from "./EditSectionName";

export default function SectionsContainer({
  collectionPath,
  docString,
  sections,
  render,
  handleAdvance,
  approvalView,
}) {
  const { setCompanyDoc, getCompanyDoc } = useDb();
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [responses, setResponses] = useState([]);
  const [snackOpen, setSnackOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState();
  const [editOpen, setEditOpen] = useState(false);

  const saveResponses = async () => {
    setLoading(true);
    try {
      await setCompanyDoc(collectionPath, docString, {
        responses,
      });
      setSnackOpen(true);
    } catch (err) {
      window.alert(err.message);
    }
    setLoading(false);
  };

  const getResponses = async () => {
    const responseDoc = await getCompanyDoc(collectionPath, docString);
    if (responseDoc && responseDoc.responses && render) {
      setResponses(responseDoc.responses);
    }
  };

  useEffect(() => {
    const unsubscribe = render ? getResponses() : null;
    return unsubscribe;
  }, []);

  const useFirstRender = () => {
    const firstRender = React.useRef(true);

    useEffect(() => {
      firstRender.current = false;
    }, []);

    return firstRender.current;
  };

  const isFirstRender = useFirstRender();

  useEffect(() => {
    if (isFirstRender) return;
    if (render) saveResponses();
  }, [responses]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleEditClick = (s) => {
    setSelectedSection(s);
    setEditOpen(true);
  };

  const handleComplete = async () => {
    if (render) {
      handleAdvance();
      // history.push(nextRoute);
    }
  };

  return (
    <div>
      <Stepper orientation="vertical" activeStep={activeStep}>
        {sections &&
          sections.map((section, index) => (
            <Step key={index}>
              <StepLabel>
                {section.name}{" "}
                {!render && (
                  <IconButton onClick={() => handleEditClick(section)} size="large">
                    <Edit />
                  </IconButton>
                )}
              </StepLabel>
              <StepContent>
                <IsoFormSection
                  section={section}
                  render={render || false}
                  responses={responses}
                  setResponses={setResponses}
                  saveResponses={saveResponses}
                  loading={loading}
                />

                {!approvalView && (
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={
                          index === sections.length - 1
                            ? handleComplete
                            : handleNext
                        }
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {index === sections.length - 1 ? "Finish" : "Continue"}
                      </Button>
                      <Button
                        disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Back
                      </Button>
                    </div>
                  </Box>
                )}
              </StepContent>
            </Step>
          ))}
      </Stepper>

      {selectedSection && (
        <EditSectionName
          section={selectedSection}
          open={editOpen}
          close={() => setEditOpen(false)}
        />
      )}

      <Snackbar
        open={snackOpen}
        message="Data Saved!"
        autoHideDuration={3000}
        color="success"
        onClose={() => setSnackOpen(false)}
      />
    </div>
  );
}
