import { Avatar, Card, CardContent, CardHeader } from '@mui/material'
import { Warning } from '@mui/icons-material'
import React from 'react'
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid'
import moment from 'moment'

export default function RecentMisses({ missed }) {

    const rows = missed && missed.map(m => ({
        id: m.id,
        dueDate: moment(m.dueDate.toDate()).format('DD/MM/YYYY'),
        jobRef: m.jobRef,
        assignee: m.assignedTo.label,
        title: m.title,
        type: m.type
    }))

    const columns = [
        { field: 'jobRef', headerName: 'Code', width: 120 },
        { field: 'dueDate', headerName: 'Due', width: 140 },
        { field: 'assignee', headerName: 'Assignee', width: 200 },
        { field: 'title', headerName: 'Title', width: 200 },
        { field: 'type', headerName: 'Type', width: 200 }
    ]

    return (
        <div>
            <Card>
                <CardHeader
                    title="Recently Missed (This Year)"
                    avatar={
                        <Avatar>
                            <Warning />
                        </Avatar>
                    }
                />
                <CardContent>
                    <IsoDataGrid
                        data={rows}
                        columns={columns}
                    />
                </CardContent>
            </Card>
        </div>
    )
}
