import { Grid } from '@mui/material'
import React from 'react'
import Select from 'react-select'
import { selectFieldProps } from '../../../shared/constants'

export default function ExtendedTabsDropdown({ updateFilters }) {

    const options = [
        { value: 'L1', label: 'L1'},
        { value: 'L2', label: 'L2'},
        { value: 'L3', label: 'L3'},
        { value: 'L4', label: 'L4'},
        { value: 'L5', label: 'L5'},
        { value: 'L6', label: 'L6'},
        { value: 'L7', label: 'L7'},
        { value: 'L8', label: 'L8'},
    ]

    const handleSelect = (v) => {
        if(!v) {
            updateFilters({
                key: 'category',
                data: {
                    fieldName: 'category',
                    value: null
                }
            })
        }
        else {
            updateFilters({
                key: 'category',
                data: {
                    fieldName: 'category',
                    value: v.value
                }
            })
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
                <Select
                    className="iso-select"
                    {...selectFieldProps}
                    options={options}
                    onChange={handleSelect}
                    placeholder="Document Level (L Category) ..."
                    isClearable                
                />  
            </Grid>
        </Grid>
    )
}
