import { Avatar, Button } from "@mui/material";
import React from "react";
import IsoDataGrid from "../../shared/data-grid/IsoDataGrid";
import { useDb } from "../../../contexts/DatabaseContext";
import { Link } from "react-router-dom";
import { camelCaseToTitleCase } from "../../shared/helpers";

export default function MocRegister() {
  const { GetCollection } = useDb();
  const mocs = GetCollection(
    false,
    "moc",
    ["changeRequired", "==", false],
    ["createdAt", "desc"]
  );

  const rows =
    mocs &&
    mocs.map((moc) => ({
      ...moc,
      sites: moc.sites && moc.sites.map((site) => site.label).join(", "),
      departments:
        moc.departments && moc.departments.map((dep) => dep.label).join(", "),
      date: moc.createdAt.toDate(),
      currentStep: moc.step ? camelCaseToTitleCase(moc.step) : "In Progress",
    }));

  // TODO: Allow continue of action button depending on phase and step
  const columns = [
    {
      field: "mocNumber",
      headerName: "MOC ID",
      width: 180,
      renderCell: (params) => {
        return params.row.changeRequired ? (
          <div style={{ cursor: "pointer" }}>
            <Link to={`/moc-request/${params.row.id}`}>
              <Button variant="outlined">{params.row.mocNumber}</Button>
            </Link>
          </div>
        ) : (
          `${params.row.mocNumber}`
        );
      },
    },
    { field: "title", headerName: "Title", width: 220 },
    { field: "status", headerName: "Status", width: 120 },
    {
      field: "phase",
      headerName: "Phase",
      width: 70,
      renderCell: (params) => {
        return (
          <div>
            <Avatar>{params.row.phase}</Avatar>
          </div>
        );
      },
    },

    { field: "sites", headerName: "Sites", width: 220 },
    { field: "date", headerName: "Date Initiated", width: 120, type: "date" },
    { field: "departments", headerName: "Departments", width: 220 },
  ];
  return <div>{mocs && <IsoDataGrid data={rows} columns={columns} />}</div>;
}
