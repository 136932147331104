import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material'
import { Save } from '@mui/icons-material'
import React, { useState } from 'react'
import Select from 'react-select'
import { useDb } from '../../../contexts/DatabaseContext'
import { selectFieldProps, textFieldProps } from '../../shared/constants'

export default function AddIsoClass({ open, closeDialog }) {
    const { createIsoClass, GetIsoCodes } = useDb()

    const standardOptions = GetIsoCodes()

    const handleClose = () => {
        setState({})
        setLoading(false)
        setError(null)
        closeDialog()
    }

    const [state, setState] = useState({})

    // Action State
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setError(null)
        setLoading(true)
        try {
            await createIsoClass(state).then(() => {
                handleClose()
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
        
    }

    return (
        <Dialog open={open} maxWidth="sm" fullWidth onClose={handleClose}>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <Select
                        options={standardOptions}
                        { ...selectFieldProps }
                        placeholder="Select Code ..."
                        onChange={v => setState({ ...state, standard: v.value })}
                    />
                    <br/>
                    <TextField
                        { ...textFieldProps }
                        onChange={e => setState({ ...state, code: e.target.value})}
                        
                        label="Select Standard"
                        required
                    />
                </DialogContent>
                <DialogActions>
                    { error ? <span className="iso-error">{error}</span> : null }
                    <Button disabled={ loading } onClick={handleClose} color="secondary">Cancel</Button>
                    <Button disabled={ loading || !state.standard } type="submit" color="primary" variant="contained" startIcon={<Save />}>Submit</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
