import React from "react";
import IsoDataGrid from "../shared/data-grid/IsoDataGrid";

export default function ParticipantsList({ parties, roleName }) {
  const roleHeader = roleName.charAt(0).toUpperCase() + roleName.slice(1);
  const rows =
    parties &&
    parties.map((p) => ({
      ...p,
      id: p.user.value.id,
      name: p.user.label,
    }));

  const columns = [{ field: "name", headerName: roleHeader, width: 400 }];
  return <div>{parties && <IsoDataGrid data={rows} columns={columns} />}</div>;
}
