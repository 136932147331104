import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import { Save } from '@mui/icons-material'
import React from 'react'
import BulkFileUploader from '../../shared/BulkFileUploader'
import { useState } from 'react'
import { useAuth } from '../../../contexts/AuthContext'
import { useDb } from '../../../contexts/DatabaseContext'

export default function AddSharedDocuments({open, handleClose, folderId}) {

    const [bulkFiles, setBulkFiles] = useState()
    const [loading, setLoading] = useState()
    const { currentUser } = useAuth()
    const { addSharedDocument } = useDb()

    const addMultiple = async () => {
        setLoading(true)
        await bulkFiles.forEach(async f => {
            await addSharedDocument(f.url, f.fileName, folderId)
        })
        
        handleClose()
    }

    const toggleBusy = (busy) => {
        setLoading(busy)
    }

    const getFiles = async (f) => {
        setBulkFiles(f)
        
    }

    return(
        <Dialog open={open} onClose={handleClose}>
            <DialogContent>
                <BulkFileUploader getFiles={getFiles} userId={currentUser.uid} setBusy={toggleBusy}/>
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} startIcon={<Save />} variant='contained' color='primary' onClick={addMultiple}>Save</Button>
                <Button disabled={loading} variant='outlined' color='secondary' onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )

}