import { Button } from '@mui/material'
import { Warning } from '@mui/icons-material'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useDb } from '../../../../../contexts/DatabaseContext'
import IsoDataGrid from '../../../../shared/data-grid/IsoDataGrid'

export default function CoActionsImport() {
    const { MgmtGetActions, importActionsBQ } = useDb()
    const { coId } = useParams()
    const actions = MgmtGetActions(coId)
    
    const rows = actions && actions.map(a => ({
        ...a,
        id: a.id,
    }))

    const columns = [
        { field: 'id', headerName: "ID", width: 200 },
        { field: 'actionNumber', headerName: "ID", width: 200 }
    ]

    console.log(actions)

    const handleImport = async () => {
        try {
            await importActionsBQ(actions)
        }
        catch(err) {
            window.alert(err.message)
        }
    }

    return (
        <div>
            <Button onClick={handleImport} color="secondary" startIcon={<Warning />} variant="contained">Import</Button>
            <IsoDataGrid
                data={rows}
                columns={columns}
            />
        </div>
    )
}
