import React from 'react'
import { Switch } from 'react-router-dom'
import Account from '../account/Account'
import AccountBlocked from '../account/AccountBlocked'
import PrivateRoute from './PrivateRoute'

export default function AccountBlockedRoutes() {
    return (
        <Switch>
            <PrivateRoute exact path='/' component={AccountBlocked} />  
            {/* Account */}
            <PrivateRoute exact path='/account' component={Account} />
            <PrivateRoute path="*" component={AccountBlocked} />
        </Switch>
    )
}
