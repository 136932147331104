import { Button, Card, CardHeader, Grid, CardActions, CircularProgress, CardContent, Typography, Avatar } from '@mui/material'
import { AddBox, CreditCard, People, Person } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { useDb } from '../../contexts/DatabaseContext'
import { functions } from '../../firebase'

export default function Account() {
    const { GetSubscription } = useDb()
    const { logout, currentUser } = useAuth()
    const subscription = GetSubscription()
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [userCount, setUserCount] = useState()

    const handlePortalAccess = async () => {
        setLoading(true)
        const functionRef = functions
        .httpsCallable('ext-firestore-stripe-payments-createPortalLink');
        const { data } = await functionRef({
        returnUrl: window.location.origin + "/account",
        locale: "auto", // Optional, defaults to "auto"
        // configuration: "bpc_1JSEAKHYgolSBA358VNoc2Hs", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
        });
        window.location.assign(data.url);
    }

    const getCurrentUserCount = async () => {
        const functionRef = functions
        .httpsCallable('getUserCount');

        const count = await functionRef({ companyId: currentUser.companyId });
        setUserCount(count.data)
        setLoading(false)
    }

    const handleLogout = async () => {
        try {
            await logout()
            history.push('/login')
            window.location.reload()
        }
        catch(err) {
            console.log(err.message)
        }
    }

    useEffect(() => {
      const count = getCurrentUserCount();
      return count
    }, []);
    

    if(!currentUser.companyOwner || currentUser.stripe_exempt) {
        return <div>
            <Typography>
                You are not authorized to view this page.
            </Typography>
        </div>
    }
    else return (
        <div>
            <h1>My Account</h1>

            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Card>
                        <CardHeader
                            title="Your Profile"
                            avatar={<Person />}
                        />
                        <CardContent>
                            <Typography>Account: {currentUser.email}</Typography>
                        </CardContent>
                        <CardActions>
                            <Button onClick={handleLogout} color="primary">Log Out</Button>
                        </CardActions>
                    </Card>
                </Grid>
                
                <Grid item xs={6}>
                    <Card>
                        <CardHeader
                            title={'Billing'}
                            avatar={<CreditCard />}
                        />
                        <CardContent>
                            <Card>
                                <CardHeader
                                    title={`Your Current Plan: ${ subscription ? subscription.items[0].price.product.name :"No Active Plan" }`}
                                    avatar={
                                        <Avatar>
                                            <CreditCard />
                                        </Avatar>
                                    }
                                />
                            </Card>
                            <br/>
                            {
                                !loading && 
                                <Card>
                                    <CardHeader
                                        title={`Users on Plan: ${ userCount && userCount }`}
                                        avatar={
                                            <Avatar>
                                                <People />
                                            </Avatar>
                                        }
                                    />
                                </Card>
                            }
                            <br/>
                            {
                                !subscription
                                ?
                                <Link to="/addplan">
                                    <Button color="primary" variant="contained" startIcon={<AddBox />}>Add Plan</Button>
                                </Link>
                                :
                                null
                            }
                        </CardContent>
                        <CardActions>
                            {
                                loading
                                ?
                                <CircularProgress />
                                :
                                <Button disabled={ !subscription } variant="contained" onClick={handlePortalAccess} color="primary">Manage Billing</Button>
                            }
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}
