import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { Save } from '@mui/icons-material'
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'

export default function DidAttend({ open, close, sessionId, attendee }) {
    const { confirmAttend } = useDb()
    const [compState, setCompState] = useState({ loading: false, error: null })

    const handleClose = () => {
        setCompState({ loading: false, error: null })
        close()
    }

    const handleConfirm = async () => {
        setCompState({ loading: true, error: null })
        try { 
            await confirmAttend(sessionId, attendee.id)
            handleClose()
        }
        catch(err) {
            setCompState({ loading: false, error: err.message })
        }
    }

    return (
        <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
            <DialogTitle>Confirm Attended Appointment</DialogTitle>
            <DialogContent>
                <Typography>Are you sure you want to mark {attendee && attendee.firstName} {attendee && attendee.lastName} as Attended?</Typography>
                { compState.error ? <span className="iso-error">{compState.error}</span> : null }
            </DialogContent>
            <DialogActions>
                <Button disabled={ compState.loading } onClick={handleClose} color="secondary">Cancel</Button>
                <Button disabled={ compState.loading } variant="contained" startIcon={<Save />} onClick={handleConfirm} color="primary">Confirm</Button>
            </DialogActions>
        </Dialog>
    )
}
