import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Save } from '@mui/icons-material'
import React from 'react'
import { selectFieldProps, textFieldProps } from '../../shared/constants'
import Select from 'react-select'
import { useDb } from '../../../contexts/DatabaseContext'

const useStyles = makeStyles((theme) => ({
    header: {
        background: theme.palette.primary.main
    }
}))

export default function AddSupplier({ open, close }) {
    const classes = useStyles()
    const { GetSites, GetDepartments, GetUsersLevelFilter, GetProducts, createSupplier } = useDb()
    const hods = GetUsersLevelFilter('auditManager', 2)
    const sites = GetSites()
    const depts = GetDepartments()
    const products = GetProducts()

    const handleClose = () => {
        setState({
            loading: false,
            error: null,
            data: {}
        })
        close()
    }

    const [state, setState] = React.useState({
        loading: false,
        error: null,
        data: {}
    })

    const handleChange = (e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleSelect = (v, e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.name]: v
            }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setState({
            ...state,
            error: null,
            loading: true
        })
        try {
            await createSupplier(state.data)
            handleClose()
        }
        catch(err) {
            setState({
                ...state,
                error: err.message,
                loading: false
            })
        }
    }

    return (
        <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
            <DialogTitle className={classes.header}>Add Supplier</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                {...textFieldProps}
                                label="Company Name"
                                name="companyName"
                                required
                                onChange={handleChange}
                            />
                            <Select
                                {...selectFieldProps}
                                className="iso-select"
                                onChange={handleSelect}
                                options={sites}
                                placeholder="Allocated Site ..."
                                name="site"
                            />
                            <Select
                                {...selectFieldProps}
                                className="iso-select"
                                onChange={handleSelect}
                                options={depts}
                                placeholder="Responsible Department ..."
                                name="department"
                            />
                            <Select
                                {...selectFieldProps}
                                className="iso-select"
                                onChange={handleSelect}
                                options={hods}
                                placeholder="Contract Manager ..."
                                name="manager"
                            />
                            <Select
                                {...selectFieldProps}
                                className="iso-select"
                                onChange={handleSelect}
                                options={products}
                                placeholder="Products / Services ..."
                                name="products"
                                isMulti
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                {...textFieldProps}
                                label="Contact Person: First Name"
                                name="firstName"
                                required
                                onChange={handleChange}
                            />
                            <TextField
                                {...textFieldProps}
                                label="Contact Person: Last Name"
                                name="lastName"
                                required
                                onChange={handleChange}
                            />
                            <TextField
                                {...textFieldProps}
                                label="Contact Person: Email"
                                name="email"
                                required
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {
                        state.error && <Typography color="secondary">{state.error}</Typography>
                    }
                    <Button
                        color="secondary"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        startIcon={<Save />}
                        disabled={
                            state.loading ||
                            !state.data.site ||
                            !state.data.department ||
                            !state.data.manager ||
                            !state.data.products
                        }
                        type="submit"
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
