import { faShareSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Button, Card, CardContent, CardHeader, Divider, Grid, IconButton, LinearProgress, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Menu, MenuItem, Snackbar, Typography } from '@mui/material'
import { ArrowLeftOutlined, ArrowRightOutlined, Delete, Email, FileCopy, People, WhatsApp } from '@mui/icons-material'
import React, { useState } from 'react'
import { useAuth } from '../../../contexts/AuthContext'
import { useDb } from '../../../contexts/DatabaseContext'
import BulkFileUploader from '../../shared/BulkFileUploader'
import FileDialog from '../../shared/FileDialog'
import ActionShareEmail from './ActionShareEmail'
import { useParams, Link } from 'react-router-dom'

export default function ActionAttachments() {
    const { actionId } = useParams()
    const [fileUrl, setFileUrl] = useState(null)
    const [file, setFile] = useState(null)
    const { currentUser } = useAuth()
    const { addActionAttachment, GetActionAttachments, deleteActionAttachment, getAction } = useDb()
    const [error, setError] = useState(null)
    const attachments = GetActionAttachments(actionId)
    const [loading, setLoading] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [action, setAction] = useState(null)
    const [loaded, setLoaded] = useState(false)
    const [emailShareOpen, setEmailShareOpen] = useState(false)
    const [selectedAttachment, setSelectedAttachment] = useState(null)
    const [snackOpen, setSnackOpen] = useState(false)

    const [fileDialogOpen, setFileDialogOpen] = useState(false)

    const getFile = async (fileObj) => {
        setFileUrl(fileObj.url)
        setFile(fileObj.fileName)
        try {
            await addActionAttachment(action.id, fileObj.url, fileObj.fileName)
        }
        catch(err) {
            setError(err.message)
        }
    }

    const getUrl = async (url) => {
        
    }

    const getFilename = (fileName) => {
        
    }

    const deleteFile = async (attachId) => {
        try {
            await deleteActionAttachment(action.id, attachId)
        }
        catch(err) {
            setError(err.message)
        }
    }

    const openFileDialog = (fUrl) => {
        setFileUrl(fUrl)
        setFileDialogOpen(true)
    }

    const getFiles = async (files) => {
        await files.forEach(async f => {
            await getFile(f)
        })
    }

    const shareAction = (a) => {
        setSelectedAttachment(a)
        setEmailShareOpen(true)
    }

    const emailShare = (a) => {
        shareAction(a)
    }

    const copyLink = (a) => {
        const eUrl = encodeURIComponent(a.fileUrl)
        const doubleEncode = encodeURIComponent(eUrl)
        const attachmentUrl = `https://public.isoms.co.za/actions/attachment/${doubleEncode}/${a.fileName}`
        navigator.clipboard.writeText(attachmentUrl)
        setSnackOpen(true)
    }

    const whatsappShare = (a) => {
        const eUrl = encodeURIComponent(a.fileUrl)
        const doubleEncode = encodeURIComponent(eUrl)
        const attachmentUrl = `https://public.isoms.co.za/actions-wa-attachment/${doubleEncode}/`
        const whatsAppUrl = `https://wa.me/?text=${attachmentUrl}`
        window.open(whatsAppUrl)
    }

    const firstLoad = async () => {
        const a = await getAction(actionId)
        setAction(a)
        setLoaded(true)
    }

    if(!loaded) {
        firstLoad()
        return <LinearProgress />
    }
    else return <>
        <h1>Attachments for Action: {action.actionNumber}</h1>
        <Link to={`/action/${actionId}`}>
            <Button disabled={ loading } color="primary" variant="contained" startIcon={<ArrowLeftOutlined />}>Action {action.actionNumber}</Button>
        </Link>
        <Link style={{ float: 'right'}} to={`/action-int-parties/${action.id}/${action.actionNumber}`}>
            <Button startIcon={<People />} endIcon={<ArrowRightOutlined />} color={'primary'} variant={'contained'}>Next: Interested Parties</Button>
        </Link>
        <br/><br/>
        <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
                <Card>
                    <CardHeader
                        title="Files"
                        avatar={
                            <Avatar>
                                <FileCopy />
                            </Avatar>
                        }
                    />
                    <CardContent>
                        <List>
                            {
                                attachments && attachments.map((a) => {
                                    return (
                                        <ListItem button key={a.id}>
                                            <ListItemText onClick={() => openFileDialog(a.fileUrl)}>{a.fileName}</ListItemText>
                                            <ListItemSecondaryAction>
                                                <IconButton onClick={e => setAnchorEl(e.currentTarget)} size="large">
                                                    <FontAwesomeIcon icon={faShareSquare} />
                                                </IconButton>
                                                <Menu
                                                    anchorEl={anchorEl}
                                                    open={Boolean(anchorEl)}
                                                    onClose={() => setAnchorEl(null)}
                                                >
                                                    <MenuItem onClick={() => emailShare(a)}>
                                                        <ListItemIcon>
                                                            <Email color="primary"/>
                                                        </ListItemIcon>
                                                        Email
                                                    </MenuItem>
                                                    <MenuItem onClick={() => copyLink(a)}>
                                                        <ListItemIcon>
                                                            <Link color="primary"/>
                                                        </ListItemIcon>
                                                        Copy Link
                                                    </MenuItem>
                                                    {/* <MenuItem onClick={() => whatsappShare(a)}>
                                                        <ListItemIcon>
                                                            <WhatsApp style={{ color: '#1dd03f' }}/>
                                                        </ListItemIcon>
                                                        Whatsapp
                                                    </MenuItem> */}
                                                </Menu>
                                                <IconButton onClick={() => deleteFile(a.id)} color={'secondary'} size="large"><Delete /></IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    );
                                })
                            }
                            <Snackbar
                                open={snackOpen}
                                autoHideDuration={4000}
                                onClose={() => setSnackOpen(false)}
                                message="Link Copied!"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                            />
                        </List>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
                {
                    fileUrl 
                    ?
                    <FileDialog file={fileUrl} open={fileDialogOpen} closeDialog={() => setFileDialogOpen(false)}/>
                    :
                    null
                }
                
                <BulkFileUploader getFiles={getFiles} userId={currentUser.uid} setBusy={e => setLoading(e)}/><br/>
                {
                    error ? <span className={'iso-error'}>{error}</span> : null
                }
                {
                    selectedAttachment
                    ?
                    <ActionShareEmail attachment={selectedAttachment} open={emailShareOpen} closeDialog={() => setEmailShareOpen(false)} />
                    :
                    null
                }
            </Grid>
        </Grid>
        
        {
            selectedAttachment
            ?
            <ActionShareEmail action={action} attachment={selectedAttachment} open={emailShareOpen} closeDialog={() => setEmailShareOpen(false)} />
            :
            null
        }
        
    </>;
}
 