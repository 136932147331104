import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

export default function OtpManagerMenuItem({ classes, currentUser }) {
    const [otpMenuOpen, setOtpMenuOpen] = useState(false)
    return (
        <>
            <ListItem button onClick={() => setOtpMenuOpen(!otpMenuOpen)}>
                <ListItemIcon><FontAwesomeIcon className={classes.icons} icon={faCalendarCheck} /></ListItemIcon>
                    <ListItemText className={classes.menuText}>OTP Manager</ListItemText>
                { otpMenuOpen ? <ExpandLess className={classes.icon} /> : <ExpandMore className={classes.icon} /> }
            </ListItem>
            <Collapse in={otpMenuOpen} timeout="auto" unmountOnExit>
                <List component="div" className={classes.subMenuItem} dense>
                    <ListItem>
                        <Link to='/otp-manager' className="drawer-item">
                            <ListItemText className={classes.menuText}>OTP Dashboard</ListItemText>
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link to='/otp-manager/register' className="drawer-item">
                            <ListItemText className={classes.menuText}>OTP Register</ListItemText>
                        </Link>
                    </ListItem>
                    {
                        currentUser.companyAdmin || currentUser.accessRights.otpManager <= 0
                        ?
                        <ListItem>
                            <Link to='/otp-manager/criteria' className="drawer-item">
                                <ListItemText className={classes.menuText}>Criteria</ListItemText>
                            </Link>
                        </ListItem>
                        :
                        null
                    }
                    {
                        currentUser.companyAdmin || currentUser.accessRights.otpManager <= 0
                        ?
                        <ListItem>
                            <Link to='/otp-metrics' className="drawer-item">
                                <ListItemText className={classes.menuText}>Metrics</ListItemText>
                            </Link>
                        </ListItem>
                        :
                        null
                    }
                </List>
            </Collapse>
        </>
    )
}
