import { Button, Card, CardContent, Divider, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useAuth } from '../../../contexts/AuthContext'
import { useDb } from '../../../contexts/DatabaseContext'
import Notification from './Notification'

export default function Notifications({ updateCount, closeMenu }) {
    // GetNotifications
    const { GetNotifications, markAllNotificationsRead } = useDb()
    const { currentUser } = useAuth()
    const notifications = GetNotifications(currentUser.uid)

    const [unreadCount, setUnreadCount] = useState(0)
    
    const markAllRead = async () => {
        try {
            await markAllNotificationsRead(currentUser.uid)
            updateCount(0)
        }
        catch(err) {
            console.log(err.message)
        }
    }

    let counter = 0;
    return (
        <Card elevation={0}>
            <CardContent>
                <Typography>Notifications</Typography><br/>
                <Button size="small" variant="contained" onClick={markAllRead} color="primary">Mark all as read</Button>
                <br/><br/>
                <>
                    Unread messages:
                    <Divider />
                </>
                
                {
                    // Map through notifications
                    notifications && notifications.map((n) => {
                        if (!n.read) {
                            counter ++
                            updateCount(counter)
                            return <Notification key={n.id}  notif={n} closeMenu={closeMenu}/>
                        }
                    })
                    
                }
                <br/>
                50 most recent messages:
                <Divider />
                {
                    // Map through notifications
                    notifications && notifications.map((n) => {       
                        return (
                            <Notification key={n.id}  notif={n} closeMenu={closeMenu}/>
                        )
                    })
                }
            </CardContent>
        </Card>
    )
}
