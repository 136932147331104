import React from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import { Avatar, Card, CardContent, CardHeader, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { School, Search } from '@mui/icons-material'
import moment from 'moment'
import { Link } from 'react-router-dom'

export default function FacilUpcomingSessions() {
    const { GetFacilitatorUpcomingSessions } = useDb()
    const sessions = GetFacilitatorUpcomingSessions()
    return (
        <Card>
            <CardHeader
                title="Your Upcoming Sessions"
                avatar={<Avatar>
                    <School />
                </Avatar>}
            />
            <CardContent>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Start Date</TableCell>
                                <TableCell>Start Time</TableCell>
                                <TableCell>Module</TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                sessions && sessions.map(s => (
                                    <TableRow key={s.id}>
                                        <TableCell>{moment(s.startTime.toDate()).format('DD/MM/YYYY')}</TableCell>
                                        <TableCell>{moment(s.startTime.toDate()).format('HH:mm')}</TableCell>
                                        <TableCell>{s.module?.name}</TableCell>
                                        <TableCell align="right">
                                            <Link to={`/training-manager/sessions/facilitator/${s.id}`}>
                                                <IconButton size="small">
                                                    <Search />
                                                </IconButton>
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    )
}
