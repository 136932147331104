import { IconButton } from '@mui/material'
import { Delete, HighlightOffOutlined, Remove } from '@mui/icons-material'
import React from 'react'
import { useDb } from '../../../../../contexts/DatabaseContext'

export default function SelectedSignBlock({ sign, ibraId }) {
    const { removeSignFromIbra } = useDb()

    const handleSignRemove = async () => {
        await removeSignFromIbra(ibraId, sign.id)
    }

    return (
        <div style={{ textAlign: 'center', minWidth: '100%' }}>
            <IconButton
                className="iso-right"
                color="secondary"
                style={{ background: 'white', padding: 5, float: 'right', margin: '-5px -5px 0 0', boxShadow: '0px 0px 5px grey'}}
                onClick={handleSignRemove}
                size="large">
                <HighlightOffOutlined />
            </IconButton>
            <img src={sign.svg ? sign.svg : sign.png } style={{ width: '100%', marginTop: -30 }} />
        </div>
    );
}
