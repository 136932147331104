import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React from 'react'
import EditRiskText from './EditRiskText';
import EditRiskSelect from './EditRiskSelect';
import EditRiskRadio from './EditRiskRadio';

export default function EditRiskPopup({ open, close, type, assessmentId, riskId, assessmentType }) {

    const handleClose = () => {
        close();
    }

    const editTypes = {
        "description": <EditRiskText type="description" close={handleClose} assessmentId={assessmentId} riskId={riskId} assessmentType={assessmentType}/>,
        "cause": <EditRiskText type="cause" close={handleClose} assessmentId={assessmentId} riskId={riskId} assessmentType={assessmentType}/>,
        "type": <EditRiskSelect assessmentId={assessmentId} riskId={riskId} close={handleClose} type={"type"} assessmentType={assessmentType}/>,
        "entity": <EditRiskSelect assessmentId={assessmentId} riskId={riskId} close={handleClose} type={"entity"} assessmentType={assessmentType}/>,
        "pattern": <EditRiskSelect assessmentId={assessmentId} riskId={riskId} close={handleClose} type={"pattern"} assessmentType={assessmentType}/>,
        "hod": <EditRiskSelect assessmentId={assessmentId} riskId={riskId} close={handleClose} type={"hod"} assessmentType={assessmentType}/>,
        "routine": <EditRiskRadio assessmentId={assessmentId} riskId={riskId} close={handleClose} assessmentType={assessmentType}/>,
    }
    
    return (
        <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
            <DialogTitle>Edit {type}</DialogTitle>
            <DialogContent>
                {editTypes[type]}
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    onClick={handleClose}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}
