import { faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function MasterListMenuItem({ classes, currentUser }) {
  const [masterListOpen, setMasterOpen] = useState(false);
  return (
    <div>
      <ListItem button onClick={() => setMasterOpen(!masterListOpen)}>
        <ListItemIcon>
          <FontAwesomeIcon className={classes.icons} icon={faList} />
        </ListItemIcon>

        <ListItemText className={classes.menuText}>Master List</ListItemText>

        {masterListOpen ? (
          <ExpandLess className={classes.icon} />
        ) : (
          <ExpandMore className={classes.icon} />
        )}
      </ListItem>

      <Collapse in={masterListOpen} timeout="auto" unmountOnExit>
        <List component="div" className={classes.subMenuItem} dense>
          {currentUser.companyAdmin ? (
            <>
              <ListItem>
                <Link to="/master-list/users" className="drawer-item">
                  <ListItemText className={classes.menuText}>
                    Users
                  </ListItemText>
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/master-list/iso-classes" className="drawer-item">
                  <ListItemText className={classes.menuText}>
                    ISO Classifications
                  </ListItemText>
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/master-list/iso-agencies" className="drawer-item">
                  <ListItemText className={classes.menuText}>
                    ISO Agencies
                  </ListItemText>
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/company-settings" className="drawer-item">
                  <ListItemText className={classes.menuText}>
                    Company Settings
                  </ListItemText>
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/audit-trail" className="drawer-item">
                  <ListItemText className={classes.menuText}>
                    Audit Trail
                  </ListItemText>
                </Link>
              </ListItem>
              {/* <ListItem>
                                <Link to='/notification-log' className="drawer-item">
                                    <ListItemText className={classes.menuText}>Notification Log</ListItemText>
                                </Link>
                            </ListItem> */}
            </>
          ) : null}

          <ListItem>
            <Link to="/master-list/departments" className="drawer-item">
              <ListItemText className={classes.menuText}>
                Departments
              </ListItemText>
            </Link>
          </ListItem>
          <ListItem>
            <Link to="/master-list/rosters" className="drawer-item">
              <ListItemText className={classes.menuText}>Rosters</ListItemText>
            </Link>
          </ListItem>
          {/* <ListItem>
                        <Link to='/master-list/employees'className="drawer-item">
                            <ListItemText className={classes.menuText}>Employees</ListItemText>
                        </Link>
                    </ListItem> */}
          <ListItem>
            <Link to="/master-list/groups" className="drawer-item">
              <ListItemText className={classes.menuText}>Groups</ListItemText>
            </Link>
          </ListItem>
          <ListItem>
            <Link to="/master-list/sites" className="drawer-item">
              <ListItemText className={classes.menuText}>Sites</ListItemText>
            </Link>
          </ListItem>
        </List>
      </Collapse>
    </div>
  );
}
