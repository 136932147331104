import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { DeleteForever, Warning } from '@mui/icons-material'
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'

export default function ConfirmRosterDelete({ roster, open, closeDialog, deleteComplete }) {
    const { deleteUser } = useDb()

    // Action State
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const handleClose = () => {
        setLoading(false)
        setError(null)
        closeDialog()
    }

    const handleConfirm = async () => {
        setError(null)
        setLoading(true)
        try {
            await deleteUser(roster.uid).then(() => {
                handleClose()
                deleteComplete()
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                <Warning color="secondary"/> Delete Roster: {roster.firstName} {roster.lastName} ? 
            </DialogTitle>
            <DialogContent>
                Deleting this roster will remove the person from the following items: <br/>
                <ul>
                    <li>Rosters</li>
                    <li>Users (If User)</li>
                    <li>Groups</li>
                    <li>Document Approvals</li>
                    <li>Document Interested Parties</li>
                    <li>Action Interested Parties</li>
                </ul>
                <br/>
                The roster audit trail will remain in place for historical data, as well as any history of actions taken on any system item.

                <br/><br/>
                <Typography><b>Are you sure you want to Delete this Roster?</b></Typography>

            </DialogContent>
            <DialogActions>
                { error ? <span className="iso-error">{error}</span> : null }
                <Button disabled={loading} color="secondary" onClick={handleClose}>Cancel</Button>
                <Button disabled={loading} color="secondary" variant="contained" startIcon={<DeleteForever />} onClick={handleConfirm}>Confirm Delete</Button>
            </DialogActions>
        </Dialog>
    )
}
