import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material'
import React, { useState } from 'react'
import { textFieldProps } from '../../../shared/constants'
import { useDb } from '../../../../contexts/DatabaseContext'

export default function EditMeasurement({open, close, otpId, targetId, programId, measurementId, measurement}) {

  const [state, setState] = useState(null)
  const [loading, setLoading] = useState(false)
  const { editProgramMeasurement } = useDb()

  const handleClose = () => {
    setState(null)
    close()
  }

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const handleSave = async (e) => {
    e.preventDefault()
    setLoading(true)
    await editProgramMeasurement(otpId, targetId, programId, measurementId, state)
    setLoading(false)
    close()
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSave}>
      <DialogContent>
        <TextField 
          {...textFieldProps}
          label={measurement}
          onChange={handleChange}
          name="measurementData"
          type='number'
          required
        />
        <TextField 
          {...textFieldProps}
          label="Comment"
          onChange={handleChange}
          name="comment"
          multiline
          rows={4}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={ loading || state && !state.measurementData } variant='contained' color='primary' type='submit'>Save</Button>
        <Button disabled={loading} variant='outlined' color='secondary' onClick={handleClose}>Cancel</Button>
      </DialogActions>
      </form>
    </Dialog>
  )
}
