import { Container } from "@mui/material";
import React from "react";
import { useAuth } from "../../../contexts/AuthContext";

export default function MocDashboard() {
  const { currentUser } = useAuth();
  var params = {
    "ds9.company": currentUser.companyId,
  };
  var paramsAsString = JSON.stringify(params);
  var encodedParams = encodeURIComponent(paramsAsString);
  return (
    <div>
      <h1>MOC Dashboard</h1>
      <Container>
        <iframe
          title="MOC Report"
          width="100%"
          height="1200"
          frameBorder="0"
          src={`https://lookerstudio.google.com/embed/reporting/5da046f9-8087-4e76-a6ff-5844e99bba28/page/LqgcD?params=${encodedParams}`}
        ></iframe>
      </Container>
    </div>
  );
}
