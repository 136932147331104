import { Dialog, DialogContent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import AddDocument from '../../doc-register/AddDocument';

const useStyles = makeStyles((theme) => ({
    dialog: {
      backgroundColor: theme.palette.dialogBg,
    },
}));

export default function CreateDocument({ open, close}) {
    const classes = useStyles()

    const handleClose = () => {
        close()
    }

    return (
        <Dialog
            maxWidth="sm"
            fullWidth
            open={open}
            onClose={handleClose}
        >
            <DialogContent className={classes.dialog}>
                <AddDocument closeDialog={handleClose} />
            </DialogContent>
        </Dialog>
    )
}
