import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material'
import { Save } from '@mui/icons-material'
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import { textFieldProps } from '../../shared/constants'

export default function AddCompany({ open, closeDialog }) {
    const [companyName, setCompanyName] = useState()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const { addCompany } = useDb()

    const handleSubmit = async () => {
        setLoading(true)
        setError(null)
        try {
            await addCompany(companyName).then(() => {
                setCompanyName(null)
                setLoading(false)
                closeDialog()
            })
        }
        catch(err) {
            setError(err.message)
        }
    }

    return (
        <Dialog open={open} onClose={closeDialog} maxWidth={'sm'} fullWidth>
            <DialogContent>
                <TextField
                    {...textFieldProps}
                    label={'Company Name'}
                    onChange={(e) => setCompanyName(e.target.value)}
                />
                {
                    error
                    ?
                    <span className={'iso-error'}>{error}</span>
                    :
                    null
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit} disabled={loading || !companyName } color={'primary'} variant={'contained'} startIcon={<Save />}>Submit</Button>
            </DialogActions>
        </Dialog>
    )
}
