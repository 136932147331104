import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';
import { Save } from '@mui/icons-material';
import React from 'react';
import { useState } from 'react';
import { useDb } from '../../../contexts/DatabaseContext';

export default function CreateFolder({ open, handleClose }) {
    const [folderName, setFoldername] = useState('');
    const { createSharedFolder } = useDb()

    const handeleSubmit = () => {
        createSharedFolder({name: folderName})
        handleClose();
    }

    return (
        <Dialog
            maxWidth="sm"
            fullWidth
            open={open}
            onClose={handleClose}
        >
            <DialogContent>
                <h1>Create Shared Folder</h1>
                <TextField label='Name' variant='outlined' fullWidth onChange={(e) => {setFoldername(e.target.value)}}/>
            </DialogContent>
            <DialogActions>
                <Button disabled={!folderName} startIcon={<Save />} variant='contained' color='primary' onClick={handeleSubmit}>Save</Button>
                <Button color='secondary' onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}
