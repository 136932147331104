import { Tab, Tabs } from '@mui/material'
import React from 'react'

export default function LTabs({ setTab, classes, a11yProps, setLFilter, value, handleL }) {
    
    const handleClick = (lArray, category) => {
        setLFilter(lArray)
        handleL({
            key: 'category',
            data: {
                fieldName: 'category',
                value: category
            }
        })
    }

    const handleAll = (lArray) => {
        setLFilter(lArray)
        handleL({
            key: 'category',
            data: {
                fieldName: 'category',
                value: null
            }
        })
    }

    

    return (
        <Tabs
            value={value}
            onChange={(e, v) => setTab(v)}
            className={classes.tab}
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            scrollButtons
            allowScrollButtonsMobile>
            <Tab
                onClick={() => handleAll([0, ""])}
                label="All"
                {...a11yProps(0)}
                
            />
            <Tab
                onClick={() => handleClick([1, "L1"], "L1")}
                label="L1"
                {...a11yProps(1)}
            />
            <Tab
                onClick={() => handleClick([2, "L2"], "L2")}
                label="L2"
                {...a11yProps(2)}
            />
            <Tab
                onClick={() => handleClick([3, "L3"], "L3")}
                label="L3"
                {...a11yProps(3)}
            />
            <Tab
                onClick={() => handleClick([4, "L4"], "L4")}
                label="L4"
                {...a11yProps(4)}
            />
            <Tab
                onClick={() => handleClick([5, "L5"], "L5")}
                label="L5"
                {...a11yProps(5)}
            />
        </Tabs>
    );
}
