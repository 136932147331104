import { Button, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { Save } from '@mui/icons-material';
import React from 'react'
import { useState } from 'react'
import { useDb } from '../../../../contexts/DatabaseContext'

export default function EditRiskRadio({ assessmentId, riskId, close, assessmentType }) {
    const { updateBlraRisk, updateIbraRisk } = useDb();

    const [state, setState] = useState({
        loading: false,
        error: null,
        data: {}
    })

    const handleClose = () => {
        setState({
            loading: false,
            error: null,
            data: {}
        })
        close();
    }

    const handleRadio = (e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                routine: e.target.value
            }
        })
    }

    const handleSubmit = async () => {
        setState({
            ...state,
            loading: true,
            error: null
        });
        try {
            if(assessmentType === "blra") {
                await updateBlraRisk(assessmentId, riskId, state.data);
            }
            if(assessmentType === "ibra") {
                await updateIbraRisk(assessmentId, riskId, state.data);
            }
            handleClose();
        }
        catch(err) {
            setState({
                ...state,
                loading: false,
                error: err.message
            })
        }
    }
    
    return (
        <div>
            <RadioGroup name="routine" value={state.data.routine} onChange={handleRadio}>
                <FormControlLabel value="routine" control={<Radio />} label="Routine Task" />
                <FormControlLabel value="non-routine" control={<Radio />} label="Non-Routine Task" />
            </RadioGroup>

            <Button
                startIcon={<Save />}
                color="primary"
                variant="contained"
                style={{ float: "right" }}
                onClick={handleSubmit}
                disabled={state.loading}
            >
                Save
            </Button>
        </div>
    )
}
