import React, { useEffect, useState } from "react";
import { useDb } from "../../contexts/DatabaseContext";
import RegisterViewer from "../shared/RegisterViewer";
import CreateAction from "./CreateAction";
import { amber } from "@mui/material/colors";
import makeStyles from '@mui/styles/makeStyles';
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import Filters from "../docmanager/doc-register/filters/Filters";
import { isEmpty } from "lodash";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    width: 500,
    position: "relative",
    minHeight: 200,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(6),
    right: theme.spacing(7),
    backgroundColor: amber[500],
    "&:hover": {
      backgroundColor: amber[600],
    },
  },
  tab: {
    indicatorColor: theme.palette.primary,
  },
  icon: {
    color: theme.palette.primary,
  },
  tabPanel: {
    backgroundColor: theme.palette.main,
  },
  dialog: {
    backgroundColor: theme.palette.dialogBg,
  },
  search: {
    color: theme.palette.isotext,
  },
}));

export default function ActionRegister() {
  const [createOpen, setCreateOpen] = useState(false);
  const { GetActions, createActionsReportPdf } = useDb();
  const [dialogOpen, setDialog] = useState(false);
  const filterObj = {
    department: {},
    site: {},
    source: {},
    isoClass: {},
    isoAgency: {},
    open: {},
  };

  const [filters, setFilters] = useState(filterObj);
  const actions = GetActions();
  const classes = useStyles();
  const [data, setData] = useState(actions);

  const generateRegisterPdf = async () => {
    await createActionsReportPdf(data);
  };
  // Switch Filters
  const [overdue, setOverdue] = useState(false);
  const [due30, setDue30] = useState(false);

  const openCreate = () => {
    setCreateOpen(true);
  };

  const closeCreate = () => {
    setCreateOpen(false);
  };

  const receiveFilters = (f) => {
    setDialog(false);
    setFilters(f);
  };

  useEffect(() => {
    setData(actions);
  }, [actions]);

  useEffect(() => {
    setData(actions.filter((item) => filterByDropdowns(item, filters)));
  }, [filters, overdue, due30]);

  useEffect(() => {
    console.log("action register filtered data is: ", data);
  }, [data]);

  const handleChipDelete = (f) => {
    setFilters({ ...filters, [f]: {} });
  };

  const filterByDropdowns = (doc, f) => {
    if (overdue && (!doc.open || !(doc.targetDate.toDate() < Date.now()))) {
      return null;
    }
    let dayDiff = moment(Date.now())
      .add(30, "days")
      .diff(moment(doc.targetDate.toDate()), "days");
    if (due30 && (!doc.open || !(dayDiff > 0 && dayDiff <= 30))) {
      return null;
    }
    if (
      isEmpty(f.department) &&
      isEmpty(f.site) &&
      isEmpty(f.source) &&
      isEmpty(f.isoClass) &&
      isEmpty(f.isoAgency) &&
      isEmpty(f.open)
    )
      return true;
    let dpt = f.department?.label
      ? doc["department"].name === f.department?.label
      : true;
    let site = f.site?.label ? doc["site"].label === f.site?.label : true;
    let source = f.source?.label
      ? doc["source"].sourceName === f.source?.label
      : true;
    let isoClass = f.isoClass?.label
      ? doc["isoClass"] === f.isoClass?.label
      : true;
    let isoAgency = f.isoAgency?.label
      ? doc["isoAgency"].isoAgency === f.isoAgency?.label
      : true;
    let isOpen = !isEmpty(f.open) ? doc["open"] === f.open?.value : true;
    return dpt && site && source && isoClass && isOpen && isoAgency;
  };

  return (
    <>
      <RegisterViewer
        addItemFunction={openCreate}
        type={"action"}
        data={data}
        pdfFunction={generateRegisterPdf}
        openDialog={setDialog}
        handleChipDelete={handleChipDelete}
        filterItems={filters}
        overdue={overdue}
        due30={due30}
        setDue30={setDue30}
        setOverdue={setOverdue}
      />
      <CreateAction open={createOpen} closeDialog={closeCreate} />
      <Dialog
        maxWidth="sm"
        fullWidth
        open={dialogOpen}
        onClose={() => setDialog(false)}
      >
        <DialogTitle className={classes.dialog}>Filters</DialogTitle>
        <DialogContent className={classes.dialog}>
          <Filters
            sendFilters={receiveFilters}
            selectedFilter={filters}
            isActionFilters={true}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
