import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from "@mui/material";
import { Save } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import Select from "react-select";
import { selectFieldProps } from "../../shared/constants";

export default function ChangeProvider({ open, close, module }) {
  const { GetTrainingProviders, updateTrainingModule } = useDb();
  const providers = GetTrainingProviders();
  const [external, setExternal] = useState(false);
  const [provider, setProvider] = useState();
  const [selectedProvider, setSelectedProvider] = useState();
  const [loading, setLoading] = useState(false);

  const toggleExternal = () => {
    setExternal(!external);
  };

  const handleClose = () => {
    setLoading(false);
    close();
  };

  const handleSelect = (val) => {
    setSelectedProvider({
      name: val.value.name,
      id: val.value.id,
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await updateTrainingModule(module.id, {
        external,
        provider: selectedProvider || " ",
      });
      handleClose();
    } catch (err) {
      window.alert(err.message);
      setLoading(false);
    }
  };

  // Determine the existing state of the module and set the existing values such as external
  useEffect(() => {
    setExternal(module.external || false);
    if (module.provider) {
      setProvider({ value: module.provider, label: module.provider.name });
      setSelectedProvider({
        name: module.provider.name,
        id: module.provider.id,
      });
    }
    return;
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Edit Provider</DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={
            <Checkbox
              checked={external}
              onChange={toggleExternal}
              name="external"
              color="primary"
            />
          }
          label="External Training Provider?"
        />
        {external ? (
          <>
            <Select
              options={providers}
              placeholder="Select External Provider ..."
              onChange={handleSelect}
              defaultValue={provider}
              {...selectFieldProps}
              className="iso-select"
              name="provider"
            />
          </>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          startIcon={<Save />}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={loading}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
