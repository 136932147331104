import { MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react'
import DeleteConfirm from '../../shared/DeleteConfirm'
import EditDocument from '../doc-register/EditDocument'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    dropDown: {
        backgroundColor: theme.palette.menuBg
    }
}))

export default function PendingDropDown({ doc, openDialog, closeDialog }) {
    const classes = useStyles()

    const [deleteOpen, setDeleteOpen] = useState(false)


    return (
        <div className={classes.dropDown}>
            <Link to={`/doc-manager/doc-detail/${doc.id}`}>
                <MenuItem>Detail</MenuItem>
            </Link>
            <MenuItem onClick={() => openDialog(<EditDocument doc={doc} closeDialog={closeDialog}/>)}>Edit</MenuItem>
            <a target="_blank" href={doc.docUrl}><MenuItem>Download</MenuItem></a>
            
            <Link to={`/doc-approvers/${doc.id}`}>
                <MenuItem>Approvers</MenuItem>
            </Link>
            <Link to={`/doc-supdocs/${doc.id}`}>
                <MenuItem>Documents Submitted</MenuItem> 
            </Link>
            <MenuItem onClick={() => setDeleteOpen(true)}>Make Obsolete</MenuItem>
            <DeleteConfirm collection={'documents'} docId={doc.id} open={deleteOpen} closeDialog={() => setDeleteOpen(false)} />
        </div>
    )
}
