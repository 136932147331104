export const riskQuestions = [
  {
    title: "Does the change affect production?",
    order: 1,
  },
  {
    title: "Does the change affect the building structure?",
    order: 2,
  },
  {
    title:
      "Is a piece of equipment that is operating, or used to operate, being removed?",
    order: 3,
  },
  {
    title: "Is a new piece of equipment being added to the plant?",
    order: 4,
  },
  {
    title:
      "Will the modification introduce new chemicals or raw materials into the plant?",
    order: 5,
  },
  {
    title: "Will the process be changed in any way?",
    order: 6,
  },
];
