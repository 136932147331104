import { Button, Typography } from '@mui/material';
import { Save } from '@mui/icons-material';
import React, { useState } from 'react'
import Select from 'react-select'
import { useDb } from '../../../../contexts/DatabaseContext'
import { selectFieldProps } from '../../../shared/constants'

export default function EditRiskSelect({ assessmentId, riskId, close, type, assessmentType }) {
    const { GetRiskTypes, updateBlraRisk, GetRiskEntities, GetRiskPatterns, GetUsersLevelFilter, updateIbraRisk } = useDb();
    const dbTypes = {
        "type": GetRiskTypes(),
        "entity": GetRiskEntities(),
        "pattern": GetRiskPatterns(),
        "hod": GetUsersLevelFilter("riskManager", 2)
    }

    const [state, setState] = useState({
        loading: false,
        error: null,
        data: {}
    });

    const handleClose = () => {
        setState({
            loading: false,
            error: null,
            data: {}
        });
        close();
    }

    const handleSelect = ((v, e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.name]: v.value.id
            }
        })
    });

    const handleSubmit = async () => {
        setState({
            ...state,
            loading: true,
            error: null
        });
        try {
            if(assessmentType === "blra") {
                await updateBlraRisk(assessmentId, riskId, state.data);
            }
            if(assessmentType === "ibra") {
                await updateIbraRisk(assessmentId, riskId, state.data);
            }
            handleClose();
        }
        catch(err) {
            setState({
                ...state,
                loading: false,
                error: err.message
            })
        }
    }
    
    return (
        <div>
            <Select
                options={dbTypes[type]}
                onChange={handleSelect}
                {...selectFieldProps}
                placeholder={`Select New ${type} ...`}
                name={type}
                className="iso-select"
            />
            {
                state.error && <Typography color="secondary">{state.error}</Typography>
            }
            <Button
                startIcon={<Save />}
                color="primary"
                variant="contained"
                style={{ float: "right" }}
                onClick={handleSubmit}
                disabled={state.loading}
            >
                Save
            </Button>
        </div>
    )
}
