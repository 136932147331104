import { Avatar, Card, CardContent, CardHeader, IconButton, Typography } from '@mui/material'
import { School, Search } from '@mui/icons-material'
import React from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid'
import { Link } from 'react-router-dom'
import moment from 'moment'

export default function UpcomingSessionsDashboard() {
    const { GetUpcomingTrainingSessions } = useDb()
    const sessions = GetUpcomingTrainingSessions()

    const columns = [
        { field: 'date', headerName: 'Date' , width: 200 },
        { field: 'module', headerName: 'Module', width: 200 },
        { field: 'facilitator', headerName: 'Facilitator', width: 200 },
        { 
            field: 'action', 
            headerName: 'Action', 
            width: 140,
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                        <Link to={`/training-manager/sessions/detail/${params.row.id}`}>
                            <IconButton size="large">
                                <Search />
                            </IconButton>
                        </Link>
                     </div>
                );
            }
        }
    ]

    const rows = sessions && sessions.map(s  => ({
        id: s.id,
        date: moment(s.startTime.toDate()).format('DD/MM/YYYY'),
        module: s.module.name,
        facilitator: s.facilitator.label
    }))

    return (
        <Card>
            <CardHeader
                title="Upcoming Sessions"
                avatar={<Avatar>
                    <School />
                </Avatar>}
            />
            <CardContent>
                <IsoDataGrid
                    columns={columns}
                    data={rows}
                />
            </CardContent>
            
        </Card>
    )
}
