import { IconButton } from '@mui/material'
import { Restore } from '@mui/icons-material'
import React from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid'

export default function ActionArchive() {
    const { GetArchivedActions, restoreAction } = useDb()
    const actions = GetArchivedActions()

    const rows = actions && actions.map(a => ({
        id: a.id,
        actionNumber: a.actionNumber,
        department: a.department.name,
        hod: `${a.hod.firstName} ${a.hod.lastName}`
    }))

    const columns = [
        { field: "actionNumber", headerName: "Action", width: 180 },
        { field: "department", headerName: "Department", width: 180 },
        { field: "hod", headerName: "HOD", width: 180 },
        { 
            field: 'restore', 
            headerName: 'Restore', 
            width: 200,
            renderCell: (params) => {
                return (
                    <IconButton onClick={() => restoreAction(params.row.id)} size="large">
                        <Restore />
                    </IconButton>
                );
            }
        },
    ]

    return (
        <div>
            <h1>Archived Actions</h1>
            <IsoDataGrid
                data={rows}
                columns={columns}
            />
        </div>
    )
}
