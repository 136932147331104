import React from "react";
import SetupWizard from "./SetupWizard";
import { Container } from "@mui/material";
import { Alert } from '@mui/material';
import { useAuth } from "../../../contexts/AuthContext";

export default function MocSetup() {
  const { currentUser } = useAuth();
  return (
    <Container>
      <h1>MOC Setup</h1>
      <Alert severity="info">
        Welcome to MOC Manager. In order to start using the MOC Manager, you
        will need to complete the setup steps show below. We'll guide on each
        step.
      </Alert>
      <SetupWizard setupStep={currentUser.mocSetupStep || 0} />
    </Container>
  );
}
