import React from "react";
import { Card, CardContent, CardHeader } from "@mui/material";

export default function EditMocChecklist() {
  return (
    <div>
      <Card>
        <CardHeader title="Edit MOC Checklist" />
        <CardContent>This had been moved</CardContent>
      </Card>
    </div>
  );
}
