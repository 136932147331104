import { Dialog, DialogActions, DialogContent, TextField, Button, DialogTitle } from '@mui/material'
import { Send } from '@mui/icons-material'
import React, { useState } from 'react'
import { textFieldProps } from '../shared/constants'
import { useHistory } from 'react-router-dom'
import { useDb } from '../../contexts/DatabaseContext'

export default function SupportDialog({ open, closeDialog }) {
    const history = useHistory()
    const [state, setState] = useState({})
    const { submitSupportRequest } = useDb()

    // Action state
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleClose = () => {
        setState({})
        setLoading(false)
        setError(null)
        closeDialog()
        
    }

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        setError(null)
        try {
            await submitSupportRequest(state).then(() => {
                handleClose()
                history.push('/support-thanks')
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <form onSubmit={handleSubmit}>
                <DialogTitle>
                    Submit a Support Request
                </DialogTitle>
                <DialogContent>
                    <TextField
                        {...textFieldProps}
                        name="clientName"
                        label="Your Name"
                        onChange={handleChange}
                        required
                    />
                    <TextField
                        {...textFieldProps}
                        name="clientPhone"
                        label="Your Phone Number"
                        onChange={handleChange}
                        required
                        type="number"
                    />
                    <TextField
                        {...textFieldProps}
                        name="message"
                        label="Describe the problem you are having."
                        onChange={handleChange}
                        required
                        multiline
                        rows={3}
                    />
                </DialogContent>
                <DialogActions>
                    { error ? <span className="iso-error">{error}</span> : null }
                    <Button disabled={ loading } color="secondary" onClick={handleClose}>Cancel</Button>
                    <Button disabled={ loading } color="primary" type="submit" variant="contained" startIcon={<Send />}>Send Message</Button>
                </DialogActions>

            </form>
        </Dialog>
    )
}
