import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { Info, Save } from "@mui/icons-material";
import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDb } from "../../../../../contexts/DatabaseContext";
import { selectFieldProps, textFieldProps } from "../../../../shared/constants";
import Select from "react-select";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function CoCreateAction() {
  const { coId } = useParams();
  const history = useHistory();
  const {
    MgmtGetCoDepartments,
    MgmtGetRosters,
    MgmtGetIsoAgencies,
    MgmtGetIsoClasses,
    MgmtGetPriorities,
    MgmtGetSites,
    MgmtGetSources,
    mgmtCreateAction,
  } = useDb();
  const departments = MgmtGetCoDepartments(coId);
  const rosters = MgmtGetRosters(coId);
  const agencies = MgmtGetIsoAgencies(coId);
  const isoClasses = MgmtGetIsoClasses(coId);
  const priorities = MgmtGetPriorities(coId);
  const sites = MgmtGetSites(coId);
  const sources = MgmtGetSources(coId);

  const [state, setState] = useState({
    open: true,
    reviewBaseline: false,
  });

  // Action State
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      await mgmtCreateAction(coId, state).then((doc) => {
        history.push(`/companies/${coId}/action-intparties/${doc.id}`);
      });
    } catch (err) {
      setError(err.message);
      console.log(err.message);
    }
  };

  return (
    <div>
      <Card>
        <CardHeader
          title="Manual Action Creation"
          avatar={
            <Avatar>
              <Info />
            </Avatar>
          }
        />
        <form onSubmit={handleSubmit}>
          <CardContent>
            <TextField
              required
              {...textFieldProps}
              label="Action Number / Code"
              name="actionNumber"
              onChange={handleChange}
            />
            <TextField
              required
              {...textFieldProps}
              label="Year"
              name="year"
              onChange={handleChange}
              type="number"
            />
            <Select
              options={departments}
              {...selectFieldProps}
              className="iso-select"
              placeholder="Department"
              onChange={(val) => setState({ ...state, department: val.value })}
            />
            <TextField
              required
              {...textFieldProps}
              label="Description"
              name="description"
              onChange={handleChange}
              multiline
              rows={2}
            />
            <Select
              options={rosters}
              {...selectFieldProps}
              className="iso-select"
              placeholder="HOD"
              onChange={(val) => setState({ ...state, hod: val.value })}
            />
            <TextField
              required
              {...textFieldProps}
              label="Immediate Action"
              name="immAction"
              onChange={handleChange}
              multiline
              rows={2}
            />
            <Select
              options={isoClasses}
              {...selectFieldProps}
              className="iso-select"
              placeholder="ISO Classification"
              onChange={(val) =>
                setState({
                  ...state,
                  isoClass: `${val.value.standard} ${val.value.code}`,
                })
              }
              // onChange={val => console.log(val)}
            />
            <Select
              options={agencies}
              {...selectFieldProps}
              className="iso-select"
              placeholder="ISO Agency"
              onChange={(val) => setState({ ...state, isoAgency: val.value })}
              // onChange={val => console.log(val)}
            />

            <Select
              options={rosters}
              {...selectFieldProps}
              className="iso-select"
              placeholder="Originator"
              onChange={(val) => setState({ ...state, originator: val.value })}
            />
            <Select
              options={priorities}
              {...selectFieldProps}
              className="iso-select"
              placeholder="Priority"
              onChange={(val) => setState({ ...state, priority: val.value })}
            />
            <Select
              options={sites}
              {...selectFieldProps}
              className="iso-select"
              placeholder="Site"
              onChange={(val) => setState({ ...state, site: val.value })}
            />
            <Select
              options={sources}
              {...selectFieldProps}
              className="iso-select"
              placeholder="Source"
              onChange={(val) => setState({ ...state, source: val.value })}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Target Date"
                onChange={(d) => setState({ ...state, targetDate: d })}
                defaultValue={dayjs(state.targetDate)}
              />
            </LocalizationProvider>
            <br />
            <br />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Created Date"
                onChange={(d) => setState({ ...state, timestamp: d })}
                defaultValue={dayjs(state.timestamp)}
              />
            </LocalizationProvider>
            <br />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.open}
                  color="primary"
                  onChange={(e) =>
                    setState({ ...state, open: e.target.checked })
                  }
                />
              }
              label="Open"
            />
            {!state.open ? (
              <div>
                <TextField
                  required
                  {...textFieldProps}
                  className={"iso-text-field"}
                  label={"Root Cause"}
                  multiline
                  rows={3}
                  onChange={(e) =>
                    setState({ ...state, rootCause: e.target.value })
                  }
                />
                <TextField
                  required
                  {...textFieldProps}
                  className={"iso-text-field"}
                  label={"Preventive Measures"}
                  multiline
                  rows={3}
                  onChange={(e) =>
                    setState({ ...state, preventive: e.target.value })
                  }
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.reviewBaseline}
                      onChange={(e) =>
                        setState({ ...state, reviewBaseline: e.target.checked })
                      }
                      color={"primary"}
                    />
                  }
                  label={"Review Baseline Risk"}
                />
                {state.reviewBaseline ? (
                  <TextField
                    required
                    label={"Review Baseline Risk"}
                    {...textFieldProps}
                    onChange={(e) =>
                      setState({ ...state, baselineText: e.target.value })
                    }
                  />
                ) : null}
                <br />
                <br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Close Out Date"
                    onChange={(d) => setState({ ...state, closeOutDate: d })}
                    defaultValue={dayjs(state.closeOutDate)}
                  />
                </LocalizationProvider>
              </div>
            ) : null}
          </CardContent>
          <CardActions>
            {error ? <span className="iso-error">{error}</span> : null}
            <Button
              disabled={
                loading ||
                !state.site ||
                !state.source ||
                !state.department ||
                !state.hod ||
                !state.originator ||
                !state.priority ||
                !state.isoClass ||
                !state.isoAgency ||
                !state.targetDate ||
                !state.timestamp
              }
              fullWidth
              color="primary"
              type="submit"
              variant="contained"
              startIcon={<Save />}
            >
              Submit
            </Button>
          </CardActions>
        </form>
      </Card>
    </div>
  );
}
