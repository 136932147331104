import React from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid'

export default function Suppliers() {
    const { GetSuppliers } = useDb()
    const suppliers = GetSuppliers()

    const rows = suppliers && suppliers.map(s => ({
        id: s.id,
        companyName: s.companyName,
        contactPerson: `${s.firstName} ${s.lastName}`,
        vendorCode: s.vendorCode
    }))

    const columns = [
        { field: 'vendorCode', headerName: 'Vendor Code', width: 180 },
        { field: 'companyName', headerName: 'Supplier', width: 300 },
        { field: 'contactPerson', headerName: 'Contact Person', width: 300 },
        // TODO: Add Detail Link
    ]
    
    return (
        <div>
            <IsoDataGrid
                data={rows}
                columns={columns}
            />
        </div>
    )
}
