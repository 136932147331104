import { Grid } from "@mui/material";
import React from "react";
import { componentList } from "./fieldTypes";
import CatalogItem from "./CatalogItem";

export default function ComponentCatalog({ handlePick }) {
  return (
    <div>
      <Grid container spacing={3}>
        {componentList.map((comp, index) => (
          <Grid key={index} item xs={4}>
            <CatalogItem comp={comp} handlePick={handlePick} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
