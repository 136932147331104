import { faPortrait } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import ChangeProfilePicture from './ChangeProfilePicture'

export default function EditProfile({ open, closeDialog, profile }) {
    const [photoOpen, setPhotoOpen] = useState(false)

    return (
        <Dialog open={open} onClose={closeDialog} maxWidth={'sm'} fullWidth>
            <DialogTitle>View / Edit Profile</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Avatar 
                            src={profile.profilePhoto} 
                            style={{
                                width:150,
                                height: 150
                            }}
                        />
                        <Button onClick={() => setPhotoOpen(true)} startIcon={<FontAwesomeIcon icon={faPortrait} />} color={'primary'}>Change Photo</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography>{profile.firstName} {profile.lastName}</Typography>
                        <Typography>{profile.email}</Typography>
                        <Typography>{profile.companyName}</Typography>
                    </Grid>
                </Grid>

                <ChangeProfilePicture pOpen={photoOpen} pCloseDialog={() => setPhotoOpen(false)} profile={profile} />
            </DialogContent>
        </Dialog>
    )
}
