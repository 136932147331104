import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
} from "@mui/material";
import {
  AddBox,
  ArrowLeft,
  Edit,
  Info,
  People,
  PictureAsPdf,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDb } from "../../../contexts/DatabaseContext";
import InviteAttendees from "./InviteAttendees";
import moment from "moment";
import ChangeFacilitator from "./ChangeFacilitator";
import { useAuth } from "../../../contexts/AuthContext";

export default function SessionDetail() {
  const { getTrainingSession, GetAttendees, createAttendanceRegister } =
    useDb();
  const { id } = useParams();
  const attendees = GetAttendees(id);
  const history = useHistory();
  const [compState, setCompState] = useState({
    loading: false,
    pageLoaded: false,
    error: null,
  });
  const [inviteOpen, setInviteOpen] = useState(false);
  const [session, setSession] = useState(null);
  const [editFacilOpen, setEditFacilOpen] = useState(false);
  const { currentUser } = useAuth();

  const firstLoad = async () => {
    const s = await getTrainingSession(id);
    setSession(s);
    setCompState({ ...compState, pageLoaded: true });
  };

  useEffect(() => {
    const unsubscribe = firstLoad();
    return unsubscribe;
  }, [editFacilOpen]);

  const handlePdf = async () => {
    setCompState({
      ...compState,
      loading: true,
      error: null,
    });
    await createAttendanceRegister(session);
    setCompState({
      ...compState,
      loading: false,
      error: null,
    });
  };

  const toggleEditFacil = () => {
    setEditFacilOpen(!editFacilOpen);
  };

  if (!compState.pageLoaded) {
    firstLoad();
    return <LinearProgress />;
  } else
    return (
      <div>
        <h1>Training Session: {session.module.name}</h1>
        <Button
          onClick={() => history.push("/training-manager/sessions")}
          color="primary"
          variant="contained"
          startIcon={<ArrowLeft />}
        >
          Sessions
        </Button>{" "}
        &nbsp;
        {session.startTime.toDate() < new Date() ? (
          <Button
            disabled={compState.loading}
            onClick={handlePdf}
            color="primary"
            variant="contained"
            startIcon={<PictureAsPdf />}
          >
            Attendance Register
          </Button>
        ) : null}
        <br />
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12} md={7}>
            <Card>
              <CardHeader
                title="Attendees"
                avatar={
                  <Avatar>
                    <People />
                  </Avatar>
                }
              />
              <CardContent>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>First Name</TableCell>
                        <TableCell>Last Name</TableCell>
                        <TableCell>Confirmation</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {attendees &&
                        attendees.map((a) => (
                          <TableRow key={a.id}>
                            <TableCell>{a.firstName}</TableCell>
                            <TableCell>{a.lastName}</TableCell>
                            <TableCell>
                              {a.responded ? (
                                a.attending ? (
                                  "Attending"
                                ) : (
                                  <>
                                    Declined
                                    <Tooltip
                                      title={
                                        a.reason ? a.reason : "No Reason Given"
                                      }
                                    >
                                      <IconButton size="large">
                                        <Info />
                                      </IconButton>
                                    </Tooltip>
                                  </>
                                )
                              ) : (
                                "Pending"
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
              <CardActions>
                <Button
                  onClick={() => setInviteOpen(true)}
                  color="primary"
                  variant="contained"
                  startIcon={<AddBox />}
                >
                  Add Attendees
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={5}>
            <Card>
              <CardHeader
                title="Session Info"
                avatar={
                  <Avatar>
                    <Info />
                  </Avatar>
                }
              />
              <CardContent>
                <Typography>
                  Training Module:{" "}
                  {session.module
                    ? `${session.module.code} ${session.module.name}`
                    : null}
                </Typography>
                <Typography>
                  External Provider:{" "}
                  {session.source === "external" ? "Yes" : "No"}
                </Typography>
                <Typography>
                  Facilitator:{" "}
                  {session.facilitator && session.facilitator.label}
                </Typography>
                <Typography>Site: {session.siteLabel}</Typography>
                <Typography>
                  Venue: {session.venue ? session.venue.name : null}
                </Typography>
                <Typography>Cost of Training: {currentUser.currency ? currentUser.currency.value : ""} {session.cost}</Typography>
                <Typography>
                  Max No. of Delegates: {session.maxDelegates}
                </Typography>
                <Typography>
                  Min No. of Delegates: {session.minDelegates}
                </Typography>
                <Typography>
                  Min Cost: {currentUser.currency ? currentUser.currency.value : ""}{" "}
                  {session.minDelegates && session.cost
                    ? session.minDelegates * session.cost
                    : null}
                </Typography>
                <Typography>
                  Max Cost: {currentUser.currency ? currentUser.currency.value : ""}{" "}
                  {session.maxDelegates && session.cost
                    ? session.maxDelegates * session.cost
                    : null}
                </Typography>
                <Typography>
                  Start Date/Time:{" "}
                  {moment(session.startTime.toDate()).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )}
                </Typography>
                <Typography>
                  End Date/Time:{" "}
                  {moment(session.endTime.toDate()).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )}
                </Typography>
              </CardContent>
              {currentUser.companyAdmin && (
                <CardActions>
                  <Button
                    variant="contained"
                    startIcon={<Edit />}
                    color="primary"
                    onClick={toggleEditFacil}
                  >
                    Change Facilitator
                  </Button>
                </CardActions>
              )}
            </Card>
          </Grid>
        </Grid>
        <InviteAttendees
          open={inviteOpen}
          close={() => setInviteOpen(false)}
          sessionId={id}
        />
        <ChangeFacilitator
          close={toggleEditFacil}
          open={editFacilOpen}
          session={session}
        />
      </div>
    );
}
