import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import { textFieldProps } from '../../shared/constants'

const useStyles = makeStyles((theme) => ({
    header: {
        background: theme.palette.primary.main
    }
}))

export default function RaiseIssue({ open, close, inspectionId, field, fieldVal,  index, completeIssue }) {
    const classes = useStyles()
    const { raiseIssue } = useDb()

    const [state, setState] = React.useState({
        loading: false,
        error: null,
        data: {}
    })

    const handleClose = () => {
        close()
        setState({
            loading: false,
            error: null,
            data: {}
        })
    }

    const handleChange = (e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.target.name]: e.target.value
            }
        })
        console.log(state)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setState({
            loading: true,
            error: null
        })
        try {
            const issueId = await raiseIssue(inspectionId, state.data, field)
            completeIssue(index, issueId, state.data.description, field, fieldVal)
            handleClose()
        }
        catch(err) {
            setState({
                loading: false,
                error: err.message
            })
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle className={classes.header}>
                Raise Issue on: {field.name}
            </DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        {...textFieldProps}
                        name="description"
                        onChange={handleChange}
                        label="Describe Issue"
                        required
                        multiline
                        rows={3}
                    />
                        {
                            state.error && <Typography color="secondary">{state.error}</Typography>
                        }
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        onClick={handleClose}
                        disabled={state.loading}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={state.loading}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
