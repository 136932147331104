import React from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import CompanyList from './CompanyList'

export default function Companies() {
    const { GetCompanies } = useDb()
    const companies = GetCompanies()

    return (
        <div>
            <CompanyList companies={companies} />
        </div>
    )
}
