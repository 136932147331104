import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress, Button } from '@mui/material'
import React, { useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { useDb } from '../../contexts/DatabaseContext'
import moment from 'moment'
import { ArrowLeftOutlined } from '@mui/icons-material'
import IsoDataGrid from '../shared/data-grid/IsoDataGrid'

export default function UserLogsSearch() {
    const { uid, from, to } = useParams()
    const { getUserLogs, userLogsPdf  } = useDb()
    const [loaded, setLoaded] = useState(false)
    const [logs, setLogs] = useState([])

    const dateFrom = Date.parse(from)
    const dateTo = Date.parse(to)

    const convertedFromDate = new Date(dateFrom) 
    const convertedToDate = new Date(dateTo) 
    
    const firstLoad = async () => {
        const l = await getUserLogs(uid, convertedFromDate, convertedToDate)
        setLogs(l)
        setLoaded(true)
    }

    const columns = [
        {
            field: 'timestamp',
            headerName: 'Time',
            width: 150
        },
        {
            field: 'date',
            headerName: 'Date',
            width: 150
        },
        {
            field: 'user',
            headerName: 'User',
            width: 400
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 300
        },
    ]
    
    const rows = logs && logs.map(log => ({
        id: log.id,
        timestamp: moment(log.timestamp.toDate()).format('HH:mm'),
        date: moment(log.timestamp.toDate()).format('DD/MM/YYYY'),
        user: `${log.user.firstName} ${log.user.lastName} - ${log.user.email}`,
        description: log.event
    }))

    if(!loaded) {
        firstLoad()
        return <CircularProgress />
    }
    else return (
        <div>
            <h1>User Logs Search:</h1>
            <Link to="/audit-trail">
                <Button startIcon={<ArrowLeftOutlined />} color="primary">Back to Audit Trail</Button>
            </Link>
            <Button disabled={logs === undefined} className='iso-right' color="primary" variant='contained' onClick={() => userLogsPdf(rows)}>Export to PDF</Button>
            <br/><br/>
            <IsoDataGrid 
                data={rows ? rows : [{id: 1, timestamp: 'No Results', date: 'No Results', user: 'No Results', description: 'No Results'}]}
                columns={columns}
                height={650}
            />
        </div>
    )
}
