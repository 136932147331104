import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { textFieldProps } from "../../../shared/constants";
import { ArrowForward } from "@mui/icons-material";
import { useDb } from "../../../../contexts/DatabaseContext";

export default function AddSection({
  open,
  close,
  formId,
  formPath,
  sectionsLength,
}) {
  const [sectionName, setSectionName] = useState("");
  const { addStandardDoc } = useDb();
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    // TODO: Clear any state
    close();
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await addStandardDoc(`${formPath}/sections/`, {
        formId,
        name: sectionName,
        order: sectionsLength ? sectionsLength + 1 : 1,
      });
      handleClose();
    } catch (err) {
      window.alert(err.message);
    }
    setLoading(false);
  };
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add New Section</DialogTitle>
      <DialogContent>
        <TextField
          {...textFieldProps}
          label="Section Name"
          value={sectionName}
          onChange={(e) => setSectionName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="error">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          endIcon={<ArrowForward />}
          disabled={loading || !sectionName}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
