import { Button, Card, CardActions, CardContent, CircularProgress, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import moment from 'moment'
import { ArrowLeftOutlined, AttachFile, Check, Edit, FileCopy, People, Search } from '@mui/icons-material'
import FileDialog from '../../shared/FileDialog'
import { useAuth } from '../../../contexts/AuthContext'
import FloatingHelpComponent from '../../help/FloatingHelpComponent'

export default function DocumentDetail() {
    const { id } = useParams()
    const { getDocById } = useDb()
    const { currentUser } = useAuth()
    const [loaded, setLoaded] = useState(false)
    const [doc, setDoc] = useState(null)
    const [pdfViewOpen, setPdfViewOpen] = useState(false)

    const firstLoad = async () => {
        const d = await getDocById(id)
        setDoc(d)
        setLoaded(true)
    }

    if(!loaded) {
        firstLoad()
        return <CircularProgress /> 
    }
    else return (
        <div>
            <h1>Document: {doc.docCode}</h1>
            <Typography>{doc.docName}</Typography>
            <br/>
            <Link to={`/doc-manager/doc-register`}>
                <Button color="primary" variant="contained" startIcon={<ArrowLeftOutlined />}>Document Register</Button>
            </Link>
            <br/>
            <br/>
            <Card>
                <CardContent>
                    <Typography noWrap>Document Code: {doc.docCode}</Typography>
                    <Typography noWrap>Document Name: {doc.docName}</Typography>
                    <Typography noWrap>Doc Type: {doc.docType.label}</Typography>
                    <Typography noWrap>Category: {doc.category}</Typography>
                    <Typography noWrap>Created: {moment(doc.createdDate.toDate()).format('DD/MM/YYYY')}</Typography>
                    <Typography noWrap>Department: {doc.department.label}</Typography>
                    <Typography noWrap>Disposition Method: {doc.dispositionMethod.label}</Typography>
                    <Typography noWrap>Owner: {doc.owner.label}</Typography>
                    <Typography noWrap>Retention Period: {doc.retentionPeriod.label}</Typography>
                    <Typography noWrap>Site: {doc.site.label}</Typography>
                    <Typography noWrap>Target Revision Date: {moment(doc.targetRevDate.toDate()).format('DD/MM/YYYY')}</Typography>
                </CardContent>
                <CardActions>
                    <Button onClick={() => setPdfViewOpen(true)} color="primary" variant="contained" startIcon={<Search />}>View Document</Button>
                    {
                        currentUser.accessRights.docManager < 2
                        ?
                        <Link to={`/doc-manager/edit-doc/${id}`}>
                            <Button color="primary" variant="contained" startIcon={<Edit />}>Edit Document</Button>
                        </Link>
                        :
                        null
                    }
                    <Link to={`/doc-manager/revisions/${id}/${doc.docCode}`}>
                        <Button color="primary" variant="contained" startIcon={<FileCopy />}>Revisions</Button>
                    </Link>
                    <Link to={`/doc-int-parties/${id}`}>
                        <Button color="primary" variant="contained" startIcon={<People />}>Interested Parties</Button>
                    </Link>
                    <Link to={`/doc-approvers/${id}`}>
                        <Button color="primary" variant="contained" startIcon={<Check />}>Approvers</Button>
                    </Link>
                    <Link to={`/doc-supdocs/${id}`}>
                        <Button color="primary" variant="contained" startIcon={<AttachFile />}>Documents Submitted</Button>
                    </Link>
                    
                </CardActions>
            </Card>
            <FileDialog open={pdfViewOpen} closeDialog={() => setPdfViewOpen(false)} file={doc.docUrl} /> 

            <FloatingHelpComponent
                name="Generating Doc PDFs" 
                videoPath="doc_manager"
                videoIndex={7}
            />
        </div>
    )
}
