import { Grid } from '@mui/material'
import React from 'react'
import ManagementUsers from './users/ManagementUsers'

export default function ManagementDashboard() {

    return (
        <Grid container spacing={3}>
            <h1 style={{ color: 'white'}}>ISOMS Admin Dashboard</h1>
        </Grid>
    )
}
