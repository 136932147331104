import { Button, Container, Typography } from '@mui/material'
import { ArrowLeftOutlined } from '@mui/icons-material'
import React from 'react'
import { Link } from 'react-router-dom'

export default function SupportThanks() {
    return (
        <div>
            <Container>
                <h1>Support Request Received</h1>
                <Typography>
                    Thank you for your message. One of our support representatives will get back to you within 1 business day.
                </Typography>
                <br/>
                <Link to="/">
                    <Button color="primary" startIcon={<ArrowLeftOutlined />}>
                        Back to Dashboard
                    </Button>
                </Link>
                
            </Container>
        </div>
    )
}
