import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import { AddBox } from "@mui/icons-material";
import React, { useState } from "react";
import Select from "react-select";
import { useDb } from "../../../../contexts/DatabaseContext";
import { selectFieldProps, textFieldProps } from "../../../shared/constants";

export default function AddParticipant({ open, close, add }) {
  const { GetUsersLevelFilter, GetDepartments } = useDb();
  const users = GetUsersLevelFilter("riskManager", 2);
  const departments = GetDepartments();

  const [state, setState] = useState({});

  const handleClose = () => {
    setState({});
    close();
  };

  const handleAdd = () => {
    add({ ...state });
    handleClose();
  };

  const handleSelect = (v, e) => {
    console.log(v);
    setState({
      ...state,
      [e.name]: v,
    });
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={handleClose}>
      <DialogTitle>Add Participant</DialogTitle>
      <DialogContent>
        <Select
          options={users}
          {...selectFieldProps}
          className="iso-select"
          onChange={handleSelect}
          placeholder="Select Participant ..."
          name="user"
        />
        <Select
          options={departments}
          {...selectFieldProps}
          className="iso-select"
          onChange={handleSelect}
          placeholder="Select Department ..."
          name="department"
        />
        <TextField
          {...textFieldProps}
          label="Occupation"
          name="occupation"
          onChange={handleChange}
          value={state.occupation}
        />
        <TextField
          {...textFieldProps}
          label="Years Experience"
          type="number"
          name="experience"
          onChange={handleChange}
          value={state.experience}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          startIcon={<AddBox />}
          color="primary"
          variant="contained"
          disabled={
            !state.user ||
            !state.department ||
            !state.experience ||
            !state.occupation
          }
          onClick={handleAdd}
        >
          Add Participant
        </Button>
      </DialogActions>
    </Dialog>
  );
}
