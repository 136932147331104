import { Button, ButtonGroup, Chip, CircularProgress, IconButton, Input, InputAdornment } from '@mui/material'
import { AddBox, ArrowBack, Check, Edit, Search } from '@mui/icons-material'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useParams, useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { useDb } from '../../../../contexts/DatabaseContext'
import IsoDataGrid from '../../../shared/data-grid/IsoDataGrid'
import AddIBRisk from './new-risk/AddIBRisk'

export default function IBFromBaselineHazardRisks() {
    const { ibraId, hazardId } = useParams()
    const history = useHistory()
    const { GetRisksForIBHazard, getIbraHazard, updateIbraHazard } = useDb()
    const risks = GetRisksForIBHazard(ibraId, hazardId)
    const [loading, setLoading] = useState(true);

    useEffect(async () => {
        const h = await getIbraHazard(ibraId, hazardId)
        setHazard(h)
        setLoading(false)
    }, [ibraId, hazardId])

    const [hazard, setHazard] = useState()
    const [addOpen, setAddOpen] = useState(false)
    
    const [hazardName, setHazardName] = useState()
    const [editing, setEditing] = useState(false)
    
    const handleEditSave = async () => {
        try {
            await updateIbraHazard(ibraId, hazardId, hazardName)
            setEditing(false)
        }
        catch(err) {
            console.log(err.message)
        }
    }
    
    const handleEditCancel = () => {
        setHazardName(hazard.name)
        setEditing(false)
    }
    
    const handleNav = (step, riskId) => {
        switch(step) {
            case 1: 
                history.push(`/risk-manager/issue-based/${ibraId}/ppesigns/${riskId}`)
                break;
            case 2: 
                history.push(`/risk-manager/issue-based/${ibraId}/hierarchy/${riskId}`)
                break;
            case 3:
                history.push(`/risk-manager/issue-based/${ibraId}/risk-matrix/${riskId}`)
                break;
            default: 
            
                history.push(`/risk-manager/ibra-risk-detail/${ibraId}/${riskId}`)
                break;
        }
    }

    const rows = risks && risks.map(r =>({
        id: r.id,
        cause: r.cause,
        description: r.description,
        routine: r.routine === 'routine' ? 'Routine Task' : 'Non-Routine Task',
        completed: r.completed ? true : false,
        currentStep: r.currentStep
    }))
    
    const columns = [
        { field: 'cause', headerName: 'Risk Cause' , width: 200 },
        { field: 'description', headerName: 'Description' , width: 300 },
        { field: 'routine', headerName: 'Routine Task', width: 200 },
        { 
            field: 'status',
            headerName: 'Status', 
            width: 140,
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-between align-items-center">
                        {
                            params.row.completed ? <Chip icon={<Check />} label="Complete" color="primary" /> : <Chip label="Draft" />
                        }
                    </div>
                );
            }
        },
        { field: 'currentStep', headerName: 'Step', width: 120 },
        { 
            field: 'action', 
            headerName: 'Action', 
            width: 140,
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                        <IconButton
                            onClick={() => handleNav(params.row.currentStep, params.row.id)}
                            size="large">
                            <Search />
                        </IconButton>
                     </div>
                );
            }
        }
    ]

    console.log(hazard)

    return <>
        {
            loading
            ?
            <CircularProgress />
            :
            <div>
                {
                    editing
                    ?
                    <Input
                        type="text"
                        variant="outlined"
                        style={{ padding: 10, width: '50%'}}
                        label="Hazard Name"
                        value={hazardName}
                        onChange={(e) => setHazardName(e.target.value)}
                        endAdornment={
                            <InputAdornment position="end">
                                <ButtonGroup>
                                    <Button
                                        onClick={handleEditCancel}
                                    >Cancel</Button>
                                    <Button
                                        onClick={handleEditSave}
                                    >Save</Button>
                                </ButtonGroup>
                            </InputAdornment>
                        }
                    />
                    :
                    <h1>Hazard: {hazardName} <IconButton onClick={() => setEditing(true)} size="large"><Edit /></IconButton></h1>
                }
                <div>
                    <Link to={`/risk-manager/issue-based/${ibraId}/3`}>
                        <Button
                            variant="contained"
                            startIcon={<ArrowBack />}
                            color="primary"
                        >
                            Hazards
                        </Button>
                    </Link>
                    &nbsp;
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddBox />}
                        onClick={() => setAddOpen(true)}
                    >
                        Add Risk
                    </Button>
                </div>
                <IsoDataGrid
                    data={rows}
                    columns={columns}
                />
                <AddIBRisk
                    open={addOpen}
                    close={() => setAddOpen(false)}
                    ibraId={ibraId}
                    hazardId={hazardId}
                />
            </div>
        }
    </>;
}
