import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { ArrowForward, Delete, Person, AddBox } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDb } from "../../../../contexts/DatabaseContext";
import { selectFieldProps, textFieldProps } from "../../../shared/constants";
import Select from "react-select";

export default function IBFromBaseline2() {
  const { ibraId } = useParams();
  const history = useHistory();
  const {
    getIbra,
    createIbHazard,
    updateIbra,
    GetSites,
    retrieveBaselineProcesses,
  } = useDb();
  const sites = GetSites();
  const [procs, setProcs] = useState();

  const [ibra, setIbra] = useState();
  const [state, setState] = useState({
    loading: false,
    error: null,
    data: {},
    addOpen: false,
    process: null,
  });

  const handleChange = (e) => {
    setState({
      ...state,
      data: {
        ...state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleSubmit = async () => {
    setState({
      ...state,
      loading: true,
      error: null,
    });
    try {
      const hazardId = await createIbHazard(ibraId, {
        ...state.data,
        site: state.site,
        process: state.process,
      });
      await updateIbra(ibraId, {
        currentStep: 3,
        process: state.process,
        tag: state.data.tag,
      });
      history.push(
        `/risk-manager/issue-based/${ibraId}/hazards/${hazardId}/risks`
      );
    } catch (err) {
      setState({
        ...state,
        loading: false,
        error: err.message,
      });
    }
  };

  const loadIbra = async () => {
    const i = await getIbra(ibraId);
    console.log(i.blra);
    const processes = await retrieveBaselineProcesses(i.blra);
    setIbra(i);
    setProcs(processes);
    console.log(processes);
  };

  useEffect(() => {
    const unsubscribe = loadIbra();
    return unsubscribe;
  }, [ibraId]);

  return !ibra ? (
    <LinearProgress />
  ) : (
    <Container>
      <h1>{ibra.ibra}: Identify Hazard</h1>
      {procs && procs.length > 0 && (
        <Select
          {...selectFieldProps}
          className="iso-select"
          name="process"
          options={procs}
          placeholder="Select Process From Baseline ..."
          onChange={(val) => setState({ ...state, process: val })}
        />
      )}
      <TextField
        {...textFieldProps}
        label="IBRA Short Description/Tag"
        onChange={handleChange}
        required
        name="tag"
      />
      <TextField
        {...textFieldProps}
        label="Hazard Name"
        onChange={handleChange}
        required
        name="name"
      />
      <TextField
        {...textFieldProps}
        label="Tools / Equipment Used"
        onChange={handleChange}
        required
        name="tools"
      />

      <Typography>Select Site:</Typography>
      <Select
        {...selectFieldProps}
        options={sites}
        className="iso-select"
        onChange={(val) => setState({ ...state, site: val })}
      />

      {state.error && <Typography color="secondary">{state.error}</Typography>}
      <CardActions className="iso-right">
        <Button
          color="primary"
          variant="contained"
          endIcon={<ArrowForward />}
          disabled={
            state.loading || !state.site || !state.data.tag || !state.process
          }
          onClick={handleSubmit}
        >
          Next
        </Button>
      </CardActions>
    </Container>
  );
}
