import { Avatar, Button, ButtonGroup, Card, CardContent, CircularProgress, Container, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, TextField, Typography } from '@mui/material'
import { ArrowBack, ArrowDownward, ArrowUpward, Delete, Save } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { textFieldProps } from '../../shared/constants'
import { useHistory, useParams } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import { useAuth } from '../../../contexts/AuthContext'

export default function RiskProcessDetail() {
    const history = useHistory()
    const { processId } = useParams()
    const { updateRiskProcess, getRiskProcess, deleteProcessStep } = useDb()
    const { currentUser } = useAuth()

    const [loaded, setLoaded] = useState(false)

    const [state, setState] = useState({
        loading: false,
        error: null,
        data: {
            steps: []
        }
    })

    const handleChange = (e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleAdd = () => {
        let steps = state.data.steps
        steps.push(state.data.step)
        setState({
            ...state,
            data: {
                ...state.data,
                step: '',
                steps
            }
        })
        handleSubmit()
    }

    const handleSubmit = async () => {
        setState({
            ...state,
            error: null,
            loading: true
        })
        try {
            await updateRiskProcess(processId, state.data)
            setState({
                ...state,
                error: null,
                loading: false
            })
        }
        catch(err) {
            setState({
                ...state,
                error: err.message,
                loading: false
            })
        }
    }

    const handleStepDelete = async (step, stepIndex) => {
        const confirm = window.confirm("Are you sure you want to delete this step? This action cannot be undone.")
        if(confirm) {
            setState({
                ...state,
                error: null,
                loading: true
            });
    
            let stepArray = state.data.steps;
            stepArray.splice(stepIndex, 1);
            try {
                await deleteProcessStep(processId, step)
                setState({
                    error: null,
                    loading: false,
                    data: {
                        ...state.data,
                        steps: stepArray
                    }
                })
            }
            catch(err) {
                setState({
                    ...state,
                    error: err.message,
                    loading: false
                })
            }
        }
        else return
    }

    const handleStepMove = (from, to) => {
        // Reference the step array 
        let arr = state.data.steps;
        console.log(arr)

        // Delete the item from it's current position
        let item = arr.splice(from, 1);

        // Move the item to its new position
        arr.splice(to, 0, item[0])

        // Update the state with the modified array
        setState({
            ...state,
            data: {
                ...state.data,
                steps: arr
            }
        })

        // Update Firebase
        handleSubmit()
    }

    const firstLoad = async () => {
        const p = await getRiskProcess(processId)
        setState({
            ...state,
            data: {
                ...state.data,
                name: p.name,
                steps: p.steps
            }
        })
        setLoaded(true)
    }

    if(!loaded) {
        firstLoad()
        return <CircularProgress />
    }
    else return (
        <div>
            
            <Container>
                <h1>Edit Process</h1>
                <div className="back-link">
                    <Button
                        color="primary"
                        variant="contained"
                        startIcon={<ArrowBack />}
                        onClick={() => history.goBack()}
                    >
                        Back
                    </Button>
                </div>
                <TextField
                    {...textFieldProps}
                    label="Process Name"
                    onChange={handleChange}
                    name="name"
                    value={state.data.name}
                />
                <br/>
                <Card>
                    <CardContent>
                        <Typography>Steps:</Typography>
                        <List>
                            {
                                state.data.steps && state.data.steps.map((step, i) => (
                                    <ListItem key={i} divider>
                                        <ListItemAvatar>
                                            <Avatar>
                                                {i+1}
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText>{step}</ListItemText>
                                        <ListItemSecondaryAction>
                                            <ButtonGroup>
                                                {
                                                    i > 0 && <IconButton onClick={() => handleStepMove(i, i-1)} size="large">
                                                        <ArrowUpward />
                                                    </IconButton>
                                                }
                                                {
                                                    i+1 < state.data.steps.length && <IconButton onClick={() => handleStepMove(i, i+1)} size="large">
                                                        <ArrowDownward />
                                                    </IconButton>
                                                }
                                                <IconButton onClick={() => handleStepDelete(step, i)} size="large">
                                                    <Delete />
                                                </IconButton>
                                            </ButtonGroup>
                                            
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))
                            }
                        </List>
                    </CardContent>
                </Card>
                <br/>

                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={10}>
                                <TextField
                                    {...textFieldProps}
                                    label="Add Step ..."
                                    onChange={handleChange}
                                    name="step"
                                    value={state.data.step}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                {
                                    currentUser.accessRights.riskManager > 2 || currentUser.companyAdmin
                                    &&
                                    <Button
                                        variant="contained"
                                        startIcon={<ArrowUpward />}
                                        fullWidth
                                        size="large"
                                        color="primary"
                                        onClick={handleAdd}
                                        disabled={!state.data.step}
                                    >
                                        Add
                                    </Button>
                                }
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <br/>
                {
                    state.error && <Typography color="secondary">{state.error}</Typography>
                }
                {
                    currentUser.accessRights.riskManager > 2 || currentUser.companyAdmin
                    &&
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        startIcon={<Save />}
                        onClick={handleSubmit}
                        disabled={
                            state.loading ||
                            !state.data.steps ||
                            !state.data.name 
                        }
                    >
                        Update Process
                    </Button>
                }
            </Container>
            <br/>
        </div>
    );
}
