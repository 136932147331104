import React, { useState } from "react";
import Chart from "react-apexcharts";
import moment from "moment";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import { Info } from "@mui/icons-material";

export default function ContinuousChart({ measurements, target }) {
  console.log(measurements);
  // const baselineValue = baseline || 0;

  function average(array) {
    let sum = array.reduce((acc, curr) => acc + curr, 0);
    return sum / array.length;
  }

  const sortedData = measurements.sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  );
  const dates = sortedData
    .map((item) => moment(item.timestamp.toDate()).format("YYYY-MM-DD"))
    .reverse();
  const targetValue = target;
  console.log(targetValue);
  const values = sortedData.map((item) => item.measurement).reverse();
  const minVal = Math.min(...values);
  const maxVal = Math.max(...values);
  const baselineValue = average(values);
  const variances = sortedData
    .map((item) => (item.measurement - target).toFixed(2))
    .reverse();

  const opportunityRange = values.map((val) => {
    if (val >= minVal && val <= baselineValue) {
      return val;
    }
    return null;
  });

  const options = {
    chart: {
      id: "basic-line",
    },
    xaxis: {
      categories: dates,
    },
    annotations: {
      yaxis: [
        {
          y: baselineValue,
          borderColor: "#FF0000",
          label: {
            borderColor: "#FF0000",
            style: {
              color: "#FFF",
              background: "#FF0000",
            },
            text: "Baseline",
          },
        },
        {
          y: targetValue,
          borderColor: "#00c685",
          label: {
            borderColor: "#00c685",
            style: {
              color: "#FFF",
              background: "#00c685",
            },
            text: "Target",
          },
        },
        {
          y: minVal,
          borderColor: "#0000FF",
          label: {
            borderColor: "#0000FF",
            style: {
              color: "#FFF",
              background: "#0000FF",
            },
            text: "Min",
          },
        },
        {
          y: maxVal,
          borderColor: "#0000FF",
          label: {
            borderColor: "#0000FF",
            style: {
              color: "#FFF",
              background: "#0000FF",
            },
            text: "Max",
          },
        },
      ],
    },
    tooltip: {
      y: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          // You can format the output here as you like
          return `${value}\nVariance: ${variances[dataPointIndex]}`;
        },
      },
    },
  };

  const series = [
    {
      name: "Value",
      data: values,
    },
  ];

  return (
    <div>
      <Chart type="area" options={options} series={series} />
      <Card>
        <CardHeader
          title="Opportunity Range"
          avatar={
            <Avatar>
              <Info />
            </Avatar>
          }
        />
        <CardContent>
          <Typography>Baseline: {baselineValue}</Typography>
          <Typography>Min: {minVal}</Typography>
          <Typography>Max: {maxVal}</Typography>
        </CardContent>
      </Card>
    </div>
  );
}
