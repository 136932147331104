import React from "react";
import { useAuth } from "../../contexts/AuthContext";
import FloatingHelpComponent from "../help/FloatingHelpComponent";

export default function OtpManager() {
  const { currentUser } = useAuth();
  var params = {
    "ds0.company": currentUser.companyId,
    "ds18.companyId": currentUser.companyId,
  };
  var paramsAsString = JSON.stringify(params);
  var encodedParams = encodeURIComponent(paramsAsString);

  return (
    <div>
      <h1>OTP Manager Dashboard</h1>
      <iframe
        width="1200"
        height="1200"
        src={`https://datastudio.google.com/embed/reporting/469fe6a9-83c1-4116-ad3f-1a6aedd929f4/page/oNhrC?params=${encodedParams}`}
        frameBorder="0"
      ></iframe>
      <FloatingHelpComponent
        name="Using OTP Dashboard"
        videoPath="otp_manager"
        videoIndex={1}
      />
    </div>
  );
}
