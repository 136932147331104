import { Restore } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { useDb } from '../../contexts/DatabaseContext'
import IsoDataGrid from '../shared/data-grid/IsoDataGrid'

export default function FixPrograms() {

  const { GetAllPrograms, fixPrograms } = useDb()
  const programs = GetAllPrograms()

  const handleClick = async (data) => {
    console.log(data)
    await fixPrograms(data)
  }

  const columns = [
    {field: 'id', headerName: 'Id' , width: 200},
    {field: 'measurement', headerName: 'Measurement' , width: 200},
    {field: 'date', headerName: 'Date' , width: 200},
    {field: 'fix', headerName: 'Fix' , width: 200,
      renderCell: (params) => {
        return (
          <IconButton
            disabled={params.row.data.proFixed}
            onClick={() => handleClick(params.row.data)}
            size="large">
            <Restore />
          </IconButton>
        );
      }
    },
  ]

  const rows = programs && programs.map(p => ({
    id: p.id,
    measurement: p.measurement,
    date: moment(p.createdAt.toDate()).format('DD MMM YYYY'),
    data: p
  }))

  console.log(rows)

  return (
    <div>
      <IsoDataGrid 
        columns={columns}
        data={rows}
      />
    </div>
  )
}
