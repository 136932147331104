import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
} from "@mui/material";
import { AddBox, Search } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useDb } from "../../../contexts/DatabaseContext";
import moment from "moment";
import IsoDataGrid from "../../shared/data-grid/IsoDataGrid";
import FloatingHelpComponent from "../../help/FloatingHelpComponent";

export default function TrainingModules() {
  const { GetTrainingModules, fixModules } = useDb();
  const modules = GetTrainingModules();

  const rows =
    modules &&
    modules.map((m) => ({
      id: m.id,
      code: m.code,
      name: m.name,
      department: m.department.name,
      risk: m.risk.name,
      targetRevDate: moment(m.targetRevDate.toDate()).format("DD/MM/YYYY"),
      data: m,
      external: m.external ? m.provider.name : "In-House",
    }));

  const columns = [
    {
      field: "action",
      headerName: "Detail",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <Link
              to={`/training-manager/module/${params.row.id}/${params.row.code}`}
            >
              <IconButton size="large">
                <Search />
              </IconButton>
            </Link>
          </div>
        );
      },
    },
    { field: "code", headerName: "Code", width: 150 },
    { field: "name", headerName: "Name", width: 200 },
    { field: "department", headerName: "Department", width: 200 },
    { field: "risk", headerName: "Risk", width: 200 },
    { field: "targetRevDate", headerName: "Target Rev. Date", width: 200 },
    { field: "external", headerName: "Provider", width: 200 },

    // {
    //     field: 'fix',
    //     headerName: 'Fix',
    //     sortable: false,
    //     width: 80,
    //     renderCell: (params) => {
    //         return (
    //             <IconButton disabled={params.row.data.modulefixed} onClick={() => fixModules(params.row.data)}>
    //                 <Restore />
    //             </IconButton>
    //         );
    //     }
    // }
  ];

  return (
    <div>
      <h1>Training Modules</h1>

      <Card>
        <CardContent>
          <IsoDataGrid data={rows} columns={columns} />
        </CardContent>
        <CardActions>
          <Link to={"/training-manager/modules/add"}>
            <Button color="primary" variant="contained" startIcon={<AddBox />}>
              Add Module
            </Button>
          </Link>
        </CardActions>
      </Card>

      <FloatingHelpComponent
        name="Managing Training Modules"
        videoPath="training_manager"
        videoIndex={2}
      />
    </div>
  );
}
