import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Container,
  IconButton,
} from "@mui/material";
import React from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import { Check, Edit } from "@mui/icons-material";
import { Link } from "react-router-dom";

export default function MocTemplates() {
  const { GetMOCTemplates } = useDb();
  const templates = GetMOCTemplates();
  return (
    <div>
      <Container>
        <Card>
          <CardHeader title="MOC Templates" />
          <CardContent>
            {templates &&
              templates.map((t) => (
                <Card key={t.id}>
                  <CardHeader
                    title={t.name}
                    avatar={
                      <Avatar>
                        <Check />
                      </Avatar>
                    }
                    action={
                      <Link to={`${t.link}/${t.id}`}>
                        <IconButton size="large">
                          <Edit />
                        </IconButton>
                      </Link>
                    }
                  />
                </Card>
              ))}
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}
