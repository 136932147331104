import { Avatar, Button, Card, CardContent, CardHeader, Grid, IconButton, Typography } from '@mui/material'
import { Add, ArrowForward, Info } from '@mui/icons-material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDb } from '../../../../contexts/DatabaseContext'
import IsoDataGrid from '../../../shared/data-grid/IsoDataGrid'
import AddProcessToBaseline from './AddProcessToBaseline'
import BlraProcessSteps from './BlraProcessSteps'

export default function BlraProcesses({ blraId, blra }) {
    const { GetBaselineProcesses } = useDb();
    const procs = GetBaselineProcesses(blraId);

    const [addOpen, setAddOpen] = useState(false);

    const rows = procs && procs.map(p => ({
        ...p,
        name: p.name
    }))

    const columns = [
        { field: 'name', headerName: "Process", width: 400 },
        { 
            field: 'action', 
            headerName: 'Detail' , 
            width: 120,
            renderCell: (params) => {
              return(
                <Link to={`/risk-manager/baselines/baseline-process-steps/${blraId}/${params.row.id}/${params.row.name}`}>
                    <Button color="primary" size="small" variant="contained" endIcon={<ArrowForward />}>
                        Steps
                    </Button>
                </Link>
              )
            }
        },
    ]

    return (
        <div>
            <Typography variant="h5">Processes:</Typography>
            <Button 
                color="primary" 
                size="small" 
                variant="contained" 
                startIcon={<Add />}
                onClick={() => setAddOpen(true)}
            >
                Add Process
            </Button>
            <br/><br/>
            <Card>
                <CardContent>
                    <IsoDataGrid 
                        data={rows}
                        columns={columns}
                    />
                </CardContent>
            </Card>
            

            <AddProcessToBaseline
                open={addOpen}
                close={() => setAddOpen(false)}
                blra={blra}
                blraId={blraId}
            />
        </div>
    )
}
