import { faChalkboardTeacher } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";

export default function TrainingManagerMenuItem({ classes }) {
  const [open, setOpen] = useState(false);
  const { currentUser } = useAuth();

  return (
    <>
      <ListItem button onClick={() => setOpen(!open)}>
        <ListItemIcon>
          <FontAwesomeIcon
            className={classes.icons}
            icon={faChalkboardTeacher}
          />
        </ListItemIcon>
        <ListItemText className={classes.menuText}>
          Training Manager
        </ListItemText>
        {open ? (
          <ExpandLess className={classes.icon} />
        ) : (
          <ExpandMore className={classes.icon} />
        )}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" className={classes.subMenuItem} dense>
          {currentUser.companyAdmin ||
          currentUser.accessRights.trainingManager < 1 ? (
            <ListItem>
              <Link
                to="/training-manager/admin-dashboard"
                className="drawer-item"
              >
                <ListItemText className={classes.menuText}>
                  Admin Dashboard
                </ListItemText>
              </Link>
            </ListItem>
          ) : null}
          {currentUser.companyAdmin ||
          currentUser.accessRights.trainingManager < 1 ? (
            <ListItem>
              <Link to="/training-manager/reports" className="drawer-item">
                <ListItemText className={classes.menuText}>
                  Reports
                </ListItemText>
              </Link>
            </ListItem>
          ) : null}
          {currentUser.employee ? (
            <ListItem>
              <Link
                to="/training-manager/employee-dashboard"
                className="drawer-item"
              >
                <ListItemText className={classes.menuText}>
                  Employee Dashboard
                </ListItemText>
              </Link>
            </ListItem>
          ) : null}
          {currentUser.facilitator || currentUser.companyAdmin ? (
            <ListItem>
              <Link
                to="/training-manager/facilitator-dashboard"
                className="drawer-item"
              >
                <ListItemText className={classes.menuText}>
                  Facilitator Dashboard
                </ListItemText>
              </Link>
            </ListItem>
          ) : null}
          {currentUser.companyAdmin ||
          currentUser.accessRights.trainingManager < 2 ? (
            <ListItem>
              <Link to="/training-manager/sessions" className="drawer-item">
                <ListItemText className={classes.menuText}>
                  Sessions
                </ListItemText>
              </Link>
            </ListItem>
          ) : null}
          {currentUser.companyAdmin ||
          currentUser.accessRights.trainingManager < 1 ? (
            <ListItem>
              <Link
                to="/training-manager/training-requests"
                className="drawer-item"
              >
                <ListItemText className={classes.menuText}>
                  Requests
                </ListItemText>
              </Link>
            </ListItem>
          ) : null}
          {currentUser.companyAdmin ||
          currentUser.accessRights.trainingManager < 2 ? (
            <ListItem>
              <Link to="/training-manager/evaluations" className="drawer-item">
                <ListItemText className={classes.menuText}>
                  Evaluations
                </ListItemText>
              </Link>
            </ListItem>
          ) : null}
          {currentUser.companyAdmin ||
          currentUser.accessRights.trainingManager < 1 ? (
            <ListItem>
              <Link to="/training-manager/employees" className="drawer-item">
                <ListItemText className={classes.menuText}>
                  Employees
                </ListItemText>
              </Link>
            </ListItem>
          ) : null}
          {currentUser.companyAdmin ||
          currentUser.accessRights.trainingManager < 1 ? (
            <ListItem>
              <Link to="/training-manager/modules" className="drawer-item">
                <ListItemText className={classes.menuText}>
                  Modules
                </ListItemText>
              </Link>
            </ListItem>
          ) : null}
          {currentUser.companyAdmin ||
          currentUser.accessRights.trainingManager < 1 ? (
            <ListItem>
              <Link to="/training-manager/providers" className="drawer-item">
                <ListItemText className={classes.menuText}>
                  Providers
                </ListItemText>
              </Link>
            </ListItem>
          ) : null}
          {currentUser.companyAdmin ||
          currentUser.accessRights.trainingManager < 1 ? (
            <ListItem>
              <Link to="/training-manager/facilitators" className="drawer-item">
                <ListItemText className={classes.menuText}>
                  Facilitators
                </ListItemText>
              </Link>
            </ListItem>
          ) : null}
          {currentUser.companyAdmin ||
          currentUser.accessRights.trainingManager < 1 ? (
            <ListItem>
              <Link to="/training-manager/occupations" className="drawer-item">
                <ListItemText className={classes.menuText}>
                  Occupations
                </ListItemText>
              </Link>
            </ListItem>
          ) : null}
          {currentUser.companyAdmin ||
          currentUser.accessRights.trainingManager < 1 ? (
            <ListItem>
              <Link to="/training-manager/venues" className="drawer-item">
                <ListItemText className={classes.menuText}>Venues</ListItemText>
              </Link>
            </ListItem>
          ) : null}
          {currentUser.companyAdmin ||
          currentUser.accessRights.trainingManager < 1 ? (
            <ListItem>
              <Link to="/training-manager/settings" className="drawer-item">
                <ListItemText className={classes.menuText}>
                  Settings
                </ListItemText>
              </Link>
            </ListItem>
          ) : null}
        </List>
      </Collapse>
    </>
  );
}
