import React, { useEffect, useState } from "react";
import { fieldTypes } from "./form-components/fieldTypes";
import { useParams } from "react-router-dom";
import { useDb } from "../../../contexts/DatabaseContext";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  LinearProgress,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import AddSection from "./sections/AddSection";
import SectionsContainer from "./sections/SectionsContainer";

export default function IsoFormBuilder({ admin, formDocId }) {
  const { formId } = useParams();
  const { getStandardDoc, GetCollection, getCompanyDoc } = useDb();
  const [template, setTemplate] = useState();
  const [addSectionOpen, setAddSectionOpen] = useState(false);
  const sections = GetCollection(
    admin || false,
    admin
      ? `masterFormTemplate/${formId || formDocId}/sections`
      : `formTemplate/${formDocId || formId}/sections`,
    null,
    ["order", "asc"]
  );

  const loadForm = async () => {
    if (admin) {
      const t = await getStandardDoc("masterFormTemplate", formId || formDocId);
      setTemplate(t);
    } else {
      try {
        const t = await getCompanyDoc("formTemplate", formId || formDocId);
        setTemplate(t);
      } catch (err) {
        window.alert("Error in loadForm(): ", err.message);
      }
    }
  };

  useEffect(() => {
    const unsubscribe = loadForm();
    return unsubscribe;
  }, [formId || formDocId]);

  return !template ? (
    <LinearProgress />
  ) : (
    <div>
      <Container>
        <Card>
          <CardContent>
            {sections && <SectionsContainer sections={sections} />}
          </CardContent>
          <CardActions>
            <Button onClick={() => setAddSectionOpen(true)} startIcon={<Add />}>
              Add Section
            </Button>
          </CardActions>
        </Card>
      </Container>

      <AddSection
        open={addSectionOpen}
        close={() => setAddSectionOpen(false)}
        formId={formId || formDocId}
        formPath={template.path}
        sectionsLength={sections ? sections.length : 0}
      />
    </div>
  );
}
