import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDb } from "../../../contexts/DatabaseContext";
import Select from "react-select";
import { selectFieldProps, textFieldProps } from "../../shared/constants";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { Button, TextField, Typography } from "@mui/material";
import { Schedule } from "@mui/icons-material";

export default function AddSchedule() {
  const { checklistId } = useParams();
  const history = useHistory();
  const { GetUsersLevelFilter, scheduleChecklist } = useDb();
  const people = GetUsersLevelFilter("auditManager", 2);

  const timeOpts = [
    { value: 1, label: "Daily" },
    { value: 7, label: "Weekly" },
    { value: 30, label: "Monthly" },
    { value: 90, label: "Quarterly" },
    { value: 182, label: "Every 6 Months" },
    { value: 365, label: "Yearly" },
    { value: 730, label: "Every 2 Years" },
  ];

  const [state, setState] = useState({
    loading: false,
    error: null,
    data: {},
  });

  const handleDatePick = (d, field) => {
    setState({
      ...state,
      data: {
        ...state.data,
        [field]: d,
      },
    });
  };

  const handleTimeChange = (d, t, name) => {
    setState({
      ...state,
      data: {
        ...state.data,
        [name]: d,
      },
    });
  };

  const handleSubmit = async () => {
    setState({
      ...state,
      loading: true,
      error: null,
    });
    try {
      await scheduleChecklist(checklistId, state.data);
      history.push("/audit-manager/schedules");
    } catch (err) {
      setState({
        ...state,
        loading: false,
        error: err.message,
      });
    }
  };

  return (
    <div>
      <h1>Schedule Checklist</h1>
      <Select
        options={people}
        className="iso-select"
        {...selectFieldProps}
        onChange={(v) =>
          setState({
            ...state,
            data: {
              ...state.data,
              assignedTo: {
                id: v.value.id,
                label: v.label,
                email: v.value.email,
              },
            },
          })
        }
        placeholder="Assign To ..."
      />
      <Select
        options={timeOpts}
        className="iso-select"
        {...selectFieldProps}
        onChange={(v) =>
          setState({
            ...state,
            data: {
              ...state.data,
              frequency: v.value,
            },
          })
        }
        placeholder="Frequency ..."
      />
      <div>
        {state.data.frequency && state.data.frequency != 1 ? (
          <TextField
            {...textFieldProps}
            label="Time Window (Days) to complete audit/inspection"
            type="number"
            InputProps={{
              inputProps: { min: 1, max: state.data.frequency - 1 },
            }}
            onChange={(e) =>
              setState({
                ...state,
                data: {
                  ...state.data,
                  window: parseInt(e.target.value),
                },
              })
            }
          />
        ) : null}
        {state.data.frequency &&
        state.data.window > state.data.frequency - 1 ? (
          <Typography color="secondary">
            Your time window can not equal or exceed your frequency.
          </Typography>
        ) : null}
      </div>
      <div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            minDate={state.nextDate ? dayjs(state.nextDate) : null}
            label="Beginning On:"
            onChange={(d) => handleDatePick(d.toDate(), "nextDate")}
            defaultValue={dayjs(state.nextDate)}
          />
        </LocalizationProvider>
      </div>
      <br />
      <br />
      {state.error && <Typography color="secondary">{state.error}</Typography>}
      <Button
        variant="contained"
        color="primary"
        startIcon={<Schedule />}
        onClick={handleSubmit}
        disabled={
          state.loading ||
          !state.data.assignedTo ||
          !state.data.nextDate ||
          !state.data.frequency ||
          (state.data.frequency > 1 && !state.data.window) ||
          state.data.window > state.data.frequency - 1
        }
      >
        Create Schedule
      </Button>
    </div>
  );
}
