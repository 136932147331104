import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add } from '@mui/icons-material'
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import { textFieldProps } from '../../shared/constants'

const useStyles = makeStyles((theme) => ({
    header: {
        background: theme.palette.primary.main
    }
}))

export default function NewChecklistSection({ checklistId, open, close }) {
    const classes = useStyles()
    const { createChecklistSection } = useDb()

    const [state, setState] = useState({
        error: null,
        loading: false,
        data: {}
    })

    const handleClose = () => {
        setState({
            error: null,
            loading: false,
            data: {}
        })
        close()
    }

    const handleChange = (e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setState({
            ...state,
            loading: true,
            error: null
        })
        try {
            await createChecklistSection(checklistId, state.data)
            handleClose()
        }
        catch(err) {
            setState({
                ...state,
                loading: false,
                error: err.message
            })
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle className={classes.header}>
                New Section
            </DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        {...textFieldProps}
                        label="Section Name"
                        onChange={handleChange}
                        required
                        name="name"
                    />
                    { state.error && <Typography color="secondary">{state.error}</Typography> }
                </DialogContent>
                <DialogActions> 
                    <Button
                        color="secondary"
                        onClick={handleClose}
                        disabled={state.loading}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        startIcon={<Add />}
                        type="submit"
                        disabled={state.loading}
                    >
                        Create Section
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
