import React, { useState } from 'react';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardActions, CardContent, CardHeader, List } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDb } from '../../../contexts/DatabaseContext';
import DocListItem from '../document/DocListItem';
import { Pagination } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: theme.palette.cardBg
    },
}))


const DocsOverdueRev = ({ favs, userGroups }) => {
    const classes = useStyles()
    const { GetOverdueDocs } = useDb()
    const docs = GetOverdueDocs()
    // Pagination
    const itemsPerPage = 5; // <- Not state but related to below pagination
    const [page, setPage] = useState(1);
    const [noOfPages] = useState(
        Math.ceil(docs.length / itemsPerPage)
    );
    return (  
        <Card className={classes.card}>
            <CardHeader 
                className="iso-card-header"
                avatar={<FontAwesomeIcon icon={faExclamationTriangle} />}
                title="Documents Overdue for Revision"
            />
            <CardContent className="iso-card-content">
                <List>
                {
                    docs && docs
                    .slice((page -1) * itemsPerPage, page * itemsPerPage)
                    .map((doc) => {
                        if(doc.groups && doc.groups.length > 0) {
                            if(doc.groups.some(r=> userGroups.indexOf(r) >= 0)) {
                                return (
                                    <div key={doc.id} style={{ marginBottom: 10 }}>
                                        <DocListItem doc={doc} favs={favs}/>
                                    </div>
                                )
                            }
                            else {
                              return null
                            }
                        }
                        else {
                            return (
                                <div key={doc.id} style={{ marginBottom: 10 }}>
                                    <DocListItem doc={doc} favs={favs}/>
                                </div>
                            )
                            // return (
                            //     <div key={doc.id} style={{ marginBottom: 10 }}>
                            //         <DocListItem doc={doc} favs={favs}/>
                            //     </div>
                            // )
                        }
                    })
                }
                </List>
            </CardContent>
            <CardActions className="iso-card-header">
                <Pagination
                    count={Math.ceil(docs.length / itemsPerPage)}
                    page={page}
                    onChange={(e, val) => setPage(val)}
                    defaultPage={1}
                    color="primary"
                    size="large"
                    showFirstButton
                    showLastButton
                />      
            </CardActions>
        </Card>
    )
}

export default DocsOverdueRev;