import { Button } from '@mui/material'
import { Alert } from '@mui/material';
import React from 'react'
import { useAuth } from '../../contexts/AuthContext'

export default function AccountBlocked() {
    const { currentUser } = useAuth()

    return (
        <div>
            <Alert severity='error' action={currentUser.companyOwner && currentUser.billing_type === "stripe" ? <Button color="primary" variant="contained" size="small">Go to Account</Button> : <></>}>
                There seems to be an issue with your billing information. 
            </Alert>
        </div>
    )
}
