import { Grid } from '@mui/material'
import React from 'react'
import FloatingHelpComponent from '../../help/FloatingHelpComponent'
import TrainingChart from './TrainingChart'
import UpcomingSessionsDashboard from './UpcomingSessionsDashboard'

export default function TrainingAdminDashboard() {
    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <UpcomingSessionsDashboard />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TrainingChart />
                </Grid>
            </Grid>

            <FloatingHelpComponent
                name="Setting up the Training Manager" 
                videoPath="training_manager"
                videoIndex={7}
            />
        </div>
    )
}
