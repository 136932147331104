import React from 'react';
import { Container } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import FloatingHelpComponent from '../help/FloatingHelpComponent';

const DocManager = () => {
    const { currentUser } = useAuth()
    var params = {
        "ds12.company": currentUser.companyId
    }
    var paramsAsString = JSON.stringify(params);
    var encodedParams = encodeURIComponent(paramsAsString);
    
    return (
        <div>
            <h1>Doc Manager Dashboard</h1>
            <Container>
                <iframe width="1200" height="1400" src={`https://datastudio.google.com/embed/reporting/19fae711-5e8e-4575-8e82-f30ebb362c5f/page/w3HqC?params=${encodedParams}`} allowfullscreen frameBorder={0}></iframe>
            </Container>

            <FloatingHelpComponent
                name="How to use Doc Dashboard" 
                videoPath="doc_manager"
                videoIndex={2}
            />
        </div>
    )
}
 
export default DocManager;