import React from 'react'
import Select from 'react-select'
import { useDb } from '../../../../contexts/DatabaseContext';
import { selectFieldProps } from '../../../shared/constants';

export default function BlraEditArea({ site, handleSelect }) {
    const { GetRiskAreasForSite } = useDb();
    const areas = GetRiskAreasForSite(site.value);



    return (
        <div style={{ marginTop: 20}}>
            <Select 
                options={areas}
                {...selectFieldProps}
                onChange={handleSelect}
                name="area"
            />
        </div>
    )
}
