import { Card, CardHeader, Grid } from '@mui/material'
import React from 'react'

export default function SharedFolders() {
  return (
    <div>
        <h1>Shared Folders</h1>
        <Grid item xs={12} md={6}>
            <Card>
                <CardHeader
                    title="Recent Files"
                />
            </Card>
        </Grid>
        <Grid item xs={12} md={6}>
            <Card>
                <CardHeader
                    title="Folders"
                />
            </Card>
        </Grid>
    </div>
  )
}
