import React from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Card, CardContent, IconButton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { CloudDownload } from "@mui/icons-material";

export default function EmployeeEvaluations() {
  const { empId } = useParams();
  const { GetEmployeeEvaluations } = useDb();
  const evals = GetEmployeeEvaluations(empId);

  const handleUrlClick = (url) => {
    window.open(url, "_blank").focus();
  };

  const columns = [
    { field: "firstName", headerName: "First Name", width: 240 },
    { field: "lastName", headerName: "Last Name", width: 240 },
    { field: "module", headerName: "Module", width: 200 },
    { field: "evalDate", headerName: "Eval. Date", width: 200 },
    {
      field: "action",
      headerName: "Template",
      width: 140,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <IconButton
              onClick={() => handleUrlClick(params.row.proofUrl)}
              size="large"
            >
              <CloudDownload />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const rows =
    evals &&
    evals.map((e) => ({
      id: e.id,
      firstName: e.employee.firstName,
      lastName: e.employee.lastName,
      module: e.module.name,
      evalDate: moment(e.evaluationDate.toDate()).format("DD/MM/YYYY"),
      proofUrl: e.proofUrl,
    }));

  return (
    <div>
      <Card>
        <CardContent>
          <div style={{ height: 600, width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              disableSelectionOnClick
              components={{
                Toolbar: GridToolbar,
              }}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
