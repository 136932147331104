import React from "react";
import CollapsibleCostTable from "../CollapsibleCostTable";
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from "@mui/material";
import { Info } from "@mui/icons-material";
import { useAuth } from "../../../../contexts/AuthContext";

export default function ApprovalCosts({ moc }) {

  const { currentUser } = useAuth()

  return (
    <div>
      <Card>
        <CardHeader
          title="Expected Costs"
          avatar={
            <Avatar>
              <Info />
            </Avatar>
          }
        />
        <CardContent>
          <CollapsibleCostTable data={moc.costs} />
        </CardContent>
        <CardActions>Total Cost: {currentUser.currency ? currentUser.currency.value : ""} {moc.totalCost}</CardActions>
      </Card>
    </div>
  );
}
