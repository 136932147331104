import React from "react";
import BackLink from "../../shared/BackLink";
import IsoRenderForm from "../form-builder/form-render/IsoRenderForm";
import { useParams } from "react-router-dom";

export default function Phase1() {
  const { mocId } = useParams();
  const collectionPath = `moc/${mocId}/responses`;
  const nextRoute = `/moc/phase-2/${mocId}`;
  return (
    <div>
      <BackLink />
      <h1>Phase 1: MOC Checklist</h1>
      <IsoRenderForm
        formId="mocChecklist"
        docString={"mocChecklist"}
        collectionPath={collectionPath}
        nextRoute={nextRoute}
      />
    </div>
  );
}
