import { TextField } from "@mui/material";
import React from "react";
import { textFieldProps } from "../../../shared/constants";

export default function IsoTextField(props) {
  const { name, onChange, type, value, multiline, rows, label } = props;
  const handleChange = (event) => {
    if (onChange) {
      onChange(name, event.target.value, type);
    }
  };
  return (
    <div>
      <TextField
        {...textFieldProps}
        onChange={handleChange}
        value={value || ""}
        multiline={multiline || false}
        rows={rows || 1}
        label={label || "Text Field"}
      />
    </div>
  );
}
