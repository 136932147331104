import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  AddBox,
  ArrowLeftOutlined,
  Assessment,
  Assignment,
  AssignmentTurnedIn,
  AttachFile,
  CloudUpload,
  Edit,
  EditRounded,
  Speed,
} from "@mui/icons-material";
import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useDb } from "../../../contexts/DatabaseContext";
import RecordMeasurement from "./measurements/RecordMeasurement";
import moment from "moment";
import ProgressChart from "./measurements/ProgressChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxOpen } from "@fortawesome/free-solid-svg-icons";
import ProgressChartEmpty from "./measurements/ProgressChartEmpty";
import InfoIcon from "@mui/icons-material/Info";
import ViewComments from "./ViewComments";
import EditMeasurement from "./measurements/EditMeasurement";
import ContinuousChart from "./measurements/ContinuousChart";
import EditProgramTarget from "./EditProgramTarget";
import ProgramMeasurementsTable from "./measurements/ProgramMeasurementsTable";

export default function ProgramDetail() {
  const { otpId, targetId, programId } = useParams();
  const { getSingleProgram, GetProgramMeasurements, reOpenProgram } = useDb();
  const records = GetProgramMeasurements(otpId, targetId, programId);

  const [loaded, setLoaded] = useState(false);
  const [program, setProgram] = useState(null);
  const [progressState, setProgressState] = useState(0);

  const [recordOpen, setRecordOpen] = useState(false);

  const [commentOpen, setCommentOpen] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);

  const handleCommentView = (c) => {
    setSelectedComment(c);
    setCommentOpen(true);
  };

  const closeCommentView = () => {
    setCommentOpen(false);
    setSelectedComment(null);
  };

  const firstLoad = async () => {
    const p = await getSingleProgram(otpId, targetId, programId);
    console.log(p);
    setProgram(p);
    setProgressState(p.currentProgress ? p.currentProgress : 0);
    setLoaded(true);
  };

  const updateProgress = (pr) => {
    console.log(pr);
    setProgressState(pr);
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedMeasurement, setSelectedMeasurement] = useState("");
  const [openMeasurement, setMeasurementOpen] = useState(false);
  const [editTargetOpen, setEditTargetOpen] = useState(false);

  const handleMeasuremenEdit = (id) => {
    setSelectedMeasurement(id);
    setMeasurementOpen(true);
  };

  const closeMeasurementView = () => {
    setMeasurementOpen(false);
    setSelectedMeasurement(null);
    setLoaded(false);
  };

  const reOpen = async () => {
    setLoading(true);
    setError(null);
    try {
      await reOpenProgram(otpId, targetId, programId).then(() => {
        setLoading(false);
      });
      window.location.reload();
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  if (!loaded) {
    firstLoad();
    return <LinearProgress />;
  } else
    return (
      <div>
        <h1>Program {program.programNo}</h1>
        <Link to={`/target/${otpId}/${targetId}/programs`}>
          <Button
            startIcon={<ArrowLeftOutlined />}
            size="small"
            variant="contained"
            color="primary"
          >
            Programs
          </Button>
        </Link>
        <br />
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12} md={7} lg={7}>
            <Card>
              <CardHeader
                title="Measurements"
                avatar={
                  <Avatar>
                    <Assessment />
                  </Avatar>
                }
                action={
                  <div>
                    {program.measurementType !== "continuous" && (
                      <Chip
                        label={`${progressState} / ${program.programTarget}`}
                      />
                    )}
                  </div>
                }
              />
              <CardActions>
                <Button
                  onClick={() => setRecordOpen(true)}
                  color="primary"
                  variant="contained"
                  startIcon={<AddBox />}
                >
                  Record Measurement
                </Button>
                <Link
                  to={`/import-program-data/${otpId}/${targetId}/${program.id}`}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<CloudUpload />}
                  >
                    Import Data
                  </Button>
                </Link>
              </CardActions>
              <CardContent>
                <ProgramMeasurementsTable
                  records={records}
                  target={program.programTarget}
                  program={program}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={5} lg={5}>
            <Card>
              <CardHeader
                title="Functions"
                avatar={
                  <Avatar>
                    <Assignment />
                  </Avatar>
                }
              />
              <CardContent>
                <Link
                  to={`/program-attachments/${otpId}/${targetId}/${programId}`}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<AttachFile />}
                  >
                    Attachments
                  </Button>
                </Link>
                <br />
                <br />
                {!program.open ? (
                  <Button
                    onClick={reOpen}
                    startIcon={<FontAwesomeIcon icon={faBoxOpen} />}
                    color={"primary"}
                    variant={"contained"}
                  >
                    Re-Open Program
                  </Button>
                ) : null}
                {error ? <span className="iso-error">{error}</span> : null}
              </CardContent>
            </Card>
            {program.otpV2 ? (
              <Card>
                <CardHeader
                  title="Chart"
                  avatar={
                    <Avatar>
                      <Speed />
                    </Avatar>
                  }
                />
                <CardContent>
                  {program.measurementType !== "continuous" ? (
                    progressState === 0 ? (
                      <ProgressChartEmpty />
                    ) : (
                      <ProgressChart
                        currentProgress={progressState}
                        targetProgress={program.programTarget}
                      />
                    )
                  ) : (
                    <ContinuousChart
                      target={program.programTarget}
                      measurements={records}
                    />
                  )}
                </CardContent>
                {program.measurementType === "continuous" && (
                  <CardActions>
                    <Button
                      color="primary"
                      variant="contained"
                      startIcon={<EditRounded />}
                      onClick={() => setEditTargetOpen(true)}
                    >
                      Edit/Update Target
                    </Button>
                  </CardActions>
                )}
              </Card>
            ) : null}
            <br />
          </Grid>
        </Grid>

        <RecordMeasurement
          open={recordOpen}
          closeDialog={() => setRecordOpen(false)}
          otpId={otpId}
          targetId={targetId}
          programId={programId}
          measurement={program.measurement}
          updateProgress={updateProgress}
          currentProgress={progressState}
        />
        <EditMeasurement
          open={openMeasurement}
          close={closeMeasurementView}
          otpId={otpId}
          targetId={targetId}
          programId={programId}
          measurement={program.measurement}
          measurementId={selectedMeasurement}
        />
        {selectedComment && (
          <ViewComments
            open={commentOpen}
            close={closeCommentView}
            comment={selectedComment}
          />
        )}
        <EditProgramTarget
          open={editTargetOpen}
          close={() => setEditTargetOpen(false)}
          currentTarget={program.programTarget}
          otpId={otpId}
          targetId={targetId}
          programId={programId}
        />
      </div>
    );
}
