import { Button, Dialog, DialogActions, DialogContent, TextField, Typography, Grid } from '@mui/material'
import React, { useState } from 'react'
import { useDb } from '../../contexts/DatabaseContext'
import { useAuth } from '../../contexts/AuthContext'
import { textFieldProps } from '../shared/constants'
import { Save } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'

export default function AddTarget({ open, closeDialog, otp }) {
    const { currentUser } = useAuth()
    const { AddOTPTarget } = useDb()   
    const history = useHistory()

    // Component State
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    
    // Form State
    const [otpid, setOtpId] = useState(otp.id)
    const [targetDescription, setTargetDescription] = useState(null)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        try {
            await AddOTPTarget(
                
                otpid,
                {
                    targetDescription,
                    timestamp: new Date(),
                    obsolete: false,
                    otpNumber: otp.otpNumber
                },
                currentUser.email
            ).then((targetId) => {
                setLoading(false)
                closeDialog()

                history.push(`/target/${otpid}/${targetId}/programs`)
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }
    
    return (
        <>
            <Dialog open={open} maxWidth="sm" fullWidth onClose={closeDialog}>
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography>Add Target</Typography>
                                <Typography><small>Objective # { otp.otpNumber }</small></Typography>
                                <br/>
                                <TextField 
                                    className={'iso-text-field'} 
                                    {...textFieldProps} 
                                    label="Target Description" 
                                    multiline 
                                    rows={3} 
                                    required 
                                    onChange={(e) => setTargetDescription(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                        { error ? <span className={'iso-error'}>{error}</span> : null }
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={closeDialog} color="secondary">Cancel</Button>
                        <Button disabled={loading} type="submit" variant="contained"  color="primary" startIcon={<Save />}>Submit</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}
