import {
  Button,
  Card,
  CardActions,
  CardContent,
  Snackbar,
  Typography,
} from "@mui/material";
import { ArrowForward, Save, AttachFile } from "@mui/icons-material";
import React, { useState } from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import CostComponent from "./CostComponent";
import { Alert } from "@mui/material";
import { useAuth } from "../../../contexts/AuthContext";

export default function MocCostEstimates({ moc, props, approvalView }) {
  const { handleAdvance, setMoc, handleUpdate } = props;
  const [lineItems, setLineItems] = useState(
    moc.costs || [{ label: "", cost: 0 }]
  );
  const [totalCost, setTotalCost] = useState(0);
  const { updateDocFromPath } = useDb();
  const [loading, setLoading] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const { currentUser } = useAuth()

  const getFileObj = async (fileObj) => {
    await setMoc({
      ...moc,
      costAttachments: moc.costAttachments
        ? [...moc.costAttachments, fileObj]
        : [fileObj],
    });
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      await handleUpdate({ totalCost: parseFloat(totalCost) });
      await updateDocFromPath(moc.path, { costs: lineItems });
      setSnackOpen(true);
    } catch (err) {
      window.alert(err.message);
    }
    setLoading(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    await handleSave();
    handleAdvance();
    setLoading(false);
  };

  return (
    <div>
      <Card elevation={0}>
        <CardContent>
          <CostComponent
            approvalView={approvalView}
            lineItems={lineItems}
            setLineItems={setLineItems}
            totalCost={totalCost}
            setTotalCost={setTotalCost}
          />
          <br />
          <Typography variant="h6">Total Cost: {currentUser.currency ? currentUser.currency.value : ""} {totalCost}</Typography>
        </CardContent>
        {!approvalView && (
          <CardActions
            style={{
              float: "right",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<Save />}
              onClick={handleSave}
              disabled={loading}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="primary"
              endIcon={<ArrowForward />}
              onClick={handleSubmit}
              disabled={loading}
            >
              Next
            </Button>
          </CardActions>
        )}
      </Card>

      <Snackbar
        open={snackOpen}
        autoHideDuration={4000}
        onClose={() => setSnackOpen(false)}
      >
        <Alert
          onClose={() => setSnackOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
          variant="filled"
        >
          Changes Saved!
        </Alert>
      </Snackbar>
    </div>
  );
}
