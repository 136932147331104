import {
    Avatar,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Grid,
    LinearProgress,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ArrowLeft, EventBusy } from '@mui/icons-material'
import React, { useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    chip: {
        marginBottom: 5
    }
}))

export default function VenueDetail() {
    const classes = useStyles()
    const { id } = useParams()
    const { getTrainingVenue } = useDb()
    const [venue, setVenue] = useState()
    const [bookings, setBookings] = useState([])
    // Action State
    const [loaded, setLoaded] = useState(false)

    // const bookings = [
    //     { id: 1, startDate: new Date(), endDate:  new Date().setDate(15) },
    //     { id: 2, startDate: new Date().setDate(16), endDate:  new Date().setDate(18) },
    //     { id: 3, startDate: new Date().setDate(29), endDate:  new Date().setDate(30) },
    // ]


    const firstLoad = async () => {
        const v = await getTrainingVenue(id)
        setBookings(v.bookings)
        console.log(v)
        setVenue(v)
        setLoaded(true)
    }

    if(!loaded) {
        firstLoad()
        return <LinearProgress />
    }
    else return (
        <div>
            <h1>Training Venue: {venue.name}</h1>        
            <Link to={'/training-manager/venues'}>
                <Button variant="contained" startIcon={<ArrowLeft />} color="primary">Venues</Button>
            </Link>
            <br/><br/>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Card>
                        <CardContent>
                            <Typography>Venue Name: {venue.name}</Typography>
                            <Typography>Site: {venue.siteLabel}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Card>
                        <CardHeader
                            title="Bookings"
                            avatar={
                                <Avatar>
                                    <EventBusy />
                                </Avatar>
                            }
                        />
                        <CardContent>
                            {
                                bookings && bookings.map(b => (
                                    <div key={b.id}>
                                        <Chip color="secondary" className={classes.chip}  label={`${moment(b.startDate).format('MMMM Do YYYY, h:mm:ss a')} - ${moment(b.endDate).format('MMMM Do YYYY, h:mm:ss a')}`} /><br/>
                                    </div>
                                ))
                            }
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}
