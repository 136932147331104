import { Avatar, Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import { Warning } from '@mui/icons-material'
import React from 'react'

export default function RiskDetail({ open, close, risk }) {
    const handleClose = () => {
        close()
    }

    console.log(risk)

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
            <DialogTitle>
                Risk Level: {risk.category.value * risk.likelihood.value}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardContent>
                                <Typography><b>Consequences</b></Typography>
                                {
                                    risk.category.consequences && risk.category.consequences.map((c, i) => (
                                        <Card key={i} style={{ marginBottom: 5 }}>
                                            <CardHeader
                                                title={c.label}
                                                avatar={
                                                    <Avatar>
                                                        <span className="iso-small">
                                                        {c.code}
                                                        </span>
                                                    </Avatar>
                                                }
                                                subheader={c.description}
                                            />
                                        </Card>
                                    ))
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardContent>
                                <Typography><b>Likelihood</b></Typography>
                                <Typography>{risk.likelihood.wordModel}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}
