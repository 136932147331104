import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import {
  Drawer,
  AppBar,
  Toolbar,
  CssBaseline,
  IconButton,
  Typography,
  Avatar,
  Menu,
  MenuItem,
  Card,
  CardHeader,
} from "@mui/material/";
import MenuIcon from "@mui/icons-material/Menu";
import clsx from "clsx";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faBuilding,
  faDatabase,
  faDollarSign,
  faFileImport,
  faHeadset,
  faTachometerAlt,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../../img/gold_logo.png";
import nycIwan from "../../img/nycIwan.jpg";
import { Switch, Link, useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import Notifications from "../dashboard/notifications/Notifications";
import AdminRoutes from "../routes/AdminRoutes";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  icons: {
    color: "#ffca27",
    marginLeft: theme.spacing(2),
  },
  menuText: {
    color: "#fff",
    textDecoration: "none",
  },
  subMenuItem: {
    paddingLeft: 55,
  },
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: "#11163a",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    background: "#11163a",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    backgroundColor: "#262b49",
    height: "100vh", // <------ THIS May Cause issues remove if necessary
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const AdminMenu = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [profileMenuOpen, toggleProfileMenu] = useState(false);
  const [notificationMenuOpen, toggleNotificationMenu] = useState(false);
  const [docOpen, setDocOpen] = useState(false);
  const [masterListOpen, setMasterOpen] = useState(false);
  const [notifAnchorEl, setNotifAnchorEl] = useState(null);
  const { logOut } = useAuth();
  const history = useHistory();
  const [notifCount, setNotifCount] = useState(0);
  const [actionMenuOpen, setActionMenuOpen] = useState(false);
  const [otpMenuOpen, setOtpMenuOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const openProfileMenu = () => {
    toggleProfileMenu(true);
  };

  const closeProfileMenu = () => {
    toggleProfileMenu(false);
  };

  // const openNotificationMenu = () => {
  //     toggleNotificationMenu(true)
  // }

  // const closeNotificationMenu = () => {
  //     toggleNotificationMenu(null)
  // }

  const handleSignOut = async () => {
    try {
      await logOut();
      history.push("/login");
    } catch (err) {
      console.log(err.message);
    }
  };

  const updateNotifCount = (n) => {
    setNotifCount(n);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
            size="large">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap style={{ flex: 1 }}>
            <b>ISO Office</b>
          </Typography>
          <Avatar style={{ backgroundColor: "#11163a" }}>
            <FontAwesomeIcon icon={faHeadset} />
          </Avatar>
          &nbsp;
          <Menu
            anchorEl={notifAnchorEl}
            open={Boolean(notifAnchorEl)}
            keepMounted
            onClose={() => setNotifAnchorEl(null)}
          >
            <Notifications
              updateCount={updateNotifCount}
              closeMenu={() => setNotifAnchorEl(null)}
            />
          </Menu>
          <div style={{ marginRight: 15, cursor: "pointer" }}>
            {/* <Badge badgeContent={notifCount} color="secondary">
                                <NotificationsIcon onClick={(e) => setNotifAnchorEl(e.currentTarget)}/>
                            </Badge> */}
          </div>
          <Menu
            id="profile-menu"
            anchorEl={profileMenuOpen}
            //keepMounted
            open={Boolean(profileMenuOpen)}
            onClose={closeProfileMenu}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Card elevation={0}>
              <CardHeader
                avatar={<Avatar src={nycIwan} />}
                title="Iwan Pieterse"
                subheader="We Manage Your Site, Inc."
              />
            </Card>
            <MenuItem onClick={closeProfileMenu}>Profile</MenuItem>
            <MenuItem onClick={closeProfileMenu}>My account</MenuItem>
            <MenuItem onClick={handleSignOut}>Logout</MenuItem>
          </Menu>
          <Avatar
            src={
              "https://www.paidmembershipspro.com/wp-content/uploads/2015/09/Iwan-MBG-Media-300x300.jpg"
            }
            onClick={openProfileMenu}
            className="profileBtn"
          />
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <div style={{ width: "100%", padding: "10px 10px 10px 0" }}>
            <img src={logo} width="50" alt="ISO Logo" />
          </div>
          <IconButton style={{ color: "#fff" }} onClick={handleDrawerClose} size="large">
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <Link to="/" className="drawer-item">
            <ListItem>
              <ListItemIcon>
                <FontAwesomeIcon
                  className={classes.icons}
                  icon={faTachometerAlt}
                />
              </ListItemIcon>
              <ListItemText className={classes.menuText}>
                Dashboard
              </ListItemText>
            </ListItem>
          </Link>

          <Link to="/companies" className="drawer-item">
            <ListItem>
              <ListItemIcon>
                <FontAwesomeIcon className={classes.icons} icon={faBuilding} />
              </ListItemIcon>
              <ListItemText className={classes.menuText}>
                Companies
              </ListItemText>
            </ListItem>
          </Link>
          <Link to="/agents" className="drawer-item">
            <ListItem>
              <ListItemIcon>
                <FontAwesomeIcon
                  className={classes.icons}
                  icon={faDollarSign}
                />
              </ListItemIcon>
              <ListItemText className={classes.menuText}>
                Sales Agents
              </ListItemText>
            </ListItem>
          </Link>
          <Link to="/templates" className="drawer-item">
            <ListItem>
              <ListItemIcon>
                <FontAwesomeIcon
                  className={classes.icons}
                  icon={faFileImport}
                />
              </ListItemIcon>
              <ListItemText className={classes.menuText}>
                Document Templates
              </ListItemText>
            </ListItem>
          </Link>
          <Link to="/moc-templates" className="drawer-item">
            <ListItem>
              <ListItemIcon>
                <FontAwesomeIcon
                  className={classes.icons}
                  icon={faFileImport}
                />
              </ListItemIcon>
              <ListItemText className={classes.menuText}>
                MOC Templates
              </ListItemText>
            </ListItem>
          </Link>
          {/* <Link to='/users' className="drawer-item">
                            <ListItem>
                                <ListItemIcon><FontAwesomeIcon className={classes.icons} icon={faUsers} /></ListItemIcon>
                                <ListItemText className={classes.menuText}>Users</ListItemText>
                            </ListItem>
                        </Link> */}
          <Link to="/billing" className="drawer-item">
            <ListItem>
              <ListItemIcon>
                <FontAwesomeIcon
                  className={classes.icons}
                  icon={faDollarSign}
                />
              </ListItemIcon>
              <ListItemText className={classes.menuText}>Billing</ListItemText>
            </ListItem>
          </Link>
          <Link to="/update-manual" className="drawer-item">
            <ListItem>
              <ListItemIcon>
                <FontAwesomeIcon className={classes.icons} icon={faBook} />
              </ListItemIcon>
              <ListItemText className={classes.menuText}>
                User Manual
              </ListItemText>
            </ListItem>
          </Link>
          <Link to="/videos" className="drawer-item">
            <ListItem>
              <ListItemIcon>
                <FontAwesomeIcon className={classes.icons} icon={faYoutube} />
              </ListItemIcon>
              <ListItemText className={classes.menuText}>Videos</ListItemText>
            </ListItem>
          </Link>
          {/* <Link to='/data-mgmt' className="drawer-item">
                            <ListItem>
                                <ListItemIcon><FontAwesomeIcon className={classes.icons} icon={faDatabase} /></ListItemIcon>
                                <ListItemText className={classes.menuText}>Iwan Data Mgmt</ListItemText>
                            </ListItem>
                        </Link> */}
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <Switch>
          <AdminRoutes />
        </Switch>
      </main>
    </div>
  );
};

export default AdminMenu;
