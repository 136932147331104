import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
  IconButton,
  Switch,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { AddBox, Delete, Save } from "@mui/icons-material";
import React, { useState } from "react";
import { useDb } from "../../../../contexts/DatabaseContext";
import { textFieldProps } from "../../../shared/constants";
import { useEffect } from "react";
import { indexOf } from "lodash";

const useStyles = makeStyles((theme) => ({
  header: {
    background: theme.palette.primary.main,
  },
}));

export default function AddCheckbox({ open, close, checklistId, sectionId }) {
  const classes = useStyles();
  const { addChecklistField, updateChecklistSectionScore} = useDb();

  const [state, setState] = useState({
    options: [],
    name: null,
  });

  const [actionState, setActionState] = useState({
    loading: false,
    error: null,
  });

  const [locked, setLocked] = useState(false);

  const [option, setOption] = useState();

  const [score, setScore] = useState()

  useEffect(() => {setLocked(false)}, [open])

  const handleChange = (e) => {
    if (e.target.name == 'score') {
      setScore(e.target.value)
    } else
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  
  const handleOptionChange = (e) => {
    setOption(e.target.value);
  };
  
  const handleClose = () => {
    setState({
      options: [],
    });
    setOption(null);
    setActionState({ error: null, loading: false });
    close();
  };
  
  const handleAdd = () => {
    if (state.options.includes(option)) {
      window.alert("That option has already been added");
      return;
    }
    if (score == 0) {
      setState({
        ...state,
        options: [...state.options, option],
      });
    } else {
      setState({
        ...state,
        options: [...state.options, option],
        score: state.score ? [...state.score, parseInt(score)] : [parseInt(score)]
      });
    }
    setOption("");
    setScore(0)
  };
  console.log(state.options);
  console.log(state)

  const handleSubmit = async (e) => {
    e.preventDefault();
    setActionState({ loading: true, error: null });
    try {
      if(state.score) {
        await updateChecklistSectionScore(checklistId, sectionId, state.score, 'checkbox');
      }
      await addChecklistField(checklistId, sectionId, "checkbox", state);
      handleClose();
    } catch (err) {
      setActionState({ error: err.message, loading: false });
    }
  };

  const handleDelete = (o) => {
    if (state.score) {
      var filteredScore = state.score.filter(function (value, index, arr) {
        return index !== indexOf(state.options, o);
      });
      var filtered = state.options.filter(function (value, index, arr) {
        return value !== o;
      });
      setState({
        ...state,
        options: filtered,
        score: filteredScore
      });
    } else {
      var filtered = state.options.filter(function (value, index, arr) {
        return value !== o;
      });
      setState({
        ...state,
        options: filtered
      });
    }
  };

  const handleSwitch = (e) => {
    const confirm = window.confirm(
      "This will lock this question as scoring, any options without a score will be scored at zero. Proceed?"
    );
    setState({
      ...state,
      [e.target.name]: e.target.checked,
    });
    setLocked(true);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle className={classes.header}>Add Checkboxes</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            {...textFieldProps}
            label="Field Name"
            onChange={handleChange}
            name="name"
            required
          />

          <Typography>Options:</Typography>
          <FormGroup>
            {state.options &&
              state.options.map((o) => (
                <>
                  <div>
                    <FormControlLabel
                      key={o}
                      control={<Checkbox />}
                      label={o}
                    />
                    <IconButton className="iso-right" onClick={() => handleDelete(o)} size="large">
                      <Delete />
                    </IconButton>
                  </div>
                </>
              ))}
          </FormGroup>
          <br />
          <TextField
            label="Option Name"
            {...textFieldProps}
            onChange={handleOptionChange}
            value={option}
          />
          {state.scoringField && (
            <TextField
              type="number"
              onChange={handleChange}
              value={score}
              name="score"
              {...textFieldProps}
              label="Score"
            />
          )}

          <Button
            disabled={!option}
            color="primary"
            onClick={handleAdd}
            variant="contained"
            fullWidth
            startIcon={<AddBox />}
          >
            Add Option
          </Button>
          {actionState.error ? (
            <span className="wireline-error">{actionState.error}</span>
          ) : null}
          <FormControlLabel
            control={
              <Switch
                checked={state.scoringField}
                onChange={handleSwitch}
                name="scoringField"
                color="primary"
                disabled={locked}
              />
            }
            label="Scoring Field"
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={actionState.loading}
            color="secondary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            disabled={actionState.loading || state.options.length < 1}
            variant="contained"
            type="submit"
            color="primary"
            startIcon={<Save />}
          >
            Add to Section
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
