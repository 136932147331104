import { Card, CardContent } from '@mui/material'
import React from 'react'

export default function MgBilling() {
    return (
        <Card>
            <CardContent>
                Here you'll see which companies have which modules activated
            </CardContent>
        </Card>
    )
}
