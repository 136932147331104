import React from "react";
import { useDb } from "../../../../contexts/DatabaseContext";
import SectionsContainer from "../sections/SectionsContainer";

export default function IsoRenderForm({
  formId,
  collectionPath,
  docString,
  handleAdvance,
  approvalView,
}) {
  const { GetCollection } = useDb();
  const sections = GetCollection(
    false,
    `formTemplate/${formId}/sections`,
    null,
    ["order", "asc"]
  );

  return (
    <div>
      {sections && (
        <SectionsContainer
          render={true}
          sections={sections}
          docString={docString}
          collectionPath={collectionPath}
          handleAdvance={handleAdvance}
          approvalView={approvalView}
        />
      )}
    </div>
  );
}
