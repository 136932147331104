import { ButtonGroup, IconButton } from '@mui/material'
import { Delete, Edit } from '@mui/icons-material'
import React, { useState } from 'react'
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid'
import ConfirmUserDelete from './ConfirmUserDelete'
import UserAccess from './UserAccess'
import { useAuth } from '../../../contexts/AuthContext'

export default function UserListDataGrid({users}) {
  const { currentUser } = useAuth()

  const rows = users.map((u, i) => ({
    index: i,
    id: u.id,
    name: `${u.label}`,
    user: u
  }))
  
  // Manage User Access
  const [accessOpen, setAccessOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)

  // User Deletion
  const [deleteOpen, setDeleteOpen] = useState(false)


  const openUserAccess = (u) => {
    setSelectedUser(u)
    setAccessOpen(true)
  }
  
  const closeUserAccess = () => {
    setAccessOpen(false)
    setSelectedUser(null)
  }

  const openDelete = (u) => {
    setSelectedUser(u)
    setDeleteOpen(true)
  }

  const closeDelete = () => {
    setSelectedUser(null)
    setDeleteOpen(false)
  }

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 450
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => {
        return (
          <ButtonGroup>
            <IconButton
              onClick={() => {
                openUserAccess(params.row.user)
              }}
              size="large">
              <Edit />
            </IconButton>
            {
              !params.row.user.companyOwner && (currentUser.companyOwner || currentUser.companyAdmin) && <IconButton
                onClick={() => {
                  openDelete(params.row.user)
                }}
                size="large">
                <Delete />
              </IconButton>
            }
          </ButtonGroup>
        );
      }
    }
  ]

  return (
    <div>
      <IsoDataGrid
        data={rows}
        columns={columns}
      />
      {
        selectedUser &&
        <UserAccess open={accessOpen} user={selectedUser} closeDialog={closeUserAccess} />
      }
      {
        selectedUser &&
        <ConfirmUserDelete user={selectedUser} open={deleteOpen} closeDialog={closeDelete} deleteComplete={closeDelete} />
      }
    </div>
  )
}
