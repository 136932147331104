import { Button, Card, CardContent, Grid, Typography } from '@mui/material'
import { ArrowForward, Phone } from '@mui/icons-material'
import { Alert } from '@mui/material';
import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import TrialExpiredUsers from './TrialExpiredUsers'

export default function TrialExpired() {
    const { currentUser } = useAuth()

    if(!currentUser.companyOwner) {
        return <TrialExpiredUsers />
    }
    else return (
        <div>
            <Card>
                <CardContent>
                    <Typography variant='h4'>Your Free Trial has expired</Typography>
                    <br/>
                    <Alert severity="info">
                        It seems you have been using our service for more than 14 days. We hope you enjoyed it. If you would like to continue using our service, please subscribe:
                    </Alert>
                    <br/>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardContent>
                                    <Typography variant='h5'>Ready to go?</Typography>
                                    <br/>
                                    <Link to="/addplan">
                                        <Button variant='contained' color='primary' fullWidth endIcon={<ArrowForward />}>
                                            Subscribe
                                        </Button>
                                    </Link>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardContent>
                                    <Typography variant='h5'>Talk to us first?</Typography>
                                    <br/>
                                    <Button href="https://calendly.com/iso-office/iso-software-introduction?back=1&month=2022-06" target="_blank" rel="noopener noreferrer" variant='contained' color='primary' fullWidth endIcon={<Phone />}>
                                        Schedule a call
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            
        </div>
    )
}
