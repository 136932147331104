import { Button, Dialog, DialogActions, DialogContent, Grid, TextField, Typography } from '@mui/material'
import { Save } from '@mui/icons-material'
import React, { useState } from 'react'
import Select from 'react-select'
import { useDb } from '../../../../contexts/DatabaseContext'
import { selectFieldProps, textFieldProps } from '../../../shared/constants'

export default function AddAreas({open, closeDialog}) {

  const { GetSites, addRiskAreas } = useDb()
  const sites = GetSites()
  const [state, setState] = useState({
    error: null,
    loading: false,
    data: {}
  })

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      setState({
        ...state,
        loading: true,
        error: null
      })
      await addRiskAreas(state.data)
      setState({
        ...state,
        loading: false
      })
      closeDialog()
    } catch (err) {
      setState({
        ...state,
        loading: false,
        error: err
      })
    }
  }

  const handleChange = (e) => {
    setState({
      ...state,
      data: {
        ...state.data,
        [e.target.name]: e.target.value
      }
    })
  }

  return (
    <div>
      <Dialog open={open} maxWidth="sm" fullWidth onClose={closeDialog} >
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Typography>Add Area</Typography>
              <br/>
              <TextField 
                  className={'iso-text-field'} 
                  {...textFieldProps} 
                  label="Area Name"
                  required 
                  name="areaName"
                  onChange={handleChange}
              />
              <Select
                options={sites}
                {...selectFieldProps}
                placeholder={'Select Site ...'}
                onChange={(val) => setState({
                  ...state,
                  data: {
                    ...state.data,
                    site: {
                      id: val.value,
                      name: val.label
                    }
                  }
                })}
                className={'iso-select'}
              />
                { state.error ? <span className={'iso-error'}>{state.error}</span> : null }
            </DialogContent>
            <DialogActions>
              <Button disabled={state.loading} onClick={closeDialog} color="secondary">Cancel</Button>
              <Button disabled={state.loading || !state.data.site } onClick={handleSubmit} type="submit" variant="contained" color="primary" startIcon={<Save />}>Submit</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  )
}
