import React, { useEffect, useState } from "react";
import { useDb } from "../../contexts/DatabaseContext";
import RegisterViewer from "../shared/RegisterViewer";
import CreateOTP from "./CreateOTP";
import { amber } from "@mui/material/colors";
import makeStyles from '@mui/styles/makeStyles';
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import Filters from "../docmanager/doc-register/filters/Filters";
import { isEmpty } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    width: 500,
    position: "relative",
    minHeight: 200,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(6),
    right: theme.spacing(7),
    backgroundColor: amber[500],
    "&:hover": {
      backgroundColor: amber[600],
    },
  },
  tab: {
    indicatorColor: theme.palette.primary,
  },
  icon: {
    color: theme.palette.primary,
  },
  tabPanel: {
    backgroundColor: theme.palette.main,
  },
  dialog: {
    backgroundColor: theme.palette.dialogBg,
  },
  search: {
    color: theme.palette.isotext,
  },
}));

export default function OtpRegister() {
  const [createOpen, setCreateOpen] = useState(false);
  const { GetOTPs, generateOtpRegisterPdf } = useDb();
  const otps = GetOTPs(false);
  const [dialogOpen, setDialog] = useState(false);
  const [data, setData] = useState(otps);
  const filterObj = {
    department: {},
    hod: {},
    criteria: {},
  };

  const [filters, setFilters] = useState(filterObj);
  const classes = useStyles();

  const openCreate = () => {
    setCreateOpen(true);
  };

  useEffect(() => {
    setData(otps);
  }, [otps]);

  useEffect(() => {
    setData(otps.filter((item) => filterByDropdowns(item, filters)));
  }, [filters]);

  const closeCreate = () => {
    setCreateOpen(false);
  };

  const receiveFilters = (f) => {
    setDialog(false);
    setFilters(f);
  };

  useEffect(() => {
    console.log("otp register filtered data is: ", data);
  }, [data]);

  const filterByDropdowns = (doc, f) => {
    if (isEmpty(f.department) && isEmpty(f.criteria)) return true;
    let dpt = f.department?.label
      ? doc["department"].name === f.department?.label
      : true;
    let type = f.criteria?.label
      ? doc["criteria"].name === f.criteria?.label
      : true;
    // let owner = f.hod?.label ? doc["hod"].name === f.hod?.label : true;
    return dpt && type; //&& owner;
  };

  const handleChipDelete = (f) => {
    setFilters({ ...filters, [f]: {} });
  };

  const getOtpPdf = async () => {
    try {
      await generateOtpRegisterPdf(data)
    }
    catch(err) {
      console.log(err.message)
    }
  }

  return (
    <div>
      <RegisterViewer
        addItemFunction={openCreate}
        type={"otps"}
        data={data}
        openDialog={setDialog}
        filterItems={filters}
        handleChipDelete={handleChipDelete}
        pdfFunction={getOtpPdf}
      />
      <CreateOTP open={createOpen} closeDialog={closeCreate} />
      <Dialog
        maxWidth="sm"
        fullWidth 
        open={dialogOpen}
        onClose={() => setDialog(false)}
      >
        <DialogTitle className={classes.dialog}>Filters</DialogTitle>
        <DialogContent className={classes.dialog}>
          <Filters
            sendFilters={receiveFilters}
            selectedFilter={filters}
            isOptFilters={true}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
