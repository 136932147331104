import { Button, Dialog, DialogActions, DialogContent, TextField, Typography } from '@mui/material'
import { Save } from '@mui/icons-material'
import React, { useState } from 'react'
import Select from 'react-select'
import { useDb } from '../../../../contexts/DatabaseContext'
import { selectFieldProps, textFieldProps } from '../../../shared/constants'

export default function AddRiskTypes({open, closeDialog}) {

  const { GetIsoClasses, addRiskType } = useDb()
  const isoClasses = GetIsoClasses()
  
  const [state, setState] = useState({
    error: null,
    loading: false,
    data: {
      riskType: null,
      isoClass: {value: null},
      // isoAgency: null
    }
  })
  
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      setState({
        ...state,
        loading: true,
        error: null
      })
      await addRiskType(state.data)
      setState({
        ...state,
        loading: false
      })
      closeDialog()
    } catch (err) {
      setState({
        ...state,
        loading: false,
        error: err
      })
    }
  }

  return (
    <div>
      <Dialog open={open} maxWidth="sm" fullWidth onClose={closeDialog} >
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Typography>Add Risk Type</Typography>
              <br/>
              <TextField 
                  className={'iso-text-field'} 
                  {...textFieldProps} 
                  label="Risk Type"
                  required 
                  // onChange={(e) => console.log(e.target.value)}
                  onChange={(e) => setState({
                    ...state,
                    data: {
                      riskType: e.target.value,
                      isoClass: state.data.isoClass
                    }
                  })}
              />
              <Select
                options={isoClasses}
                {...selectFieldProps}
                placeholder={'Select ISO Classification ...'}
                // onChange={(val) => setIsoClass(val.value)}
                onChange={(val) => setState({
                  ...state,
                  data: {
                    riskType: state.data.riskType,
                    isoClass: val
                  }
                })}
                className={'iso-select'}
              />
              
                { state.error ? <span className={'iso-error'}>{state.error}</span> : null }
            </DialogContent>
            <DialogActions>
              <Button disabled={state.loading} onClick={closeDialog} color="secondary">Cancel</Button>
              <Button 
                disabled={
                  state.loading ||
                  !state.data.riskType ||
                  !state.data.isoClass.value
                } 
                onClick={handleSubmit} 
                type="submit" 
                variant="contained" 
                color="primary" 
                startIcon={<Save />}>
                  Submit
                </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  )
}
