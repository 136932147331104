import {
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
  Grid,
  CardHeader,
  Avatar,
} from "@mui/material";
import { ArrowLeft, Save } from "@mui/icons-material";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { selectFieldProps, textFieldProps } from "../../shared/constants";
import Select from "react-select";
import { useDb } from "../../../contexts/DatabaseContext";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import FileUploader from "../../shared/FileUploader";
import { useAuth } from "../../../contexts/AuthContext";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

export default function AddTrainingModule() {
  const { currentUser } = useAuth();
  const {
    GetDepartments,
    GetUsersLevelFilter,
    GetTrainingRisks,
    GetTrainingProviders,
    createTrainingModule,
    GetOccupations,
  } = useDb();
  const depts = GetDepartments();
  const hods = GetUsersLevelFilter("trainingManager", 1);
  const risks = GetTrainingRisks();
  const providers = GetTrainingProviders();
  const jobs = GetOccupations();
  const history = useHistory();

  const [state, setState] = useState({
    practicalRequired: false,
    competencyRequired: false,
    targetRevDate: new Date(),
    evaluationRequested: false,
    external: false,
    occupations: [],
  });

  // Action State
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheck = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.checked,
    });
  };

  const handleSelect = (v, e) => {
    setState({
      ...state,
      [e.name]: v.value,
    });
  };

  const handleJobsSelect = (val) => {
    const array = val.map((v) => ({
      id: v.id,
      title: v.title,
    }));
    console.log(array);
    setState({
      ...state,
      occupations: array,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting");
    setError(null);
    setLoading(true);
    try {
      await createTrainingModule(state).then((res) => {
        history.push(`/training-manager/module/${res.id}/${res.code}`);
      });
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const getUrl = (url) => {
    setState({
      ...state,
      ptoUrl: url,
    });
  };

  const getPracticalUrl = (url) => {
    setState({
      ...state,
      practicalUrl: url,
    });
  };

  const getFilename = (n) => {};

  const getFileObj = (o) => {};

  return (
    <div>
      <h1>Add Training Module</h1>
      <Link to={"/training-manager/modules"}>
        <Button variant="contained" startIcon={<ArrowLeft />} color="primary">
          Training Modules
        </Button>
      </Link>
      <br />
      <br />
      <Card>
        <form onSubmit={handleSubmit}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  {...textFieldProps}
                  label="Module Name"
                  name="name"
                  required
                  onChange={handleChange}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.practicalRequired}
                      onChange={handleCheck}
                      name="practicalRequired"
                      color="primary"
                    />
                  }
                  label="Practical Assessment Required"
                />
                <br />
                {state.practicalRequired ? (
                  <Card>
                    <CardHeader
                      title="Practical Assessment"
                      avatar={
                        <Avatar>
                          <AssignmentTurnedInIcon />
                        </Avatar>
                      }
                    />
                    <CardContent>
                      <Typography>
                        Upload Practical Assessment Template
                      </Typography>
                      <FileUploader
                        getFileObj={getFileObj}
                        getFilename={getFilename}
                        getUrl={getPracticalUrl}
                        userId={currentUser.uid}
                      />
                    </CardContent>
                  </Card>
                ) : null}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.competencyRequired}
                      onChange={handleCheck}
                      name="competencyRequired"
                      color="primary"
                    />
                  }
                  label="Include on Competency Certificate"
                />

                <Select
                  options={depts}
                  placeholder="Select Department ..."
                  onChange={handleSelect}
                  {...selectFieldProps}
                  className="iso-select"
                  name="department"
                />
                <input
                  autoComplete="off"
                  style={{ opacity: 0, height: 0, marginTop: -20, width: 0 }}
                  value={state.department}
                  required
                />
                <br />
                <Select
                  options={hods}
                  placeholder="Select Module Owner ..."
                  onChange={handleSelect}
                  {...selectFieldProps}
                  className="iso-select"
                  name="owner"
                />
                <input
                  autoComplete="off"
                  style={{ opacity: 0, height: 0, marginTop: -20, width: 0 }}
                  value={state.owner}
                  required
                />
                <br />
                <TextField
                  {...textFieldProps}
                  label="Frequency of Occurences (Days)"
                  type="number"
                  name="frequency"
                  required
                  onChange={handleChange}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.external}
                      onChange={handleCheck}
                      name="external"
                      color="primary"
                    />
                  }
                  label="External Training Provider?"
                />
                {state.external ? (
                  <>
                    <Select
                      options={providers}
                      placeholder="Select External Provider ..."
                      onChange={handleSelect}
                      {...selectFieldProps}
                      className="iso-select"
                      name="provider"
                    />
                    <input
                      autoComplete="off"
                      style={{
                        opacity: 0,
                        height: 0,
                        marginTop: -20,
                        width: 0,
                      }}
                      value={state.provider}
                      required
                    />
                    <br />
                  </>
                ) : null}
              </Grid>
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Target Revision Date"
                    onChange={(d) =>
                      setState({ ...state, targetRevDate: d.toDate() })
                    }
                    defaultValue={dayjs(state.targetRevDate)}
                  />
                </LocalizationProvider>
                <br />
                <br />
                <Select
                  options={risks}
                  placeholder="Select Risk Level ..."
                  onChange={handleSelect}
                  {...selectFieldProps}
                  className="iso-select"
                  name="risk"
                />
                <input
                  autoComplete="off"
                  style={{ opacity: 0, height: 0, marginTop: -20, width: 0 }}
                  value={state.risk}
                  required
                />
                <br />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.evaluationRequested}
                      onChange={handleCheck}
                      name="evaluationRequested"
                      color="primary"
                    />
                  }
                  label="Evaluation Requested"
                />
                {state.evaluationRequested ||
                (state.risk && state.risk.name === "High") ? (
                  <>
                    <Typography>Upload PTO Template</Typography>
                    <FileUploader
                      getFileObj={getFileObj}
                      getFilename={getFilename}
                      getUrl={getUrl}
                      userId={currentUser.uid}
                    />
                    <input
                      autoComplete="off"
                      style={{
                        opacity: 0,
                        height: 0,
                        marginTop: -20,
                        width: 0,
                      }}
                      value={state.ptoUrl}
                      required
                    />
                    <br />
                  </>
                ) : null}
                <br />
                <Select
                  {...selectFieldProps}
                  className="iso-select"
                  options={jobs}
                  isMulti
                  placeholder="Required for Occupations ..."
                  onChange={handleJobsSelect}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              startIcon={<Save />}
              disabled={!state.targetRevDate || loading || !state.department}
              type="submit"
            >
              Create Module
            </Button>
            {error ? <span className="iso-error">{error}</span> : null}
          </CardActions>
        </form>
      </Card>
    </div>
  );
}
