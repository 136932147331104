import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { DeleteForever, Warning } from '@mui/icons-material'
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'

export default function ConfirmUserDelete({ user, open, closeDialog, deleteComplete, fromManage }) {
    const { deleteUser, managerDeleteUser } = useDb()

    // Action State
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const handleClose = () => {
        setLoading(false)
        setError(null)
        closeDialog()
    }

    const handleConfirm = async () => {
        setError(null)
        setLoading(true)
        try {
            if(fromManage) {
                await managerDeleteUser(user.companyId, user.id).then(() => {
                    handleClose()
                    deleteComplete()
                })
            }
            else {
                await deleteUser(user.id).then(() => {
                
                    handleClose()
                    deleteComplete()
                })
            }
            

        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                <Warning color="secondary"/> Delete User: {user.firstName} {user.lastName} ? 
            </DialogTitle>
            <DialogContent>
                Deleting this user will remove the user from the following items: <br/>
                <ul>
                    <li>Rosters</li>
                    <li>Groups</li>
                    <li>Document Approvals</li>
                    <li>Document Interested Parties</li>
                    <li>Action Interested Parties</li>
                </ul>
                <br/>
                The user audit trail will remain in place for historical data, as well as any history of actions taken on any system item.

                <br/><br/>
                <Typography><b>Are you sure you want to Delete this user?</b></Typography>

            </DialogContent>
            <DialogActions>
                { error ? <span className="iso-error">{error}</span> : null }
                <Button disabled={loading} color="secondary" onClick={handleClose}>Cancel</Button>
                <Button disabled={loading} color="secondary" variant="contained" startIcon={<DeleteForever />} onClick={handleConfirm}>Confirm Delete</Button>
            </DialogActions>
        </Dialog>
    )
}
