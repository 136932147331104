import {
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
    Menu,
    MenuItem,
    Avatar,
    IconButton,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Paper,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react'
import moment from 'moment'
import InterestedParties from './interested-parties/InterestedParties'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import RevisionDetails from './RevisionDetails'
import EditRevision from './EditRevision'
import { useAuth } from '../../../contexts/AuthContext'

export default function RevisionListItem({ rev, doc, counter }) {
    const { currentUser } = useAuth()
    const [anchorEl, setAnchorEl] = useState(null)
    const [dest, setDest] = useState(null)
    const [open, setOpen] = useState(false)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)

    const openDialog = (dest) => {
        setDest(dest)
        setAnchorEl(null)
        setOpen(true)
    }

    return (
        <ListItem component={Paper}>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                
            >
                <MenuItem onClick={() => setDetailsOpen(true)}>View</MenuItem>
                {
                    currentUser.companyAdmin || currentUser.accessRights.docManager <= 1 ?
                    <MenuItem onClick={() => setEditOpen(true)}>Edit</MenuItem>
                    :
                    null
                }
                {
                    currentUser.companyAdmin || currentUser.accessRights.docManager <= 1 ?
                    <MenuItem onClick={() => openDialog(<InterestedParties doc={doc} isRevision={true} revId={rev.id}/>)}>Approvers</MenuItem>
                    :
                    null
                }
                
            </Menu>

            <ListItemAvatar>
                {/* <Avatar>
                   
                </Avatar> */}
                {rev.revCode}
            </ListItemAvatar>
            <ListItemText>
                <Typography style={{ fontSize: 12 }} noWrap>Target Revision Date: {moment(rev.targetRevDate.toDate()).format('DD/MM/YYYY')} </Typography>
            </ListItemText>
            <ListItemSecondaryAction>
                <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} size="large">
                    <FontAwesomeIcon icon={faEllipsisH} />
                </IconButton>
            </ListItemSecondaryAction>

            <Dialog maxWidth="sm" fullWidth open={open} onClose={() => setOpen(false)}>
                <DialogContent>
                    { dest }
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={() => setOpen(false)}>Close</Button>
                </DialogActions>
            </Dialog>

            <RevisionDetails rev={rev} open={detailsOpen} closeDialog={() => setDetailsOpen(false)} />
            <EditRevision rev={rev} doc={doc} open={editOpen} closeDialog={() => setEditOpen(false)} />

        </ListItem>
    );
}
