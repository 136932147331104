import { Button, TextField } from '@mui/material'
import React, { useState } from 'react'
import Select from 'react-select'
import { useAuth } from '../../../contexts/AuthContext'
import { useDb } from '../../../contexts/DatabaseContext'
import { docCategories, selectFieldProps, extendedDocCategories } from '../../shared/constants'

export default function AddDocType({ closeDialog }) {
    const { saveDocumentType } = useDb()
    const { currentUser } = useAuth()

    const [docType, setDocType] = useState('')
    const [cat, setCat] = useState('')
    const [docTypeCode, setDocTypeCode] = useState('')

    const saveDocType = async () => {
        const result = await saveDocumentType({ type: docType, category: cat, code: docTypeCode })
        if (result !== null) {
            closeDialog()
        }
        else {
            console.log('Error saving to database')
        }
    }

    return (
        <>
            <TextField label='Document Type' onChange={(e) => setDocType(e.target.value)} variant="outlined" fullWidth /><br/><br/>
            <TextField label='Document Type Code (Optional) ie: AIG' onChange={(e) => setDocTypeCode(e.target.value)} variant="outlined" fullWidth />
            <br/><br/>
            <Select 
                options={currentUser.extendedDocs ? extendedDocCategories : docCategories}
                onChange={(val) => setCat(val.value)}
                {...selectFieldProps}
                placeholder="Select Document Category ..."
            />
            <div className="iso-right">
                <Button onClick={saveDocType} color="primary">Confirm</Button>
                <Button onClick={closeDialog} color="secondary">Cancel</Button>
            </div>
        </>
    )
}
