import { Button, IconButton, LinearProgress, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@mui/material'
import { Add, ArrowForward, Warning } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useDb } from '../../../../contexts/DatabaseContext'
import BackLink from '../../../shared/BackLink'
import IsoDataGrid from '../../../shared/data-grid/IsoDataGrid'
import AddHazard from './AddHazard'

export default function BlraProcessStepHazards() {
    const { blraId, stepId, stepName, processId } = useParams();
    const { GetHazardsForStep, getSingleStep } = useDb();
    const hazards = GetHazardsForStep(blraId, stepId);
    const [addOpen, setAddOpen] = useState(false);
    const [step, setStep] = useState()

    const getStep = async () => {
        const s = await getSingleStep(blraId, stepId);
        setStep(s)
    }

    useEffect(() => {
      const unsubscribe = getStep();
      return unsubscribe;
    }, [blraId, stepId])
    

    const rows = hazards && hazards.map(h => ({
        ...h
    }));

    // /risk-manager/baselines/:blraId/hazards/:hazardId
    const columns = [
        { field: "name", headerName: "Hazard", width: 500 },
        { 
            field: 'action', 
            headerName: 'Detail' , 
            width: 300,
            renderCell: (params) => {
              return(
                <Link to={`/risk-manager/baselines/${blraId}/hazards/${params.row.id}`}>
                    <Button color="primary" size="small" variant="contained" endIcon={<ArrowForward />}>
                        Hazard Detail
                    </Button>
                </Link>
              )
            }
        },
    ];

    if(!step) {
        return <LinearProgress />
    }
    else return (
        <div>
            <h1>Hazards for Step: {step.value}</h1>
            <BackLink route={`/risk-manager/baselines/add-baseline-3/${blraId}/`} />
            <br/>
            <Button 
                color="primary" 
                variant="contained" 
                size="small" 
                startIcon={<Add />}
                onClick={() => setAddOpen(true)}
            >
                Add Hazard
            </Button>
            <IsoDataGrid 
                data={rows}
                columns={columns}
            />
            <AddHazard
                open={addOpen}
                close={() => setAddOpen(false)}
                blraId={blraId}
                process={processId}
                step={stepId}
            />
        </div>
    )
}
