import { faEnvelope, faMinusCircle, faPaperPlane, faPlusSquare, faUserTag } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Button, Card, CardContent, CardHeader, Chip, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material'
import { AddBox, Send } from '@mui/icons-material'
import React, { useState } from 'react'
import Select from 'react-select'
import { useDb } from '../../../../contexts/DatabaseContext'
import { selectFieldProps } from '../../../shared/constants'

export default function InterestedParties({ doc, isRevision, revId }) {
    const { 
        savePartyToRevision, 
        savePartyToDoc, 
        removePartyFromDoc, 
        removePartyFromRevision, 
        bulkEmailDocParties, 
        bulkEmailRevParties, 
        GetInterestedParties,
        GetUsersLevelFilter
    } = useDb()
    const rosters = GetUsersLevelFilter('docManager', 1)
    const parties = GetInterestedParties(doc.id, isRevision, revId)
    const [party, setParty] = useState(null)
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [emailDisable, setEmailDisable] = useState(false) 
    
    const addParty = async (party, isRevision, revId) => {
        setLoading(true) 
        if(isRevision) {
            const result = await savePartyToRevision(party, doc.id, revId, `${doc.docCode} ${doc.docName}`)
            if (result !== null) {
                setParty(null)
                setLoading(false)
                setEmailDisable(false)
            }
            else {
                setError('Error Saving to Database')
                setLoading(false)
                setEmailDisable(true)
            }
        }
        else {
            const result = await savePartyToDoc(party, doc.id)
            if (result !== null) {
                setParty(null)
                setLoading(false)
                setEmailDisable(false)
            }
            else {
                setError('Error Saving to Database')
                setLoading(false)
                setEmailDisable(true)
            }
        }
    }

    const removeParty = async (party, isRevision, revId) => {
        if(isRevision) {
            await removePartyFromRevision(doc.id, party.id, revId)
        }
        else {
            await removePartyFromDoc(doc.id, party.id)
        }
    }

    const emailAll = async () => {
        
        if(isRevision) {
            const result = await bulkEmailRevParties(doc.id, revId, parties)
            if (result !== null) {
                setEmailDisable(true)
            }
            else {
                console.log('Error saving to Database Email Log')
            }
        }
        else {
            const result = await bulkEmailDocParties(doc.id, parties)
            if (result !== null) {
                setEmailDisable(true)
            }
            else {
                console.log('Error saving to Database Email Log')
            }
        }
    }

    const emailOne = async (pty) => {
        if(isRevision) {
            console.log('Here!')
            const result = await bulkEmailRevParties(doc.id, revId, [pty])
            if (result !== null) {
                setEmailDisable(true)
            }
            else {
                console.log('Error saving to Database Email Log')
            }
        }
        else {
            const result = await bulkEmailDocParties(doc.id, [pty])
            if (result !== null) {
                setEmailDisable(true)
            }
            else {
                console.log('Error saving to Database Email Log')
            }
        }
    }

    return <>
        <Select 
            options={rosters}
            placeholder="Add Approver ..."
            onChange={(val => setParty(val))}
            {...selectFieldProps}
        />
        <span className="iso-small" style={{ color: 'red' }}>{error}</span>
        <br/>
        <Button onClick={() => addParty(party, isRevision, revId)} disabled={party !== null ? false : true } color="primary" variant="contained" startIcon={<AddBox />}>Add New</Button>&nbsp;
        <Button disabled={emailDisable} onClick={emailAll} color="primary" variant="contained" startIcon={<Send />}>Send Email</Button>
        <br/><br/>
        
        <Card>
            <CardHeader
                title="Approvers:"
                avatar={<Avatar><FontAwesomeIcon icon={faUserTag}/></Avatar>}
            />
            <CardContent>
                <List>
                    {
                        parties && parties.map((pty) => {
                            return (
                                <ListItem key={pty.id} className="iso-list-item">
                                    <ListItemText><span className="iso-small">{`${pty.firstName} ${pty.lastName}`} <Chip size="small" label={pty.status}/></span></ListItemText>
                                    <ListItemSecondaryAction>
                                        <IconButton onClick={() => removeParty(pty, isRevision, revId)} size="large"><FontAwesomeIcon icon={faMinusCircle}/></IconButton>
                                        <IconButton onClick={() => emailOne(pty)} size="large"><FontAwesomeIcon icon={faPaperPlane}/></IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })
                    }
                </List>
            </CardContent>
        </Card>
    </>;
}
