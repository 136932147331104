import React, { useEffect, useState } from "react";
import RegisterViewer from "../../shared/RegisterViewer";
import { amber } from "@mui/material/colors";
import makeStyles from '@mui/styles/makeStyles';
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import Filters from "../doc-register/filters/Filters";
import { isEmpty } from "lodash";
import moment from "moment";
import { useDb } from "../../../contexts/DatabaseContext";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    width: 500,
    position: "relative",
    minHeight: 200,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(6),
    right: theme.spacing(7),
    backgroundColor: amber[500],
    "&:hover": {
      backgroundColor: amber[600],
    },
  },
  tab: {
    indicatorColor: theme.palette.primary,
  },
  icon: {
    color: theme.palette.primary,
  },
  tabPanel: {
    backgroundColor: theme.palette.main,
  },
  dialog: {
    backgroundColor: theme.palette.dialogBg,
  },
  search: {
    color: theme.palette.isotext,
  },
}));

export default function PendingDocs() {
  const [createOpen, setCreateOpen] = useState(false)
  const { GetPendingDocs, createDocsPdf } = useDb()
  const [dialogOpen, setDialog] = useState(false)
  const filterObj = {
    department: {},
    site: {},
    source: {},
    isoClass: {},
    isoAgency: {},
    open: {},
  };

  const [filters, setFilters] = useState(filterObj);
  const docs = GetPendingDocs();
  const classes = useStyles();
  const [data, setData] = useState(docs);

  const generateRegisterPdf = async () => {
    await createDocsPdf(data)
  };
  // Switch Filters
  const [overdue, setOverdue] = useState(false);
  const [due30, setDue30] = useState(false);

  const openCreate = () => {
    setCreateOpen(true);
  };

  const closeCreate = () => {
    setCreateOpen(false);
  };

  const receiveFilters = (f) => {
    setDialog(false);
    setFilters(f);
  };

  useEffect(() => {
    setData(docs);
  }, [docs]);

  useEffect(() => {
    setData(docs.filter((item) => filterByDropdowns(item, filters)));
  }, [filters, overdue, due30]);

  useEffect(() => {
    console.log("action register filtered data is: ", data);
  }, [data]);

  const handleChipDelete = (f) => {
    setFilters({ ...filters, [f]: {} });
  };

  const filterByDropdowns = (doc, f) => {
    if (overdue && (!doc.open || !(doc.targetDate.toDate() < Date.now()))) {
      return null;
    }
    let dayDiff = moment(Date.now())
      .add(30, "days")
      .diff(moment(doc.targetDate.toDate()), "days");
    if (due30 && (!doc.open || !(dayDiff > 0 && dayDiff <= 30))) {
      return null;
    }
    if (
      isEmpty(f.department) 
    )
      return true;
    let dpt = f.department?.label
      ? doc["department"].name === f.department?.label
      : true;
    return dpt;
  };

  return (
    <>
      <RegisterViewer
        addItemFunction={openCreate}
        type={"pendingDoc"}
        data={data}
        pdfFunction={generateRegisterPdf}
        openDialog={setDialog}
        handleChipDelete={handleChipDelete}
        filterItems={filters}
        overdue={overdue}
        due30={due30}
        setDue30={setDue30}
        setOverdue={setOverdue}
      />

      <Dialog
        maxWidth="sm"
        fullWidth
        open={dialogOpen}
        onClose={() => setDialog(false)}
      >
        <DialogTitle className={classes.dialog}>Filters</DialogTitle>
        <DialogContent className={classes.dialog}>
          <Filters
            sendFilters={receiveFilters}
            selectedFilter={filters}
            isActionFilters={true}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
