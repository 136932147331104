import { Button, Card, CardActions, CardContent, Grid, IconButton, Typography } from '@mui/material'
import { AddBox, ArrowForward } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useDb } from '../../../../contexts/DatabaseContext'
import BackLink from '../../../shared/BackLink'
import IsoDataGrid from '../../../shared/data-grid/IsoDataGrid'
import BlraProcessStepHazards from './BlraProcessStepHazards'

export default function BlraProcessSteps({ open }) {
    const { blraId, processId, processName } = useParams();
    const { GetBaselineProcessSteps, getSingleProcess } = useDb();
    const steps = GetBaselineProcessSteps(blraId, processId);
    const [process, setProcess] = useState();

    const getProcess = async () => {
        const p = await getSingleProcess(processId);
        setProcess(p);
    }

    useEffect(() => {
      const unsubscribe = getProcess();
    
      return 
    }, [processId])
    

    const rows = steps && steps.map(s => ({
        ...s
    }))

    const columns = [
        { field: "label", headerName: "Step", width: 300 },
        { 
            field: 'action', 
            headerName: 'Detail' , 
            width: 120,
            renderCell: (params) => {
              return(
                <Link to={`/risk-manager/baselines/baseline-step-hazards/${blraId}/${params.row.id}/${params.row.label}/${processId}`}>
                    <Button color="primary" size="small" variant="contained" endIcon={<ArrowForward />}>
                        Hazards
                    </Button>
                </Link>
              )
            }
        },
    ];



    return (
        <>
            <Typography variant='h5'>Steps for Process: {process?.name} </Typography>
            <BackLink route={`/risk-manager/baseline-detail/Baseline/ADqGEEp7HlJTrnglUhfG`} />
            <IsoDataGrid 
                data={rows}
                columns={columns}
            />
        </>
    )
}
