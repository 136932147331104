import React, { useEffect } from "react";
import {
  ButtonGroup,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Button,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  Add as AddIcon,
  AttachFile,
} from "@mui/icons-material";
import { textFieldProps } from "../../shared/constants";
import CostAttachments from "./CostAttachments";
import { useAuth } from "../../../contexts/AuthContext";

export default function CostComponent({
  lineItems,
  setLineItems,
  approvalView,
  setTotalCost,
}) {
  useEffect(() => {
    const total = lineItems.reduce(
      (acc, item) => acc + parseFloat(item.cost),
      0
    );
    setTotalCost(total.toFixed(2));
  }, [lineItems]);

  const handleAddLineItem = () => {
    setLineItems([...lineItems, { label: "", cost: 0, attachments: [] }]);
  };

  const handleRemoveLineItem = (index) => {
    setLineItems(lineItems.filter((_, i) => i !== index));
  };

  const handleEditLineItem = (index, key, value) => {
    const newLineItems = [...lineItems];
    newLineItems[index][key] = value;
    setLineItems(newLineItems);
  };

  const handleAddAttachment = (index, fileObj) => {
    const newLineItems = [...lineItems];
    newLineItems[index].attachments
      ? newLineItems[index].attachments.push(fileObj)
      : (newLineItems[index].attachments = [fileObj]);
    setLineItems(newLineItems);
  };

  const [attachOpen, setAttachOpen] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const { currentUser } = useAuth()

  return (
    <div>
      {lineItems.map((item, index) => (
        <Grid container spacing={2} key={index}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Label"
              value={item.label}
              onChange={(e) =>
                handleEditLineItem(index, "label", e.target.value)
              }
              {...textFieldProps}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              label="Cost"
              type="number"
              value={item.cost}
              onChange={(e) =>
                handleEditLineItem(index, "cost", e.target.value)
              }
              {...textFieldProps}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{currentUser.currency ? currentUser.currency.value : ""}</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={1}>
            <ButtonGroup>
              <IconButton
                color="secondary"
                onClick={() => handleRemoveLineItem(index)}
                size="large">
                <DeleteIcon />
              </IconButton>
              <IconButton
                color="success"
                onClick={() => {
                  setCurrentIndex(index); // Set current index when the Attach button is clicked
                  setAttachOpen(true);
                }}
                size="large">
                <AttachFile />
              </IconButton>
            </ButtonGroup>
          </Grid>
        </Grid>
      ))}

      {!approvalView && (
        <Button
          startIcon={<AddIcon />}
          color="primary"
          onClick={handleAddLineItem}
          variant="contained"
        >
          Add Line Item
        </Button>
      )}

      <CostAttachments
        lineItem={lineItems[currentIndex]}
        open={attachOpen}
        close={() => setAttachOpen(false)}
        handleAddAttachment={handleAddAttachment}
        currentIndex={currentIndex}
      />
    </div>
  );
}
