import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import React from 'react'
import { useDb } from '../../../../../contexts/DatabaseContext'
import SignBlock from './SignBlock'

export default function SelectCustomSign({ open, close, ibraId, riskId, type, setSign, loading }) {
    const { GetCustomSignsOfType } = useDb()
    const signs = GetCustomSignsOfType(type)

    const handleClose = () => {
        close()
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle>
                Select Custom {type} Signage
            </DialogTitle>
            {
                !loading ? <DialogContent>
                    <Grid container spacing={3}>
                        {
                            signs && signs.map(s => (
                                <Grid item xs={12} md={4} key={s.id}>
                                    <SignBlock
                                        sign={s}
                                        selectSign={setSign}
                                    />
                                </Grid>
                            ))
                        }
                    </Grid>
                </DialogContent>
                :
                <CircularProgress />
            }
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color="secondary"
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}
