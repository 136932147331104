import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    Grid,
    Card,
    CardContent,
    Divider,
    Button,
    List,
    TextField,
    CircularProgress,
    Dialog,
    DialogContent,
    CardActions,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Pagination } from '@mui/material';
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import FloatingHelpComponent from '../../help/FloatingHelpComponent'
import AddSite from './AddSite'
import EditSite from './EditSite'
import SiteDataGrid from './SiteDataGrid'
import SiteTile from './SiteTile'

const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: theme.palette.cardBg
    },
    dialog: {
        backgroundColor: theme.palette.dialogBg
    }
}))

export default function SiteList() {
    const classes = useStyles()
    const { GetSiteList, deleteSite } = useDb()
    const sites = GetSiteList()
    const [search, setSearch] = useState(null) 

    const [dialog, setDialog] = useState(false)

    const closeDialog = () => {
        setDialog(false)
    }

    // Pagination
    const itemsPerPage = 10; // <- Not state but related to below pagination
    const [page, setPage] = useState(1);
    const [noOfPages] = useState(
        Math.ceil(sites.length / itemsPerPage)
    );

    const [error, setError] = useState(null)

    // Editing
    const [editOpen, setEditOpen] = useState(false)
    const [selectedSite, setSelectedSite] = useState(null)
    const handleEdit = (s) => {
        setSelectedSite(s)
        setEditOpen(true)
    }
    const handleDelete = async (s) => {
        try {
            await deleteSite(s.id)
        }
        catch(err) {
            setError(err.message)
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
                <Card className={classes.card}>
                    <CardContent>
                        <SiteDataGrid data={sites} handleDelete={handleDelete} handleEdit={handleEdit} />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={5}>
                <Card className={classes.card}>
                    <CardContent>
                        <Button onClick={() => setDialog(true)} variant="contained" color="primary" startIcon={<FontAwesomeIcon icon={faPlusSquare} />}>Add Site</Button>
                    </CardContent>
                </Card>
                <Dialog open={dialog} onClose={() => setDialog(false)} maxWidth="sm" fullWidth>
                    <DialogContent className={classes.dialog}>
                        <AddSite closeDialog={closeDialog} />
                    </DialogContent>
                </Dialog>
            </Grid>

            {
                selectedSite
                ?
                <EditSite open={editOpen} site={selectedSite} closeDialog={() => setEditOpen(false)} />
                :
                null
            }
            <FloatingHelpComponent
                name="Master Sites List" 
                videoPath="master_list"
                videoIndex={8}
            />
        </Grid>
    )
}
