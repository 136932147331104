import { Button, Card, CardActions, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { AddBox, ArrowForward, CloudDownload } from '@mui/icons-material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { selectFieldProps } from '../../shared/constants'
import Select from 'react-select'
import { useDb } from '../../../contexts/DatabaseContext'
import moment from 'moment'
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid'

export default function Evaluations() {
    const { GetEmployees, GetEvaluations } = useDb()
    const employees = GetEmployees()
    const evals = GetEvaluations()

    const [state, setState] = useState({
        addOpen: false,
    })

    const handleOpen = () => {
        setState({
            addOpen: true
        })
    }

    const handleClose = () => {
        setState({
            addOpen: false
        })
    }

    const handleSelect = (v, e) => {
        setState({
            ...state,
            [e.name]: v.value
        })
    }

    const handleUrlClick = (url) => {
        window.open(url, '_blank').focus();
    }

    const columns = [
        // { field: 'id', headerName: 'ID', width: 0 },
        { field: 'firstName', headerName: 'First Name', width: 240 },
        { field: 'lastName', headerName: 'Last Name', width: 240 },
        { field: 'module', headerName: 'Module', width: 200  },
        { field: 'evalDate', headerName: 'Eval. Date', width: 200  },
        { 
            field: 'action', 
            headerName: 'Template', 
            width: 140,
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                        <IconButton onClick={() => handleUrlClick(params.row.proofUrl)} size="large">
                            <CloudDownload />
                        </IconButton>
                     </div>
                );
            }
        }
    ]

    const rows = evals && evals.map(e =>({
        id: e.id,
        firstName: e.employee.firstName,
        lastName: e.employee.lastName,
        module: e.module.name,
        evalDate: moment(e.evaluationDate.toDate()).format('DD/MM/YYYY'),
        proofUrl: e.proofUrl
    }))

    return (
        <div>
            <h1>Evaluations</h1>
            <Card>
                <CardContent>
                <IsoDataGrid
                    data={rows}
                    columns={columns}
                />
                </CardContent>
                <CardActions>
                    <Button onClick={handleOpen} color="primary" variant="contained" startIcon={<AddBox />}>New Evaluation</Button>
                </CardActions>
            </Card>

            <Dialog maxWidth="sm" fullWidth open={state.addOpen} onClose={handleClose}>
                <DialogTitle>New Evaluation</DialogTitle>
                <DialogContent>
                    <Select
                        {...selectFieldProps}
                        options={employees}
                        onChange={handleSelect}
                        className="iso-select"
                        placeholder="Select Employee ..."
                        name="employee"
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={handleClose}>Cancel</Button>
                    {
                        state.employee 
                        ?
                        <Link to={`/training-manager/evaluations/new/${state.employee.id}`}>
                            <Button disabled={!state.employee} color="primary" variant="contained" startIcon={<ArrowForward />}>Next</Button>
                        </Link>
                        :
                        null
                    }
                </DialogActions>
            </Dialog>
        </div>
    )
}
