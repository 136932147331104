import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { Info, Save } from "@mui/icons-material";
import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDb } from "../../../../../contexts/DatabaseContext";
import {
  selectFieldProps,
  textFieldProps,
  docCategories,
  retention,
  disposition,
} from "../../../../shared/constants";
import Select from "react-select";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import FileUploader from "../../../../shared/FileUploader";
import { useAuth } from "../../../../../contexts/AuthContext";

export default function CoCreateDoc() {
  const { coId } = useParams();
  const history = useHistory();
  const { currentUser } = useAuth();
  const {
    MgmtGetCoDepartments,
    MgmtGetRosters,
    MgmtGetSites,
    MgmtGetDocTypes,
    mgmtCreateDoc,
  } = useDb();
  const departments = MgmtGetCoDepartments(coId);
  const rosters = MgmtGetRosters(coId);
  const sites = MgmtGetSites(coId);
  const docTypes = MgmtGetDocTypes(coId);

  const [state, setState] = useState({});

  // Action State
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      await mgmtCreateDoc(coId, state).then((docId) => {
        setState({});
        setLoading(false);
        history.push(`/companies/${coId}/doc-intparties/${docId}`);
      });
    } catch (err) {
      setError(err.message);
    }
  };

  const getUrl = (url) => {
    setState({
      ...state,
      docUrl: url,
    });
  };

  const getFileName = (f) => {
    console.log(f);
  };

  const getFileObj = (o) => {
    console.log(o);
  };

  return (
    <div>
      <Card>
        <CardHeader
          title="Manual Document Creation"
          avatar={
            <Avatar>
              <Info />
            </Avatar>
          }
        />
        <form onSubmit={handleSubmit}>
          <CardContent>
            <FileUploader
              userId={currentUser.uid}
              getUrl={getUrl}
              getFilename={getFileName}
              getFileObj={getFileObj}
            />
            <br />
            <TextField
              required
              {...textFieldProps}
              label="Doc Code"
              name="docCode"
              onChange={handleChange}
            />
            <TextField
              required
              {...textFieldProps}
              label="Doc Name"
              name="docName"
              onChange={handleChange}
            />
            <Select
              options={departments}
              {...selectFieldProps}
              className="iso-select"
              placeholder="Department"
              onChange={(val) => setState({ ...state, department: val })}
            />
            <Select
              options={docCategories}
              placeholder="Document Category"
              className="iso-select"
              onChange={(val) => setState({ ...state, category: val.value })}
              {...selectFieldProps}
            />
            <Select
              options={docTypes}
              placeholder="Document Type"
              onChange={(val) => setState({ ...state, docType: val })}
              className="iso-select"
              {...selectFieldProps}
            />
            <Select
              options={rosters}
              placeholder="Document Owner"
              onChange={(val) => setState({ ...state, owner: val })}
              {...selectFieldProps}
              className="iso-select"
            />
            <Select
              options={sites}
              placeholder="Site"
              onChange={(val) => setState({ ...state, site: val })}
              {...selectFieldProps}
              className="iso-select"
            />
            <Select
              options={retention}
              placeholder="Retention (Years)"
              onChange={(val) => setState({ ...state, retentionPeriod: val })}
              {...selectFieldProps}
              className="iso-select"
            />
            <Select
              options={disposition}
              placeholder="Disposition"
              onChange={(val) => setState({ ...state, dispositionMethod: val })}
              isRequired
              {...selectFieldProps}
              className="iso-select"
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Created Date"
                onChange={(d) => setState({ ...state, createdDate: d })}
                defaultValue={dayjs(state.createdDate)}
              />
            </LocalizationProvider>
            <br />
            <br />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Target Revision Date"
                onChange={(d) => setState({ ...state, targetRevDate: d })}
                defaultValue={dayjs(state.targetRevDate)}
              />
            </LocalizationProvider>
          </CardContent>
          <CardActions>
            {error ? <span className="iso-error">{error}</span> : null}
            <Button
              disabled={
                loading ||
                !state.site ||
                !state.department ||
                !state.owner ||
                !state.targetRevDate ||
                !state.createdDate ||
                !state.docUrl ||
                !state.docCode ||
                !state.docName ||
                !state.dispositionMethod ||
                !state.retentionPeriod
              }
              fullWidth
              color="primary"
              type="submit"
              variant="contained"
              startIcon={<Save />}
            >
              Submit & Next
            </Button>
          </CardActions>
        </form>
      </Card>
    </div>
  );
}
