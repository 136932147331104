import { Button, Chip, IconButton, TableCell, TableRow } from '@mui/material'
import { DeleteForever } from '@mui/icons-material'
import React from 'react'
import { useDb } from '../../../../contexts/DatabaseContext'

export default function CoUserTile({ u, remove, change }) {
    const { makeUserAdmin, revokeAdmin } = useDb()

    const makeAdmin = async () => {
        await makeUserAdmin(u.id)
    }

    const removeAdmin = async () => {
        await revokeAdmin(u.id)
    }

    return (
        <TableRow>
            <TableCell>{u.email}</TableCell>
            <TableCell>{u.firstName}</TableCell>
            <TableCell>{u.lastName}</TableCell>
            <TableCell>{u.companyAdmin ? <Chip size="small" label="Admin"/> : '' }</TableCell>
            <TableCell align="right">
                {
                    u.companyAdmin 
                    ?
                    <Button onClick={removeAdmin} color="secondary" size="small" variant="contained">Revoke Admin</Button>
                    :
                    <Button onClick={makeAdmin} color="primary" size="small" variant="contained">Make Admin</Button>
                }
                {
                    <IconButton color="secondary" onClick={remove} size="large">
                        <DeleteForever />
                    </IconButton>
                }
            </TableCell>
            <TableCell align="right">
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={change}
                >
                    Change PW
                </Button>
            </TableCell>
        </TableRow>
    );
}
