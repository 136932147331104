import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { textFieldProps } from "../../shared/constants";
import { Save } from "@mui/icons-material";
import { useDb } from "../../../contexts/DatabaseContext";

export default function EditProgramTarget({
  open,
  close,
  otpId,
  targetId,
  programId,
  currentTarget,
}) {
  const { updateCompanyDoc } = useDb();
  const [loading, setLoading] = useState();
  const [newTarget, setNewTarget] = useState("");

  const handleConfirm = async () => {
    setLoading(true);
    try {
      await updateCompanyDoc(
        `otps/${otpId}/targets/${targetId}/programs/`,
        programId,
        {
          programTarget: parseFloat(newTarget),
        }
      );
      close();
      window.location.reload();
    } catch (err) {
      window.alert(err.message);
    }
    setLoading(false);
  };
  return (
    <Dialog open={open} onClose={close} maxWidth="sm" fullWidth>
      <DialogTitle>Edit Target</DialogTitle>
      <DialogContent>
        <Typography>
          Current Target: <b>{currentTarget}</b>
        </Typography>
        <br />
        <TextField
          {...textFieldProps}
          required
          label="New Target"
          type="number"
          value={newTarget}
          onChange={(e) => setNewTarget(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} color="secondary">
          Cancel
        </Button>
        <Button
          startIcon={<Save />}
          color="primary"
          variant="contained"
          onClick={handleConfirm}
          disabled={loading || !newTarget}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
