import React from 'react'
import { Route, Switch } from 'react-router-dom'
import SignUp from './SignUp'
import Authenticate from './Authenticate'

export default function PublicRoutes() {
    return (
        <Switch>
            <Route path="/signup" component={SignUp} />
            <Route path="*" component={Authenticate} />
        </Switch>
    )
}
