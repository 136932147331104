import { ButtonGroup, IconButton } from '@mui/material'
import { RestorePage, Search } from '@mui/icons-material'
import React from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../contexts/AuthContext'
import FloatingHelpComponent from '../../help/FloatingHelpComponent'

export default function DocumentArchives() {
    const { GetObsoleteDocs, restoreObsolete } = useDb()
    const { currentUser } = useAuth()
    const docs = GetObsoleteDocs()

    const handleRestore = async (id) => {
        try {
            await restoreObsolete('documents', id)
        }
        catch(err) {
            console.log(err.message)
        }
    }

    console.log(currentUser)

    const columns = [
        { 
            field: 'action', 
            headerName: 'Detail', 
            width: 140,
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                        <ButtonGroup>
                            <Link to={`/doc-manager/doc-detail/${params.row.id}`}>
                                <IconButton size="large">
                                    <Search />
                                </IconButton>
                            </Link>
                            {
                                currentUser.accessRights.docManager > 1
                                ?
                                null
                                : 
                                <IconButton size="large">
                                    <RestorePage onClick={() => handleRestore(params.row.id)}/>
                                </IconButton>
                            }                            
                        </ButtonGroup>
                     </div>
                );
            }
        },
        { field: 'category', headerName: 'Category' , width: 200 },
        { field: 'docCode', headerName: 'Code', width: 200 },
        { field: 'docName', headerName: 'Name', width: 200 },
        { field: 'docType', headerName: 'Type', width: 200 },
        { field: 'department', headerName: 'Department', width: 200 },
        { field: 'owner', headerName: 'Owner', width: 200 },
        { field: 'site', headerName: 'Site', width: 200 },
        { field: 'targetRevDate', headerName: 'Target Rev.', width: 200 },
        
    ]

    const rows = docs && docs.map(doc  => ({
        id: doc.id,
        targetRevDate: moment(doc.targetRevDate.toDate()).format('DD/MM/YYYY'),
        category: doc.category,
        department: doc.department.label,
        docCode: doc.docCode,
        docName: doc.docName,
        docType: doc.docType.label,
        owner: `${doc.owner.value.firstName} ${doc.owner.value.lastName}`,
        site: doc.site.label
    }))
    
    
    return (
        <div>
            <h1>Archived Docs: </h1>
            <IsoDataGrid
                data={rows}
                columns={columns}
            />
            <FloatingHelpComponent
                name="Archived Documents" 
                videoPath="doc_manager"
                videoIndex={0}
            />
        </div>
    )
}
