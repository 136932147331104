import { Button } from '@mui/material'
import React from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import IsoDataGrid from '../../shared/data-grid/IsoDataGrid'

export default function CriticalControls() {
    const { GetCriticalControls } = useDb()
    const controls = GetCriticalControls()

    const rows = controls && controls.map(c => ({
        id: c.id,
        description: c.description,
        ibra: c.ibra,
        type: c.label,
        compliance: c.compliance,
        process: c.process,
        pto: c.pto,
        training: c.training
    }))

    const columns = [
        { field: 'description', headerName: 'Control Description', width: 250 },
        { field: 'type', headerName: 'Control', width: 200 },
        { field: 'ibra', headerName: 'Issue Based RA', width: 200 },
        { 
            field: 'process',
            headerName: 'Procedure', 
            width: 180,
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-between align-items-center">
                        {
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                href={params.row.process}
                                target="_blank"
                            >
                                Procedure
                            </Button>
                        }
                    </div>
                );
            }
        },
        { 
            field: 'training',
            headerName: 'Training', 
            width: 180,
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-between align-items-center">
                        {
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                href={params.row.training}
                                target="_blank"
                            >
                                Training
                            </Button>
                        }
                    </div>
                );
            }
        },
        { 
            field: 'pto',
            headerName: 'PTO', 
            width: 180,
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-between align-items-center">
                        {
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                href={params.row.training}
                                target="_blank"
                            >
                                PTO
                            </Button>
                        }
                    </div>
                );
            }
        },
        { 
            field: 'compliance',
            headerName: 'Legal', 
            width: 180,
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-between align-items-center">
                        {
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                href={params.row.compliance}
                                target="_blank"
                            >
                                Legal
                            </Button>
                        }
                    </div>
                );
            }
        },
    ]

    return (
        <div>
            <h1>Critical Controls</h1>
            <IsoDataGrid
                data={rows}
                columns={columns}
            />
        </div>
    )
}
