import { Card, CardContent, Typography } from '@mui/material'
import React from 'react'

export default function SignBlock({ sign, selectSign }) {

    return (
        <Card className="signblock" onClick={() => selectSign(sign)}>
            <CardContent>
                <div onClick={() => selectSign(sign)}>
                    <div style={{ textAlign: 'center', clear: 'both', marginTop: 5 }}>
                        <img src={sign.svg ? sign.svg : sign.png } width={200}/>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <Typography variant="caption" noWrap>
                            <b>{sign.code}</b> - {sign.name}
                        </Typography>
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}
