import { Avatar, Button, Card, CardActions, CardContent, CardHeader, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import { Add, Edit, Person, Save } from '@mui/icons-material'
import { Pagination } from '@mui/material';
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import FloatingHelpComponent from '../../help/FloatingHelpComponent'

import { textFieldProps } from '../../shared/constants'
import AddUser from './AddUser'
import MgUserEdit from './MgUserEdit'

export default function ManagementUsers() {
    const [editOpen, setEditOpen] = useState(false)
    const [user, setUser] = useState(null)
    const { GetAllUsers } = useDb()
    const users = GetAllUsers()
    const [search, setSearch] = useState(null) 
    const [addOpen, setAddOpen] = useState(false)
    
    // Pagination
    const itemsPerPage = 10; // <- Not state but related to below pagination
    const [page, setPage] = useState(1);
    const [noOfPages] = useState(
        Math.ceil(users.length / itemsPerPage)
    );

    const openEditUser = (u) => {
        setUser(u)
        setEditOpen(true)
    }

    const closeEditUser = () => {
        setEditOpen(false)
        setUser(null)
    }

   
    
    
    return (
        <Card>
            <CardHeader 
                avatar={<Avatar><Person /></Avatar>}
                title={'Users'}
            />
            <CardContent>
                <TextField
                    {...textFieldProps}
                    label={'Search Users'}
                    className={'iso-text-field'}
                    onChange={(e) => setSearch(e.target.value)}
                />
                
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>First Name</TableCell>
                                <TableCell>Last Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Company</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                search !== null || ''
                                ?
                                users && users.map((u) => {
                                    if(
                                        u.firstName.toLowerCase().includes(search) ||
                                        u.firstName.includes(search) ||
                                        u.lastName.toLowerCase().includes(search) ||
                                        u.lastName.includes(search) ||
                                        u.email.includes(search)
                                    ) {
                                        return (
                                            <TableRow key={u.id}>
                                                <TableCell>{u.firstName}</TableCell>
                                                <TableCell>{u.lastName}</TableCell>
                                                <TableCell>{u.email}</TableCell>
                                                <TableCell>{u.companyName}</TableCell>
                                                <TableCell><IconButton onClick={() => openEditUser(u)} size="large"><Edit /></IconButton></TableCell>
                                            </TableRow>
                                        );
                                    }
                                })
                                :
                                users && users
                                .slice((page -1) * itemsPerPage, page * itemsPerPage)
                                .map(u => {
                                    return (
                                        <TableRow key={u.id}>
                                            <TableCell>{u.firstName}</TableCell>
                                            <TableCell>{u.lastName}</TableCell>
                                            <TableCell>{u.email}</TableCell>
                                            <TableCell>{u.companyName}</TableCell>
                                            <TableCell>
                                                <IconButton onClick={() => openEditUser(u)} size="large"><Edit /></IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            }
                            <MgUserEdit open={editOpen} closeDialog={closeEditUser} user={user}/>
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
            <CardActions>
                {
                    !search
                    ?
                    <Pagination
                        count={Math.ceil(users.length / itemsPerPage)}
                        page={page}
                        onChange={(e, val) => setPage(val)}
                        defaultPage={1}
                        color="primary"
                        size="large"
                        showFirstButton
                        showLastButton
                    /> 
                    :
                    null
                }
                <Button onClick={() => setAddOpen(true)} color={'primary'} variant={'contained'} startIcon={<Add />}>Add User</Button>
            </CardActions>

            <AddUser open={addOpen} closeDialog={() => setAddOpen(false)} />

        </Card>
    );
}
