import { Button, CardActions, CircularProgress, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDb } from '../../../../contexts/DatabaseContext'
import Select from 'react-select'
import { selectFieldProps } from '../../../shared/constants'
import IBSelectProcessStep from './IBSelectProcessStep'
import IBSelectHazard from './IBSelectHazard'
import { ArrowForward } from '@mui/icons-material'

export default function IBFromBaseline1() {
    const { ibraId, blraId } = useParams()
    const history = useHistory()
    const { getIbra, GetBaselineProcesses, updateIbra } = useDb()
    const processes = GetBaselineProcesses(blraId)

    const [ibra, setIbra] = useState()
    const [state, setState] = useState({
        loading: false,
        error: null,
        data: {}
    })

    const firstLoad = async () => {
        const i = await getIbra(ibraId)
        setIbra(i)
    }

    const handleSelect = (v, e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.name]: v.value
            }
        })
    }

    const handleSubmit = async () => {
        setState({
            ...state,
            loading: true,
            error: null
        })
        try {
            await updateIbra(ibraId, { ...state.data, currentStep: 2 })
            history.push(`/risk-manager/issue-based/${ibraId}/2`)
        }
        catch(err) {
            setState({
                ...state,
                error: err.message,
                loading: false
            })
        }
    }
    
    if(!ibra) {
        firstLoad()
        return <CircularProgress />
    }
    else return (
        <div>
            <h1>{ibra.ibra}</h1>
            <Select
                options={processes}
                {...selectFieldProps}
                className="iso-select"
                placeholder="Select Process ..."
                name="process"
                onChange={handleSelect}
            />
            {
                state.data.process &&
                <IBSelectProcessStep
                    blraId={blraId}
                    processId={state.data.process}
                    selectStep={handleSelect}
                />
            }
            {
                // state.data.activity && 
                // <IBSelectHazard
                //     blraId={blraId}
                //     stepId={state.data.activity}
                //     selectHazard={handleSelect}
                // />
            }
            { 
                state.error && <Typography color="secondary">{state.error}</Typography>
            }
            <CardActions
                className="iso-right"
            >
                <Button
                    color="primary"
                    variant="contained"
                    endIcon={<ArrowForward />}
                    onClick={handleSubmit}
                    disabled={state.loading}
                >
                    Next: Identify Hazard
                </Button>
            </CardActions>
        </div>
    )
}
