import React, { useEffect, useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { Alert } from '@mui/material';
import { Button, Container } from "@mui/material";
import { useDb } from "../../../contexts/DatabaseContext";
import IsoFormBuilder from "../form-builder/IsoFormBuilder";
import { sections, questionsA, questionsB } from "./mocTemplate";

export default function MocChecklist() {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [checklist, setChecklist] = useState();
  const [pageLoad, setPageLoad] = useState(true);
  const { createNewFormTemplate, getCompanyDoc } = useDb();
  const [newFormCreated, setNewFormCreated] = useState(false);

  const loadChecklist = async () => {
    const formTemplate = await getCompanyDoc("formTemplate", "mocChecklist");
    if (formTemplate) {
      setChecklist(formTemplate);
    }
    setPageLoad(false);
  };

  useEffect(() => {
    return loadChecklist();
  }, [newFormCreated]);

  const createTemplateChecklist = async () => {
    // Load up the template checklist from Firestore master templates
    setLoading(true);
    try {
      await createNewFormTemplate(
        sections,
        [questionsA, questionsB],
        "mocChecklist"
      );
      setNewFormCreated(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      window.alert(err.message);
    }
    // Create a new checklist for the user in their checklist collection
    // Render the checklist
  };

  return (
    <Container>
      {!currentUser.mocChecklistSetup && (
        <div>
          <Alert severity="warning">This is a preview of the MOC Form.</Alert>
          <br />
          {!checklist && (
            <Button
              variant="contained"
              color="primary"
              onClick={createTemplateChecklist}
              disabled={loading}
            >
              Create MOC Checklist
            </Button>
          )}
          {checklist && <IsoFormBuilder formDocId={checklist.id} />}
        </div>
      )}
    </Container>
  );
}
