import React from 'react'
import Select from 'react-select'
import { useDb } from '../../../../contexts/DatabaseContext'
import { selectFieldProps } from '../../../shared/constants'

export default function IBSelectProcessStep({ blraId, processId, selectStep }) {
    const { GetBaselineProcessSteps } = useDb()
    const steps = GetBaselineProcessSteps(blraId, processId)

    return (
        <div>
            <Select
                {...selectFieldProps}
                className="iso-select"
                placeholder="Select Activity ..."
                options={steps}
                onChange={selectStep}
                name="activity"
            />
        </div>
    )
}
