import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React from 'react'
import { useAuth } from '../../../../contexts/AuthContext'
import FileUploader from '../../../shared/FileUploader'

export default function AddRiskAttachment({ open, close, sendFile }) {
    const { currentUser } = useAuth()
    
    const handleClose = () => {
        close()
    }

    const getUrl = (u) => {
        sendFile(u)
        handleClose()
    }

    const getFile = (f) => {}
    
    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>Attach File</DialogTitle>
            <DialogContent>
                <FileUploader
                    userId={currentUser.uid}
                    getUrl={getUrl}
                    getFileObj={getFile}
                    getFilename={getFile}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color="secondary"
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}
