import React from 'react'
import { useDb } from '../../../../contexts/DatabaseContext'
import RPendingRegister from './RPendingRegister'

export default function RPendingDocs() {
    const { GetPendingDocs } = useDb()
    const docs = GetPendingDocs()

    return (
        <div>
            <h1>Documents Pending Approval</h1>
            <RPendingRegister
                data={docs}
            />
        </div>
    )
}
