import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react'
import Select from 'react-select'
import { useDb } from '../../../../contexts/DatabaseContext'
import { selectFieldProps } from '../../../shared/constants'
import { Computer } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        background: theme.palette.primary.main,
        color: '#222'
    }
}))

export default function NewTrainingMatrix({ open, close }) {
    const classes = useStyles()
    const { GetDepartments, GetSites, newTrainingMatrixReport } = useDb()
    const depts = GetDepartments()
    const sites = GetSites()
    const history = useHistory()

    const [state, setState] = useState({})
    const [compState, setCompState] = useState({
        loading: false,
        error: null
    })

    const handleCheck = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.checked
        })
    }

    const handleClose = () => {
        setState({})
        setCompState({
            loading: false,
            error: null
        })
        close()
    }

    const handleSubmit = async () => {
        setCompState({
            loading: true,
            error: null
        })
        try {
            await newTrainingMatrixReport(state).then(matrixId => {
                handleClose()
                history.push(`/training-manager/matrix/${matrixId}`)
            })
        }
        catch(err) {
            setCompState({
                loading: true,
                error: null
            })
        }
    }
    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle className={classes.dialogTitle}>New Training Matrix</DialogTitle>
            <DialogContent>
                <Typography>Department</Typography>
                <FormControlLabel
                    label="All Departments"
                    control={
                        <Checkbox
                            onChange={handleCheck}
                            name="allDepartments"
                        />
                    }
                />
                {
                    !state.allDepartments
                    ?
                    <Select
                        {...selectFieldProps}
                        className="iso-select"
                        options={depts}
                        placeholder="Select Department ..."
                    />
                    :
                    null
                }
                <Typography>Site</Typography>
                <FormControlLabel
                    label="All Sites"
                    control={
                        <Checkbox
                            onChange={handleCheck}
                            name="allSites"
                        />
                    }
                />
                {
                    !state.allSites
                    ?
                    <Select
                        {...selectFieldProps}
                        className="iso-select"
                        options={sites}
                        placeholder="Select Site ..."
                    />
                    :
                    null
                }
                {
                    compState.error ? 
                    <span className="iso-erro">{compState.error}</span>
                    : null
                }
            </DialogContent>
            <DialogActions>
                <span className="iso-small">By default all departments and sites will be run</span>
                <Button color="secondary" onClick={handleClose}>Cancel</Button>
                <Button 
                    onClick={handleSubmit} 
                    color="primary" 
                    variant="contained" 
                    startIcon={<Computer />}
                    disabled={
                        compState.loading
                    }
                >
                    Run Report
                </Button>
            </DialogActions>
        </Dialog>
    )
}
