import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import FileUploader from "../../shared/FileUploader";

export default function CostAttachments({
  lineItem,
  open,
  close,
  handleAddAttachment,
  currentIndex,
}) {
  const handleClose = () => {
    close();
  };

  const handleFileSelect = (fileObj) => {
    // Add the file to the current line item's attachments
    handleAddAttachment(currentIndex, fileObj);

    // Then close the dialog or do whatever else you need to...
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Attachments for {lineItem.label || "Line Item"}</DialogTitle>
      <DialogContent>
        {lineItem.attachments &&
          lineItem.attachments.map((file, i) => (
            <a
              href={file.url}
              target="_blank"
              key={i}
              rel="noopener noreferrer"
            >
              <Chip style={{ cursor: "pointer" }} label={file.fileName} />
            </a>
          ))}
        <FileUploader getFileObj={handleFileSelect} />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
